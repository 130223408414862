import React from 'react';
import { Navigate } from 'react-router';
import { OwnerTaxesView } from 'src/features/owner';
import { TheRouteObject } from '../types';

export const taxes: TheRouteObject[] = [
  {
    index: true,
    element: <OwnerTaxesView />,
  },
  {
    path: '*',
    element: <Navigate to="/taxes" />,
  },
];
