import { DocumentFileCategoryEnum, FILE_SIZE_25MB, FileObject, snackbarMessagesHandler } from '@utils';

export const actionMenuItems = [
  {
    label: 'Usuń plik',
    value: 'delete',
  },
  {
    label: 'Pobierz plik',
    value: 'download',
  },
];

export const transformData = (data: FormData, companyId?: any) => {
  let totalValidFilesSize = 0;
  const files = data.getAll('file') as File[];

  const allFiles = {
    formData: new FormData(),
    okayFileCount: 0,
    totalFileCount: files.length,
    wrongSizeFiles: [] as File[],
    tooLongNameFiles: [] as File[],
  };
  //add the company ID if it exists
  if (companyId) {
    allFiles.formData.append('companyId', companyId);
  }
  //check each file
  let shouldReturn = false;
  files.forEach(file => {
    shouldReturn = false;
    //check if length is below 100
    if (file.name.length > 100) {
      allFiles.tooLongNameFiles.push(file);
      shouldReturn = true;
    }
    //check if size is below maximum
    if (file.size > FILE_SIZE_25MB) {
      allFiles.wrongSizeFiles.push(file);
      shouldReturn = true;
    }
    if (shouldReturn) return;

    //add the file to be uploaded
    totalValidFilesSize += file.size;
    allFiles.okayFileCount++;
    allFiles.formData.append('file', file);
  });
  //if total files size is above maximum, display snackbar and abort any further actions
  if (totalValidFilesSize > FILE_SIZE_25MB) {
    snackbarMessagesHandler.allFilesSize(25, totalValidFilesSize);
    return;
  }
  //if total file count is above maximum, display snackbar and abort any further actions
  if (files.length > 10) {
    snackbarMessagesHandler.tooManyFiles(10, files.length);
    return;
  }
  //if there are any files that did not match the requirements, display appropriate snackbars
  if (allFiles.tooLongNameFiles.length || allFiles.wrongSizeFiles.length) {
    if (allFiles.tooLongNameFiles.length) snackbarMessagesHandler.fileTooLongName();
    if (allFiles.wrongSizeFiles.length) snackbarMessagesHandler.singleFileSize(25, allFiles.wrongSizeFiles[0].size);

    //if there is only one file uploaded, abort any further actions
    if (allFiles.totalFileCount <= 1) return;
    return allFiles;
  }
  return allFiles;
};

export const updateFilesAfterCategoryChanged = (files: FileObject[], value: DocumentFileCategoryEnum, id: number): FileObject[] => {
  const updatedFiles = files.map(file => {
    if (file.id === id) {
      file.category = value;
    }
    return file;
  });
  return updatedFiles;
};

export interface DocumentsFormData {
  'checkbox-filter-tag-RevenuesExpenditures': boolean;
  'checkbox-filter-tag-ProfitsLosses': boolean;
  'checkbox-filter-tag-BalanceSheet': boolean;
  'checkbox-filter-tag-Personnel': boolean;
  'checkbox-filter-tag-OfficialLetters': boolean;
}
