import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { AccountingTemplate, VatTemplate } from '@services';

interface AccountantStore {
  accountingTemplateStoreOptions: AccountingTemplate[];
  setAccountingTemplateStoreOptions: (data: AccountingTemplate[]) => void;
  vatTemplateStoreOptions: VatTemplate[];
  setVatTemplateStoreOptions: (data: VatTemplate[]) => void;
}

export const useAccountantStore = create<AccountantStore>(set => ({
  accountingTemplateStoreOptions: [],
  setAccountingTemplateStoreOptions: (data: AccountingTemplate[]) => set(() => ({ accountingTemplateStoreOptions: data })),
  vatTemplateStoreOptions: [],
  setVatTemplateStoreOptions: (data: VatTemplate[]) => set(() => ({ vatTemplateStoreOptions: data })),
}));

if (process.env.NODE_ENV === 'development') mountStoreDevtool('AccountantStore', useAccountantStore);
