import React from 'react';
import { Label } from '@visx/annotation';
import { Line } from '@visx/shape';
import { Group } from '@visx/group';

type Props = {
  x: number;
  y: number;
  chartHeight: number;
  label: string;
};

export const ChartTooltipWithLine = ({ x, y, chartHeight, label }: Props) => {
  return (
    <Group left={x} top={y}>
      <Line from={{ x: 0, y: 0 }} to={{ x: 0, y: chartHeight - y }} stroke="black" strokeWidth={3} />
      <Label
        x={0}
        y={0}
        backgroundFill="#101013"
        fontColor="white"
        title={label}
        backgroundPadding={{
          bottom: 8,
          top: 10,
          left: 18,
          right: 18,
        }}
        titleFontSize={14}
        titleFontWeight={500}
        showAnchorLine={false}
        horizontalAnchor="middle"
        verticalAnchor="end"
        backgroundProps={{
          rx: 4,
        }}
        titleProps={{
          fontFamily: 'Poppins, sens-serif',
          textAnchor: 'middle',
        }}
      />
    </Group>
  );
};
