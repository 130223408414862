import { useEffect, useState } from 'react';
import { InvoiceDetails, invoiceMarkAsSent } from '@services';
import { RequestErrorMessage } from '@interfaces';
import { handleApiError, InvoiceActionEnum, InvoiceStatusType, snackbarMessagesHandler } from '@utils';

interface UseMarkInvoiceAsSent {
  isPendingMarkInvoice: boolean;
  cannotMarkInvoice: boolean;
  markInvoice: () => void;
}

export const useMarkInvoiceAsSent = (
  invoiceId: string,
  invoiceData: InvoiceDetails,
  refreshData: (action: InvoiceActionEnum) => void
): UseMarkInvoiceAsSent => {
  const [isPendingMarkInvoice, setIsPendingMarkInvoice] = useState(false);
  const [cannotMarkInvoice, setCannotMarkInvoice] = useState(true);

  useEffect(() => {
    if (!invoiceData) {
      setCannotMarkInvoice(true);
      return;
    }
    const isStatusInvalid = invoiceData.status === InvoiceStatusType.Draft;

    setCannotMarkInvoice(invoiceData.isSent || isStatusInvalid || isPendingMarkInvoice);
  }, [invoiceData, isPendingMarkInvoice]);

  const markInvoiceAsSent = () => {
    if (!invoiceId) return;
    setIsPendingMarkInvoice(true);
    invoiceMarkAsSent(+invoiceId)
      .then(() => {
        snackbarMessagesHandler.invoiceMarkedAsSent();
        refreshData(InvoiceActionEnum.SEND);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingMarkInvoice(false));
  };

  return {
    isPendingMarkInvoice,
    cannotMarkInvoice,
    markInvoice: () => markInvoiceAsSent(),
  };
};
