import { DateFormat } from '@interfaces';
import { dateFormatter } from '@lib';
import { Button, FormControl, InputLabel, TextField } from '@mui/material';
import { CalendarPickerView, DesktopDatePicker, PickersDayProps } from '@mui/x-date-pickers';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, HelperTextAlignment } from '@utils';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Control, Controller, FieldValues, RegisterOptions, UseFormClearErrors, UseFormTrigger, useFormContext } from 'react-hook-form';
import { FormElementAppearance, FormElementSize } from '../types';
import { CalendarIconDisabled, NewCalendarIcon, getSuggestion } from './datePicker.utils';

import './datePicker.scss';

interface Props {
  name: string;
  testcy: string;
  control?: Control;
  clearErrors?: UseFormClearErrors<FieldValues>;
  width?: string;
  disabled?: boolean;
  readOnly?: boolean;
  appearance?: FormElementAppearance;
  size?: FormElementSize;
  validation?: RegisterOptions;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  inputFormat?: DateFormat;
  disablePast?: boolean;
  disableFuture?: boolean;
  helperText?: string;
  helperTextAlign?: HelperTextAlignment;
  hintDay?: string;
  allowSuggestions?: boolean;
  minDate?: Date;
  maxDate?: Date;
  toolbarComponent?: JSX.Element;
  openPicker?: boolean;
  views?: CalendarPickerView[];
  openTo?: CalendarPickerView;
  onFocus?: () => void;
  onChange?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  trigger?: UseFormTrigger<FieldValues>;
  setOpenPicker?: (v: boolean) => void;
}

export const DatePicker = ({
  name,
  testcy,
  control,
  clearErrors,
  width = '200px',
  disabled = false,
  readOnly = false,
  appearance = FormElementAppearance.Old,
  size = FormElementSize.Medium,
  validation = {},
  defaultValue = '',
  label,
  placeholder = 'dd.mm.rrrr',
  inputFormat = 'dd.MM.yyyy',
  disablePast = false,
  disableFuture = false,
  helperText,
  helperTextAlign = HelperTextAlignment.Left,
  hintDay,
  allowSuggestions,
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
  toolbarComponent: ToolbarComponent,
  openPicker,
  views = ['year', 'day'],
  openTo = 'day',
  onFocus = () => {},
  onChange,
  onKeyDown,
  trigger,
  setOpenPicker,
}: Props) => {
  const [open, setOpen] = useState(false);

  const [suggestion, setSuggestion] = useState<Date | null>(null);
  const [preventEnter, setPreventEnter] = useState(false);
  const [isValidated, setIsValidated] = useState(true);
  const [isEnterHalted, setIsEnterHalted] = useState<React.KeyboardEvent<HTMLDivElement>>(null);

  const formContext = useFormContext();

  const clearErrorsLocal = formContext?.clearErrors ?? clearErrors;
  const triggerLocal = formContext?.trigger ?? trigger;

  const onAccept = (e: Date): void => {
    clearErrorsLocal?.(name);
    const value = dateFormatter.objectToString(e, 'dd.MM.yyyy');
    if (!value) return;
    onChange?.(value);
    if (triggerLocal) setTimeout(() => triggerLocal(name));
  };

  const onBlurHandler = (onBlurProvided: (...event: any[]) => void, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setSuggestion(null);
    const value = e.target.value;
    const isValid = dateFormatter.isValidStringDate(value) && value.length === 10;
    onBlurProvided(!!value ? dateFormatter.getDateObjectFromString(value) : null);
    if (isValid) {
      clearErrorsLocal?.(name);
      onChange?.(value);
    }
    if (triggerLocal) setTimeout(() => triggerLocal(name));
  };

  const isHintDay = (date: Date): boolean => {
    const formated = dateFormatter.objectToString(date, 'dd.MM.yyyy');
    return formated === hintDay;
  };

  const getDayButtonClasses = (d: Date, props: PickersDayProps<any>): string => {
    return [
      'button-day-pick',
      props.disabled ? 'button-day-pick-disable' : '',
      props.today ? 'current-day' : '',
      props.selected ? 'selected-day' : '',
      hintDay && isHintDay(d) ? 'button-hint-day' : '',
      props.outsideCurrentMonth ? 'outside-month' : '',
    ].join(' ');
  };

  const dayRenderer = (d: Date, props: PickersDayProps<any>): JSX.Element => {
    const day = dateFormatter.getDateDay(d);
    const dayClasses = getDayButtonClasses(d, props);

    return (
      <Button
        data-testcy={`${testcy}-calendar-day ${testcy}-calendar-day-${day}`}
        className={dayClasses}
        disabled={props.disabled}
        style={{ minWidth: '24px' }}
        tabIndex={props.outsideCurrentMonth ? -1 : undefined}
        onClick={() => props.onDaySelect(d, 'finish')}
        key={props.key}
      >
        {Number(day)}
      </Button>
    );
  };

  const getRootInputClasses = (error: any): string => {
    //prettier-ignore
    return [
      'date-input styled-input',
      `helper-text-${error?.message ? 'left' : helperTextAlign}`,
      error?.message ? 'has-error' : '',
      disabled ? 'input-disabled' : '',
      readOnly ? 'input-readonly' : ''
    ].join(' ');
  };

  const weekdayRenderer = (day: string): string => {
    switch (day) {
      case 'pon':
        return 'Pon.';
      case 'wto':
        return 'Wt.';
      case 'śro':
        return 'Śr.';
      case 'czw':
        return 'Czw.';
      case 'pią':
        return 'Pt.';
      case 'sob':
        return 'Sob.';
      case 'nie':
        return 'Niedz.';

      default:
        return '???';
    }
  };

  const updateSuggestion = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string | undefined): void => {
    if (!allowSuggestions) return setSuggestion(null);
    const value = typeof e == 'object' ? e.target.value : e; // e may be either event object or string
    const suggestion = getSuggestion(value);
    setSuggestion(suggestion);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>, onChangeProvided: (value: Date) => void): void => {
    if (!allowSuggestions || !suggestion || (e.key !== 'Enter' && e.key !== 'Tab')) {
      onKeyDown?.(e);
      return;
    }

    onChangeProvided(suggestion);
    setSuggestion(null);
    setIsValidated(false);
    setIsEnterHalted(null);

    if (triggerLocal)
      setTimeout(async () => {
        const isValid = await triggerLocal(name);
        setIsValidated(true);
        if (!isValid) {
          setPreventEnter(true);
          return;
        }
        onChange?.(dateFormatter.objectToString(suggestion, inputFormat));
        if (isEnterHalted) onKeyDown?.(e);
      });

    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onCalendarChangeHandler = (onChangeProvided: (date: Date, keyboardInput?: string) => void, date: Date, keyboardInput?: string): void => {
    onChangeProvided(date, keyboardInput);
    updateSuggestion(keyboardInput);
  };

  const onKeyUpHandler = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (preventEnter) {
      setPreventEnter(false);
      if (e.key === 'Enter') return;
    }
    if (!isValidated && e.key === 'Enter') {
      setIsEnterHalted(e);
      return;
    }
  };

  const suggestionStr = useMemo(() => dateFormatter.objectToString(suggestion, inputFormat), [suggestion, inputFormat]);

  return (
    <div className={`date-input-container appearance-${appearance} size-${size}`} style={{ width, minWidth: width }}>
      <FormControl variant="outlined">
        {label && (
          <InputLabel shrink className={`input-label `} data-testcy={`${testcy}-label`} disabled={disabled}>
            {label}
          </InputLabel>
        )}
        <Controller
          control={control}
          name={name}
          rules={validation}
          defaultValue={defaultValue}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <div className={`desktop-date-picker-wrapper`}>
              <DesktopDatePicker
                open={openPicker === undefined ? open : openPicker}
                onClose={() => (setOpenPicker === undefined ? setOpen(false) : setOpenPicker(false))}
                onOpen={() => (setOpenPicker === undefined ? setOpen(true) : setOpenPicker(true))}
                inputFormat={inputFormat}
                value={value}
                disablePast={disablePast}
                views={views}
                openTo={openTo}
                disableFuture={disableFuture}
                maxDate={maxDate}
                minDate={value && value < minDate ? value : minDate}
                components={{
                  OpenPickerIcon: () => NewCalendarIcon(testcy, disabled || readOnly),
                }}
                ToolbarComponent={() => ToolbarComponent}
                showToolbar={!!ToolbarComponent}
                onChange={(date, keyboardInput) => onCalendarChangeHandler(onChange, date, keyboardInput)}
                onAccept={value => onAccept(value)}
                disabled={disabled}
                dayOfWeekFormatter={weekdayRenderer}
                renderDay={(d, _, props) => dayRenderer(d, props)}
                renderInput={params => {
                  if (params?.inputProps) params.inputProps.placeholder = placeholder;
                  return (
                    <>
                      {allowSuggestions && <div className="suggestion">{suggestionStr}</div>}
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          'data-testcy': testcy,
                          tabIndex: readOnly ? -1 : undefined,
                        }}
                        id={name}
                        style={{ width: width }}
                        variant="outlined"
                        onFocus={onFocus}
                        helperText={error?.message ?? helperText}
                        onBlur={e => onBlurHandler(onBlur, e)}
                        classes={{ root: getRootInputClasses(error) }}
                        placeholder={placeholder}
                        disabled={disabled}
                        autoComplete="off"
                        onKeyUp={onKeyUpHandler}
                        onKeyDown={e => onKeyDownHandler(e, onChange)}
                      />
                    </>
                  );
                }}
              />
            </div>
          )}
        />
      </FormControl>
    </div>
  );
};
