import React from 'react';
import { Avatar } from '@mui/material';
import { animated, config, useSpring } from '@react-spring/web';

import './chatItem.scoped.scss';

type Props = {
  channel: Amity.Channel<any>;
  isSelected: boolean;
  onSelect: (channelId: string) => void;
};

export const ChatItem = ({ channel, isSelected, onSelect }: Props) => {
  const handleChatItemClick = e => {
    e.stopPropagation();
    onSelect(channel.channelId);
  };

  const containerClassName = isSelected ? 'active-chat-item-container' : 'chat-item-container';

  const spring = useSpring({
    from: { backgroundColor: '#ffffff00', borderRadius: '0em' },
    to: { backgroundColor: '#f7f7f9ff', borderRadius: '1em' },
    reverse: !isSelected,
    config: config.stiff,
  });

  const AnimatedDiv = animated('div');

  const firstLetter = channel.displayName.charAt(0)?.toUpperCase();

  return (
    <AnimatedDiv className={containerClassName} onClick={handleChatItemClick} style={spring}>
      <Avatar>{firstLetter}</Avatar>
      <p className="title">{channel.displayName}</p>
    </AnimatedDiv>
  );
};
