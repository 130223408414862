import { dateFormatter } from '@lib';
import { EmployeeDetails } from '@services';
import { formatNumberToCurrency } from '@utils';
import { EmployeeFormData } from 'src/services/employees';

export const transformEmployeeDataToFormData = (data: EmployeeDetails): EmployeeFormData => {
  if (!data) return null;

  const contractStartDate = dateFormatter.getDateObjectFromString(data.contractStartDate as string, "yyyy-MM-dd'T'HH:mm:ss");
  const contractEndDate = data.contractEndDate ? dateFormatter.getDateObjectFromString(data.contractEndDate as string, "yyyy-MM-dd'T'HH:mm:ss") : null;
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    pesel: data.pesel,
    city: data.address.city,
    street: data.address.street,
    email: data.email,
    phone: data.phone,
    position: data.position,
    zipCode: data.address.zipCode,
    typeOfEmployment: data.typeOfEmployment,
    grossSalary: formatNumberToCurrency(data.grossSalary),
    contractStartDate: contractStartDate,
    contractEndDate: contractEndDate,
  };
};
