import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { FieldValues, UseFormReturn, useFormState } from 'react-hook-form';
import { AutocompleteInput, PaperWithActions, SearchIcon } from '@components';
import { LabeledAction, focusOnNextFormInput, formErrorMsg, handleApiError, nipRegexp } from '@utils';
import { Option } from '@interfaces';
import { ContractorData, getContractorById } from '@services';
import { useContractorStore, useInvoiceStore } from '@store';
import './contractorSection.scoped.scss';
import { ContractorModalOpenMode } from 'src/hooks/contractor';
import { InvoiceSection } from 'src/components/miscellaneous/invoiceSection/invoiceSection';
import { ContractorInfo } from './contractorInfo';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  useFormFeatures: Partial<UseFormReturn<FieldValues>>;
  contractorOptions: Option[];
  isPendingContractors: boolean;
  onFilterContractorChanged: (v: string) => void;
  openContractorModal: (mode: ContractorModalOpenMode) => void;
  allowedActions: LabeledAction[];
  date?: string;
}

export const ContractorSection = ({
  open,
  setOpen,
  useFormFeatures,
  contractorOptions,
  isPendingContractors,
  onFilterContractorChanged,
  openContractorModal,
  allowedActions,
  date,
}: Props) => {
  const { setSelectedContractor, selectedContractor } = useContractorStore();
  const { isCorrected } = useInvoiceStore();

  const { control, setValue, trigger, watch } = useFormFeatures;
  const navigate = useNavigate();
  const {
    errors: { client: clientErr },
  } = useFormState({ control });

  const watchClientId = watch('client');

  useEffect(() => {
    const errors = [clientErr];
    const isInvalid = errors.some(e => !!e);
    if (isInvalid && !open) setOpen(true);
  }, [clientErr]);

  useEffect(() => {
    if (!watchClientId) return;
    fetchContractorData(date);
  }, [date]);

  useEffect(() => {
    if (!watchClientId) {
      setSelectedContractor(null, null);
    } else {
      fetchContractorData();
    }
  }, [watchClientId]);

  const fetchContractorData = (date = null) => {
    getContractorById(watchClientId, date)
      .then((data: ContractorData) => {
        if (data) {
          setSelectedContractor(data, watchClientId);
        }
      })
      .catch(err => {
        if (err.status === 404 || err.status === 403) {
          navigate('/page-not-found');
          return;
        }
        handleApiError(err.data);
      });
  };

  return (
    <InvoiceSection title="Klient">
      <div className={`contractor-section-content ${isCorrected && 'disabled'}`}>
        <AutocompleteInput
          width="316px"
          testcy="invoice-form-contractor"
          name={'contractorNameInvoiceEdit'}
          control={control}
          trigger={trigger}
          options={contractorOptions}
          validation={{
            required: formErrorMsg.isRequired,
          }}
          placeholder="Wybierz kontrahenta"
          appearance="primary"
          size="medium"
          label="Kontrahent"
          startAdornment={<SearchIcon />}
          endAdornment={true}
          onChange={v => {
            setValue('client', v?.value);
          }}
          PaperComponent={props => <PaperWithActions testcy="invoice-form" {...props} actions={allowedActions} />}
          onKeyDown={event => {
            focusOnNextFormInput(event, 'name-1');
          }}
          isPendingFilter={isPendingContractors}
          onFilterChanged={(filteredValue: string) => onFilterContractorChanged(filteredValue)}
          onPaste={val => {
            if (nipRegexp.test(val)) {
              return val.trim().replaceAll('-', '');
            }
            return val;
          }}
        />
        <div className="contractor-info-container">
          {selectedContractor && <ContractorInfo data={selectedContractor} onEdit={() => openContractorModal('edit')} />}
        </div>
      </div>
    </InvoiceSection>
  );
};
