import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const StoreIcon = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M4.75 12.25V21.25H19.25V12.25" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2.75 21.25H21.25" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M14.5 21.25V16.25C14.5 15.6977 14.0523 15.25 13.5 15.25H10.5C9.94772 15.25 9.5 15.6977 9.5 16.25V21.25"
        stroke="#101013"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.91246 11.75C3.86478 11.75 2.41888 9.74391 3.06641 7.80132L4.06641 4.80131C4.47475 3.57629 5.62116 2.75 6.91246 2.75H9.00024M18.0879 11.75C20.1356 11.75 21.5815 9.74391 20.934 7.80132L19.934 4.80132C19.5256 3.57629 18.3792 2.75 17.0879 2.75H15.0002M9.00024 8.75C9.00024 10.4069 7.6571 11.75 6.00024 11.75M9.00024 8.75C9.00024 10.4069 10.3434 11.75 12.0002 11.75C13.6571 11.75 15.0002 10.4069 15.0002 8.75M9.00024 8.75V2.75M15.0002 8.75C15.0002 10.4069 16.3434 11.75 18.0002 11.75M15.0002 8.75V2.75M9.00024 2.75H15.0002"
        stroke="#101013"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
