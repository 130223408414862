import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonMain } from '@components';
import { IncomesTab } from './components';
import './sharedInvoicesList.scoped.scss';
import { useContractorStore } from 'src/store/contractorStore';
import { useInvoiceStore } from '@store';

export const SharedInvoicesList = () => {
  const { ownerMyCompanyData, setSelectedContractor } = useContractorStore();
  const { setIsClone, setIsCorrected, setIsFromDashboard } = useInvoiceStore();

  const navigate = useNavigate();

  const onNavigateToInvoiceForm = () => {
    setIsClone(false);
    setIsFromDashboard(false);
    setIsCorrected(false);
    navigate('/invoices-list/add-invoice');
    setSelectedContractor(null, null);
  };

  return (
    <div className="invoices-list-wrapper standard-layout-spacing-g">
      <div className="invoices-list">
        <div className="header">
          <h1 className="title-g">Przychody</h1>
          <div className="navigation">
            <div></div>
            <div className="buttons-arrea">
              <ButtonMain
                testcy="add-invoice"
                content={'Utwórz fakturę'}
                width="200px"
                isDisabled={ownerMyCompanyData?.isDummy}
                onClick={onNavigateToInvoiceForm}
                tooltip={ownerMyCompanyData?.isDummy ? 'Przed wystawieniem faktury należy uzupełnić dane firmy.' : null}
              />
            </div>
          </div>
        </div>
        <div>
          <IncomesTab />
        </div>
      </div>
    </div>
  );
};
