import { LoaderIcon } from '@components';
import { FormControl, InputAdornment, InputLabel, TextField } from '@mui/material';
import { HelperTextAlignment, InputFormatFn } from '@utils';
import { isArray } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Control, Controller, FieldValues, RegisterOptions, UseFormWatch } from 'react-hook-form';
import './textInputNew.scss';
import { FormElementAppearance, FormElementSize } from '../types';

interface Props {
  name: string;
  testcy: string;
  control?: Control;
  width?: string;
  disabled?: boolean;
  appearance?: FormElementAppearance;
  size?: FormElementSize;
  validation?: RegisterOptions;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  darkPlaceholder?: boolean;
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  formatFn?: InputFormatFn;
  formatOnBlurFn?: InputFormatFn;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onFocus?: (e) => void;
  isPendingValue?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  helperTextAlign?: HelperTextAlignment;
  multiline?: boolean;
  rows?: string;
  watch?: UseFormWatch<FieldValues>;
  isPassword?: boolean;
  readOnly?: boolean;
  adornmentAlignment?: 'auto' | 'center';
  dialogStyle?: boolean;
}

export const TextInputNew = ({
  name,
  testcy,
  control,
  width = '200px',
  disabled = false,
  appearance = FormElementAppearance.Old,
  size = FormElementSize.Medium,
  validation = {},
  defaultValue = '',
  label,
  placeholder = '',
  darkPlaceholder = false,
  endAdornment,
  startAdornment,
  formatFn,
  formatOnBlurFn,
  onChange,
  onBlur,
  onKeyUp,
  onKeyDown,
  onFocus = e => {},
  isPendingValue = false,
  autoFocus = false,
  helperText,
  helperTextAlign = HelperTextAlignment.Left,
  multiline = false,
  rows,
  watch,
  isPassword = false,
  readOnly = false,
  dialogStyle = false,
  adornmentAlignment = 'auto',
}: Props) => {
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    const subscription = watch
      ? watch(value => {
          if (!isArray(value.disabledValues)) return;
          setIsDisabled(value.disabledValues.includes(name));
        })
      : null;

    return () => subscription && subscription.unsubscribe();
  }, [watch, name]);

  const onChangeHandler = (onChangeProvided: (...event: any[]) => void, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    let value = e.target.value;
    e.stopPropagation();

    if (formatFn) value = formatFn(value);

    onChange?.(value);
    onChangeProvided(value);
  };

  const onBlurHandler = (onBlurProvided: (...event: any[]) => void, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    let value = e.target.value;

    if (formatOnBlurFn) value = formatOnBlurFn(value);

    onBlur?.(value);
    onBlurProvided(value);
  };

  const onKeyUpHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyUp?.(e);
  };

  const getRootInputClasses = (error: any): string => {
    //prettier-ignore
    return [
      'text-input styled-input',
      `helper-text-${error?.message ? 'left' : helperTextAlign}`,
      `adornment-align-${adornmentAlignment}`,
      error?.message ? 'has-error' : '',
      isDisabled ? 'input-disabled' : '',
      readOnly ? 'input-readonly' : '',
      dialogStyle ? 'dialog-style' : '',
      darkPlaceholder ? 'dark-placeholder' : '',
    ].join(' ');
  };

  return (
    <div className={`text-input-container appearance-${appearance} size-${size}`} style={{ width: width }}>
      <FormControl variant="outlined">
        {label && (
          <InputLabel shrink className="input-label" data-testcy={`${testcy}-label`} disabled={disabled}>
            {label}
          </InputLabel>
        )}
        <Controller
          control={control}
          name={name}
          rules={validation}
          defaultValue={defaultValue}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <TextField
              autoComplete="off"
              inputProps={{
                'data-testcy': testcy,
              }}
              id={name}
              type={isPassword ? 'password' : 'text'}
              value={value}
              multiline={multiline}
              rows={rows}
              onChange={e => onChangeHandler(onChange, e)}
              onBlur={e => onBlurHandler(onBlur, e)}
              onKeyUp={e => onKeyUpHandler(e)}
              onKeyDown={e => onKeyDown?.(e)}
              onFocus={e => onFocus?.(e)}
              variant="outlined"
              helperText={error?.message ?? helperText}
              placeholder={placeholder}
              disabled={isDisabled}
              autoFocus={autoFocus}
              classes={{ root: getRootInputClasses(error) }}
              InputProps={{
                readOnly: readOnly,
                startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null,
                endAdornment:
                  endAdornment || isPendingValue ? (
                    <InputAdornment position="end">
                      {endAdornment ?? ''}
                      {isPendingValue ? <LoaderIcon size="tiny" /> : ''}
                    </InputAdornment>
                  ) : null,
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};
