import React from 'react';

type Props = {};

export const MaximizeIcon = (props: Props) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.1482 3.67411L16.4852 3.66296L16.474 8.99999" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.5 4.51624L11.1489 8.86735" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.50028 16.9747L3.16322 16.9858L3.1744 11.6488" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.14844 16.1326L8.49955 11.7815" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
