import { InputFormatFn, formErrorMsg, splitIntoChunks } from '@utils';
import { RegisterOptions, Validate } from 'react-hook-form';

function isControlNumberInvalid(v: string, weights: readonly number[], excludeMod10?: boolean): boolean {
  const digits = v.split('').map(str => Number(str));
  const lastIndex = digits.length - 1;
  const sum = digits.reduce((acc, num, i) => {
    if (i == lastIndex) return acc;
    return acc + num * weights[i];
  }, 0);
  const modulo = sum % 11;
  if (excludeMod10 && modulo == 10) return true;
  const validDigit = modulo == 10 ? 0 : modulo;
  return validDigit != digits[lastIndex];
}

//! REGON
export const regonCharWhitelist = '-\\s0-9';

const regonWeights: { [9]: readonly number[]; [14]: readonly number[] } = {
  [9]: [8, 9, 2, 3, 4, 5, 6, 7],
  [14]: [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8],
};

export function regonValidator(v: string): string | true {
  v = v?.trim();
  if (!v) return true;
  if (v.length != 9 && v.length != 14) return formErrorMsg.incorrectRegonLength;

  //according to https://pl.wikipedia.org/wiki/REGON#Budowa_numeru_REGON
  if (isRegonControlNumberInvalid(v)) return formErrorMsg.incorrectRegonControlNumber;

  return true;
}

function isRegonControlNumberInvalid(v: string): boolean {
  if (v.length == 9) {
    return isControlNumberInvalid(v, regonWeights[9]);
  }
  return isControlNumberInvalid(v, regonWeights[14]);
}

export function formatRegonOnBlur(v: string): string {
  v = v?.trim();
  return v;
}

//! NIP
export const nipCharWhitelist = '-0-9';
const nipWeights: readonly number[] = [6, 5, 7, 2, 3, 4, 5, 6, 7];

export function nipValidator(v: string): string | true {
  v = v?.trim();
  if (!v) return true;
  if (v.length != 10) return formErrorMsg.incorrectNipLength;

  //according to https://pl.wikipedia.org/wiki/Numer_identyfikacji_podatkowej#Znaczenie_numeru
  if (isControlNumberInvalid(v, nipWeights, true)) return formErrorMsg.incorrectNipControlNumber;

  return true;
}

const nipRemoveDashesRegex = /^\d\d\d-\d\d-\d\d-\d\d\d$|^\d\d\d-\d\d\d-\d\d-\d\d$/;
export function formatNipOnBlur(v: string): string {
  v = v?.trim();
  if (nipRemoveDashesRegex.test(v)) {
    v = v.replace(/-/g, '');
  }
  return v;
}

//! FOREIGN TAX NUMBER
export const foreignTaxNumberCharWhitelist = '-0-9a-zA-Z!"#$%&\'()*+,./:;<=>?@\\\\[\\]\\^_`{|}~';

export function foreignTaxNumberValidator(v: string): string | true {
  v = v?.trim();
  if (!v) return true;
  if (v.length > 18) return formErrorMsg.foreignTaxNumberTooLong;

  return true;
}

export function formatForeignTaxNumberOnBlur(v: string): string {
  v = v?.trim();
  return v;
}

//! PHONE
export const phoneCharWhitelist = '-+\\s0-9()';
const phoneHelperRegex = /[^+0-9]/g;
/**
 * Matches a phone number with or without an international code (formatted as +X, +XX, or +XXX) and 8-10 digits.
 */
const phoneRegex = /^(\+\d\d{0,2}(?:\s)?)?\d{8,10}$/;

export function phoneValidator(v: string): string | true {
  v = v?.trim();
  if (!v) return true;
  v = v.replace(phoneHelperRegex, '');

  if (!v.match(phoneRegex)) return formErrorMsg.incorrectPhone;

  return true;
}

const phoneFormatWhitelistRegex = /[^+\s0-9]/g;
const phoneFormatRegex = /\+48 ?(\d{3})(\d{3})(\d{3})$/;
export function formatPhoneOnBlur(v: string): string {
  v = v?.trim();
  if (!v) return v;
  v = v.replace(phoneFormatWhitelistRegex, '');
  v = v.replace(/\s+/g, ' ');
  v = v.replace(/( \d+) /g, '$1');
  if (v.match(phoneFormatRegex)) v = v.replace(phoneFormatRegex, '+48 $1-$2-$3');
  return v;
}

//! E-MAIL
export const emailCharWhitelist = "@.a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-";
const emailRegexp = /^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export function emailValidator(v: string): string | true {
  v = v?.trim();
  if (!v) return true;
  if (!v.match(emailRegexp)) return formErrorMsg.invalidMail;

  const parts = v.split('@');
  if (parts[0].length > 64) return formErrorMsg.tooLongEmailLocal;
  if (parts[1].length > 255) return formErrorMsg.tooLongEmailDomain;

  return true;
}

export function formatEmailOnBlur(v: string): string {
  v = v?.trim();
  return v;
}

//! IBAN (bank account)
export const bankCharWhitelist = '\\s0-9';
const bankCharWhitelistRegex = new RegExp(`[^${bankCharWhitelist}]`, 'g');
const bankRegex = /^\d{26}$/i;
const bankFormatRegex = /(\d\d)(\d+)/i;

export function cleanupBank(v: string): string {
  v = v?.trim();
  if (!v) return v;
  v = v.replace(bankCharWhitelistRegex, '');
  v = v.replace(/\s/g, '');
  return v;
}

export function bankValidator(v: string): string | true {
  v = cleanupBank(v);
  if (!v) return true;

  if (!v.match(bankRegex)) return formErrorMsg.incorrectIBANLength;

  return true;
}

export function formatBankOnBlur(v: string): string {
  v = v?.trim();
  if (!v) return v;
  v = v.replace(bankCharWhitelistRegex, '');
  v = v.replace(/\s/g, '');
  const parts = v.match(bankFormatRegex);

  if (!parts) return v;

  return [parts[1], splitIntoChunks(parts[2], 4)].flat().join(' ');
}
export function getFormattedAccountNo(v: string): string {
  return formatBankOnBlur(v);
}

//! ZIP CODE
export const postalCodeCharWhitelist = '-0-9';
const postalCodeRegex = /^(\d\d)-?(\d\d\d)$/;

export function postalCodeValidator(v: string): string | true {
  v = v?.trim();
  if (!v) return true;

  if (!v.match(postalCodeRegex)) return formErrorMsg.postalCode;

  return true;
}

export function formatPostalCodeOnBlur(v: string): string {
  if (!v) return v;
  v = v.replace(/\D/g, '');

  const parts = v.match(postalCodeRegex);

  if (!parts) return v;

  return `${parts[1]}-${parts[2]}`;
}

//! FOREIGN ZIP CODE
export const foreignPostalCodeCharWhitelist = '-0-9 a-zA-Z';

export function foreignPostalCodeValidator(v: string): string | true {
  v = v?.trim();
  if (!v) return true;

  if (v.length > 20) return formErrorMsg.foreignPostalCodeTooLong;

  return true;
}

export function foreignFormatPostalCodeOnBlur(v: string): string {
  v = v?.trim();
  return v;
}

//! PESEL
export const peselCharWhitelist = '0-9';
const peselRegex = /^\d{11}$/;

export function peselValidator(v: string): string | true {
  v = v?.trim();
  if (!v) return true;

  if (!v.match(peselRegex)) return formErrorMsg.incorrectPeselLength;

  return true;
}

export function formatPeselOnBlur(v: string): string {
  return v?.replace(/\D/g, '');
}

//! OTHER UTILS
export const SpecialInputType = {
  NIP: 'nip',
  ForeignTaxNumber: 'foreign-tax-number',
  Regon: 'regon',
  Phone: 'phone',
  Email: 'email',
  BankAccount: 'bank',
  PostalCode: 'postalcode',
  ForeignPostalCode: 'foreign-postalcode',
  Pesel: 'pesel',
  Custom: 'custom',
} as const;
export type SpecialInputType = typeof SpecialInputType[keyof typeof SpecialInputType];

type ValidatorFnInObject = { validate?: (v: string) => string | true };

function _getValidationForType(type: SpecialInputType): ValidatorFnInObject {
  if (type == SpecialInputType.Custom) return {};
  let validate;
  switch (type) {
    case SpecialInputType.NIP:
      validate = nipValidator;
      break;
    case SpecialInputType.ForeignTaxNumber:
      validate = foreignTaxNumberValidator;
      break;
    case SpecialInputType.Regon:
      validate = regonValidator;
      break;
    case SpecialInputType.Phone:
      validate = phoneValidator;
      break;
    case SpecialInputType.Email:
      validate = emailValidator;
      break;
    case SpecialInputType.BankAccount:
      validate = bankValidator;
      break;
    case SpecialInputType.PostalCode:
      validate = postalCodeValidator;
      break;
    case SpecialInputType.ForeignPostalCode:
      validate = foreignPostalCodeValidator;
      break;
    case SpecialInputType.Pesel:
      validate = peselValidator;
      break;

    default:
      throw new Error(`Unexpected special input type "${type}"`);
  }
  return {
    validate,
  };
}
export function mergeValidationsForType(type: SpecialInputType, validation: RegisterOptions): RegisterOptions {
  const validationFromType = _getValidationForType(type);

  if (validationFromType.validate && validation.validate && validation.validate instanceof Function) {
    const validationFromTypeFn = validationFromType.validate;
    const validationFromPropsFn = validation.validate;
    const mergedValidation = v => {
      const result1 = validationFromTypeFn(v);
      if (result1) return result1;
      return validationFromPropsFn(v);
    };
    return {
      ...validation,
      validate: mergedValidation,
    };
  }
  return {
    ...validation,
    ...validationFromType,
  };
}

export function getCharWhitelistRegexForType(type: SpecialInputType): RegExp | null {
  if (type == SpecialInputType.Custom) return null;

  let whitelist: string;
  switch (type) {
    case SpecialInputType.NIP:
      whitelist = nipCharWhitelist;
      break;
    case SpecialInputType.ForeignTaxNumber:
      whitelist = foreignTaxNumberCharWhitelist;
      break;
    case SpecialInputType.Regon:
      whitelist = regonCharWhitelist;
      break;
    case SpecialInputType.Phone:
      whitelist = phoneCharWhitelist;
      break;
    case SpecialInputType.Email:
      whitelist = emailCharWhitelist;
      break;
    case SpecialInputType.BankAccount:
      whitelist = bankCharWhitelist;
      break;
    case SpecialInputType.PostalCode:
      whitelist = postalCodeCharWhitelist;
      break;
    case SpecialInputType.ForeignPostalCode:
      whitelist = foreignPostalCodeCharWhitelist;
      break;
    case SpecialInputType.Pesel:
      whitelist = peselCharWhitelist;
      break;

    default:
      throw new Error(`Unexpected special input type "${type}"`);
  }
  return new RegExp(`[^${whitelist}]`, 'gi');
}

export function mergeFormatOnBlurFnsForType(type: SpecialInputType, fn?: InputFormatFn): InputFormatFn | undefined {
  if (type == SpecialInputType.Custom) return fn;

  let fn1: InputFormatFn;
  switch (type) {
    case SpecialInputType.NIP:
      fn1 = formatNipOnBlur;
      break;
    case SpecialInputType.ForeignTaxNumber:
      fn1 = formatForeignTaxNumberOnBlur;
      break;
    case SpecialInputType.Regon:
      fn1 = formatRegonOnBlur;
      break;
    case SpecialInputType.Phone:
      fn1 = formatPhoneOnBlur;
      break;
    case SpecialInputType.Email:
      fn1 = formatEmailOnBlur;
      break;
    case SpecialInputType.BankAccount:
      fn1 = formatBankOnBlur;
      break;
    case SpecialInputType.PostalCode:
      fn1 = formatPostalCodeOnBlur;
      break;
    case SpecialInputType.ForeignPostalCode:
      fn1 = foreignFormatPostalCodeOnBlur;
      break;
    case SpecialInputType.Pesel:
      fn1 = formatPeselOnBlur;
      break;

    default:
      throw new Error(`Unexpected special input type "${type}"`);
  }
  return (v: string) => {
    v = fn1(v);
    v = fn?.(v) ?? v;
    return v;
  };
}
