import React, { MouseEvent } from 'react';
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Tooltip } from '@mui/material';
import { useInvoiceStore } from '@store';
import './favoriteIcons.scoped.scss';
import { HeartIcon } from '@components';

interface Props {
  name: string;
  testcy: string;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  value: string;
  tooltipMessageFavoriteIcons?: string;
  tooltipMessageFavoriteBorderIcon?: string;
  disabled?: boolean;
  isFavorite?: boolean;
}

export const FavoriteIcons = ({
  name,
  testcy,
  setValue,
  watch,
  value,
  tooltipMessageFavoriteBorderIcon,
  tooltipMessageFavoriteIcons,
  disabled,
  isFavorite = false,
}: Props) => {
  const watchedValue = watch(name);
  const { setIsFavoriteClicked } = useInvoiceStore();

  const onIconClick = (e: MouseEvent<HTMLElement>, v: boolean) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    setValue(name, v ? value : '');
    setIsFavoriteClicked(true);
  };

  return (
    <div className={`favorite-icon-wrapper`} key={`${watchedValue}`}>
      {watchedValue === value || isFavorite ? (
        <Tooltip title={tooltipMessageFavoriteIcons}>
          <button type="button" data-testcy={`${testcy} ${testcy}-selected`} onClick={e => onIconClick(e, false)}>
            <HeartIcon isFilled size="16" colorType={disabled ? 'disabled' : 'black'} />
          </button>
        </Tooltip>
      ) : (
        <Tooltip title={tooltipMessageFavoriteBorderIcon}>
          <button type="button" data-testcy={`${testcy} ${testcy}-unselected`} onClick={e => onIconClick(e, true)}>
            <HeartIcon size="16" colorType={disabled ? 'disabled' : 'black'} />
          </button>
        </Tooltip>
      )}
    </div>
  );
};
