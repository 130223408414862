import { DuplicatedFile } from '@components';
import { FileHashesRequest } from '@interfaces';
import { httpClient } from '@lib';
import { APIFile, DocumentFileCategoryEnum } from '@utils';
import { AxiosResponse } from 'axios';

export const getInboxFiles = async (companyId: string, params: { categories: DocumentFileCategoryEnum[] }) => {
  try {
    const response: AxiosResponse<APIFile[]> = await httpClient.post(`/Companies/${companyId}/files/search`, params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const uploadInboxFiles = async (companyId: string, files: FormData) => {
  try {
    const response: AxiosResponse<APIFile[]> = await httpClient.post(`/Companies/${companyId}/files`, files);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const deleteInboxFile = async (id: string) => {
  try {
    await httpClient.delete(`/Companies/files/${id}`);
  } catch (e) {
    throw e?.response?.data;
  }
};

export const downloadInboxFile = async (id: string) => {
  try {
    const response: AxiosResponse<Blob> = await httpClient.get(`/Companies/files/${id}`, { responseType: 'blob' });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const addCategoryToFile = async (id: number, category: DocumentFileCategoryEnum) => {
  try {
    const response: AxiosResponse = await httpClient.put(`/Companies/files/${id}/category/?category=${category}`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const compareInboxHashes = async (body: FileHashesRequest) => {
  try {
    const response: AxiosResponse<DuplicatedFile[]> = await httpClient.post(`/Companies/files/hashes`, body);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
