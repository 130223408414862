import React, { PropsWithChildren } from 'react';

import './invoiceSection.scoped.scss';

interface Props {
  title?: string;
}

export const InvoiceSection = ({ title, children }: PropsWithChildren<Props>) => {
  return (
    <div className="section">
      {title && <h3>{title}</h3>}
      {children}
    </div>
  );
};
