import React from 'react';
import { DocumentFileCategoryEnum, FileObject } from '@utils';
import './sharedTableList.scoped.scss';
import { SharedTableListHeader } from './components/sharedTableListHeader';
import { InvoiceStatus, Option } from '@interfaces';
import { SharedTableListRow } from './components/sharedTableListRow';
import { CostFile } from '@services';
import { HeaderData } from './sharedTableList.types';
import { LoaderIcon } from '@components';

interface Props {
  testcy: string;
  rowsWithoutScroll?: number;
  actionMenuItems: Option[];
  files: CostFile[] | FileObject[];
  isPending: boolean;
  showMenu?: boolean;
  header?: HeaderData;
  tagOptions?: Option[];
  statusSelected?: InvoiceStatus[] | DocumentFileCategoryEnum[];
  selectedRowId?: string;
  onSetHeaderFilter?: (filter: InvoiceStatus[] | DocumentFileCategoryEnum[]) => void;
  onClickMenuItem?: (action: string, item: CostFile | FileObject) => void;
  onChangeCategoryFile?: (value: DocumentFileCategoryEnum, id: number) => void;
  onDigitalizeClick?: (item: CostFile | FileObject) => void;
  onPreviewClick?: (item: CostFile | FileObject) => void;
  onDeleteClick?: (item: CostFile | FileObject) => void;
  onDownloadClick?: (item: CostFile | FileObject) => void;
  onFilesScroll?: (e: React.UIEvent<HTMLElement>) => void;
  onClickRow?: (id: string) => void;
}

export const SharedTableList = ({
  testcy,
  rowsWithoutScroll,
  actionMenuItems,
  showMenu = false,
  files,
  header,
  tagOptions,
  statusSelected,
  selectedRowId,
  isPending = false,
  onSetHeaderFilter,
  onClickMenuItem,
  onChangeCategoryFile,
  onDigitalizeClick,
  onPreviewClick,
  onDeleteClick,
  onDownloadClick,
  onFilesScroll,
  onClickRow,
}: Props) => {
  return (
    <>
      <SharedTableListHeader
        testcy={`${testcy}-header`}
        showMenu={showMenu}
        header={header}
        actionMenuItems={actionMenuItems}
        onSetHeaderFilter={onSetHeaderFilter}
      />
      <div className={`${rowsWithoutScroll < files?.length && `table-files-wrapper-${rowsWithoutScroll}`} table-files-wrapper`} onScroll={onFilesScroll}>
        {files?.length > 0 ? (
          files.map((item: CostFile | FileObject, index: number) => (
            <SharedTableListRow
              testcy={`${testcy}-row-${index}`}
              key={item.id}
              item={item}
              header={{ name: !!header.name, tag: !!header.tag, date: !!header.date }}
              actionMenuItems={actionMenuItems}
              showMenu={showMenu}
              onClickMenuItem={onClickMenuItem}
              tagOptions={tagOptions}
              onChangeCategoryFile={onChangeCategoryFile}
              selectedRowId={selectedRowId}
              onDigitalizeClick={() => onDigitalizeClick(item.id)}
              onPreviewClick={() => onPreviewClick(item)}
              onDeleteClick={() => onDeleteClick(item)}
              onDownloadClick={() => onDownloadClick(item)}
              onClickRow={() => onClickRow?.(item.id)}
              isClickable={!!onClickRow}
            />
          ))
        ) : (
          <div className="inbox-empty-state">
            {isPending && <LoaderIcon size="small"></LoaderIcon>}
            {!isPending && <span>{`${!!statusSelected?.length ? 'Brak plików spełniających kryteria wyszukiwania' : 'Nie ma żadnych plików.'}`}</span>}
          </div>
        )}
      </div>
    </>
  );
};
