import React from 'react';

import './toggle.scoped.scss';
import { Control, Controller } from 'react-hook-form';

interface Props {
  name: string;
  testcy: string;
  disabled?: boolean;
  defaultValue?: boolean;
  control?: Control;
}

export const Toggle = ({ name, testcy, disabled, defaultValue, control }: Props) => {
  return (
    <div className="toggle-container">
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange: onChangeProvided, value } }) => {
          return (
            <>
              <input
                className="toggle-input"
                type="checkbox"
                data-testcy={testcy}
                name={name}
                id={name}
                defaultChecked={value}
                disabled={disabled}
                onChange={onChangeProvided}
              />
            </>
          );
        }}
      />
    </div>
  );
};
