import { displaySnackBarMessage } from '@lib';
import { formatFileSize, pluralize } from './helpers';

export const snackbarMessagesTexts = Object.freeze({
  invoiceCreated: 'Faktura została utworzona',
  invoiceCorretionCreated: 'Korekta faktury została utworzona',
  invoiceEdited: 'Faktura została zedytowana',
  costEdited: 'Faktura kosztowa została zedytowana',
  invoiceNeedCorrectionBeforeSave: 'Faktura nie może zostać zapisana, niezbędne jest poprawne wypełnienie formularza',
  invoiceWasConfirmed: 'Faktura została wystawiona',
  invoicesWasConfirmed: 'Faktury zostały wystawione',
  invoiceDeleted: 'Faktura została usunięta',
  invoiceSend: 'Faktura została wysłana',
  invoicesSend: 'Faktury zostały wysłane',
  invoicesCheckedDifferentStatuses: 'Wybrano faktury o różnych statusach',
  invoiceFilterErrorDate: 'Wybrano daty o błędnym zakresie',
  invoiceSendEmail: 'Wiadomość została wysłana',
  invoiceSendReminder: 'Upomnienie zostało wysłane',
  invoiceRecordAmountPaid: 'Wpłata została zarejestrowana',
  contractorCreated: 'Kontrahent został utworzony',
  contractorEdited: 'Kontrahent został zedytowany',
  formEdited: 'Formularz nie może zostać zapisany, niezbędne jest poprawne wypełnienie danych',
  contractorDeleted: 'Kontrahent został usunięty',
  companyWasCreated: 'Firma została utworzona',
  companyWasEdited: 'Dane firmy zostały zmienione',
  bankAccountAdded: 'Konto bankowe zostało dodane',
  bankAccountMainAccount: 'Konto bankowe zostało ustawione jako główne',
  bankAccountUnSetMainAccount: 'Konto główne zostało usunięte z ulubionych',
  invoiceMarkedAsSent: 'Faktura została oznaczona jako wysłana',
  gusDataWasFetched: 'Dane z GUS zostały pobrane',
  gusNoData: 'Podmiot nie został znaleziony w bazie GUS',
  invalidFileFormat: 'Niedozwolony format pliku',
  costsToManyFiles: 'Maksymalnie 20 plików może być dodane jednocześnie',
  costsUploadedFiles: 'Pliki zostały dodane',
  costsUploadedFile: 'Plik został dodany',
  costsSendToInsert: 'Faktura kosztowa została wysłana',
  costsMarkAsPaid: 'Faktura kosztowa została opłacona',
  costsDocumentDeleted: 'Dokument kosztowy został usunięty',
  costsInvoiceDeleted: 'Faktura została usunięta',
  taxValuesUpdate: 'Dane podatkowe zostały zapisane',
  settingsCredentials: 'Dane dostępowe do bazy danych InsERT zostały zapisane',
  settingAccountingPermission: 'Firma księgowa została dodana',
  employeeCreated: 'Pracownik został utworzony',
  employeeEdited: 'Pracownik został zedytowany',
  employeeUnregister: 'Pracownik został wyrejestrowany',
  employeeUploadedFiles: 'Pliki zostały dodane',
  employeeUploadedFile: 'Plik został dodany',
  uploadedFiles: 'Pliki zostały dodane',
  uploadedFile: 'Plik został dodany',
  employeeWrongFormat: 'Niedozwolony format pliku',
  employeeToManyFiles: 'Maksymalnie 10 plików może być dodane jednocześnie',
  documentDeleted: 'Dokument został usunięty',
  documentDownload: 'Dokument został pobrany',
  documentChangedCategory: 'Kategoria dokumentu została zmieniona',
  fileDeleted: 'Plik został usunięty',
  downloading: 'Pobieranie...',
  fileDownloaded: 'Plik został pobrany',
  tooManyFiles1: 'Maksymalnie 1 plik może być dodany jednocześnie',
  tooManyFiles234: 'Maksymalnie %% pliki mogą być dodane jednocześnie',
  tooManyFiles5plus: 'Maksymalnie %% plików może być dodane jednocześnie',
  employeeToBigFile: 'Maksymalny rozmiar pliku to',
  employeeDocumentDeleted: 'Plik został usunięty',
  employeeDocumentDownload: 'Plik został pobrany',
  singleFileSize: 'Maksymalny rozmiar pliku to %%MB%%',
  allFilesSize: 'Maksymalny rozmiar wszystkich plików to %%MB%%',
  fileTooLongName: 'Nazwa pliku przekracza dozwoloną liczbę 100 znaków',
  emailSent: 'Wiadomość została wysłana',
  emailNotSent: 'Nie udało się wysłać wiadomości',
  documentConfirmed: 'Dokument został zatwierdzony',
  documentNotConfirmed: 'Nie udało się zatwierdzić dokumentu',
  costsExportCSV: 'Dokument został eksportowany do pliku CSV',
  invitationSent: 'Zaproszenie zostało wysłane',
  invitationNotSent: 'Nie udało się wysłać zaproszenia',
  invitationAlreadyAccepted: 'Przedsiębiorca już korzysta z aplikacji',
  bankAccountBalanceUpdated: 'Saldo konta bankowego zostało zaktualizowane',
  accountDeleted: 'Konto zostało usunięte',
  nipIsRequired: 'Do pobrania danych wymagany jest numer NIP'
});

export const snackbarMessagesHandler = Object.freeze({
  invoiceCreatedSuccess: () => displaySnackBarMessage('success', snackbarMessagesTexts.invoiceCreated),
  invoiceCorrectionCreatedSuccess: () => displaySnackBarMessage('success', snackbarMessagesTexts.invoiceCorretionCreated),
  invoiceEdited: () => displaySnackBarMessage('success', snackbarMessagesTexts.invoiceEdited),
  costEdited: () => displaySnackBarMessage('success', snackbarMessagesTexts.costEdited),
  invoiceFormNeedCorrection: () => displaySnackBarMessage('info', snackbarMessagesTexts.invoiceNeedCorrectionBeforeSave),
  invoicesConfirmed: (plural: boolean) =>
    displaySnackBarMessage('success', plural ? snackbarMessagesTexts.invoicesWasConfirmed : snackbarMessagesTexts.invoiceWasConfirmed),
  invoicesSendToInsert: (plural: boolean) => displaySnackBarMessage('success', plural ? snackbarMessagesTexts.invoicesSend : snackbarMessagesTexts.invoiceSend),
  invoiceDeleted: () => displaySnackBarMessage('success', snackbarMessagesTexts.invoiceDeleted),
  invoiceFilterErrorDate: () => displaySnackBarMessage('error', snackbarMessagesTexts.invoiceFilterErrorDate),
  invoiceSendEmail: () => displaySnackBarMessage('success', snackbarMessagesTexts.invoiceSendEmail),
  invoiceSendReminder: () => displaySnackBarMessage('success', snackbarMessagesTexts.invoiceSendReminder),
  invoiceRecordAmountPaid: () => displaySnackBarMessage('success', snackbarMessagesTexts.invoiceRecordAmountPaid),
  contractorWasCreated: () => displaySnackBarMessage('success', snackbarMessagesTexts.contractorCreated),
  contractorWasEdited: () => displaySnackBarMessage('success', snackbarMessagesTexts.contractorEdited),
  contractorFormNeedCorrection: () => displaySnackBarMessage('info', snackbarMessagesTexts.formEdited),
  contractorWasDeleted: () => displaySnackBarMessage('success', snackbarMessagesTexts.contractorDeleted),
  companyCreated: () => displaySnackBarMessage('success', snackbarMessagesTexts.companyWasCreated),
  companyEdited: () => displaySnackBarMessage('success', snackbarMessagesTexts.companyWasEdited),
  bankAccountAdded: () => displaySnackBarMessage('success', snackbarMessagesTexts.bankAccountAdded),
  bankAccountMainAccount: () => displaySnackBarMessage('success', snackbarMessagesTexts.bankAccountMainAccount),
  bankAccountUnsetMainAccount: () => displaySnackBarMessage('info', snackbarMessagesTexts.bankAccountUnSetMainAccount),
  invoiceMarkedAsSent: () => displaySnackBarMessage('success', snackbarMessagesTexts.invoiceMarkedAsSent),
  gusDataWasFetched: () => displaySnackBarMessage('success', snackbarMessagesTexts.gusDataWasFetched),
  gusNoData: () => displaySnackBarMessage('error', snackbarMessagesTexts.gusNoData),
  costsUploadDoc: (plural: boolean) =>
    displaySnackBarMessage('success', plural ? snackbarMessagesTexts.costsUploadedFiles : snackbarMessagesTexts.costsUploadedFile),
  taxValuesUpdated: () => displaySnackBarMessage('success', snackbarMessagesTexts.taxValuesUpdate),
  costsSendToInsert: () => displaySnackBarMessage('success', snackbarMessagesTexts.costsSendToInsert),
  costsDocumentDeleted: () => displaySnackBarMessage('success', snackbarMessagesTexts.costsDocumentDeleted),
  costsInvoiceDeleted: () => displaySnackBarMessage('success', snackbarMessagesTexts.costsInvoiceDeleted),
  settingsCredentials: () => displaySnackBarMessage('success', snackbarMessagesTexts.settingsCredentials),
  settingAccountingPermission: () => displaySnackBarMessage('success', snackbarMessagesTexts.settingAccountingPermission),
  employeeCreated: () => displaySnackBarMessage('success', snackbarMessagesTexts.employeeCreated),
  employeeEdited: () => displaySnackBarMessage('success', snackbarMessagesTexts.employeeEdited),
  employeeUnregister: () => displaySnackBarMessage('success', snackbarMessagesTexts.employeeUnregister),
  employeeWrongFormat: () => displaySnackBarMessage('info', snackbarMessagesTexts.invalidFileFormat),
  employeeUploadDoc: (plural: boolean) =>
    displaySnackBarMessage('success', plural ? snackbarMessagesTexts.employeeUploadedFiles : snackbarMessagesTexts.employeeUploadedFile),
  employeeDocumentDeleted: () => displaySnackBarMessage('success', snackbarMessagesTexts.employeeDocumentDeleted),
  employeeDocumentDownload: () => displaySnackBarMessage('success', snackbarMessagesTexts.employeeDocumentDownload),
  fileDeleted: () => displaySnackBarMessage('success', snackbarMessagesTexts.fileDeleted),
  downloading: () => displaySnackBarMessage('info', snackbarMessagesTexts.downloading),
  fileDownloaded: () => displaySnackBarMessage('success', snackbarMessagesTexts.fileDownloaded),
  inboxFileUpload: (plural: boolean) => displaySnackBarMessage('success', plural ? snackbarMessagesTexts.uploadedFiles : snackbarMessagesTexts.uploadedFile),
  tooManyFiles: (maxAmount: number, actualAmount: number) => {
    const actualAmountString = actualAmount ? ` (przesłano ${actualAmount})` : '';
    const stringAmount = maxAmount.toString();

    const message = pluralize(
      maxAmount,
      snackbarMessagesTexts.tooManyFiles1,
      snackbarMessagesTexts.tooManyFiles234.replace('%%', stringAmount),
      snackbarMessagesTexts.tooManyFiles5plus.replace('%%', stringAmount)
    );

    displaySnackBarMessage('info', message + actualAmountString);
  },
  allFilesSize: (size: number, totalSize: number) => {
    const formattedSize = totalSize && formatFileSize(totalSize);
    const finalMessage = snackbarMessagesTexts.allFilesSize.replace('%%', String(size)).replace('%%', formattedSize ? ` (przesłano ${formattedSize})` : '');

    displaySnackBarMessage('info', finalMessage);
  },
  singleFileSize: (size: number, totalSize: number) => {
    const formattedSize = totalSize && formatFileSize(totalSize);
    const finalMessage = snackbarMessagesTexts.singleFileSize.replace('%%', String(size)).replace('%%', formattedSize ? ` (przesłano ${formattedSize})` : '');

    displaySnackBarMessage('info', finalMessage);
  },
  invalidFileFormat: () => displaySnackBarMessage('info', snackbarMessagesTexts.invalidFileFormat),
  fileTooLongName: () => displaySnackBarMessage('info', snackbarMessagesTexts.fileTooLongName),
  emailSent: (success: boolean) => {
    if (success) {
      return displaySnackBarMessage('success', snackbarMessagesTexts.emailSent);
    }
    displaySnackBarMessage('error', snackbarMessagesTexts.emailNotSent);
  },
  documentConfirm: (success: boolean) => {
    if (success) {
      return displaySnackBarMessage('success', snackbarMessagesTexts.documentConfirmed);
    }
    displaySnackBarMessage('error', snackbarMessagesTexts.documentNotConfirmed);
  },
  costsExportCSV: () => displaySnackBarMessage('success', snackbarMessagesTexts.costsExportCSV),
  documentChangedCategory: () => displaySnackBarMessage('success', snackbarMessagesTexts.documentChangedCategory),
  costsMarkAsPaid: () => displaySnackBarMessage('success', snackbarMessagesTexts.costsMarkAsPaid),
  invitationSent: (success: boolean) => {
    if (success) {
      return displaySnackBarMessage('success', snackbarMessagesTexts.invitationSent);
    }
    displaySnackBarMessage('error', snackbarMessagesTexts.invitationNotSent);
  },
  invitationAlreadyAccepted: () => displaySnackBarMessage('info', snackbarMessagesTexts.invitationAlreadyAccepted),
  bankAccountBalanceUpdated: () => displaySnackBarMessage('success', snackbarMessagesTexts.bankAccountBalanceUpdated),
  accountDeleted: () => displaySnackBarMessage('success', snackbarMessagesTexts.accountDeleted),
  nipIsRequired: () => displaySnackBarMessage('error', snackbarMessagesTexts.nipIsRequired),
});
