import { ColumnDataItem } from '@components';
import { ContractorInvoicingDataBase } from '@interfaces';
import { AccountingTemplate, ContractorAccountingSettings, CostAccountingSettings, VatTemplate } from '@services';
import { FieldValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

export const generateContractorAddress = (data: ContractorInvoicingDataBase): ColumnDataItem[] => {
  const columnData = [
    {
      id: 'street',
      key: 'Ulica i numer',
      value: data.address?.street || '',
    },
    {
      id: 'city',
      key: 'Miejscowość',
      value: data.address?.city || '',
    },
    {
      id: 'zipCode',
      key: 'Kod pocztowy',
      value: data.address?.zipCode || '',
    },
  ];
  return columnData;
};

export const generateContractorData = (data: ContractorInvoicingDataBase): ColumnDataItem[] => {
  if (data.nip) {
    const columnData = [
      {
        id: 'companyName',
        key: 'Pełna nazwa firmy',
        value: data.name || '',
      },
      {
        id: 'nip',
        key: 'Numer NIP',
        value: data.nip,
        hide: false,
      },
    ];
    return columnData;
  }
  const columnUserData = [
    {
      id: 'contractorName',
      key: 'Imię i nazwisko',
      value: data.name || '',
    },
  ];
  return columnUserData;
};

export const checkIsAccountingSettingsOnList = (
  accountingSettings: ContractorAccountingSettings,
  accountingTemplateStoreOptions: AccountingTemplate[],
  vatTemplateStoreOptions: VatTemplate[],
  setValue: UseFormSetValue<FieldValues>,
  trigger: UseFormTrigger<FieldValues>,
  accountingSettingsStore: CostAccountingSettings
) => {
  const accountingTemplateSelected = accountingTemplateStoreOptions?.find(template => template?.id === accountingSettings?.accountingTemplateId);
  const vatTemplateSelected = vatTemplateStoreOptions?.find(template => Number(template?.id) === accountingSettings?.vatTemplateId);

  if (!accountingSettingsStore?.accountingTemplate?.id) {
    if (accountingTemplateSelected?.id) {
      setValue('vatAccountingScheme', `${accountingTemplateSelected?.code} ${accountingTemplateSelected?.description}`);
      trigger('vatAccountingScheme');
    } else {
      setValue('vatAccountingScheme', '');
    }
  }
  if (!accountingSettingsStore?.vatTemplate?.id) {
    if (vatTemplateSelected?.id) {
      setValue('vatTemplate', `${vatTemplateSelected?.id}`);
      trigger('vatTemplate');
    } else {
      setValue('vatTemplate', '');
    }
  }
};
