import React, { Ref } from 'react';
import { format, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';
import { MessageContent } from '../MessageContent/messageContent';
import { ChatMessage } from '../../model/chatMessage';

import './message.scoped.scss';

type Props = {
  message: ChatMessage;
  containerRef: Ref<HTMLElement>;
};

export const Message = ({ message }: Props) => {
  const { dataType, createdAt, data, isDeleted, isIncoming, metadata, isConsequent, isDateChanged } = message;

  const outgoingClassName = isIncoming ? '' : 'outgoing';
  const spacingClassName = !isConsequent || isDateChanged ? 'additional-spacing' : '';

  return (
    <div className="message-reserved-row">
      {isDateChanged && <p className="message-date-header">{format(parseISO(createdAt), 'PPP', { locale: pl })}</p>}
      <div className={`message-body ${outgoingClassName} ${spacingClassName}`}>
        <MessageContent type={dataType} isDeleted={isDeleted} data={data} mentionedInvoiceNumber={metadata?.number} isIncoming={isIncoming} />
        {!isDeleted && (
          <div className="bottom-line">
            <span className="message-date">{format(parseISO(createdAt), 'p', { locale: pl })}</span>
          </div>
        )}
      </div>
    </div>
  );
};
