import { dateFormatter } from '@lib';
import React from 'react';
import { PlusIcon } from 'src/components/icons';
import './selectDayButtonsToolbar.scoped.scss';

interface Props {
  daysList: number[];
  testcy: string;
  onSelectDay: (v: number) => void;
  selectedValue?: Date;
  dayToDiff?: Date;
  defaultNumberDueDays?: number;
}

export const SelectDayButtonsToolbar = ({ daysList, testcy, onSelectDay, selectedValue, dayToDiff, defaultNumberDueDays }: Props): JSX.Element => {
  const checkIsSelected = (dayNumber: number): boolean => {
    const number = selectedValue ? dateFormatter.diffBetweenDates(selectedValue, dayToDiff) : defaultNumberDueDays;
    return dayNumber === number;
  };

  return (
    <div className="select-day-toolbar-wrapper">
      {daysList.map((d: number) => {
        const dayString = d === 1 ? 'dzień' : 'dni';
        return (
          <button
            className={`toolbar-button ${checkIsSelected(d) ? 'selected ' : ''}`}
            key={d}
            data-testcy={`${testcy}-day-toolbar-${d}`}
            onClick={() => onSelectDay(d)}
          >
            <span className="button-icon">
              <PlusIcon />
            </span>
            <span className="button-label">
              {d} {dayString}
            </span>
          </button>
        );
      })}
    </div>
  );
};
