import React from 'react';

import './settings.scss';

interface Props {
  title?: string;
  subtitle?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  maxWidth?: string;
  noBorder?: boolean;
  image?: React.ReactNode;
  saveImageSpace?: boolean;
}

export const SettingsSubSection = ({ title, subtitle, children, maxWidth, noBorder, image, saveImageSpace }: Props) => {
  const getRootClasses = () => {
    return ['settings-subsection', noBorder && 'no-border'].join(' ');
  };
  return (
    <div className={getRootClasses()}>
      {children && (
        <div className="subsection-content" style={maxWidth && { maxWidth }}>
          {children}
        </div>
      )}
      <div className={`subsection-left-column ${subtitle ? 'align-top' : 'align-center'}`}>
        {(image || saveImageSpace) && <div className="subsection-image">{image}</div>}
        <div className="subsection-title-container">
          {title && <h4>{title}</h4>}
          {subtitle && <div className="subsection-subtitle">{subtitle}</div>}
        </div>
      </div>
    </div>
  );
};
