import React, { useEffect, useRef, useState } from 'react';
import { Message } from '../Message/message';
import './messageList.scoped.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoaderIcon } from '@components';
import { ChatMessageCollection } from '../../model/chatMessage';

type Props = {
  messages: ChatMessageCollection;
};

export const MessageList = ({ messages }: Props) => {
  const containerRef = useRef<HTMLDivElement>();

  const currentLatestMessageId = messages.data[0]?.messageId ?? '';
  const [previousLatestMessageId, setPreviousLatestMessageId] = useState(currentLatestMessageId);

  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    if (previousLatestMessageId !== currentLatestMessageId) {
      element.scrollIntoView(false);
      setPreviousLatestMessageId(currentLatestMessageId);
    }
  }, [containerRef, currentLatestMessageId, previousLatestMessageId]);

  return (
    <div id="chat-inifinite-scroll-container" className="inifinite-scroll-container">
      <InfiniteScroll
        dataLength={messages.data.length}
        hasMore={messages.hasNextPage}
        next={messages.onNextPage}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
        loader={
          <div key={0} className="message-list-loader-cotainer">
            <LoaderIcon />
          </div>
        }
        inverse={true}
        scrollableTarget={'chat-inifinite-scroll-container'}
      >
        <div className="message-list-container" ref={containerRef} data-qa-anchor="message-list">
          {messages.data.map(message => (
            <Message key={message.messageId} message={message} containerRef={containerRef} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};
