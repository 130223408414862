import React, { createRef } from 'react';
import { AddedTab } from './components';
import './sharedCostList.scoped.scss';

export const SharedCostList = () => {
  const refTotalAmountClick = createRef<any>();

  return (
    <div className="standard-layout-spacing-g cost-list-wrapper">
      <div className="cost-list">
        <div className="header">
          <h1 className="title-g">Faktury kosztowe</h1>
        </div>
        <div className="tabs-content">
          <AddedTab ref={refTotalAmountClick} />
        </div>
      </div>
    </div>
  );
};
