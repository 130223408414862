import React, { PropsWithChildren, useEffect } from 'react';
import { SnackbarProvider as NotistackSnackbarProvider, useSnackbar, OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

type SnackbarHandler = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;

export let snackbarHandler: SnackbarHandler;

const SnackbarHandlerRegisterer = ({ children }: PropsWithChildren) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    snackbarHandler = enqueueSnackbar;
  }, [enqueueSnackbar]);

  return <>{children}</>;
};

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  return (
    <NotistackSnackbarProvider maxSnack={6}>
      <SnackbarHandlerRegisterer>{children}</SnackbarHandlerRegisterer>
    </NotistackSnackbarProvider>
  );
};
