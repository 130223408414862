import React from 'react';
import { ColumnDataItem } from './columnData.utils';
import { LoaderIcon } from '../loaderIcon/loaderIcon';
import './columnData.scoped.scss';

interface Props {
  columnTitle?: string;
  columnData: ColumnDataItem[];
  spacing?: string;
  isPending?: boolean;
}

export const ColumnData = ({ columnTitle, columnData, spacing = '8px', isPending = false }: Props) => {
  return (
    <div className="column-data-wrapper">
      {columnTitle && <p className="title">{columnTitle}</p>}
      {isPending ? (
        <LoaderIcon />
      ) : (
        <div className="rows-wrapper" style={{ gap: `${spacing}` }}>
          {columnData.map(({ key, value, hide, keyUppercase = false, valueUppercase = false, id }) => {
            if (hide) return null;
            return (
              <div className="column-row" key={id}>
                {key && (
                  <p className="key" style={{ textTransform: keyUppercase ? 'uppercase' : 'none' }}>
                    {key}
                  </p>
                )}
                {value && (
                  <p className="value" style={{ textTransform: valueUppercase ? 'uppercase' : 'none' }}>
                    {value}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
