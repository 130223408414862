import { CredentialsData } from "@services";


export const toggleMessagesTexts = {
  processing: 'Integracja z InsERT zostanie zatrzymana po ukończeniu bieżących procesów, proszę o odświeżenie strony za kilka minut.',
  processStopped: 'Integracja z InsERT została wstrzymana.',
} as const;

export interface CredentialsFormData {
  serverInsertDataBase: string;
  userNameInsertDataBase: string;
  passwordInsertDataBase: string;
}

export const transformFormDataToCredentialsData = (data: CredentialsFormData): CredentialsData => {
  const formattedData = {
    server: data.serverInsertDataBase,
    username: data.userNameInsertDataBase,
    password: data.passwordInsertDataBase,
  };
  return formattedData;
};

export const transformCredentialsDataToFormData = (username: string, password: string, server: string): CredentialsFormData => {
  return {
    serverInsertDataBase: server || '',
    userNameInsertDataBase: username || '',
    passwordInsertDataBase: password || '',
  };
};
