import { snackbarMessagesHandler } from '@utils';
import { useApiMutation } from '../useApiMutation';

export const useInviteCompany = () => {
  const { mutateAsync } = useApiMutation('inviteCompany');

  const setInviteCompanyId = async (id: number) => {
    const result = await mutateAsync(id);

    switch (result) {
      case 'Success':
      case 'AlreadyExists':
        snackbarMessagesHandler.invitationSent(true);
        break;
      case 'Failure':
        snackbarMessagesHandler.invitationSent(false);
        break;
      case 'AlreadyAccepted':
        snackbarMessagesHandler.invitationAlreadyAccepted();
        break;
      default:
        snackbarMessagesHandler.invitationSent(false);
        break;
    }
  };

  return {
    setInviteCompanyId,
  };
};
