import { useMemo } from 'react';

type UseTooltipPositionProps<TData> = {
  data: TData[];
  getX: (d: TData) => number;
  getY: (d: TData) => number;
  chartHeigth: number;
  tooltipXPosition: number;
};

export const useTooltipYPosition = <TData>({ data, getX, getY, chartHeigth, tooltipXPosition }: UseTooltipPositionProps<TData>) => {
  const tooltipYPostion = useMemo(() => {
    let tooltipY = chartHeigth;

    data.forEach(d => {
      const x = getX(d) ?? 0;
      if (x - 70 < tooltipXPosition && x + 70 > tooltipXPosition) {
        const y = getY(d) ?? 0;
        if (y < tooltipY) {
          tooltipY = y;
        }
      }
    });

    return tooltipY - 20;
  }, [data, getX, getY, chartHeigth]);

  return tooltipYPostion;
};
