import React from 'react';
import logo from '@assets/logo.svg';

export const SharedCookiesDisabledInfo = () => {
  return (
    <div className="standard-layout-spacing-g info-page-wrapper-g">
      <div className="header">
        <img src={logo} alt="logo" className="logo" />
        <p>WebCount</p>
      </div>

      <div className="content">
        Drogi użytkowniku, do działania tej aplikacji konieczne jest włączenie obsługi plików cookies w ustawieniach
        przeglądarki.
      </div>
    </div>
  );
};
