import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonMainNew, TextInputNew, TheDialog } from '@components';
import { useContractorStore } from '@store';
import { useInvitationSettings } from './hooks';
import { formErrorMsg } from '@utils';
import './invitationSettings.scoped.scss';

interface Props {
  openAddAccountantDialog?: boolean;
  setOpenAddAccountantDialog?: (v: boolean) => void;
}

export const InvitationSettings = ({ openAddAccountantDialog = false, setOpenAddAccountantDialog }: Props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPasteButtonDisabled, setIsPasteButtonDisabled] = useState(true);
  const [clipboard, setClipboard] = useState('');
  const [accountingIdInput, setAccountingIdInput] = useState('');
  const { control, setValue, setError, clearErrors, trigger } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const { ownerMyCompanyData, accountingIdCopyToClipboard } = useContractorStore();
  const { setAccountingId } = useInvitationSettings();

  useEffect(() => {
    if (!openAddAccountantDialog) return;
    onPastClipboard();
  }, [openAddAccountantDialog]);

  const onPastClipboard = async () => {
    if (typeof navigator.clipboard.readText !== 'undefined') {
      await navigator.clipboard.readText().then(clipText => {
        if (clipText.length === 20) {
          setIsPasteButtonDisabled(false);
          setClipboard(clipText);
        }
      });
      return;
    }
    // readText() is not supported in Firefox
    if (!!accountingIdCopyToClipboard) {
      setIsPasteButtonDisabled(false);
      setClipboard(accountingIdCopyToClipboard);
    }
  };

  const onCloseDialog = (isApproved: boolean) => {
    setOpenAddAccountantDialog(false);
    setIsDisabled(true);
    setValue('accountingPermissionId', '');
    clearErrors('accountingPermissionId');
    if (!isApproved) return;
    setAccountingId(accountingIdInput);
  };
  const sendEmailToAccountant = () => {
    setOpenAddAccountantDialog(false);
    window.location.href = 'mailto:';
  };

  return (
    <>
      <TheDialog
        testcy="accounting-company-id"
        open={openAddAccountantDialog}
        onClose={onCloseDialog}
        approveButtonText="Wyślij"
        rejectButtonText={'Anuluj'}
        canApprove={!isDisabled}
        title={'Uzupełnij identyfikator firmy księgowej'}
        additionalButtonText={'Zapytaj swoją księgową o ID'}
        onClickAdditionalButton={sendEmailToAccountant}
        additionalButtonWidth="230px"
        rejectButtonWidth="95px"
        approveButtonWidth="95px"
      >
        <TextInputNew
          testcy="accounting-company-id"
          placeholder="lub wpisz identyfikator firmy"
          control={control}
          width="100%"
          appearance="light"
          dialogStyle
          name="accountingPermissionId"
          onChange={(v: string) => setAccountingIdInput(v)}
          validation={{
            required: formErrorMsg.isRequired,
            onChange: e => {
              e.target.value.length > 20 || e.target.value.length === 0 ? setIsDisabled(true) : setIsDisabled(false);
              if (e.target.value === ownerMyCompanyData.accountingId) {
                setError('accountingPermissionId', { message: formErrorMsg.accountingId });
              }
            },
            validate: value => {
              return value !== ownerMyCompanyData.accountingId ? true : formErrorMsg.accountingId;
            },
            maxLength: {
              value: 20,
              message: formErrorMsg.maxLength(20),
            },
          }}
          startAdornment={
            <ButtonMainNew
              testcy="accounting-company-id-paste"
              isDisabled={isPasteButtonDisabled}
              sizeType="extra-small"
              margin="1px 2px 0 5px"
              width="70px"
              onClick={() => {
                setIsDisabled(false);
                setValue('accountingPermissionId', clipboard);
                trigger('accountingPermissionId');
                setAccountingIdInput(clipboard);
              }}
              content={'Wklej'}
              colorType="primary"
            />
          }
        />
      </TheDialog>
    </>
  );
};
