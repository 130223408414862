import { addHotjarEvent, invalidateApiQuery, dateFormatter } from '@lib';
import { snackbarMessagesHandler, stringToNumberSecondOptions } from '@utils';
import { EmployeeFormData } from 'src/services/employees';
import { useApiMutation } from '../useApiMutation';

type UseEditEmployeeProps = {
  employeeId?: number;
  onSuccess?: () => void;
};

export const useEditEmployee = ({ employeeId, onSuccess }: UseEditEmployeeProps) => {
  const { mutate, isLoading } = useApiMutation('updateEmployee', {
    onMutate: () => {
      addHotjarEvent('edit employee');
    },
    onSuccess: data => {
      snackbarMessagesHandler.employeeEdited();
      invalidateApiQuery('getEmployees');
      invalidateApiQuery('getEmployeeDataById', Number(data.id));
      onSuccess?.();
    },
  });

  const editEmployee = (data: EmployeeFormData) => {
    const employeeData = {
      firstName: data.firstName,
      lastName: data.lastName,
      pesel: data.pesel.toString(),
      city: data.city,
      email: data.email,
      phone: data.phone,
      grossSalary: stringToNumberSecondOptions(data.grossSalary),
      position: data.position,
      street: data.street,
      zipCode: data.zipCode,
      typeOfEmployment: data.typeOfEmployment,
      contractStartDate: dateFormatter.objectToString(data.contractStartDate as Date, 'yyyy-MM-dd'),
      contractEndDate: data.contractEndDate ? dateFormatter.objectToString(data.contractEndDate as Date, 'yyyy-MM-dd') : null,
    };

    mutate({
      id: employeeId,
      employeeData: employeeData,
    });
  };

  return {
    isLoading,
    editEmployee,
  };
};
