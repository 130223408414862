import { ButtonMain, CircleIcon, ErrorIcon, FileDropZone, SingleBarChart } from '@components';
import { compareCostHashes, createNewCostDocuments, getMyCompany } from '@services';
import { useContractorStore, useInvoiceStore } from '@store';
import { ReceivablesPayablesObject, SingleBarChartData, formatCurrency, handleApiError, mapReceivablesPayablesData, snackbarMessagesHandler } from '@utils';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getReceivablesAndPayables } from 'src/services/dashboard';
import { CashflowCard } from './components/cashflowCard/cashflowCard';
import { ProfitsLossesCard } from './components/profitsLossesCard/profitsLossesCard';
import { AddCostFilesType } from './ownerDashboard.utils';
import { useInvoiceDrawerController } from '@hooks';

import './ownerDashboard.scoped.scss';
import './ownerDashboard.scss';
import { ReceivablesPayablesCard } from './components/receivablesPayablesCard/receivablesPayablesCard';

export const OwnerDashboard = () => {
  const [isReceivablesPayablesPending, setIsReceivablesPayablesPending] = useState(true);
  const [receivables, setReceivables] = useState<ReceivablesPayablesObject>();
  const [receivablesChartData, setReceivablesChartData] = useState<SingleBarChartData>();
  const [payables, setPayables] = useState<ReceivablesPayablesObject>();
  const [payablesChartData, setPayablesChartData] = useState<SingleBarChartData>();

  const [addCostFilesState, setAddCostFilesState] = useState<AddCostFilesType>();

  const { ownerMyCompanyData, setSelectedContractor } = useContractorStore();
  const { setIsClone, setIsCorrected, setIsFromDashboard, setSelectedInvoice } = useInvoiceStore();
  const { closeInvoiceDrawer } = useInvoiceDrawerController('invoice');

  const navigate = useNavigate();
  const { companyId } = useParams();

  const onNavigateToInvoiceForm = () => {
    setIsClone(false);
    setIsCorrected(false);
    setIsFromDashboard(true);
    navigate('/invoices-list/add-invoice');
    setSelectedContractor(null, null);
  };

  useEffect(() => {
    closeInvoiceDrawer();
    setSelectedInvoice(null);
    fetchReceivablesPayables();
  }, []);

  const addNewCostsDocuments = async (data: FormData) => {
    const myCompany = await getMyCompany();
    const thisCompanyId = companyId ?? myCompany.id;

    data.set('companyId', String(thisCompanyId));

    setAddCostFilesState(p => ({ ...p, isPendingAddNewCostDocuments: true }));
    try {
      const costDocuments = await createNewCostDocuments(data);

      if (costDocuments.every(doc => doc.isSuccessful)) {
        snackbarMessagesHandler.costsUploadDoc(costDocuments.length > 1 ? true : false);
      }
    } catch (err) {
      handleApiError(err);
    }
    setAddCostFilesState(p => ({ ...p, isPendingAddNewCostDocuments: false }));
  };

  const fetchReceivablesPayables = async () => {
    setIsReceivablesPayablesPending(true);
    try {
      const response = await getReceivablesAndPayables();
      setReceivables(response.receivables);
      setPayables(response.payables);
      const data = mapReceivablesPayablesData(response);
      setReceivablesChartData(data.receivables);
      setPayablesChartData(data.payables);
    } catch (err) {
      handleApiError(err);
      console.error(err);
    }
    setIsReceivablesPayablesPending(false);
  };

  //TODO to be implemented in a future story
  // const onReceivablesMenuClick = () => {

  // }

  // const onPayablesMenuClick = () => {

  // }
  return (
    <div className={`dashboard-wrapper standard-layout-spacing-g`}>
      <h1 className="title-g">Panel</h1>
      <div className="dashboard-header">
        <div className="header-buttons">
          <ButtonMain
            testcy="add-invoice"
            content={'Utwórz fakturę'}
            isDisabled={ownerMyCompanyData?.isDummy}
            onClick={onNavigateToInvoiceForm}
            tooltip={ownerMyCompanyData?.isDummy ? 'Przed wystawieniem faktury należy uzupełnić dane firmy.' : null}
          />
          <ButtonMain
            testcy="costs-file-drop-zone-browse"
            content={'Wstaw koszt'}
            onClick={() => setAddCostFilesState(p => ({ ...p, openBrowserDialog: true }))}
          />
        </div>
        <FileDropZone
          testcy="costs-file-drop-zone"
          accept={'pdf,png,jpg,jpeg'}
          multiple
          onChange={addNewCostsDocuments}
          isPending={addCostFilesState?.isPendingAddNewCostDocuments}
          showDropzone={false}
          openBrowserDialog={addCostFilesState?.openBrowserDialog}
          compareHashesFetchFn={(hashes: string[]) =>
            compareCostHashes({
              values: hashes,
              companyId: companyId,
            })
          }
          setOpenBrowserDialog={open => {
            setAddCostFilesState(p => ({ ...p, openBrowserDialog: open }));
          }}
        />
      </div>
      <div className="dashboard-content">
        <ProfitsLossesCard />
        <ReceivablesPayablesCard />
        <CashflowCard />
      </div>
    </div>
  );
};
