import { MessageRepository } from '@amityco/ts-sdk';
import { useEffect, useState } from 'react';
import { ChatMessageCollection, createChatMessageCollection } from '../model/chatMessage';

type UseLiveMessageCollectionProps = {
  subChannelId?: string;
  userId?: string;
};

export const useLiveMessageCollection = ({ subChannelId, userId }: UseLiveMessageCollectionProps) => {
  const [liveCollection, setLiveCollection] = useState<ChatMessageCollection>({
    data: [],
    loading: false,
    error: undefined,
    onNextPage: () => {},
    hasNextPage: false,
  });

  useEffect(() => {
    const setMessageCollection = (collection: Amity.LiveCollection<Amity.Message<any>>) => {
      const chatMessageCollection = createChatMessageCollection(collection, userId);

      setLiveCollection(chatMessageCollection);
    };

    if (!subChannelId) {
      return;
    }

    return MessageRepository.getMessages({ subChannelId, limit: 10, sortBy: 'segmentDesc' }, setMessageCollection);
  }, [subChannelId, setLiveCollection, userId]);

  return liveCollection;
};
