import { GusCompanyData } from '@services';
import { useApiMutation } from '../useApiMutation';

interface IUseFetchGusData {
  fetchGusData: (nip: string, isNipCorrect: boolean) => void;
  isGusDataLoading: boolean;
}

export const useFetchGusData = (onSuccess: (data: GusCompanyData) => void): IUseFetchGusData => {
  const { mutate, isLoading } = useApiMutation('getCompanyDataGus');

  const fetchGusData = (nip: string, isNipCorrect: boolean) => {
    if (!isNipCorrect) return;
    mutate(nip, {
      onSuccess,
    });
  }

  return { fetchGusData, isGusDataLoading: isLoading };
};
