import { CustomObject } from '@interfaces';
import { CompanyData, InsertGetData } from '@services';
import { LegalPersonalityType, YesOrNo, zipCodeMatch } from '@utils';

export interface CompanyFormData extends CustomObject {
  nip: string;
  regon: string;
  name: string;
  firstName: string;
  lastName: string;
  city: string;
  zipCode: string;
  street: string;
  phoneNumber: string;
  email: string;
  fax: string;
  legalPersonality: LegalPersonalityType | null;
  allowKsef: YesOrNo;
  dataBaseInsert?: string;
  userNameInsert?: string;
  passwordInsert?: string;
  serverInsertDataBase?: string;
  userNameInsertDataBase?: string;
  passwordInsertDataBase?: string;
  allowSuffixes: boolean;
}

export interface InsertCredentialFormData extends CustomObject {
  dataBaseInsert: string;
  userNameInsert: string;
  passwordInsert: string;
}

export const transformFormDataToCompanyData = (data: CompanyFormData, companyId: string): CompanyData => {
  let zipCodeFormat = '';
  const isZipCodeMatch = zipCodeMatch.test(data.zipCode);

  if (isZipCodeMatch) zipCodeFormat = data.zipCode?.slice(0, 2) + '-' + data.zipCode?.slice(2);

  const transformedData: CompanyData = {
    nip: data.nip,
    regon: data.regon,
    name: data.name,
    owner: {
      firstName: data.firstName,
      lastName: data.lastName,
    },
    address: {
      city: data.city,
      street: data.street,
      zipCode: isZipCodeMatch ? zipCodeFormat : data.zipCode,
    },
    phoneNumber: data.phoneNumber,
    email: data.email,
    fax: data.fax || null,
    allowKsef: data.allowKsef == YesOrNo.Yes,
    bankAccounts: [], //filled later
    invoiceNumberSuffixes: [], //filled later
    invoiceNumberFormat: data.invoiceNumberFormat,
    isDummy: false,
    accountingCredentials: null,
    legalPersonality: data.legalPersonality,
    hasSuffixes: data.allowSuffixes,
  };
  if (!companyId && data.dataBaseInsert && data.userNameInsert && data.passwordInsert) {
    transformedData.accountingCredentials = {
      database: data.dataBaseInsert,
      username: data.userNameInsert,
      password: data.passwordInsert,
    };
  }
  for (const property in data) {
    if (property.match(/bank-number-(\d+)/)) {
      const value = data[property];
      if (!value) continue;
      const index = property.match(/bank-number-(\d+)/)[1];
      const isMain = data['main-bank-account'] == index;
      const bankName = data[`bank-name-${index}`];
      transformedData.bankAccounts.push({
        number: value,
        bankName: bankName,
        mainAccount: isMain,
      });
    }
    if (property.match(/suffix-(\d+)/)) {
      const value = data[property];
      if (!value) continue;
      value &&
        transformedData.invoiceNumberSuffixes.push({
          value,
        });
    }
  }

  return transformedData;
};

export const transformCompanyDataToFormData = (
  data: CompanyData
): CompanyFormData => {
  const formDataValues: CompanyFormData = {
    nip: data?.nip || '',
    regon: data?.regon || '',
    name: data?.name || '',
    firstName: data?.owner?.firstName || '',
    lastName: data?.owner?.lastName || '',
    city: data?.address?.city || '',
    zipCode: data?.address?.zipCode || '',
    street: data?.address?.street || '',
    phoneNumber: data?.phoneNumber || '',
    email: data?.email || '',
    fax: data?.fax || '',
    legalPersonality: data?.legalPersonality || null,
    allowKsef: data?.allowKsef ? "tak" : "nie",
    invoiceNumberSuffixes: data?.invoiceNumberSuffixes || [],
    bankNumbers: data?.bankAccounts || [],
    invoiceNumberFormat: data?.invoiceNumberFormat,
    ownAccountingId: data?.accountingId || '',
    allowSuffixes: !!data?.hasSuffixes,
  };

  return formDataValues;
};

export const transformFormDataToInsertData = (data: CompanyFormData) => {
  if (!data.dataBaseInsert && !data.userNameInsert && !data.passwordInsert) {
    const transformedData = {
      accountingCredentials: null,
    };
    return transformedData;
  }
  const transformedData = {
    accountingCredentials: {
      database: data.dataBaseInsert,
      username: data.userNameInsert,
      password: data.passwordInsert,
    },
  };
  return transformedData;
};

export const transformInsertDataToFormData = (data: InsertGetData): { formDataValues: InsertCredentialFormData } => {
  const formDataValues: InsertCredentialFormData = {
    dataBaseInsert: data.database,
    userNameInsert: data.username,
    passwordInsert: data.password,
  };
  return { formDataValues };
};