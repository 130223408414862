import React, { MouseEvent, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './buttonText.scoped.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export interface Props {
  content: string;
  testcy: string;
  isDisabled?: boolean;
  fontColor?: string;
  startIcons?: 'back' | 'backArrow';
  endIcons?: 'far' | 'add' | 'info';
  type?: 'button' | 'submit' | 'reset';
  iconSize?: 'small' | 'medium' | 'big';
  fontSize?: string;
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none';
  isNewLayout?: boolean;
  margin?: string | number;
  name?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  fontWeight?: string;
  fontFamily?: string;
}

export const ButtonText = ({
  content,
  testcy,
  isDisabled,
  startIcons,
  fontColor = '',
  endIcons,
  type = 'button',
  iconSize = 'small',
  fontSize = '14px',
  textTransform = 'uppercase',
  isNewLayout = false,
  margin = '0',
  name = '',
  onClick = () => {},
  fontWeight,
  fontFamily,
}: Props) => {
  const [startIcon, setStartIcon] = useState(null);
  const [endIcon, setEndIcon] = useState(null);

  useEffect(() => {
    if (startIcons === 'back') setStartIcon(<ArrowBackIosNewIcon style={{ fontSize: `${iconSize}` }} />);
    if (startIcons === 'backArrow') setStartIcon(<ArrowBackIcon />);
    if (endIcons === 'far') setEndIcon(<ArrowForwardIosIcon style={{ fontSize: `${iconSize}` }} />);
    if (endIcons === 'add') setEndIcon(<ControlPointIcon style={{ fontSize: `${iconSize}` }} />);
    if (endIcons === 'info') setEndIcon(<InfoOutlinedIcon style={{ fontSize: `${iconSize}` }} />);
  }, [endIcons, iconSize, startIcons]);

  return (
    <Button
      className={`button`}
      id={name}
      style={{
        color: `${fontColor}`,
        textDecorationColor: `${fontColor}`,
        textDecoration: `${isNewLayout && 'underline'}`,
        fontSize: fontSize,
        textTransform: textTransform,
        margin: margin,
        fontWeight: fontWeight,
        fontFamily: fontFamily,
      }}
      onClick={onClick}
      disabled={isDisabled}
      variant="text"
      startIcon={startIcon}
      type={type}
      endIcon={endIcon}
      data-testcy={testcy}
    >
      {content}
    </Button>
  );
};
