import React from 'react';
import { scaleTime, scaleLinear } from '@visx/scale';
import { GridRows, GridColumns } from '@visx/grid';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { Line } from '@visx/shape';

const axisColor = '#d2d6db';
const labelColor = '#5f6770';
const axisBottomTickLabelProps = {
  textAnchor: 'middle' as const,
  fontFamily: 'Poppins',
  fontSize: 12,
  fontWeight: 400,
  fill: labelColor,
};
const axisLeftTickLabelProps = {
  dx: '-0.25em',
  dy: '0.25em',
  fontFamily: 'Poppins',
  fontSize: 14,
  fontWeight: 400,
  fill: labelColor,
  textAnchor: 'start' as const,
  className: 'axis-left-tick-label',
};

type Props = {
  chartWidth: number;
  chartHeight: number;
  xNumTicks: number;
  yNumTicks: number;
  xScale: ReturnType<typeof scaleTime<number>>;
  yScale: ReturnType<typeof scaleLinear<number>>;
  labelOffset?: number;
};

export const ChartGrid = ({ chartWidth, chartHeight, xNumTicks, yNumTicks, xScale, yScale, labelOffset }: Props) => {
  return (
    <>
      <rect x={0} y={0} width={chartWidth} height={chartHeight} fill="#F7F7F9E5" />
      <GridRows numTicks={yNumTicks} scale={yScale} width={chartWidth} height={chartHeight} stroke={axisColor} strokeDasharray="2" />
      <GridColumns numTicks={xNumTicks} scale={xScale} width={chartWidth} height={chartHeight} stroke={axisColor} strokeDasharray="2" />
      <AxisBottom
        top={chartHeight}
        scale={xScale}
        numTicks={xNumTicks}
        stroke={axisColor}
        strokeDasharray="2"
        hideTicks
        tickFormat={d => (d as Date).toLocaleString('pl-PL', { month: 'short', day: '2-digit' })}
        tickLabelProps={axisBottomTickLabelProps}
      />
      <AxisLeft
        scale={yScale}
        numTicks={yNumTicks}
        stroke={axisColor}
        strokeDasharray="2"
        hideTicks
        tickFormat={d => `${d.valueOf().toFixed(0)} PLN`}
        tickLabelProps={{
          ...axisLeftTickLabelProps,
          dx: -(labelOffset ?? 0),
        }}
      />
      <Line from={{ x: chartWidth, y: 0 }} to={{ x: chartWidth, y: chartHeight }} stroke={axisColor} strokeDasharray={2} />
      <Line from={{ x: 0, y: 0 }} to={{ x: chartWidth, y: 0 }} stroke={axisColor} strokeDasharray={2} />
    </>
  );
};
