import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import GoogleFontLoader from 'react-google-font-loader';
import { AuthProvider, QueryClientProvider, SnackbarProvider } from '@lib';
import plLocale from 'date-fns/locale/pl';
import { createTheme } from '@mui/material';
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { SharedCookiesDisabledInfo } from '@features';
import { routes } from '@routes';

const router = createBrowserRouter(routes as RouteObject[]);

const theme = createTheme({
  typography: {
    fontFamily: ['Lato', 'Poppins', 'sans-serif'].join(','),
  },
  palette: {
    text: {
      primary: '#101013',
      secondary: '#101013',
    },
  },
});

const App = () => {
  if (!navigator.cookieEnabled) return <SharedCookiesDisabledInfo />;

  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Lato',
            weights: [400, '400i', '500', '500i', 700, '700i', 800, '800i'],
          },
          {
            font: 'Poppins',
            weights: [400, '400i', 500, '500i', 700, '700i', 800, '800i'],
          },
        ]}
      />
      <AuthProvider>
        <SnackbarProvider>
          <LocalizationProvider adapterLocale={plLocale} dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
              <QueryClientProvider>
                <RouterProvider router={router} />
              </QueryClientProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </AuthProvider>
    </>
  );
};

export default App;
