import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const Invoices = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} width="100%" height="100%" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.74586 22.0702H15.2981V19.965H5.74586V22.0702ZM5.74586 16.4561H15.2981V14.3509H5.74586V16.4561ZM3.27208 27.3334C2.62872 27.3334 2.08416 27.0877 1.63839 26.5965C1.19261 26.1053 0.969727 25.5052 0.969727 24.7962V3.20382C0.969727 2.49485 1.19261 1.89476 1.63839 1.40353C2.08416 0.912302 2.62872 0.666687 3.27208 0.666687H13.3877L20.0742 8.03506V24.7962C20.0742 25.5052 19.8513 26.1053 19.4055 26.5965C18.9598 27.0877 18.4152 27.3334 17.7718 27.3334H3.27208ZM12.4324 9.08769V2.77192H3.27208C3.17409 2.77192 3.08427 2.8169 3.00261 2.90687C2.92097 2.99686 2.88015 3.09584 2.88015 3.20382V24.7962C2.88015 24.9042 2.92097 25.0032 3.00261 25.0932C3.08427 25.1831 3.17409 25.2281 3.27208 25.2281H17.7718C17.8698 25.2281 17.9597 25.1831 18.0413 25.0932C18.123 25.0032 18.1638 24.9042 18.1638 24.7962V9.08769H12.4324Z"
        fill="#5b5b5a"
      />
    </svg>
  );
};
