import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const DeleteIcon = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-g`} width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.8077 17.5017C2.30898 17.5017 1.88302 17.3251 1.52982 16.9719C1.17661 16.6187 1 16.1928 1 15.694V3.00176H0V1.50179H4.49997V0.617188H10.5V1.50179H15V3.00176H14V15.694C14 16.1992 13.825 16.6267 13.475 16.9767C13.125 17.3267 12.6974 17.5017 12.1922 17.5017H2.8077ZM12.5 3.00176H2.49997V15.694C2.49997 15.7838 2.52883 15.8575 2.58652 15.9152C2.64422 15.9729 2.71795 16.0018 2.8077 16.0018H12.1922C12.2692 16.0018 12.3397 15.9697 12.4038 15.9056C12.4679 15.8415 12.5 15.771 12.5 15.694V3.00176ZM4.90385 14.0018H6.40382V5.00176H4.90385V14.0018ZM8.59613 14.0018H10.0961V5.00176H8.59613V14.0018Z"
        fill="#000000"
      />
    </svg>
  );
};
