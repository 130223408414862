import arrow from '@assets/arrow.svg';
import { LoaderIcon } from '@components';
import { Option } from '@interfaces';
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { HelperTextAlignment } from '@utils';
import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { Control, Controller, FieldValues, RegisterOptions, UseFormWatch } from 'react-hook-form';
import { FormElementAppearance, FormElementSize } from '../types';
import { MemoizedFilterOptions } from './components';

import './theSelect.scss';

interface Props {
  name: string;
  testcy: string;
  control?: Control;
  options?: Option[];
  width?: string;
  disabled?: boolean;
  readOnly?: boolean;
  isEnabledOnEmptyList?: boolean;
  validation?: RegisterOptions;
  defaultValue?: string;
  appearance?: FormElementAppearance;
  size?: FormElementSize;
  label?: string;
  placeholder?: string;
  hideEmptyOption?: boolean;
  showAdditionalMessageInOption?: boolean;
  isPendingFilter?: boolean;
  emptyListText?: string;
  addBtnText?: string;
  helperText?: string;
  helperTextAlign?: HelperTextAlignment;
  flags?: {};
  renderLabel?: (option: Option) => JSX.Element | string;
  watch?: UseFormWatch<FieldValues>; // required when u have additional message
  onChange?: (value: string) => void;
  onFilterChanged?: (value: string) => void;
  onAddClick?: () => void;
  onFocus?: (value) => void;
  onBlur?: (value) => void;
  rerenderKey?: string;
}

export const TheSelect = ({
  name,
  testcy,
  control,
  options = [],
  width = '250px',
  disabled = false,
  readOnly = false,
  isEnabledOnEmptyList = false,
  appearance = FormElementAppearance.Old,
  validation = {},
  size = FormElementSize.Medium,
  defaultValue = '',
  label = '',
  placeholder = '',
  hideEmptyOption = false,
  showAdditionalMessageInOption = false,
  isPendingFilter = false,
  emptyListText,
  addBtnText = 'Dodaj',
  helperText,
  helperTextAlign = HelperTextAlignment.Left,
  flags,
  renderLabel = v => v.label,
  watch,
  onChange,
  onFilterChanged,
  onAddClick,
  onFocus,
  onBlur,
  rerenderKey = '',
}: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [isEmptyListDisabled, setIsEmptyListDisabled] = useState(false);
  const [additionalMessage, setAdditionalMessage] = useState('');

  const currentValueWatch = watch ? watch(name) : null;

  useEffect(() => {
    if (currentValueWatch) onChangeCurrentValue(currentValueWatch);
  }, [currentValueWatch]);

  const onChangeHandler = (onChangeProvided: (...event: any[]) => void, e: SelectChangeEvent<any>): void => {
    const value = e.target.value;
    onChange?.(value);
    onChangeProvided(value);
    setOpen(false);
  };

  const onChangeCurrentValue = (value: any) => {
    const option = options.find(o => o.value === value);
    if (onFilterChanged && option) setSelectedOption(option);
    if (option?.additionalMessage) setAdditionalMessage(option.additionalMessage);
  };

  const onClickSelectAddBtn = () => {
    setOpen(false);
    onAddClick();
  };

  const onSelectClick: MouseEventHandler<HTMLElement> = event => {
    if (disabled || readOnly) return;
    const classes = (event.target as HTMLElement).classList;

    if (open || classes.contains('MuiBackdrop-root') || classes.contains('MuiMenuItem-root')) return;

    setOpen(true);
  };

  const getOptions = useMemo((): Option[] => {
    const canSetEmptyLabel = !options.length && emptyListText;
    setIsEmptyListDisabled(!!canSetEmptyLabel);
    if (canSetEmptyLabel) {
      return [
        {
          label: emptyListText,
          value: 'empty',
        },
      ];
    }
    return options;
  }, [options, emptyListText]);

  const getRootInputClasses = (error: any): string => {
    //prettier-ignore
    return [
      'select-input styled-input',
      `helper-text-${error?.message ? 'left' : helperTextAlign}`,
      error?.message ? 'has-error' : '',
      disabled ? 'input-disabled' : '',
      readOnly ? 'input-readonly' : ''
    ].join(' ');
  };

  const getMenuClasses = (): string => {
    //prettier-ignore
    return [
      'select-options-menu-wrapper',
      'menu-g',
      `appearance-${appearance}`,
    ].join(' ');
  };

  return (
    <div className={`select-input-container appearance-${appearance} size-${size}`}>
      <FormControl variant="outlined" className="select-form-control input-form-control">
        {label && (
          <InputLabel shrink className={`input-label`} disabled={disabled}>
            {label}
          </InputLabel>
        )}
        <Controller
          control={control}
          name={name}
          rules={validation}
          defaultValue={defaultValue}
          render={({ field: { onChange: onChangeProvided, value, onBlur: onBlurProvided }, fieldState: { error } }) => (
            <div className={`select-input-wrapper ${getRootInputClasses(error)} `}>
              <Select
                inputProps={{
                  'data-testcy': testcy,
                }}
                id={name}
                multiple={false}
                open={open}
                onClose={event => {
                  onBlur?.(event);
                  setOpen(false);
                }}
                onClick={onSelectClick}
                onOpen={event => {
                  onFocus?.(event);
                  setOpen(true);
                }}
                displayEmpty
                renderValue={value => {
                  const option = getOptions.find(option => option.value === value);
                  if (!option?.label) return <span className="input-label-placeholder">{placeholder}</span>;
                  return renderLabel(option);
                }}
                value={isEmptyListDisabled ? 'empty' : value}
                onChange={e => onChangeHandler(onChangeProvided, e)}
                onBlur={onBlurProvided}
                style={{ width: width, maxWidth: width }}
                MenuProps={{
                  classes: { paper: getMenuClasses() },
                }}
                disabled={disabled || (isEmptyListDisabled && !isEnabledOnEmptyList)}
                IconComponent={props => <img src={arrow} alt="arrow" {...props} />}
              >
                {onFilterChanged && <MemoizedFilterOptions testcy={`${testcy}-filter`} control={control} parentName={name} onFilterChanged={onFilterChanged} />}
                {isPendingFilter && (
                  <div className="center-g w-100-g">
                    <LoaderIcon size="big" />
                  </div>
                )}
                {!isEnabledOnEmptyList && (
                  <MenuItem
                    data-testcy={`${testcy}-empty-option`}
                    className={`empty-option ${(!hideEmptyOption && !isPendingFilter) || !value ? 'empty-option-enabled' : 'empty-option-disabled'}`}
                    value={''}
                  ></MenuItem>
                )}

                {getOptions.map((opt, index) => (
                  <MenuItem
                    data-testcy={`${testcy}-option-${index} ${testcy}-option-${opt.value.toLowerCase()}`}
                    key={opt.value}
                    value={opt.value}
                    className={`${isPendingFilter ? 'hiden-option' : ''}`}
                  >
                    {opt.action && <div key={rerenderKey}>{opt.action}</div>}
                    <span className={`select-input ${opt.value === 'empty' ? 'enable-select-no-options' : ''}`}>
                      {flags && <img src={flags[`${opt.value.toLowerCase()}.svg`]} width="15" alt="country flag"></img>} {renderLabel(opt)}
                    </span>
                    <span className="additional-label-value">{showAdditionalMessageInOption && opt.additionalMessage ? `- ${opt.additionalMessage}` : ''}</span>
                  </MenuItem>
                ))}

                {onFilterChanged && selectedOption && (
                  <MenuItem data-testcy={`${testcy}-selected`} key={selectedOption?.value} value={selectedOption.value} className="hiden-option">
                    <span>{selectedOption.label}</span>
                  </MenuItem>
                )}
                {onAddClick && (
                  // TODO: Temporary implementation. Replace with transparent button when #3022 is merged
                  <Button type="button" data-testcy={`${testcy}-add-button`} className="select-options-add-btn" onClick={onClickSelectAddBtn}>
                    {addBtnText}
                  </Button>
                )}
              </Select>
              <p className={`MuiFormHelperText-root`}>{error?.message ?? (additionalMessage || helperText)}</p>
            </div>
          )}
        />
      </FormControl>
    </div>
  );
};
