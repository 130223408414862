import arrow from '@assets/arrow.svg';
import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { YearsDropdownCalendar } from 'src/components/icons/yearsDropdownCalendar';
import { ButtonIconNew, ChevronIcon } from '@components';
import './yearDropdown.scss';

const YearDropdown = ({ onDateRangeChange }) => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [open, setOpen] = useState(false);

  const yearsToShow = 9;
  const startYear = currentYear + 1 - yearsToShow + 1;
  const years = Array.from({ length: yearsToShow }, (_, index) => startYear + index);

  const handleYearChange = event => {
    const newSelectedYear = event.target.value;
    setSelectedYear(newSelectedYear);
    if (newSelectedYear <= currentYear && newSelectedYear >= currentYear - 8) {
      const toDate = new Date(newSelectedYear, 11, 31, 22, 59, 59, 999);
      const fromDate = new Date(newSelectedYear, 0, 1, 23, 0, 0, 0);
      const updatedDateRange = {
        toDate: toDate,
        fromDate: fromDate,
      };
      onDateRangeChange(updatedDateRange);
    }
  };

  const handleNudgeBackward = () => {
    const newSelectedYear = Math.max(selectedYear - 1, currentYear - 8);
    setSelectedYear(newSelectedYear >= currentYear - 8 && newSelectedYear);
    const toDate = new Date(newSelectedYear, 11, 31, 22, 59, 59, 999);
    const fromDate = new Date(newSelectedYear, 0, 1, 23, 0, 0, 0);
    const updatedDateRange = {
      toDate: toDate,
      fromDate: fromDate,
    };
    onDateRangeChange(updatedDateRange);
  };

  const handleNudgeForward = () => {
    const newSelectedYear = Math.min(selectedYear + 1);
    setSelectedYear(newSelectedYear);
    const toDate = new Date(newSelectedYear, 11, 31, 22, 59, 59, 999);
    const fromDate = new Date(newSelectedYear, 0, 1, 23, 0, 0, 0);
    const updatedDateRange = {
      toDate: toDate,
      fromDate: fromDate,
    };
    onDateRangeChange(updatedDateRange);
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="year-selector-container appearance-light size-big">
      <button onClick={handleToggle}>
        <Select
          open={open}
          value={selectedYear}
          onChange={handleYearChange}
          className="custom-select"
          startAdornment={<YearsDropdownCalendar />}
          IconComponent={props => <img src={arrow} alt="arrow" {...props} />}
          MenuProps={{
            classes: { paper: 'menu-g year-selector-container-dropdown' },
          }}
        >
          {years
            .map(year => (
              <MenuItem key={year} value={year}>
                <span>{year}</span>
              </MenuItem>
            ))
            .reverse()}
        </Select>
      </button>
      <div className="range-picker-buttons">
        <ButtonIconNew testcy="range-picker-nudge-backward" size="big" onClick={handleNudgeBackward} border disabled={selectedYear === Math.min(...years)}>
          <ChevronIcon direction="left" />
        </ButtonIconNew>
        <ButtonIconNew testcy="range-picker-nudge-forward" size="big" onClick={handleNudgeForward} border disabled={selectedYear === Math.max(...years)}>
          <ChevronIcon direction="right" />
        </ButtonIconNew>
      </div>
    </div>
  );
};

export default YearDropdown;
