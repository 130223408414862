import React from 'react';

import './chevron.scoped.scss'

export const ChevronDirection = {
  Left: 'left',
  Right: 'right',
  Up: 'up',
  Down: 'down',
} as const;
export type ChevronDirection = typeof ChevronDirection[keyof typeof ChevronDirection];

interface Props {
  colorType?: 'black' | 'white' | 'primary' | 'disabled' | '';
  direction?: ChevronDirection;
  size?: number;
}

export const ChevronIcon = ({ colorType = '', size = 24, direction = ChevronDirection.Left }: Props) => {
  return (
    <svg
      className={`chevron-icon icon-color-${colorType}-stroke direction-${direction}`}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M15 18L9 12L15 6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
