import { SpecialInput, TextInputNew } from '@components';
import { focusOnNextFormInput, formErrorMsg, standardValidators } from '@utils';
import React from 'react';
import { SettingsSection, SettingsSubSection, SettingsTab } from '../../components/settingsTab';

export const OwnerProfileSection = () => {
  return (
    <SettingsTab title="Profil (Właściciel firmy)">
      <SettingsSection>
        <SettingsSubSection title="Dane kontaktowe" subtitle="Personalia oraz Twoje dane kontaktowe firmy.">
          <TextInputNew
            testcy="first-name"
            name="firstName"
            label="Imię właściciela"
            width="100%"
            appearance="filled"
            onKeyUp={event => {
              focusOnNextFormInput(event, 2);
            }}
            validation={standardValidators.requiredMaxNumber(100)}
          />
          <TextInputNew
            testcy="last-name"
            name="lastName"
            label="Nazwisko właściciela"
            width="100%"
            appearance="filled"
            onKeyUp={event => {
              focusOnNextFormInput(event, 2);
            }}
            validation={standardValidators.requiredMaxNumber(100)}
          />
        </SettingsSubSection>
        <SettingsSubSection>
          <SpecialInput
            testcy="fax"
            name="fax"
            type="phone"
            label="Fax"
            width="100%"
            appearance="filled"
            onKeyDown={event => {
              focusOnNextFormInput(event, 2);
            }}
            validation={{
              required: false,
            }}
          />
          <SpecialInput
            testcy="phone-number"
            name="phoneNumber"
            type="phone"
            label="Telefon"
            width="100%"
            appearance="filled"
            onKeyDown={event => {
              focusOnNextFormInput(event, 2);
            }}
            validation={{
              required: formErrorMsg.isRequired,
            }}
          />
          <SpecialInput
            testcy="email"
            name="email"
            type="email"
            label="E-mail"
            width="100%"
            appearance="filled"
            onKeyDown={event => {
              focusOnNextFormInput(event, 2);
            }}
            validation={{
              required: formErrorMsg.isRequired,
            }}
          />
        </SettingsSubSection>
      </SettingsSection>
    </SettingsTab>
  );
};
