import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | 'black' | '';
  size?: number;
}

export const MeatballsMenuIcon = ({ colorType = '', size = 16}: Props) => {
  return (
    <svg className={`icon-color-${colorType}-g`} width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33331 9C3.8856 9 4.33331 8.55228 4.33331 8C4.33331 7.44772 3.8856 7 3.33331 7C2.78103 7 2.33331 7.44772 2.33331 8C2.33331 8.55228 2.78103 9 3.33331 9ZM8.99998 8C8.99998 8.55228 8.55226 9 7.99998 9C7.44769 9 6.99998 8.55228 6.99998 8C6.99998 7.44772 7.44769 7 7.99998 7C8.55226 7 8.99998 7.44772 8.99998 8ZM13.6666 8C13.6666 8.55228 13.2189 9 12.6666 9C12.1144 9 11.6666 8.55228 11.6666 8C11.6666 7.44772 12.1144 7 12.6666 7C13.2189 7 13.6666 7.44772 13.6666 8Z"
        fill="black"
      />
    </svg>
  );
};
