import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isBoolean } from 'lodash';
import { ButtonText, PageLoaderWrapper, TabsButton, TheDialog, useTabsButtonStore } from '@components';
import { CompanyData, getCompanyById } from '@services';
import { useCompanyStore, useContractorStore } from '@store';
import { handleApiError } from '@utils';
import { tabsData, TabId } from './accountantCompanyDetails.utils';
import { TaxTab, IncomeTab, ClientsTab, CostsTab, EmployeesTab, InboxTab } from './components';
import './accountantCompanyDetails.scoped.scss';
import { useForm } from 'react-hook-form';
import { useSharedTaxesForm } from '@features';

export const AccountantCompanyDetails = () => {
  const [isPending, setIsPending] = useState(false);
  const [tabId, setTabId] = useState<TabId>('income');
  const [nextTabId, setNextTabId] = useState<TabId | null>(null);
  const [companyName, setCompanyName] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isBackPressed, setIsBackPressed] = useState(false);

  const navigate = useNavigate();
  const routeParams = useParams();
  const { selectedCompanyId, selectedCompany, setSelectedCompany } = useCompanyStore();
  const { hasAccountingClients, ownerMyCompanyData } = useContractorStore();

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    setError,
    clearErrors,
    watch,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const form = useSharedTaxesForm({ formFeatures: { watch, reset } });
  const { updateActiveTab } = useTabsButtonStore();

  useEffect(() => {
    if (isBoolean(hasAccountingClients) && !hasAccountingClients && ownerMyCompanyData?.id === +routeParams?.companyId) {
      navigate('/page-not-found');
    }
  }, [hasAccountingClients, ownerMyCompanyData, routeParams]);

  useEffect(() => {
    const activeTab = tabsData.filter(tab => tab.isActive);
    const activeTabId = activeTab[0].id;
    setTabId(activeTabId as TabId);

    if (routeParams.companyId) {
      fetchCompanyData(routeParams.companyId);
    }
  }, [routeParams, companyName, tabId]);

  const fetchCompanyData = (id: string) => {
    if (selectedCompanyId === id && selectedCompany) {
      setCompanyName(selectedCompany.name);
    } else {
      setIsPending(true);
      getCompanyById(id)
        .then((data: CompanyData) => {
          setSelectedCompany(data, id);
          setCompanyInfo(data);
        })
        .catch(err => {
          if (err.status === 404 || err.status === 403) {
            navigate('/page-not-found');
            return;
          }
          handleApiError(err.data);
        })
        .finally(() => setIsPending(false));
    }
  };

  const onModalClose = (agree: boolean) => {
    setIsConfirmModalOpen(false);

    if (agree) {
      handleSubmit(f => form.onSubmit(f))();
    }

    if (isBackPressed) {
      reset();
      setIsBackPressed(false);
      navigate('/company-list');
      updateActiveTab('income');
    } else {
      reset();
      updateActiveTab(nextTabId);
      setTabId(nextTabId);
      setNextTabId(null);
    }
  };

  const onBeforeTabChange = async (id: string): Promise<boolean> => {
    setNextTabId(id as TabId);

    if (isDirty) {
      setIsConfirmModalOpen(true);
      return false;
    }

    return true;
  };

  const onTabClick = (id: string) => {
    if (isDirty) {
      setIsConfirmModalOpen(true);
      return false;
    }

    setTabId(id as TabId);
  };

  const setCompanyInfo = (data: CompanyData) => {
    setCompanyName(data.name);
  };

  const navigateTo = () => {
    setIsBackPressed(true);
    if (isDirty) {
      setIsConfirmModalOpen(true);
      return;
    }
    navigate('/owner-company-list');
    updateActiveTab('income');
  };

  return (
    <div className="company-details-accountant standard-layout-spacing-g">
      <PageLoaderWrapper isLoading={isPending}>
        <>
          <div className="header">
            <ButtonText testcy="go-back" onClick={navigateTo} fontSize="12px" content="powrót" startIcons="back" />
            <p className="title-g">{companyName}</p>
            <div className="navigation">
              <TabsButton testcy="tabs" contents={tabsData} onClickTab={onTabClick} beforeTabChange={onBeforeTabChange} />
            </div>
          </div>
          <div className="tabs-wrapper">
            {tabId === 'income' && <IncomeTab />}
            {tabId === 'costs' && <CostsTab />}
            {tabId === 'clients' && <ClientsTab />}
            {tabId === 'taxes' && <TaxTab formFeatures={{ control, setValue, getValues, handleSubmit, trigger, setError, clearErrors, watch, reset }} />}
            {tabId === 'employees' && <EmployeesTab />}
            {tabId === 'inbox' && <InboxTab />}
          </div>
          <TheDialog
            testcy="company-details-leave-without-saving"
            open={isConfirmModalOpen}
            onClose={onModalClose}
            approveButtonText="Zapisz zmiany"
            rejectButtonText="Wyjdź"
            title="Czy chcesz opuścić formularz bez zapisania zmian?"
          >
            <></>
          </TheDialog>
        </>
      </PageLoaderWrapper>
    </div>
  );
};
