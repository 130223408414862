import React, { useState } from 'react';
import './floatingFooter.scoped.scss';
import { ButtonMain, ReportAnIssueModal } from '@components';

export const FloatingFooter = () => {
  const [isOpened, setIsOpened] = useState(false);

  const closeModal = () => {
    setIsOpened(false);
  };

  const openModal = () => {
    setIsOpened(true);
  };

  return (
    <div className="floatingContainer">
      <ButtonMain testcy="report-problem" sizeType="extra-small" colorType="blackOutlined" content={'Zgłoś problem'} onClick={openModal} />
      <ReportAnIssueModal isOpened={isOpened} onCloseModal={closeModal} />
    </div>
  );
};
