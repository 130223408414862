import { snackbarMessagesHandler } from '@utils';
import { useApiMutation } from '../useApiMutation';

interface IUseMarkInvoiceAsSent {
  markInvoiceAsSent: (id: string) => void;
  isMarkInvoiceAsSentLoading: boolean;
}

export const useMarkInvoiceAsSent = (onSuccess: () => void): IUseMarkInvoiceAsSent => {
  const { mutate, isLoading } = useApiMutation('invoiceMarkAsSent');

  const markInvoiceAsSent = (id: string) => {
    mutate(+id, {
      onSuccess: () => {
        snackbarMessagesHandler.invoiceMarkedAsSent();
        onSuccess();
      }
    });
  }

  return { markInvoiceAsSent, isMarkInvoiceAsSentLoading: isLoading };
};
