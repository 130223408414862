import { ButtonIconNew, EyeIcon, TextInputNew } from '@components';
import { HelperTextAlignment, InputFormatFn } from '@utils';
import React, { useState } from 'react';
import { Control, FieldValues, RegisterOptions, UseFormWatch } from 'react-hook-form';
import { FormElementAppearance } from '../types';

interface Props {
  name: string;
  testcy: string;
  control?: Control;
  width?: string;
  disabled?: boolean;
  appearance?: FormElementAppearance;
  validation?: RegisterOptions;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  formatFn?: InputFormatFn;
  formatOnBlurFn?: InputFormatFn;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onFocus?: (e) => void;
  isPendingValue?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  helperTextAlign?: HelperTextAlignment;
  multiline?: boolean;
  rows?: string;
  watch?: UseFormWatch<FieldValues>;
  isPassword?: boolean;
  readOnly?: boolean;
  adornmentAlignment?: 'auto' | 'center';
  dialogStyle?: boolean;
}

export const PasswordInput = ({
  name,
  testcy,
  control,
  width = '200px',
  disabled = false,
  appearance = FormElementAppearance.Old,
  validation = {},
  label,
  placeholder = '',
  endAdornment,
  startAdornment,
  formatFn,
  formatOnBlurFn,
  onChange,
  onBlur,
  onKeyUp,
  onKeyDown,
  onFocus = e => {},
  isPendingValue = false,
  autoFocus = false,
  helperText,
  helperTextAlign = HelperTextAlignment.Left,
  watch,
  readOnly = false,
  dialogStyle = false,
  adornmentAlignment = 'auto',
}: Props) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const toggleVisibility = () => {
    setIsPasswordShown(prev => !prev);
  };

  return (
    <TextInputNew
      name={name}
      testcy={testcy}
      control={control}
      width={width}
      disabled={disabled}
      appearance={appearance}
      validation={validation}
      label={label}
      placeholder={placeholder}
      endAdornment={
        <>
          <ButtonIconNew color="transparent" size="small" testcy={`${testcy}-toggle`} onClick={toggleVisibility}>
            {isPasswordShown ? <EyeIcon isClosed={false} /> : <EyeIcon isClosed={true} />}
          </ButtonIconNew>
          {endAdornment}
        </>
      }
      startAdornment={startAdornment}
      formatFn={formatFn}
      formatOnBlurFn={formatOnBlurFn}
      onChange={onChange}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      isPendingValue={isPendingValue}
      autoFocus={autoFocus}
      helperText={helperText}
      helperTextAlign={helperTextAlign}
      watch={watch}
      isPassword={!isPasswordShown}
      readOnly={readOnly}
      dialogStyle={dialogStyle}
      adornmentAlignment={adornmentAlignment}
    />
  );
};
