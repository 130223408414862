import { ArrowIcon, ButtonIconNew } from '@components';
import { useMeasure } from '@hooks';
import React, { useEffect, useRef } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { ResolveHintsFunction } from '../../hooks/useHintResolver';
import { MessageComposer } from '../../hooks/useMessageComposer';
import { MentionsContainer } from '../MentionsContainer/mentionsContainer';

import './mentionsInputStyles.scss';
import './messageComposeBar.scoped.scss';

type Props = {
  dense: boolean;
  isSending: boolean;
  sendCurrentMessage: () => void;
  composer: MessageComposer;
  resolveHints: ResolveHintsFunction;
};

// Line height is defined in scss, but it is also needed in JS to compute how many lines are in the input
const INPUT_LINE_HEIGHT_PX = 18;

export const MessageComposeBar = ({ dense, isSending, sendCurrentMessage, composer, resolveHints }: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!isSending) {
      inputRef.current?.focus();
    }
  }, [isSending]);

  const { height: inputHeight } = useMeasure(inputRef);

  const shouldShowSendButton = composer.currentMessage.length > 0;
  const isMultiline = composer.currentMessage.includes('\n') || inputHeight !== INPUT_LINE_HEIGHT_PX;

  const containerClassName = `message-compose-bar-container ${dense ? 'dense' : ''}`;
  const styledInputContainerClassName = isMultiline ? 'multiline-styled-input-container' : 'styled-input-container';

  return (
    <div className={containerClassName}>
      <div className={styledInputContainerClassName}>
        <MentionsInput
          className="mentions"
          value={composer.currentMessage}
          disabled={isSending}
          placeholder={'Napisz wiadomość...'}
          onChange={e => composer.updateMessage(e.target.value)}
          onKeyPress={e => {
            if (!e.shiftKey && e.key === 'Enter') {
              e.preventDefault();
              sendCurrentMessage();
            }
          }}
          forceSuggestionsAboveCursor
          inputRef={inputRef}
          customSuggestionsContainer={children => <MentionsContainer>{children}</MentionsContainer>}
        >
          <Mention trigger="@" data={resolveHints} onAdd={composer.addTag} markup="@[__display__]" />
        </MentionsInput>
      </div>
      {shouldShowSendButton && (
        <ButtonIconNew testcy="send-message-button" color="primary" size="extra-small" shouldResizeChildSvg={false} onClick={sendCurrentMessage}>
          <ArrowIcon colorType="white" direction='up' />
        </ButtonIconNew>
      )}
    </div>
  );
};
