import { useEffect, useState } from 'react';
import { useDebounce } from './useDebounce';

export const useIsAllFalse = (valuesList: boolean[], delay = 0, defaultValue = true): boolean => {
  const [currentValues, setCurrentValue] = useState(defaultValue);
  const isAllFalse = useDebounce(currentValues, delay);

  useEffect(() => {
    const value = valuesList.some(v => v);
    setCurrentValue(value);
  }, [valuesList]);

  return isAllFalse;
};
