import React from 'react';
import { Avatar } from '@mui/material';
import { ButtonIconNew, CloseIcon } from '@components';
import { ChatState } from '../../hooks/useChatState';

import './minimizedChat.scoped.scss';
import './minimizedChat.scss';

type Props = {
  changeState: (state: ChatState) => void;
  channelName: string;
};

export const MinimizedChat = (props: Props) => {
  const { changeState, channelName, ...otherProps } = props;

  const firstLetter = channelName.charAt(0)?.toUpperCase();

  return (
    <div {...otherProps} className="minimized-chat-badge">
      <button className="minimized-chat" data-testcy="minimized-chat" onClick={() => changeState('open')}>
        <Avatar classes={{ root: 'minimized-chat-avatar' }}>{firstLetter}</Avatar>
        <span className="minimized-chat-label">{channelName}</span>
      </button>
      <ButtonIconNew
        testcy="minimized-chat-close-button"
        className="minimized-chat-close-button"
        color="white"
        size="small"
        shouldResizeChildSvg={false}
        onClick={() => changeState('closed')}
      >
        <CloseIcon colorType="grey600" />
      </ButtonIconNew>
    </div>
  );
};
