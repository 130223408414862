import { CustomObject, PhoneAndFaxData, SuffixInvoiceNumberData } from '@interfaces';
import { FieldValues, SetValueConfig, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { bankNameNumbers, emailValidateRegexp, InvoiceNumberFormat, isNumberRegexp, stringToNumberSecondOptions } from '@utils';
import { dateFormatter } from '@lib';
import { KeyboardEvent } from 'react';
import { isArray, isUndefined } from 'lodash';

export const setFormValues = (
  setValue: (name: string, value: string, option?: SetValueConfig) => void,
  formData: CustomObject,
  option: SetValueConfig = {}
) => {
  for (let [key, value] of Object.entries(formData)) {
    if (key === 'invoiceNumberSuffixes') {
      value.forEach((suffix: SuffixInvoiceNumberData, index: number) => {
        setValue(`suffix-${index}`, suffix.value);
      });
    }
    if (typeof value !== 'object') {
      setValue(key, value, option);
    }
  }
};

export const isMonthNumber = (value: string) => {
  if (isNaN(+value)) return;
  const valueNum = +value;
  return valueNum >= 1 && valueNum <= 12;
};

export const isYearNumber = (value: string) => {
  if (isNaN(+value)) return;
  if (value.length !== 4) return;
  return true;
};

export const formErrorMsg = Object.freeze({
  isRequired: 'To pole jest wymagane',
  maxLength: (value: number, actualLength?: number) =>
    `Maksymalna liczba znaków tego pola to ${value}` + (isUndefined(actualLength) ? '' : ` (podano ${actualLength})`),
  invalidMail: 'Nieprawidłowy adres e-mail',
  tooLongEmailLocal: 'Zbyt długa pierwsza część adresu e-mail',
  tooLongEmailDomain: 'Zbyt długa domena adresu e-mail',
  exactLength: (value: number, actualLength?: number) => `Wymagana liczba znaków ${value}` + (isUndefined(actualLength) ? '' : ` (podano ${actualLength})`),
  exactLengthOr: (value: number, secondValue: number, actualLength?: number) =>
    `Wymagana liczba znaków ${value} lub ${secondValue}` + (isUndefined(actualLength) ? '' : ` (podano ${actualLength})`),
  noBankName: 'Brak nazwy banku',
  invalidCountryCode: 'Nieprawidłowy kod kraju',
  incorrectIBANLength: 'Numer konta powinien mieć 26 cyfr',
  incorrectPeselLength: 'Numer PESEL powinien mieć 11 cyfr',
  minLength: (value: number, actualLength?: number) =>
    `Minimalna liczba znaków tego pola to ${value}` + (isUndefined(actualLength) ? '' : ` (podano ${actualLength})`),
  postalCode: `Kod pocztowy powinien mieć 5 cyfr`,
  foreignPostalCodeTooLong: `Zagraniczny kod pocztowy może mieć maksymalnie 20 znaków`,
  suffixInvoiceNumber: `Przyrostek powinien posiadać 8 znaków tylko liczby i litery`,
  incorrectNipLength: 'NIP powinien mieć 10 cyfr',
  foreignTaxNumberTooLong: 'Zagraniczny NIP może mieć maksymalnie 18 znaków',
  incorrectNipControlNumber: 'Nieprawidłowy numer NIP', //cyfra kontrolna się nie zgadza
  incorrectRegonLength: 'REGON powinien mieć 9 lub 14 cyfr',
  incorrectRegonControlNumber: 'Nieprawidłowy numer REGON', //cyfra kontrolna się nie zgadza
  incorrectPhone: 'Nieprawidłowy numer telefonu',
  incorrectInvoiceName: 'Nieprawidłowa nazwa faktury',
  incorrectDate: 'Nieprawidłowa data',
  incorrectAmount: 'Kwota powinna być większa od 0',
  incorrectAmountCorrection: 'Kwota powinna być większa lub równa 0',
  incorrectAmountToPay: 'Kwota nie może przekroczyć kwoty pozostałej do zapłaty',
  incorrectAmountToPaidCorrection: 'Wprowadzono kwotę mniejszą od kwoty korekty',
  incorrectQuantity: 'Ilość powinna być większa od 0',
  incorrectQuantityCorrection: 'Ilość powinna być większa lub równa 0',
  accountingId: 'Dodajesz swój własny Id, będziesz odpowiedzialny za księgowanie własnej firmy',
  correctionInvoiceDate: 'Data powinna być późniejsza niż data wystawienia faktury korygowanej',
  duplicateInvoiceDate: 'Data powinna być późniejsza niż data wystawienia faktury',
  costVatRateDuplicated: 'Stawka VAT nie może się powtarzać',
  incorrectAmountMoreThanZero: 'Kwota powinna być większa od 0',
  incorrectAmountLessThanZero: 'Kwota powinna być mniejsza od 0',
  incorrectAmountDifferentThanZero: 'Kwota powinna być równa 0',
  futureDate: 'Nie można wybrać daty z przyszłości',
  fieldInRange: (min: number, max: number) => `Wartość powinna być w przedziale od ${min} do ${max}`,
});

export const standardValidators = Object.freeze({
  requiredMaxNumber: (value: number) => {
    return {
      required: formErrorMsg.isRequired,
      maxLength: {
        value: value,
        message: formErrorMsg.maxLength(value),
      },
    };
  },
  requiredExactLength: (value: number) => {
    return {
      required: formErrorMsg.isRequired,
      maxLength: {
        value: value,
        message: formErrorMsg.exactLength(value),
      },
      minLength: {
        value: value,
        message: formErrorMsg.exactLength(value),
      },
    };
  },
  requiredMinNumber: (value: number) => {
    return {
      required: formErrorMsg.isRequired,
      minLength: {
        value: value,
        message: formErrorMsg.minLength(value),
      },
    };
  },
  requiredDate: {
    required: formErrorMsg.isRequired,
    validate: (value: Date) => {
      return dateFormatter.isValidDateObject(value) || formErrorMsg.incorrectDate;
    },
  },
  validDate: (value: Date) => {
    return !value || dateFormatter.isValidDateObject(value) || formErrorMsg.incorrectDate;
  },
  noFutureDate: (value: Date) => {
    const now = new Date();
    return value <= now || formErrorMsg.futureDate;
  },
  validPaymentAmount: (leftToPay: string, acceptZero?: boolean) => (amount: string) => {
    const amountToPayAsNumber = stringToNumberSecondOptions(leftToPay);
    const valueAsNumber = stringToNumberSecondOptions(amount);

    if (acceptZero && valueAsNumber === 0) return true; 

    if (amountToPayAsNumber > 0 && valueAsNumber <= 0) {
      return formErrorMsg.incorrectAmountMoreThanZero;
    }
    if (amountToPayAsNumber < 0 && valueAsNumber >= 0) {
      return formErrorMsg.incorrectAmountLessThanZero;
    }
    if ((amountToPayAsNumber >= 0 && valueAsNumber > amountToPayAsNumber) || (amountToPayAsNumber < 0 && valueAsNumber < amountToPayAsNumber)) {
      return formErrorMsg.incorrectAmountToPay;
    }
  },
  requiredIsCorrectInvoiceName: (type: InvoiceNumberFormat) => {
    // currently unused
    return {
      required: formErrorMsg.isRequired,
      validate: (value: string) => {
        const splittedValue = value.split('/');
        const splittedValueLength = splittedValue.length;

        if (splittedValueLength > 3) return formErrorMsg.incorrectInvoiceName;
        if (type === InvoiceNumberFormat.Monthly && splittedValueLength !== 3) return formErrorMsg.incorrectInvoiceName;
        if (type === InvoiceNumberFormat.Yearly && splittedValueLength !== 2) return formErrorMsg.incorrectInvoiceName;

        const isNumberValid = !isNaN(+splittedValue[0]);

        if (!isNumberValid) return formErrorMsg.incorrectInvoiceName;

        if (type === InvoiceNumberFormat.Monthly) {
          const isMonthValid = isMonthNumber(splittedValue[1]);
          const isYearValid = isYearNumber(splittedValue[2]);
          const isValid = isMonthValid && isYearValid;
          return isValid ? isValid : formErrorMsg.incorrectInvoiceName;
        }

        if (type === InvoiceNumberFormat.Yearly) {
          const isYearValid = isYearNumber(splittedValue[1]);
          return isYearValid ? isYearValid : formErrorMsg.incorrectInvoiceName;
        }
      },
    };
  },
  requiredEmailList: (tagsData: string[]) => {
    const validationObj = {
      pattern: {
        value: emailValidateRegexp,
        message: formErrorMsg.invalidMail,
      },
      maxLength: {
        value: 254,
        message: formErrorMsg.maxLength(254),
      },
      validate: (emails: string | string[]) => {
        if ((Array.isArray(emails) && emails.length === 0) || (emails.length === 0 && tagsData.length === 0)) {
          return formErrorMsg.isRequired;
        }
        return true;
      },
    };

    return validationObj;
  },
});

export const getBankNameByAccountNumber = (value: string, userBankNames: CustomObject = {}): { value: string | null; isFromBase: boolean } => {
  const bankNameData = { value: null, isFromBase: false };
  if (!value) return bankNameData;
  const valueWithoutSpace = value.replace(/ /g, '');

  if (valueWithoutSpace.length < 6) return bankNameData;
  const bankNumber = valueWithoutSpace.slice(2, 6);
  const bankName = bankNameNumbers[bankNumber];

  if (bankName) {
    bankNameData.value = bankName;
    bankNameData.isFromBase = true;
    return bankNameData;
  }

  bankNameData.value = userBankNames[valueWithoutSpace] ? userBankNames[valueWithoutSpace] : null;
  return bankNameData;
};

export const getPropertySuffixNumbers = (data: CustomObject, value: string): string[] => {
  const rowNumbers = [];
  for (const property in data) {
    if (property.slice(0, value.length) === value) {
      const number = property.slice(value.length);
      rowNumbers.push(number);
    }
  }
  return rowNumbers;
};

export const getPhoneAndFaxCountOfIntegers = (value: PhoneAndFaxData) => {
  const getValue = value.replaceAll(' ', '').replaceAll('+', '').replaceAll('-', '');
  return getValue.length;
};

export const scrollToFirstError = (errors: string[]) => {
  errors.forEach(e => {
    const firstErrorElement = document.getElementById(e as any);
    firstErrorElement.scrollIntoView({ behavior: `smooth`, block: 'center' });
  });
};

type FormInputElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

/**
 * Checks if the event is an Enter-pressed kind of event and if it is, then it gets one of the next form inputs and moves the focus to that element.
 * @param event The keyboard event to be checked.
 * @param step How many form elements should be stepped over, with the last getting focused.
 * @param inputName The name of the form input to be focused. If defined, `step` is not used.
 * @returns Whether the element was able to be focused.
 */
export function focusOnNextFormInput(event: KeyboardEvent<HTMLDivElement | HTMLButtonElement>, stepOrName: number | string): boolean {
  const target = event.target as FormInputElement;
  if (event.key !== 'Enter' || target.nodeName !== 'INPUT') return;

  event.preventDefault();

  return _focusOnNextFormInput(stepOrName, target);
}

/**
 * Gets one of the next form inputs and moves the focus to that element.
 * @param step How many form elements should be stepped over, with the last getting focused.
 * @returns Whether the element was able to be focused.
 */
export function focusOnNextFormInput_FORCE(step: number, target: FormInputElement): boolean;
/**
 * Gets an input with the specified name and moves the focus to that element.
 * @param inputName The name of the element to be focused.
 * @param target The currently focused form input element.
 * @returns Whether the element was able to be focused.
 */
export function focusOnNextFormInput_FORCE(inputName: string): boolean;
export function focusOnNextFormInput_FORCE(stepOrName: number | string, target?: FormInputElement): boolean {
  return _focusOnNextFormInput(stepOrName, target);
}

function _focusOnNextFormInput(step: number, target: FormInputElement): boolean;
function _focusOnNextFormInput(inputName: string): boolean;
function _focusOnNextFormInput(stepOrName: number | string, target?: FormInputElement): boolean;
function _focusOnNextFormInput(stepOrName: number | string, target?: FormInputElement): boolean {
  if (typeof stepOrName == 'string') {
    const element = document.getElementById(stepOrName) as HTMLInputElement;
    element?.focus();
    if (element?.select) {
      element?.select();
    }
    return document.activeElement === element;
  }
  const form = target.form;
  const index = [...form].indexOf(target);
  const element = form[index + stepOrName] as HTMLInputElement;
  element?.focus();
  if (element?.select) {
    element?.select();
  }
  return document.activeElement === element;
}

/**
 * Disables or enables a field, without changing the state of other fields.
 * @param setValue the `setValue` function from the `useForm()` hook.
 * @param getValues the `getValues` function from the `useForm()` hook.
 * @param field the name of the field to be disabled or enabled.
 * @param disable if set to true the field will be disabled. If set to false, it will be enabled.
 */
export const setDisabledField = (setValue: UseFormSetValue<FieldValues>, getValues: UseFormGetValues<FieldValues>, field: string, disable: boolean): void => {
  if (disable) {
    addDisabledField(setValue, getValues, field);
    return;
  }
  removeDisabledField(setValue, getValues, field);
};
/**
 * Disables a field or multiple fields, without changing the state of other fields.
 * @param setValue the `setValue` function from the `useForm()` hook.
 * @param getValues the `getValues` function from the `useForm()` hook.
 * @param fields a single field or an array of fields to be set as disabled.
 */
export const addDisabledField = (setValue: UseFormSetValue<FieldValues>, getValues: UseFormGetValues<FieldValues>, fields: string | string[]): void => {
  let fieldsArray: string[];
  if (typeof fields == 'string') {
    fieldsArray = [fields];
  } else {
    fieldsArray = fields;
  }
  const oldValues = getValues().disabledValues;
  if (!isArray(oldValues)) {
    setValue('disabledValues', fieldsArray);
    return;
  }
  const newValues = [...oldValues, ...fieldsArray];

  setValue('disabledValues', newValues);
};

/**
 * Enables a field or multiple fields, without changing the state of other fields.
 * @param setValue the `setValue` function from the `useForm()` hook.
 * @param getValues the `getValues` function from the `useForm()` hook.
 * @param predicate a single field name or a function that determines which fields should be enabled. Return `true` if should be enabled, `false` if should be left as-is.
 */
export const removeDisabledField = (
  setValue: UseFormSetValue<FieldValues>,
  getValues: UseFormGetValues<FieldValues>,
  predicate: string | ((value: string) => boolean)
) => {
  let predicateFn: (value: string) => boolean;
  if (typeof predicate == 'string') {
    predicateFn = v => v === predicate;
  } else {
    predicateFn = predicate;
  }
  const oldValues = getValues().disabledValues;
  if (!isArray(oldValues)) {
    setValue('disabledValues', []);
    return;
  }

  const newValues = oldValues.filter(v => !predicateFn(v));
  setValue('disabledValues', newValues);
};

export const countryValidatorMessage = (countryCode: string) => {
  let message: string = 'Zagraniczny NIP może mieć maksymalnie 18 znaków';
  switch (countryCode) {
    case 'AT':
      message = 'Zagraniczny NIP powinien zaczynać się od litery U, a następnie z 8 cyfr';
      break;
    case 'BE':
      message = 'Zagraniczny NIP powinien mieć 10 cyfr';
      break;
    case 'BG':
      message = 'Zagraniczny NIP powinien mieć 9 lub 10 cyfr';
      break;
    case 'CN':
      message = 'Zagraniczny NIP może mieć maksymalnie 18 znaków';
      break;
    case 'CY':
      message = 'Zagraniczny NIP powinien mieć 8 cyfr, a następnie 1 literę';
      break;
    case 'CZ':
      message = 'Zagraniczny NIP powinien mieć od 8 do 10 cyfr';
      break;
    case 'DK':
      message = 'Zagraniczny NIP powinien mieć 8 cyfr';
      break;
    case 'EE':
      message = 'Zagraniczny NIP powinien mieć 9 cyfr';
      break;
    case 'FI':
      message = 'Zagraniczny NIP powinien mieć 8 cyfr';
      break;
    case 'FR':
      message = 'Zagraniczny NIP powinien mieć 11 znaków, na pierwszym i drugim miejscu może wystąpić litera';
      break;
    case 'EL': //Grecja
      message = 'Zagraniczny NIP powinien mieć 9 cyfr';
      break;
    case 'ES':
      message = 'Zagraniczny NIP powinien mieć 9 znaków, na pierwszym lub ostatnim miejscu powinna być litera';
      break;
    case 'NL':
      message = 'Zagraniczny NIP powinien mieć 9 cyfr, nastepnie literę "B" i 2 cyfry';
      break;
    case 'IE': //Irlandia jest rózna zmienic
      message = 'Zagraniczny NIP powinien mieć 8 lub 9 znaków, na 2 miejscu może być litera, na końcu powinna być 1 lub 2 litery';
      break;
    case 'LT':
      message = 'Zagraniczny NIP powinien mieć 9 lub 12 cyfr';
      break;
    case 'LU':
      message = 'Zagraniczny NIP powinien mieć 8 cyfr';
      break;
    case 'LV':
      message = 'Zagraniczny NIP powinien mieć 11 cyfr';
      break;
    case 'MT':
      message = 'Zagraniczny NIP powinien mieć 8 cyfr';
      break;
    case 'DE':
      message = 'Zagraniczny NIP powinien mieć 9 cyfr';
      break;
    case 'PT':
      message = 'Zagraniczny NIP powinien mieć 9 cyfr';
      break;
    case 'RU':
      message = 'Zagraniczny NIP powinien mieć 10 lub 12 cyfr';
      break;
    case 'RO':
      message = 'Zagraniczny NIP powinien mieć od 2 do 10 cyfr';
      break;
    case 'SK':
      message = 'Zagraniczny NIP powinien mieć 10 cyfr';
      break;
    case 'SI':
      message = 'Zagraniczny NIP powinien mieć 8 cyfr';
      break;
    case 'SE':
      message = 'Zagraniczny NIP powinien mieć 12 cyfr';
      break;
    case 'TR':
      message = 'Zagraniczny NIP powinien mieć 10 cyfr';
      break;
    case 'HU':
      message = 'Zagraniczny NIP powinien mieć 8 cyfr';
      break;
    case 'GB':
      message = 'Zagraniczny NIP powinien mieć 9 lub 12 cyfr';
      break;
    case 'IT':
      message = 'Zagraniczny NIP powinien mieć 11 cyfr';
      break;
    case 'HR':
      message = 'Zagraniczny NIP powinien mieć 11 cyfr';
      break;
    case 'UA':
      message = 'Zagraniczny NIP powinien mieć 12 cyfr';
      break;
    case 'XI': //Irlandia Północna
      message = 'Zagraniczny NIP powinien mieć od 2 do 13 znaków';
      break;
  }
  return message;
};

const requiredNipCountryCodes = [
  'GB',
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'EL',
  'ES',
  'NL',
  'IE',
  'LT',
  'LU',
  'LV',
  'MT',
  'DE',
  'PT',
  'RO',
  'SK',
  'SI',
  'SE',
  'HU',
  'IT',
  'HR',
  'XI',
];

export const isNipRequired = (countryCode: string) => {
  return requiredNipCountryCodes.includes(countryCode);
};
