import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInputNew, TheSelect } from '@components';
import { ZwOptions, formErrorMsg, standardValidators } from '@utils';

import './zwSelector.scoped.scss';

export const ZwSelector = () => {
  const { watch, resetField } = useFormContext();
  const watchZwOptions = watch('zwOptions');

  const otherZW = watchZwOptions === 'Other';

  return (
    <div className="zw-container">
      <TheSelect
        testcy="zw-options"
        watch={watch}
        name="zwOptions"
        appearance="primary"
        size="medium"
        label="Proszę określić podstawę zwolnienia z podatku VAT"
        width="523px"
        options={ZwOptions}
        hideEmptyOption={true}
        onChange={() => {
          resetField('zwVatOther');
        }}
        validation={{
          required: formErrorMsg.isRequired,
        }}
      />
      {otherZW && (
        <TextInputNew
          testcy="zw-vat-other"
          name="zwVatOther"
          appearance="primary"
          size="medium"
          width="523px"
          placeholder="Przepis, na podstawie którego stosowane jest zwolnienie z podatku VAT"
          validation={standardValidators.requiredMaxNumber(500)}
          defaultValue=""
        />
      )}
    </div>
  );
};
