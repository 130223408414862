import { SharedCostList } from '@features';
import { CostDetails, getCostDataById } from '@services';
import React from 'react';
import { Navigate } from 'react-router';
import { InDevelopmentTab } from 'src/features/shared/sharedCostList/components';
import { TheRouteObject } from '../types';

export const costList: TheRouteObject[] = [
  {
    index: true,
    element: <SharedCostList />,
  },
  {
    path: 'edit-cost/:id',
    element: <InDevelopmentTab />,
    loader: async ({ params }) => {
      return await getCostDataById(+params.id);
    },
    handle: {
      crumb: (data: CostDetails) => {
        if (data.invoicingData?.number) return `Edytuj koszt ${data.invoicingData.number}`;
        return `Digitalizuj plik "${data.originalFilename}"`;
      },
    },
  },
  {
    path: '*',
    element: <Navigate to="/costs-list" />,
  },
];
