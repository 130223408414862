import { PopupMenu } from '@components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { uniqueId } from 'lodash';
import React from 'react';
import { ActionsSettings, TableHeaderInterface, TableRowInterface } from './theTable.interfaces';

import './theTable.scoped.scss';
import { Action } from './components';
interface Props {
  testcy: string;
  header: TableHeaderInterface[];
  rows: TableRowInterface[];
  actionSettings?: ActionsSettings;
  actionMenuItems?: any;
  showHeaderAndFooter?: boolean;
  centerCells?: boolean;
  showIconActions?: boolean;
  onClickRemove?: (data: TableRowInterface) => void;
  onClickDownload?: (data: TableRowInterface) => void;
  onClickSyncToggle?: (id: string, newState: boolean) => void;
}

export const TheTable = ({
  testcy,
  header,
  rows,
  actionSettings,
  actionMenuItems,
  showHeaderAndFooter = true,
  showIconActions = false,
  centerCells = false,
  onClickRemove = () => {},
  onClickDownload = () => {},
  onClickSyncToggle = () => {},
}: Props) => {
  const onClickMenuItem = (action: string, row: TableRowInterface) => {
    if (action === 'download') return onClickDownload(row);
    if (action === 'delete') return onClickRemove(row);
  };

  return (
    <Table>
      {showHeaderAndFooter && (
        <TableHead>
          <TableRow>
            {header.map((h, index) => {
              return (
                <TableCell
                  data-testcy={`${testcy}-header-column-${index}`}
                  key={`${h.keyPrefix || ''}-${h.label}`}
                  className={`bold ${!h.autoCount && !h.action && 'header'} ${h.autoCount && 'auto-count-header'}`}
                >
                  {h.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={row.id}>
            {header.map((h, colIndex) => {
              if (h.action) {
                const rowActions = row[h.data];
                const cannotDelete = (actionSettings?.cannotDeleteLastRow && rows.length === 1) || rowActions.removeDisabled;

                if (!rowActions) return <TableCell />;

                return (
                  <TableCell
                    data-testcy={`${testcy}-body-column-${colIndex} ${testcy}-body-row-${index}`}
                    key={`${row.id}-${uniqueId()}`}
                    className={`fit-content ${h.customCellClass}  ${!!centerCells ? 'center-cell' : ''}`}
                    sx={{
                      minWidth: h.minWidth || 'unset',
                      width: h.width || 'unset',
                    }}
                    align="left"
                    onClick={e => e.stopPropagation()}
                  >
                    {!showIconActions ? (
                      <PopupMenu index={index} menuItems={actionMenuItems} onClickMenuItem={actionType => onClickMenuItem(actionType, row)} />
                    ) : (
                      <div className="centering-container narrow">
                        <Action
                          row={row}
                          index={index}
                          rowActions={rowActions}
                          cannotDelete={cannotDelete}
                          onClickRemove={onClickRemove}
                          onClickDownload={onClickDownload}
                          onClickSyncToggle={onClickSyncToggle}
                        />
                      </div>
                    )}
                  </TableCell>
                );
              }
              const content = h.autoCount ? <p className="centering-container">{index + 1}.</p> : row[h.data].content || '';
              return (
                <TableCell
                  data-testcy={`${testcy}-body-column-${colIndex} ${testcy}-body-row-${index}`}
                  key={`${h.keyPrefix || ''}-${row.id}-${h.label}`}
                  className={`${h.customCellClass} ${!!h.autoCount ? 'bold' : ''} ${!!centerCells ? 'center-cell' : ''}`}
                  sx={{
                    minWidth: h.minWidth || 'unset',
                    maxWidth: h.maxWidth || 'unset',
                    width: h.width || 'unset',
                  }}
                  align={'left'}
                >
                  {row[h.data].component ? row[h.data].component : content}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
