import { DocumentFileCategoryEnum } from '@utils';

export interface APIFile {
  id: number;
  uploadDate: string; // "YYYY-MM-DD'T'HH:mm:ss.ms"
  originalFilename: string;
  byteSize: number;
  category: DocumentFileCategoryEnum | null;
}

export const FileType = {
  Document: 'document',
  Spreadsheet: 'spreadsheet',
  PDF: 'pdf',
  Image: 'image',
} as const;
export type FileType = typeof FileType[keyof typeof FileType];

export interface FileObject {
  id: any;
  originalFilename: string;
  fileShortName: string;
  fileExtension: string;
  fileType?: FileType;
  byteSize: number;
  uploadDate?: Date;
  category: DocumentFileCategoryEnum | null;
}

export const apiFileToFileObject = (file: APIFile): FileObject => {
  const { originalFilename, byteSize, uploadDate: uploadDateRaw, id, category } = file;
  const uploadDate = new Date(uploadDateRaw || new Date());
  const parts = originalFilename.split('.');
  const fileExtension = parts.pop();
  const fileShortName = parts.join('.');
  const fileType = _mapExtensionToFileType(fileExtension);

  return {
    id,
    originalFilename,
    byteSize,
    uploadDate,
    fileExtension,
    fileShortName,
    fileType,
    category,
  };
};

const FILE_TYPE_MAP = {
  svg: FileType.Image,
  png: FileType.Image,
  jpg: FileType.Image,
  jpeg: FileType.Image,
  gif: FileType.Image,
  docx: FileType.Document,
  doc: FileType.Document,
  odt: FileType.Document,
  pdf: FileType.PDF,
  xls: FileType.Spreadsheet,
  xlsx: FileType.Spreadsheet,
  xml: FileType.Spreadsheet,
  csv: FileType.Spreadsheet,
  tsv: FileType.Spreadsheet,
} as const;

function _mapExtensionToFileType(ext: string): FileType | undefined {
  if (ext in FILE_TYPE_MAP) {
    return FILE_TYPE_MAP[ext];
  }
  return undefined;
}

export const FILE_SIZE_25MB = 26_214_400; // 25*1024*1024
export const FILE_SIZE_10MB = 10_485_760; // 10*1024*1024
