import { RequestErrorMessage } from '@interfaces';
import { displaySnackBarMessage } from '@lib';
import { isArray } from 'lodash';

export const apiErrorMessages = Object.freeze({
  unexpectedError: 'Wystapił nieoczekiwany błąd',
  accountingPermissionWrongId: 'Firma księgowa o takim identyfikatorze nie istnieje',

  InvoiceNumberFormatIncorrect: 'Niepoprawny format numeru faktury',
  InvoiceNumberMustBeUnique: 'Numer faktury musi być unikalny',
  InvoiceNumberMustNotBreakNumberContinuation: 'Numer faktury musi być kontynuacją poprzedniej faktury',
  CannotChangeNumberFormatAfterIssueingFirstInvoiceForCurrentYear:
    'Nie można zmienić formatu numeracji faktur w roku po wystawieniu pierwszej faktury w tym roku',
  InvalidInvoiceStatus: 'Nieprawidłowy status faktury dla zadanej operacji',
  DuplicateBankAccountNumbers: 'Numery kont bankowych nie mogą się powtarzać',
  ContractorNipMustBeUnique: 'Istnieje już kontrahent o podanym numerze NIP',
  UnsupportedFileExtension: 'Niewspierany typ pliku',
  CannotSendDraftInvoice: 'Nie można wysłać niewystawionej faktury',
  CannotSendReminderForUnsentInvoice: 'Nie można wysłać upomnienia dla niewysłanej wcześniej faktury',
  CannotSendAlreadySentInvoice: 'Nie można wysłać wcześniej wysłanej faktury',
  MultipleVatExemptionTypes: 'Nie można utworzyć faktury z pozycjami o różnych zwolnieniach z podatku VAT',
  NoAccCredsFound: 'Nie znaleziono danych księgowych dla wybranej firmy',
  CannotUpdateInvoiceSentToAccounting: 'Nie można edytować faktury wysłanej do księgowości',
  MaxAccountingPermissionCountReached: 'Osiągnięto maksymalną liczbę przypisanych firm księgowych',
  DuplicateInvoiceNumberSuffixes: 'Przyrostki numeru faktury nie mogą się powtarzać',
  MultipleMainBankAccounts: 'Nie można wybrać kilku kont bankowych jako główne',
  InvalidCorrectionItems: 'Nieprawidłowe pozycje korygujące',
  CorrectionInvoiceMustDifferFromCorrectedInvoice: 'Faktura korygująca musi się różnić od korygowanej',
  MustExistAndBeUnsent: 'Nie można edytować kosztów wysłanych do księgowości',
  InvalidCostForDeletion: 'Wybrany koszt nie istnieje lub nie można go usunąć',
  PeselMustBeUniqueForCompany: 'Wybrany PESEL jest już przypisany do innego pracownika',
  NoCompanyBankAccounts: 'Brak zdefiniowanych kont bankowych dla wybranej firmy',
  CouldNotExportCostAsTransfer: 'Wystąpił problem podczas eksportu wybranego kosztu',
  FileSizeLimitExceeded: 'Przekroczono limit danych, które można przesłać',
  DateFromAfterDateTo: 'Data od nie może być późniejsza niż data do',
  InvalidEmploymentType: 'Nieprawidłowy typ umowy pracownika dla wybranej akcji',
  InvalidLeasingPercent: 'Nieprawidłowy procent odliczenia dla leasingu',
  InvalidCostForConfirmation: 'Tego kosztu nie można potwierdzić',
  DuplicateDateBeforeIssueDate: 'Duplikat faktury nie może istnieć przed datą wystawienia',
  InvalidContractorNip: 'Nieprawidłowy numer NIP kontrahenta',
});

export const displayUnhandledError = () => {
  displaySnackBarMessage('error', apiErrorMessages.unexpectedError);
};

export const handleApiError = (errors: RequestErrorMessage[]) => {
  if (!errors || !errors.length || !isArray(errors)) {
    displayUnhandledError();
    return;
  }
  let isSentUnhandledErrorMessage = false;
  errors.forEach(d => {
    const errorMessage = apiErrorMessages[d.errorCode];
    if (errorMessage) displaySnackBarMessage('error', errorMessage);
    else if (!isSentUnhandledErrorMessage) {
      displayUnhandledError();
      isSentUnhandledErrorMessage = true;
    }
  });
};

export const errorCode = Object.freeze({
  invoiceNumberFormatError: 'InvoiceNumberFormat',
});
