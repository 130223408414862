import { useForm } from 'react-hook-form';
import { EmployeesContractEnum } from 'src/services/employees';
import { useCreateEmployee } from './useCreateEmployee';
import { useEditEmployee } from './useEditEmployee';
import { scrollToFirstError } from '@utils';

type EmployeeFormData = {
  firstName: string;
  lastName: string;
  pesel: string;
  email?: string;
  phone?: string;
  position: string;
  grossSalary: string;
  city: string;
  street: string;
  zipCode: string;
  typeOfEmployment: EmployeesContractEnum;
  contractStartDate: string | Date;
  contractEndDate: string | Date;
};

type UseEmployeeFormProps = {
  defaultValues: EmployeeFormData;
  employeeId?: number;
  onSubmitted?: () => void;
};

export const useEmployeeForm = ({ defaultValues, employeeId, onSubmitted }: UseEmployeeFormProps) => {
  const form = useForm<EmployeeFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: defaultValues,
  });

  const { createEmployee, isLoading: isCreating } = useCreateEmployee({ onSuccess: onSubmitted });
  const { editEmployee, isLoading: isEditing } = useEditEmployee({ employeeId, onSuccess: onSubmitted });

  const onSubmit = form.handleSubmit(
    data => {
      if (employeeId) {
        editEmployee(data);
      } else {
        createEmployee(data);
      }
    },
    errors => {
      scrollToFirstError(Object.keys(errors));
    }
  );

  return {
    form,
    isSubmitting: isCreating || isEditing,
    onSubmit,
  };
};
