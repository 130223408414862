import React, { useEffect, useRef, useState } from 'react';
import './dragScrollWrapper.scope.scss';

interface Props {
  children: JSX.Element;
  id: string;
  onZoom?: (value: number) => void;
  zoom?: number;
  zoomTick?: number;
  handleScroll?: boolean;
  allowCopyText?: boolean;
}

export const DragoScrollWrapper = ({
  children,
  id,
  onZoom,
  zoom,
  zoomTick = 0.1,
  handleScroll,
  allowCopyText,
}: Props) => {
  const [pos, setPos] = useState({
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
    clientY: 0,
    scrollY: 0,
  });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = document.getElementById(id);
    if (onZoom && handleScroll) {
      if (!el) return;
      el.addEventListener('wheel', onScroll);
    }
    return () => el.removeEventListener('wheel', onScroll);
  }, [id, onZoom]);

  useEffect(() => {
    if (onZoom) onZoom(zoom);
  }, [onZoom, zoom]);

  const onScroll = (e: WheelEvent) => {
    const isDown = e.deltaY > 0;
    const newZoom = Number((isDown ? zoom - zoomTick : zoom + zoomTick).toFixed(1));
    onZoom(newZoom);
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (allowCopyText) return;
    setPos({
      ...pos,
      isScrolling: true,
      clientX: e.clientX,
      clientY: e.clientY,
    });
    ref.current.style.cursor = 'grabbing';
  };

  const onMouseUp = () => {
    if (allowCopyText) return;
    setPos({
      ...pos,
      isScrolling: false,
    });
    ref.current.style.cursor = 'grab';
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (allowCopyText) return;
    if (!pos?.isScrolling) return;
    const currentScrollX = pos.scrollX + e.clientX - pos.clientX;
    const currentScrollY = pos.scrollY + e.clientY - pos.clientY;
    ref.current.scrollTop = currentScrollY;
    ref.current.scrollLeft = currentScrollX;
    setPos({
      ...pos,
      scrollX: currentScrollX,
      clientX: e.clientX,
      clientY: e.clientY,
      scrollY: currentScrollY,
    });
  };

  return (
    <div
      id={id}
      className="drag-scroll-wrapper"
      style={{ cursor: allowCopyText ? 'default' : 'grab' }}
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      {children}
    </div>
  );
};
