import React from 'react';
import '../sharedTableList.scoped.scss';
import { TheHeaderFilter } from '@components';
import { useFormContext } from 'react-hook-form';
import { DocumentFileCategoryEnum, documentFileCategoryOptions } from '@utils';
import { HeaderData } from '../sharedTableList.types';
import { InvoiceStatus, Option } from '@interfaces';

interface Props {
  testcy: string;
  showMenu?: boolean;
  isHeader?: boolean;
  header?: HeaderData;
  actionMenuItems: Option[];
  onSetHeaderFilter?: (filter: InvoiceStatus[] | DocumentFileCategoryEnum[]) => void;
}

export const SharedTableListHeader = ({ testcy, showMenu = false, header, actionMenuItems, onSetHeaderFilter }: Props) => {
  const { setValue, getValues, control } = useFormContext?.() ?? {};
  return (
    <div className={'cost-list-item header-item'}>
      {header.name && (
        <div className="file-name-container">
          <div className="file-name" data-testcy={`${testcy}-file-name`}>
            {header.name}
          </div>
        </div>
      )}
      {header.tag && (
        <div className="tag-container" data-testcy={`${testcy}-tag`}>
          {header.tag}
          <TheHeaderFilter
            key={header.tag as string}
            control={control}
            getValues={getValues}
            setValue={setValue}
            onSetHeaderFilter={onSetHeaderFilter}
            name={'tag'}
            menuOptions={documentFileCategoryOptions}
          />
        </div>
      )}
      {header.date && (
        <div className="date-container" data-testcy={`${testcy}-created-date`}>
          {header.date}
        </div>
      )}
      <div className={`actions-container ${showMenu ? 'actions-container-menu' : `actions-container-icons-${actionMenuItems.length}`}`}></div>
    </div>
  );
};
