import { FieldValues } from 'react-hook-form';
import { ContractorData } from '@services';
import { BusinessEntity, zipCodeMatch } from '@utils';
import { isEqual, mapValues } from 'lodash';
import { CountryPrefix } from './components/contractorForm/contractorForm.utils';

export interface ContractorFormData {
  country: CountryPrefix;
  nip: string;
  foreignTaxNumber: string;
  regon: string;
  name: string;
  city: string;
  zipCode: string;
  foreignZipCode: string;
  street: string;
  companyOrPerson: string;
  shortName?: string;
  saveContractor?: boolean;
  emails: string[];
  dummy: boolean;
}

export const transformFormDataToContractorData = (data: ContractorFormData, companyId: string): ContractorData => {
  //get the correct zip code value
  let zipCode = data.country !== CountryPrefix.Poland ? data.foreignZipCode : data.zipCode;
  //if it is a polish zip-code, add the dash in the middle, if necessary
  if (data.country == CountryPrefix.Poland && zipCode && zipCodeMatch.test(zipCode)) {
    zipCode = zipCode.slice(0, 2) + '-' + zipCode.slice(2);
  }

  const nip = data.country !== CountryPrefix.Poland ? data.foreignTaxNumber : data.nip;

  //map all the form values
  return {
    name: data.name,
    friendlyName: data.shortName || null,
    country: data.country,
    nip: data.companyOrPerson === BusinessEntity.COMPANY ? nip : null,
    regon: data.companyOrPerson === BusinessEntity.COMPANY ? data.regon : null,
    address: {
      city: data.city,
      street: data.street,
      zipCode: zipCode,
    },
    isBusiness: data.companyOrPerson === BusinessEntity.COMPANY ? true : false,
    companyId: companyId ? +companyId : null,
    emails: data.emails,
    dummy: data.dummy,
  };
};

export const transformContractorDataToFormData = (data: ContractorData): ContractorFormData => {
  if (!data) return;

  const country = data.country === CountryPrefix.Other ? '' : data.country;

  return {
    country: country as CountryPrefix,
    nip: data.nip || '',
    foreignTaxNumber: data.nip || '',
    regon: data.regon || '',
    name: data.name || '',
    shortName: data.friendlyName || '',
    city: data.address?.city || '',
    zipCode: data.address?.zipCode || '',
    foreignZipCode: data.address?.zipCode || '',
    street: data.address?.street || '',
    companyOrPerson: data.isBusiness === true ? BusinessEntity.COMPANY : BusinessEntity.PERSON,
    emails: data.emails,
    dummy: data.dummy === true,
  };
};

export const compareEditedForm = (contractorOriginalData: ContractorFormData, editedData: FieldValues): boolean => {
  const contractorOriginalDataTransformed = { ...contractorOriginalData, dummy: '', emails: '' };
  const editedDataTransformed = { ...editedData, dummy: '', emails: '' };

  const transformedFormObject = mapValues(contractorOriginalDataTransformed, (val, _) => {
    return val || '';
  });
  return !isEqual(transformedFormObject, editedDataTransformed);
};
