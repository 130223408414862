import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type ChatState = 'closed' | 'open' | 'minimized' | 'maximized';

export const useChatState = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [chatState, setChatState] = useState<ChatState>('closed');

  const changeState = useCallback(
    (newState: ChatState) => {
      if (newState === 'open' && chatState === 'maximized') {
        return;
      }

      if (newState === 'maximized') {
        navigate('/chat');
      }

      setChatState(newState);
    },
    [chatState, setChatState, navigate]
  );

  useEffect(() => {
    if (location.pathname !== '/chat' && chatState === 'maximized') {
      setChatState('closed');
    }

    if (location.pathname === '/chat') {
      setChatState('maximized');
    }
  }, [location.pathname, chatState, setChatState]);

  return {
    chatState,
    changeState,
  };
};
