import { ButtonIconNew, DeleteIconNew, TextInputNew } from '@components';
import { focusOnNextFormInput, formErrorMsg } from '@utils';
import React from 'react';

import './suffixField.scss';

type Props = {
  index: number;
  onDeleteClick: () => void;
  onBlur: (value: string) => void;
};

export const SuffixField = ({ index, onDeleteClick, onBlur }: Props) => {
  return (
    <div className="suffix-field">
      <TextInputNew
        testcy={`suffix-list-input-${index}`}
        name={`suffix-${index}`}
        label="Numer faktur - przyrostek"
        width="100%"
        appearance="filled"
        onKeyDown={event => {
          focusOnNextFormInput(event, 3);
        }}
        validation={{
          required: formErrorMsg.isRequired,
        }}
        onBlur={onBlur}
        adornmentAlignment="center"
        endAdornment={
          <div className="end-adornment">
            <ButtonIconNew testcy={`suffix-list-delete-${index}`} size="extra-small" color="transparent" shouldResizeChildSvg={false} onClick={onDeleteClick}>
              <DeleteIconNew size={16} />
            </ButtonIconNew>
          </div>
        }
      />
    </div>
  );
};
