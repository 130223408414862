import { InvoiceBulkOperationsParams } from '@services';
import { useApiMutation } from '../useApiMutation';
import { snackbarMessagesHandler } from '@utils';

interface IUseMarkInvoiceAsPaid {
  markInvoiceAsPaid: (id: string, selectAll?: boolean) => void;
  isMarkInvoiceAsPaidLoading: boolean;
}

export const useMarkInvoiceAsPaid = (onSuccess: () => void): IUseMarkInvoiceAsPaid => {
  const { mutate, isLoading } = useApiMutation('invoiceMarkAsPaid');

  const markInvoiceAsPaid = (id: string, selectAll: boolean = false) => {
    const params: InvoiceBulkOperationsParams = {
      ids: [+id],
      selectAll,
    };
    mutate(params, {
      onSuccess: () => {
        snackbarMessagesHandler.invoiceRecordAmountPaid();
        onSuccess();
      }
    });
  }

  return { markInvoiceAsPaid, isMarkInvoiceAsPaidLoading: isLoading };
};
