import React from 'react';
import { TableHeaderInterface, TableRowInterface } from '@components';
import { ContractorsListData } from '@services';

export const tableHeader: TableHeaderInterface[] = [
  {
    label: 'Nazwa ',
    data: 'name',
    minWidth: 300,
    isSortable: true,
  },
  {
    label: '',
    data: 'action',
    width: 60,
    minWidth: 60,
    action: true,
    isSortable: false,
  },
];

export const contractorDataToTableRow = (data: ContractorsListData): TableRowInterface => {
  return {
    id: data?.id?.toString() || '',
    name: {
      content: <div className="ellipsis-text-one-line-g">{data.name}</div>,
    },
    action: {},
    stashedData: {
      name: `${data.name}`,
    },
  };
};

export const transformMenuItemsCostsList = dataList => {
  const menuItems = [];
  dataList.forEach(data => {
    menuItems.push([
      {
        label: 'Edytuj',
        value: 'edit',
      },
      {
        label: 'Usuń',
        value: 'delete',
      },
    ]);
  });

  return menuItems;
};
