import { EmployeesViewList, SharedEmployeeProfile } from '@features';
import { EmployeeDetails, getEmployeeDataById } from '@services';
import React from 'react';
import { Navigate } from 'react-router';
import { TheRouteObject } from '../types';

export const employeeList: TheRouteObject[] = [
  {
    index: true,
    element: <EmployeesViewList />,
  },
  {
    path: 'employee-preview/:employeeId',
    element: <SharedEmployeeProfile />,
    loader: async ({ params }) => {
      return await getEmployeeDataById(+params.employeeId);
    },
    handle: {
      crumb: (data: EmployeeDetails) => `${data.firstName} ${data.lastName}`,
    },
  },
  {
    path: '*',
    element: <Navigate to="/employees-list" />,
  },
];
