import insertImg from '@assets/images/insert.png';
import { ButtonMainNew, ErrorIcon, HeartIcon, LoaderIcon, MessageBox, PasswordInput, TextInputNew } from '@components';
import { focusOnNextFormInput, formErrorMsg } from '@utils';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { SettingsSection, SettingsSubSection, SettingsTab } from '../../components/settingsTab';
import { CompanyFormData } from '../../sharedCompanyForm.utils';
import { transformInsertErrorForm } from './accountantInsertSection.utils';
import { useCheckInsertStatusEachCompany } from './hooks';

import './accountantInsertSection.scss';

export const AccountantInsertSection = () => {
  const formContext = useFormContext<CompanyFormData>();
  const routeParams = useParams();
  const location = useLocation();

  const { isPendingCredentialsToInsertData, status, checkInsertStatus, resetStatus } = useCheckInsertStatusEachCompany();

  const checkStatus = () => {
    checkInsertStatus(routeParams.companyId);
  };

  const showCheckStatusForm = useMemo((): boolean => {
    return !location.pathname.includes('add-company');
  }, [location.pathname]);

  const checkInsertFormValidation = () => {
    const watchInsertInputs = formContext.watch(['dataBaseInsert', 'userNameInsert', 'passwordInsert']);
    transformInsertErrorForm(watchInsertInputs, formContext.register, formContext.unregister);
  };

  return (
    <SettingsTab title="Intergracje">
      <SettingsSection>
        <SettingsSubSection title="InsERT" subtitle="Dane dostępowe do bazy danych InsERT." image={<img src={insertImg} />}>
          <TextInputNew
            testcy="insert-database"
            name="dataBaseInsert"
            label="Baza danych"
            width="100%"
            appearance="filled"
            onKeyUp={event => {
              focusOnNextFormInput(event, 2);
            }}
            onChange={resetStatus}
            validation={{
              maxLength: {
                value: 1000,
                message: formErrorMsg.maxLength(1000),
              },
              onBlur: () => {
                checkInsertFormValidation();
              },
            }}
          />
          <TextInputNew
            testcy="insert-unsername"
            name="userNameInsert"
            label="Użytkownik"
            width="100%"
            appearance="filled"
            onKeyUp={event => {
              focusOnNextFormInput(event, 2);
            }}
            onChange={resetStatus}
            validation={{
              maxLength: {
                value: 100,
                message: formErrorMsg.maxLength(100),
              },
              onBlur: () => {
                checkInsertFormValidation();
              },
            }}
          />
          <PasswordInput
            testcy="insert-password"
            name="passwordInsert"
            label="Hasło"
            width="100%"
            appearance="filled"
            onKeyUp={event => {
              focusOnNextFormInput(event, 3);
            }}
            onChange={resetStatus}
            validation={{
              maxLength: {
                value: 100,
                message: formErrorMsg.maxLength(100),
              },
              onBlur: () => {
                checkInsertFormValidation();
              },
            }}
          />
          {!isPendingCredentialsToInsertData && status && (
            <div className="insert-text-wrapper">
              {status === 'success' ? (
                <MessageBox type="success">Dane są poprawne, połączono z serwerem</MessageBox>
              ) : (
                status === 'failure' && <MessageBox type="error">Brak połączenia z serwerem</MessageBox>
              )}
            </div>
          )}
          {showCheckStatusForm && status !== 'success' && (
            <div className="accountant-button-submit">
              <ButtonMainNew
                testcy="test-connection"
                colorType="primaryLight"
                sizeType="medium"
                onClick={checkStatus}
                startIcon={isPendingCredentialsToInsertData ? <LoaderIcon size="small" color="inherit" /> : null}
              >
                {isPendingCredentialsToInsertData ? 'Oczekuję na odpowiedź z serwera' : 'Przetestuj połączenie'}
              </ButtonMainNew>
            </div>
          )}
        </SettingsSubSection>
      </SettingsSection>
    </SettingsTab>
  );
};
