import { ButtonMain, DatePicker, LoaderIcon, NumberInput, SpecialInput, TextInputNew, TheSelect } from '@components';
import { dateFormatter } from '@lib';
import { focusOnNextFormInput, formErrorMsg, standardValidators, typeOfEmploymentOptions, stringToNumberSecondOptions } from '@utils';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';
import { useEmployeeForm } from 'src/hooks/employee/useEmployeeForm';

import { startOfYear, subYears } from 'date-fns';
import { EmployeeDetails } from 'src/services/employees';
import { transformEmployeeDataToFormData } from './ownerEmployeeForm.utils';
import './ownerEmployeeForm.scoped.scss';

interface Props {
  defaultValues?: EmployeeDetails;
  onSubmitted?: () => void;
}

export const EmployeeForm = ({ defaultValues, onSubmitted }: Props) => {
  const { employeeId } = useParams();

  const { form, isSubmitting, onSubmit } = useEmployeeForm({
    defaultValues: transformEmployeeDataToFormData(defaultValues),
    employeeId: +employeeId,
    onSubmitted,
  });

  const { setValue, watch } = form;

  const onContractStartDateChange = (value: string) => {
    const date = dateFormatter.getDateObjectFromString(value, 'dd.MM.yyyy');
    const contractEndDate = watch('contractEndDate');
    if (contractEndDate && date > contractEndDate) {
      setValue('contractEndDate', null);
    }
  };

  return (
    <FormProvider {...form}>
      <form className="w-100-g" onSubmit={onSubmit}>
        <div className="form-wrapper">
          <div className="form-column">
            <div className="sub-title first-sub-title">Dane pracownika</div>
            <TextInputNew
              testcy="first-name"
              name="firstName"
              label="Imię"
              width="100%"
              appearance="light"
              validation={standardValidators.requiredMaxNumber(255)}
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
            <TextInputNew
              testcy="last-name"
              name="lastName"
              label="Nazwisko"
              width="100%"
              appearance="light"
              validation={standardValidators.requiredMaxNumber(255)}
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
            <SpecialInput
              testcy="pesel"
              type="pesel"
              name="pesel"
              label="Pesel"
              width="100%"
              appearance="light"
              validation={{ required: formErrorMsg.isRequired }}
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
            <SpecialInput
              testcy="email"
              name="email"
              type="email"
              label="E-mail"
              width="100%"
              appearance="light"
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
            <SpecialInput
              testcy="phone"
              name="phone"
              type="phone"
              label="Telefon"
              width="100%"
              appearance="light"
              onKeyDown={event => {
                focusOnNextFormInput(event, 'typeOfEmployment');
              }}
            />
          </div>
          <div className="form-column">
            <div className="sub-title">Forma zatrudnienia</div>
            <TheSelect
              testcy="type-of-employment"
              name={`typeOfEmployment`}
              label="Typ umowy"
              width="100%"
              options={typeOfEmploymentOptions}
              hideEmptyOption
              appearance="light"
              validation={{
                required: formErrorMsg.isRequired,
              }}
            />
            <TextInputNew
              testcy="position"
              name="position"
              label="Stanowisko"
              width="100%"
              appearance="light"
              validation={standardValidators.requiredMaxNumber(50)}
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
            <DatePicker
              testcy="contract-start-date"
              name="contractStartDate"
              label="Data zatrudnienia"
              minDate={startOfYear(subYears(new Date(), 55))}
              width="100%"
              appearance="light"
              onChange={onContractStartDateChange}
              onKeyDown={event => {
                focusOnNextFormInput(event, 3);
              }}
              validation={standardValidators.requiredDate}
            />
            <DatePicker
              testcy="contract-end-date"
              name="contractEndDate"
              label="Koniec umowy"
              minDate={watch('contractStartDate') as Date}
              maxDate={new Date(new Date().getFullYear() + 3, 11, 31)}
              width="100%"
              appearance="light"
              validation={{
                validate: {
                  validDate: standardValidators.validDate,
                },
              }}
              onKeyDown={event => {
                focusOnNextFormInput(event, 3);
              }}
            />
            <NumberInput
              testcy="gross-salary"
              name="grossSalary"
              width="100%"
              margin="40px 0 0 0 "
              float
              maxDecimals={2}
              fixedDecimals
              label="Kwota brutto"
              placeholder="0,00"
              appearance="light"
              validation={{
                required: formErrorMsg.isRequired,
                validate: {
                  greaterThanZero: (value: string) => {
                    return stringToNumberSecondOptions(value) > 0 || formErrorMsg.incorrectAmount;
                  },
                },
              }}
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
          </div>

          <div className="form-column">
            <div className="sub-title">Adres</div>
            <TextInputNew
              testcy="street"
              name="street"
              label="Ulica i numer"
              width="100%"
              appearance="light"
              validation={standardValidators.requiredMaxNumber(100)}
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
            <TextInputNew
              testcy="city"
              name="city"
              label="Miejscowość"
              width="100%"
              appearance="light"
              validation={standardValidators.requiredMaxNumber(100)}
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
            <SpecialInput
              testcy="zip-code"
              name="zipCode"
              label="Kod pocztowy"
              width="100%"
              type="postalcode"
              appearance="light"
              validation={{
                required: formErrorMsg.isRequired,
              }}
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
          </div>
        </div>
        <div className="form-footer">
          {isSubmitting && <LoaderIcon size="medium" />}

          <ButtonMain testcy="save-changes" type="submit" content={`${employeeId ? 'Zapisz zmiany' : 'Dodaj pracownika'}`} width="200px" />
        </div>
      </form>
    </FormProvider>
  );
};
