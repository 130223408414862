import React from 'react';

interface Props {
  colorType?: 'black' | 'white' | 'primary' | 'disabled' | '';
  size?: string | number;
}

export const DigitalizeIcon = ({ colorType = '', size = 24 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9957 2.1875H4.00497C2.89773 2.1875 2 3.08523 2 4.19183V9.19223C2 10.2995 2.89773 11.1972 4.00497 11.1972H11.9957C13.1029 11.1972 14 10.2995 14 9.19223V4.19183C14 3.08523 13.1029 2.1875 11.9957 2.1875Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.37012 13.8125H10.63" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 11.3359V13.8112" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
