import React from 'react';

import './arrow.scoped.scss';

export const ArrowDirection = {
  Left: 'left',
  Right: 'right',
  Up: 'up',
  Down: 'down',
} as const;
export type ArrowDirection = typeof ArrowDirection[keyof typeof ArrowDirection];

interface Props {
  colorType?: 'black' | 'white' | 'primary' | 'disabled' | '';
  direction?: ArrowDirection;
  size?: number;
}

export const ArrowIcon = ({ colorType = '', size = 16, direction = ArrowDirection.Left }: Props) => {
  return (
    <svg
      className={`icon-color-${colorType}-stroke direction-${direction}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.57355 10.4531L11.9997 4L18.4258 10.4531" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 4V20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
