import { FavoriteIcons, TableHeaderInterface, TableRowInterface, TheSelect } from '@components';
import { BankAccountData, CustomObject, Option, SuffixInvoiceNumberData } from '@interfaces';
import { dateFormatter } from '@lib';
import { ContractorsListData, GetInvoiceSumValueParams, InvoiceCalculatedData, InvoiceDetails, InvoiceUnitCalculation, SaveInvoiceRequest } from '@services';
import {
  InvoiceNumberFormat,
  PaymentType,
  ZwOptionsType,
  focusOnNextFormInput,
  formErrorMsg,
  formatNumberToCurrency,
  getPropertySuffixNumbers,
  gtuOptions,
  numberToString,
  standardValidators,
  stringToNumberSecondOptions,
  tooltipMessages,
  vatOptions,
} from '@utils';
import React from 'react';
import { Control, FieldErrors, FieldValues, UseFormClearErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { NumberInput, TextInputNew, getFormattedAccountNo } from 'src/components/form';

export interface InvoiceFormData extends CustomObject {
  client: string;
  deliveryDate: Date;
  dueDate: Date;
  invoiceDate: Date;
  invoiceName: string;
  paymentyType: PaymentType;
  suffix: string;
  bankNumber: string;
  invoiceItems: TableRowInterface[];
  paidAmount: string;
  selectOptionId?: string;
  zwOptions?: ZwOptionsType;
  zwVatOther?: string;
}

export interface InvoiceItemEditingValue {
  id: string;
  name: string;
}

export const defaultDueDateDays = 14;

export const tableHeaderGenerate = (): TableHeaderInterface[] => {
  return [
    {
      label: 'L.p.',
      data: 'no',
      autoCount: true,
      keyPrefix: 'no-value',
      customCellClass: 'center-g text-align-center-g',
    },
    {
      label: 'Nazwa',
      data: 'name',
      minWidth: 250,
    },
    {
      label: 'Ilość',
      data: 'quantity',
      minWidth: 100,
      maxWidth: 120,
    },
    {
      label: 'Jednostka',
      data: 'jm',
      minWidth: 120,
      maxWidth: 140,
    },
    {
      label: 'Cena netto',
      data: 'netUnitPrice',
      minWidth: 120,
      maxWidth: 140,
    },
    {
      label: 'Stawka VAT',
      data: 'vatValue',
      minWidth: 120,
      maxWidth: 140,
    },
    {
      label: 'Wartość netto',
      data: 'netValue',
      minWidth: 120,
      maxWidth: 140,
    },
    {
      label: 'Wartość brutto',
      data: 'grossValue',
      minWidth: 120,
      maxWidth: 140,
    },
    {
      label: 'GTU',
      data: 'gtu',
      minWidth: 100,
      maxWidth: 120,
    },

    {
      label: '',
      data: 'action',
      keyPrefix: 'action',
      action: true,
    },
  ];
};

export const generateRow = (
  id: string,
  control: Control,
  onValueChange: (valueType: InvoiceUnitCalculation, id: string, force?: boolean) => void,
  watch: UseFormWatch<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  getValues: (name: string) => string,
  clearErrors: UseFormClearErrors<FieldValues>,
  setFieldRecalculationNeeded: (val: boolean) => void,
  checkIfRecalculationNeeded: (valueType: InvoiceUnitCalculation, id: string) => boolean,
  trigger: (name: string) => Promise<boolean>,
  numberOfItems: number,
  action: string,
  index: number
): TableRowInterface => {
  const updateValueOnChange = (nextValueFromEvent: string, unitType: InvoiceUnitCalculation, rowId: string) => {
    // if blur value changed, we can perform update and recalculate
    // NOTE checkIfRecalculationNeeded checks only current form state but not incoming value
    const isBlurValueChanged = (nextValue: string): boolean => {
      const fieldNameNextValue = `onBlur-${unitType}-${rowId}`;
      const currValue = getValues(fieldNameNextValue);
      return currValue !== nextValue;
    };

    if (isBlurValueChanged(nextValueFromEvent)) {
      setFieldRecalculationNeeded(true);
      const fieldNameNextValue = `onBlur-${unitType}-${rowId}`;
      const fieldNamePreviousValue = `onFocus-${unitType}-${rowId}`;
      const currValue = getValues(fieldNameNextValue);
      setValue(fieldNameNextValue, nextValueFromEvent);
      if (currValue) {
        setValue(fieldNamePreviousValue, currValue);
      }
    }
  };

  const onEnterUpRecalculate = async (event: React.KeyboardEvent<HTMLDivElement>, unitType: InvoiceUnitCalculation, rowId: string) => {
    const nextValueFromEvent = (event.target as any).value;
    updateValueOnChange(nextValueFromEvent, unitType, rowId);

    if (event.key === 'Enter') {
      const isCorrection = action === 'correction' || action === 'correction-edit';
      const quantityIsZero = getValues(`quantity-${id}`) === '0';
      if (quantityIsZero && isCorrection) {
        return;
      }
      onValueChange(unitType, rowId, true);
      await trigger(getFieldNameByType(unitType, rowId));
    } else {
      setFieldRecalculationNeeded(checkIfRecalculationNeeded(unitType, id));
    }
  };

  return {
    id: id,
    no: {
      content: '',
      bold: true,
    },
    name: {
      content: '',
      component: (
        <TextInputNew
          testcy={`invoice-form-name-${index}`}
          control={control}
          name={`name-${id}`}
          width="100%"
          appearance="primary"
          size="medium"
          validation={{
            required: formErrorMsg.isRequired,
            maxLength: {
              value: 500,
              message: formErrorMsg.maxLength(500),
            },
          }}
          onKeyUp={event => {
            focusOnNextFormInput(event, `gtu-${id}`);
          }}
        />
      ),
      stashedData: {
        name: `name-${id}`,
      },
    },
    gtu: {
      content: '',
      component: (
        <TheSelect
          testcy={`invoice-form-gtu-${index}`}
          appearance="primary"
          size="medium"
          control={control}
          name={`gtu-${id}`}
          width="100%"
          options={gtuOptions}
        />
      ),
      stashedData: {
        name: `gtu-${id}`,
      },
    },
    quantity: {
      content: '',
      component: (
        <NumberInput
          testcy={`invoice-form-quantity-${index}`}
          control={control}
          name={`quantity-${id}`}
          appearance="primary"
          size="medium"
          width="100%"
          maxDecimals={3}
          float
          defaultValue="1"
          onBlur={value => {
            if (value === '0' && (action === 'correction' || action === 'correction-edit') && !numberOfItems) {
              setValue(`netUnitPrice-${id}`, '0,00');
              setValue(`grossValue-${id}`, '0,00');
            }
            setValue(`onBlur-Quantity-${id}`, value);
            onValueChange('Quantity', id);
          }}
          onFocus={e => {
            setValue(`onFocus-Quantity-${id}`, e.target.value);
          }}
          onKeyDown={async event => {
            await onEnterUpRecalculate(event, 'Quantity', id);
          }}
          onKeyUp={event => {
            focusOnNextFormInput(event, 2);
          }}
          validation={{
            validate: value => {
              if (action === 'correction' || action === 'correction-edit') {
                if (value < 0 && !numberOfItems) {
                  return formErrorMsg.incorrectQuantityCorrection;
                }
                if (value <= 0 && numberOfItems) {
                  return formErrorMsg.incorrectQuantity;
                }
              } else if (action !== 'correction' && value <= 0) {
                return formErrorMsg.incorrectQuantity;
              }
            },
            required: formErrorMsg.isRequired,
          }}
        />
      ),
      stashedData: {
        name: `quantity-${id}`,
      },
    },
    jm: {
      content: '',
      component: (
        <TextInputNew
          testcy={`invoice-form-jm-${index}`}
          control={control}
          name={`jm-${id}`}
          appearance="primary"
          size="medium"
          width="100%"
          onKeyUp={event => {
            focusOnNextFormInput(event, 2);
          }}
          validation={standardValidators.requiredMaxNumber(100)}
          defaultValue="sztuka"
        />
      ),
      stashedData: {
        name: `jm-${id}`,
      },
    },
    netUnitPrice: {
      content: '',
      component: (
        <NumberInput
          testcy={`invoice-form-net-unit-price-${index}`}
          control={control}
          name={`netUnitPrice-${id}`}
          appearance="primary"
          size="medium"
          width="100%"
          maxDecimals={2}
          float
          placeholder="0,00"
          watch={watch}
          onFocus={e => {
            setValue(`onFocus-NetUnitPrice-${id}`, e.target.value);
            setValue('disabledValues', [`grossValue-${id}`]);
          }}
          onBlur={value => {
            const isCorrection = action === 'correction' || action === 'correction-edit';
            const quantityIsZero = getValues(`quantity-${id}`) === '0';
            if (value !== '0' && isCorrection && !quantityIsZero) {
              clearErrors(`quantity-${id}`);
            }
            if (value === '0' && isCorrection && !numberOfItems) {
              setValue(`quantity-${id}`, '0');
              setValue(`grossValue-${id}`, '0,00');
            }
            setValue('disabledValues', []);
            if (quantityIsZero && isCorrection && !numberOfItems) {
              setValue(`netUnitPrice-${id}`, '0,00');
              return;
            }
            setValue(`onBlur-NetUnitPrice-${id}`, value);
            onValueChange('NetUnitPrice', id);
            if (stringToNumberSecondOptions(value) > 0) {
              clearErrors(`grossValue-${id}`);
            }
          }}
          onKeyDown={async event => {
            await onEnterUpRecalculate(event, 'NetUnitPrice', id);
            focusOnNextFormInput(event, `vatValue-${id}`);
          }}
          validation={{
            validate: value => {
              if (action === 'correction' || action === 'correction-edit') {
                if (stringToNumberSecondOptions(value) < 0 && !numberOfItems) {
                  return formErrorMsg.incorrectAmountCorrection;
                }
                if (stringToNumberSecondOptions(value) <= 0 && numberOfItems) {
                  return formErrorMsg.incorrectAmount;
                }
              } else if (action !== 'correction' && stringToNumberSecondOptions(value) <= 0) {
                return formErrorMsg.incorrectAmount;
              }
            },
          }}
        />
      ),
      stashedData: {
        name: `netUnitPrice-${id}`,
      },
    },
    netValue: {
      content: '',
      component: (
        <NumberInput
          testcy={`invoice-form-net-value-${index}`}
          control={control}
          name={`netValue-${id}`}
          width="100%"
          appearance="primary"
          size="medium"
          maxDecimals={2}
          placeholder="0,00"
          disabled
        />
      ),
      stashedData: {
        name: `netValue-${id}`,
      },
    },
    grossValue: {
      content: '',
      component: (
        <NumberInput
          testcy={`invoice-form-gross-value-${index}`}
          control={control}
          name={`grossValue-${id}`}
          width="100%"
          appearance="primary"
          size="medium"
          maxDecimals={2}
          placeholder="0,00"
          float
          watch={watch}
          onFocus={e => {
            setValue(`onFocus-GrossValue-${id}`, e.target.value);
            setValue('disabledValues', [`netUnitPrice-${id}`]);
          }}
          onBlur={value => {
            const isCorrection = action === 'correction' || action === 'correction-edit';
            const quantityIsZero = getValues(`quantity-${id}`) === '0';
            if (value !== '0' && isCorrection && !quantityIsZero) {
              clearErrors(`quantity-${id}`);
            }
            if (value === '0' && isCorrection && !numberOfItems) {
              setValue(`quantity-${id}`, '0');
              setValue(`netUnitPrice-${id}`, '0,00');
            }
            setValue('disabledValues', []);
            if (quantityIsZero && isCorrection && !numberOfItems) {
              setValue(`grossValue-${id}`, '0,00');
              return;
            }
            setValue(`onBlur-GrossValue-${id}`, value);
            onValueChange('GrossValue', id);
            if (stringToNumberSecondOptions(value) > 0) {
              clearErrors(`netUnitPrice-${id}`);
            }
          }}
          onKeyDown={async event => {
            await onEnterUpRecalculate(event, 'GrossValue', id);
          }}
          onKeyUp={event => {
            focusOnNextFormInput(event, 2);
          }}
          validation={{
            validate: value => {
              if (action === 'correction' || action === 'correction-edit') {
                if (stringToNumberSecondOptions(value) < 0 && !numberOfItems) {
                  return formErrorMsg.incorrectAmountCorrection;
                }
                if (stringToNumberSecondOptions(value) <= 0 && numberOfItems) {
                  return formErrorMsg.incorrectAmount;
                }
              } else if (action !== 'correction' && stringToNumberSecondOptions(value) <= 0) {
                return formErrorMsg.incorrectAmount;
              }
            },
          }}
        />
      ),
      stashedData: {
        name: `grossValue-${id}`,
      },
    },
    vatValue: {
      content: '',
      component: (
        <TheSelect
          testcy={`invoice-form-vat-value-${index}`}
          control={control}
          name={`vatValue-${id}`}
          appearance="primary"
          size="medium"
          width="100%"
          options={vatOptions}
          defaultValue={'_23'}
          hideEmptyOption
          onFocus={e => {
            setValue(`onFocus-VatRate-${id}`, e.target.textContent);
          }}
          onBlur={e => {
            onValueChange('VatRate', id);
            setValue(`onBlur-VatRate-${id}`, e.target.textContent);
          }}
          onChange={value => {
            updateValueOnChange(value, 'VatRate', id);
            onValueChange('VatRate', id);
          }}
          validation={{
            required: formErrorMsg.isRequired,
          }}
        />
      ),
      stashedData: {
        name: `vatValue-${id}`,
      },
    },
    divider: {
      content: '',
    },
    action: {
      remove: true,
    },
  };
};

export const getValuesToCalculateSummary = (data: CustomObject, fieldChange: InvoiceUnitCalculation | null, rowId?: string): GetInvoiceSumValueParams[] => {
  const rowNumber: string[] = getPropertySuffixNumbers(data, 'quantity-');

  const values = [];

  const getValues = (num: string) => {
    let netUnitPrice = data[`netUnitPrice-${num}`];
    netUnitPrice = netUnitPrice !== '' ? netUnitPrice : '0';
    const grossValue = data[`grossValue-${num}`];
    const netValue = data[`netValue-${num}`];
    const vatRate = data[`vatValue-${num}`];
    const quantity = data[`quantity-${num}`];

    if (netUnitPrice && vatRate && quantity) {
      const res: GetInvoiceSumValueParams = {
        netUnitPrice: stringToNumberSecondOptions(netUnitPrice),
        grossValue: stringToNumberSecondOptions(grossValue),
        netValue: stringToNumberSecondOptions(netValue),
        quantity: stringToNumberSecondOptions(quantity),
        vatRate,
        fieldChange: rowId === num ? fieldChange : null,
        rowId: num,
      };

      return res;
    }

    return null;
  };

  for (const nr of rowNumber) {
    const rowData = getValues(nr);
    if (rowData) {
      values.push(rowData);
    }
  }

  return values;
};

export const transformOriginalCorrectedInvoiceItems = data => {
  const values = data.map((item, index) => {
    const res = {
      netUnitPrice: item.netUnitPrice,
      grossValue: item.grossValue,
      netValue: item.netValue,
      quantity: item.quantity,
      vatRate: item.vatRate,
      fieldChange: null,
      rowId: index.toString(),
    };
    return res;
  });
  return values;
};

export const getFieldNameByType = (unitType: InvoiceUnitCalculation | null, rowId?: string): string => {
  switch (unitType) {
    case 'GrossValue':
      return `grossValue-${rowId}`;
    case 'NetUnitPrice':
      return `netUnitPrice-${rowId}`;
    case 'Quantity':
      return `quantity-${rowId}`;
    case 'VatRate':
      return `vatValue-${rowId}`;
  }
};

export const createSubmitData = (data: InvoiceFormData, bankName: string, hideBankAccount = false, isPaid = false): SaveInvoiceRequest => {
  const invoiceName = data.suffix ? `${data.invoiceName}/${data.suffix}` : data.invoiceName;
  const createInvoiceData: SaveInvoiceRequest = {
    number: invoiceName || '',
    bankAccount:
      hideBankAccount || isPaid
        ? null
        : {
            bankName: bankName,
            number: data.bankNumber || '',
          },
    invoiceItems: [],
    issueDate: data.invoiceDate ? dateFormatter.objectToString(data.invoiceDate, 'yyyy-MM-dd') : '',
    deliveryDate: data.deliveryDate ? dateFormatter.objectToString(data.deliveryDate, 'yyyy-MM-dd') : '',
    dueDate: isPaid ? null : dateFormatter.objectToString(data.dueDate, 'yyyy-MM-dd'),
    contractorId: data.client ? data.client : null,
    paymentMethod: data.paymentType,
    amountPaid: isPaid ? null : stringToNumberSecondOptions(data.paidAmount),
  };

  const rowNumber = getPropertySuffixNumbers(data, 'quantity-');
  rowNumber.forEach(num => {
    const gtu = data[`gtu-${num}`];
    const description = data[`name-${num}`];
    const quantity = data[`quantity-${num}`];
    const unit = data[`jm-${num}`];
    const netUnitPrice = data[`netUnitPrice-${num}`];
    const netValue = data[`netValue-${num}`];
    const grossValue = data[`grossValue-${num}`];
    const vatRate = data[`vatValue-${num}`];

    createInvoiceData.invoiceItems.push({
      gtu: gtu || null,
      description,
      quantity: stringToNumberSecondOptions(quantity),
      unit,
      netUnitPrice: stringToNumberSecondOptions(netUnitPrice),
      netValue: stringToNumberSecondOptions(netValue),
      grossValue: stringToNumberSecondOptions(grossValue),
      vatRate,
    });
  });
  if (data.zwOptions) {
    createInvoiceData.vatExemptionInfo = {};
    createInvoiceData.vatExemptionInfo.exemptionType = data.zwOptions;
    createInvoiceData.vatExemptionInfo.description = data.zwOptions === 'Other' ? data.zwVatOther : null;
  }

  return createInvoiceData;
};
export const createSubmitCorrectData = (data: InvoiceFormData, bankName: string, hideBankAccount = false, isPaid = false): SaveInvoiceRequest => {
  const invoiceName = data.suffix ? `FK-${data.invoiceName}/${data.suffix}` : `FK-${data.invoiceName}`;
  const createInvoiceData: SaveInvoiceRequest = {
    number: invoiceName || '',
    bankAccount:
      hideBankAccount || isPaid
        ? null
        : {
            bankName: bankName,
            number: data.bankNumber || '',
          },
    invoiceItems: [],
    issueDate: data.invoiceDate ? dateFormatter.objectToString(data.invoiceDate, 'yyyy-MM-dd') : '',
    deliveryDate: data.deliveryDate ? dateFormatter.objectToString(data.deliveryDate, 'yyyy-MM-dd') : '',
    paymentMethod: data.paymentType || '',
    correctionReason: data.correctedInvoiceReason,
    dueDate: dateFormatter.objectToString(data.dueDate, 'yyyy-MM-dd'),
  };

  const rowNumber = getPropertySuffixNumbers(data, 'quantity-');
  rowNumber.forEach(num => {
    const gtu = data[`gtu-${num}`];
    const description = data[`name-${num}`];
    const quantity = data[`quantity-${num}`];
    const unit = data[`jm-${num}`];
    const netUnitPrice = data[`netUnitPrice-${num}`];
    const netValue = data[`netValue-${num}`];
    const grossValue = data[`grossValue-${num}`];
    const vatRate = data[`vatValue-${num}`];

    createInvoiceData.invoiceItems.push({
      gtu: gtu || null,
      description,
      quantity: stringToNumberSecondOptions(quantity),
      unit,
      netUnitPrice: stringToNumberSecondOptions(netUnitPrice),
      netValue: stringToNumberSecondOptions(netValue),
      grossValue: stringToNumberSecondOptions(grossValue),
      vatRate,
    });
  });
  if (data.zwOptions) {
    createInvoiceData.vatExemptionInfo = {};
    createInvoiceData.vatExemptionInfo.exemptionType = data.zwOptions;
    createInvoiceData.vatExemptionInfo.description = data.zwOptions === 'Other' ? data.zwVatOther : null;
  }

  return createInvoiceData;
};

export const generateSuffixesOptions = (suffixes: SuffixInvoiceNumberData[]): Option[] => {
  if (suffixes) {
    const options = suffixes.map((data: SuffixInvoiceNumberData) => {
      return {
        label: data.value,
        value: data.value,
      };
    });
    return options;
  }
};

export const generateBankAccountsOptions = (
  bankAccounts: BankAccountData[],
  setValue: UseFormSetValue<FieldValues>,
  watch: UseFormWatch<FieldValues>,
  disableBankAccount: boolean
): Option[] => {
  if (bankAccounts) {
    const options = bankAccounts.map((data: BankAccountData, index) => {
      return {
        label: `${getFormattedAccountNo(data.number)}`,
        value: data.number,
        additionalMessage: data.bankName,
        action: (
          <>
            <FavoriteIcons
              testcy="main-bank-account"
              watch={watch}
              isFavorite={data.mainAccount}
              value={data.number}
              setValue={setValue}
              name={'main-bank-account'}
              tooltipMessageFavoriteIcons={tooltipMessages.favoriteBankAccountIconFiled}
              tooltipMessageFavoriteBorderIcon={tooltipMessages.favoriteBankAccountIconBorder}
              disabled={disableBankAccount}
            />
          </>
        ),
      };
    });
    return options;
  }
};

export const setFormData = (
  data: InvoiceDetails,
  setValue: UseFormSetValue<FieldValues>,
  addMultipleRows: (ids: number[]) => void,
  contractorsList: ContractorsListData[]
): void => {
  const contractor = contractorsList.find(contractor => {
    return contractor.id === data.contractorId;
  });
  setValue('client', data.contractorId);
  setValue('contractorNameInvoiceEdit', contractor.name);

  const deliveryDate = data.deliveryDate ? dateFormatter.getDateObjectFromString(data.deliveryDate, "yyyy-MM-dd'T'HH:mm:ss") : '';
  const dueDate = data.dueDate ? dateFormatter.getDateObjectFromString(data.dueDate, "yyyy-MM-dd'T'HH:mm:ss") : '';
  const issueDate = data.issueDate ? dateFormatter.getDateObjectFromString(data.issueDate, "yyyy-MM-dd'T'HH:mm:ss") : '';

  if (data.numberFormat === InvoiceNumberFormat.Yearly) {
    const indexOfNumber = data.number.indexOf('/');
    const invoiceName = data.number.slice(0, indexOfNumber + 5);
    const invoiceSuffix = data.number.slice(indexOfNumber + 6);

    setValue('invoiceName', invoiceName);
    if (invoiceSuffix.length) setValue('suffix', invoiceSuffix);
  } else {
    const indexOfNumber = data.number.indexOf('/');
    const invoiceName = data.number.slice(0, indexOfNumber + 8);
    const invoiceSuffix = data.number.slice(indexOfNumber + 9);

    setValue('invoiceName', invoiceName);
    if (invoiceSuffix.length) setValue('suffix', invoiceSuffix);
  }
  if (data.bankAccount?.number) setValue('bankNumber', data.bankAccount.number);
  setValue('paymentType', data.paymentMethod);
  setValue('deliveryDate', deliveryDate);
  setValue('dueDate', dueDate);
  setValue('invoiceDate', issueDate);
  setValue('paidAmount', formatNumberToCurrency(data.amountPaid));

  const rowsIds = [];
  data.invoiceItems.forEach((data, index) => {
    const { gtu, description, quantity, unit, vatRate, netUnitPrice, netValue, grossValue } = data;

    const num = index + 1;
    rowsIds.push(num);
    setValue(`gtu-${num}`, gtu || '');
    setValue(`name-${num}`, description || '');
    setValue(`quantity-${num}`, numberToString(quantity));
    setValue(`jm-${num}`, unit);
    setValue(`netUnitPrice-${num}`, formatNumberToCurrency(netUnitPrice));
    setValue(`netValue-${num}`, formatNumberToCurrency(netValue));
    setValue(`grossValue-${num}`, formatNumberToCurrency(grossValue));
    setValue(`vatValue-${num}`, vatRate);
  });
  addMultipleRows(rowsIds);
  if (data?.vatExemptionInfo) {
    setValue(`zwOptions`, data.vatExemptionInfo.exemptionType);
    setValue(`zwVatOther`, data.vatExemptionInfo.description);
  }
};

export const setCloneFormData = (
  data: InvoiceDetails,
  setValue: UseFormSetValue<FieldValues>,
  addMultipleRows: (ids: number[], action?) => void,
  contractorsList: ContractorsListData[],
  action?: string
): void => {
  const contractor = contractorsList.find(contractor => {
    return contractor.id === data.contractorId;
  });
  setValue('client', data.contractorId);
  setValue('contractorNameInvoiceEdit', contractor.name);

  if (data.bankAccount?.number) setValue('bankNumber', data.bankAccount.number);
  setValue('paymentType', data.paymentMethod);

  const rowsIds = [];
  data.invoiceItems.forEach((data, index) => {
    const { gtu, description, quantity, unit, vatRate, netUnitPrice, netValue, grossValue } = data;

    const num = index + 1;
    rowsIds.push(num);
    setValue(`gtu-${num}`, gtu || '');
    setValue(`name-${num}`, description || '');
    setValue(`quantity-${num}`, numberToString(quantity));
    setValue(`jm-${num}`, unit);
    setValue(`netUnitPrice-${num}`, formatNumberToCurrency(netUnitPrice));
    setValue(`netValue-${num}`, formatNumberToCurrency(netValue));
    setValue(`grossValue-${num}`, formatNumberToCurrency(grossValue));
    setValue(`vatValue-${num}`, vatRate);
  });
  addMultipleRows(rowsIds, action);
  if (data?.vatExemptionInfo) {
    setValue(`zwOptions`, data.vatExemptionInfo.exemptionType);
    setValue(`zwVatOther`, data.vatExemptionInfo.description);
  }
};

export const setCalculatedValuesToInvoice = (data: InvoiceCalculatedData, setValue: UseFormSetValue<FieldValues>) => {
  data.items.forEach((item, index: number) => {
    const invoiceItem = data.items[index];
    if (!invoiceItem) return;
    setValue(`netUnitPrice-${item.rowId}`, formatNumberToCurrency(item.netUnitPrice));
    setValue(`netValue-${item.rowId}`, formatNumberToCurrency(item.netValue));
    setValue(`grossValue-${item.rowId}`, formatNumberToCurrency(item.grossValue));
  });
};

export const calculateDueDate = (baseDate: Date, paymentType: PaymentType) => {
  if (!paymentType || paymentType === 'Transfer' || paymentType === 'TransferSplitPayment') {
    return dateFormatter.addDaysToDate(baseDate, defaultDueDateDays);
  }

  return baseDate;
};

export const checkCalculatedFormValidation = (errors: FieldErrors<FieldValues>, clearErrors: UseFormClearErrors<FieldValues>) => {
  const keys = Object.keys(errors);
  const calculatedInputErrors = keys.filter(key => {
    return key.slice(0, 10) === 'grossValue' || key.slice(0, 12) === 'netUnitPrice';
  });

  const lineNumber = calculatedInputErrors.map(inputValue => {
    return inputValue.split('-')[1];
  });

  const unique = lineNumber.filter(v => {
    return lineNumber.indexOf(v) === lineNumber.lastIndexOf(v);
  });

  if (!unique.length) return;
  calculatedInputErrors.forEach(element => {
    if (element.endsWith(`-${unique[0]}`)) {
      clearErrors(element);
    }
  });
};

export const checkInvoiceDatePeriodChange = (
  invoiceOriginalNumber: string,
  invoiceDate: Date,
  invoiceNumberFetch: string,
  setValue: UseFormSetValue<FieldValues>,
  id: string,
  suffixInput: string,
  isSuffixChange: boolean,
  action: string
): void => {
  if (invoiceNumberFetch.slice(0, 4).includes('FK-')) {
    invoiceNumberFetch = invoiceNumberFetch.slice(3, invoiceNumberFetch.length);
  }
  if (invoiceOriginalNumber?.slice(0, 4).includes('FK-')) {
    invoiceOriginalNumber = invoiceOriginalNumber.slice(3, invoiceOriginalNumber.length);
  }

  const invoiceDateFormat = dateFormatter.objectToString(invoiceDate);
  const indexOfNumber = invoiceOriginalNumber?.indexOf('/') + 1;
  const indexOfNumberAfterChange = invoiceNumberFetch.indexOf('/') + 1;

  const invoiceNumberFormat = invoiceOriginalNumber?.slice(indexOfNumber, indexOfNumber + 4).includes('/')
    ? InvoiceNumberFormat.Monthly
    : InvoiceNumberFormat.Yearly;

  const invoiceNumberFormatAfterChange = invoiceNumberFetch.slice(indexOfNumberAfterChange, indexOfNumberAfterChange + 4).includes('/')
    ? InvoiceNumberFormat.Monthly
    : InvoiceNumberFormat.Yearly;

  const suffixName =
    invoiceNumberFormat === InvoiceNumberFormat.Yearly ? invoiceOriginalNumber?.slice(indexOfNumber + 5) : invoiceOriginalNumber?.slice(indexOfNumber + 8);
  const suffixNameAfterChangeNumber =
    invoiceNumberFormatAfterChange === InvoiceNumberFormat.Yearly
      ? invoiceNumberFetch.slice(indexOfNumberAfterChange + 5)
      : invoiceNumberFetch.slice(indexOfNumberAfterChange + 8);

  const invoiceYear =
    invoiceNumberFormatAfterChange === InvoiceNumberFormat.Yearly
      ? +invoiceOriginalNumber?.slice(indexOfNumber, indexOfNumber + 4)
      : +invoiceOriginalNumber?.slice(indexOfNumber + 3, indexOfNumber + 7);
  const invoiceYearInputHandler = +invoiceDateFormat.slice(-4);

  const invoiceMonth = +invoiceOriginalNumber?.slice(indexOfNumber, indexOfNumber + 2);
  const invoiceMonthInputHandler = +invoiceDateFormat.slice(3, -4);
  const isYearChange = invoiceYear !== invoiceYearInputHandler;
  // isMonthChange use only to InvoiceNumberFormat.MONTHLY
  const isMonthChange = invoiceMonth !== invoiceMonthInputHandler;
  // setValueCase1 change number to number from API
  const setValueCase1 = () => {
    setValue('invoiceName', suffixNameAfterChangeNumber ? invoiceNumberFetch.replace(`/${suffixNameAfterChangeNumber}`, '') : invoiceNumberFetch);
  };
  // setValueCase2 change number to number from API after suffix change
  const setValueCase2 = () => {
    setValue('invoiceName', suffixInput ? invoiceNumberFetch.replace(`/${suffixInput}`, '') : invoiceNumberFetch);
  };
  // setValueCase3 change number to original invoice number
  const setValueCase3 = () => {
    setValue('invoiceName', suffixName ? invoiceOriginalNumber.replace(`/${suffixName}`, '') : invoiceOriginalNumber);
  };

  if (!id) {
    setValueCase1();
    return;
  }

  if (action === 'correction') {
    setValueCase1();
    return;
  }

  if (invoiceNumberFormatAfterChange === InvoiceNumberFormat.Yearly) {
    if (isSuffixChange) {
      if (suffixInput !== suffixName || isYearChange) {
        setValueCase2();
        return;
      }
      setValueCase3();
      return;
    } else {
      if (isYearChange || suffixName !== suffixNameAfterChangeNumber) {
        setValueCase1();
        return;
      }
      setValueCase3();
      return;
    }
  } else {
    if (isSuffixChange) {
      if (suffixInput !== suffixName || isYearChange || isMonthChange) {
        setValueCase2();
        return;
      }
      setValueCase3();
      return;
    } else {
      if (isMonthChange || isYearChange || suffixName !== suffixNameAfterChangeNumber) {
        setValueCase1();
        return;
      }
      setValueCase3();
      return;
    }
  }
};

export const checkInvoiceNameHasSuffix = (number: string, invoiceNumberFormat: InvoiceNumberFormat, setValue: UseFormSetValue<FieldValues>) => {
  if (invoiceNumberFormat === InvoiceNumberFormat.Yearly) {
    const indexOfNumber = number.indexOf('/');
    const invoiceSuffix = number.slice(indexOfNumber + 6);
    if (invoiceSuffix.length) setValue('suffix', invoiceSuffix);
    return invoiceSuffix;
  } else {
    const indexOfNumber = number.indexOf('/');
    const invoiceSuffix = number.slice(indexOfNumber + 9);
    if (invoiceSuffix.length) setValue('suffix', invoiceSuffix);
    return invoiceSuffix;
  }
};
