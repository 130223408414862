import { useState, useCallback } from 'react';
import { NotificationsListData, getNotifications } from '@services';

export const useNotifications = (pageNumber: number, pageSize?: number) => {
  const [isPendingGetNotifications, setIsPendingGetNotifications] = useState(false);
  const [notifications, setNotifications] = useState<NotificationsListData[]>([]);

  const getNotificationsData = useCallback(async () => {
    setIsPendingGetNotifications(true);

    const result = await getNotifications({
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
    setNotifications(result.data);
    setIsPendingGetNotifications(false);
  }, [pageNumber, pageSize]);

  return {
    getNotificationsData,
    notifications,
    isPendingGetNotifications,
  };
};
// future task, add signalR notification, now 
// infinity, scroll and store
