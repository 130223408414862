import { AxiosResponse } from 'axios';
import { httpClient } from '@lib';
import {
  SortDirection,
  PaymentType,
  GtuOptionsType,
  VatOptionsType,
  InvoiceStatusType,
  AccountantStatusType,
  InvoicesSummaryTypeEnum,
  InvoiceNumberFormat,
  ZwOptionsType,
  CostsUnitEnum,
  IncomesChartAPIData,
} from '@utils';
import { BankAccountData, InvoiceStatus, InvoicingItemValues, InvoicingItemValuesBase, PagedList } from '@interfaces';

export interface Invoice {
  id: number;
  companyId: number;
  companyName: string;
  number: string;
  deliveryDate: string;
  issueDate: string;
  dueDate: string;
  contractor: string;
  contractorId: string;
  nip?: string;
  netValue: number;
  vatValue: number;
  grossValue: number;
  status: InvoiceStatusType;
  accountingStatus: AccountantStatusType;
  daysOverdue?: number;
  correctedInvoiceId: number;
  originalInvoiceId?: number;
  isSent: boolean;
  correctionId: number;
  paymentMethod: PaymentType;
  amountPaid: number;
}

export interface InvoiceList extends PagedList<Invoice> {}

export type InvoiceOrderOptions =
  | 'gtu'
  | 'deliveryDate'
  | 'issueDate'
  | 'dueDate'
  | 'number'
  | 'contractor'
  | 'nip'
  | 'netValue'
  | 'vatValue'
  | 'grossValue'
  | 'status';

export interface GetInvoiceListParams {
  filter?: {
    companyId?: number;
    textFilter?: string;
    status?: InvoiceStatus[];
    accountingStatus?: InvoiceStatus[];
    dateFrom?: string;
    dateTo?: string;
    noGtuOnly?: boolean;
    summaryType?: InvoicesSummaryTypeEnum;
  };
  paging?: { pageNumber?: number; pageSize?: number };
  sorting?: {
    direction?: SortDirection;
    orderBy?: InvoiceOrderOptions;
  };
}

export interface InvoiceBulkOperationsParams {
  filter?: {
    companyId?: number;
    textFilter?: string;
    status?: InvoiceStatus[];
    accountingStatus?: InvoiceStatus[];
    dateFrom?: string;
    dateTo?: string;
    noGtuOnly?: boolean;
    summaryType?: InvoicesSummaryTypeEnum;
  };
  ids: number[];
  selectAll: boolean;
}

export interface GetInvoiceNumberParams {
  companyId?: number;
  issueDate?: string;
  suffix?: string;
  prefix?: string;
}

export interface InvoiceSaveItem extends InvoicingItemValuesBase {
  gtu?: GtuOptionsType;
  description: string;
  unit: string;
}

export interface InvoiceItemDetails extends InvoicingItemValues {
  gtu?: GtuOptionsType;
  description: string;
  unit: string;
}

export interface InvoiceRecordedPayment {
  amount: number;
  date: Date;
  notes?: string;
}

export interface RecordPaymentRequest {
  amountPaid: number;
  date: string;
  notes?: string;
}

export interface SaveInvoiceRequest {
  number: string;
  bankAccount?: BankAccountData;
  invoiceItems: InvoiceSaveItem[];
  issueDate: string;
  deliveryDate: string;
  dueDate: string;
  contractorId?: string;
  paymentMethod: PaymentType;
  amountPaid?: number;
  vatExemptionInfo?: VatExemptionInfo;
  correctionReason?: string;
}

export interface InvoiceDetails {
  id: number;
  invoiceItems: InvoiceItemDetails[];
  number: string;
  bankAccount?: BankAccountData;
  issueDate: string;
  deliveryDate: string;
  dueDate: string;
  contractorId: string;
  paymentMethod: PaymentType;
  amountPaid: number;
  totalInstalments: number;
  isSent: boolean;
  grossValue: number;
  status: InvoiceStatusType;
  accountingStatus: AccountantStatusType;
  payments: InvoiceRecordedPayment[];
  numberFormat: InvoiceNumberFormat;
  companyId: number;
  vatExemptionInfo?: VatExemptionInfo;
  correctedInvoiceId: number;
  originalInvoiceId?: number;
  correctionId: number;
  correctionReason: string;
}

export interface VatExemptionInfo {
  exemptionType?: ZwOptionsType;
  description?: string;
}
export interface InvoiceHistoryProperty {
  property: string;
  oldValue: string;
  newValue: string;
}

export interface InvoiceHistoryItem {
  invoiceId: number;
  gtu?: GtuOptionsType;
  description: string;
  quantity: number;
  unit: string;
  netUnitPrice: number;
  vatValue: number;
  netValue: number;
  grossValue: number;
  vatRate: VatOptionsType;
}

export interface InvoiceHistoryItemChange {
  oldValue?: InvoiceHistoryItem;
  newValue?: InvoiceHistoryItem;
}

export const InvoiceEventType = {
  InvoiceCreated: 'InvoiceCreated',
  InvoiceModified: 'InvoiceModified',
  InvoiceIssued: 'InvoiceIssued',
  InvoiceSentToAccounting: 'InvoiceSentToAccounting',
  InvoiceMarkedAsSent: 'InvoiceMarkedAsSent',
  InvoiceSentViaEmail: 'InvoiceSentViaEmail',
  PaymentRecorded: 'PaymentRecorded',
  InvoiceReminderSent: 'InvoiceReminderSent',
  DuplicateIssued: 'DuplicateIssued',
  InvoicePaid: 'InvoicePaid',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InvoiceEventType = typeof InvoiceEventType[keyof typeof InvoiceEventType];

export interface InvoiceHistory {
  event: InvoiceEventType;
  payload: PayloadProperty | string;
  time: string;
  user: string;
}

export interface PayloadProperty {
  propertyChanges: InvoiceHistoryProperty[];
  itemChanges: InvoiceHistoryItemChange[];
  any: boolean;
}

export type InvoiceUnitCalculation = 'Quantity' | 'NetUnitPrice' | 'VatRate' | 'GrossValue' | 'vatValue';

export interface GetInvoiceSumValueParams extends InvoicingItemValuesBase {
  fieldChange: InvoiceUnitCalculation | null;
  rowId: string;
}

export interface GetCostSumValueParams extends CostItemValuesBase {
  fieldChange: CostsUnitEnum | null;
  rowId: string;
}

export interface CostItemValuesBase {
  vatRate: VatOptionsType;
  netValue: number;
  grossValue: number;
  vatValue: number;
  rowId: string;
}

export interface InvoiceCalculatedDataItem extends InvoicingItemValuesBase {
  vatValue: number;
  rowId: string;
}

export interface InvoiceCalculatedData {
  netValue: number;
  grossValue: number;
  vatValue: number;
  items: InvoiceCalculatedDataItem[];
}

export interface CostCalculatedData {
  netValue: number;
  grossValue: number;
  vatValue: number;
  items: CostItemValuesBase[];
}

export interface EmailFormData {
  recipients: string[];
  subject: string;
  body: string;
  sendCopyToSender: boolean;
}

export interface ReminderFormData {
  recipients: string[];
  subject: string;
  body: string;
  sendCopyToSender: boolean;
  invoiceAttachment: boolean;
}

export interface ReminderData {
  recipients: string[];
  subject: string;
  body: string;
  sendCopyToSender: boolean;
  invoiceAttachment: boolean;
}

export interface InvoiceRelatedFiles {
  id: number;
  number: string;
  duplicateDate: string | null;
}

export interface InvoicePreviewDuplicateStateInterface {
  id: string;
  duplicateDate: Date;
  isOpenDuplicateModal: boolean;
}

export const getInvoices = async (params: GetInvoiceListParams = {}) => {
  try {
    const response: AxiosResponse<InvoiceList> = await httpClient.post('/Invoices/search', params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getInvoiceById = async (id: number) => {
  try {
    const response: AxiosResponse<InvoiceDetails> = await httpClient.get(`/Invoices/${id}`);
    return response.data;
  } catch (e) {
    throw e?.response;
  }
};

export const createInvoice = async (invoiceData: SaveInvoiceRequest) => {
  try {
    const response: AxiosResponse<number> = await httpClient.post('/Invoices', invoiceData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const createInvoiceCorrection = async (invoiceData: SaveInvoiceRequest, id: string) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Invoices/${id}/correct`, invoiceData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
export const editInvoiceCorrection = async (invoiceData: SaveInvoiceRequest, id: string) => {
  try {
    const response: AxiosResponse = await httpClient.put(`/Invoices/correction/${id}`, invoiceData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getInvoicesNextNumber = async (params: GetInvoiceNumberParams = {}) => {
  try {
    const response: AxiosResponse<string> = await httpClient.get('/Invoices/NextNumber', { params });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getInvoiceSumValues = async (params: GetInvoiceSumValueParams[]) => {
  try {
    const response: AxiosResponse<InvoiceCalculatedData> = await httpClient.post('/Invoices/calculate', params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const createInvoicePdfById = async (id: string, duplicateDate: string | null) => {
  try {
    const response: AxiosResponse<Blob> = await httpClient.put(`/Invoices/${id}/download`, { date: duplicateDate }, { responseType: 'blob' });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getInvoiceHistory = async (id: string) => {
  try {
    const response: AxiosResponse<InvoiceHistory[]> = await httpClient.get(`/Invoices/${id}/History`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const invoicesConfirm = async (params: InvoiceBulkOperationsParams) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Invoices/issue`, params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const deleteInvoiceById = async (id: string) => {
  try {
    const response: AxiosResponse = await httpClient.delete(`/Invoices/${id}`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const editInvoice = async (invoiceData: SaveInvoiceRequest, id: string) => {
  try {
    const response: AxiosResponse = await httpClient.put(`/Invoices/${id}`, invoiceData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const invoicesSendToInsert = async (params: { ids: number[] }) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Invoices/Send`, params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const invoicesSendEmail = async (emailData: EmailFormData, id: number) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Invoices/${id}/email`, emailData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const invoicesSendReminder = async (reminderData: ReminderFormData, id: number) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Invoices/${id}/reminder`, reminderData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const invoiceMarkAsSent = async (id: number) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Invoices/${id}/MarkAsSent`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getInvoiceTotal = async (companyId?: number) => {
  try {
    const response: AxiosResponse = await httpClient.get(`/Invoices/totals`, { params: { companyId } });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const invoiceRecordPayment = async (amountData: RecordPaymentRequest, id: number) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Invoices/${id}/RecordPayment`, amountData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const invoiceMarkAsPaid = async (params: InvoiceBulkOperationsParams) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Invoices/MarkAsPaid`, params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getCostSumValues = async (params: GetCostSumValueParams[]) => {
  try {
    const response: AxiosResponse<CostCalculatedData> = await httpClient.post('/Costs/calculate', params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getChartData = async (data: GetInvoiceListParams = {}) => {
  const params = data.filter || {};
  try {
    const response: AxiosResponse<IncomesChartAPIData> = await httpClient.post('/invoices/dailyAmounts', params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getInvoiceRelatedFiles = async (id: number) => {
  try {
    const response: AxiosResponse<InvoiceRelatedFiles[]> = await httpClient.get(`/Invoices/${id}/related`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
