import { QueriesTypeKey, QueryParams } from '@services';
import { queryClient } from './queryClientProvider';

export function invalidateApiQuery<TKey extends QueriesTypeKey>(key: TKey, params?: QueryParams<TKey>) {
  if (params) {
    queryClient.invalidateQueries([key, params]);
  } else {
    queryClient.invalidateQueries([key]);
  }
}
