import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { ButtonMain, LoaderIcon, SpecialInput, TextInputNew, TheDialog } from '@components';
import { formErrorMsg, getBankNameByAccountNumber, handleApiError, snackbarMessagesHandler } from '@utils';
import { addBankAccount, CompanyData, getMyCompany } from '@services';
import { BankAccountData, RequestErrorMessage } from '@interfaces';
import { useContractorStore } from '@store';
import { useIsAllFalse } from '@hooks';
import './bankAccountForm.scoped.scss';

interface Props {
  setCloseDialog: (v: boolean) => void;
  onAddNewBankAccount: (v: BankAccountData) => void;
}

export const BankAccountForm = ({ setCloseDialog, onAddNewBankAccount }: Props) => {
  const [bankName, setBankName] = useState('');
  const [bankNameFromBase, setBankNameFromBase] = useState(true);
  const [showBankNameInput, setShowBankNameInput] = useState(false);
  const [isPendingBankAccount, setIsPendingBankAccount] = useState(false);
  const [isUpdatingOwnerData, setIsUpdatingOwnerData] = useState(false);
  const { setOwnerMyCompanyData } = useContractorStore();

  const isPending = useIsAllFalse([isPendingBankAccount, isUpdatingOwnerData], 0, false);

  const { control, handleSubmit, trigger } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const onChange = (value: string) => {
    const bankNameData = getBankNameByAccountNumber(value);
    setBankName(bankNameData.value);
    setBankNameFromBase(bankNameData.isFromBase);
  };

  const onChangeBankName = (v: string) => {
    trigger('bankAccount');
    setBankName(v);
  };

  const onSubmitBankName = (data: FieldValues) => {
    const formattedData = {
      number: data.bankAccount || '',
      bankName: bankName,
    };
    setIsPendingBankAccount(true);
    addBankAccount(formattedData)
      .then(() => {
        updateMyCompanyData(formattedData);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        setIsPendingBankAccount(false);
      });
  };

  const updateMyCompanyData = (bankAccData: BankAccountData) => {
    setIsUpdatingOwnerData(true);
    getMyCompany()
      .then((data: CompanyData) => {
        if (data) {
          setOwnerMyCompanyData(data);
          onAddNewBankAccount(bankAccData);
          snackbarMessagesHandler.bankAccountAdded();
        }
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        setIsUpdatingOwnerData(false);
        setCloseDialog(false);
      });
  };

  return (
    <div className="bank-account-modal-wrapper">
      <form className="form-wrapper" onSubmit={handleSubmit(onSubmitBankName)}>
        <SpecialInput
          testcy="bank-account-form-bank-account"
          type="bank"
          name="bankAccount"
          control={control}
          label="Numer konta"
          appearance="light"
          width="100%"
          validation={{
            required: formErrorMsg.isRequired,
            validate: () => {
              return bankName ? true : formErrorMsg.noBankName;
            },
            onBlur: data => {
              const { value } = data.target;
              const valueWithoutSpaces = value.replace(/ /g, '');
              const isCorrectLength = valueWithoutSpaces.length === 26;
              const showBankNameInput = !bankNameFromBase && isCorrectLength;
              setShowBankNameInput(showBankNameInput);
            },
          }}
          onChange={onChange}
          helperText={bankName}
        />
        {showBankNameInput && (
          <TextInputNew
            testcy="bank-account-form-bank-name"
            name="bankAccountName"
            control={control}
            label="Nazwa banku"
            width="100%"
            appearance="light"
            validation={{
              required: formErrorMsg.isRequired,
            }}
            onChange={onChangeBankName}
          />
        )}
        <div className="button-wrapper">
          <ButtonMain
            testcy="bank-account-form-cancel"
            sizeType="small"
            onClick={() => setCloseDialog(false)}
            width="160px"
            content={'Anuluj'}
            colorType="secondary"
            isDisabled={isPending}
          />

          {isPending ? (
            <div className="loader-wrapper">
              <LoaderIcon />
            </div>
          ) : (
            <ButtonMain testcy="bank-account-form-submit" sizeType="small" width="160px" type="submit" content={'Zapisz'} isDisabled={isPending} />
          )}
        </div>
      </form>
    </div>
  );
};
