import { DocumentIcon, LoaderIcon } from '@components';
import React from 'react';

import './linkedFileItem.scoped.scss';

interface Props {
  name: string;
  testcy: string;
  isPendingData?: boolean;
  onClickRow?: () => void;
}

export const LinkedFileItem = ({ name, testcy, isPendingData = false, onClickRow }: Props) => {
  return (
    <div className={`linked-file-item ${!!onClickRow && 'row-hover'}`} onClick={onClickRow}>
      <div className="file-name-container">
        <DocumentIcon />
        <div className="file-name" data-testcy={`${testcy}-file-name`}>
          {name}
        </div>
      </div>
      {isPendingData && (
        <LoaderIcon size={'small'} />
      )}
    </div>
  );
};
