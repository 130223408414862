import { useMutation } from '@tanstack/react-query';
import { sendInvoiceHeaderMessage, sendTextMessage, SendTextMessageParams } from '../services/messaging';

type UseMessageSenderProps = {
  onSuccess: () => void;
};

export const useMessageSender = ({ onSuccess }: UseMessageSenderProps) => {
  const { mutate: sendHeaderMessage, isLoading: isSendingHeader } = useMutation(sendInvoiceHeaderMessage);
  const { mutate: sendMessage, isLoading: isSendingMessage } = useMutation(sendTextMessage, {
    onSuccess,
  });

  const sendMessageWithHeader = (params: SendTextMessageParams) => {
    sendHeaderMessage(
      { subChannelId: params.subChannelId, tag: params.tag },
      {
        onSuccess: () => {
          sendMessage(params);
        },
      }
    );
  };

  return {
    sendTextMessage: sendMessage,
    sendMessageWithHeader,
    isSending: isSendingHeader || isSendingMessage,
  };
};
