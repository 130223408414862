import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonIconNew, ButtonTextNew, ChevronIcon, LoaderIcon, SearchInput, Statuses, TheCheckbox, TimeRangePicker } from '@components';
import { DateRange } from '@interfaces';
import { dateFormatter } from '@lib';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, isDefined } from '@utils';
import { SearchBarValues } from './listSearchBar.utils';
import './listSearchBar.scoped.scss';

interface Props {
  onChange?: (value: SearchBarValues) => void;
  resetAllFilters?: () => void;
  setPage?: (value: number) => void;
  defaultValues?: SearchBarValues;
  searchBarPlaceholder?: string;
  isPending?: boolean;
  showSearchBar?: boolean;
  showStatusPicker?: boolean;
  showGtuCheckbox?: boolean;
  showDatePicker?: boolean;
  maxContentWidth?: boolean;
  selectedStatus?: string[];
  costAccountingStatuses?: boolean;
}

export const ListSearchBar = forwardRef(
  (
    {
      setPage,
      isPending = false,
      onChange,
      resetAllFilters,
      searchBarPlaceholder = 'Wyszukaj',
      defaultValues,
      showSearchBar,
      showStatusPicker,
      showGtuCheckbox,
      showDatePicker,
      maxContentWidth,
      selectedStatus,
      costAccountingStatuses = false,
    }: Props,
    ref
  ) => {
    const [isDefaultValuesChecked, setIsDefaultValuesChecked] = useState(false);
    const [isGtuSelected, setIsGtuSelected] = useState(false);
    const [dateRange, setDateRange] = useState<DateRange>(defaultValues && { fromDate: defaultValues.fromDate, toDate: defaultValues.toDate });
    const [searchValue, setSearchValue] = useState('');

    const { control, setValue, reset } = useForm({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      criteriaMode: 'all',
      defaultValues,
    });

    const resetFilters = () => {
      reset();
      resetAllFilters && resetAllFilters();
      setDateRange({
        toDate: null,
        fromDate: null,
      });
      setSearchValue('');
      setIsGtuSelected(false);
    };

    useImperativeHandle(ref, () => {
      return {
        resetFilters,
        resetSearch() {
          reset({ filter: '' });
          setSearchValue('');
        },
      };
    });

    useEffect(() => {
      const data: SearchBarValues = {
        textFilter: searchValue,
        noGtu: isGtuSelected,
        fromDate: dateRange?.fromDate,
        toDate: dateRange?.toDate,
      };
      onChange?.(data);
    }, [dateRange, isGtuSelected, searchValue]);

    useEffect(() => {
      if (defaultValues) {
        const { toDate, fromDate, textFilter, noGtu, filter } = defaultValues;

        if (toDate || fromDate) {
          setDateRange({
            toDate: toDate || null,
            fromDate: fromDate || null,
          });
        }
        if (isDefined(noGtu)) {
          setIsGtuSelected(defaultValues.noGtu);
        }
        if (filter) {
          setSearchValue(filter);
          setValue('filter', filter);
        }
        if (textFilter) {
          setValue('filter', textFilter);
          setSearchValue(textFilter);
        }
      }
      setTimeout(() => setIsDefaultValuesChecked(true));
    }, [defaultValues]);

    const handleSearchInputChange = (value: string): void => {
      setSearchValue(value);
      if (setPage) setPage(1);
    };

    const handleGtuCheckboxChange = (value: boolean): void => {
      setIsGtuSelected(value);
      if (setPage) setPage(1);
    };

    const handleDatePickerChange = (start: Date | null, end: Date | null): void => {
      setDateRange({ fromDate: start, toDate: end });
      if (setPage) setPage(1);
    };

    const nudgeDate = (direction: 1 | -1): void => {
      const newDate = dateFormatter.nudgeDate(dateRange, direction);
      setDateRange(newDate);
      handleDatePickerChange(newDate.fromDate, newDate.toDate);
    };

    const isLeftSideVisible = showSearchBar || showStatusPicker || showGtuCheckbox;
    const isDateRangeDefined = Boolean(dateRange?.fromDate && dateRange?.toDate);
    const hasFilters = !!searchValue || isDateRangeDefined || isGtuSelected || !!selectedStatus?.length;

    const clearButtonAndLoader = (
      <>
        {hasFilters && (
          <div className="clear-filters-area">
            <ButtonTextNew testcy="filters-clear" content="Wyczyść" color="blue" textTransform="none" noDecoration highPadding onClick={resetFilters} />
          </div>
        )}
        <div className="loader-wrapper">{isPending && <LoaderIcon size="medium" />}</div>
      </>
    );

    return (
      <div className="filter-and-status-wrapper">
        <div data-testcy="filters-wrapper" className={`filters-wrapper ${maxContentWidth ? 'width-max-content' : ''}`}>
          {isLeftSideVisible && (
            <div className="filters-left filters-side">
              {showSearchBar && (
                <div className="search-text-area">
                  <SearchInput
                    testcy="filters-text-filter"
                    control={control}
                    name="filter"
                    onChange={handleSearchInputChange}
                    placeholder={searchBarPlaceholder}
                    width="unset"
                    size="medium"
                  />
                </div>
              )}
              {showGtuCheckbox && isDefaultValuesChecked && (
                <div className="gtu-area">
                  <TheCheckbox
                    testcy="filters-no-gtu"
                    name="withoutGtu"
                    defaultValue={isGtuSelected}
                    control={control}
                    label="Bez GTU"
                    appearance="primary"
                    validation={{ onChange: e => handleGtuCheckboxChange(e.target.value) }}
                  />
                </div>
              )}
              {clearButtonAndLoader}
            </div>
          )}
          <div className="filters-right filters-side">
            {showDatePicker && (
              <div className="range-picker-area">
                {isDateRangeDefined && (
                  <div className="range-picker-buttons">
                    <ButtonIconNew
                      testcy="range-picker-nudge-backward"
                      onClick={() => nudgeDate(-1)}
                      size="big"
                      color="primary-light-colored"
                      disabled={
                        dateRange.fromDate.getFullYear() === DEFAULT_MIN_DATE.getFullYear() && dateRange.fromDate.getMonth() === DEFAULT_MIN_DATE.getMonth()
                      }
                    >
                      <ChevronIcon direction="left" />
                    </ButtonIconNew>
                    <ButtonIconNew
                      testcy="range-picker-nudge-forward"
                      onClick={() => nudgeDate(1)}
                      size="big"
                      color="primary-light-colored"
                      disabled={
                        dateRange.toDate.getFullYear() === DEFAULT_MAX_DATE.getFullYear() && dateRange.toDate.getMonth() === DEFAULT_MAX_DATE.getMonth()
                      }
                    >
                      <ChevronIcon direction="right" />
                    </ButtonIconNew>
                  </div>
                )}
                <TimeRangePicker
                  testcy="filters-date-range"
                  id="list-search-bar-range-picker"
                  onChange={handleDatePickerChange}
                  defaultValues={dateRange}
                  placeholder="Okres rozliczeniowy"
                  appearance="primary"
                  width="240px"
                  dropdownIcon
                />
              </div>
            )}
            {!isLeftSideVisible && clearButtonAndLoader}
          </div>
        </div>
        {selectedStatus && (
          <div className="filter-header-selected-wrapper">
            {selectedStatus?.map((item, i) => {
              return (
                <div key={item}>
                  <Statuses status={item} costAccountingStatuses={costAccountingStatuses} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);
