import { ButtonIconNew, DeleteIconNew, DigitalizeIcon, DocumentIcon, DownloadIcon, PopupMenu, TheSelect } from '@components';
import { dateFormatter } from '@lib';
import React, { useMemo } from 'react';
import { Option } from '@interfaces';

import '../sharedTableList.scoped.scss';
import { DocumentFileCategoryEnum, FileObject } from '@utils';
import { CostFile } from '@services';
import { HeaderData } from '../sharedTableList.types';

interface Props {
  testcy: string;
  item: CostFile | FileObject;
  header?: HeaderData;
  selectedRowId?: string;
  actionMenuItems?: Option[];
  tagOptions?: Option[]; //documentFileCategoryOptions
  showMenu?: boolean;
  isClickable?: boolean;
  onDigitalizeClick?: () => void;
  onPreviewClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onDeleteClick?: () => void;
  onDownloadClick?: () => void;
  onClickRow?: () => void;
  onClickMenuItem?: (action: string, item: CostFile | FileObject) => void;
  onChangeCategoryFile?: (value: DocumentFileCategoryEnum, id: number) => void;
}

export const SharedTableListRow = ({
  testcy,
  item,
  header,
  selectedRowId,
  actionMenuItems,
  showMenu = false,
  tagOptions,
  isClickable = false,
  onDigitalizeClick,
  onPreviewClick,
  onDeleteClick,
  onDownloadClick,
  onClickRow,
  onClickMenuItem,
  onChangeCategoryFile,
}: Props) => {
  const dateMemo = useMemo(() => {
    return dateFormatter.objectToString(new Date((item as FileObject).uploadDate?.toString() || (item as CostFile)?.createdDate), 'dd MMMM yyyy, HH:mm');
  }, [(item as FileObject).uploadDate, (item as CostFile).createdDate]);

  const itemClasses = useMemo(() => {
    return ['cost-list-item', isClickable && 'row-hover', selectedRowId == item.id ? 'selected-row' : ''].join(' ');
  }, [selectedRowId, item.id, isClickable]);

  const transformName = (originalFilename: string) => {
    const lastIndex = originalFilename.lastIndexOf('.');
    const name = originalFilename.substring(0, lastIndex);
    const fileExtension = originalFilename.substring(lastIndex);
    return { name, fileExtension };
  };

  const checkActionOptions = (action: string) => {
    return actionMenuItems?.some(item => item.value === action);
  };

  return (
    <div className={itemClasses} onClick={onClickRow}>
      {header.name && (
        <div className="file-name-container">
          <div className="icon-wrapper">
            <DocumentIcon size={24} colorType="black" />
          </div>
          <div className="file-name" data-testcy={`${testcy}-file-name`}>
            <span>{transformName(item?.originalFilename).name}</span>
            <span className="file-extension">{transformName(item?.originalFilename).fileExtension}</span>
          </div>
        </div>
      )}
      {header.tag && (
        <div className="tag-container" data-testcy={`${testcy}-tag`}>
          <TheSelect
            onChange={value => {
              onChangeCategoryFile(value as any, Number(item.id));
            }}
            testcy={`document-category-${item.id}`}
            name={`document-category-${item.id}`}
            options={tagOptions}
            placeholder="Dodaj tag"
            appearance="tag"
            size="small"
            width="unset"
            defaultValue={(item as FileObject)?.category || ''}
          />
        </div>
      )}
      {dateMemo && (
        <div className="date-container" data-testcy={`${testcy}-created-date`}>
          {dateMemo}
        </div>
      )}
      <div className={`actions-container ${showMenu ? 'actions-container-menu' : `actions-container-icons-${actionMenuItems.length}`}`}>
        {!showMenu && checkActionOptions('digitalize') && (
          <ButtonIconNew
            testcy={`${testcy}-action-digitalize`}
            color="primary-light"
            size="small"
            tooltip="Przekształć na format cyfrowy"
            onClick={e => {
              e.stopPropagation();
              onDigitalizeClick();
            }}
          >
            <DigitalizeIcon />
          </ButtonIconNew>
        )}
        {!showMenu && checkActionOptions('preview') && (
          <ButtonIconNew
            testcy={`${testcy}-action-preview`}
            color="white"
            size="small"
            tooltip="Podgląd"
            onClick={e => {
              e.stopPropagation();
              onPreviewClick(e);
            }}
          >
            <DocumentIcon />
          </ButtonIconNew>
        )}
        {!showMenu && checkActionOptions('download') && (
          <ButtonIconNew
            testcy={`${testcy}-action-preview`}
            color="white"
            size="small"
            tooltip="Pobierz"
            onClick={e => {
              e.stopPropagation();
              onDownloadClick();
            }}
          >
            <DownloadIcon />
          </ButtonIconNew>
        )}
        {!showMenu && checkActionOptions('delete') && (
          <ButtonIconNew
            testcy={`${testcy}-action-delete`}
            color="red"
            size="small"
            tooltip="Usuń"
            onClick={e => {
              e.stopPropagation();
              onDeleteClick();
            }}
          >
            <DeleteIconNew />
          </ButtonIconNew>
        )}
        {showMenu && <PopupMenu menuItems={actionMenuItems} onClickMenuItem={actionType => onClickMenuItem(actionType, item)} />}
      </div>
    </div>
  );
};
