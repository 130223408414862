import React from 'react';
import { TableHeaderInterface, TableRowInterface } from '@components';
import { EmployeeDetails, FilesDetails } from '@services';
import { EmployeesStatusEnum } from '@utils';
import { EmployeesContractEnum } from 'src/services/employees';

export const tableHeader: TableHeaderInterface[] = [
  {
    label: 'Nazwa pliku',
    data: 'originalFilename',
    minWidth: 120,
    width: 800,
    isSortable: false,
  },
  {
    label: '',
    data: 'action',
    width: 64,
    minWidth: 64,
    action: true,
    isSortable: false,
  },
];

export const employeeDocumentDataToTableRow = (data: FilesDetails): TableRowInterface => {
  const id = data?.id?.toString();

  return {
    id: id,
    originalFilename: { content: data.originalFilename || '' },
    action: {},
  };
};

export const actionMenuItems = [
  {
    label: 'Usuń plik',
    value: 'delete',
  },
  {
    label: 'Pobierz plik',
    value: 'download',
  },
];

export const actionMenuItemsEmployeeProfile = [
  {
    label: 'Edycja pracownika',
    value: 'employeeEdit',
    disabled: false,
  },
  {
    label: 'Wyrejestruj pracownika',
    value: 'employeeUnregister',
    disabled: false,
  },
];

export const transformMenuItemsEmployeeProfile = (employeeDetails: EmployeeDetails) => {
  const disabledEmployeeUnregister =
    employeeDetails?.status === EmployeesStatusEnum.UNREGISTER || employeeDetails?.typeOfEmployment === EmployeesContractEnum.CivilLawContract;
  const menuItems = [
    {
      label: 'Edycja pracownika',
      value: 'employeeEdit',
    },
    {
      label: 'Wyrejestruj pracownika',
      value: 'employeeUnregister',
      disabled: disabledEmployeeUnregister,
    },
  ];
  return menuItems;
};
