import React from 'react';
import { MessageComposeBar } from '../MessageComposeBar/messageComposeBar';
import { ChatHeader } from '../ChatHeader/chatHeader';
import { MessageList } from '../MessageList/messageList';
import { useChatContext } from '../../providers/ChatContextProvider';
import { useMessagingChannel } from '../../hooks/useMessagingChannel';
import { InvoiceBar } from '../InvoiceBar/invoiceBar';

import './chat.scoped.scss';

type Props = {
  subChannelId: string;
  chatName: string;
  isFloating?: boolean;
  isMinimized?: boolean;
  headerContainerRef?: React.RefObject<HTMLDivElement>;
};

export const Chat = ({ subChannelId, chatName, isFloating = false, isMinimized = false, headerContainerRef }: Props) => {
  const { currentUserId, isConnected, channels } = useChatContext();

  const companyId = channels.find(ch => ch.channelId === subChannelId)?.discussedCompanyId;

  const { messageCollection, messageComposer, sendCurrentMessage, resolveHints, isSending, invoiceTag, disableContext } = useMessagingChannel({
    subChannelId,
    companyId,
    userId: currentUserId,
  });

  return (
    <div className="chat-container">
      <ChatHeader
        chatName={chatName}
        dense={isFloating}
        containerRef={headerContainerRef}
        showMinimize={isFloating && !isMinimized}
        showMaximize={isFloating && !isMinimized}
        showClose={isFloating}
      />
      {!isMinimized && (
        <>
          {!isConnected && <p>Wystąpił błąd przy łączeniu z serwerem.</p>}
          <MessageList messages={messageCollection} />
          <InvoiceBar invoiceNumber={invoiceTag?.number} onClose={disableContext} />
          <MessageComposeBar
            dense={isFloating}
            composer={messageComposer}
            isSending={isSending}
            resolveHints={resolveHints}
            sendCurrentMessage={sendCurrentMessage}
          />
        </>
      )}
    </div>
  );
};
