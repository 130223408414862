import React from 'react';
import { EmployeeForm } from '@features';
import { TheDialog } from '@components';
import { EmployeeDetails } from '@services';

interface Props {
  openEmployeeModal: boolean;
  setOpenEmployeeModal: (v: boolean) => void;
  employeeDetails?: EmployeeDetails;
}

export const ModalAndDialogs = ({ openEmployeeModal, setOpenEmployeeModal, employeeDetails }: Props) => {
  const closeModal = () => setOpenEmployeeModal(false);

  return (
    <>
      <TheDialog formDialog title="Edycja pracownika" testcy="employee-form" open={openEmployeeModal} onClose={closeModal}>
        {!!employeeDetails && <EmployeeForm defaultValues={employeeDetails} onSubmitted={closeModal} />}
      </TheDialog>
    </>
  );
};
