import React from 'react';
import { DashboardCard, DashboardCardHeader, ReceivablesPayablesIcon } from '@components';
import { useReceivablesPayables } from '@hooks';
import { CardContent } from './cardContent/cardContent';

import './receivablesPayablesCard.scss';

const ReceivablesPayablesCardRaw = () => {
  const {
    data: { receivables, payables },
    chartData: { receivables: receivablesChartData, payables: payablesChartData },
    isLoading,
  } = useReceivablesPayables();

  return (
    <div className="receivables-payables-wrapper">
      <DashboardCard>
        <DashboardCardHeader
          icon={<ReceivablesPayablesIcon type="receivables" />}
          title="Należności"
          subtitle="Nadchodzące płatności od Twoich kontrahentów."
        />
        <div className="receivables">
          <CardContent data={receivables} chartData={receivablesChartData} isLoading={isLoading} type="receivables" />
        </div>
      </DashboardCard>
      <DashboardCard>
        <DashboardCardHeader
          icon={<ReceivablesPayablesIcon type="payables" />}
          title="Zobowiązania"
          subtitle="Zbliżające się płatności zobowiązań finansowych."
        />
        <div className="payables">
          <CardContent data={payables} chartData={payablesChartData} isLoading={isLoading} type="payables" />
        </div>
      </DashboardCard>
    </div>
  );
};

export const ReceivablesPayablesCard = React.memo(ReceivablesPayablesCardRaw);
