import { MessageContentType, MessageRepository } from '@amityco/ts-sdk';
import { InvoiceTag } from '../model/chatMessage';

export type SendTextMessageParams = {
  subChannelId: string;
  textMessage: string;
  tag?: InvoiceTag;
};

type SendInvoiceHeaderMessageParams = {
  subChannelId: string;
  tag: InvoiceTag;
};

export const sendTextMessage = ({ subChannelId, textMessage, tag }: SendTextMessageParams) => {
  return MessageRepository.createMessage({
    subChannelId,
    dataType: MessageContentType.TEXT,
    data: {
      text: textMessage,
    },
    metadata: {
      number: tag?.number,
      id: tag?.id,
    },
  });
};

export const sendInvoiceHeaderMessage = ({ subChannelId, tag }: SendInvoiceHeaderMessageParams) => {
  return MessageRepository.createMessage({
    subChannelId,
    dataType: MessageContentType.TEXT,
    data: {
      text: `Numer: ${tag.number}\nId: ${tag.id}`,
    },
  });
};
