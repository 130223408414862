import { ButtonMain, ButtonText, LoaderIcon, PageLoaderWrapper, TableHeaderInterface, TableRowInterface } from '@components';
import { OwnerContractorForm, SharedFilePreview, SharedInvoiceDetails } from '@features';
import { useNavigate, useParams } from 'react-router';
import { addHotjarEvent, dateFormatter, invalidateApiQuery } from '@lib';
import { useInvoiceDrawerController, useIsAllFalse } from '@hooks';
import { RequestErrorMessage } from '@interfaces';
import {
  ContractorData,
  CostCalculatedData,
  GetCostSumValueParams,
  UpdateCostDataRequest,
  CostData,
  editCostData,
  getContractorById,
  getCostDataById,
  getCostSumValues,
} from '@services';
import { useContractorStore, useCostStore } from '@store';
import {
  AccountantStatusType,
  CostsUnitEnum,
  PaymentType,
  formErrorMsg,
  formatNumberToCurrency,
  handleApiError,
  isArraysEqual,
  paymentTypeOptions,
  removeDisabledField,
  scrollToFirstError,
  snackbarMessagesHandler,
  stringToNumberSecondOptions,
} from '@utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ContractorSection, InvoiceDataSection, PaymentSection, VatSection } from './components';
import {
  createSubmitData,
  editExistingRow,
  generateRow,
  getValuesToCalculateSummary,
  setCalculatedValuesToInvoice,
  setFormData,
  tableHeaderGenerate,
} from './inDevelopmentTab.utils';
import { useAccountingTemplate } from './hooks';
import './inDevelopmentTab.scoped.scss';
import { startOfMonth } from 'date-fns';
import { useContractorSection } from 'src/hooks/contractor';

interface Props {
  openInDevelopmentTab?: any;
  onClickTab?: (v: string) => void;
}

export const InDevelopmentTab = ({ openInDevelopmentTab, onClickTab }: Props, ref) => {
  const [tableHeader, setTableHeader] = useState<TableHeaderInterface[]>([]);
  const [basicSectionOpen, setBasicSectionOpen] = useState(true);

  const [contractorSectionOpen, setContractorSectionOpen] = useState(true);
  const [prodServiceSectionOpen, setProdServiceSectionOpen] = useState(true);
  const [rows, setRows] = useState<TableRowInterface[]>([]);
  const [lastNumberId, setLastNumberId] = useState(1);
  const [netSummary, setNetSummary] = useState('0,00');
  const [vatSummary, setVatSummary] = useState('0,00');
  const [grossSummary, setGrossSummary] = useState('0,00');
  const [amountPaid, setAmountPaid] = useState('0,00');
  const [leftToPaid, setLeftToPaid] = useState('0,00');
  const [paymentSectionOpen, setPaymentSectionOpen] = useState(false);
  const [isBlurred, setIsBlurred] = useState(true);
  const [paymentTypeCurrent, setPaymentTypeCurrent] = useState<PaymentType | null>(null);
  const [disableBankAccount, setHideBankAccount] = useState<boolean>(null);
  const [selectedCostDocumentId, setSelectedCostDocumentId] = useState('');
  const [selectedCostDocumentName, setSelectedCostDocumentName] = useState('');
  const [isPendingSubmit, setIsPendingSubmit] = useState(false);
  const [isPendingCostData, setIsPendingCostData] = useState(false);
  const [isPendingRecalculate, setIsPendingRecalculate] = useState(false);
  const [contractorOptionsEditedInvoice, setContractorOptionsEditedInvoice] = useState([]);
  const { contractorsList, setSelectedContractor } = useContractorStore();
  const [hasZW, setHasZW] = useState(false);
  const [status, setStatus] = useState<AccountantStatusType>(AccountantStatusType.New);
  const [fieldRecalculationNeeded, setFieldRecalculationNeeded] = useState(false);
  const [paymentValuesToSend, setPaymentValuesToSend] = useState<GetCostSumValueParams[]>([]);
  const [lastPaymentValuesSent, setLastPaymentValuesSent] = useState<GetCostSumValueParams[]>([]);

  const [issueDate, setIssueDate] = useState('');
  const [isIssueDateDefined, setIsIssueDateDefined] = useState<boolean>();
  const { setCostAccountingSettings, costId } = useCostStore();

  const isPaid = paymentTypeCurrent === PaymentType.Paid;
  const {
    control,
    setValue,
    unregister,
    getValues,
    handleSubmit,
    trigger,
    setError,
    clearErrors,
    watch,
    register,
    resetField,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });
  const invoicePaidAmount = watch('paidAmount');
  const { id, companyId } = useParams();
  const navigate = useNavigate();

  const { isPendingAccountingTemplateData, accountingTemplateDataOptions } = useAccountingTemplate();

  const isLoadingPage = useIsAllFalse([isPendingAccountingTemplateData, isPendingCostData], 0);
  const { openInvoiceDrawer, closeInvoiceDrawer } = useInvoiceDrawerController('cost');

  const {
    search: contractorSearch,
    modalController: contractorModalController,
    formDefaults: contractorFormDefaults,
    allowedActions: contractorAllowedActions,
  } = useContractorSection({
    defaultOptions: contractorOptionsEditedInvoice,
    clientId: watch('client'),
    clientName: watch('contractorNameInvoiceEdit'),
  });

  const onCreatedContractor = (closeModal: boolean, id: string, name: string) => {
    contractorSearch.setValue(name);
    setValue('client', id);
    setValue('selectOptionId', id);
    setValue('contractorNameInvoiceEdit', name);
    addHotjarEvent('user created contractor from invoice view ', 'action');
    invalidateApiQuery('getContractors');
    contractorModalController.closeModal();
  };

  useEffect(() => {
    if (isBlurred) {
      setAmountPaid(formatNumberToCurrency(stringToNumberSecondOptions(invoicePaidAmount)));
    }
  }, [invoicePaidAmount, isBlurred]);

  useEffect(() => {
    const header = tableHeaderGenerate();
    setSelectedContractor(null, '');
    setTableHeader(header);
    if (id) addRowToTable(true);
    register('disabledValues');
    setSelectedCostDocumentId(id);
    setValue('paymentType', '');
    fetchCostData(id);
    closeInvoiceDrawer();
  }, []);

  useEffect(() => {
    if (paymentTypeCurrent) {
      const isCardOrFullyPaid = paymentTypeCurrent === PaymentType.Card || paymentTypeCurrent === PaymentType.Paid;
      const isTransfer = paymentTypeCurrent === PaymentType.Transfer || paymentTypeCurrent === PaymentType.TransferSplitPayment;
      setHideBankAccount(!isTransfer);

      const values = getValues();
      if (!isTransfer && !values.bankAccount) clearErrors('bankAccount');
      if (isCardOrFullyPaid && !values.dueDate) clearErrors('dueDate');
    }
  }, [clearErrors, getValues, paymentTypeCurrent]);

  useEffect(() => {
    if (grossSummary) {
      trigger('paidAmount');
      setLeftToPaid(formatNumberToCurrency(stringToNumberSecondOptions(grossSummary) - stringToNumberSecondOptions(amountPaid)));
    }
    if (grossSummary) {
      if (isPaid) {
        setAmountPaid(grossSummary);
        setLeftToPaid('0,00');
      } else {
        const paid = getValues('paidAmount');
        setAmountPaid(paid);
        setLeftToPaid(formatNumberToCurrency(stringToNumberSecondOptions(grossSummary) - stringToNumberSecondOptions(paid)));
      }
    }
  }, [isPaid, grossSummary, amountPaid, leftToPaid]);

  useEffect(() => {
    const isTheSameValue = isArraysEqual(paymentValuesToSend, lastPaymentValuesSent);
    const isAnyValueChanges = !isTheSameValue;
    const isValues = paymentValuesToSend.length;

    if (isAnyValueChanges && isValues) fetchCostsValues(paymentValuesToSend);
  }, [paymentValuesToSend]);

  const fetchCostsValues = useCallback(
    async (values: GetCostSumValueParams[]) => {
      setIsPendingRecalculate(true);
      try {
        const data: CostCalculatedData = await getCostSumValues(paymentValuesToSend);
        setGrossSummary(formatNumberToCurrency(data.grossValue));
        setNetSummary(formatNumberToCurrency(data.netValue));
        setVatSummary(formatNumberToCurrency(data.vatValue));
        setCalculatedValuesToInvoice(data, setValue, paymentValuesToSend);
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsPendingRecalculate(false);
        setFieldRecalculationNeeded(false);
        setLastPaymentValuesSent(values);
      }
    },
    [paymentValuesToSend, setValue]
  );

  const onSubmit = (data, redirectToList = false): void => {
    const submitData = createSubmitData(data, disableBankAccount, hasZW, companyId, accountingTemplateDataOptions);
    submitEditCost(submitData, redirectToList);
  };

  const submitEditCost = (submitData: UpdateCostDataRequest, redirectToList: boolean) => {
    addHotjarEvent('edit invoice');
    setIsPendingSubmit(true);
    editCostData(submitData, selectedCostDocumentId)
      .then(() => {
        snackbarMessagesHandler.costEdited();
        setIsIssueDateDefined(true);
        openInvoiceDrawer('invoiceDrawerAndInvoicePreview', id);
      })
      .catch((err: RequestErrorMessage[]) => {
        handleApiError(err);
      })
      .finally(() => setIsPendingSubmit(false));
  };

  const fetchCostData = (id: string) => {
    addHotjarEvent('edit invoice');
    setIsPendingCostData(true);

    getCostDataById(+id)
      .then(data => {
        data?.invoicingData?.issueDate && setIsIssueDateDefined(true);
        setCostAccountingSettings({ accountingTemplate: data?.invoicingData?.accountingTemplate, vatTemplate: data?.invoicingData?.vatTemplate });
        if (!data.invoicingData) return;
        setStatus(data.accountingStatus);
        setIssueDate(data.invoicingData.issueDate);
        if (data.invoicingData?.vatExemptionInfo?.exemptionType) {
          setHasZW(true);
        }
        setSelectedCostDocumentName(data.invoicingData.number);
        const isContractorOnList = contractorsList.find(contractor => {
          return contractor.id === data.invoicingData.contractorId;
        });
        if (!isContractorOnList && data.invoicingData.contractorId) {
          fetchContractorData(data.invoicingData.contractorId, data.invoicingData);
        } else {
          setFormData(data.invoicingData, setValue, getValues, addMultipleRows, contractorsList);
        }
        setPaymentTypeCurrent(data.invoicingData.paymentMethod || 'None');

        setGrossSummary(formatNumberToCurrency(data.invoicingData.grossValue));
        setNetSummary(formatNumberToCurrency(data.invoicingData.netValue));
        setVatSummary(formatNumberToCurrency(data.invoicingData.vatValue));
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingCostData(false));
  };
  const fetchContractorData = (id: string, invoicingData: CostData) => {
    getContractorById(id)
      .then((data: ContractorData) => {
        contractorsList.push({
          id: id,
          name: data.friendlyName || data.name,
          fullName: data.name,
        });
        const contractorsListPlusDeleteContractor = contractorsList.map(contr => {
          return { label: contr.name, value: contr.id };
        });
        setContractorOptionsEditedInvoice(contractorsListPlusDeleteContractor);
        setFormData(invoicingData, setValue, getValues, addMultipleRows, contractorsList);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {});
  };

  const checkIfRecalculationNeeded = (valueChange: CostsUnitEnum | null, rowId?: string): boolean => {
    let beforeChange = getValues(`onFocus-${valueChange}-${rowId}`);
    let afterChange = getValues(`onBlur-${valueChange}-${rowId}`);
    if (valueChange === 'VatRate' && afterChange === '') {
      afterChange = beforeChange;
    }

    const bothDefinedAndSame = afterChange === beforeChange && afterChange !== undefined && beforeChange !== undefined;
    const valueNotChanged = afterChange === undefined;

    if (bothDefinedAndSame || valueNotChanged) {
      setFieldRecalculationNeeded(false);
      return false;
    }

    return true;
  };

  const onCloseInvoice = () => {
    if (companyId) {
      navigate(`/owner-company-list/details/${companyId}`);
      return;
    }
    navigate('/costs-list');
  };

  const addRowToTable = (isFirst: boolean = false) => {
    if ((isFirst && rows.length) || rows.length > 5) return;
    const row = generateRow(
      lastNumberId.toString(),
      control,
      onRecalculate,
      watch,
      setValue,
      clearErrors,
      getValues,
      trigger,
      setFieldRecalculationNeeded,
      checkIfRecalculationNeeded,
      false,
      rows.length
    );
    setLastNumberId(lastNumberId + 1);
    setRows([...rows, row]);
    setTimeout(() => {
      const select = document.getElementById(`vatRate-${lastNumberId.toString()}`);
      select?.focus();
    }, 200);
  };

  const addMultipleRows = (idsAndCustomAmounts: [number, boolean][]) => {
    const currentRows = [];
    idsAndCustomAmounts.forEach(([id, hasCustomAmounts], index: number) => {
      const generatedRow = generateRow(
        id.toString(),
        control,
        onRecalculate,
        watch,
        setValue,
        clearErrors,
        getValues,
        trigger,
        setFieldRecalculationNeeded,
        checkIfRecalculationNeeded,
        hasCustomAmounts,
        index
      );
      generatedRow.action.syncToggleState = !hasCustomAmounts;
      currentRows.push(generatedRow);
      if (index + 1 === idsAndCustomAmounts.length) setLastNumberId(id + 1);
    });
    setRows(currentRows);
  };

  const editRow = (id: string, editFn: (row: TableRowInterface) => any) => {
    const newRows = editExistingRow([...rows], id, editFn);
    setRows(newRows);
  };

  const onRecalculate = (valueChange: CostsUnitEnum | null, rowId?: string, force?: boolean) => {
    if (!force) {
      const skipRecalculation = !checkIfRecalculationNeeded(valueChange, rowId);
      if (skipRecalculation) {
        return;
      }
    }
    setTimeout(() => {
      const formValues = getValues();
      const values = getValuesToCalculateSummary(formValues, valueChange, rowId);
      setPaymentValuesToSend(values);
      if (!values.length) {
        setNetSummary('0,00');
        setVatSummary('0,00');
        setGrossSummary('0,00');
      }

      const arrayOfFormValues = Object.entries(formValues);
      if (valueChange === 'VatRate') {
        const filteredVatValue = arrayOfFormValues
          .filter(([key, value]) => key.slice(0, 8) === 'vatRate-')
          .map(([key, value]) => {
            return { name: `${key}`, value: `${value}` };
          });
        const lookup = filteredVatValue.reduce((a, e) => {
          a[e.value] = ++a[e.value] || 0;
          return a;
        }, {});
        const errorDuplicates = filteredVatValue.filter(e => lookup[e.value]);
        errorDuplicates.forEach(e => setError(e.name, { message: formErrorMsg.costVatRateDuplicated, type: 'custom' }));
        if (!errorDuplicates.length) {
          filteredVatValue.forEach(VatRate => {
            clearErrors(VatRate.name);
          });
        }

        const filteredZwValue = arrayOfFormValues.filter(([key, value]) => key.slice(0, 7) === 'vatRate' && value === 'ZW');
        if (filteredZwValue.length) {
          setHasZW(true);
        } else {
          setHasZW(false);
        }
      }
    });
  };

  const removeRow = (rowData: TableRowInterface) => {
    const id = rowData.id;
    const currentRows = rows;
    const index = currentRows.findIndex(row => row.id === id);

    if (index < 0) return;

    const rowToDelete = currentRows.splice(index, 1);
    if (rowToDelete[0]) unregisterTableRow(rowToDelete[0]);
    const filtered = currentRows.filter(key => getValues(`vatRete-${key.id}`) === 'ZW');

    if (!filtered.length) {
      setHasZW(false);
    }

    const filteredZw = currentRows.filter(key => getValues(`vatRete-${key.id}`) === 'ZW');
    if (!filteredZw.length) {
      setHasZW(false);
      resetField('zwOptions');
      resetField('zwVatOther');
    }

    setRows([...currentRows]);
    onRecalculate(null, null, true);
  };

  const unregisterTableRow = (rowToDel: TableRowInterface) => {
    Object.keys(rowToDel).forEach(key => {
      const propName = rowToDel[key]?.stashedData?.name;
      propName && unregister(propName);
    });
  };

  const checkIsSubmitDisabled = useCallback(() => {
    return status !== AccountantStatusType.New;
  }, [status]);

  const onCancel = () => {
    if (companyId) {
      navigate(`/owner-company-list/details/${companyId}`);
      return;
    }
    navigate('/costs-list');
  };

  const onClickSyncToggle = (id: string, shouldBeActive: boolean) => {
    if (shouldBeActive) return;
    editRow(id, v => {
      v.action.syncToggleState = false;
    });
    setValue(`recalculationDisabled-${id}`, true);
    removeDisabledField(setValue, getValues, `vatValue-${id}`);
  };

  const onIssueDateHandle = (date: string) => {
    const issueDateParsed = dateFormatter.getDateObjectFromString(date);
    setIssueDate(dateFormatter.objectToString(issueDateParsed, "yyyy-MM-dd'T'HH:mm:ss"));

    const newIssueDate = dateFormatter.changeFormat(issueDate, "yyyy-MM-dd'T'HH:mm:SS", 'dd.MM.yyyy');
    !isIssueDateDefined && date && date !== newIssueDate && setValue('deliveryDate', issueDateParsed);
    !isIssueDateDefined && date && date !== newIssueDate && setValue('deductionMonth', startOfMonth(issueDateParsed));
    !isIssueDateDefined && trigger('deductionMonth');
  };
  return (
    <div className="owner-invoice-wrapper standard-layout-spacing-g">
      <SharedInvoiceDetails invoiceId={costId} isAccountant={!!companyId} type="cost" />
      <div className="cost-invoice-wrapper">
        <ButtonText testcy="go-back" onClick={onCancel} fontSize="12px" content="powrót" startIcons="back" />
        <PageLoaderWrapper isLoading={isLoadingPage}>
          <div className="file-and-form-wrapper">
            <div className="file-wrapper">
              <SharedFilePreview
                testcy="costs-form-file-preview"
                fileId={selectedCostDocumentId}
                fileName={selectedCostDocumentName}
                modalParentId="shared-cost-file-preview"
                arrangement={'center'}
                type="cost"
                startZoom={1.5}
                allowCopyText={true}
              />
            </div>
            {contractorModalController.isOpen ? (
              <div className="form-wrapper ">
                <OwnerContractorForm
                  onCreatedContractor={onCreatedContractor}
                  defaultNip={contractorFormDefaults.Nip}
                  contractorDefaultId={contractorFormDefaults.Id}
                  defaultName={contractorFormDefaults.Name}
                  showToggleSaveToDataBase={false}
                  addNewContractor={contractorModalController.isAdd}
                  onClickBackButton={contractorModalController.closeModal}
                />
              </div>
            ) : (
              <form className="form-wrapper">
                <InvoiceDataSection
                  open={basicSectionOpen}
                  setOpen={setBasicSectionOpen}
                  useFormFeatures={{ control, trigger, setError, clearErrors }}
                  onIssueDateHandle={onIssueDateHandle}
                />
                <ContractorSection
                  open={contractorSectionOpen}
                  setOpen={setContractorSectionOpen}
                  useFormFeatures={{ control, setValue, trigger, watch }}
                  contractorOptions={contractorSearch.options}
                  isPendingContractors={contractorSearch.isLoadingOptions}
                  onFilterContractorChanged={contractorSearch.setValue}
                  onOpenContractorModal={contractorModalController.openModal}
                  allowedActions={contractorAllowedActions}
                  date={issueDate}
                />
                <VatSection
                  open={prodServiceSectionOpen}
                  setOpen={setProdServiceSectionOpen}
                  useFormFeatures={{ control, watch, resetField, setValue, trigger, clearErrors }}
                  rows={rows}
                  tableHeader={tableHeader}
                  removeRow={removeRow}
                  addRowToTable={addRowToTable}
                  netSummary={netSummary}
                  vatSummary={vatSummary}
                  grossSummary={grossSummary}
                  isPendingRecalculate={isPendingRecalculate}
                  onClickSyncToggle={onClickSyncToggle}
                />
                <PaymentSection
                  open={paymentSectionOpen}
                  setOpen={setPaymentSectionOpen}
                  useFormFeatures={{ clearErrors, control, trigger, setError, watch, setValue, resetField }}
                  paymentTypeOptions={paymentTypeOptions}
                  amountPaid={amountPaid}
                  leftToPaid={leftToPaid}
                  grossSummary={grossSummary}
                  setPaymentTypeCurrent={setPaymentTypeCurrent}
                  setIsBlurred={setIsBlurred}
                  isPaid={isPaid}
                />
                <div className="button-area">
                  {isPendingSubmit && (
                    <div className="button-loader">
                      <LoaderIcon size={'medium'} />
                    </div>
                  )}
                  <div className="button-close">
                    <ButtonMain testcy="costs-form-cancel" content={'Anuluj'} width="100%" isDisabled={isPendingSubmit} onClick={onCloseInvoice} />
                  </div>
                  <div className="button-submit">
                    <ButtonMain
                      testcy="costs-form-save-and-continue"
                      content={'Zapisz i kontynuuj'}
                      width="100%"
                      type="button"
                      onClick={async () => {
                        await trigger();
                        if (Object.keys(errors).length) scrollToFirstError(Object.keys(errors));
                        if (isValid && !fieldRecalculationNeeded) {
                          handleSubmit(f => onSubmit(f))();
                        }
                      }}
                      isDisabled={checkIsSubmitDisabled() || isPendingSubmit || fieldRecalculationNeeded}
                      tooltip={checkIsSubmitDisabled() && 'Nie można edytować kosztów wysłanych do księgowości.'}
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
        </PageLoaderWrapper>
      </div>
    </div>
  );
};
