import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const PencilIcon = ({ colorType = '' }: Props) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon-color-${colorType}-g`}
    >
      <path
        d="M3.27825 14.4C3.32325 14.4 3.36825 14.3955 3.41325 14.3888L7.19775 13.725C7.24275 13.716 7.2855 13.6958 7.317 13.662L16.8547 4.12425C16.8756 4.10343 16.8922 4.07871 16.9034 4.05149C16.9147 4.02427 16.9205 3.99509 16.9205 3.96563C16.9205 3.93616 16.9147 3.90698 16.9034 3.87976C16.8922 3.85254 16.8756 3.82782 16.8547 3.807L13.1153 0.06525C13.0725 0.0225 13.0163 0 12.9555 0C12.8948 0 12.8385 0.0225 12.7958 0.06525L3.258 9.603C3.22425 9.63675 3.204 9.67725 3.195 9.72225L2.53125 13.5068C2.50936 13.6273 2.51718 13.7513 2.55404 13.8682C2.59089 13.985 2.65566 14.0911 2.74275 14.1772C2.89125 14.3212 3.078 14.4 3.27825 14.4ZM4.79475 10.476L12.9555 2.3175L14.6047 3.96675L6.444 12.1253L4.44375 12.4785L4.79475 10.476ZM17.28 16.29H0.72C0.32175 16.29 0 16.6118 0 17.01V17.82C0 17.919 0.081 18 0.18 18H17.82C17.919 18 18 17.919 18 17.82V17.01C18 16.6118 17.6783 16.29 17.28 16.29Z"
        fill="#0AA473"
      />
    </svg>
  );
};
