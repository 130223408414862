import { DashboardCard, DashboardCardHeader, ProfitAndLossChart, ProfitsLossesIcon } from '@components';
import { useProfitsLosses } from '@hooks';
import { dateFormatter } from '@lib';
import { useListParamsStore } from '@store';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import './profitsLossesCard.scss';
import ProfitsLossesSummary from './profitsLossesSummary/profitsLossesSummary';

const ProfitsLossesCardRaw = () => {
  const [dateRange, setDateRange] = useState(dateFormatter.getYearRange());
  const { profitsLossesData, isLoading, profitsSum, costsSum } = useProfitsLosses(dateRange);
  const navigate = useNavigate();

  const { setLastParamsInvoiceList, setLastParamsCostDocumentsList } = useListParamsStore();

  const onProfitsAndLossesClick = (date: Date, seriesIndex: number) => {
    const { fromDate, toDate } = dateFormatter.dateToMonthRange(date);

    if (seriesIndex === 0) {
      setLastParamsInvoiceList({ searchBarValues: { fromDate, toDate } });
      navigate(`/invoices-list`);
      return;
    }

    setLastParamsCostDocumentsList({ searchBarValues: { fromDate, toDate } });
    navigate(`/costs-list`);
  };

  return (
    <DashboardCard>
      <DashboardCardHeader icon={<ProfitsLossesIcon />} title="Przychody i koszty" subtitle="Twoje zestawienie przychodów i kosztów w ujęciu czasowym." />
      <div className="chart-container">
        <ProfitAndLossChart series={profitsLossesData} isPending={isLoading} onClick={onProfitsAndLossesClick} />
        <ProfitsLossesSummary setDateRange={setDateRange} profitsSum={profitsSum} costsSum={costsSum} />
      </div>
    </DashboardCard>
  );
};

export const ProfitsLossesCard = React.memo(ProfitsLossesCardRaw);
