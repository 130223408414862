import { dateFormatter, httpClient } from '@lib';
import { MAX_DATE_STRING, MIN_DATE_STRING, ProfitLossChartAPIData, ReceivablesPayablesChartAPIData as ReceiablesPayablesAPIData } from '@utils';
import { AxiosResponse } from 'axios';

export interface GetDashboardParams {
  dateFrom?: string;
  dateTo?: string;
}

export type GetCashflowParams = {
  from: Date;
  to: Date;
};

export type CashflowDataPoint = {
  date: Date;
  value: number;
};

export type GetCashflowResult = {
  dataPoints: CashflowDataPoint[];
};

export type UpdateBankAccountBalanceSubmitData = {
  date: Date;
  balance: number;
};

export const getProfitsAndLosses = async (params: GetDashboardParams) => {
  try {
    const response = await httpClient.get<ProfitLossChartAPIData>(`/Dashboard/profitLoss`, { params });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getReceivablesAndPayables = async () => {
  try {
    const response = await httpClient.get<ReceiablesPayablesAPIData>(`/Dashboard/receivablesPayables`, {
      params: { dateFrom: MIN_DATE_STRING, dateTo: MAX_DATE_STRING },
    });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getCashflow = async (params: GetCashflowParams): Promise<GetCashflowResult> => {
  try {
    const response: AxiosResponse<GetCashflowResult> = await httpClient.get(`/Dashboard/Cashflow`, { params });

    return {
      ...response.data,
      dataPoints: response.data.dataPoints.map(dataPoint => ({
        ...dataPoint,
        date: new Date(dataPoint.date),
      })),
    };
  } catch (e) {
    throw e?.response?.data;
  }
};

export const updateBankAccountBalance = async (params: UpdateBankAccountBalanceSubmitData) => {
  try {
    await httpClient.post(`/Dashboard/Cashflow/BankAccountBalance`, {
      date: dateFormatter.objectToString(params.date, 'yyyy-MM-dd'),
      balance: params.balance,
    });
  } catch (e) {
    throw e?.response?.data;
  }
};
