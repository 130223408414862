import { ReminderData } from '@services';
import { EmailFormData } from '../sharedEmailForm/sharedEmailForm.utils';
import { ReminderFormData } from '../sharedReminderForm/sharedReminderForm.utils';
import { dateFormatter } from '@lib';
import { stringToNumberSecondOptions } from '@utils';
import { RecordAmountFormData } from './components/invoicePopups/components/invoiceRecordPaymentForm/invoiceRecordPaymentForm.utils';

export const transformFormDataToEmailData = (data: EmailFormData) => {
  const transformedData = {
    recipients: [...data.emailTo],
    subject: data.subject,
    body: data.emailMessage,
    sendCopyToSender: data.sendCopy,
  };
  return transformedData;
};

export const transformFormDataToReminderData = (data: ReminderFormData): ReminderData => {
  const transformedData = {
    recipients: [...data.emailTo],
    subject: data.subject,
    body: data.emailMessage,
    sendCopyToSender: data.sendCopy,
    invoiceAttachment: data.invoiceAttachment,
  };
  return transformedData;
};

export const transformFormDataToRecordAmountData = (data: RecordAmountFormData) => {
  const transformedData = {
    amountPaid: stringToNumberSecondOptions(data.paymentAmount),
    date: dateFormatter.objectToString(data.paymentDate, 'yyyy-MM-dd'),
    notes: data.notes,
  };
  return transformedData;
};

export const InvoiceDetailsTab = {
  Main: 'main',
  History: 'history',
} as const;
export type InvoiceDetailsTab = typeof InvoiceDetailsTab[keyof typeof InvoiceDetailsTab];