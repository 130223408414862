import { DatePicker, TextInputNew, TheSelect } from '@components';
import { Option } from '@interfaces';
import { dateFormatter } from '@lib';
import { useContractorStore, useInvoiceStore } from '@store';
import { InvoiceNumberFormat, focusOnNextFormInput, formErrorMsg, standardValidators } from '@utils';
import React, { useEffect } from 'react';
import { FieldValues, UseFormReturn, useFormState } from 'react-hook-form';
import { InvoiceSection } from 'src/components/miscellaneous/invoiceSection/invoiceSection';
import './invoiceDataSection.scoped.scss';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  useFormFeatures: Partial<UseFormReturn<FieldValues>>;
  companyName: string;
  invoiceNameType: InvoiceNumberFormat;
  isPendingInvoiceNameValue: boolean;
  suffixesOptions: Option[];
  minDate: string;
  onRedirectToSettings: (v: 'suffix' | 'bank-account') => void;
  onSuffixChange: (suffix: string) => void;
  onInvoiceIssueDateHandle: (date: string) => void;
}

export const InvoiceDataSection = ({
  open,
  setOpen,
  useFormFeatures,
  companyName,
  invoiceNameType,
  isPendingInvoiceNameValue,
  suffixesOptions,
  onRedirectToSettings,
  onSuffixChange,
  onInvoiceIssueDateHandle,
  minDate,
}: Props) => {
  const { control, trigger, clearErrors, watch } = useFormFeatures;
  const {
    errors: { invoiceName: invoiceNameErr, suffix: suffixErr, invoiceDate: invoiceDateErr, deliveryDate: deliveryDateErr },
  } = useFormState({ control });
  const { isCorrected } = useInvoiceStore();
  const { ownerMyCompanyData } = useContractorStore();
  const invoiceSuffix = watch('suffix');

  useEffect(() => {
    const errors = [invoiceNameErr, suffixErr, invoiceDateErr, deliveryDateErr];
    const isInvalid = errors.some(e => !!e);
    if (isInvalid && !open) setOpen(true);
  }, [invoiceNameErr, suffixErr, invoiceDateErr, deliveryDateErr]);

  return (
    <InvoiceSection title="Dane faktury">
      <div className="invoice-section-content">
        <div className="invoice-data">
          <TextInputNew
            testcy="invoice-form-invoice-name"
            onKeyUp={event => {
              if (event.key === 'Enter') {
                if (isCorrected) {
                  focusOnNextFormInput(event, 4);
                  return;
                }
                focusOnNextFormInput(event, `suffix`);
              }
            }}
            appearance="primary"
            size="big"
            control={control}
            name="invoiceName"
            label="Numer faktury"
            width="316px"
            validation={{
              required: formErrorMsg.isRequired,
            }}
            isPendingValue={isPendingInvoiceNameValue}
          />
          {ownerMyCompanyData?.hasSuffixes || !!invoiceSuffix ? (
            <div className="suffix-selector-container">
              <TheSelect
                testcy="invoice-form-suffix"
                control={control}
                disabled={isCorrected}
                onChange={onSuffixChange}
                name="suffix"
                appearance="primary"
                size="big"
                emptyListText="Brak wyników"
                options={suffixesOptions}
                width="192px"
                placeholder="Przyrostek (opcj.)"
              />
            </div>
          ) : (
            <div />
          )}
          {isCorrected && (
            <TextInputNew
              testcy="invoice-form-correction-reason"
              control={control}
              appearance="primary"
              size="big"
              name="correctedInvoiceReason"
              label="Przyczyna korekty"
              width="316px"
              validation={standardValidators.requiredMaxNumber(100)}
              onKeyUp={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
          )}
        </div>
        <div className="invoice-dates">
          <DatePicker
            testcy="invoice-form-issue-date"
            control={control}
            trigger={trigger}
            clearErrors={clearErrors}
            name="invoiceDate"
            appearance="primary"
            size="big"
            label="Data wystawienia"
            onChange={onInvoiceIssueDateHandle}
            width="306px"
            validation={{
              required: formErrorMsg.isRequired,
              validate: value => {
                if (isCorrected) {
                  const formattedDate1 = dateFormatter.getDateObjectFromString(dateFormatter.standardFormat(minDate));
                  const getDiff = dateFormatter.diffBetweenDates(formattedDate1, value);
                  if (getDiff > 0) {
                    return formErrorMsg.correctionInvoiceDate;
                  }
                }
                return dateFormatter.isValidDateObject(value) || formErrorMsg.incorrectDate;
              },
            }}
            onKeyDown={event => {
              focusOnNextFormInput(event, 3);
            }}
          />
          <DatePicker
            testcy="invoice-form-delivery-date"
            control={control}
            name="deliveryDate"
            appearance="primary"
            size="big"
            trigger={trigger}
            clearErrors={clearErrors}
            label="Data dostawy"
            width="306px"
            validation={standardValidators.requiredDate}
            onKeyDown={event => {
              if (isCorrected) return focusOnNextFormInput(event, 7);
              focusOnNextFormInput(event, 3);
            }}
          />
        </div>
      </div>
    </InvoiceSection>
  );
};
