import { TableCell, TableCellProps } from '@mui/material';
import React from 'react';

import '../theTableNew.scoped.scss';

type Props = TableCellProps & {
  isActive: boolean;
};

export const TheTableCell = (props: Props) => {
  const { isActive, ...cellProps } = props;
  const className = `${isActive ? 'xxxxx-active' : 'xxxxx'}`;

  return <TableCell {...cellProps} className={className} />;
};
