import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | 'grey500' | '';
}

export const WasteBasketIcon = ({ colorType = '' }: Props) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`icon-color-${colorType}-stroke`}>
      <path d="M19.6943 6.62622H3.82593" stroke="#101013" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.3999 9.64502L17.8125 17.6866C17.6911 19.35 16.306 20.6389 14.6369 20.6389H8.88361C7.21553 20.6389 5.8295 19.35 5.70809 17.6856L5.12061 9.64502"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3824 6.62518L14.8996 4.2482C14.7594 3.72425 14.284 3.35999 13.7423 3.35999H9.78606C9.2415 3.35812 8.76424 3.72237 8.62322 4.2482L8.14502 6.62518"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
