import React from 'react';
import folderPic from '@assets/images/Folder.png';
import envelopePic from '@assets/images/Envelope.png';
import paymentPic from '@assets/images/Payment.png';
import { Button } from '@mui/material';
import { useCostDetails, useEditDigitalizedCost, useMarkAsPaidCost } from '@hooks';
import { InvoiceActionEnum, InvoiceStatusType, formatNumberToCurrency } from '@utils';
import { useMarkInvoiceAsSent } from '../../hooks';
import { EditIcon } from '@components';
import { InvoiceDetails } from '@services';

interface Props {
  invoiceId: string;
  type: 'invoice' | 'cost';
  isAccountant: boolean;
  setOpenRecordPaymentModal: (value: boolean) => void;
  setOpenEmailModal: (value: boolean) => void;
  setOpenReminderModal: (value: boolean) => void;
  refreshData: (action: InvoiceActionEnum) => void;
  markAsPaid: () => void;
  isPaid: boolean;
  isPendingConfirm: boolean;
  disableEdit: boolean;
  onConfirmInvoice: () => void;
  onEditInvoice: () => void;
  onCorrectionInvoice: () => void;
  isLoadingPage: boolean;
  invoiceData?: InvoiceDetails;
}

export const InvoiceDetailsMainTab = ({
  type,
  invoiceId,
  isAccountant,
  setOpenRecordPaymentModal,
  setOpenEmailModal,
  setOpenReminderModal,
  refreshData,
  markAsPaid,
  isPaid,
  isPendingConfirm,
  disableEdit,
  onConfirmInvoice,
  onEditInvoice,
  onCorrectionInvoice,
  isLoadingPage,
  invoiceData,
}: Props) => {
  const { editDigitalizedCost } = useEditDigitalizedCost();
  const { markCostAsPaid } = useMarkAsPaidCost(invoiceId, refreshData);
  const cost = useCostDetails(invoiceId, type);

  const { markInvoice } = useMarkInvoiceAsSent(invoiceId, invoiceData, refreshData);

  return (
    <>
      {type === 'cost' && (
        <>
          <div className="section">
            <div className="section-header">
              <div className="section-title-container">
                <img className={'icon-wrapper-png'} src={folderPic} alt="folder" />
                <div className="section-title center-g">Zdigitalizuj fakturę</div>
              </div>
              <div className="section-button-area">
                <Button
                  className="confirm-invoice"
                  onClick={() => {
                    editDigitalizedCost(invoiceId);
                  }}
                >
                  {`${cost.isDigitalized ? 'Edytuj' : 'Zdigitalizuj'}`}
                </Button>
              </div>
            </div>
          </div>
          <div className="section-divider"></div>
          {cost.isDigitalized && (
            <div className={`section ${isAccountant && 'disabled-section'}`}>
              <div className={`section-header paid-header ${isAccountant && 'disabled-section-opacity'} `}>
                <div className="section-title-container">
                  <img className={'icon-wrapper-png'} src={paymentPic} alt="payment" />
                  <div className="section-title center-g">Zarejestruj płatność</div>
                </div>
                <div className="section-button-area paid-button-area">
                  <Button className="invoice-link-blue" onClick={() => setOpenRecordPaymentModal(true)} disabled={!cost.supportsPayments || isAccountant}>
                    Zarejestruj wpłatę
                  </Button>

                  <Button className="invoice-link" onClick={() => markCostAsPaid({ id: invoiceId }, false)} disabled={!cost.supportsPayments || isAccountant}>
                    Oznacz jako opłaconą
                  </Button>
                </div>
              </div>
              <div className="custom-line"></div>
              <div className="summary-container">
                <div className="summary">
                  <p className="summary-title">Zapłacono: </p>
                  <span className="summary-value" data-testcy="drawer-invoice-details-paid-amount">
                    {formatNumberToCurrency(cost.totalAmountPaid)} zł
                  </span>
                </div>

                {cost.isPaid ? (
                  <div className="summary-title-paid" data-testcy="drawer-invoice-details-fully-paid">
                    Faktura została opłacona
                  </div>
                ) : (
                  <div className="summary">
                    <p className="summary-title">Pozostało do zapłaty:</p>
                    <span className="summary-value" data-testcy="drawer-invoice-details-left-to-pay">
                      {formatNumberToCurrency(cost.leftToPay)} zł
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {type === 'invoice' && (
        <>
          <div className={`section ${isAccountant && 'disabled-section'}`}>
            <div className={`section-header ${isAccountant && 'disabled-section-opacity'}`}>
              <div className="section-title-container">
                <img className={'icon-wrapper-png'} src={folderPic} alt="folder" />
                <div className="section-title center-g">Wystaw fakturę</div>
              </div>
              <div className="section-button-area">
                {invoiceData?.status === InvoiceStatusType.Draft && (
                  <Button className="confirm-invoice" onClick={onConfirmInvoice} disabled={invoiceData?.status !== 'Draft' || isPendingConfirm || isAccountant}>
                    Zatwierdź fakturę
                  </Button>
                )}
                {invoiceData?.status === InvoiceStatusType.Draft && (
                  <Button className="invoice-link-blue" onClick={onEditInvoice} disabled={isAccountant || disableEdit || !!invoiceData?.correctionId}>
                    <EditIcon colorType="primary" />
                    <p>Edytuj</p>{' '}
                  </Button>
                )}
                {invoiceData?.status !== InvoiceStatusType.Draft && (
                  <Button className="invoice-link-blue" onClick={onCorrectionInvoice} disabled={isAccountant || disableEdit || !!invoiceData?.correctionId}>
                    <EditIcon colorType="primary" />
                    <p>Skoryguj fakturę</p>{' '}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="section-divider"></div>
          <div className={`section ${(invoiceData?.status === InvoiceStatusType.Draft || isAccountant) && 'disabled-section '}`}>
            <div className={`section-header send-header ${(invoiceData?.status === InvoiceStatusType.Draft || isAccountant) && 'disabled-section-opacity'}`}>
              <div className={`section-title-container `}>
                <img className={'icon-wrapper-png'} src={envelopePic} alt="envelope" />
                <div className="section-title center-g">Wyślij fakturę</div>
              </div>
              <div className="section-button-area send-button-area">
                <Button
                  className="invoice-link-blue"
                  onClick={() => setOpenEmailModal(true)}
                  disabled={invoiceData?.status === InvoiceStatusType.Draft || isAccountant}
                >
                  Wyślij
                </Button>
                <Button
                  className="invoice-link"
                  onClick={() => markInvoice()}
                  disabled={invoiceData?.isSent || invoiceData?.status === InvoiceStatusType.Draft || isLoadingPage || isAccountant}
                >
                  Oznacz jako wysłane
                </Button>
                <Button
                  className="invoice-link"
                  onClick={() => setOpenReminderModal(true)}
                  disabled={isAccountant || !invoiceData?.isSent || invoiceData?.status === InvoiceStatusType.Paid}
                >
                  Wyślij upomnienie
                </Button>
              </div>
            </div>
          </div>
          <div className="section-divider"></div>
          <div className={`section ${(invoiceData?.status === InvoiceStatusType.Draft || isAccountant) && 'disabled-section'}`}>
            <div className={`section-header paid-header ${(invoiceData?.status === InvoiceStatusType.Draft || isAccountant) && 'disabled-section-opacity'} `}>
              <div className="section-title-container">
                <img className={'icon-wrapper-png'} src={paymentPic} alt="payment" />
                <div className="section-title center-g">Zarejestruj płatność</div>
              </div>
              <div className="section-button-area paid-button-area">
                <Button
                  className="invoice-link-blue"
                  onClick={() => setOpenRecordPaymentModal(true)}
                  disabled={isPaid || invoiceData?.status === 'Draft' || isAccountant}
                >
                  Zarejestruj wpłatę
                </Button>

                <Button className="invoice-link" onClick={() => markAsPaid()} disabled={isPaid || invoiceData?.status === 'Draft' || isAccountant}>
                  Oznacz jako opłaconą
                </Button>
              </div>
            </div>
            <div className="custom-line"></div>
            <div className="summary-container">
              <div className="summary">
                <p className="summary-title">Zapłacono: </p>
                <span className="summary-value" data-testcy="drawer-invoice-details-paid-amount">
                  {formatNumberToCurrency(invoiceData?.amountPaid + invoiceData?.totalInstalments)} zł
                </span>
              </div>

              {isPaid ? (
                <div className="summary-title-paid" data-testcy="drawer-invoice-details-fully-paid">
                  Faktura została opłacona
                </div>
              ) : (
                <div className="summary">
                  <p className="summary-title">Pozostało do zapłaty:</p>
                  <span className="summary-value" data-testcy="drawer-invoice-details-left-to-pay">
                    {formatNumberToCurrency(Math.max(invoiceData?.grossValue - (invoiceData?.amountPaid + invoiceData?.totalInstalments)))} zł
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
