import React from 'react';
import { Navigate } from 'react-router';
import { TheRouteObject } from '../types';
import { SharedInbox } from '@features';

export const inbox: TheRouteObject[] = [
  {
    index: true,
    element: <SharedInbox />,
  },
  {
    path: '*',
    element: <Navigate to="/files" />,
  },
];
