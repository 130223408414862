import { ContractorData, ContractorsListData, CostCalculatedData, CostData, GetCostSumValueParams, UpdateCostDataRequest, VatExemptionInfo } from '@services';
import { ContractorInvoicingDataBase, CustomObject, Option } from '@interfaces';
import { NumberInput, TableHeaderInterface, TableRowInterface, TheSelect, getFormattedAccountNo } from '@components';
import {
  focusOnNextFormInput,
  CostsUnitEnum,
  formatNumberToCurrency,
  formErrorMsg,
  getPropertySuffixNumbers,
  PaymentType,
  stringToNumberSecondOptions,
  vatOptions,
  VatOptionsType,
  VatTemplateTypeEnum,
  setDisabledField,
  VehicleOperationEnum,
  DeductVATEnum,
  DeductionInCostsEnum,
  focusOnNextFormInput_FORCE,
} from '@utils';
import React from 'react';
import { Control, FieldValues, UseFormClearErrors, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { dateFormatter } from '@lib';

export interface InvoiceFormData extends CustomObject {
  client: string;
  deliveryDate: Date;
  dueDate: Date;
  invoiceDate: Date;
  invoiceName: string;
  paymentyType: PaymentType;
  suffix: string;
  bankNumber: string;
  invoiceItems: TableRowInterface[];
  paidAmount: string;
  selectOptionId?: string;
  vatExemptionInfo?: VatExemptionInfo;
  vatTemplateId: VatTemplateTypeEnum;

  deductionType: VehicleOperationEnum;
  deductionPart?: DeductVATEnum;
  deductionPercent?: DeductionInCostsEnum;
}

export const contractorDataToInvoicingContractorData = (data: ContractorData): ContractorInvoicingDataBase => {
  return {
    nip: data.nip,
    name: data.name,
    address: data.address,
  };
};

export const generateContractorsOptions = (contractors: ContractorsListData[]): Option[] => {
  if (!contractors) return [];

  const options = contractors.map((contractor: ContractorsListData) => {
    return {
      label: contractor.name,
      value: contractor?.id?.toString(),
    };
  });
  return options;
};

export const getFieldNameByType = (unitType: CostsUnitEnum | null, rowId?: string): string => {
  switch (unitType) {
    case 'GrossValue':
      return `grossValue-${rowId}`;
    case 'NetValue':
      return `netValue-${rowId}`;
    case 'VatRate':
      return `vatRate-${rowId}`;
  }
};

export const generateRow = (
  id: string,
  control: Control,
  onValueChange: (valueType: CostsUnitEnum, id: string, force?: boolean) => void,
  watch: UseFormWatch<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  clearErrors: UseFormClearErrors<FieldValues>,
  getValues: UseFormGetValues<FieldValues>,
  trigger: (name: string) => Promise<boolean>,
  setFieldRecalculationNeeded: (val: boolean) => void,
  checkIfRecalculationNeeded: (valueType: CostsUnitEnum, id: string) => boolean,
  hasCustomAmounts: boolean,
  index: number
): TableRowInterface => {
  const vatOptionsError = (value: VatOptionsType) => {
    const arrayOfFormValues = Object.entries(watch());
    const filteredVatValue = arrayOfFormValues
      .filter(([key, value]) => key.slice(0, 8) === 'vatRate-')
      .map(([key, value]) => {
        return { name: `${key}`, value: `${value}` };
      });
    const lookup = filteredVatValue.reduce((a, e) => {
      a[e.value] = ++a[e.value] || 0;
      return a;
    }, {});
    const errorDuplicates = filteredVatValue.filter(e => lookup[e.value]);

    if (errorDuplicates.map(e => e.value).includes(value)) return formErrorMsg.costVatRateDuplicated;
    if (!errorDuplicates.length) {
      filteredVatValue.forEach(VatRate => {
        clearErrors(VatRate.name);
      });
    }
  };

  const updateValueOnChange = (nextValueFromEvent: string, unitType: CostsUnitEnum, rowId: string) => {
    // if blur value changed, we can perform update and recalculate
    // NOTE checkIfRecalculationNeeded checks only current form state but not incoming value
    const isBlurValueChanged = (nextValue: string): boolean => {
      const fieldNameNextValue = `onBlur-${unitType}-${rowId}`;
      const currValue = getValues(fieldNameNextValue);
      return currValue !== nextValue;
    };

    if (isBlurValueChanged(nextValueFromEvent)) {
      setFieldRecalculationNeeded(true);
      const fieldNameNextValue = `onBlur-${unitType}-${rowId}`;
      const fieldNamePreviousValue = `onFocus-${unitType}-${rowId}`;
      const currValue = getValues(fieldNameNextValue);
      setValue(fieldNameNextValue, nextValueFromEvent);
      if (currValue) {
        setValue(fieldNamePreviousValue, currValue);
      }
    }
  };

  const onEnterUpRecalculate = async (event: React.KeyboardEvent<HTMLDivElement>, unitType: CostsUnitEnum, rowId: string) => {
    const nextValueFromEvent = (event.target as any).value;
    updateValueOnChange(nextValueFromEvent, unitType, rowId);

    if (event.key === 'Enter') {
      onValueChange(unitType, rowId, true);
      await trigger(getFieldNameByType(unitType, rowId));
    } else {
      setFieldRecalculationNeeded(checkIfRecalculationNeeded(unitType, id));
    }
  };

  setDisabledField(setValue, getValues, `vatValue-${id}`, !hasCustomAmounts);

  return {
    id: id,
    no: {
      content: '',
      bold: true,
    },
    vatRate: {
      content: '',
      component: (
        <TheSelect
          testcy={`costs-form-vat-rate-${index}`}
          control={control}
          name={`vatRate-${id}`}
          width="100%"
          options={vatOptions}
          onFocus={e => {
            setValue(`onFocus-VatRate-${id}`, e.target.textContent);
          }}
          onBlur={e => {
            onValueChange(CostsUnitEnum.VAT_RATE, id);
            setValue(`onBlur-VatRate-${id}`, e.target.textContent);
          }}
          onChange={value => {
            updateValueOnChange(value, CostsUnitEnum.VAT_RATE, id);
            onValueChange(CostsUnitEnum.VAT_RATE, id);
            if (value === 'ZW' || value === 'NP' || value === '_0') {
              setValue(`vatValue-${id}`, '0,00');
              clearErrors(`vatValue-${id}`);
            }
            if ((value === '_23' || value === '_8' || value === '_5') && watch(`vatValue-${id}`) !== '0,00') {
              clearErrors(`vatValue-${id}`);
            }
            if (value) {
              setTimeout(() => {
                focusOnNextFormInput_FORCE(`netValue-${id}`);
              }, 0);
            }
          }}
          hideEmptyOption
          validation={{
            validate: value => {
              const errorMessage = vatOptionsError(value);
              return errorMessage;
            },

            required: formErrorMsg.isRequired,
          }}
        />
      ),
      stashedData: {
        name: `vatRate-${id}`,
      },
    },
    netValue: {
      content: '',
      component: (
        <NumberInput
          testcy={`costs-form-net-value-${index}`}
          control={control}
          name={`netValue-${id}`}
          width="100%"
          maxDecimals={4}
          float
          allowMultiplication
          negative
          placeholder="0,00"
          onFocus={e => {
            setValue(`onFocus-NetValue-${id}`, e.target.value);
          }}
          onBlur={value => {
            setValue(`onBlur-NetValue-${id}`, value);
            onValueChange(CostsUnitEnum.NET_VALUE, id);
            if (stringToNumberSecondOptions(value) >= 0 && !getValues(`recalculationDisabled-${id}`)) {
              clearErrors(`grossValue-${id}`);
            }
          }}
          onKeyDown={async event => {
            await onEnterUpRecalculate(event, CostsUnitEnum.NET_VALUE, id);
            const isSuccessful = focusOnNextFormInput(event, 2);
            if (!isSuccessful) {
              focusOnNextFormInput(event, 4);
            }
          }}
          validation={{
            required: formErrorMsg.isRequired,
          }}
          watch={watch}
        />
      ),
      stashedData: {
        name: `netValue-${id}`,
      },
    },
    vatValue: {
      content: '',
      component: (
        <NumberInput
          testcy={`costs-form-vat-value-${index}`}
          control={control}
          name={`vatValue-${id}`}
          width="100%"
          maxDecimals={4}
          float
          allowMultiplication
          negative
          placeholder="0,00"
          onFocus={e => {
            setValue(`onFocus-VatValue-${id}`, e.target.value);
          }}
          onBlur={value => {
            setValue(`onBlur-VatValue-${id}`, value);
            onValueChange(CostsUnitEnum.NET_VALUE, id);
          }}
          onKeyDown={async event => {
            await onEnterUpRecalculate(event, CostsUnitEnum.NET_VALUE, id);
            focusOnNextFormInput(event, 2);
          }}
          watch={watch}
          disabled={!hasCustomAmounts}
        />
      ),
      stashedData: {
        name: `vatValue-${id}`,
      },
    },
    grossValue: {
      content: '',
      component: (
        <NumberInput
          testcy={`costs-form-gross-value-${index}`}
          control={control}
          name={`grossValue-${id}`}
          width="100%"
          allowMultiplication
          maxDecimals={4}
          float
          negative
          placeholder="0,00"
          onFocus={e => {
            setValue(`onFocus-GrossValue-${id}`, e.target.value);
          }}
          onBlur={value => {
            setValue(`onBlur-GrossValue-${id}`, value);
            onValueChange(CostsUnitEnum.GROSS_VALUE, id);
            if (stringToNumberSecondOptions(value) >= 0 && !getValues(`recalculationDisabled-${id}`)) {
              clearErrors(`netValue-${id}`);
            }
          }}
          onKeyDown={async event => {
            await onEnterUpRecalculate(event, CostsUnitEnum.GROSS_VALUE, id);

            // if an element has been removed then we need to find the next one
            const elements = Array.from(document.querySelectorAll('[id^="vatRate-"]'));
            const allIds = elements.map(el => Number(el.id.substring(`vatRate-`.length)));
            const index = allIds.findIndex(i => i > Number(id));

            if (index !== -1) {
              const targetId = elements[index].id;
              return focusOnNextFormInput(event, targetId);
            }
            focusOnNextFormInput(event, 'costs-form-add-vat-rate');
          }}
          validation={{
            required: formErrorMsg.isRequired,
          }}
          watch={watch}
        />
      ),
      stashedData: {
        name: `grossValue-${id}`,
      },
    },
    action: {
      remove: true,
      syncToggle: true,
      syncToggleState: !hasCustomAmounts,
    },
  };
};

export const editExistingRow = (rows: TableRowInterface[], id: string, editFn: (row: TableRowInterface) => any): TableRowInterface[] => {
  const row = rows.find(v => v.id === id);
  editFn(row);
  return rows;
};

export const getValuesToCalculateSummary = (data: CustomObject, fieldChange: CostsUnitEnum | null, rowId?: string): GetCostSumValueParams[] => {
  const rowNumber: string[] = getPropertySuffixNumbers(data, 'vatRate-');

  const values = [];

  const getValues = (num: string) => {
    let netValue = data[`netValue-${num}`];
    netValue = netValue !== '' ? netValue : '0';
    const grossValue = data[`grossValue-${num}`];
    const vatRate = data[`vatRate-${num}`];
    const vatValue = data[`vatValue-${num}`];
    const shouldRecalculateRow = !data[`recalculationDisabled-${num}`];

    if (netValue && vatRate) {
      const res = {
        netValue: stringToNumberSecondOptions(netValue),
        grossValue: stringToNumberSecondOptions(grossValue),
        vatValue: stringToNumberSecondOptions(vatValue),
        vatRate,
        fieldChange: shouldRecalculateRow && rowId === num ? fieldChange : null,
        rowId: num,
      };

      return res;
    }

    return null;
  };

  for (const nr of rowNumber) {
    const rowData = getValues(nr);
    if (rowData) {
      values.push(rowData);
    }
  }

  return values;
};

export const tableHeaderGenerate = (): TableHeaderInterface[] => {
  return [
    {
      label: 'L.p.',
      data: 'no',
      keyPrefix: 'no-value',
      autoCount: true,
      width: 40,
      minWidth: 40,
      customCellClass: 'cost-tab-vat-no',
    },
    {
      label: 'Nazwa stawki VAT',
      data: 'vatRate',
      width: 120,
      minWidth: 120,
    },
    {
      label: 'Wartość netto',
      data: 'netValue',
      width: 140,
      minWidth: 140,
    },
    {
      label: 'Kwota VAT',
      data: 'vatValue',
      width: 140,
      minWidth: 140,
    },
    {
      label: 'Wartość brutto',
      data: 'grossValue',
      width: 140,
      minWidth: 140,
    },
    {
      label: '',
      data: 'action',
      keyPrefix: 'action',
      width: 50,
      minWidth: 50,
      action: true,
    },
  ];
};

export const setCalculatedValuesToInvoice = (data: CostCalculatedData, setValue: UseFormSetValue<FieldValues>, paymentValuesToSend) => {
  let index = null;
  let inputChange = '';
  paymentValuesToSend.forEach(value => {
    if (value.fieldChange) {
      index = value.rowId;
      inputChange = value.fieldChange;
    }
  });
  data.items.forEach((item, index: number) => {
    const invoiceItem = data.items[index];
    if (!invoiceItem) return;
    setValue(`netValue-${item.rowId}`, formatNumberToCurrency(item.netValue));
    setValue(`grossValue-${item.rowId}`, formatNumberToCurrency(item.grossValue));
    setValue(`vatValue-${item.rowId}`, formatNumberToCurrency(item.vatValue));
  });
  if (inputChange === 'NetValue') {
    setTimeout(() => {
      focusOnNextFormInput_FORCE(`grossValue-${index}`);
    }, 0);
  } else if (inputChange === 'VatRate') {
    setTimeout(() => {
      focusOnNextFormInput_FORCE(`netValue-${index}`);
    }, 0);
  }
};

const getAccountingSchemeId = (scheme: string | undefined, accountingSchemes: Option[]): number | null => {
  if (!scheme) return null;
  const schemeValue = accountingSchemes.find(opt => opt.label === scheme)?.value;
  if (!schemeValue) return null;
  const idIndex = schemeValue.indexOf(':') + 1;
  return Number(schemeValue.slice(idIndex)) || null;
};

export const createSubmitData = (
  data: InvoiceFormData,
  hideBankAccount: boolean,
  hasZW: boolean,
  companyId: string | null,
  accountingSchemes: Option[]
): UpdateCostDataRequest => {
  const proportionLimit = data?.proportionLimit?.replace('%', '') || null;
  const accountingTemplateId = getAccountingSchemeId(data?.vatAccountingScheme, accountingSchemes);

  const isPaid = data.paymentType === 'Paid';

  const paymentInfo =
    data.paymentType && !isPaid
      ? {
          bankAccountNumber: hideBankAccount ? null : data.bankAccount.replace(/\s/g, ''),
          dueDate: dateFormatter.objectToString(data.dueDate, 'yyyy-MM-dd'),
          paymentMethod: data.paymentType || '',
          amountPaid: stringToNumberSecondOptions(data.paidAmount),
        }
      : {
          bankAccountNumber: null,
          dueDate: null,
          paymentMethod: data.paymentType ?? null,
          amountPaid: isPaid ? null : stringToNumberSecondOptions(data.paidAmount),
        };

  const createInvoiceData: UpdateCostDataRequest = {
    number: data.invoiceNumber,
    vatRateRecords: [],
    issueDate: data.invoiceDate ? dateFormatter.objectToString(data.invoiceDate, 'yyyy-MM-dd') : '',
    deliveryDate: data.deliveryDate ? dateFormatter.objectToString(data.deliveryDate, 'yyyy-MM-dd') : '',
    deductionMonth: data.deductionMonth ? dateFormatter.objectToString(data.deductionMonth, 'yyyy-MM-dd') : '',
    contractorId: data.client || '',
    companyId: +companyId || null,
    vatTemplateId: Number(data.vatTemplate),
    accountingTemplateId: accountingTemplateId,
    vehicleDeductionInfo: {
      type: data?.deductionType || null,
      part: data?.deductionPart || null,
      percent: data?.deductionPercent || null,
      leasingDeductionInfo:
        proportionLimit != null
          ? {
              isElectricVehicle: data.electricVehicle,
              deductibleLimit: stringToNumberSecondOptions(data?.deductibleLimit) || null,
              vehicleAcquisitionValue: stringToNumberSecondOptions(data?.vehicleAcquisitionValue) || null,
              proportionLimit: stringToNumberSecondOptions(proportionLimit) || null,
            }
          : null,
    },
    ...paymentInfo,
  };

  const rowNumber = getPropertySuffixNumbers(data, 'netValue-');
  rowNumber.forEach(num => {
    const vatRate = data[`vatRate-${num}`];
    const netValue = data[`netValue-${num}`];
    const vatValue = data[`vatValue-${num}`];
    const grossValue = data[`grossValue-${num}`];
    const hasCustomAmounts = data[`recalculationDisabled-${num}`] || false;

    createInvoiceData.vatRateRecords.push({
      vatRate,
      netValue: stringToNumberSecondOptions(netValue),
      vatValue: stringToNumberSecondOptions(vatValue),
      grossValue: stringToNumberSecondOptions(grossValue),
      hasCustomAmounts,
    });
  });

  if (hasZW) {
    createInvoiceData.vatExemptionInfo = {};
    createInvoiceData.vatExemptionInfo.exemptionType = 'Art113';
    createInvoiceData.vatExemptionInfo.description = null;
  }
  return createInvoiceData;
};

export const setFormData = (
  data: CostData,
  setValue: UseFormSetValue<FieldValues>,
  getValues: UseFormGetValues<FieldValues>,
  addMultipleRows: (ids: [number, boolean][]) => void,
  contractorsList: ContractorsListData[]
): void => {
  const contractor = contractorsList?.find(contractor => {
    return contractor.id === data.contractorId;
  });

  setValue('client', data.contractorId);
  contractorsList?.length && setValue('contractorNameInvoiceEdit', contractor.name);

  const deliveryDate = data.deliveryDate ? dateFormatter.getDateObjectFromString(data.deliveryDate, "yyyy-MM-dd'T'HH:mm:ss") : '';
  const dueDate = data.dueDate ? dateFormatter.getDateObjectFromString(data.dueDate, "yyyy-MM-dd'T'HH:mm:ss") : '';
  const issueDate = data.issueDate ? dateFormatter.getDateObjectFromString(data.issueDate, "yyyy-MM-dd'T'HH:mm:ss") : '';
  const deductionMonth = data.deductionMonth ? dateFormatter.getDateObjectFromString(data.deductionMonth, "yyyy-MM-dd'T'HH:mm:ss") : '';
  const proportionLimit = data?.vehicleDeductionInfo?.leasingDeductionInfo?.proportionLimit
    ? `${formatNumberToCurrency(data?.vehicleDeductionInfo?.leasingDeductionInfo?.proportionLimit)}%`
    : ``;
  const deductibleLimit = data?.vehicleDeductionInfo?.leasingDeductionInfo?.deductibleLimit
    ? `${formatNumberToCurrency(data.vehicleDeductionInfo?.leasingDeductionInfo.deductibleLimit as number)}`
    : data?.vehicleDeductionInfo?.leasingDeductionInfo?.deductibleLimit;
  const vehicleAcquisitionValue = data?.vehicleDeductionInfo?.leasingDeductionInfo?.vehicleAcquisitionValue
    ? `${formatNumberToCurrency(data.vehicleDeductionInfo?.leasingDeductionInfo.vehicleAcquisitionValue)}`
    : data?.vehicleDeductionInfo?.leasingDeductionInfo?.vehicleAcquisitionValue;

  setValue('invoiceNumber', data.number);

  if (data.bankAccountNumber) setValue('bankAccount', getFormattedAccountNo(data.bankAccountNumber));
  setValue('paymentType', data.paymentMethod || '');
  setValue('deliveryDate', deliveryDate);
  setValue('dueDate', dueDate);
  setValue('invoiceDate', issueDate);
  setValue('deductionMonth', deductionMonth);
  setValue('paidAmount', formatNumberToCurrency(data.amountPaid));
  setValue('deductionType', data?.vehicleDeductionInfo?.type);
  setValue('deductionPart', data?.vehicleDeductionInfo?.part);
  setValue('deductionPercent', data?.vehicleDeductionInfo?.percent);
  setValue('electricVehicle', data?.vehicleDeductionInfo?.leasingDeductionInfo?.isElectricVehicle);
  setValue('deductibleLimit', deductibleLimit);
  setValue('vehicleAcquisitionValue', vehicleAcquisitionValue);
  setValue('proportionLimit', proportionLimit);

  const rowsIds: [number, boolean][] = [];

  data.vatRateRecords.forEach((data, index) => {
    const { vatRate, netValue, grossValue, vatValue, hasCustomAmounts } = data;

    const num = index + 1;
    rowsIds.push([num, hasCustomAmounts]);
    setValue(`vatRate-${num}`, vatRate);
    setValue(`vatValue-${num}`, formatNumberToCurrency(vatValue));
    setValue(`netValue-${num}`, formatNumberToCurrency(netValue));
    setValue(`grossValue-${num}`, formatNumberToCurrency(grossValue));
    setValue(`recalculationDisabled-${num}`, hasCustomAmounts);
    setDisabledField(setValue, getValues, `vatValue-${num}`, !hasCustomAmounts);
  });
  addMultipleRows(rowsIds);
  if (data?.vatExemptionInfo) {
    setValue(`zwOptions`, data.vatExemptionInfo.exemptionType);
    setValue(`zwVatOther`, data.vatExemptionInfo.description);
  }
  setValue(`vatTemplate`, String(data?.vatTemplate?.id));
  if (data?.accountingTemplate?.code) {
    setValue(`vatAccountingScheme`, `${data.accountingTemplate.code} ${data.accountingTemplate.description}`);
  }
};

export const vatOptionsLeft = (vatOptions, arrayOfFormValues) => {
  const filteredVatValue = arrayOfFormValues.filter(([key, value]) => key.slice(0, 9) === 'vatValue-').map(([key, value]) => value);
  const vatOptionsToSelect = vatOptions.filter(e => !filteredVatValue.includes(e.value));

  return vatOptionsToSelect;
};
