import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Layout from '@layout';
import NotFound from './components/NotFound';
import { chat, contractor, costList, dashboard, employeeList, inbox, invoicesList, ownerCompanyList, taxes } from './childRoutes';
import { TheRouteObject } from './types';

const appRoutes: TheRouteObject[] = [
  {
    path: '/dashboard',
    children: dashboard,
    //breadcrumb dla dashboardu zdefiniowany na sztywno w theBreadcrumbs, bo ma się wyświetlać zawsze, niezależnie od ścieżki
  },
  {
    path: '/contractors-list',
    children: contractor,
    handle: {
      crumb: 'Kontrahenci',
    },
  },
  {
    path: '/invoices-list',
    children: invoicesList,
    handle: {
      crumb: 'Przychody',
    },
  },
  {
    path: '/costs-list',
    children: costList,
    handle: {
      crumb: 'Koszty',
    },
  },
  {
    path: '/documents',
    children: inbox,
    handle: {
      crumb: 'Dokumenty',
    },
  },
  {
    path: '/employees-list',
    children: employeeList,
    handle: {
      crumb: 'Pracownicy',
    },
  },
  {
    path: '/taxes',
    children: taxes,
    handle: {
      crumb: 'Podatki',
    },
  },
  {
    path: '/owner-company-list',
    children: ownerCompanyList,
    handle: {
      crumb: 'Lista firm',
    },
  },
  {
    path: '/chat',
    children: chat,
    handle: {
      crumb: 'Czat',
    },
  },
];

export const routes: TheRouteObject[] = [
  {
    element: <Layout />,
    children: [
      ...appRoutes,
      {
        path: '/',
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
