import React from 'react';
import { CheckboxStateDataType } from '../../../features/shared/sharedInvoicesList/components/incomesTab/incomesTab.utils';
import { ThePrice, TheTableCell, TheTableRow } from '@components';

import './selectedInvoiceCustomRow.scoped.scss';

type Props = {
  checkboxState: CheckboxStateDataType;
  onSelectAll: () => void;
  onUnselectAll: () => void;
  optionsColumnSpan: number;
  testcy?: string;
};

export const SelectedInvoiceCustomRow = ({ checkboxState = {}, onSelectAll, onUnselectAll, optionsColumnSpan, testcy }: Props) => {
  const { checkboxTimeout, numberOfInvoicesChecked, totalInvoicesCount, checkedNetValue, checkedGrossValue } = checkboxState;

  if (!checkboxTimeout) {
    return <TheTableRow isActive={numberOfInvoicesChecked > 0} />;
  }

  return (
    <TheTableRow isActive={numberOfInvoicesChecked > 0}>
      <TheTableCell isActive={checkboxTimeout} colSpan={2} data-testcy={`${testcy}-selected-checkboxes`}>
        <div className="selected-info-wrapper">{`Zaznaczonych faktur: ${numberOfInvoicesChecked}`}</div>
      </TheTableCell>
      <TheTableCell isActive={checkboxTimeout} colSpan={optionsColumnSpan} data-testcy={`${testcy}-select-unselect-wrapper`}>
        <div className="costs-select-all-container">
          <div className="costs-select-all-wrapper">
            <button data-testcy={`${testcy}-unselect-all`} className="select-all-wrapper" onClick={onUnselectAll}>
              {`Odznacz wszystkie (${numberOfInvoicesChecked})`}
            </button>
            <button data-testcy={`${testcy}-select-all`} className="unselect-all-wrapper" onClick={onSelectAll}>
              {`Zaznacz wszystkie (${totalInvoicesCount})`}
            </button>
          </div>
        </div>
      </TheTableCell>
      <TheTableCell align="left" isActive={checkboxTimeout} colSpan={3} data-testcy={`${testcy}-price`}>
        <div className="price-container">
          <ThePrice netValue={checkedNetValue} grossValue={checkedGrossValue} bold showCurrency showLabels />
        </div>
      </TheTableCell>
    </TheTableRow>
  );
};
