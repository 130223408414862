import { httpClient } from '@lib';
import { AxiosResponse } from 'axios';
import { SortDirection } from '@utils';
import { Address } from '@interfaces';
import { CountryPrefix } from 'src/features/owner/ownerContractorForm/components/contractorForm/contractorForm.utils';

export interface ContractorData {
  name: string;
  friendlyName?: string;
  country?: CountryPrefix;
  nip?: string;
  regon: string;
  address: Address;
  isBusiness: boolean;
  companyId?: number;
  emails: string[];
  dummy: boolean;
  accountingSettings?: ContractorAccountingSettings;
}

export type ContractorAccountingSettings = {
  vatTemplateId?: number;
  accountingTemplateId?: number;
};

export type ContractorsOrderOptions = 'name';

export interface GetContractorsParams {
  companyId?: number;
  textFilter?: string;
  direction?: SortDirection;
  orderBy?: ContractorsOrderOptions;
  pageNumber?: number;
  pageSize?: number;
}

export interface ContractorsListData {
  id: string;
  name: string;
  fullName: string;
}

export interface ContractorsList {
  data: ContractorsListData[];
  pageNumber: number;
  totalPageCount: number;
  pageSize: number;
  totalCount: number;
}

export const createNewContractors = async (contractorData: ContractorData) => {
  try {
    const response: AxiosResponse = await httpClient.post('/Contractors', contractorData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getContractors = async (params: GetContractorsParams = {}) => {
  try {
    const response: AxiosResponse<ContractorsList> = await httpClient.get('/Contractors', { params });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getContractorById = async (id: string, date?: string) => {
  try {
    const response: AxiosResponse<ContractorData> = await httpClient.get(`/Contractors/${id}`, { params: { date } });
    return response.data;
  } catch (e) {
    throw e?.response;
  }
};

export const updateContractor = async (contractorData: ContractorData, id: string) => {
  try {
    const response: AxiosResponse<ContractorData> = await httpClient.put(`/Contractors/${id}`, contractorData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const deleteContractorById = async (id: string) => {
  try {
    await httpClient.delete(`/Contractors/${id}`);
  } catch (e) {
    throw e?.response?.data;
  }
};
