import { LoaderIcon } from '@components';
import { Option } from '@interfaces';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, AutocompleteRenderOptionState, FormControl, IconButton, InputAdornment, InputLabel, TextField, Tooltip } from '@mui/material';
import { HelperTextAlignment } from '@utils';
import React, { useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Control, Controller, FieldValues, RegisterOptions, UseFormTrigger } from 'react-hook-form';
import './autocompleteInput.scss';
import { FormElementAppearance, FormElementSize } from '../types';
import arrow from '@assets/arrow.svg';

interface Props {
  name: string;
  testcy: string;
  control: Control;
  trigger: UseFormTrigger<FieldValues>;
  options?: Option[];
  validation?: RegisterOptions;
  appearance?: FormElementAppearance;
  size?: FormElementSize;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: string;
  width?: string;
  placeholder?: string;
  helperText?: string;
  helperTextAlign?: HelperTextAlignment;
  startAdornment?: React.ReactNode;
  endAdornment?: boolean;
  isPendingFilter?: boolean;
  tooltipMessage?: string;
  PaperComponent?: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>>;
  onFilterChanged?: (value: string) => void;
  onPaste?: (value: string) => string;
  onChange?: (value: { label: string; value: string }) => void;
  onBlur?: (value: string) => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const AutocompleteInput = ({
  name,
  testcy,
  control,
  trigger,
  options = [],
  validation = {},
  label = '',
  appearance = FormElementAppearance.Old,
  size = FormElementSize.Medium,
  disabled = false,
  readOnly = false,
  defaultValue = null,
  width = '250px',
  placeholder = '',
  helperText,
  helperTextAlign = HelperTextAlignment.Left,
  startAdornment,
  endAdornment,
  isPendingFilter = false,
  tooltipMessage,
  PaperComponent,
  onFilterChanged,
  onPaste,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onPasteHandler = (event: React.ClipboardEvent) => {
    const clipString: string = event.clipboardData.getData('text');
    event.preventDefault();

    const pastedValue = onPaste ? onPaste(clipString) : clipString;
    setInputValue(pastedValue);
    onFilterChanged(pastedValue);
  };

  const getRootInputClasses = (error: any): string => {
    //prettier-ignore
    return [
      'autocomplete-input styled-input',
      `helper-text-${error?.message ? 'left' : helperTextAlign}`,
      error?.message ? 'has-error' : '',
      disabled ? 'input-disabled' : '',
      readOnly ? 'input-readonly' : ''
    ].join(' ');
  };

  return (
    <div className={`autocomplete-input-container appearance-${appearance} size-${size}`} style={{ width: width, minWidth: width }}>
      <FormControl variant="outlined">
        {label && (
          <InputLabel shrink className="input-label" data-testcy={`${testcy}-label`} disabled={disabled}>
            {label}
            {tooltipMessage && (
              <Tooltip arrow title={tooltipMessage} className="label-tooltip">
                <HelpOutlineIcon fontSize="inherit" color="inherit" />
              </Tooltip>
            )}
          </InputLabel>
        )}
        <Controller
          control={control}
          name={name}
          rules={validation}
          defaultValue={defaultValue}
          render={({ field: { onChange: onChangeProvided, value, onBlur: onBlurProvided }, fieldState: { error } }) => (
            <div className={`autocomplete-wrapper`}>
              <Autocomplete
                id={name}
                open={open}
                onClose={() => {
                  setOpen(false);
                  trigger(name);
                }}
                onOpen={() => {
                  setOpen(true);
                }}
                onFocus={onFocus}
                onChange={(_, values) => {
                  const v = values?.label || null;
                  onChangeProvided(v);
                  onChange?.(values);
                  setIsSelected(true);
                }}
                inputValue={inputValue}
                onInputChange={(_, value) => {
                  setInputValue(value);
                  onFilterChanged(value);
                }}
                onBlur={e => {
                  onBlurProvided();
                  if (onBlur) {
                    const value = (e.target as HTMLInputElement).value;
                    onBlur(value);
                  }
                }}
                options={options}
                disabled={disabled}
                getOptionLabel={option => option.label || value}
                isOptionEqualToValue={(option, value) => value === option.label || !value || value.label === option.label}
                filterOptions={options => options}
                clearIcon={false}
                popupIcon={<KeyboardArrowDownIcon fontSize="small" />}
                noOptionsText="Brak wyników"
                closeText=""
                openText=""
                classes={{ option: `${isSelected ? '' : 'on-focus-on-past'}` }}
                PaperComponent={PaperComponent}
                renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: any, state: AutocompleteRenderOptionState) => {
                  return (
                    <li {...props} key={option.value} data-testcy={`${testcy}-menu-item-${option.value} ${testcy}-menu-item-${option.name?.toLowerCase()}`}>
                      {option.label}
                    </li>
                  );
                }}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      placeholder={placeholder}
                      inputProps={{
                        ...params.inputProps,
                        'data-testcy': testcy,
                      }}
                      classes={{ root: getRootInputClasses(error) }}
                      helperText={error?.message ?? helperText}
                      onPaste={onPasteHandler}
                      onKeyUp={e => onKeyDown?.(e)}
                      InputProps={
                        {
                          ...params.InputProps,
                          startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null,
                          endAdornment: endAdornment ? (
                            <InputAdornment position="end" className="input-adorment">
                              {isPendingFilter && <LoaderIcon size="tiny" />}
                              <IconButton size="small" onClick={() => setOpen(true)}>
                                <img src={arrow} alt="arrow" />
                              </IconButton>
                            </InputAdornment>
                          ) : (
                            <InputAdornment position="end" className="input-adorment">
                              <img src={arrow} alt="arrow" />
                            </InputAdornment>
                          ),
                          style: {
                            paddingRight: '39px',
                          },
                        } as any
                      }
                    />
                  );
                }}
                value={value}
              />
            </div>
          )}
        />
      </FormControl>
    </div>
  );
};
