import { CustomObject, Option } from "@interfaces";
import { useMemo, useState } from "react";

export const useFilter = (options: Option[]) => {
  const [filterValue, setFilterValue] = useState<string>('');

  return useMemo(() => {
    const filteredOptions = filterOptions(filterValue, options, opt => opt.label);
    return {
      filteredOptions,
      setFilterValue,
    }
  }, [options, filterValue]);
}

function filterOptions<T extends string>(filterValue: string, options: T[]): T[];
function filterOptions<T extends CustomObject>(filterValue: string, options: T[], mapFn: (obj: T) => string): T[];
function filterOptions<T extends CustomObject | string>(filterValue: string, options: T[], mapFn?: (obj: T) => string): T[] {
  if (!options.length || !filterValue) return options;
  const isString = typeof options[0] == 'string';

  const lowerFilterValue = filterValue.toLowerCase();

  return options.filter(v => {
    const str = (isString ? v : mapFn(v)) as string;
    if (!str) return false;
    return str.toLowerCase().indexOf(lowerFilterValue) != -1;
  })
}