import React, { useState } from 'react';
import { PopupMenu } from '@components';
import { Option } from '@interfaces';
import './employeeProfile.scoped.scss';
import { Avatar, Badge } from '@mui/material';
import { EmployeeDetails } from '@services';
import { translateTypeOfContract } from '@utils';
import { ModalAndDialogs } from '../modalAndDialogs/modalAndDialogs';
import { useUnregisterEmployee } from 'src/features/owner';

interface Props {
  employeeDetails?: EmployeeDetails;
  actionMenuItems?: Option[];
}
export const EmployeeProfile = ({ employeeDetails, actionMenuItems }: Props) => {
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false);

  const { unregisterEmployee } = useUnregisterEmployee();

  const onClickMenuItem = (action: string) => {
    if (action === 'employeeEdit') return setOpenEmployeeModal(true);
    if (action === 'employeeUnregister') return unregisterEmployee(employeeDetails?.id);
  };

  return (
    <div className={`employee-profile-wrapper`}>
      <ModalAndDialogs openEmployeeModal={openEmployeeModal} setOpenEmployeeModal={setOpenEmployeeModal} employeeDetails={employeeDetails} />
      <div className="popup-menu-employee-details">
        <PopupMenu menuItems={actionMenuItems} onClickMenuItem={actionType => onClickMenuItem(actionType)} iconSize={24} />
      </div>
      <div className="badge-wrapper">
        <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Avatar sx={{ width: 100, height: 100 }} src="/future-image.jpg" />
        </Badge>
      </div>
      <div className="name-wrapper">
        <div className="name" data-testcy="employee-data-name">{`${employeeDetails?.firstName} ${employeeDetails?.lastName}`}</div>
      </div>
      <div className="additional-information-wrapper">
        <div className="additional-information" data-testcy="employee-data-additional">
          Dodatkowa informacja
        </div>
        <div className="type-of-employment" data-testcy="employee-data-type-of-employment">
          {translateTypeOfContract(employeeDetails?.typeOfEmployment)}
        </div>
        <div className="address-wrapper">
          <div className="street" data-testcy="employee-data-street">
            {employeeDetails?.address?.street}
          </div>
          <div className="city-and-zipCode">
            <div className="zipCode" data-testcy="employee-data-zip-code">
              {employeeDetails?.address?.zipCode}
            </div>
            <div className="city" data-testcy="employee-data-city">
              {employeeDetails?.address?.city}
            </div>
          </div>
        </div>
        <div className="pesel" data-testcy="employee-data-pesel">
          PESEL: {employeeDetails?.pesel}
        </div>
      </div>
    </div>
  );
};
