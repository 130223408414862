

const bufferToHex = (buffer: ArrayBuffer): string => {
  return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
};

export const hashFileContent = async (file: File): Promise<string> => {
  const content = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest('SHA-256', content);
  return bufferToHex(hashBuffer);
};