import { getFormattedAccountNo } from "@components";
import { BankAccountData } from "@interfaces";
import { isDefined } from '@utils';
import { useEffect, useState } from "react";

type BankAccountDataWithIndex = BankAccountData & { index: number };

interface IUseBankAccounts {
  accounts: BankAccountDataWithIndex[];
  mainAccount: BankAccountDataWithIndex | null;
  mainAccountIndex: number;
  otherAccounts: BankAccountDataWithIndex[];
  removeAccount: (index: number) => void;
  addAccount: (data: BankAccountData) => void;
  updateAccount: (index: number, data: BankAccountData) => void;
  setMainAccount: (index: number) => void;
}

export const useBankAccounts = (bankAccounts: BankAccountData[], setValue: (name: string, value: any) => void): IUseBankAccounts => {
  const [accounts, setAccounts] = useState<BankAccountDataWithIndex[]>(bankAccounts.map((account, index) => {
    return {
      ...account,
      number: getFormattedAccountNo(account.number),
      index,
    }
  }));
  const [nextIndex, setNextIndex] = useState<number>(bankAccounts.length);
  
  const mainAccountIndex = accounts.find((account) => account.mainAccount)?.index ?? -1;
  const mainAccount = accounts[mainAccountIndex] ?? null;
  const otherAccounts = accounts.filter(a => !a.mainAccount);

  useEffect(() => {
    if (isDefined(mainAccountIndex)) {
      setFormData();
    }
  }, [mainAccountIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const setFormData = () => {
    accounts.forEach((account, i) => {
      setValue(`bank-name-${i}`, account.bankName);
      setValue(`bank-number-${i}`, getFormattedAccountNo(account.number));
    });
    setValue("main-bank-account", mainAccountIndex);
  }
  const removeAccount = (index: number) => {
    if (index === mainAccountIndex) {
      setValue("main-bank-account", null);
    }
    setAccounts(prev => prev.filter(account => account.index !== index));

    setValue(`bank-name-${index}`, undefined);
    setValue(`bank-number-${index}`, undefined);
  }
  const addAccount = (data: BankAccountData) => {
    setValue(`bank-name-${nextIndex}`, data.bankName);
    setValue(`bank-number-${nextIndex}`, data.number);

    if (accounts.length === 0) {
      setValue('main-bank-account', nextIndex);
    }

    setAccounts(prev => [...prev, { mainAccount: accounts.length === 0, ...data, index: nextIndex }]);
    setNextIndex(prev => prev + 1);
  }
  const updateAccount = (index: number, data: BankAccountData) => {
    setValue(`bank-name-${index}`, data.bankName);
    setValue(`bank-number-${index}`, data.number);
    setAccounts(prev => prev.map(account => account.index === index ? { ...account, ...data } : account));
  }
  const setMainAccount = (index: number) => {
    if (mainAccountIndex === index) {
      setValue('main-bank-account', null);
      setAccounts(prev => prev.map(account => ({ ...account, mainAccount: account.index === index ? null : account.mainAccount })));
      return;
    }
    setValue("main-bank-account", index);
    setAccounts(prev => prev.map(account => ({ ...account, mainAccount: account.index === index })));
  }

  return {
    accounts,
    mainAccount,
    mainAccountIndex,
    otherAccounts,
    removeAccount,
    addAccount,
    updateAccount,
    setMainAccount,
  }
}