import React from 'react';
import { Chat } from '../Chat/chat';
import { ChatList } from '../ChatList/chatList';
import { useChatContext } from '../../providers/ChatContextProvider';
import './chatComposer.scoped.scss';

export const ChatComposer = () => {
  const { channels, currentChannelId, changeChannel } = useChatContext();

  const channelId = channels.length === 1 ? channels[0].channelId : currentChannelId;
  const channelName = channels.find(ch => ch.channelId === channelId)?.displayName;

  return (
    <div className="chat-composer-container">
      <ChatList channels={channels} selectedChannelId={channelId} onChannelSelect={changeChannel} />
      {channelId && <Chat subChannelId={channelId} chatName={channelName} />}
    </div>
  );
};
