


export const AvatarColor = {
  Blue: 'blue',
  Green: 'green',
  Pink: 'pink',
  Cyan: 'cyan',
  Yellow: 'yellow',
} as const;
export type AvatarColor = typeof AvatarColor[keyof typeof AvatarColor];

//custom hash function to get a number between 0 and 4
const getHashedColorId = (id: number) => Math.floor(Math.abs(id * (Math.sqrt(id) ** Math.log(id + Math.PI) - 9)) % 5);

const COLOR_MAP: AvatarColor[] = Object.values(AvatarColor);

/**
 * Generates a pseudorandom avatar color based on the id of the item
 * 
 * Results distribution:
 * - blue: 19.985%
 * - yellow: 19.999%
 * - pink: 20.003%
 * - green: 19.997%
 * - cyan: 20.016%
 * @param id the id of the item th avatar represents
 * @returns a string representing the color
 */
export const getAvatarColor = (id: number): AvatarColor => COLOR_MAP[getHashedColorId(id)];
