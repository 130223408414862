import React from 'react';
import { useForm } from 'react-hook-form';
import { TheSelect } from 'src/components/form';
import { TablePagination } from '@mui/material';

import './theTablePagination.scoped.scss';
import './theTablePagination.scss';

interface Props {
  totalItems: number;
  testcy: string;
  page: number;
  rowsPerPage: number;
  onChange: (data: { page: number; rowsPerPage: number }) => void;
}

export const TheTablePagination = ({ totalItems = 50, testcy, page, rowsPerPage, onChange }: Props) => {
  const { control } = useForm();

  const onItemsPerPageChangeHandler = (value: string) => {
    const v = Number(value);
    onChange({ page: 1, rowsPerPage: v });
  };
  const onPageChangeHandler = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onChange({ page: newPage + 1, rowsPerPage });
  };

  const paginationOptions = [
    { value: '10', label: '10', key: '10' },
    { value: '25', label: '25', key: '25' },
    { value: '50', label: '50', key: '50' },
  ];

  return (
    <div className="table-pagination">
      <div className="rows-per-page">
        <div className="rows-per-page__text">Pokaż wiersze na stronie</div>
        <TheSelect
          testcy={`${testcy}-pagination-rows-per-page`}
          name="rowsPerPage"
          options={paginationOptions}
          control={control}
          appearance="primary"
          defaultValue={String(rowsPerPage)}
          onChange={onItemsPerPageChangeHandler}
          hideEmptyOption
          size="small"
          width="66px"
        />
      </div>
      <div className="current-page-wrapper">
        <div>
          <TablePagination
            component="div"
            count={Number(totalItems)}
            page={Number(page - 1)}
            onPageChange={onPageChangeHandler}
            rowsPerPage={rowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
            rowsPerPageOptions={[]}
            backIconButtonProps={{
              title: '',
            }}
            nextIconButtonProps={{
              title: '',
            }}
            sx={{
              '& .MuiTablePagination-displayedRows': {
                fontFamily: 'Poppins',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
