import { formatCurrency } from '@utils';
import React from 'react';
import YearDropdown from 'src/components/form/yearDropdown/yearDropdown';
import './profitsLossesSummary.scss';

const ProfitsLossesSummary = ({ setDateRange, profitsSum, costsSum }) => {
  const handleDateRangeChange = newDateRange => {
    setDateRange(newDateRange);
  };

  return (
    <div className="revenues-cost-container">
      <YearDropdown onDateRangeChange={handleDateRangeChange} />
      <div className="title-and-value">
        <div className="summary-title revenues">Przychody</div>
        <div className="value">{formatCurrency(profitsSum || 0, 'zł')}</div>
      </div>
      <div className="title-and-value">
        <div className="summary-title costs">- Koszty</div>
        <div className="value">{formatCurrency(costsSum || 0, 'zł')}</div>
      </div>
      <div className="title-and-value">
        <div className="summary-title summary">= Wynik finansowy</div>
        <div className="value">{formatCurrency(profitsSum - costsSum || 0, 'zł')}</div>
      </div>
    </div>
  );
};

export default ProfitsLossesSummary;
