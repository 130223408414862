import calendar from '@assets/calendar.svg';
import newCalendar from '@assets/newCalendarIcon.svg';
import calendarDisabled from '@assets/calendarDisabled.svg';
import { isLeapYear } from 'date-fns';
import React from 'react';

export const CalendarIcon = (testcy: string) => {
  return <img data-testcy={`${testcy}-calendar-open`} src={calendar} alt="calendar" style={{ margin: '0' }} />;
};

export const NewCalendarIcon = (testcy: string, disabledOrReadonly?: boolean) => {
  return <img data-testcy={`${testcy}-calendar-open`} src={newCalendar} alt="calendar" style={{ margin: '0', opacity: `${disabledOrReadonly && 0.3}` }} />;
};

export const CalendarIconDisabled = (testcy: string) => {
  return <img data-testcy={`${testcy}-calendar-open ${testcy}-calendar-open-disabled`} src={calendarDisabled} alt="calednarDisabled" style={{ margin: '0' }} />;
};

export const getSuggestion = (partial: string | undefined): Date | null => {
  if (!partial) return null;
  const parts = partial.split('.');
  if (parts.length > 3) return null;
  if (parts.length === 3 && parts[2].length > 0) return null;
  if (parts[0].length !== 2) return null;
  if (parts[1] && parts[1].length !== 2) return null;

  parts[1] ||= (new Date().getMonth() + 1).toString();
  const day = Number(parts[0]);
  const month = Number(parts[1]);

  if (day > 31 || day < 1 || month > 12 || month < 1) return null;

  switch (month) {
    case 4:
    case 6:
    case 9:
    case 11:
      if (day > 30) return null;
      break;

    case 2:
      if (day > 29 || (!isLeapYear(new Date().getFullYear()) && day === 29)) return null;
      break;
  }

  return new Date(new Date().getFullYear(), month - 1, day);
};
