import { useState, useCallback } from 'react';
import { updateTaxValues as update, getTaxValues as fetch, TaxValues, TaxValueChange } from '@services';
import { getTaxValuesAccounting } from 'src/services/taxes';

export const useDataSource = (companyId: number | null, month: Date) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [taxValues, setTaxValues] = useState<TaxValues[]>();
  const [tableTaxValues, setTableTaxValues] = useState([]);

  const getTaxValues = useCallback(async () => {
    setIsFetching(true);
    const getFullYear = month.getFullYear();
    const period = new Date(month.getFullYear(), month.getMonth(), 1);
    const result = await fetch({
      companyId: companyId,
      from: period,
      to: period,
    });

    const resultTable = await getTaxValuesAccounting({
      companyId: companyId,
      year: getFullYear,
    });
    setTaxValues(result.data);
    setTableTaxValues(resultTable);
    setIsFetching(false);
  }, [month, companyId]);

  const updateTaxValues = useCallback(
    async (values: TaxValueChange[]) => {
      setIsSaving(true);
      await update({
        companyId,
        month,
        values,
      });
      setIsSaving(false);
    },
    [companyId, month]
  );

  return {
    isFetching,
    getTaxValues,
    taxValues,
    updateTaxValues,
    isSaving,
    tableTaxValues,
  };
};
