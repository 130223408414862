import { startOfYear } from 'date-fns';
import { CalendarIcon, ChevronIcon } from '@components';
import { DateRange } from '@interfaces';
import { dateFormatter } from '@lib';
import { Popover } from '@mui/material';
import { pl } from 'date-fns/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { DateRangePicker, RangeKeyDict, createStaticRanges } from 'react-date-range';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from '@utils';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { FormElementAppearance, FormElementSize } from '../types';
import './timeRangePicker.scss';

interface Props {
  id: string;
  testcy: string;
  onChange: (start: Date, end: Date) => void;
  label?: string;
  defaultValues?: DateRange;
  placeholder?: string;
  appearance?: FormElementAppearance;
  size?: FormElementSize;
  dropdownIcon?: boolean;
  width?: number | string;
  minDate?: Date;
  maxDate?: Date;
}

export const TimeRangePicker = ({
  id,
  testcy,
  onChange,
  label,
  defaultValues,
  placeholder,
  dropdownIcon,
  width = 'auto',
  appearance = FormElementAppearance.Old,
  size = FormElementSize.Medium,
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = useState<Date>(defaultValues?.fromDate || null);
  const [endDate, setEndDate] = useState<Date>(defaultValues?.toDate || new Date(''));
  const [startDateString, setStartDateString] = useState('');
  const [endDateString, setEndDateString] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  useEffect(() => {
    const el = document.getElementById(id);
    setAnchorEl(el);
  }, [id]);

  useEffect(() => {
    startDate ? setStartDateString(dateFormatter.objectToString(startDate, 'd MMM, yyyy')) : setStartDateString('');
  }, [startDate]);

  useEffect(() => {
    endDate ? setEndDateString(dateFormatter.objectToString(endDate, 'd MMM, yyyy')) : setEndDateString('');
  }, [endDate]);

  useEffect(() => {
    if (defaultValues) {
      const { fromDate, toDate } = defaultValues;
      setStartDate(fromDate || null);
      setEndDate(toDate || null);
    }
  }, [defaultValues]);

  const staticRanges = useMemo(() => {
    const currentMonthDate = dateFormatter.getMonthRange();
    const prevMonthDate = dateFormatter.getMonthRange(-1);
    const currentYearDate = dateFormatter.getYearRange();
    const prevYearDate = dateFormatter.getYearRange(-1);

    return [
      ...createStaticRanges([
        {
          label: 'Bieżący miesiąc',
          range: () => {
            return {
              startDate: currentMonthDate.fromDate,
              endDate: currentMonthDate.toDate,
            };
          },
          isSelected: data => {
            const isStartEqual = dateFormatter.diffBetweenDates(data.startDate, currentMonthDate.fromDate) === 0;
            const isEndEqual = dateFormatter.diffBetweenDates(data.endDate, currentMonthDate.toDate) === 0;
            return isStartEqual && isEndEqual;
          },
          hasCustomRendering: true,
        },
        {
          label: 'Poprzedni miesiąc',
          range: () => {
            return {
              startDate: prevMonthDate.fromDate,
              endDate: prevMonthDate.toDate,
            };
          },
          isSelected: data => {
            const isStartEqual = dateFormatter.diffBetweenDates(data.startDate, prevMonthDate.fromDate) === 0;
            const isEndEqual = dateFormatter.diffBetweenDates(data.endDate, prevMonthDate.toDate) === 0;
            return isStartEqual && isEndEqual;
          },
          hasCustomRendering: true,
        },
        {
          label: 'Bieżący rok',
          range: () => {
            return {
              startDate: currentYearDate.fromDate,
              endDate: currentYearDate.toDate,
            };
          },
          isSelected: data => {
            const isStartEqual = dateFormatter.diffBetweenDates(data.startDate, currentYearDate.fromDate) === 0;
            const isEndEqual = dateFormatter.diffBetweenDates(data.endDate, currentYearDate.toDate) === 0;
            return isStartEqual && isEndEqual;
          },
          hasCustomRendering: true,
        },
        {
          label: 'Poprzedni rok',
          range: () => {
            return {
              startDate: prevYearDate.fromDate,
              endDate: prevYearDate.toDate,
            };
          },
          isSelected: data => {
            const isStartEqual = dateFormatter.diffBetweenDates(data.startDate, prevYearDate.fromDate) === 0;
            const isEndEqual = dateFormatter.diffBetweenDates(data.endDate, prevYearDate.toDate) === 0;
            return isStartEqual && isEndEqual;
          },
          hasCustomRendering: true,
        },
      ]),
    ];
  }, []);

  const handleSelect = (range: RangeKeyDict) => {
    const start = range.range1?.startDate;
    const end = range.range1?.endDate;
    setStartDate(start);
    setEndDate(end);
    onChange(start, end);
    String(start) !== String(end) && setOpen(false);
  };

  const openPicker = () => {
    setOpen(true);
  };

  const classesArrows = useMemo(() => {
    return [
      endDate?.getMonth() === maxDate?.getMonth() && endDate.getFullYear() === maxDate.getFullYear() ? 'disabledRight' : '',
      startDate?.getMonth() === startOfYear(minDate).getMonth() && startDate?.getFullYear() === minDate.getFullYear() ? 'disabledLeft' : '',
    ].join(' ');
  }, [endDate, startDate, minDate, maxDate]);

  return (
    <div className={`date-picker-wrapper ${open ? 'focused-date-picker' : 'non-focus-date-picker'} appearance-${appearance} size-${size}`} style={{ width }}>
      <button data-testcy={testcy} id={id} onClick={openPicker} className="date-picker-content">
        {label && <div className="date-picker-label">{label}</div>}
        {appearance !== 'old' && !dropdownIcon && <CalendarIcon />}
        {startDateString && endDateString && (
          <div className="date-picker-value">
            {startDateString} - {endDateString}
          </div>
        )}
        {!startDateString && !endDateString && placeholder && <div className="date-picker-placeholder">{placeholder}</div>}
        {appearance !== 'old' && dropdownIcon && <ChevronIcon direction="down" size={16} />}
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <div className={`date-range-wrapper`}>
          <DateRangePicker
            className={classesArrows}
            data-testcy={`${testcy}-picker`}
            locale={pl}
            minDate={minDate}
            maxDate={maxDate}
            ranges={[
              {
                startDate: startDate,
                endDate: endDate,
              },
            ]}
            onChange={handleSelect}
            showDateDisplay={false}
            renderStaticRangeLabel={({ label, isSelected, range }) => {
              const selected = isSelected({ startDate, endDate });
              return (
                <span
                  data-testcy={`${testcy}-static-button-label ${testcy}-static-button-label-${label.replace(' ', '_').toLowerCase()}`}
                  onClick={() => setOpen(false)}
                  className={`input-static-range ${selected ? 'input-static-range-selected' : 'input-static-range-unselected'}`}
                >
                  {label}
                </span>
              );
            }}
            inputRanges={[]}
            staticRanges={staticRanges}
          />
        </div>
      </Popover>
    </div>
  );
};
