import { useContractorStore } from '@store';
import { useParams } from 'react-router';

export const useCurrentCompanyId = () => {
  const { companyId } = useParams();

  const ownerCompanyId = useContractorStore(state => state.ownerMyCompanyData?.id);

  return companyId ?? ownerCompanyId?.toString();
};
