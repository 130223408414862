import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
  size?: number;
}

export const AttachmentIcon = ({ colorType = '', size = 16 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        d="M12.6663 3.254V9.33333C12.6663 11.9107 10.577 14 7.99967 14C5.42234 14 3.33301 11.9107 3.33301 9.33333V5.1C3.33301 3.388 4.72101 2 6.43301 2C8.14501 2 9.53301 3.388 9.53301 5.1V9.22933C9.53301 10.0933 8.83234 10.7947 7.96834 10.7947C7.10367 10.7947 6.40301 10.0933 6.40301 9.22933V5.33267"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
