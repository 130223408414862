import React, { useEffect } from 'react';
import { FieldValues, UseFormReturn, useFormState } from 'react-hook-form';
import { DatePicker, TextInputNew, TheAccordion } from '@components';
import { focusOnNextFormInput, standardValidators } from '@utils';
import './invoiceDataSection.scoped.scss';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  useFormFeatures: Partial<UseFormReturn<FieldValues>>;
  onIssueDateHandle: (date: string) => void;
}

export const InvoiceDataSection = ({ open, setOpen, useFormFeatures, onIssueDateHandle }: Props) => {
  const { control, trigger, clearErrors } = useFormFeatures;
  const {
    errors: { invoiceDate: invoiceDateErr, deliveryDate: deliveryDateErr },
  } = useFormState({ control });

  useEffect(() => {
    const errors = [invoiceDateErr, deliveryDateErr];
    const isInvalid = errors.some(e => !!e);
    if (isInvalid && !open) setOpen(true);
  }, [invoiceDateErr, deliveryDateErr]);

  return (
    <TheAccordion testcy="costs-form-invoice-data-accordion" open={open} setOpen={setOpen} id="basic-data-invoice-form" title="Dane faktury">
      <div className="basic-data-section-wrapper">
        <div className="form-area">
          <div className="invoice-name-area">
            <TextInputNew
              testcy="costs-form-invoice-number"
              control={control}
              name="invoiceNumber"
              label="Numer faktury"
              width="260px"
              onKeyUp={event => {
                focusOnNextFormInput(event, 2);
              }}
              validation={standardValidators.requiredMaxNumber(100)}
            />
          </div>
          <div className="date-picker-area">
            <DatePicker
              testcy="costs-form-issue-date"
              control={control}
              trigger={trigger}
              clearErrors={clearErrors}
              name="invoiceDate"
              label="Data wystawienia"
              width="260px"
              onChange={onIssueDateHandle}
              allowSuggestions
              onKeyDown={event => {
                focusOnNextFormInput(event, 3);
              }}
              validation={standardValidators.requiredDate}
            />
            <DatePicker
              testcy="costs-form-delivery-date"
              control={control}
              name="deliveryDate"
              trigger={trigger}
              clearErrors={clearErrors}
              label="Data dostawy"
              width="260px"
              allowSuggestions
              onKeyDown={event => {
                focusOnNextFormInput(event, 3);
              }}
              validation={standardValidators.requiredDate}
            />
            <DatePicker
              testcy="costs-form-deduction-month"
              control={control}
              name="deductionMonth"
              trigger={trigger}
              clearErrors={clearErrors}
              label="Miesiąc odliczenia"
              views={['year', 'month']}
              openTo={'month'}
              inputFormat={'MM.yyyy'}
              placeholder="mm.rrrr"
              onKeyDown={event => {
                focusOnNextFormInput(event, 4);
              }}
              validation={standardValidators.requiredDate}
            />
          </div>
        </div>
      </div>
    </TheAccordion>
  );
};
