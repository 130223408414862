import React, { useState } from 'react';
import { ButtonMain, DatePicker, LoaderIcon, NumberInput, TextInputNew } from '@components';
import { formErrorMsg, formatNumberToCurrency, standardValidators, isDefined } from '@utils';
import { FormProvider, useForm } from 'react-hook-form';
import { RecordAmountFormData } from './invoiceRecordPaymentForm.utils';
import './invoiceRecordPaymentForm.scoped.scss';

interface Props {
  onSubmit: (data: RecordAmountFormData) => void;
  isRecordPaymentPending: boolean;
  amountToPay: number;
  amountPaid: string;
}

export const InvoiceRecordPaymentForm = ({ onSubmit, isRecordPaymentPending, amountPaid, amountToPay }: Props) => {
  const [openPicker, setOpenPicker] = useState(false);

  const form = useForm<RecordAmountFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      paymentDate: new Date(),
      paymentAmount: null,
      notes: null,
    },
  });

  const leftToPay = Math.round((+amountToPay - +amountPaid) * 100) / 100;
  const leftToPayFormatted = formatNumberToCurrency(leftToPay);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="form-wrapper">
          <div className="form-column">
            <DatePicker
              testcy="payment-form-payment-date"
              name="paymentDate"
              openPicker={openPicker}
              appearance="light"
              setOpenPicker={setOpenPicker}
              label="Data wpłaty"
              width="100%"
              validation={standardValidators.requiredDate}
            />
            <div className="payment-amount-wrapper">
              <NumberInput
                testcy="payment-form-payment-amount"
                name="paymentAmount"
                label={'Kwota'}
                float
                negative={amountToPay < 0}
                maxDecimals={2}
                width="100%"
                appearance="light"
                validation={{
                  validate: {
                    validAmount: standardValidators.validPaymentAmount(leftToPayFormatted),
                  },
                  required: formErrorMsg.isRequired,
                }}
                placeholder="0,00"
              />
              <div>
                Zapłacono: <span className="bold-g">{isDefined(amountPaid) ? formatNumberToCurrency(+amountPaid) + 'zł' : null}</span>
              </div>

              <div className="text-loader">
                Pozostało do zapłaty: <span className="bold-g"> {isDefined(amountPaid) ? leftToPayFormatted + 'zł' : <LoaderIcon size="tiny" />}</span>
              </div>
            </div>
            <TextInputNew
              testcy="payment-form-notes"
              name="notes"
              label={'Uwagi'}
              width="100%"
              multiline
              appearance="light"
              rows={'5'}
              validation={{
                maxLength: {
                  value: 100,
                  message: formErrorMsg.maxLength(100),
                },
              }}
            />
          </div>
        </div>
        <div className="form-footer">
          {isRecordPaymentPending && (
            <div className="loader-wrapper">
              <LoaderIcon size="small" />
            </div>
          )}
          <div className={`${isRecordPaymentPending && 'button-wrapper'}`}>
            <ButtonMain testcy="payment-form-send" type="submit" content={' Wyślij'} width="160px" isDisabled={isRecordPaymentPending} />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
