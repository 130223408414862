import React from 'react';
import { Avatar } from '@mui/material';
import { CloseIcon, MaximizeIcon, MinimizeIcon } from '@components';
import { useChatContext } from '../../providers/ChatContextProvider';
import './chatHeader.scoped.scss';

type Props = {
  chatName: string;
  dense?: boolean;
  showMaximize?: boolean;
  showMinimize?: boolean;
  showClose?: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
};

export const ChatHeader = ({ chatName, containerRef, dense = false, showMaximize = true, showMinimize = true, showClose = true }: Props) => {
  const classNames = `chat-header-user ${dense ? 'dense' : ''}`;

  const { changeState } = useChatContext();

  const firstLetter = chatName.charAt(0)?.toUpperCase();

  return (
    <div className="chat-header-container" ref={containerRef}>
      <div className={classNames} onClick={() => changeState('open')}>
        <Avatar>{firstLetter}</Avatar>
        <p className="chat-header-label">{chatName}</p>
      </div>
      <div className="chat-header-actions">
        {showMaximize && (
          <button data-testcy="chat-action-maximize" onClick={() => changeState('maximized')}>
            <MaximizeIcon />
          </button>
        )}
        {showMinimize && (
          <button data-testcy="chat-action-minimize" onClick={() => changeState('minimized')}>
            <MinimizeIcon />
          </button>
        )}
        {showClose && (
          <button data-testcy="chat-action-close" onClick={() => changeState('closed')}>
            <CloseIcon />
          </button>
        )}
      </div>
    </div>
  );
};
