import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AccountingTemplate, getAccountingTemplates } from '@services';
import { handleApiError } from '@utils';
import { RequestErrorMessage, Option } from '@interfaces';
import { useAccountantStore } from '@store';

interface UseAccountingTemplateInterface {
  isPendingAccountingTemplateData: boolean;
  isConfiguredAccountingTemplate: boolean;
  accountingTemplateDataOptions: Option[];
}

export const useAccountingTemplate = (): UseAccountingTemplateInterface => {
  const [isPendingAccountingTemplateData, setIsPendingAccountingTemplateData] = useState(false);

  const [isConfiguredAccountingTemplate, setIsConfiguredAccountingTemplate] = useState(false);
  const [accountingTemplateDataOptions, setAccountingTemplateDataOptions] = useState([]);

  const { companyId } = useParams();

  const { setAccountingTemplateStoreOptions } = useAccountantStore();

  useEffect(() => {
    fetchAccountingTemplates();
  }, []);

  const transformDataToSelectOptions = (templates: AccountingTemplate[], isConfigured: boolean) => {
    const selectOptions = templates.map(template => {
      const label = `${template.code} ${template.description}`;
      const value = `${template.code} id:${template.id}`;
      const options = { value, label, key: value };
      return isConfigured ? options : [];
    });
    setAccountingTemplateDataOptions(selectOptions);
  };

  const fetchAccountingTemplates = () => {
    setIsPendingAccountingTemplateData(true);
    getAccountingTemplates(Number(companyId) || null)
      .then(data => {
        data?.isConfigured && setAccountingTemplateStoreOptions(data?.templates);
        if (data.templates.length) {
          setIsConfiguredAccountingTemplate(data.isConfigured);
          transformDataToSelectOptions(data.templates, data.isConfigured);
        } else {
          setIsConfiguredAccountingTemplate(false);
          transformDataToSelectOptions([], false);
        }
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        setIsPendingAccountingTemplateData(false);
      });
  };

  return {
    isPendingAccountingTemplateData,
    isConfiguredAccountingTemplate,
    accountingTemplateDataOptions,
  };
};
