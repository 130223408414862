import React from 'react';
import { CheckCircleIcon, ErrorCircleIcon, ErrorTriangleIcon } from 'src/components/icons';

import './messageBox.scoped.scss';

export const MessageBoxType = {
  success: 'success',
  warning: 'warning',
  error: 'error',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageBoxType = typeof MessageBoxType[keyof typeof MessageBoxType];

interface Props {
  type: MessageBoxType;
  children: React.ReactNode;
}

export const MessageBox = ({ type, children }: Props) => {
  const getIcon = () => {
    switch (type) {
      case MessageBoxType.success:
        return <CheckCircleIcon size={20} />;
      case MessageBoxType.warning:
        return <ErrorTriangleIcon size={20} />;
      case MessageBoxType.error:
        return <ErrorCircleIcon size={20} />
      default:
        return null;
    }
  }

  const icon = getIcon();

  return (
    <div className={`message-box type-${type}`}>
      {icon && <div className="message-box-icon">{icon}</div>}
      <div className="message-box-content">{children}</div>
    </div>
  )
};