import { TheDialog, TheDialogNew } from '@components';
import { useDeleteAccount } from '@hooks';
import React from 'react';

import './confirmDeleteAccountDialog.scoped.scss';

interface Props {
  isOpen: boolean;
  closeDialog: () => void;
}

export const ConfirmDeleteAccountDialog = ({ isOpen, closeDialog }: Props) => {
  const { deleteAccount } = useDeleteAccount();

  const onDialogClose = (agree: boolean) => {
    closeDialog();
    if (!agree) return;
    deleteAccount();
  };

  return (
    <TheDialog
      testcy="confirm-delete-account"
      open={isOpen}
      onClose={onDialogClose}
      title="Usunięcie konta"
      approveButtonText="Usuń konto na zawsze"
      rejectButtonText="Anuluj"
      approveButtonWidth="200px"
      approveButtonColorType="red"
      rejectButtonColorType="text"
    >
      <div className="dialog-text">
        Tego działania nie można cofnąć. Spowoduje to trwałe usunięcie całego konta. Wszystkie prywatne obszary robocze zostaną usunięte, a użytkownik zostanie
        usunięty ze wszystkich współużytkowanych obszarów roboczych.
      </div>
    </TheDialog>
  );
};
