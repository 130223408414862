import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { formatNumberToCurrency } from '@utils';
import React from 'react';

import './correctionSummaryTable.scoped.scss';
import { LoaderIcon } from '@components';

type Props = {
  isRecalculating: boolean;
  beforeVat: number;
  beforeNet: number;
  beforeGross: number;
  afterVat: number;
  afterNet: number;
  afterGross: number;
  correctionVat: number;
  correctionNet: number;
  correctionGross: number;
};

export const CorrectionSummaryTable = ({
  isRecalculating,
  beforeVat,
  beforeNet,
  beforeGross,
  afterVat,
  afterNet,
  afterGross,
  correctionVat,
  correctionNet,
  correctionGross,
}: Props) => {
  const loaderOrValue = (value: number) => {
    if (isRecalculating) {
      return <LoaderIcon size="tiny" />;
    } else {
      return formatNumberToCurrency(value);
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell className="bold">Kwota VAT</TableCell>
          <TableCell className="bold">Wartość netto</TableCell>
          <TableCell className="bold">Wartość brutto</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className="bold right-align">Razem przed korektą</TableCell>
          <TableCell>{loaderOrValue(beforeVat)}</TableCell>
          <TableCell>{loaderOrValue(beforeNet)}</TableCell>
          <TableCell className="bold">{loaderOrValue(beforeGross)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="bold right-align">Razem korekta</TableCell>
          <TableCell>{loaderOrValue(correctionVat)}</TableCell>
          <TableCell>{loaderOrValue(correctionNet)}</TableCell>
          <TableCell className="bold">{loaderOrValue(correctionGross)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="bold right-align">Razem po korekcie</TableCell>
          <TableCell>{loaderOrValue(afterVat)}</TableCell>
          <TableCell>{loaderOrValue(afterNet)}</TableCell>
          <TableCell className="bold">{loaderOrValue(afterGross)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
