import { useCurrentCompanyId, useDebounce } from '@hooks';
import { ContractorsListData } from '@services';
import { useState } from 'react';
import { useApiQuery } from 'src/hooks/useApiQuery';
import { Option } from '@interfaces';

type UseContractorSearchProps = {
  defaultOptions: Option[];
  currentClientName: string;
};

export const useContractorSearch = ({ defaultOptions, currentClientName }: UseContractorSearchProps) => {
  const companyId = useCurrentCompanyId();

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 500);

  const mapToOptions = (contractors: ContractorsListData[]): Option[] => {
    if (!contractors) return [];

    return contractors.map((contractor: ContractorsListData) => ({
      label: contractor.name,
      value: contractor?.id?.toString(),
    }));
  };

  // When the user has selected a client, we want to show the clientName in the search field
  // but we want to search for all clients for the options list
  const textFilter = debouncedValue === currentClientName ? '' : debouncedValue;

  const { data: options, isLoadingAndEnabled: isLoadingOptions } = useApiQuery(
    'getContractors',
    { pageSize: 100, textFilter: textFilter, companyId: Number(companyId) },
    {
      select: result => mapToOptions(result.data),
    }
  );

  const resultOptions = value || options?.length > defaultOptions?.length ? options : defaultOptions;

  return {
    value,
    debouncedValue,
    setValue,
    options: resultOptions,
    isLoadingOptions,
  };
};
