import React from 'react';
import './buttonIcon.scoped.scss';

interface Props {
  children: JSX.Element;
  testcy: string;
  size?: "extra-small" | 'small' | 'medium' | 'big';
  onClick?: () => void;
  disabled?: boolean;
}

export const ButtonIcon = ({ children, size = 'medium', onClick = () => {}, disabled = false, testcy }: Props) => {
  return (
    <button
      type="button"
      data-testcy={testcy}
      className={`btn-icon-${size} button-icon-wrapper ${disabled ? 'disabled-icon-button' : ''}`}
      onClick={() => (disabled ? null : onClick())}
    >
      {children}
    </button>
  );
};
