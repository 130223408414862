import { EmailFormData } from '@services';
import { useApiMutation } from '../useApiMutation';
import { snackbarMessagesHandler } from '@utils';

interface IUseSendInvoiceEmail {
  sendInvoiceEmail: (id: string, data: EmailFormData) => void;
  isSendInvoiceEmailLoading: boolean;
}

export const useSendInvoiceEmail = (onSuccess: () => void): IUseSendInvoiceEmail => {
  const { mutate, isLoading } = useApiMutation('invoicesSendEmail');

  const sendInvoiceEmail = (id: string, data: EmailFormData) => {
    mutate({ id, data }, {
      onSuccess: () => {
        snackbarMessagesHandler.invoiceSendEmail();
        onSuccess();
      }
    });
  }

  return { sendInvoiceEmail, isSendInvoiceEmailLoading: isLoading };
};
