import { ButtonMainNew, LoaderIcon, SpecialInput } from '@components';
import { GusCompanyData, getCompanyDataGus } from '@services';
import { handleApiError, standardValidators } from '@utils';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import './gusFetcher.scoped.scss';
import { GusFormData } from './gusFetcher.utils';
import { SettingsTab } from '../settingsTab';

interface Props {
  onGusDataFetched: (data: GusCompanyData, nip: string) => void;
  onFormCompletedManually: () => void;
}

export const GusFetcher = ({ onGusDataFetched, onFormCompletedManually }: Props) => {
  const [isPendingGusData, setIsPendingGusData] = useState(false);

  const useFormProps = useForm<{ nip: string }>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });
  const { handleSubmit } = useFormProps;

  const onSubmit = async (formData: GusFormData) => {
    setIsPendingGusData(true);

    try {
      const data = await getCompanyDataGus(formData.nip);
      onGusDataFetched(data, formData.nip);
    } catch (err) {
      console.error(err);
      handleApiError(err);
    }
    setIsPendingGusData(false);
  };

  return (
    <form className="w-100-g form-wrapper">
      <FormProvider {...useFormProps}>
        <SettingsTab title="Dane firmy">
          <SpecialInput
            testcy="gus-fetcher-nip"
            name="nip"
            label="Numer NIP"
            width="405px"
            appearance="filled"
            type="nip"
            validation={standardValidators.requiredExactLength(10)}
            endAdornment={isPendingGusData && <LoaderIcon size="tiny" />}
          />
          <div className="button-area">
            <ButtonMainNew
              testcy="gus-fetcher-fetch"
              colorType="primary"
              content="Dodaj z danymi z GUS"
              sizeType="extra-big"
              width="260px"
              isDisabled={isPendingGusData}
              onClick={handleSubmit(onSubmit)}
            />
            <ButtonMainNew
              testcy="gus-fetcher-manual"
              content="Uzupełnij dane ręcznie"
              colorType="primaryLight"
              onClick={onFormCompletedManually}
              type="button"
              sizeType="extra-big"
              width="260px"
              isDisabled={isPendingGusData}
            />
          </div>
        </SettingsTab>
      </FormProvider>
    </form>
  );
};
