import { useModal } from '@hooks';
import { useAuth } from 'oidc-react';
import React from 'react';
import { SettingsSection, SettingsTab } from '../../components/settingsTab';
import { ConfirmDeleteAccountDialog } from './confirmDeleteAccountDialog';
import { LoginActionCard } from './loginActionCard';

export const LoginSection = () => {
  const { signOutRedirect } = useAuth();

  const { isOpen, openModal, closeModal } = useModal();

  return (
    <>
      <ConfirmDeleteAccountDialog isOpen={isOpen} closeDialog={closeModal} />
      <SettingsTab title="Logowanie">
        <SettingsSection>
          <LoginActionCard testcy="login-tab-logout" title="Wyloguj się" subtitle="Wyloguj się ze wszystkich profili." onClick={signOutRedirect} />
          <LoginActionCard
            testcy="login-tab-delete-account"
            title="Usuń konto"
            subtitle="Chcesz usunąć swoje konto na zawsze? Trwałe usunięcie konta usuwa dostęp do twojego profilu."
            onClick={openModal}
            isRed
          />
        </SettingsSection>
      </SettingsTab>
    </>
  );
};
