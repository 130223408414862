import { GetInvoiceListParams } from '@services';
import { ChartSeries, IncomesChartAPIData, mapIncomesChartData } from '@utils';
import { useEffect, useMemo } from 'react';
import { useApiQuery } from '../useApiQuery';

interface IUseIncomesChart {
  incomesChartData: ChartSeries[];
  isIncomesChartLoading: boolean;
}

const checkIfDataIsZero = (data: IncomesChartAPIData): boolean => {
  const lengthOfPeriod = data?.dates?.length;
  const paid = data.paid.filter(item => {
    return item === 0;
  });
  const unpaid = data.unpaid.filter(item => {
    return item === 0;
  });

  return paid.length === lengthOfPeriod && unpaid.length === lengthOfPeriod;
};

export const useIncomesChart = (params: GetInvoiceListParams, setEnableChartTooltip: (v: boolean) => void): IUseIncomesChart => {
  const { data: seriesData, isLoading: isIncomesChartLoading } = useApiQuery('getIncomesChartData', params, {
    select: response => response ?? { dates: [], paid: [], unpaid: [] },
    keepPreviousData: true,
    placeholderData: { dates: [], paid: [], unpaid: [] },
    enabled: !!params?.filter && !params?.filter?.companyId,
  });

  const incomesChartData: ChartSeries[] = useMemo(() => mapIncomesChartData(seriesData), [seriesData]);

  useEffect(() => {
    setEnableChartTooltip(checkIfDataIsZero(seriesData));
  }, [seriesData]);

  return {
    incomesChartData,
    isIncomesChartLoading,
  };
};
