import { FileDropZone, SearchBarValues, TableRowData, TheDialog, TheTableNew } from '@components';
import { SharedFilePreview, SharedInvoiceDetails, SharedTableList } from '@features';
import { useCurrentCompanyId, useDebounce, useEditDigitalizedCost, useInvoiceDrawerController, useIsAllFalse, useMarkAsPaidCost, usePagination } from '@hooks';
import { CustomObject, InvoiceStatus, RequestErrorMessage } from '@interfaces';
import { dateFormatter } from '@lib';
import {
  CostDocumentsList,
  CostDocumentsOrderOptions,
  CostFile,
  CostOverviewNew,
  CostsBulkOperationsParams,
  GetCostDocumentsListParams,
  compareCostHashes,
  confirmCostDocument,
  costTransferCSV,
  createNewCostDocuments,
  deleteCostDocument,
  getCostDataById,
  getCostFiles,
  getMyCompany,
  sendCostToInsert,
} from '@services';
import { useCompanyStore, useContractorStore, useCostStore, useListParamsStore } from '@store';
import {
  AccountantStatusType,
  BulkOperationsEnum,
  InvoiceActionEnum,
  PaymentStatusType,
  SortDirection,
  costOwnerStatusOptions,
  dialogMessagesText,
  formatFetchedDataToPdf,
  handleApiError,
  invoiceAccountantStatusShortOptions,
  snackbarMessagesHandler,
} from '@utils';
import { uniqueId as getUniqueId, isEqual, uniqBy } from 'lodash';
import React, { UIEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { ListSearchBar, SelectedInvoiceCustomRow } from 'src/components/combined';
import { useApiMutation } from 'src/hooks/useApiMutation';
import {
  CheckboxStateDataType,
  CostConfirmDialogDataType,
  actionMenuItems,
  b64toBlob,
  costDocumentDataToTableRow,
  getSelectedCosts,
  getTableHeader,
  isCostFile,
  transformFileCreationResultToCostFile,
  transformMenuItemsCostsList,
  translateAction,
  unregisterCheckboxes,
} from './addedTab.utils';
import cleaningPic from '@assets/images/cleaning_angle.png';

import './addedTab.scoped.scss';
import './addedTab.scss';
import { useCredentialToInsertData } from 'src/features/shared/sharedCompanyForm/sections/insertSection/hooks';

interface Props {
  showAccountingStatus?: boolean;
}

export const AddedTab = forwardRef(({ showAccountingStatus = false }: Props, ref) => {
  const defaultSortingColumn = 'issueDate';
  const testcy = 'costs-table';

  const { page, setPage, totalRows, setTotalRows, rowsPerPage, setRowsPerPage } = usePagination();

  const inputFileRef = useRef(null);
  const listSearchBarRef = useRef(null);
  const [isPendingAddNewCostDocuments, setIsPendingAddNewCostDocuments] = useState(false);
  const [isFilesPending, setIsFilesPending] = useState(true);
  const [costFiles, setCostFiles] = useState<CostFile[]>([]);
  const [orderBy, setOrderBy] = useState<CostDocumentsOrderOptions>(defaultSortingColumn);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESCENDING);
  const [costDocumentsList, setCostDocumentsList] = useState<CostOverviewNew[]>([]);
  const [costFilesPage, setCostFilesPage] = useState(1);
  const [totalCostFilePages, setTotalCostFilePages] = useState<number>(null);
  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [statusSelected, setStatusSelected] = useState<InvoiceStatus[]>([]);
  const [rows, setRows] = useState<TableRowData[]>([]);
  const [searchBarDefaultValues, setSearchBarDefaultValues] = useState<SearchBarValues>(null);
  const [isCheckedStore, setIsCheckedStore] = useState(false);
  const [selectedCostDocumentId, setSelectedCostDocumentId] = useState('');
  const [selectedCostDocumentName, setSelectedCostDocumentName] = useState('');
  const [listParams, setListParams] = useState<GetCostDocumentsListParams>();
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [openDeleteCostInvoiceDialog, setOpenDeleteCostInvoiceDialog] = useState<boolean>(false);
  const [openDeleteCostFileDialog, setOpenDeleteCostFileDialog] = useState<boolean>(false);
  const [stashedData, setStashedData] = useState(null);

  const [costDocumentId, setCostDocumentId] = useState(null);

  const [checkboxState, setCheckboxesState] = useState<CheckboxStateDataType>();

  const [dialogConfirmBulkOperationsData, setDialogConfirmBulkOperationsData] = useState<CostConfirmDialogDataType>();
  const [openConfirmBulkOperationDialog, setOpenConfirmBulkOperationDialog] = useState(false);

  const [menuItemsList, setMenuItemsList] = useState([]);
  const [updateData, setUpdateData] = useState(false);

  const listSearchParams = useDebounce(listParams, 200);
  const { companyId } = useParams();
  const { control, getValues, setValue, handleSubmit } = useForm();
  const { costId } = useCostStore();
  const { selectedCompany } = useCompanyStore();
  const { ownerMyCompanyData } = useContractorStore();
  const currentCompanyId = useCurrentCompanyId();

  const { lastParamsCostDocumentsList, setLastParamsCostDocumentsList } = useListParamsStore();
  const { hasInsertCredentials, isPendingHasCredentialsData } = useCredentialToInsertData(false);
  const isPending = useIsAllFalse([isPendingHasCredentialsData], 0);
  const { openInvoiceDrawer, closeInvoiceDrawer } = useInvoiceDrawerController('cost');
  const { editDigitalizedCost } = useEditDigitalizedCost();
  const { markCostAsPaid } = useMarkAsPaidCost(costId, () => setUpdateData(true), dialogConfirmBulkOperations);

  useEffect(() => {
    if (lastParamsCostDocumentsList && !!lastParamsCostDocumentsList?.companyId === !!companyId) {
      setPage(lastParamsCostDocumentsList.page);
      setSortDirection(lastParamsCostDocumentsList.sortDirection);
      setOrderBy(lastParamsCostDocumentsList.orderBy);
      setRowsPerPage(lastParamsCostDocumentsList.itemsPerPage);
      if (lastParamsCostDocumentsList.searchBarValues) {
        setStatusSelected(lastParamsCostDocumentsList.searchBarValues.status);
        setSearchBarDefaultValues(lastParamsCostDocumentsList.searchBarValues);
      }
      setLastParamsCostDocumentsList(null);
    } else {
      const { fromDate, toDate } = dateFormatter.getMonthRange();
      setSearchBarDefaultValues({ fromDate: fromDate, toDate: toDate });
    }
    setIsCheckedStore(true);
    closeInvoiceDrawer();
  }, []);

  useEffect(() => {
    const uniqueCheckedValue = uniqBy(checkboxState?.checkboxesSelected, 'id'); //removed if had duplicate id
    const net = uniqueCheckedValue.map(item => item.net);
    const gross = uniqueCheckedValue.map(item => item.gross);

    const netSum = net.reduce((partialSum, a) => partialSum + a, 0);
    const grossSum = gross.reduce((partialSum, a) => partialSum + a, 0);

    if (
      (checkboxState?.selectedAll && checkboxState?.totalInvoicesCount - uniqueCheckedValue.length) ||
      (!checkboxState?.selectedAll && uniqueCheckedValue.length)
    ) {
      const myTimeout = setTimeout(() => {
        setCheckboxesState(p => ({ ...p, checkboxTimeout: true }));
      }, 600);
      setCheckboxesState(p => ({ ...p, time: myTimeout }));
    } else {
      setCheckboxesState(p => ({ ...p, checkboxTimeout: false }));
      clearTimeout(checkboxState?.time);
    }
    if (checkboxState?.selectedAll) {
      setCheckboxesState(p => ({
        ...p,
        checkedNetValue: checkboxState?.totalNetValue - netSum,
        checkedGrossValue: checkboxState?.totalGrossValue - grossSum,
        numberOfInvoicesChecked: checkboxState?.totalInvoicesCount - uniqueCheckedValue.length,
        headerCheckboxIsPressed: !!(checkboxState?.totalInvoicesCount - uniqueCheckedValue.length),
      }));
    } else {
      setCheckboxesState(p => ({ ...p, numberOfInvoicesChecked: uniqueCheckedValue.length, headerCheckboxIsPressed: !!uniqueCheckedValue.length }));
      if (uniqueCheckedValue.length) {
        setCheckboxesState(p => ({ ...p, checkedNetValue: netSum, checkedGrossValue: grossSum }));
      }
    }
  }, [checkboxState?.checkboxesSelected, checkboxState?.totalNetValue, checkboxState?.totalGrossValue]);

  useEffect(() => {
    if (isCheckedStore) {
      modifyParams();
    }
  }, [isCheckedStore, companyId, searchValue, startDate, endDate, statusSelected, sortDirection, orderBy, page, rowsPerPage]);

  useEffect(() => {
    fetchTableData();
  }, [listSearchParams, isPendingAddNewCostDocuments, hasInsertCredentials]);

  useEffect(() => {
    fetchCostFiles(1);
  }, [companyId, totalCostFilePages]);

  const { mutateAsync } = useApiMutation('getDigitalizedCosts', {
    onSuccess: costDocumentsList => {
      const hasBankAccount = companyId ? selectedCompany?.bankAccounts.length > 0 : ownerMyCompanyData?.bankAccounts.length > 0;
      generateRows(costDocumentsList.data);
      setMenuItemsList(
        transformMenuItemsCostsList(costDocumentsList.data, companyId, hasInsertCredentials, selectedCompany?.hasEntityCredentials, hasBankAccount)
      );
    },
  });

  async function fetchTableData(resetCheckboxes = false) {
    if (!listSearchParams) return;
    const costDocumentsList = await mutateAsync(listSearchParams);
    setTableParam(costDocumentsList, resetCheckboxes);
  }

  let lastFetchedCostFilesPage: number | undefined = undefined;
  const fetchCostFiles = async (page: number) => {
    if (page === lastFetchedCostFilesPage) return;
    if (totalCostFilePages !== null && page > totalCostFilePages) return;

    lastFetchedCostFilesPage = page;
    setIsFilesPending(true);
    try {
      const files = await getCostFiles({
        companyId: Number(companyId) || null,
        pageNumber: page,
      });
      setTotalCostFilePages(files.totalPageCount);

      if (files.data.length > 0) {
        setCostFilesPage(page);
      }

      if (page == 1) {
        setCostFiles(files.data);
      } else {
        setCostFiles([...costFiles, ...files.data]);
      }
    } catch (err) {
      handleApiError(err);
    }
    setIsFilesPending(false);
  };

  const onFilterChange = (data: SearchBarValues) => {
    unselectAllCheckboxes();
    setSearchValue(data.textFilter);
    setStartDate(data.fromDate);
    setEndDate(data.toDate);
    setPage(1);
  };

  const onFilesScroll = (event: UIEvent<HTMLDivElement>) => {
    if (isFilesPending) return;

    const { scrollHeight, scrollTop, clientHeight } = event.target as HTMLDivElement;

    if (scrollTop + clientHeight > scrollHeight - 256) {
      const newPage = costFilesPage + 1;
      fetchCostFiles(newPage);
    }
  };

  const generateRows = (data: CostOverviewNew[]): void => {
    const uniqueId = getUniqueId();
    const ownerHasBankAccount = ownerMyCompanyData?.bankAccounts.length > 0;
    const newRows = data.map((dataRow: CostOverviewNew, index: number) => {
      const transformedDataToRow = costDocumentDataToTableRow(
        dataRow,
        companyId,
        hasInsertCredentials,
        selectedCompany?.hasEntityCredentials,
        ownerHasBankAccount,
        control,
        onChangeCheckbox,
        uniqueId,
        index,
        data,
        showAccountingStatus
      );
      return transformedDataToRow;
    });
    setRows([...newRows]);
  };

  const setTableParam = (costDocumentsList: CostDocumentsList, resetCheckboxes: boolean): void => {
    setTotalRows(costDocumentsList.totalCount);
    setCostDocumentsList(costDocumentsList.data);
    setCheckboxesState(p => ({
      ...p,
      totalInvoicesCount: costDocumentsList.totalCount,
      totalNetValue: costDocumentsList.netValue,
      totalGrossValue: costDocumentsList.grossValue,
    }));
    const arrayOfNonCheckboxSelectedOnPage = [];
    const arrayOfCheckboxSelectedOnPage = [];
    if (checkboxState?.selectedAll && !resetCheckboxes) {
      costDocumentsList.data.forEach(row => {
        if (checkboxState?.checkboxesSelected.filter(item => item.id === row.id).length > 0) {
          setValue(`checkbox-${row.id}`, false);
          arrayOfNonCheckboxSelectedOnPage.push(...checkboxState?.checkboxesSelected);
        } else {
          setValue(`checkbox-${row.id}`, true);
        }
      });
      arrayOfNonCheckboxSelectedOnPage.length ? setValue(`check-all-on-page`, false) : setValue(`check-all-on-page`, true);
    } else {
      costDocumentsList.data.forEach(row => {
        const uniqueCheckedValue = uniqBy(checkboxState?.checkboxesSelected, 'id');
        const checkboxIsInCheckboxSelected = uniqueCheckedValue.filter(item => {
          return item.id === row.id;
        });
        arrayOfCheckboxSelectedOnPage.push(...checkboxIsInCheckboxSelected);
      });
      if (arrayOfCheckboxSelectedOnPage.length && arrayOfCheckboxSelectedOnPage.length === costDocumentsList.data.length) {
        setValue(`check-all-on-page`, true);
      } else {
        setValue(`check-all-on-page`, false);
      }
    }
  };

  const saveToStateParams = () => {
    setLastParamsCostDocumentsList({
      page,
      sortDirection,
      orderBy,
      searchBarValues: {
        textFilter: searchValue,
        fromDate: startDate,
        toDate: endDate,
        status: statusSelected,
      },
      companyId: Number(companyId) || null,
      itemsPerPage: rowsPerPage,
    });
  };

  const modifyParams = (useDefaultParams: boolean = false) => {
    const params: GetCostDocumentsListParams = {
      filter: { companyId: Number(companyId) || null },
      paging: { pageNumber: page, pageSize: rowsPerPage },
      sorting: { direction: sortDirection },
    };

    if (orderBy) params.sorting.orderBy = orderBy;
    if (searchValue) params.filter.textFilter = searchValue;
    if (statusSelected) {
      statusSelected.forEach(item => {
        setValue(`checkbox-filter-status-${item}`, true);
      });
      if (!companyId) {
        const trans = statusSelected.map(item => {
          return item === PaymentStatusType.Empty ? null : item;
        });
        params.filter.paymentStatusFilter = trans;
      } else {
        params.filter.accountingStatusFilter = statusSelected;
      }
    }
    if (startDate) params.filter.dateFrom = dateFormatter.objectToString(startDate, "yyyy-MM-dd'T'HH:mm:ss");
    if (endDate) params.filter.dateTo = dateFormatter.objectToString(endDate, "yyyy-MM-dd'T'HH:mm:ss");

    if (useDefaultParams && listSearchBarRef) {
      onChangeSort(SortDirection.DESCENDING, defaultSortingColumn);
      listSearchBarRef.current.resetSearch();
    }

    setListParams(params);
    setTimeout(() => {
      saveToStateParams();
    });
  };

  const onChangePagination = (data: { page: number; rowsPerPage: number }) => {
    setPage(data.page);
    setRowsPerPage(data.rowsPerPage);
  };

  const onChangeSort = (sortType: SortDirection, propertyName: string): void => {
    setSortDirection(sortType);
    setOrderBy(propertyName as CostDocumentsOrderOptions);
  };

  const onChangeCheckbox = (value: boolean, id: number, name: string, net: number, gross: number, data: CostOverviewNew[]) => {
    const ids = getSelectedCosts(getValues());

    const arrayOfCheckboxSelectedOnPage = [];
    data.forEach(row => {
      const checkboxIsInCheckboxSelected = ids.filter(item => {
        return Number(item) === row.id;
      });
      arrayOfCheckboxSelectedOnPage.push(...checkboxIsInCheckboxSelected);
    });

    arrayOfCheckboxSelectedOnPage.length === data.length && setValue(`check-all-on-page`, true);
    arrayOfCheckboxSelectedOnPage.length < data.length && setValue(`check-all-on-page`, false);
    !arrayOfCheckboxSelectedOnPage.length && setValue(`check-all-on-page`, false);
    setCheckboxesState(p => {
      if (p.selectedAll) {
        if (!value) {
          return { ...p, checkboxesSelected: [...p.checkboxesSelected, { id, name, net, gross }] };
        } else {
          return {
            ...p,
            headerCheckboxIsPressed: p.headerCheckboxIsPressed,
            checkboxesSelected: p.checkboxesSelected.filter(item => item.name !== name),
          };
        }
      } else {
        if (value) {
          return { ...p, checkboxesSelected: [...p.checkboxesSelected, { id, name, net, gross }] };
        } else {
          return { ...p, checkboxesSelected: p.checkboxesSelected.filter(item => item.name !== name) };
        }
      }
    });
  };

  const onChangeCheckboxHeader = (isChecked: boolean) => {
    costDocumentsList.forEach(row => {
      const nameOfCheckbox = `checkbox-${row.id}`;
      if (checkboxState?.selectedAll) {
        if (!isChecked) {
          setValue(`checkbox-${row.id}`, false);
          setCheckboxesState(p => ({
            ...p,
            checkboxesSelected: [...p.checkboxesSelected, { id: row.id, name: nameOfCheckbox, net: row.netValue, gross: row.grossValue }],
          }));
        } else {
          setValue(`checkbox-${row.id}`, true);
          setCheckboxesState(p => ({ ...p, checkboxesSelected: p.checkboxesSelected.filter(item => item.name !== nameOfCheckbox) }));
        }
      } else {
        if (isChecked) {
          setValue(`checkbox-${row.id}`, true);
          setCheckboxesState(p => ({
            ...p,
            checkboxesSelected: [...p.checkboxesSelected, { id: row.id, name: nameOfCheckbox, net: row.netValue, gross: row.grossValue }],
          }));
        } else {
          setValue(`checkbox-${row.id}`, false);
          setCheckboxesState(p => ({ ...p, checkboxesSelected: p.checkboxesSelected.filter(item => item.name !== nameOfCheckbox) }));
        }
      }
    });
  };

  const unselectAllCheckboxes = () => {
    setCheckboxesState(p => ({ ...p, checkboxesSelected: [], headerCheckboxIsPressed: false, selectedAll: false }));
    setValue('check-all-on-page', false);
    unregisterCheckboxes(getValues(), setValue);
  };

  const onSelectAll = () => {
    setCheckboxesState(p => ({
      ...p,
      checkedNetValue: checkboxState?.totalNetValue,
      checkedGrossValue: checkboxState?.totalGrossValue,
      selectedAll: true,
      numberOfInvoicesChecked: checkboxState?.totalInvoicesCount,
      checkboxesSelected: [],
    }));

    costDocumentsList.forEach(row => {
      setValue(`checkbox-${row.id}`, true);
      setValue(`check-all-on-page`, true);
    });
  };

  const onUnselectAll = () => {
    unselectAllCheckboxes();
  };

  const changeSelectedArray = (action: InvoiceActionEnum, invoiceId: string) => {
    if (action === InvoiceActionEnum.DELETE) {
      setCheckboxesState(p => ({ ...p, checkboxesSelected: p.checkboxesSelected.filter(item => item.id !== +invoiceId) }));
    }
    if (checkboxState?.selectedAll && action === InvoiceActionEnum.DELETE) {
      setCheckboxesState(p => ({
        ...p,
        totalInvoicesCount: checkboxState?.totalInvoicesCount - 1,
      }));
    }
  };

  const onClickPreview = (data: TableRowData | CostFile): void => {
    const name = isCostFile(data) ? data.originalFilename : data.name.content;
    setSelectedCostDocumentName(name);
    setSelectedCostDocumentId(String(data.id));
    setOpenPreviewModal(true);
  };

  useImperativeHandle(ref, () => {
    return {
      resetFilters() {
        if (inputFileRef) inputFileRef.current.click();
      },
    };
  });

  const addNewCostsDocuments = async (data: FormData) => {
    const myCompany = await getMyCompany();
    const thisCompanyId = companyId ?? myCompany.id;

    data.set('companyId', String(thisCompanyId));

    setIsPendingAddNewCostDocuments(true);
    try {
      const costDocuments = await createNewCostDocuments(data);

      if (costDocuments.every(doc => doc.isSuccessful)) {
        snackbarMessagesHandler.costsUploadDoc(costDocuments.length > 1 ? true : false);
      }
      if (totalCostFilePages <= 1) {
        setTotalCostFilePages(1);
        const newFiles = transformFileCreationResultToCostFile(costDocuments);
        setCostFiles([...newFiles, ...costFiles]);
      }
      setCostFilesPage(1);
      fetchCostFiles(1);
    } catch (err) {
      handleApiError(err);
    }
    setIsPendingAddNewCostDocuments(false);
  };

  const onClosePreview = () => {
    setOpenPreviewModal(false);
  };

  const onClickMenuItem = (action: string, row: TableRowData) => {
    if (action === 'filePreview') return openInvoiceDrawer('invoiceDrawerAndInvoicePreview', row.id);
    if (action === 'edit') return editDigitalizedCost(row.id);
    // if (action === 'print') return onClickPrint(row); //TODO to be implemented
    if (action === 'exportCSV') return onClickCreateCSVFile(row);
    if (action === 'delete') return onClickDelete(row);
    if (action === 'confirm') return onClickConfirmDocument(row);
    if (action === 'sendToInsert') return onClickSendToInsert(row);
    if (action === 'markAsPaid')
      return markCostAsPaid(
        row,
        false,
        listParams.filter,
        checkboxState?.selectedAll,
        checkboxState?.checkboxesSelected,
        checkboxState?.numberOfInvoicesChecked
      );
  };

  const onClickOpenMenu = (row: TableRowData, index: number) => {
    // furure implementation of menu items refresh on change accounting status
    getCostDataById(+row.id)
      .then(data => {
        if (!data.invoicingData) return;
        setMenuItemsList(prev => {
          const itemUpdatedStatus = prev[index].map(item => {
            return item.value === 'confirm' ? { ...item, disabled: data.accountingStatus === AccountantStatusType.SentToAccountingService } : item;
          });

          const updatedMenuItemsArray = prev.map((item, i) => {
            return i === index ? itemUpdatedStatus : item;
          });
          isEqual(prev, updatedMenuItemsArray);
          return prev;
        });
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {});
  };

  // const onClickPrint = (row: TableRowData) => {
  //   //TODO to be implemented
  // };

  const onClickSendToInsert = (data: TableRowData) => {
    sendCostToInsert(+data.id)
      .then(() => {
        snackbarMessagesHandler.costsSendToInsert();
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        fetchTableData();
      });
  };

  const onClickFileDelete = (data: TableRowData | CostFile) => {
    setCostDocumentId(data.id);
    setCostFilesPage(1);
    setStashedData(data.stashedData);
    setOpenDeleteCostFileDialog(true);
  };

  const onClickDelete = (data: TableRowData | CostFile) => {
    setCostDocumentId(data.id);
    setStashedData(data.stashedData);
    setOpenDeleteCostInvoiceDialog(true);
  };

  const onCloseConfirmDelete = async (isConfirmed: boolean, isInvoice: boolean) => {
    if (!isConfirmed) return isInvoice ? setOpenDeleteCostInvoiceDialog(false) : setOpenDeleteCostFileDialog(false);
    deleteCostDocument(costDocumentId)
      .then(() => {
        if (isInvoice) {
          changeSelectedArray(InvoiceActionEnum.DELETE, costDocumentId);
          snackbarMessagesHandler.costsInvoiceDeleted();
          setOpenDeleteCostInvoiceDialog(false);
        } else {
          snackbarMessagesHandler.costsDocumentDeleted();
          setOpenDeleteCostFileDialog(false);
        }
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        isInvoice ? fetchTableData() : fetchCostFiles(1);
      });
  };

  const onClickConfirmDocument = async (data: TableRowData) => {
    unselectAfterBulkOperation(BulkOperationsEnum.CONFIRM);
    try {
      const response = await confirmCostDocument(Number(data.id));
      snackbarMessagesHandler.documentConfirm(true);
      fetchTableData();
    } catch (err) {
      console.error(err);
      handleApiError(err);
    }
  };

  // const onDigitalizeAllClick = () => {
  //   //TODO to be implemented
  // };

  const checkCostStatusWhenDelete = useCallback(() => {
    return companyId && stashedData?.accountingStatus !== AccountantStatusType.New;
  }, [stashedData?.accountingStatus, companyId]);

  const onBulkOperationsInvoice = (action: BulkOperationsEnum) => {
    unselectAfterBulkOperation(action);
    if (action === BulkOperationsEnum.EXPORT_CSV) {
      handleSubmit(f => onClickCreateCSVFile(f as CustomObject))();
    } else if (action === BulkOperationsEnum.MARK_AS_PAID) {
      handleSubmit(f =>
        markCostAsPaid(
          f as CustomObject,
          true,
          listParams.filter,
          checkboxState?.selectedAll,
          checkboxState?.checkboxesSelected,
          checkboxState?.numberOfInvoicesChecked
        )
      )();
    }
  };

  const unselectAfterBulkOperation = (bulk: BulkOperationsEnum) => {
    if (
      checkboxState.selectedAll ||
      statusSelected.includes(PaymentStatusType.Paid) ||
      statusSelected?.length === 0 ||
      bulk === BulkOperationsEnum.EXPORT_CSV ||
      (statusSelected.includes(AccountantStatusType.Confirmed) && bulk === BulkOperationsEnum.CONFIRM && companyId)
    ) {
      return;
    }
    setCheckboxesState(p => ({ ...p, checkboxesSelected: [], headerCheckboxIsPressed: false, selectedAll: false }));
  };

  const onClickCreateCSVFile = (dataId: CustomObject) => {
    const params: CostsBulkOperationsParams = {
      filter: listParams.filter,
      ids: [],
      selectAll: checkboxState?.selectedAll,
    };
    if (dataId?.id) {
      params.ids = [Number(dataId.id)];
      params.selectAll = false;
    } else {
      params.ids = checkboxState?.checkboxesSelected.map(item => item.id);
    }
    costTransferCSV(params)
      .then(data => {
        const blob = b64toBlob(data.data, data.contentType);

        if (dataId?.id) {
          snackbarMessagesHandler.costsExportCSV();
        } else {
          dialogConfirmBulkOperations(checkboxState?.numberOfInvoicesChecked, data.failedCount, 'Eksportowanie do CSV', BulkOperationsEnum.EXPORT_CSV);
        }
        if (!data.data) return;
        formatFetchedDataToPdf(blob, `plik.csv`);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally();
  };

  function dialogConfirmBulkOperations(numberOfInvoicesChecked: number, invoicesNotConfirmed, dialogTitle: string, action: BulkOperationsEnum) {
    const params = { numberOfInvoicesChecked, invoicesNotConfirmed, dialogTitle, action };
    setDialogConfirmBulkOperationsData(params);
    setOpenConfirmBulkOperationDialog(true);
    fetchTableData();
  }

  const onSetHeaderFilter = (filter: InvoiceStatus[]) => {
    setStatusSelected(filter);
    unregisterCheckboxes(getValues(), setValue);
  };

  const resetAllFilters = () => {
    const filterStatus = companyId ? invoiceAccountantStatusShortOptions : costOwnerStatusOptions;
    filterStatus.forEach(item => {
      setValue(`checkbox-filter-status-${item.value}`, false);
      setStatusSelected([]);
    });
  };

  return (
    <div className={`owner-cost-documents-tab`}>
      <TheDialog
        testcy="costs-delete-confirmation"
        open={openDeleteCostInvoiceDialog}
        onClose={isConfirm => onCloseConfirmDelete(isConfirm, true)}
        approveButtonText="Tak"
        rejectButtonText="Nie"
      >
        <div className="inbox-dialog-container">
          <img src={cleaningPic} alt="cleaning-set" width={111} height={128} />
          <div>Czy na pewno chcesz usunąć fakturę</div>
          <div className="remove-dialog-content-document">
            <div>
              {stashedData?.documentNumber} <span className='question-mark'>?</span>
            </div>
          </div>
          <br />
          {checkCostStatusWhenDelete() && <p>Konieczne jest również ręczne usunięcie faktury z systemu księgowego.</p>}
        </div>
      </TheDialog>
      <TheDialog
        testcy="costs-delete-confirmation"
        open={openDeleteCostFileDialog}
        onClose={isConfirm => onCloseConfirmDelete(isConfirm, false)}
        approveButtonText="Tak"
        rejectButtonText="Nie"
      >
        <div className="inbox-dialog-container">
          <img src={cleaningPic} alt="cleaning-set" width={111} height={128} />
          <div>{dialogMessagesText.constFileDelete}</div>
        </div>
      </TheDialog>

      <TheDialog
        testcy="costs-preview"
        open={openPreviewModal}
        onClose={onClosePreview}
        fullWidth={true}
        fullScreen={true}
        title="Podgląd pliku do zdigitalizowania"
      >
        <SharedFilePreview
          testcy="costs-file-preview"
          fileId={selectedCostDocumentId}
          fileName={selectedCostDocumentName}
          modalParentId="shared-cost-file-preview"
          arrangement="center"
          type="cost"
        />
      </TheDialog>
      <TheDialog
        testcy="confirm-bulk-operation"
        open={openConfirmBulkOperationDialog}
        onClose={() => setOpenConfirmBulkOperationDialog(false)}
        title={dialogConfirmBulkOperationsData?.dialogTitle}
        rejectButtonText={'Zamknij'}
      >
        <div className="dialog-content" style={{ paddingTop: '20px' }}>
          <div className="confirmed">
            {translateAction(dialogConfirmBulkOperationsData?.action)}
            <span className="bold"> {dialogConfirmBulkOperationsData?.numberOfInvoicesChecked - dialogConfirmBulkOperationsData?.invoicesNotConfirmed}</span>
          </div>
          <div className="checked">
            Zaznaczonych:<span className="bold"> {dialogConfirmBulkOperationsData?.numberOfInvoicesChecked}</span>
          </div>
        </div>
      </TheDialog>
      <SharedInvoiceDetails
        invoiceId={costId}
        isAccountant={!!companyId}
        refreshParentData={action => {
          changeSelectedArray(action, costId);
          fetchTableData();
          setUpdateData(false);
        }}
        updateData={updateData}
        type="cost"
      />

      <div className="file-drop-zone-container">
        <FileDropZone
          testcy="costs-file-drop-zone"
          accept={'pdf,png,jpg,jpeg'}
          showMaxTotalSize
          multiple
          compareHashesFetchFn={(hashes: string[]) =>
            compareCostHashes({
              values: hashes,
              companyId: currentCompanyId,
            })
          }
          onChange={addNewCostsDocuments}
          isPending={isPendingAddNewCostDocuments}
        />
      </div>
      <SharedTableList
        testcy="cost-files-list"
        files={costFiles}
        header={{ name: 'Pliki do przekształcenia na format cyfrowy', date: 'Data dodania' }}
        onSetHeaderFilter={onSetHeaderFilter}
        selectedRowId={costId}
        actionMenuItems={actionMenuItems}
        onDigitalizeClick={id => editDigitalizedCost(String(id))}
        onPreviewClick={item => onClickPreview(item)}
        onDeleteClick={item => onClickFileDelete(item)}
        onFilesScroll={onFilesScroll}
        onClickRow={id => openInvoiceDrawer('onlyInvoiceDrawer', String(id))}
        rowsWithoutScroll={8}
        isPending={isFilesPending}
      ></SharedTableList>
      <div className="filter-area">
        <ListSearchBar
          onChange={onFilterChange}
          resetAllFilters={resetAllFilters}
          isPending={isPending}
          setPage={setPage}
          showDatePicker
          showSearchBar
          defaultValues={searchBarDefaultValues}
          ref={listSearchBarRef}
          selectedStatus={statusSelected}
          costAccountingStatuses={companyId ? true : false}
        />
      </div>
      <div className="table-wrapper-g cost-table">
        <TheTableNew
          testcy={testcy}
          header={getTableHeader(
            control,
            getValues,
            setValue,
            onChangeCheckboxHeader,
            checkboxState?.headerCheckboxIsPressed,
            onBulkOperationsInvoice,
            companyId ? selectedCompany : ownerMyCompanyData,
            showAccountingStatus,
            companyId,
            onSetHeaderFilter
          )}
          CustomRowComponents={
            <SelectedInvoiceCustomRow
              testcy={testcy}
              onUnselectAll={onUnselectAll}
              onSelectAll={onSelectAll}
              checkboxState={checkboxState}
              optionsColumnSpan={3}
            />
          }
          rows={rows}
          // clickableRows
          onSelectRow={(id, row) => openInvoiceDrawer('onlyInvoiceDrawer', id)}
          selectedRowId={costId}
          actionMenuItems={menuItemsList}
          defaultSortProp={orderBy}
          defaultSortType={sortDirection}
          onClickMenuItem={onClickMenuItem}
          onClickOpenMenu={onClickOpenMenu}
          onChangeSort={onChangeSort}
          onChangePagination={onChangePagination}
          numberOfRowsChecked={checkboxState?.numberOfInvoicesChecked}
          //pagination
          totalRows={totalRows}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </div>
  );
});
