import React, { useEffect, useState } from 'react';
import { TheDialog } from '@components';
import './sharedCookieInfo.scoped.scss';
import { appConfig } from 'src/appConfig';
import { addHotjarEvent } from '@lib';

const cookieApprovalKey = 'user-cookies-approval';
const acceptValue = 'accept';

export const SharedCookieInfo = () => {
  const [open, setOpen] = useState(false);
  const { domain, name, address, nip, regon, registrationCity, krs } = appConfig.owner;

  useEffect(() => {
    if (!localStorage) return;
    const isApproved = localStorage.getItem(cookieApprovalKey) === acceptValue;
    if (!isApproved) {
      addHotjarEvent('show user cookie info', 'action');
      setOpen(true);
    }
  }, []);

  const onClose = (approval: boolean) => {
    if (!localStorage) return;
    setOpen(false);
    addHotjarEvent(`${approval ? 'user approved cookies policy' : 'user closed cookies policy modal without approve'}`, 'action');
    if (approval) localStorage.setItem(cookieApprovalKey, acceptValue);
  };

  return (
    <TheDialog removeCloseButton fullWidth testcy="cookies" open={open} onClose={onClose} approveButtonText="Akceptuję" className="modal-medium-size-g">
      <div className="cookie-policy-content-wrapper">
        <h1 className="text-align-center">Polityka Cookies</h1>
        <p>
          Poniższa Polityka Cookies określa zasady zapisywania i uzyskiwania dostępu do danych na Urządzeniach Użytkowników korzystających z Serwisu do celów
          świadczenia usług drogą elektroniczną przez Administratora Serwisu.
        </p>

        <h2 className="text-align-center">§ 1 Definicje</h2>
        <ul>
          <li>
            <p>
              <strong>Serwis</strong> - serwis internetowy działający pod adresem
              <span id="serwis">
                <span className="main-text">{domain}</span>
              </span>
            </p>
          </li>
          <li>
            <p>
              <strong>Serwis zewnętrzny</strong> - serwisy internetowe partnerów, usługodawców lub usługobiorców Administratora.
            </p>
          </li>
          <li>
            <p>
              <strong>Administrator</strong> - firma
              <span id="firma">
                <span className="main-text">{name}</span>, prowadząca działalność pod adresem: <span className="main-text">{address}</span>, o nadanym numerze
                identyfikacji podatkowej (NIP): <span className="main-text">{nip}</span>, o nadanym numerze REGON: <span className="main-text">{regon}</span>,
                zarejestrowana w<span className="main-text">{registrationCity}</span>
                pod numerem KRS:<span className="main-text">{krs}</span>
              </span>
              , świadcząca usługi drogą elektroniczną za pośrednictwem Serwisu oraz przechowująca i uzyskująca dostęp do informacji w urządzeniach Użytkownika
            </p>
          </li>
          <li>
            <p>
              <strong>Użytkownik</strong> - osoba fizyczna, dla której Administrator świadczy usługi drogą elektroniczną za pośrednictwem Serwisu.
            </p>
          </li>
          <li>
            <p>
              <strong>Urządzenie</strong> - elektroniczne urządzenie wraz z oprogramowaniem, za pośrednictwem, którego Użytkownik uzyskuje dostęp do Serwisu.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies (ciasteczka)</strong> - dane tekstowe gromadzone w formie plików zamieszczanych na Urządzeniu Użytkownika.
            </p>
          </li>
        </ul>
        <h2 className="text-align-center">§ 2 Rodzaje Cookies</h2>
        <ul>
          <li>
            <p>
              <strong>Cookies wewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez system teleinformatyczny Serwisu.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies zewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez systemy teleinformatyczne Serwisów
              zewnętrznych
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies sesyjne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis lub Serwisy zewnętrzne podczas jednej
              sesji danego Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia Użytkownika.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies trwałe</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis{' '}
              <span id="sz2" className="display-inline">
                {' '}
                lub Serwisy zewnętrzne{' '}
              </span>{' '}
              do momentu ich ręcznego usunięcia. Pliki nie są usuwane automatycznie po zakończeniu sesji Urządzenia, chyba że konfiguracja Urządzenia
              Użytkownika jest ustawiona na tryb usuwanie plików Cookie po zakończeniu sesji Urządzenia.
            </p>
          </li>
        </ul>

        <h2 className="text-align-center">§ 3 Bezpieczeństwo</h2>
        <ul>
          <li>
            <p>
              <strong>Mechanizmy składowania i odczytu</strong> - Mechanizmy składowania i odczytu Cookies nie pozwalają na pobieranie jakichkolwiek danych
              osobowych ani żadnych informacji poufnych z Urządzenia Użytkownika. Przeniesienie na Urządzenie Użytkownika wirusów, koni trojańskich oraz innych
              robaków jest praktycznie niemożliwe.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookie wewnętrzne</strong> - zastosowane przez Administratora Cookie wewnętrzne są bezpieczne dla Urządzeń Użytkowników.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookie zewnętrzne</strong> - za bezpieczeństwo plików Cookie pochodzących od partnerów Serwisu Administrator nie ponosi odpowiedzialności.
              Lista partnerów zamieszczona jest w dalszej części Polityki Cookies.
            </p>
          </li>
        </ul>

        <h2 className="text-align-center">§ 4 Cele, do których wykorzystywane są pliki Cookie</h2>
        <ul id="cele">
          <li>
            <p>
              <strong>
                <span className="main-text">Dane statystyczne</span>
              </strong>
              - Administrator{' '}
              <span id="sz4" className="display-inline">
                {' '}
                oraz Serwisy zewnętrzne{' '}
              </span>
              wykorzystują pliki Cookie do zbierania i przetwarzania danych statystycznych takich jak np. statystyki odwiedzin, statystyki Urządzeń Użytkowników
              czy statystyki zachowań użytkowników. Dane te zbierane są w celu analizy i ulepszania Serwisu.
            </p>
          </li>
        </ul>
        <h2 className="text-align-center">§ 5 Serwisy zewnętrzne</h2>
        <p id="zewinfo">
          <span className="main-text">
            Administrator współpracuje z następującymi serwisami zewnętrznymi, które mogą zamieszczać pliki Cookie na Urządzeniach Użytkownika:
          </span>
        </p>
        <ul id="zewnetrzne">
          <li>
            <p>
              <strong>
                <span className="main-text">Google Analytics</span>
              </strong>
            </p>
            <p>
              <strong>
                <span className="main-text">Hotjar</span>
              </strong>
            </p>
          </li>
        </ul>
        <h2 className="text-align-center">
          § 6 Możliwości określania warunków przechowywania i uzyskiwania dostępu na Urządzeniach Użytkownika przez Serwis{' '}
          <span id="sz6" className="display-inline">
            i Serwisy zewnętrzne
          </span>
        </h2>
        <ul>
          <li>
            <p>
              Użytkownik może w dowolnym momencie, samodzielnie zmienić ustawienia dotyczące zapisywania, usuwania oraz dostępu do danych zapisanych plików
              Cookies.
            </p>
          </li>
          <li>
            <p>
              Informacje o sposobie wyłączenia plików Cookie w najpopularniejszych przeglądarkach komputerowych i urządzeń mobilnych dostępne są na stronie:{' '}
              <a data-testcy="cookies-how-to-disable" href="https://jakwylaczyccookie.pl" target="_blank" rel="noreferrer">
                jak wyłączyć cookie
              </a>
              .
            </p>
          </li>
          <li>
            <p>
              Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do tej pory pliki Cookie, korzystając z narzędzi Urządzenia Użytkownika, za
              pośrednictwem którego Użytkownik korzysta z usług Serwisu.
            </p>
          </li>
        </ul>

        <h2 className="text-align-center">§ 7 Wyłączenie odpowiedzialności</h2>
        <ul>
          <li>
            Administrator stosuje wszelkie możliwe środki w celu zapewnienia bezpieczeństwa danych umieszczanych w plikach Cookie. Należy jednak zwrócić uwagę,
            że zapewnienie bezpieczeństwa tych danych zależy od obu stron, w tym działalności Użytkownika oraz stanu zabezpieczeń urządzenia, z którego
            korzysta.
          </li>
          <li>
            Administrator nie bierze odpowiedzialności za przechwycenie danych zawartych w plikach Cookie, podszycie się pod sesję Użytkownika lub ich
            usunięcie, na skutek świadomej lub nieświadomej działalności Użytkownika, wirusów, koni trojańskich i innego oprogramowania szpiegującego, którymi
            może być zainfekowane Urządzenie Użytkownika.
          </li>
          <li>
            Użytkownicy w celu zabezpieczenia się przed wskazanymi w punkcie poprzednim zagrożeniami powinni stosować się do{' '}
            <span id="cyber_random">
              {' '}
              zasad{' '}
              <a data-testcy="cookies-cybersecurity" href="https://nety.pl/cyberbezpieczenstwo/" target="_blank" rel="noreferrer">
                cyberbezpieczeństwa
              </a>
            </span>
            .
          </li>
          <li>
            Usługi świadczone przez podmioty trzecie są poza kontrolą Administratora. Podmioty te mogą w każdej chwili zmienić swoje warunki świadczenia usług,
            cel oraz wykorzystanie plików cookie. Administrator nie odpowiada, na tyle na ile pozwala na to prawo, za działanie plików cookies używanych przez
            serwisy partnerskie. Użytkownicy w każdej chwili mogą samodzielnie zarządzać zezwoleniami i ustawieniami plików cookie dla każdej dowolnej witryny.
          </li>
        </ul>

        <h2 className="text-align-center">§ 8 Wymagania Serwisu</h2>
        <ul>
          <li>
            <p>Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu Użytkownika może spowodować nieprawidłowe działanie niektórych funkcji Serwisu.</p>
          </li>
          <li>
            <p>
              Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo działające funkcje Serwisu w przypadku gdy Użytkownik ograniczy w jakikolwiek
              sposób możliwość zapisywania i odczytu plików Cookie.
            </p>
          </li>
        </ul>
        <h2 className="text-align-center">§ 9 Zmiany w Polityce Cookies</h2>
        <ul>
          <li>
            <p>Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej Polityki Cookies bez konieczności informowania o tym użytkowników.</p>
          </li>
          <li>
            <p>Wprowadzone zmiany w Polityce Cookies zawsze będą publikowane na tej stronie.</p>
          </li>
          <li>
            <p>Wprowadzone zmiany wchodzą w życie w dniu publikacji Polityki Cookies.</p>
          </li>
        </ul>
      </div>
    </TheDialog>
  );
};
