import React, { useState } from 'react';
import { TextInputNew, TheDialog } from '@components';
import { useForm } from 'react-hook-form';
import { useContractorStore } from '@store';
import { formErrorMsg } from '@utils';

import './sendEmailPopup.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

export const SendEmailPopup = ({ isOpen, setIsOpen }: Props) => {
  const {
    control,
    getValues,
    reset: resetForm,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const [isSendPending, setIsSendPending] = useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(true);

  const { ownerMyCompanyData } = useContractorStore();

  const onFormFieldChange = () => {
    const values = getValues();
    setIsSendDisabled(!(values.email && values.subject && values.body));
  };

  const onCloseFrom = (confirmed: boolean) => {
    if (!confirmed) {
      setIsOpen(false);
      resetForm();
      return;
    }
    sendEmail();
    resetForm();
  };

  const sendEmail = () => {
    if (isSendDisabled) return;
    setIsSendPending(true);

    const { email, subject, body } = getValues();
    document.location = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    setTimeout(() => {
      setIsSendPending(false);
    }, 3000);
  };

  return (
    <TheDialog
      testcy="accountant-email"
      open={isOpen}
      onClose={onCloseFrom}
      title={'Wyślij e-mail do księgowej'}
      approveButtonText={'Wyślij'}
      canApprove={!isSendDisabled}
      rejectButtonText={'Anuluj'}
      className="send-email-to-accountant"
      isActionsPending={isSendPending}
    >
      <form className="form-container">
        <TextInputNew
          testcy="accountant-email-email"
          control={control}
          name="email"
          label={'Adres e-mail'}
          placeholder={'Nie udało się załadować e-maila'}
          width="250px"
          defaultValue={ownerMyCompanyData?.accountingCompany?.email}
          readOnly
        />
        <TextInputNew
          testcy="accountant-email-subject"
          control={control}
          name="subject"
          label={'Temat'}
          width="250px"
          validation={{
            required: formErrorMsg.isRequired,
            onChange: onFormFieldChange,
            validate: v => {
              if (v.length > 100) return formErrorMsg.maxLength(100);
              return null;
            },
          }}
          onBlur={onFormFieldChange}
          onFocus={onFormFieldChange}
        />
        <TextInputNew
          testcy="accountant-email-body"
          control={control}
          name="body"
          label={'Treść'}
          width="400px"
          multiline
          rows="6"
          validation={{
            required: formErrorMsg.isRequired,
            onChange: onFormFieldChange,
            validate: v => {
              if (v.length > 1000) return formErrorMsg.maxLength(1000, v.length);
              return null;
            },
          }}
          onBlur={onFormFieldChange}
          onFocus={onFormFieldChange}
        />
      </form>
    </TheDialog>
  );
};
