import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

interface TaxStore {
  taxValueSetManually: string[] | null;
  setTaxValueSetManually: (data: string[] | null) => void;
}

export const useTaxStore = create<TaxStore>(set => ({
  taxValueSetManually: null,
  setTaxValueSetManually: data => set(() => ({ taxValueSetManually: data })),
}));

if (process.env.NODE_ENV === 'development') mountStoreDevtool('TaxStore', useTaxStore);

// for future use: remember to set setSelectedInvoice as null!!
