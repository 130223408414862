import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
  size?: string | number;
}

export const ErrorTriangleIcon = ({ colorType = '', size = 24 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M19.004 20.1007H4.99575C3.46195 20.1007 2.50321 18.4407 3.26962 17.1118L10.3179 4.89678C11.0872 3.56308 13.0135 3.56794 13.776 4.90552L20.7359 17.1206C21.4926 18.4494 20.5329 20.1007 19.004 20.1007Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.0117 13.2827V10.2715M12.0098 16.328V16.2794" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
