import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FieldValues, UseFormReturn, useFormState } from 'react-hook-form';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { ColumnData, ColumnDataItem, TheAccordion, AutocompleteInput, PaperWithActions } from '@components';
import { focusOnNextFormInput, formErrorMsg, handleApiError, nipRegexp, LabeledAction } from '@utils';
import { ContractorInvoicingDataBase, Option } from '@interfaces';
import { getContractorById } from '@services';
import { checkIsAccountingSettingsOnList, generateContractorAddress, generateContractorData } from './contractorSection.utils';
import { useAccountantStore, useContractorStore, useCostStore, useInvoiceStore } from '@store';
import './contractorSection.scoped.scss';
import { ContractorModalOpenMode } from 'src/hooks/contractor';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  useFormFeatures: Partial<UseFormReturn<FieldValues>>;
  contractorOptions: Option[];
  isPendingContractors: boolean;
  onFilterContractorChanged: (v: string) => void;
  onOpenContractorModal: (mode: ContractorModalOpenMode) => void;
  allowedActions: LabeledAction[];
  date?: string;
}

export const ContractorSection = ({
  open,
  setOpen,
  useFormFeatures,
  contractorOptions,
  isPendingContractors,
  onFilterContractorChanged,
  onOpenContractorModal,
  allowedActions,
  date,
}: Props) => {
  const [addressColumnData, setAddressColumnData] = useState<ColumnDataItem[]>([]);
  const [contractorColumnData, setContractorColumnData] = useState<ColumnDataItem[]>([]);
  const { setSelectedContractor, selectedContractor } = useContractorStore();
  const { isCorrected } = useInvoiceStore();
  const { accountingTemplateStoreOptions, vatTemplateStoreOptions } = useAccountantStore();
  const { setCostAccountingSettings, costAccountingSettings } = useCostStore();

  const { control, setValue, trigger, watch } = useFormFeatures;
  const navigate = useNavigate();
  const {
    errors: { client: clientErr },
  } = useFormState({ control });

  const watchClientId = watch('client');

  useEffect(() => {
    if (selectedContractor) {
      createColumnData(selectedContractor);
    }
  }, [selectedContractor]);

  useEffect(() => {
    const errors = [clientErr];
    const isInvalid = errors.some(e => !!e);
    if (isInvalid && !open) setOpen(true);
  }, [clientErr]);

  useEffect(() => {
    if (!watchClientId) return;
    fetchContractorData(date);
  }, [date]);

  useEffect(() => {
    if (!watchClientId) {
      setSelectedContractor(null, null);
    } else {
      fetchContractorData();
    }
  }, [watchClientId]);

  const fetchContractorData = async (date = null) => {
    try {
      const data = await getContractorById(watchClientId, date);
      if (data) {
        checkIsAccountingSettingsOnList(
          data?.accountingSettings,
          accountingTemplateStoreOptions,
          vatTemplateStoreOptions,
          setValue,
          trigger,
          costAccountingSettings
        );
        setSelectedContractor(data, watchClientId);
        setCostAccountingSettings(null);
      }
    } catch (err) {
      if (err.status === 404 || err.status === 403) {
        navigate('/page-not-found');
        return;
      }
      handleApiError(err.data);
    }
  };
  const createColumnData = (data: ContractorInvoicingDataBase) => {
    const addressData = generateContractorAddress(data);
    const contractorColData = generateContractorData(data);
    setAddressColumnData(addressData);
    setContractorColumnData(contractorColData);
  };

  return (
    <TheAccordion testcy="costs-form-contractor-accordion" open={open} setOpen={setOpen} id="contractor-invoice-form" title="Kontrahent">
      <div className="contractor-section-wrapper">
        <div className={`invoice-contractor-select-area ${isCorrected && 'readonly'} `}>
          <AutocompleteInput
            testcy="costs-form-contractor"
            name={'contractorNameInvoiceEdit'}
            control={control}
            trigger={trigger}
            options={contractorOptions}
            validation={{
              required: formErrorMsg.isRequired,
            }}
            placeholder="Wybierz kontrahenta"
            label="Kontrahent"
            endAdornment={true}
            onChange={v => {
              setValue('client', v?.value);
            }}
            PaperComponent={props => <PaperWithActions testcy="costs-form" {...props} actions={allowedActions} />}
            onKeyDown={event => {
              focusOnNextFormInput(event, 'vatRate-1');
            }}
            isPendingFilter={isPendingContractors}
            onFilterChanged={(filteredValue: string) => onFilterContractorChanged(filteredValue)}
            onPaste={val => {
              if (nipRegexp.test(val)) {
                return val.trim().replaceAll('-', '');
              }
              return val;
            }}
          />
          {selectedContractor && (
            <button type="button" data-testcy="costs-form-contractor-edit" className="edit-contractor-icon" onClick={() => onOpenContractorModal('edit')}>
              <BorderColorIcon />
            </button>
          )}
        </div>
        <div className="contractor-data-columns">
          {selectedContractor && (
            <>
              <ColumnData columnData={addressColumnData} columnTitle="Adres" />
              <ColumnData columnData={contractorColumnData} columnTitle="Dane kontrahenta" />
            </>
          )}
        </div>
      </div>
    </TheAccordion>
  );
};
