import { LoaderIcon } from '@components';
import { FormControl, InputAdornment, InputLabel, TextField } from '@mui/material';
import { HelperTextAlignment, InputFormatFn } from '@utils';
import { isArray } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Control, Controller, FieldValues, RegisterOptions, UseFormWatch } from 'react-hook-form';
import './specialInput.scss';
import { SpecialInputType, getCharWhitelistRegexForType, mergeFormatOnBlurFnsForType, mergeValidationsForType } from './specialInput.utils';
import { FormElementAppearance, FormElementSize } from '../types';

interface Props {
  name: string;
  testcy: string;
  control?: Control;
  width?: string;
  disabled?: boolean;
  readOnly?: boolean;
  type: SpecialInputType;
  size?: FormElementSize;
  appearance?: FormElementAppearance;
  formatFn?: InputFormatFn;
  formatOnBlurFn?: InputFormatFn;
  validation?: RegisterOptions;
  charWhitelist?: string;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onFocus?: (e) => void;
  onPaste?: (value: string) => void;
  isPendingValue?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  helperTextAlign?: HelperTextAlignment;
  adornmentAlignment?: 'auto' | 'center';
  watch?: UseFormWatch<FieldValues>;
}

export const SpecialInput = ({
  name,
  testcy,
  control,
  size = FormElementSize.Medium,
  appearance = FormElementAppearance.Old,
  width = '200px',
  disabled = false,
  readOnly = false,
  validation = {},
  type,
  defaultValue = '',
  charWhitelist,
  label,
  placeholder = '',
  endAdornment,
  startAdornment,
  formatFn,
  formatOnBlurFn,
  onChange,
  onBlur,
  onKeyDown,
  onFocus,
  onPaste,
  isPendingValue = false,
  autoFocus = false,
  helperText,
  helperTextAlign = HelperTextAlignment.Left,
  adornmentAlignment = 'auto',
  watch,
}: Props) => {
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    if (!watch) return;

    const subscription = watch(value => {
      if (!isArray(value.disabledValues)) return;

      setIsDisabled(value.disabledValues.includes(name));
    });

    return () => subscription && subscription.unsubscribe();
  }, [watch, name]);

  const onChangeHandler = (onChangeProvided: (...event: any[]) => void, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    let value = e.target.value;
    if (formatFn) value = formatFn(value);

    const typeWhitelistRegex: RegExp | null = getCharWhitelistRegexForType(type);
    if (typeWhitelistRegex || charWhitelist) value = value.replace(typeWhitelistRegex || new RegExp(`[^${charWhitelist}]`), '');

    onChange?.(value);
    onChangeProvided(value);
  };

  const onBlurHandler = (
    onBlurProvided: (...event: any[]) => void,
    onChangeProvided: (...event: any[]) => void,
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const onBlurFn = mergeFormatOnBlurFnsForType(type, formatOnBlurFn);
    const rawValue = e.target.value;
    const value = onBlurFn(rawValue);

    if (rawValue != value) {
      onChange?.(value);
      onChangeProvided(value);
    }
    onBlur?.(value);
    return onBlurProvided(e);
  };

  const onPasteHandler = (onChangeProvided: (...event: any[]) => void, e: React.ClipboardEvent): void => {
    e.preventDefault();
    const onBlurFn = mergeFormatOnBlurFnsForType(type, formatOnBlurFn);
    const pasteContent = e.clipboardData.getData('Text');
    const result = onBlurFn(pasteContent);

    onPaste?.(result);
    onChange?.(result);
    onChangeProvided(result);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyDown?.(e);
  };

  const getRootInputClasses = (error: any): string => {
    //prettier-ignore
    return [
      'special-input styled-input',
      `helper-text-${error?.message ? 'left' : helperTextAlign}`,
      `adornment-align-${adornmentAlignment}`,
      error?.message ? 'has-error' : '',
      isDisabled ? 'input-disabled' : '',
      readOnly ? 'input-readonly' : '',
    ].join(' ');
  };

  return (
    <div className={`special-input-container appearance-${appearance} size-${size}`} style={{ width, minWidth: width }}>
      <FormControl variant="outlined">
        {label && (
          <InputLabel shrink className="input-label" data-testcy={`${testcy}-label`} disabled={disabled}>
            {label}
          </InputLabel>
        )}
        <Controller
          control={control}
          name={name}
          rules={mergeValidationsForType(type, validation)}
          defaultValue={defaultValue}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <TextField
              autoComplete="off"
              id={name}
              inputProps={{
                'data-testcy': testcy,
              }}
              type="text"
              value={value}
              onChange={e => onChangeHandler(onChange, e)}
              onPaste={e => onPasteHandler(onChange, e)}
              onBlur={e => onBlurHandler(onBlur, onChange, e)}
              onKeyDown={e => onKeyDownHandler(e)}
              onFocus={e => onFocus?.(e)}
              placeholder={placeholder}
              disabled={isDisabled}
              autoFocus={autoFocus}
              helperText={error?.message ?? helperText}
              classes={{ root: getRootInputClasses(error) }}
              InputProps={{
                readOnly: readOnly,
                startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null,
                endAdornment:
                  endAdornment || isPendingValue ? (
                    <InputAdornment position="end">
                      {endAdornment ? endAdornment : ''}
                      {isPendingValue ? <LoaderIcon size="tiny" /> : ''}
                    </InputAdornment>
                  ) : null,
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};
