import { AxiosResponse } from 'axios';
import { httpClient } from '@lib';
import { PagedList } from '@interfaces';

export interface TaxValuesChangeRequest {
  companyId: number;
  month: Date;
  values: TaxValueChange[];
}

export interface TaxValueChange {
  value: number;
  dueDate: string;
  taxDiscriminator: string;
}

export interface GetTaxValuesRequest {
  companyId: number;
  from: Date;
  to: Date;
}

export interface TaxValues {
  month: Date;
  values: TaxValue[];
}

export interface TaxValue {
  value: number;
  dueDate: string;
  taxDiscriminator: string;
  accountingValue?: number;
  isPaid: boolean;
}

export interface TaxValueTableCell {
  revenue: number | null;
  cost: number | null;
  profit: number | null;
}

export interface TaxValueTable {
  month: Date;
  monthly: TaxValueTableCell;
  yearly: TaxValueTableCell;
  cit: number | null;
}

export interface GetTaxValuesTableRequest {
  year: number;
  companyId: number;
}

export interface MarkTaxAsPaidRequest {
  month: Date;
  taxDiscriminator: string;
}

export const updateTaxValues = async (params: TaxValuesChangeRequest) => {
  try {
    await httpClient.post(`/Taxes`, params);
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getTaxValues = async (params: GetTaxValuesRequest) => {
  try {
    const response: AxiosResponse<PagedList<TaxValues>> = await httpClient.get('/Taxes', { params });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getTaxValuesAccounting = async (params: GetTaxValuesTableRequest) => {
  try {
    const response: AxiosResponse<TaxValueTable[]> = await httpClient.get('/Taxes/accounting', { params });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const markTaxAsPaid = async (params: MarkTaxAsPaidRequest) => {
  try {
    await httpClient.post(`/Taxes/MarkAsPaid`, params);
  } catch (e) {
    throw e?.response?.data;
  }
};
