import { httpClient } from '@lib';
import { IntegrationStatus } from '@utils';
import { AxiosResponse } from 'axios';

export interface HoldIntegrationToInsertData {
  isStopped: boolean;
  isProcessing: boolean;
}
export interface IntegrationData {
  isStopped: boolean;
}
export interface HasCredentials {
  hasCredentials: boolean;
}
export interface CredentialsData {
  server: string;
  username: string;
  password: string;
}

export const getInsertIntegrationData = async () => {
  try {
    const response: AxiosResponse<IntegrationStatus> = await httpClient.get('/Accounting/IntegrationStatus');
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const setIntegrationToInsert = async (isStopped: boolean) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Accounting/IntegrationStatus`, { isStopped: isStopped });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
export const getCheckCredentials = async (companyId: number) => {
  try {
    const response: AxiosResponse<boolean> = await httpClient.get(`/Accounting/CheckCredentials`, { params: { companyId } });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const hasCredentials = async () => {
  try {
    const response: AxiosResponse<boolean> = await httpClient.get('/Settings/AreCredentialsAvailable');
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getCredentials = async () => {
  try {
    const response: AxiosResponse<CredentialsData> = await httpClient.get('/Settings');
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const setCredentials = async (params: CredentialsData) => {
  try {
    const response: AxiosResponse = await httpClient.post('/Settings', params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
