import React from 'react';
import { ButtonText, CashIcon, CashflowChart, DashboardCard, DashboardCardHeader, TheDialog } from '@components';
import { useCashflow, useModal } from '@hooks';
import { UpdateBankAccountBalanceForm } from './updateBankAccountBalanceForm/updateBankAccountBalanceForm';

import './cashflowCard.scoped.scss';

const CashflowCardRaw = () => {
  const currentDate = new Date(new Date().toDateString());

  const { cashFlow } = useCashflow(currentDate);

  const { isOpen, openModal, closeModal } = useModal();

  return (
    <DashboardCard>
      <DashboardCardHeader
        icon={<CashIcon />}
        title="Cashflow"
        subtitle="Wpływy i wydatki środków pieniężnych i ich ekwiwalentów w jednostce, jakie nastąpiły w okresie objętym rachunkiem przepływów pieniężnych."
      />
      <div className="bank-account-button-container">
        <ButtonText testcy="cashflow-open-bank-account-balance-modal" content="Zaktualizuj stan konta" onClick={openModal} />
      </div>
      <CashflowChart initialHeight={400} data={cashFlow} />
      <TheDialog testcy="cashflow-bank-account-balance-modal" open={isOpen} onClose={closeModal} title={'Zaktualizuj stan konta'}>
        <UpdateBankAccountBalanceForm onCancel={closeModal} onSubmitted={closeModal} />
      </TheDialog>
    </DashboardCard>
  );
};

export const CashflowCard = React.memo(CashflowCardRaw);
