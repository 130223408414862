import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormReturn, useFormState } from 'react-hook-form';
import { isEqual } from 'lodash';
import { ButtonText, Divider, TableHeaderInterface, TableRowInterface, TheTable } from '@components';
import { InvoiceCalculatedData } from '@services';
import { useInvoiceStore } from '@store';
import './productAndServiceSection.scoped.scss';
import { InvoiceSection } from 'src/components/miscellaneous/invoiceSection/invoiceSection';
import { stringToNumberSecondOptions } from '@utils';
import { ZwSelector } from './zwSelector';
import { CorrectionSummaryTable } from './correctionSummaryTable';
import { InvoiceSummaryTable } from './invoiceSummaryTable';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  useFormFeatures: Partial<UseFormReturn<FieldValues>>;
  rows: TableRowInterface[];
  tableHeader: TableHeaderInterface[];
  removeRow: (rowData: TableRowInterface) => void;
  addRowToTable: (v: boolean) => void;
  netSummary: string;
  vatSummary: string;
  grossSummary: string;
  isPendingRecalculate: boolean;
  hasZW: boolean;
  calculateOriginalInvoiceValuesId?: InvoiceCalculatedData;
}

export const ProductAndServiceSection = ({
  open,
  setOpen,
  useFormFeatures,
  rows,
  tableHeader,
  removeRow,
  addRowToTable,
  netSummary,
  vatSummary,
  grossSummary,
  isPendingRecalculate,
  hasZW,
  calculateOriginalInvoiceValuesId,
}: Props) => {
  const { control } = useFormFeatures;
  const { errors, isSubmitting } = useFormState({ control });
  const [errorsData, setErrorsData] = useState([]);

  const { isCorrected } = useInvoiceStore();

  useEffect(() => {
    const errorsList = Object.keys(errors);
    const fieldPrefixes = ['jm', 'quantity', 'value', 'vatValue', 'name'];
    const filteredErrorList = errorsList.filter(e => {
      if (e === 'valueType') return true;
      let include = false;
      fieldPrefixes.forEach(p => {
        if (e.includes(p)) include = true;
      });
      return include;
    });

    if (isEqual(filteredErrorList, errorsData) && !isSubmitting) return;
    setErrorsData(filteredErrorList);

    const isInvalid = !!filteredErrorList.length;
    if (isInvalid && !open) setOpen(true);
  }, [Object.keys(errors), isSubmitting]);

  const net = stringToNumberSecondOptions(netSummary);
  const vat = stringToNumberSecondOptions(vatSummary);
  const gross = stringToNumberSecondOptions(grossSummary);

  return (
    <InvoiceSection title="Produkty i usługi">
      <div className="products-container">
        <div className="table-container">
          <TheTable
            testcy="invoice-form-product-table"
            rows={rows}
            header={tableHeader}
            actionSettings={{
              cannotDeleteLastRow: !isCorrected,
            }}
            onClickRemove={removeRow}
            showIconActions
          />
        </div>
        <div className="button-container">
          {/* TODO: Temporary implementation. Replace with transparent button when #3022 is merged */}
          <ButtonText
            textTransform="none"
            fontColor="#2540D9"
            fontSize="14px"
            testcy="invoice-form-add-product"
            content="Dodaj kolejny produkt lub usługę"
            isDisabled={false}
            onClick={() => addRowToTable(false)}
          />
        </div>
      </div>
      <Divider />
      {hasZW && (
        <>
          <ZwSelector />
          <Divider />
        </>
      )}
      <div className="summary-container">
        <div className="table-container">
          {isCorrected ? (
            <CorrectionSummaryTable
              isRecalculating={isPendingRecalculate}
              beforeNet={calculateOriginalInvoiceValuesId?.netValue}
              beforeVat={calculateOriginalInvoiceValuesId?.vatValue}
              beforeGross={calculateOriginalInvoiceValuesId?.grossValue}
              correctionNet={Math.max(net - calculateOriginalInvoiceValuesId?.netValue)}
              correctionVat={Math.max(vat - calculateOriginalInvoiceValuesId?.vatValue)}
              correctionGross={Math.max(gross - calculateOriginalInvoiceValuesId?.grossValue)}
              afterNet={net}
              afterVat={vat}
              afterGross={gross}
            />
          ) : (
            <InvoiceSummaryTable isRecalculating={isPendingRecalculate} net={net} vat={vat} gross={gross} />
          )}
        </div>
      </div>
    </InvoiceSection>
  );
};
