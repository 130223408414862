import React from 'react';

type Props = {};

export const ProfitsLossesIcon = (props: Props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66968 11.3183L7.16396 8.07646L10.0091 10.3114L12.45 7.16113"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="15.2952" cy="2.50027" rx="1.60183" ry="1.60183" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.0693 1.60059H5.01286C2.50333 1.60059 0.947266 3.37785 0.947266 5.88739V12.6227C0.947266 15.1322 2.47282 16.9019 5.01286 16.9019H12.183C14.6925 16.9019 16.2486 15.1322 16.2486 12.6227V6.75695"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
