import { UserRolesEnum } from '@utils';
import { User } from 'oidc-react';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import create from 'zustand';

interface UserStore {
  userData: User;
  role: UserRolesEnum;
  setUserData: (data: User) => void;
}

export const useUserStore = create<UserStore>(set => ({
  userData: null,
  role: null,
  setUserData: (data: User) => set(() => ({ userData: data, role: data?.profile?.role as UserRolesEnum })),
}));

if (process.env.NODE_ENV === 'development') mountStoreDevtool('UserStore', useUserStore);
