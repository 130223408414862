import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
  size?: number | string;
  isClosed?: boolean;
}

export const EyeIcon = ({ colorType = '', size = 24, isClosed = false }: Props) => {
  if (isClosed)
    return (
      <svg className={`icon-color-${colorType}-stroke`} width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none">
        <path d="M3.95361 6.23047C6.62028 12.008 14.6203 12.008 17.2869 6.23047" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.33954 9.17969L3.95703 12.4539" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.9072 9.17969L17.2889 12.4539" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.6201 10.5664V13.7881" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  return (
    <svg className={`icon-color-${colorType}-stroke`} width={size} height={size} viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 12.0083C3 15.6303 7.289 19.1103 12 19.1103C16.712 19.1103 21 15.6303 21 12.0083C21 8.38626 16.674 4.90625 12 4.90625C7.327 4.90625 3 8.38626 3 12.0083Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0788 12.0067C15.0788 10.3077 13.7008 8.92969 12.0018 8.92969C10.3038 8.92969 8.92578 10.3077 8.92578 12.0067C8.92578 13.7047 10.3038 15.0827 12.0018 15.0827C13.7008 15.0827 15.0788 13.7047 15.0788 12.0067Z"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
