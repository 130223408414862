import { httpClient } from '@lib';
import { AxiosResponse } from 'axios';
import { EmployeesOrderOptionsEnum, EmployeesStatusEnum, SortDirection } from '@utils';
import { Address } from '@interfaces';
import { DuplicatedFile } from '@components';
import { EmployeeFileHashesRequest } from 'src/interfaces/shared';

export interface GetEmployeesParams {
  textFilter?: string;
  companyId?: number;
  orderBy?: EmployeesOrderOptionsEnum;
  direction?: SortDirection;
  pageNumber?: number;
  pageSize?: number;
}

export interface EmployeesList {
  data: EmployeesListData[];
  pageNumber: number;
  totalPageCount: number;
  pageSize: number;
  totalCount: number;
}

export const EmployeesContractEnum = {
  EmploymentContract: 'EmploymentContract',
  CivilLawContract: 'CivilLawContract',
} as const;
export type EmployeesContractEnum = typeof EmployeesContractEnum[keyof typeof EmployeesContractEnum];

export interface EmployeesListData {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  address: string;
  pesel: string;
  position: string;
  typeOfEmployment: EmployeesContractEnum;
  contractStartDate: string;
  contractEndDate: string;
  status?: EmployeesStatusEnum;
}

interface _BaseEmployeeData {
  firstName: string;
  lastName: string;
  pesel: string;
  email?: string;
  phone?: string;
  position: string;
  typeOfEmployment: EmployeesContractEnum;
  contractStartDate: string | Date;
  contractEndDate: string | Date;
}
export interface EmployeeFormData extends _BaseEmployeeData {
  grossSalary: string;
  city: string;
  street: string;
  zipCode: string;
}

export interface EmployeeData extends _BaseEmployeeData {
  grossSalary: number;
  companyId?: number;
  address: Address;
  status?: EmployeesStatusEnum;
}

export interface EmployeeFileCreationResultItem {
  id?: number;
  originalFilename: string;
  message?: string;
  isSuccessful: boolean;
}

export interface FilesDetails {
  id: number;
  uploadDate: string;
  originalFilename: string;
  byteSize: number;
}
export interface EmployeeDetails extends EmployeeData {
  id?: number;
  files?: FilesDetails[];
}

export interface EmployeeEditFormData {
  firstName: string;
  lastName: string;
  pesel: string;
  city: string;
  zipCode: string;
  street: string;
  typeOfEmployment: EmployeesContractEnum;
  grossSalary: number;
  contractStartDate?: string | Date;
}

export const getEmployees = async (params: GetEmployeesParams = {}) => {
  try {
    const response: AxiosResponse<EmployeesList> = await httpClient.get('/Employees', { params });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const createNewEmployee = async (employeeData: EmployeeData) => {
  try {
    const response: AxiosResponse = await httpClient.post('/Employees', employeeData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getEmployeeDataById = async (id: number) => {
  try {
    const response = await httpClient.get<EmployeeDetails>(`/Employees/${id}`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const updateEmployee = async (data: { employeeData: EmployeeEditFormData; id: number }) => {
  try {
    const response = await httpClient.put<EmployeeDetails>(`/Employees/${data.id}`, data.employeeData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const createEmployeeNewDocuments = async (files: FormData, id: number) => {
  try {
    const response = await httpClient.post<EmployeeFileCreationResultItem[]>(`/Employees/${id}/files`, files);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const deleteEmployeeDocument = async (id: number) => {
  try {
    const response = await httpClient.delete(`/Employees/files/${id}`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getEmployeeDownloadImgById = async (id: string) => {
  try {
    const response: AxiosResponse<Blob> = await httpClient.get(`/Employees/files/${id}`, { responseType: 'blob' });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const unregisterEmployee = async (id: number) => {
  try {
    const response = await httpClient.put(`/Employees/${id}/deregister`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const compareEmployeeFileHashes = async (body: EmployeeFileHashesRequest) => {
  try {
    const response: AxiosResponse<DuplicatedFile[]> = await httpClient.post(`/Employees/files/hashes`, body);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
