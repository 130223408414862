import React, { ChangeEvent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { RadioGroup } from '@mui/material';
import { RadioButton } from './radioButton';
import { RadioButtonList } from '@interfaces';
import './radioButtonGroup.scss';

interface Props {
  control?: Control;
  testcy: string;
  name: string;
  radioBtnList: RadioButtonList[];
  defaultValue?: string;
  horizontal?: boolean;
  spacing?: string;
  margin?: string;
  isHorizontal?: boolean;
  onChangeHandle?: (value: string) => void;
}

export const RadioButtonGroup = ({
  control,
  testcy,
  name,
  radioBtnList,
  defaultValue = '',
  spacing = '0px',
  margin = '0px',
  isHorizontal = false,
  onChangeHandle,
}: Props) => {
  const onChangeValue = (onChange: (...event: any[]) => void, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const value = e.target.value;
    if (onChangeHandle) onChangeHandle(value);
    if (!value) onChange(e);

    onChange(e);
  };
  return (
    <div className="radio-button-group-component" style={{ margin: margin }}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <RadioGroup
            row={isHorizontal}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              onChangeValue(onChange, e);
            }}
            value={value}
            name={name}
            style={{ gap: spacing }}
            data-testcy={testcy}
          >
            {radioBtnList.map(btnData => (
              <RadioButton
                testcy={`${testcy}-radio-${btnData.value}`}
                disabled={btnData.disabled}
                key={`${btnData.value}-${btnData.label}`}
                value={btnData.value}
                label={btnData.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </div>
  );
};
