import { Client } from '@amityco/ts-sdk';
import { useEffect, useState } from 'react';

export const useAmitySessionState = () => {
  const [sessionState, setSessionState] = useState('notLoggedIn');

  useEffect(() => {
    return Client.onSessionStateChange((state: Amity.SessionStates) => setSessionState(state));
  });

  return {
    sessionState,
    isConnected: sessionState === 'established',
  };
};
