import { ArrowIcon, ButtonIconNew, ButtonMainNew, LoaderIcon, PageLoaderWrapper, TableHeaderInterface, TableRowInterface } from '@components';
import { useInvoiceDrawerController, useIsAllFalse } from '@hooks';
import { BankAccountData, Option, RequestErrorMessage } from '@interfaces';
import { addHotjarEvent, dateFormatter, invalidateApiQuery } from '@lib';
import {
  GetInvoiceNumberParams,
  GetInvoiceSumValueParams,
  InvoiceCalculatedData,
  InvoiceUnitCalculation,
  SaveInvoiceRequest,
  createInvoice,
  createInvoiceCorrection,
  editInvoice,
  editInvoiceCorrection,
  getContractorById,
  getInvoiceById,
  getInvoiceSumValues,
  getInvoicesNextNumber,
  getMyCompany,
  setAccountAsMain,
} from '@services';
import { useContractorStore, useInvoiceStore, useUserStore } from '@store';
import {
  InvoiceNumberFormat,
  PaymentType,
  formatNumberToCurrency,
  handleApiError,
  isArraysEqual,
  paymentTypeOptions,
  scrollToFirstError,
  snackbarMessagesHandler,
  stringToNumberSecondOptions,
} from '@utils';
import { addDays } from 'date-fns';
import { isEqual, isUndefined } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ContractorSection, InvoiceDataSection, ModalAndDialogs, PaymentSection, ProductAndServiceSection } from './components';
import './ownerInvoice.scoped.scss';
import {
  InvoiceFormData,
  calculateDueDate,
  checkInvoiceDatePeriodChange,
  checkInvoiceNameHasSuffix,
  createSubmitCorrectData,
  createSubmitData,
  generateBankAccountsOptions,
  generateRow,
  generateSuffixesOptions,
  getValuesToCalculateSummary,
  setCalculatedValuesToInvoice,
  setCloneFormData,
  setFormData,
  tableHeaderGenerate,
  transformOriginalCorrectedInvoiceItems,
} from './ownerInvoice.utils';
import { ContractorModalOpenMode, useContractorSection } from 'src/hooks/contractor';

const formatDate = (date: Date): string => dateFormatter.objectToString(date, "yyyy-MM-dd'T'HH:mm:ss");

const currentDateFormated = new Date().toISOString().substring(0, 10) + 'T00:00:00';

export const OwnerInvoice = () => {
  const [tableHeader, setTableHeader] = useState<TableHeaderInterface[]>([]);
  const [rows, setRows] = useState<TableRowInterface[]>([]);
  const [lastNumberId, setLastNumberId] = useState(1);
  const [contractorOptionsEditedInvoice, setContractorOptionsEditedInvoice] = useState([]);
  const [netSummary, setNetSummary] = useState('0,00');
  const [vatSummary, setVatSummary] = useState('0,00');
  const [grossSummary, setGrossSummary] = useState('0,00');
  const [amountPaid, setAmountPaid] = useState('0,00');
  const [leftToPaid, setLeftToPaid] = useState('0,00');
  const [isBlurred, setIsBlurred] = useState(true);
  const [invoiceNameType, setInvoiceNameType] = useState<InvoiceNumberFormat>();
  const [bankAccountsData, setBankAccountsData] = useState<BankAccountData[]>([]);
  const [cancelConfirmationDialogOpen, setCancelConfirmationDialogOpen] = useState(false);
  const [startInvoiceData, setStartInvoiceData] = useState<InvoiceFormData>(null);
  const [paymentTypeCurrent, setPaymentTypeCurrent] = useState<PaymentType | null>(PaymentType.Transfer);
  const [disableBankAccount, setHideBankAccount] = useState<boolean>(null);
  const [openBankAccountModal, setOpenBankAccountModal] = useState(false);
  const [contractorWasModified, setContractorWasModified] = useState(false);

  const isPaid = paymentTypeCurrent === PaymentType.Paid;

  const [lastPaymentValuesSent, setLastPaymentValuesSent] = useState([]);
  const [paymentValuesToSend, setPaymentValuesToSend] = useState<GetInvoiceSumValueParams[]>([]);

  const [companyName, setCompanyName] = useState('');
  const [suffixesOptions, setSuffixesOptions] = useState<Option[]>([]);
  const [bankAccountsOptions, setBankAccountOptions] = useState<Option[]>([]);
  const [bankAccountDataSet, setBankAccountDataSet] = useState(false);

  const [isPendingInvoiceNameValue, setIsPendingInvoiceNameValue] = useState(false);
  const [isPendingRecalculate, setIsPendingRecalculate] = useState(false);
  const [isPendingInvoiceData, setIsPendingInvoiceData] = useState(false);
  const [isPendingSubmit, setIsPendingSubmit] = useState(false);

  const [basicSectionOpen, setBasicSectionOpen] = useState(true);
  const [contractorSectionOpen, setContractorSectionOpen] = useState(true);
  const [prodServiceSectionOpen, setProdServiceSectionOpen] = useState(true);
  const [paymentSectionOpen, setPaymentSectionOpen] = useState(true);
  const [status, setStatus] = useState('');
  const [issueDate, setIssueDate] = useState(currentDateFormated);
  const [editContractor, setEditContractor] = useState(false);
  const [isDeletedRow, setIsDeletedRow] = useState(false);
  const [fieldRecalculationNeeded, setFieldRecalculationNeeded] = useState(false);
  const [hasZW, setHasZW] = useState(false);
  const [invoiceIsCloned, setInvoiceIsCloned] = useState(false);
  const [invoiceIsCorrected, setInvoiceIsCorrected] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [minDate, setMinDate] = useState('');
  const [cloneId, setCloneId] = useState('');
  const [calculateOriginalInvoiceValuesId, setCalculateOriginalInvoiceValues] = useState(null);
  const [updateData, setUpdateData] = useState(false);

  const { role } = useUserStore();
  const { setSelectedContractor, ownerMyCompanyData, contractorsList, setOwnerMyCompanyData } = useContractorStore();
  const { selectedInvoice, setSelectedInvoice, isClone, isCorrected, setIsCorrected, isFromDashboard, isFavoriteClicked } = useInvoiceStore();
  const { openInvoiceDrawer } = useInvoiceDrawerController('invoice');
  const { id, action, correctionId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const {
    control,
    setValue,
    unregister,
    getValues,
    handleSubmit,
    trigger,
    setError,
    clearErrors,
    watch,
    register,
    reset,
    resetField,
    formState: { isDirty, isValid, errors },
  } = form;
  const isLoadingPage = useIsAllFalse([!ownerMyCompanyData || isPendingInvoiceData], 500);
  const favoriteBankAccount = watch('main-bank-account');
  const invoicePaidAmount = watch('paidAmount');
  const invoiceSuffix = watch('suffix');

  const {
    search: contractorSearch,
    modalController: contractorModalController,
    formDefaults: contractorFormDefaults,
    allowedActions: contractorAllowedActions,
  } = useContractorSection({
    defaultOptions: contractorOptionsEditedInvoice,
    clientId: watch('client'),
    clientName: watch('contractorNameInvoiceEdit'),
  });

  const onCreatedContractor = (closeModal: boolean, id: string, name: string) => {
    contractorSearch.setValue(name);
    setValue('client', id);
    setValue('selectOptionId', id);
    setValue('contractorNameInvoiceEdit', name);
    addHotjarEvent('user created contractor from invoice view ', 'action');
    invalidateApiQuery('getContractors');
    contractorModalController.closeModal();
  };

  const openContractorModal = (mode: ContractorModalOpenMode) => {
    setEditContractor(mode === 'edit');
    contractorModalController.openModal(mode);
  };

  // default effect/ctr
  useEffect(() => {
    const header = tableHeaderGenerate();
    setTableHeader(header);
    register('main-bank-account');
    if (!id) addRowToTable(true);
    register('disabledValues');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUndefined(issueDate)) return;
    if (!isUndefined(id) || action === 'edit' || action === 'correction-edit') return;
    const isTransfer = paymentTypeCurrent === PaymentType.Transfer || paymentTypeCurrent === PaymentType.TransferSplitPayment;

    const date = dateFormatter.getDateObjectFromString(issueDate, "yyyy-MM-dd'T'HH:mm:ss");
    const newDeliveryDate = date;
    const newDueDate = isTransfer ? addDays(date, 14) : date;

    if (action !== 'correction') setValue('deliveryDate', newDeliveryDate);
    setValue('dueDate', newDueDate);
  }, [issueDate]);

  useEffect(() => {
    if (correctionId && action) {
      setAmountPaid('0,00');
      setIsCorrected(true);
      fetchInvoiceDataForCorrection(correctionId, action);
      setInvoiceIsCorrected(true);
    }
  }, [correctionId, action]);

  useEffect(() => {
    if (searchParams.has('clone')) {
      fetchInvoiceDataForClone(searchParams.get('clone'));

      setInvoiceIsCloned(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (action === 'clone' && id) {
      setCloneId(id);
    }
  }, [action, id]);

  useEffect(() => {
    if (isBlurred) {
      setAmountPaid(invoicePaidAmount);
    }
  }, [invoicePaidAmount, isBlurred]);

  useEffect(() => {
    if (invoiceSuffix) {
      const isInSuffixOptions = ownerMyCompanyData.invoiceNumberSuffixes.some(suffix => suffix.value === invoiceSuffix);
      if (!isInSuffixOptions) {
        const suffixOptionsUpdated = [...ownerMyCompanyData.invoiceNumberSuffixes, { value: invoiceSuffix }];
        const options = generateSuffixesOptions(suffixOptionsUpdated);
        setSuffixesOptions(options);
      }
    }
  }, [invoiceSuffix]);

  useEffect(() => {
    if (!isLoadingPage && !startInvoiceData && !id) refreshStartData();
  }, [isLoadingPage]);

  useEffect(() => {
    if (bankAccountDataSet && isFavoriteClicked) setMainAccountRequest(favoriteBankAccount);
    const transformedBankAccounts = ownerMyCompanyData?.bankAccounts.map(bank => {
      return { number: bank.number, bankName: bank.bankName, mainAccount: bank.number === favoriteBankAccount };
    });
    const ownerData = { ...ownerMyCompanyData, bankAccounts: transformedBankAccounts };
    setOwnerMyCompanyData(ownerData);
  }, [favoriteBankAccount]);

  useEffect(() => {
    createBankAccountOptions();
  }, [disableBankAccount]);

  useEffect(() => {
    if (ownerMyCompanyData) {
      createSuffixOptions();
      createBankAccountOptions();
      findAndCompanyDetails();
    }
  }, [ownerMyCompanyData]);

  useEffect(() => {
    const isTheSameValue = isArraysEqual(paymentValuesToSend, lastPaymentValuesSent);
    const isAnyValueChanges = !isTheSameValue;
    const isValues = paymentValuesToSend.length;

    if (isAnyValueChanges && isValues) fetchInvoiceValues(paymentValuesToSend);
  }, [paymentValuesToSend]);

  useEffect(() => {
    if (!invoiceIsCloned) return;

    setAmountPaid('0,00');
    setValue('paidAmount', '0,00');
    setLeftToPaid(formatNumberToCurrency(stringToNumberSecondOptions(grossSummary) || 0));
  }, [invoiceIsCloned]);

  useEffect(() => {
    if (grossSummary && !isPaid) {
      trigger('paidAmount');
      if (isCorrected) {
        setAmountPaid('0,00');
        setLeftToPaid(
          formatNumberToCurrency(
            Math.max(stringToNumberSecondOptions(grossSummary) - calculateOriginalInvoiceValuesId?.grossValue - stringToNumberSecondOptions(amountPaid))
          )
        );
      } else {
        setAmountPaid(watch('paidAmount'));
        setLeftToPaid(formatNumberToCurrency(stringToNumberSecondOptions(grossSummary) - stringToNumberSecondOptions(amountPaid)));
      }
    }
    if (isPaid && grossSummary) {
      setValue('paidAmount', '0,00');
      if (isCorrected) {
        setAmountPaid(formatNumberToCurrency(Math.max(stringToNumberSecondOptions(grossSummary) - calculateOriginalInvoiceValuesId?.grossValue)));
        setLeftToPaid('0,00');
      } else {
        setAmountPaid(grossSummary);
        setLeftToPaid('0,00');
      }
    }
  }, [isPaid, grossSummary, amountPaid, leftToPaid, calculateOriginalInvoiceValuesId]);

  useEffect(() => {
    if (paymentTypeCurrent) {
      const isCardOrFullyPaid = paymentTypeCurrent === PaymentType.Card || paymentTypeCurrent === PaymentType.Paid;
      const isTransfer = paymentTypeCurrent === PaymentType.Transfer || paymentTypeCurrent === PaymentType.TransferSplitPayment;
      setHideBankAccount(!isTransfer);

      if (isTransfer && !startInvoiceData?.bankNumber && !watch('bankAccount')) {
        const mainAccount = ownerMyCompanyData?.bankAccounts?.find(bankAccount => bankAccount.mainAccount);
        if (mainAccount) {
          setValue('bankNumber', mainAccount.number);
        }
      }

      const values = getValues();
      if (!isTransfer && !values.bankNumber) clearErrors('bankNumber');
      if (isCardOrFullyPaid && !values.dueDate) clearErrors('dueDate');
    }
  }, [paymentTypeCurrent, ownerMyCompanyData]);

  const submitCreateInvoice = (submitData: SaveInvoiceRequest, redirectToList: boolean) => {
    addHotjarEvent('save invoice');
    setIsPendingSubmit(true);
    createInvoice(submitData)
      .then(data => {
        setSelectedInvoice(null);
        snackbarMessagesHandler.invoiceCreatedSuccess();
        if (isFromDashboard) {
          setSelectedContractor(null, null);
        }
        if (redirectToList) navigate('/invoices-list');
        else {
          openInvoiceDrawer('invoiceDrawerAndInvoicePreview', String(data));
          isClone ? navigate(`/invoices-list/edit-invoice/clone/${data}`) : navigate(`/invoices-list/edit-invoice/edit/${data}`);

          refreshStartData();
        }
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingSubmit(false));
  };
  const submitCreateInvoiceCorrection = (submitData: SaveInvoiceRequest, redirectToList: boolean) => {
    addHotjarEvent('create invoice correction');
    setIsPendingSubmit(true);
    createInvoiceCorrection(submitData, correctionId)
      .then(data => {
        setSelectedInvoice(null);
        snackbarMessagesHandler.invoiceCorrectionCreatedSuccess();
        if (redirectToList) navigate('/invoices-list');
        else {
          navigate(`/invoices-list/edit-invoice-correction/correction-edit/${data}`);

          refreshStartData();
          openInvoiceDrawer('invoiceDrawerAndInvoicePreview', String(data));
        }
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingSubmit(false));
  };
  const submitEditInvoiceCorrection = (submitData: SaveInvoiceRequest, redirectToList: boolean) => {
    addHotjarEvent('edit invoice correction');
    setIsPendingSubmit(true);
    editInvoiceCorrection(submitData, correctionId)
      .then(data => {
        setSelectedInvoice(null);
        snackbarMessagesHandler.invoiceEdited();
        if (redirectToList) navigate('/invoices-list');
        else {
          refreshStartData();
          openInvoiceDrawer('invoiceDrawerAndInvoicePreview', String(data));
        }
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingSubmit(false));
  };

  const submitEditInvoice = (submitData: SaveInvoiceRequest, redirectToList: boolean) => {
    addHotjarEvent('edit invoice');
    setIsPendingSubmit(true);
    editInvoice(submitData, id)
      .then(() => {
        setSelectedInvoice(null);
        snackbarMessagesHandler.invoiceEdited();
        if (redirectToList) {
          return navigate('/invoices-list');
        } else {
          refreshStartData();
          openInvoiceDrawer('invoiceDrawerAndInvoicePreview', id);
        }
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingSubmit(false));
  };

  const fetchInvoiceDataForClone = useCallback(async (invoiceId: string) => {
    const idNum = +invoiceId;

    if (isNaN(idNum)) {
      return;
    }

    setIsPendingInvoiceData(true);

    try {
      const myCompany = await getMyCompany();
      setOwnerMyCompanyData(myCompany);

      const data = await getInvoiceById(idNum);
      if (data.bankAccount) {
        createAdditionalBankAccountOptions(data.bankAccount, myCompany.bankAccounts);
      }
      setValue('client', data.contractorId);
      if (data?.vatExemptionInfo?.exemptionType) {
        setHasZW(true);
      }
      const isContractorOnList = contractorsList.some(contractor => {
        return contractor.id === data.contractorId;
      });

      if (!isContractorOnList && data.contractorId) {
        const contractor = await getContractorById(data.contractorId);
        contractorsList.push({
          id: data.contractorId,
          name: contractor.friendlyName || contractor.name,
          fullName: contractor.name,
        });
        const contractorsListPlusDeleteContractor = contractorsList.map(contr => {
          return { label: contr.name, value: contr.id };
        });
        setContractorOptionsEditedInvoice(contractorsListPlusDeleteContractor);
      }

      setCloneFormData(data, setValue, addMultipleRows, contractorsList);
      setValue('paidAmount', '0,00');
      setAmountPaid('0,00');
      setPaymentTypeCurrent(data.paymentMethod);
      setIssueDate(new Date().toISOString().slice(0, 19));
      const suffixName = checkInvoiceNameHasSuffix(data.number, data.numberFormat, setValue);

      fetchInvoiceName(suffixName);

      const mainAccount = ownerMyCompanyData?.bankAccounts?.find(bankAccount => bankAccount.mainAccount);
      if (mainAccount) {
        setValue('main-bank-account', mainAccount.number);
      }

      onRecalculate(null, null, true);
    } catch (error) {
      if (error.status === 404 || error.status === 403) {
        navigate('/page-not-found');
        return;
      }
      handleApiError(error.data);
    } finally {
      setIsPendingInvoiceData(false);
      refreshStartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInvoiceDataForCorrection = useCallback(async (invoiceId: string, actionArgument: string) => {
    const idNum = +invoiceId;

    if (isNaN(idNum)) {
      return;
    }

    setIsPendingInvoiceData(true);

    try {
      const myCompany = await getMyCompany();
      setOwnerMyCompanyData(myCompany);

      const data = await getInvoiceById(idNum);

      const deliveryDate = data.deliveryDate ? dateFormatter.getDateObjectFromString(data.deliveryDate, "yyyy-MM-dd'T'HH:mm:ss") : '';
      setValue('client', data.contractorId);

      if (data?.vatExemptionInfo?.exemptionType) {
        setHasZW(true);
      }

      if (data.bankAccount) {
        createAdditionalBankAccountOptions(data.bankAccount, myCompany.bankAccounts);
      }
      const isContractorOnList = contractorsList.some(contractor => {
        return contractor.id === data.contractorId;
      });

      if (!isContractorOnList && data.contractorId) {
        const contractor = await getContractorById(data.contractorId);
        contractorsList.push({
          id: data.contractorId,
          name: contractor.friendlyName || contractor.name,
          fullName: contractor.name,
        });
        const contractorsListPlusDeleteContractor = contractorsList.map(contr => {
          return { label: contr.name, value: contr.id };
        });
        setContractorOptionsEditedInvoice(contractorsListPlusDeleteContractor);
      }
      register('prefix');
      setValue('prefix', 'FK');
      setAmountPaid('0,00');
      if (actionArgument === 'correction-edit') {
        const dataOriginalCorrectedInvoice = await getInvoiceById(data.correctedInvoiceId);

        setValue('correctedInvoiceReason', data.correctionReason);

        const issueDate = data.issueDate ? dateFormatter.getDateObjectFromString(data.issueDate, "yyyy-MM-dd'T'HH:mm:ss") : '';
        const dueDate = data.dueDate ? dateFormatter.getDateObjectFromString(data.dueDate, "yyyy-MM-dd'T'HH:mm:ss") : '';

        setValue('deliveryDate', deliveryDate);
        setValue('dueDate', dueDate);
        setValue('invoiceDate', issueDate);
        setMinDate(dataOriginalCorrectedInvoice.issueDate);
        setNumberOfItems(dataOriginalCorrectedInvoice.invoiceItems.length);

        const transformedItems = transformOriginalCorrectedInvoiceItems(dataOriginalCorrectedInvoice.invoiceItems);
        const calculateOriginalInvoice: InvoiceCalculatedData = await getInvoiceSumValues(transformedItems);
        setCalculateOriginalInvoiceValues(calculateOriginalInvoice || 0);
        if (data.numberFormat === InvoiceNumberFormat.Yearly) {
          const indexOfNumber = data.number.indexOf('/');
          const invoiceName = data.number.slice(3, indexOfNumber + 5);
          const invoiceSuffix = data.number.slice(indexOfNumber + 6);

          setValue('invoiceName', invoiceName);
          if (invoiceSuffix.length) setValue('suffix', invoiceSuffix);
        } else {
          const indexOfNumber = data.number.indexOf('/');
          const invoiceName = data.number.slice(3, indexOfNumber + 8);
          const invoiceSuffix = data.number.slice(indexOfNumber + 9);

          setValue('invoiceName', invoiceName);
          if (invoiceSuffix.length) setValue('suffix', invoiceSuffix);
        }
      } else {
        const suffixName = checkInvoiceNameHasSuffix(data.number, data.numberFormat, setValue);
        setValue('deliveryDate', deliveryDate);
        setValue('invoiceDate', new Date());
        setIssueDate(new Date().toISOString().slice(0, 19));
        setMinDate(data.issueDate);
        setNumberOfItems(data.invoiceItems.length);
        fetchInvoiceName(suffixName, false, 'FK');
        setValue('correctedInvoiceReason', '');

        const transformedItems = transformOriginalCorrectedInvoiceItems(data.invoiceItems);
        const calculateOriginalInvoice: InvoiceCalculatedData = await getInvoiceSumValues(transformedItems);
        setCalculateOriginalInvoiceValues(calculateOriginalInvoice || 0);
      }

      setCloneFormData(data, setValue, addMultipleRows, contractorsList, actionArgument);
      setPaymentTypeCurrent(data.paymentMethod);

      const mainAccount = ownerMyCompanyData?.bankAccounts?.find(bankAccount => bankAccount.mainAccount);
      if (mainAccount) {
        setValue('main-bank-account', mainAccount.number);
      }

      onRecalculate(null, null, true);
    } catch (error) {
      if (error.status === 404 || error.status === 403) {
        navigate('/page-not-found');
        return;
      }
      handleApiError(error.data);
    } finally {
      setIsPendingInvoiceData(false);
      refreshStartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInvoiceData = useCallback(async (invoiceId: string) => {
    const idNum = +invoiceId;

    if (isNaN(idNum)) return;
    setIsPendingInvoiceData(true);

    try {
      const myCompany = await getMyCompany();
      setOwnerMyCompanyData(myCompany);

      const data = await getInvoiceById(idNum);

      setSelectedInvoice(data);

      if (data.bankAccount) {
        createAdditionalBankAccountOptions(data.bankAccount, myCompany.bankAccounts);
      }

      if (data?.vatExemptionInfo?.exemptionType) {
        setHasZW(true);
      }
      const isContractorOnList = contractorsList.some(contractor => {
        return contractor.id === data.contractorId;
      });

      if (!isContractorOnList && data.contractorId) {
        const contractor = await getContractorById(data.contractorId);
        contractorsList.push({
          id: data.contractorId,
          name: contractor.friendlyName || contractor.name,
          fullName: contractor.name,
        });
        const contractorsListPlusDeleteContractor = contractorsList.map(contr => {
          return { label: contr.name, value: contr.id };
        });
        setContractorOptionsEditedInvoice(contractorsListPlusDeleteContractor);
      }

      setIssueDate(data.issueDate);
      setFormData(data, setValue, addMultipleRows, contractorsList);
      setStatus(data.status);
      setPaymentTypeCurrent(data.paymentMethod);
      onRecalculate(null, null, true);
    } catch (error) {
      if (error.status === 404 || error.status === 403) {
        navigate('/page-not-found');
        return;
      }
      handleApiError(error.data);
    } finally {
      setIsPendingInvoiceData(false);
      refreshStartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (action === 'clone' || cloneId === id) return;
    if (action === 'correction') return;
    if (id) {
      fetchInvoiceData(id);
      const mainAccount = ownerMyCompanyData?.bankAccounts?.find(bankAccount => bankAccount.mainAccount);
      if (mainAccount) {
        setValue('main-bank-account', mainAccount.number);
      }
    } else setDefaultFormData();
  }, [fetchInvoiceData, id, action]);

  const fetchInvoiceName = (suffix: string = null, isSuffixChange: boolean = null, prefix: string = null) => {
    const formValues = getValues();
    setIsPendingInvoiceNameValue(true);

    const params: GetInvoiceNumberParams = {
      issueDate: formValues.invoiceDate ? dateFormatter.objectToString(formValues.invoiceDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS") : null,
      suffix: formValues.suffix ? null : '',
      prefix: formValues.prefix ? null : '',
    };

    if (suffix || suffix === '') {
      params.suffix = suffix;
    } else if (formValues.suffix) {
      params.suffix = formValues.suffix;
    }

    if (formValues.prefix) {
      params.prefix = formValues.prefix;
    }

    getInvoicesNextNumber(params)
      .then((name: string) => {
        checkInvoiceDatePeriodChange(selectedInvoice?.number, formValues?.invoiceDate, name, setValue, id || correctionId, suffix, isSuffixChange, action);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        trigger('invoiceName');
        setIsPendingInvoiceNameValue(false);
      });
  };

  const checkIfRecalculationNeeded = (valueChange: InvoiceUnitCalculation | null, rowId?: string): boolean => {
    let beforeChange = getValues(`onFocus-${valueChange}-${rowId}`);
    let afterChange = getValues(`onBlur-${valueChange}-${rowId}`);
    if (valueChange === 'VatRate' && afterChange === '') {
      afterChange = beforeChange;
    }

    const bothDefinedAndSame = afterChange === beforeChange && afterChange !== undefined && beforeChange !== undefined;
    const valueNotChanged = afterChange === undefined;

    if (bothDefinedAndSame || valueNotChanged) {
      setFieldRecalculationNeeded(false);
      return false;
    }

    return true;
  };

  const onRecalculate = (valueChange: InvoiceUnitCalculation | null, rowId?: string, force?: boolean) => {
    if (!force) {
      const skipRecalculation = !checkIfRecalculationNeeded(valueChange, rowId);
      if (skipRecalculation) {
        return;
      }
    }
    const formValues = getValues();
    const values = getValuesToCalculateSummary(formValues, valueChange, rowId);
    setPaymentValuesToSend(values);
    if (valueChange === 'VatRate') {
      const asArray = Object.entries(formValues);
      const filtered = asArray.filter(([key, value]) => key.slice(0, 8) === 'vatValue' && value === 'ZW');

      if (filtered.length) {
        setHasZW(true);
      } else {
        setHasZW(false);
        resetField('zwOptions');
        resetField('zwVatOther');
      }
    }
  };

  const fetchInvoiceValues = useCallback(
    async (values: GetInvoiceSumValueParams[]) => {
      setIsPendingRecalculate(true);
      try {
        const data: InvoiceCalculatedData = await getInvoiceSumValues(paymentValuesToSend);
        setGrossSummary(formatNumberToCurrency(data.grossValue || 0));
        setNetSummary(formatNumberToCurrency(data.netValue || 0));
        setVatSummary(formatNumberToCurrency(data.vatValue || 0));
        setCalculatedValuesToInvoice(data, setValue);
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsPendingRecalculate(false);
        setFieldRecalculationNeeded(false);
        setLastPaymentValuesSent(values);
      }
    },
    [amountPaid, paymentValuesToSend, setValue]
  );

  const setDefaultFormData = () => {
    const currentDate = new Date();
    setValue('invoiceDate', currentDate);
    setValue('deliveryDate', currentDate);
    setValue('dueDate', calculateDueDate(currentDate, paymentTypeCurrent));
    fetchInvoiceName();
    const mainAccount = ownerMyCompanyData?.bankAccounts?.find(bankAccount => bankAccount.mainAccount);
    if (mainAccount) {
      setValue('main-bank-account', mainAccount.number);
      setValue('bankNumber', mainAccount.number);
    }
  };

  const createSuffixOptions = () => {
    const suffixes = ownerMyCompanyData?.invoiceNumberSuffixes || [];
    const options = generateSuffixesOptions(suffixes);
    setSuffixesOptions(options);
  };

  const createBankAccountOptions = () => {
    const bankAccounts = ownerMyCompanyData?.bankAccounts || [];
    const options = generateBankAccountsOptions(bankAccounts, setValue, watch, disableBankAccount);

    setBankAccountOptions(options);
    setBankAccountsData(bankAccounts);
    setTimeout(() => {
      setBankAccountDataSet(true);
    });
  };

  const findAndCompanyDetails = () => {
    const name = ownerMyCompanyData?.name || '';
    const invoiceTypeName = ownerMyCompanyData.invoiceNumberFormat;

    setCompanyName(name);
    setInvoiceNameType(invoiceTypeName);
  };

  const addRowToTable = (isFirst: boolean = false) => {
    if (isFirst && rows.length) return;
    const row = generateRow(
      lastNumberId.toString(),
      control,
      onRecalculate,
      watch,
      setValue,
      getValues,
      clearErrors,
      setFieldRecalculationNeeded,
      checkIfRecalculationNeeded,
      trigger,
      numberOfItems,
      action,
      rows.length
    );
    setLastNumberId(lastNumberId + 1);
    setRows([...rows, row]);
    setTimeout(() => {
      const select = document.getElementById(`name-${lastNumberId.toString()}`);
      select?.focus();
    }, 200);
  };

  const addMultipleRows = (ids: number[], action = '') => {
    const currentRows = [];
    ids.forEach((id: number, index: number) => {
      const generatedRow = generateRow(
        id.toString(),
        control,
        onRecalculate,
        watch,
        setValue,
        getValues,
        clearErrors,
        setFieldRecalculationNeeded,
        checkIfRecalculationNeeded,
        trigger,
        numberOfItems,
        action,
        index
      );
      currentRows.push(generatedRow);
      if (index + 1 === ids.length) setLastNumberId(id + 1);
    });
    setRows(currentRows);
  };

  const removeRow = (rowData: TableRowInterface) => {
    const id = rowData.id;
    const currentRows = rows;
    const index = currentRows.findIndex(row => row.id === id);
    if (isCorrected && numberOfItems > index) {
      setValue(`grossValue-${id}`, '0.00');
      setValue(`quantity-${id}`, '0');
      setValue(`gtu-${id}`, '');
      const formValues = getValues();
      const values = getValuesToCalculateSummary(formValues, 'GrossValue', id);
      setPaymentValuesToSend(values);
      return;
    }
    setIsDeletedRow(true);
    if (index < 0) return;

    const rowToDelete = currentRows.splice(index, 1);
    if (rowToDelete[0]) unregisterTableRow(rowToDelete[0]);
    const filtered = currentRows.filter(key => getValues(`vatValue-${key.id}`) === 'ZW');
    if (!filtered.length) {
      setHasZW(false);
    }

    const filteredZw = currentRows.filter(key => getValues(`vatValue-${key.id}`) === 'ZW');
    if (!filteredZw.length) {
      setHasZW(false);
      resetField('zwOptions');
      resetField('zwVatOther');
    }

    setRows([...currentRows]);
    onRecalculate(null, null, true);
  };

  const unregisterTableRow = (rowToDel: TableRowInterface) => {
    Object.keys(rowToDel).forEach(key => {
      const propName = rowToDel[key]?.stashedData?.name;
      propName && unregister(propName);
    });
  };

  const onInvoiceIssueDateHandle = (issueDate: string) => {
    const issueDateParsed = dateFormatter.getDateObjectFromString(issueDate);

    setIssueDate(formatDate(issueDateParsed));

    fetchInvoiceName();
  };

  const onSubmit = (data: InvoiceFormData, redirectToList = false): void => {
    const isEqual = isEqualData();
    if (
      //prettier-ignore
      (
        (isDirty || isDeletedRow) && !isEqual
      )
      || (!isEqual && editContractor)
      || invoiceIsCloned
      || invoiceIsCorrected
      || (isPaid && !isDirty)
    ) {
      const selectedBank = bankAccountsData.find(d => d.number === data.bankNumber);
      const bankName = selectedBank?.bankName || '';
      setBankAccountOptions(generateBankAccountsOptions(ownerMyCompanyData.bankAccounts, setValue, watch, disableBankAccount));
      const submitData = createSubmitData(data, bankName, disableBankAccount, isPaid);
      const submitDataCorrection = createSubmitCorrectData(data, bankName, disableBankAccount, isPaid);

      if (!id && !action) {
        submitCreateInvoice(submitData, redirectToList);
      } else if (id) {
        submitEditInvoice(submitData, redirectToList);
      } else if (correctionId && action === 'correction') {
        submitCreateInvoiceCorrection(submitDataCorrection, redirectToList);
      } else if (correctionId && action === 'correction-edit') {
        submitEditInvoiceCorrection(submitDataCorrection, redirectToList);
      }
    } else {
      setSelectedInvoice(null);

      if (redirectToList) {
        if (isFromDashboard) {
          setSelectedContractor(null, null);
          navigate('/dashboard');
        } else {
          navigate('/invoices-list');
        }
      } else {
        openInvoiceDrawer('invoiceDrawerAndInvoicePreview', ''); // this case occurs when user click edit invoice on invoice drawer
        setUpdateData(true);
      }
    }
  };

  const onSuffixChange = (suffix: string) => {
    fetchInvoiceName(suffix, true);
  };

  const refreshStartData = () => {
    const values = getValues() as InvoiceFormData;
    setStartInvoiceData(values);
    setContractorWasModified(false);
    setUpdateData(true);
  };

  const isEqualData = () => {
    const formData = getValues() as InvoiceFormData;
    const selectedBankCurrent = bankAccountsData.find(d => d.number === formData.bankNumber);
    const bankNameCurrent = selectedBankCurrent?.bankName || '';
    const selectedBankStart = bankAccountsData.find(d => d.number === startInvoiceData.bankNumber);
    const bankNameStart = selectedBankStart?.bankName || '';

    const currentData = createSubmitData(formData, bankNameCurrent, false, false);
    const startData = createSubmitData(startInvoiceData, bankNameStart);

    return isEqual(startData, currentData) && !contractorWasModified;
  };

  const closeInvoice = () => {
    if (isFromDashboard) {
      setSelectedContractor(null, null);
      navigate('/dashboard');
      return;
    }
    if (id && ownerMyCompanyData.id === selectedInvoice.companyId) {
      openInvoiceDrawer('onlyInvoiceDrawer', id);
      reset(startInvoiceData);
      navigate('/invoices-list');
      return;
    }
    setSelectedContractor(null, null);
    navigate('/invoices-list');
  };

  const onCancelConfirmationDialogClose = (agree: boolean) => {
    setCancelConfirmationDialogOpen(false);
    if (agree) {
      addHotjarEvent('save invoice after click back');
      handleSubmit(
        f => onSubmit(f as InvoiceFormData),
        () => {
          if (Object.keys(errors).length) scrollToFirstError(Object.keys(errors));
          snackbarMessagesHandler.invoiceFormNeedCorrection();
        }
      )();
    } else {
      closeInvoice();
    }
  };

  const onCancel = () => {
    addHotjarEvent('back from invoice view');
    const isEqualInvoiceData = isEqualData();
    if (isEqualInvoiceData) {
      closeInvoice();
    } else {
      setCancelConfirmationDialogOpen(true);
    }
  };

  const onRedirectToSettings = (pathVariable: 'suffix') => {
    navigate(`/contractors-list/owner/edit/${role}/${pathVariable}`);
  };

  const onAddNewBankAccount = (data: BankAccountData) => {
    addHotjarEvent('Add bank account from invoice view');
    setTimeout(() => {
      setValue('bankNumber', data.number.replace(/ /g, ''));
      trigger('bankNumber');
    });
  };

  const setMainAccountRequest = (mainAccount: string) => {
    addHotjarEvent('set bank account as main account');
    const params = { accountNumber: mainAccount };

    setAccountAsMain(params)
      .then(() => {
        params.accountNumber.length ? snackbarMessagesHandler.bankAccountMainAccount() : snackbarMessagesHandler.bankAccountUnsetMainAccount();
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err));
  };

  const createAdditionalBankAccountOptions = (fetchedBankAccountNumber: BankAccountData, bankAccountNumbersMyCompany: BankAccountData[]) => {
    const bankNumbersHasOverlaps = bankAccountNumbersMyCompany.some(bank => {
      return bank.number === fetchedBankAccountNumber.number;
    });
    if (bankNumbersHasOverlaps) {
      setBankAccountOptions(generateBankAccountsOptions(bankAccountNumbersMyCompany, setValue, watch, disableBankAccount));
      setBankAccountsData(bankAccountNumbersMyCompany);
    } else {
      setBankAccountOptions(generateBankAccountsOptions([fetchedBankAccountNumber, ...bankAccountNumbersMyCompany], setValue, watch, disableBankAccount));
      setBankAccountsData([fetchedBankAccountNumber, ...bankAccountNumbersMyCompany]);
    }
  };

  return (
    <div className="owner-invoice-wrapper standard-layout-spacing-g">
      <ModalAndDialogs
        id={action === 'correction-edit' || action === 'correction' ? correctionId : id}
        openBankAccountModal={openBankAccountModal}
        cancelConfirmationDialogOpen={cancelConfirmationDialogOpen}
        onCancelConfirmationDialogClose={onCancelConfirmationDialogClose}
        onCreatedContractor={onCreatedContractor}
        setOpenBankAccountModal={setOpenBankAccountModal}
        onAddNewBankAccount={onAddNewBankAccount}
        contractorToEditId={contractorFormDefaults.Id}
        openContractorModal={contractorModalController.isOpen}
        closeContractorModal={contractorModalController.closeModal}
        contractorSearchNip={contractorFormDefaults.Nip}
        contractorSearchName={contractorFormDefaults.Name}
        isContractorSavedInDataBase={!!contractorFormDefaults.Id}
        addNewContractor={contractorModalController.isAdd}
        onContractorModified={() => setContractorWasModified(true)}
        updateData={updateData}
        setUpdateData={setUpdateData}
      />
      <div className="header">
        <ButtonIconNew testcy="go-back" color="transparent" size="big" onClick={onCancel}>
          <ArrowIcon direction="left" size={24} />
        </ButtonIconNew>
        <h1 className="title-g">Wystaw fakturę</h1>
      </div>
      <PageLoaderWrapper isLoading={isLoadingPage}>
        <FormProvider {...form}>
          <form className={`form-wrapper ${id && ownerMyCompanyData?.id !== selectedInvoice?.companyId && 'readonly'} `}>
            <InvoiceDataSection
              open={basicSectionOpen}
              setOpen={setBasicSectionOpen}
              useFormFeatures={{ control, trigger, setError, clearErrors, watch }}
              companyName={companyName}
              invoiceNameType={invoiceNameType}
              isPendingInvoiceNameValue={isPendingInvoiceNameValue}
              suffixesOptions={suffixesOptions}
              onRedirectToSettings={onRedirectToSettings}
              onSuffixChange={onSuffixChange}
              onInvoiceIssueDateHandle={onInvoiceIssueDateHandle}
              minDate={minDate}
            />
            <ContractorSection
              open={contractorSectionOpen}
              setOpen={setContractorSectionOpen}
              useFormFeatures={{ control, setValue, trigger, watch }}
              contractorOptions={contractorSearch.options}
              isPendingContractors={contractorSearch.isLoadingOptions}
              onFilterContractorChanged={contractorSearch.setValue}
              openContractorModal={openContractorModal}
              allowedActions={contractorAllowedActions}
              date={issueDate}
            />
            <ProductAndServiceSection
              open={prodServiceSectionOpen}
              setOpen={setProdServiceSectionOpen}
              useFormFeatures={{ control, watch, resetField }}
              rows={rows}
              tableHeader={tableHeader}
              removeRow={removeRow}
              addRowToTable={addRowToTable}
              netSummary={netSummary}
              vatSummary={vatSummary}
              grossSummary={grossSummary}
              isPendingRecalculate={isPendingRecalculate}
              hasZW={hasZW}
              calculateOriginalInvoiceValuesId={calculateOriginalInvoiceValuesId}
            />
            <PaymentSection
              open={paymentSectionOpen}
              setOpen={setPaymentSectionOpen}
              useFormFeatures={{ clearErrors, control, trigger, setError, watch, setValue }}
              paymentTypeOptions={paymentTypeOptions}
              bankAccountsOptions={bankAccountsOptions}
              disableBankAccount={disableBankAccount}
              amountPaid={amountPaid}
              leftToPaid={leftToPaid}
              grossSummary={grossSummary}
              setPaymentTypeCurrent={setPaymentTypeCurrent}
              setOpenBankAccountModal={setOpenBankAccountModal}
              setIsBlurred={setIsBlurred}
              isPaid={isPaid}
              status={status}
            />
            <div className="button-area">
              {isPendingSubmit && (
                <div className="button-loader">
                  <LoaderIcon size="small2" />
                </div>
              )}

              <ButtonMainNew
                colorType="business"
                testcy="invoice-form-cancel"
                content={'Anuluj'}
                width="160px"
                sizeType="bigger"
                isDisabled={isPendingSubmit}
                onClick={onCancel}
              />

              <ButtonMainNew
                colorType="businessDarker"
                testcy="invoice-form-save-and-continue"
                content={'Zapisz i kontynuuj'}
                width="160px"
                type="button"
                sizeType="bigger"
                isDisabled={isPendingSubmit || fieldRecalculationNeeded}
                onClick={async () => {
                  await trigger();
                  if (Object.keys(errors).length) scrollToFirstError(Object.keys(errors));
                  if (isValid && !fieldRecalculationNeeded) {
                    handleSubmit(f => onSubmit(f as InvoiceFormData))();
                  }
                }}
              />
            </div>
          </form>
        </FormProvider>
      </PageLoaderWrapper>
    </div>
  );
};
