import { MutationParams, MutationResult, MutationsTypeKey, mutations } from '@services';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

export const useApiMutation = <Key extends MutationsTypeKey, TError = unknown>(
  mutation: Key,
  options?: UseMutationOptions<MutationResult<Key>, TError, MutationParams<Key>>
) => {
  const mutationFn = mutations[mutation];
  const mutationKey = [mutation];

  return useMutation(
    mutationKey,
    async args => await mutationFn(args as never),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options as any
  ) as UseMutationResult<MutationResult<Key>, TError, MutationParams<Key>>;
};
