import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { standardValidators } from '@utils';
import { ButtonMain, LoaderIcon, TagInput, TextInputNew, Toggle } from '@components';
import { useContractorStore } from '@store';
import { ReminderFormData, EmailMessage } from './sharedReminderForm.utils';
import './sharedReminderForm.scoped.scss';

interface Props {
  invoiceNumber: string;
  isReminderPending: boolean;
  amountToPay?: number;
  onSubmit: (data: ReminderFormData) => void;
}

export const SharedReminderForm = ({ onSubmit, isReminderPending, invoiceNumber, amountToPay }: Props) => {
  const [tags, setTags] = useState([]);
  const { handleSubmit, control, setValue, clearErrors, trigger } = useForm<FieldValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const { ownerMyCompanyData, selectedContractor } = useContractorStore();

  return (
    <form
      onSubmit={handleSubmit((data: FieldValues) => {
        data['emailTo'] = tags;
        onSubmit(data as any);
      })}
    >
      <div className="form-wrapper">
        <div className="form-column">
          <TextInputNew
            testcy="reminder-form-from"
            control={control}
            name="emailFrom"
            label={'Nadawca'}
            width="100%"
            appearance="light"
            validation={standardValidators.requiredMaxNumber(255)}
            readOnly
            defaultValue={ownerMyCompanyData.name}
          />
          <TagInput
            testcy="reminder-form-to"
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            trigger={trigger}
            setTagsData={setTags}
            name="emailTo"
            label={'Odbiorca'}
            width="100%"
            type="email"
            appearance="light"
            placeholder="Adres mailowy odbiorcy"
            defaultValue={selectedContractor.emails}
            validation={standardValidators.requiredEmailList(tags)}
          />
          <TextInputNew
            testcy="reminder-form-subject"
            control={control}
            name="subject"
            label={'Temat'}
            width="100%"
            appearance="light"
            validation={standardValidators.requiredMaxNumber(150)}
            defaultValue={EmailMessage(invoiceNumber, amountToPay).subject}
          />
          <TextInputNew
            testcy="reminder-form-message"
            control={control}
            name="emailMessage"
            label={'Treść wiadomości'}
            width="100%"
            multiline
            rows={'5'}
            appearance="light"
            defaultValue={EmailMessage(invoiceNumber, amountToPay).body}
            validation={standardValidators.requiredMaxNumber(1000)}
          />

          <div className="toggle-btn-wrapper">
            <div>
              <Toggle control={control} name="invoiceAttachment" testcy="reminder-form-attach-invoice" />
            </div>
            <div>{`Załącz fakturę`}</div>
          </div>
          <div className="toggle-btn-wrapper">
            <div>
              <Toggle control={control} name="sendCopy" testcy="reminder-form-send-copy" />
            </div>
            <div>{`Wyślij kopię do mnie na ${ownerMyCompanyData.email}`}</div>
          </div>
        </div>
      </div>
      <div className="form-footer">
        {isReminderPending && (
          <div className="loader-wrapper">
            <LoaderIcon size="small" />
          </div>
        )}
        <div className={`${isReminderPending && 'button-wrapper'}`}>
          <ButtonMain testcy="reminder-form-send" type="submit" content={' Wyślij'} width="220px" />
        </div>
      </div>
    </form>
  );
};
