import { PopupMenu, TheTablePagination } from '@components';
import SouthIcon from '@mui/icons-material/South';
import { Tooltip } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SortDirection } from '@utils';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { RowContent, TableHeaderData, TableRowData } from './theTableNew.types';
import { TransitionGroup } from 'react-transition-group';

import './theTableNew.scoped.scss';

interface Props {
  header: TableHeaderData[];
  rows: TableRowData[];
  testcy: string;
  lastColumnWithoutBorder?: boolean;
  minTableWidth?: number;
  defaultSortType?: SortDirection;
  defaultSortProp?: string;
  noBorders?: boolean;
  clickableRows?: boolean;
  selectedRowId?: string;
  CustomRowComponents?: ReactNode;
  numberOfRowsChecked?: number;
  actionMenuItems?: any;
  indexedActionMenuItems?: boolean;
  showHeaderAndFooter?: boolean;
  onSelectRow?: (id: string, row: TableRowData) => void;
  onClickMenuItem?: (action: string, row: TableRowData) => void;
  onClickOpenMenu?: (row: TableRowData, index: number) => void;
  onChangePagination?: (data: { page: number; rowsPerPage: number }) => void;
  onChangeSort?: (sortType: SortDirection, propertyName: string) => void;
}
interface PaginationProps {
  showPagination?: boolean;
  totalRows?: number;
  page?: number;
  rowsPerPage?: number;
}

export const TheTableNew = ({
  header,
  rows,
  testcy,
  page = 1,
  rowsPerPage = 50,
  totalRows,
  showPagination = true,
  lastColumnWithoutBorder = false,
  minTableWidth = 650,
  defaultSortType = SortDirection.ASCENDING,
  defaultSortProp = '',
  noBorders = false,
  clickableRows = false,
  selectedRowId,
  CustomRowComponents,
  numberOfRowsChecked,
  actionMenuItems,
  showHeaderAndFooter = true,
  indexedActionMenuItems = true,
  onSelectRow,
  onClickMenuItem = () => {},
  onClickOpenMenu = () => {},
  onChangeSort = () => {},
  onChangePagination = () => {},
}: Props & PaginationProps) => {
  const [currentSortType, setCurrentSortType] = useState(defaultSortType);
  const [currentSortingProperty, setCurrentSortingProperty] = useState(defaultSortProp);

  useEffect(() => {
    if (defaultSortProp) setCurrentSortingProperty(defaultSortProp);
  }, [defaultSortProp, defaultSortType]);

  useEffect(() => {
    if (defaultSortType) setCurrentSortType(defaultSortType);
  }, [defaultSortType]);

  const onClickSort = (propertyName: string, isSortable: boolean) => {
    if (!isSortable) return;
    let sort = currentSortType;
    if (propertyName !== currentSortingProperty) {
      setCurrentSortingProperty(propertyName);
      sort = SortDirection.ASCENDING;
    } else {
      sort = sort === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    setCurrentSortType(sort);
    if (onChangeSort) onChangeSort(sort, propertyName);
  };

  const tableClasses = ['table', noBorders ? 'no-borders' : '', clickableRows ? 'clickable-rows' : ''].join(' ');

  const getCellClasses = (row: RowContent, customClass: string = ''): string => {
    let classes = ['table-cell standard-cell'];
    if (row?.bold) classes.push('bold-text');
    if (customClass) classes.push(customClass);
    return classes.join(' ');
  };

  const minWidth = useMemo(() => header.filter(h => !h.isHidden).reduce((acc, curr) => acc + (curr.minWidth || curr.width || 0), 0), [header]);

  return (
    <>
      <Table sx={{ minWidth: minTableWidth }} className={tableClasses} data-testcy={`${testcy}-header`}>
        {showHeaderAndFooter && (
          <TableHead>
            {!numberOfRowsChecked && (
              <TableRow className={`table-header-row ${lastColumnWithoutBorder ? 'table-header-row-last-col' : ''}  `}>
                {header
                  .filter(h => !h.isHidden)
                  .map((h, index) => {
                    return (
                      <TableCell
                        data-testcy={`${testcy}-header-column-${h.data}`}
                        key={`${h.keyPrefix || ''}-${h.label}`}
                        sx={{
                          minWidth: h.minWidth || 'unset',
                          width: h.width || 'unset',
                          maxWidth: h.maxWidth || 'unset',
                          cursor: h.isSortable ? 'pointer' : 'unset',
                        }}
                        align={'justify'}
                        onClick={() => onClickSort(h.data, h.isSortable)}
                        className={`table-header-cell ${h.action ? 'action-header' : ''} ${h.nowrap ? 'nowrap' : ''} ${
                          h.data === 'checkbox' ? 'checkbox-cell' : ''
                        }`}
                      >
                        <div className="table-header-cell-content">
                          {h.component ? (
                            h.component
                          ) : (
                            <>
                              {h.tooltip ? (
                                <Tooltip title={h.tooltip}>
                                  <span className="tooltip-label">{h.label}</span>
                                </Tooltip>
                              ) : (
                                <span>{h.label}</span>
                              )}
                            </>
                          )}
                          {h.data !== 'checkbox' && (
                            <button type="button" data-testcy={`${testcy}-sort-column-${index}`} style={{ width: '24px' }}>
                              {currentSortingProperty === h.data && (
                                <SouthIcon
                                  className={`sorting-arow-icon ${
                                    currentSortType === SortDirection.DESCENDING ? 'arow-icon-descending' : 'arrow-icon-ascending'
                                  }`}
                                />
                              )}
                            </button>
                          )}
                        </div>
                      </TableCell>
                    );
                  })}
              </TableRow>
            )}
            {!!numberOfRowsChecked && (
              <TableRow className={` table-header-row ${lastColumnWithoutBorder ? 'table-header-row-last-col' : ''}  `}>
                {header
                  .filter(h => !h.isHidden)
                  .map(h => {
                    return (
                      <TableCell
                        data-testcy={`${testcy}-headerChecked-column-${h.data}`}
                        key={`${h.keyPrefix || ''}-${h.label}`}
                        sx={{
                          minWidth: h.minWidth || 'unset',
                          width: h.width || 'unset',
                          cursor: 'default',
                        }}
                        align={'justify'}
                        className={`${h.customCellClass ? h.customCellClass : ''} table-header-cell ${h.action ? 'action-header' : ''}`}
                      >
                        {h.component && h.data !== 'status' ? h.component : <div className="table-header-cell-content"></div>}
                      </TableCell>
                    );
                  })}
              </TableRow>
            )}
          </TableHead>
        )}
      </Table>
      <TransitionGroup>
        {rows.map((row, index) => {
          return (
            <Collapse key={row.id} in timeout={300 + index * 80} unmountOnExit>
              <Table sx={{ minWidth: minTableWidth }} className={`table ${noBorders ? 'no-borders' : ''}`} data-testcy={`${testcy}-body`}>
                <TableBody className="table-body">
                  {index === 0 && CustomRowComponents}
                  <TableRow
                    key={row.id}
                    onClick={() => (onSelectRow ? onSelectRow(row.id, row) : null)}
                    className={`table-body-row ${lastColumnWithoutBorder ? 'table-body-row-last-col' : ''}`}
                  >
                    {header
                      .filter(h => !h.isHidden)
                      .map(h => {
                        if (h.action) {
                          if (!actionMenuItems) return <TableCell className={`table-cell standard-cell`} align="left" key={`${row.id}-no-action`} />;

                          return (
                            <TableCell
                              data-testcy={`${testcy}-body-column-${h.data} ${testcy}-body-row-${index}`}
                              key={`${row.id}-${h.label}-action`}
                              className={`table-cell action-cell-wrapper ${h.customCellClass ? h.customCellClass : ''} ${
                                selectedRowId === row.id ? 'selected-row' : ''
                              }`}
                              sx={{
                                minWidth: h.minWidth || 'unset',
                                width: h.width || 'unset',
                              }}
                              align="left"
                              onClick={e => e.stopPropagation()}
                            >
                              <div className="actions-wrapper">
                                {/* {!isAccountingView && (
                                  <Tooltip title={row.action?.CSVTooltip}>
                                    <button className="table-icon-button" onClick={() => onCSVButtonClick(row)}>
                                      <DocumentIcon size="18px" colorType={row.action?.CSVDisabled ? 'disabled' : 'primary'} />
                                    </button>
                                  </Tooltip>
                                )} */}
                                <PopupMenu
                                  menuItems={indexedActionMenuItems ? actionMenuItems[index] : actionMenuItems}
                                  onClickMenuItem={actionType => onClickMenuItem(actionType, row)}
                                  onClickOpenMenu={() => {
                                    onClickOpenMenu(row, index);
                                  }}
                                />
                              </div>
                            </TableCell>
                          );
                        }
                        const content = h.autoCount ? index + 1 : row[h.data].content || '';
                        return (
                          <TableCell
                            data-testcy={`${testcy}-body-column-${h.data} ${testcy}-body-row-${index}`}
                            key={`${h.keyPrefix || ''}-${row.id}-${h.label}`}
                            className={`
                              ${getCellClasses(row[h.data], h.customCellClass)}
                              ${selectedRowId === row.id ? 'selected-row' : ''}
                              ${onSelectRow ? 'selectable-row' : ''}
                            `}
                            align={'left'}
                            sx={{
                              minWidth: h.minWidth || 'unset',
                              maxWidth: '160px',
                              width: h.width || 'unset',
                            }}
                          >
                            {row[h.data].component ? row[h.data].component : content}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                </TableBody>
              </Table>
            </Collapse>
          );
        })}
      </TransitionGroup>
      <Table sx={{ minWidth: Math.max(minTableWidth, minWidth) }} className={`table ${noBorders ? 'no-borders' : ''}`} data-testcy={`${testcy}-footer`}>
        {rows.length === 0 && (
          <TableBody>
            <TableRow className="table-body-row" key={'no-data'}>
              <TableCell className="no-results-cell" colSpan={9999} style={{ textAlign: 'center', border: 'none' }} data-testcy={`${testcy}-no-results-cell`}>
                Brak wyników spełniających kryteria.
              </TableCell>
            </TableRow>
          </TableBody>
        )}

        <TableFooter>
          {showPagination && (
            <TableRow className="table-footer">
              <TableCell colSpan={9999} style={{ padding: 0, border: 'none' }}>
                <TheTablePagination testcy={testcy} totalItems={totalRows} page={page} rowsPerPage={rowsPerPage} onChange={onChangePagination} />
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </>
  );
};
