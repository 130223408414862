import React from 'react';

interface Props {
  colorType?: 'black' | 'white' | 'primary' | 'disabled' | '';
  size?: string | number;
}

export const PersonIcon = ({ colorType = 'black', size = 24 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M6.78759 20.161C5.32933 20.161 4.44812 19.2149 4.38574 17.897C4.38574 15.2144 7.28927 14.289 11.664 14.2578C16.0467 14.2994 18.958 15.2247 18.9424 17.897C18.8722 19.2149 17.9962 20.161 16.5405 20.161H6.78759Z"
        stroke="#101013"
        strokeWidth="1.44"
        strokeMiterlimit="10"
      />
      <path
        d="M11.6713 11.1961C13.7027 11.1961 15.3495 9.54939 15.3495 7.518C15.3495 5.48661 13.7027 3.83984 11.6713 3.83984C9.63998 3.83984 7.99316 5.48661 7.99316 7.518C7.99316 9.54939 9.63998 11.1961 11.6713 11.1961Z"
        stroke="#101013"
        strokeWidth="1.44"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
