/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { SharedFilePreview } from '@features';
import './filePreview.scoped.scss';

export const FilePreview = () => {
  const { duplicateId } = useParams();
  const [duplicateDate] = useSearchParams();
  return (
    <div className="file-preview-wrapper">
      <SharedFilePreview
        testcy="modal-file-preview"
        fileId={duplicateId}
        fileName={'duplicate'}
        modalParentId="invoice-invoices-list-modal"
        arrangement={'center'}
        type="invoice"
        startZoom={1}
        dateDuplicate={new Date(duplicateDate.get('duplicateDate'))}
      />
    </div>
  );
};
