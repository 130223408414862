import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const DownloadIcon = ({ colorType = '' }: Props) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon-color-${colorType}-g`}
    >
      <g>
        <path
          d="M7.87695 9.91016C7.89157 9.92883 7.91024 9.94394 7.93156 9.95432C7.95288 9.96471 7.97629 9.97011 8 9.97011C8.02371 9.97011 8.04712 9.96471 8.06844 9.95432C8.08976 9.94394 8.10843 9.92883 8.12305 9.91016L10.3105 7.14258C10.3906 7.04102 10.3184 6.89062 10.1875 6.89062H8.74023V0.28125C8.74023 0.195312 8.66992 0.125 8.58398 0.125H7.41211C7.32617 0.125 7.25586 0.195312 7.25586 0.28125V6.88867H5.8125C5.68164 6.88867 5.60938 7.03906 5.68945 7.14062L7.87695 9.91016ZM15.1484 9.22656H13.9766C13.8906 9.22656 13.8203 9.29688 13.8203 9.38281V12.3906H2.17969V9.38281C2.17969 9.29688 2.10938 9.22656 2.02344 9.22656H0.851562C0.765625 9.22656 0.695312 9.29688 0.695312 9.38281V13.25C0.695312 13.5957 0.974609 13.875 1.32031 13.875H14.6797C15.0254 13.875 15.3047 13.5957 15.3047 13.25V9.38281C15.3047 9.29688 15.2344 9.22656 15.1484 9.22656Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_342_7803">
          <rect width="16" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
