/* eslint-disable react-hooks/exhaustive-deps */
import { useDebounce, useInviteCompany, usePagination } from '@hooks';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonMain, SearchBarValues, TheTableNew, TableRowData } from '@components';
import { CompaniesOrderOptions, CompanyListData, GetCompaniesParams } from '@services';
import { useListParamsStore } from '@store';
import { SortDirection } from '@utils';
import './accountantCompanylist.scoped.scss';
import { ListSearchBar } from 'src/components/combined';
import { companyDataToTableRow, tableHeader, transformMenuItemsCompaniesList } from './accountantCompanylist.utils';
import { useApiQuery } from 'src/hooks/useApiQuery';

export const AccountantCompanyList = () => {
  const testcy = 'companies-table';
  const { page, setPage, totalRows, setTotalRows, rowsPerPage, setRowsPerPage } = usePagination();

  const [searchValue, setSearchValue] = useState('');
  const [orderBy, setOrderBy] = useState<CompaniesOrderOptions>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASCENDING);
  const [rows, setRows] = useState([]);
  const [isCheckedStore, setIsCheckedStore] = useState(false);
  const [listParams, setListParams] = useState<GetCompaniesParams>();
  const [searchBarDefaultValues, setSearchBarDefaultValues] = useState<SearchBarValues>(null);
  const [menuItemsList, setMenuItemsList] = useState([]);
  const navigate = useNavigate();

  const { lastParamsCompaniesList, setLastParamsCompaniesList } = useListParamsStore();
  const listSearchParams = useDebounce(listParams, 200);
  const { setInviteCompanyId } = useInviteCompany();

  useEffect(() => {
    if (lastParamsCompaniesList) {
      setPage(lastParamsCompaniesList.page);
      setSortDirection(lastParamsCompaniesList.sortDirection);
      setOrderBy(lastParamsCompaniesList.orderBy);
      if (lastParamsCompaniesList.searchBarValues) setSearchBarDefaultValues(lastParamsCompaniesList.searchBarValues);
      setLastParamsCompaniesList(null);
    }
    setIsCheckedStore(true);
  }, []);

  useEffect(() => {
    if (isCheckedStore) modifyParams();
  }, [searchValue, page, orderBy, sortDirection, isCheckedStore, rowsPerPage]);

  const onClickEdit = (data: TableRowData) => {
    saveToStateParams();
    navigate(`/owner-company-list/edit/${data.id}`);
  };

  const onClickOpen = (data: TableRowData) => {
    saveToStateParams();
    navigate(`/owner-company-list/details/${data.id}`);
  };

  const modifyParams = () => {
    const params: GetCompaniesParams = {
      direction: sortDirection,
      pageNumber: page,
      pageSize: rowsPerPage,
    };
    if (orderBy) params.orderBy = orderBy;
    if (searchValue) {
      params.textFilter = searchValue;
      params.pageNumber = page;
    }
    setListParams(params);
  };

  const { isLoading: isFetchingGetCompanies } = useApiQuery(
    'getCompanies',
    { ...listSearchParams },
    {
      onSuccess: result => {
        setTotalRows(result.totalCount);
        generateRows(result.data);
        setMenuItemsList(transformMenuItemsCompaniesList(result.data));
      },
      keepPreviousData: true,
    }
  );

  const generateRows = (list: CompanyListData[]) => {
    const rows = list.map((data: CompanyListData) => {
      const tranformedDataToRow = companyDataToTableRow(data);
      return tranformedDataToRow;
    });
    setRows(rows);
  };

  const saveToStateParams = () => {
    setLastParamsCompaniesList({
      sortDirection,
      page,
      orderBy,
      searchBarValues: {
        textFilter: searchValue,
      },
      itemsPerPage: rowsPerPage,
    });
  };

  const onChangeSort = (sortType: SortDirection, propertyName: string) => {
    setSortDirection(sortType);
    setOrderBy(propertyName.toLowerCase() as CompaniesOrderOptions);
  };

  const onChangePagination = (data: { page: number; rowsPerPage: number }) => {
    setPage(data.page);
    setRowsPerPage(data.rowsPerPage);
  };

  const onFilterChange = (data: SearchBarValues) => {
    setSearchValue(data.textFilter);
    setPage(1);
  };

  const onAddCompany = () => {
    saveToStateParams();
    navigate('/owner-company-list/add-company');
  };

  const onClickMenuItem = (action: string, row: TableRowData) => {
    if (action === 'edit') return onClickEdit(row);
    if (action === 'open') return onClickOpen(row);
    if (action === 'invite') return setInviteCompanyId(Number(row.id));
  };
  return (
    <div className="company-list-wrapper standard-layout-spacing-g">
      <h1 className="title-g">Lista firm</h1>
      <div className="company-list-header">
        <ListSearchBar
          onChange={onFilterChange}
          isPending={isFetchingGetCompanies}
          searchBarPlaceholder="Wyszukaj firmę"
          setPage={setPage}
          showSearchBar
          defaultValues={searchBarDefaultValues}
        />
        <div className="button-loader-wrapper">{<ButtonMain testcy="add-company" content={'Dodaj firmę'} width="200px" onClick={onAddCompany} />}</div>
      </div>
      <div className="table-wrapper-g">
        <TheTableNew
          testcy={testcy}
          header={tableHeader}
          rows={rows}
          actionMenuItems={menuItemsList}
          defaultSortProp={orderBy}
          defaultSortType={sortDirection}
          onClickMenuItem={onClickMenuItem}
          onChangeSort={onChangeSort}
          totalRows={totalRows}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePagination={onChangePagination}
        />
      </div>
    </div>
  );
};
