import { addHotjarEvent, invalidateApiQuery, dateFormatter } from '@lib';
import { useContractorStore } from '@store';
import { snackbarMessagesHandler, stringToNumberSecondOptions } from '@utils';
import { EmployeeFormData } from 'src/services/employees';
import { useApiMutation } from '../useApiMutation';

type UseCreateEmployeeProps = {
  onSuccess?: () => void;
};

export const useCreateEmployee = ({ onSuccess }: UseCreateEmployeeProps) => {
  const { ownerMyCompanyData } = useContractorStore();

  const { mutate, isLoading } = useApiMutation('createNewEmployee', {
    onMutate: () => {
      addHotjarEvent('create employee');
    },
    onSuccess: () => {
      snackbarMessagesHandler.employeeCreated();
      invalidateApiQuery('getEmployees');
      onSuccess?.();
    },
  });

  const createEmployee = (data: EmployeeFormData) => {
    const companyId = ownerMyCompanyData?.id;
    mutate({
      companyId: companyId ? companyId : null,
      firstName: data.firstName,
      lastName: data.lastName,
      pesel: data.pesel.toString(),
      email: data.email,
      phone: data.phone,
      grossSalary: stringToNumberSecondOptions(data.grossSalary),
      position: data.position,
      address: {
        city: data.city,
        street: data.street,
        zipCode: data.zipCode,
      },
      typeOfEmployment: data.typeOfEmployment,
      contractStartDate: dateFormatter.objectToString(data.contractStartDate as Date, 'yyyy-MM-dd'),
      contractEndDate: data.contractEndDate ? dateFormatter.objectToString(data.contractEndDate as Date, 'yyyy-MM-dd') : null,
    });
  };

  return {
    isLoading,
    createEmployee,
  };
};
