import { useState } from 'react';
import { FieldValues, UseFormSetValue, UseFormUnregister } from 'react-hook-form';

type SuffixObject = {
  value: string;
  index: number;
};

interface IUseSuffixList {
  suffixes: SuffixObject[];
  removeSuffix: (index: number) => void;
  addSuffix: () => void;
  updateSuffix: (index: number, value: string) => void;
}

type RawSuffixes = { value: string }[];

export const useSuffixList = (defaultSuffixes: RawSuffixes, setValue: UseFormSetValue<FieldValues>, unregister: UseFormUnregister<FieldValues>): IUseSuffixList => {
  const [suffixes, setSuffixes] = useState<SuffixObject[]>(defaultSuffixes.map((suffix, i) => ({ value: suffix.value, index: i })));
  const [nextIndex, setNextIndex] = useState<number>(defaultSuffixes.length);

  const addSuffix = () => {
    setSuffixes(prev => [...prev, { value: '', index: nextIndex }]);
    setValue(`suffix-${nextIndex}`, '');
    setNextIndex(prev => prev + 1);
  };
  const removeSuffix = (index: number) => {
    setSuffixes(prev => prev.filter(s => s.index !== index));
    unregister(`suffix-${index}`);
  };
  const updateSuffix = (index: number, value: string) => {
    value = value.toUpperCase();
    setSuffixes(prev => prev.map(s => s.index === index ? { ...s, value } : s));
    setValue(`suffix-${index}`, value);
  }

  return {
    suffixes,
    removeSuffix,
    addSuffix,
    updateSuffix,
  };
};
