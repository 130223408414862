import React from 'react';
import { EnvironmentTypeEnum, appConfig } from 'src/appConfig';

import './floatingEnv.scoped.scss';

export const FloatingEnv = () => {
  const [isVisible, setIsVisible] = React.useState(true);

  if (appConfig.env === EnvironmentTypeEnum.PROD || !isVisible) {
    return null;
  }

  const hide = () => setIsVisible(false);

  return (
    <div className={`floating-env-info ${appConfig.env}`} onClick={hide}>
      {appConfig.env.toUpperCase()}
    </div>
  );
};
