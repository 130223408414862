import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
  size?: number;
}

export const PlusIcon = ({ colorType = '', size = 16 }: Props) => {
  return (
    <svg
      className={`icon-color-${colorType}-stroke`}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M4 8H12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 4V12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
