import { LoaderIcon, MeatballsMenuIcon } from '@components';
import { Option } from '@interfaces';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { isUndefined } from 'lodash';
import React, { MouseEvent, useState } from 'react';

import './popupMenu.scss';

interface Props {
  menuItems: Option[];
  index?: number | string;
  onClickMenuItem: (actionType?: string) => void;
  onClickOpenMenu?: (actionType?: string) => void;
  isPending?: boolean;
  iconSize?: number;
  isDisabled?: boolean;
}

export const PopupMenu = ({ menuItems, index, onClickMenuItem, onClickOpenMenu, isPending = false, iconSize = 16, isDisabled = false }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (isUndefined(index)) index = '';
  else index = '-' + index;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    // future use refresh menu items in case of change accounting status
    onClickOpenMenu?.();
  };

  const handleClose = (actionType?: string) => {
    onClickMenuItem(actionType || null);
    setAnchorEl(null);
  };

  return (
    <div className="popup-menu-component-wrapper">
      {isPending ? (
        <LoaderIcon size="medium" />
      ) : (
        <>
          <IconButton
            className={`${isDisabled && 'disabled'}`}
            aria-controls={anchorEl ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
            onClick={handleClick}
            style={{ width: '100%' }}
            data-testcy={`popup-menu-button${index}`}
          >
            <MeatballsMenuIcon colorType="black" size={iconSize} />
          </IconButton>
          <Menu id="basic-menu" anchorEl={anchorEl} open={!!anchorEl} onClose={() => handleClose()}>
            {menuItems?.map(i => {
              return (
                <Tooltip title={i.additionalMessage} key={`tooltip-${i.value}`}>
                  <div>
                    <MenuItem
                      className="item"
                      key={i.value}
                      onClick={() => handleClose(i.value)}
                      disabled={i.disabled}
                      sx={{
                        fontFamily: 'Poppins',
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  </div>
                </Tooltip>
              );
            })}
          </Menu>
        </>
      )}
    </div>
  );
};
