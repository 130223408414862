import { InvoiceEventType } from 'src/services/invoices';

const EVENT_MAP: { [key in InvoiceEventType]: string } = {
  InvoiceCreated: 'Utworzono',
  InvoiceModified: 'Edytowano',
  InvoiceIssued: 'Wystawiono',
  InvoiceSentToAccounting: 'Wysłano do księgowania',
  PaymentRecorded: 'Zarejestrowano wpłatę',
  InvoiceSentViaEmail: 'Wysłano',
  InvoiceMarkedAsSent: 'Oznaczono jako wysłaną',
  InvoiceReminderSent: 'Wysłano upomnienie',
  DuplicateIssued: 'Wystawiono duplikat',
  InvoicePaid: 'Oznaczono jako opłaconą',
};
export const translateInvoiceEvent = (event: InvoiceEventType) => {
  return EVENT_MAP[event] ?? event;
};

const CLICKABLE_EVENT_MAP: { [key in InvoiceEventType]: boolean } = {
  InvoiceCreated: false,
  InvoiceModified: true,
  InvoiceIssued: false,
  InvoiceSentToAccounting: false,
  PaymentRecorded: false,
  InvoiceSentViaEmail: false,
  InvoiceMarkedAsSent: false,
  InvoiceReminderSent: false,
  DuplicateIssued: false,
  InvoicePaid: false,
};
export const getIsEventClickable = (event: InvoiceEventType) => {
  return CLICKABLE_EVENT_MAP[event] ?? false;
}
