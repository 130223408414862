import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormReturn, useFormState } from 'react-hook-form';
import { isEqual } from 'lodash';
import { ButtonText, TableHeaderInterface, TableRowInterface, TheAccordion, TheTable } from '@components';
import { InvoiceCalculatedData } from '@services';
import { useInvoiceStore } from '@store';
import './vatSection.scoped.scss';
import { CostSummary } from '../costSummary/costSummary';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  useFormFeatures: Partial<UseFormReturn<FieldValues>>;
  rows: TableRowInterface[];
  tableHeader: TableHeaderInterface[];
  removeRow: (rowData: TableRowInterface) => void;
  addRowToTable: (v: boolean) => void;
  netSummary: string;
  vatSummary: string;
  grossSummary: string;
  calculateOriginalInvoiceValuesId?: InvoiceCalculatedData;
  isPendingRecalculate: boolean;
  onClickSyncToggle: (id: string, newState: boolean) => void;
}

export const VatSection = ({
  open,
  setOpen,
  useFormFeatures,
  rows,
  tableHeader,
  removeRow,
  addRowToTable,
  netSummary,
  vatSummary,
  grossSummary,
  calculateOriginalInvoiceValuesId,
  isPendingRecalculate,
  onClickSyncToggle,
}: Props) => {
  const { control } = useFormFeatures;
  const { errors, isSubmitting } = useFormState({ control });
  const [errorsData, setErrorsData] = useState([]);

  const { isCorrected } = useInvoiceStore();

  useEffect(() => {
    const errorsList = Object.keys(errors);
    const fieldPrefixes = ['jm', 'quantity', 'value', 'vatValue', 'name'];
    const filteredErrorList = errorsList.filter(e => {
      if (e === 'valueType') return true;
      let include = false;
      fieldPrefixes.forEach(p => {
        if (e.includes(p)) include = true;
      });
      return include;
    });

    if (isEqual(filteredErrorList, errorsData) && !isSubmitting) return;
    setErrorsData(filteredErrorList);

    const isInvalid = !!filteredErrorList.length;
    if (isInvalid && !open) setOpen(true);
  }, [Object.keys(errors), isSubmitting]);

  return (
    <TheAccordion testcy="costs-form-vat-accordion" open={open} setOpen={setOpen} id="product-and-services-invoice-form" title="Produkty i usługi">
      <div className="vat-section-wrapper">
        <div className="table-wrapper-g">
          <TheTable
            testcy="costs-form-vat-table"
            rows={rows}
            header={tableHeader}
            actionSettings={{
              cannotDeleteLastRow: !isCorrected,
            }}
            onClickRemove={removeRow}
            onClickSyncToggle={onClickSyncToggle}
            centerCells
            showIconActions
          />
          <ButtonText
            name="costs-form-add-vat-rate"
            testcy="costs-form-add-vat-rate"
            content="DODAJ KOLEJNĄ STAWKĘ VAT"
            margin={'10px'}
            isDisabled={false}
            endIcons="add"
            onClick={() => addRowToTable(false)}
          />
        </div>
        <CostSummary
          useFormFeatures={useFormFeatures}
          netSummary={netSummary}
          vatSummary={vatSummary}
          grossSummary={grossSummary}
          isPendingRecalculate={isPendingRecalculate}
          calculateOriginalInvoiceValuesId={calculateOriginalInvoiceValuesId}
        />
      </div>
    </TheAccordion>
  );
};
