import React from 'react';
import './footer.scoped.scss';

export const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-content">Wszystkie prawa zastrzeżone...</div>
    </div>
  );
};
