import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonText, PageLoaderWrapper, TheDialog } from '@components';
import { useContractorStore } from '@store';
import { setFormValues, handleApiError, snackbarMessagesHandler, checkIsIncorrectDataFromGus, scrollToFirstError, BusinessEntity } from '@utils';
import { ContractorData, createNewContractors, getCompanyDataGus, getContractorById, GusCompanyData, updateContractor } from '@services';
import { transformContractorDataToFormData, ContractorFormData, transformFormDataToContractorData, compareEditedForm } from './ownerContractorForm.utils';
import { CountryPrefix } from './components/contractorForm/contractorForm.utils';
import { RequestErrorMessage } from '@interfaces';
import { useIsAllFalse } from '@hooks';
import { ContractorForm } from './components';
import './ownerContractorForm.scoped.scss';
import { addHotjarEvent } from '@lib';

interface Props {
  onCreatedContractor?: (closeModal: boolean, id: string, name: string) => void;
  isNested?: boolean;
  defaultNip?: string;
  contractorDefaultId?: string;
  getDataAfterSave?: (data: ContractorData) => void;
  defaultName?: string;
  isContractorSavedInDataBase?: boolean;
  addNewContractor?: boolean;
  showToggleSaveToDataBase?: boolean;
  onContractorModified?: () => void;
  onClickBackButton?: () => void;
  dialogView?: boolean;
}

export const OwnerContractorForm = ({
  onCreatedContractor,
  isNested = false,
  defaultNip = '',
  contractorDefaultId,
  getDataAfterSave,
  defaultName = '',
  isContractorSavedInDataBase,
  addNewContractor,
  showToggleSaveToDataBase,
  onContractorModified,
  onClickBackButton,
  dialogView,
}: Props) => {
  const [isSavePending, setIsSavePending] = useState(false);
  const [isPendingGusData, setIsPendingGusData] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [contractorId, setContractorId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [onStartIsCompany, setOnStartIsCompany] = useState(false);
  const [isCompanyFormType, setIsCompanyFormType] = useState(true);
  const [isFetchedGusData, setIsFetchedGusData] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [possibilityToOnlyGetData, setPossibilityToOnlyGetData] = useState(false);
  const [openDialogFormEdited, setOpenDialogFormEdited] = useState(false);
  const [isNipEnabled, setIsNipEnabled] = useState(true);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);

  const useFormProps = useForm<ContractorFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      country: CountryPrefix.Poland,
      nip: defaultNip ?? '',
      foreignTaxNumber: '',
      regon: '',
      name: '',
      city: '',
      zipCode: '',
      foreignZipCode: '',
      street: '',
      companyOrPerson: BusinessEntity.COMPANY,
      shortName: defaultName,
      saveContractor: true,
      emails: [],
      dummy: false,
    },
  });
  const {
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useFormProps;

  const navigate = useNavigate();
  const routeParams = useParams();
  const { selectedContractorId, selectedContractor, setSelectedContractor } = useContractorStore();

  const isLoadingPage = useIsAllFalse([isPending, isPendingGusData], 500);
  const watchedForm = watch();

  useEffect(() => {
    if (defaultNip.length === 10) fetchGusData();
  }, []);

  useEffect(() => {
    if (!isContractorSavedInDataBase && selectedContractor && isNested && !addNewContractor) setToFormData(selectedContractor);
    if (contractorDefaultId) {
      setContractorId(contractorDefaultId);
      setPossibilityToOnlyGetData(true);
      fetchContractorData(contractorDefaultId);
    }
  }, [contractorDefaultId, isContractorSavedInDataBase, addNewContractor]);

  useEffect(() => {
    if (isEdit) checkFormIsEdited();
  }, [watchedForm]);

  useEffect(() => {
    if (routeParams.contractorId) {
      setContractorId(routeParams.contractorId);
      fetchContractorData(routeParams.contractorId);
    }
    if (routeParams.companyId) {
      setCompanyId(routeParams.companyId);
    }
  }, [routeParams]);

  useEffect(() => {
    const isEdit = (!!contractorId && !!companyId) || !!contractorId || (!addNewContractor && addNewContractor !== undefined);

    setIsEdit(isEdit);
  }, [companyId, isFetchedGusData, onStartIsCompany, contractorId, addNewContractor]);

  const fetchContractorData = (id: string) => {
    if (selectedContractorId === id && selectedContractor) setToFormData(selectedContractor);
    else {
      setIsPending(true);
      getContractorById(id)
        .then((data: ContractorData) => {
          setToFormData(data);
          setSelectedContractor(data, id);
        })
        .catch(err => {
          if (err.status === 404 || err.status === 403) {
            navigate('/page-not-found');
            return;
          }
          handleApiError(err.data);
        })
        .finally(() => setIsPending(false));
    }
  };

  const fetchGusData = () => {
    if (!isNipEnabled) return;
    setIsPendingGusData(true);
    setIsNipEnabled(false);
    getCompanyDataGus(watchedForm.nip)
      .then(data => {
        onGusDataFetched(data);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingGusData(false));
  };

  const createContractor = (requestData: ContractorData, showSnackbar = true) => {
    addHotjarEvent('Create contractor data', 'action');
    createNewContractors(requestData)
      .then(id => {
        if (onContractorModified) onContractorModified();
        showSnackbar && snackbarMessagesHandler.contractorWasCreated();
        if (onCreatedContractor) {
          const name = requestData.friendlyName || requestData.name;
          onCreatedContractor(true, id, name);
          return;
        }
        navigateTo();
      })
      .catch((err: RequestErrorMessage[]) => {
        console.error(err);
        handleApiError(err);
      })
      .finally(() => setIsSavePending(false));
  };

  const setToFormData = (data: ContractorData) => {
    setEmails(data.emails);
    setIsCompanyFormType(data.isBusiness);
    setOnStartIsCompany(data.isBusiness);
    data.emails.forEach((email: string, index: number) => {
      const field = `emails.${index}` as const;
      setValue(field, email);
      trigger(field);
    });
    if (data.country !== CountryPrefix.Poland) {
      clearErrors('foreignZipCode');
      clearErrors('foreignTaxNumber');
    }
    const formData = transformContractorDataToFormData(data);
    setFormValues(setValue, formData);
  };

  const editContractor = (requestData: ContractorData) => {
    addHotjarEvent('Edit contractor data', 'action');
    updateContractor(requestData, contractorId)
      .then(() => {
        if (onContractorModified) onContractorModified();
        if (onCreatedContractor) {
          const name = requestData.friendlyName || requestData.name;
          setSelectedContractor(requestData, contractorId);
          onCreatedContractor(true, contractorId, name);
          return;
        }
        setSelectedContractor(null, '');
        snackbarMessagesHandler.contractorWasEdited();
        navigateTo();
      })
      .catch((err: RequestErrorMessage[]) => {
        console.error(err);
        handleApiError(err);
      })
      .finally(() => setIsSavePending(false));
  };

  const onSubmit = (data: ContractorFormData) => {
    const requestData = transformFormDataToContractorData(data, companyId);
    if (getDataAfterSave) getDataAfterSave(requestData);
    setIsSavePending(true);
    const newDummy = data.saveContractor === false;

    if (data.dummy === true) {
      if (newDummy) {
        editContractor({ ...requestData, dummy: true });
      } else {
        createContractor({ ...requestData, dummy: false }, false);
      }
    } else if (data.dummy === false && newDummy === true) {
      createContractor({ ...requestData, dummy: true }, false);
    } else if (data.dummy === false && newDummy === false) {
      contractorId ? editContractor(requestData) : createContractor(requestData);
    }
  };

  const onGusDataFetched = (data: GusCompanyData) => {
    clearErrors('nip');
    if (checkIsIncorrectDataFromGus(data)) {
      setIsNipEnabled(true);
      snackbarMessagesHandler.gusNoData();
      return;
    }

    const nip = watchedForm.nip;

    const { companyName, address = {} } = data;
    const formDataValues = {
      ...data,
      ...address,
      nip,
    };

    snackbarMessagesHandler.gusDataWasFetched();
    setIsNipEnabled(false);
    setIsCompanyFormType(true);

    setIsFetchedGusData(true);
    setValue('name', companyName);
    setFormValues(setValue, formDataValues);

    clearErrors('name');
    if (formDataValues.address?.street) clearErrors('street');
    if (formDataValues.address?.city) clearErrors('city');
    if (formDataValues.address?.zipCode) clearErrors('zipCode');
  };

  const handleRadioChange = (value: string) => {
    setIsCompanyFormType(BusinessEntity.COMPANY === value);
    setValue('nip', '');
    setValue('regon', '');
    clearErrors('nip');
    clearErrors('regon');
  };

  const onClickBack = () => {
    addHotjarEvent('Back from contractor form', 'action');
    if (!isFormChanged) reset();
    if (isEdit && isFormChanged) return setOpenDialogFormEdited(true);
    navigateTo();
  };

  const onCloseDialogFormEdited = (agree: boolean) => {
    setOpenDialogFormEdited(false);
    if (!agree) {
      navigateTo();
      return;
    }
    handleSubmit(
      f => onSubmit(f),
      () => {
        if (Object.keys(errors).length) scrollToFirstError(Object.keys(errors));
        snackbarMessagesHandler.contractorFormNeedCorrection();
      }
    )();
  };

  const navigateTo = () => {
    if (isNested) return;
    if (routeParams.id) return onClickBackButton?.();

    if (routeParams.companyId) {
      navigate(`/owner-company-list/details/${companyId}`);
    } else {
      navigate(`/contractors-list`);
    }
  };

  const checkFormIsEdited = () => {
    const isFormFormatted = compareEditedForm(transformContractorDataToFormData(selectedContractor), watchedForm);
    setIsFormChanged(isFormFormatted);
  };

  return (
    <div className={`modal-width owner-contractor-form-wrapper ${!isNested && !onClickBackButton ? 'standard-layout-spacing-g' : ''}`}>
      {!isNested && <ButtonText testcy="go-back" onClick={onClickBack} content="powrót" startIcons="back" />}
      {!dialogView && <div className="title-g">{isEdit ? 'Edycja danych kontrahenta' : 'Dodaj kontrahenta'}</div>}
      <PageLoaderWrapper isLoading={isLoadingPage}>
        <FormProvider {...useFormProps}>
          <ContractorForm
            errors={errors}
            isCompanyFormType={isCompanyFormType}
            isSavePending={isSavePending}
            handleRadioChange={handleRadioChange}
            onSubmit={onSubmit}
            useFormProps={useFormProps}
            fetchGusData={fetchGusData}
            isNipEnabled={isNipEnabled}
            isEdit={isEdit}
            possibilityToOnlyGetData={possibilityToOnlyGetData}
            showToggleSaveToDataBase={showToggleSaveToDataBase}
            emails={emails}
            dialogView={dialogView}
          />
        </FormProvider>
      </PageLoaderWrapper>
      <TheDialog
        testcy="leave-without-saving"
        open={openDialogFormEdited}
        onClose={onCloseDialogFormEdited}
        approveButtonText="Zapisz i wyjdź"
        approveButtonWidth="140px"
        rejectButtonText="Tak"
        rejectButtonWidth="140px"
        title="Czy chcesz opuścić formularz bez zapisania zmian?"
      >
        <></>
      </TheDialog>
    </div>
  );
};
