import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
  size?: number;
}

export const EditIcon = ({ colorType = '', size = 16 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path d="M8.16504 12.8862H13.0001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.52001 1.78767C8.0371 1.16967 8.96666 1.07905 9.59748 1.58563C9.63236 1.61312 10.753 2.48367 10.753 2.48367C11.446 2.90261 11.6613 3.79322 11.2329 4.47287C11.2102 4.50927 4.87463 12.4341 4.87463 12.4341C4.66385 12.6971 4.34389 12.8523 4.00194 12.856L1.57569 12.8865L1.02902 10.5727C0.952442 10.2473 1.02902 9.90566 1.2398 9.64271L7.52001 1.78767Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.34766 3.2583L9.98249 6.04972" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
