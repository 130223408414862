import { UserRepository } from '@amityco/ts-sdk';
import { dateFormatter } from '@lib';

export type InvoiceTag = {
  id: string;
  number: string;
};

export type ChatMessage = Amity.Message<any> & {
  userDisplayName: string;
  isConsequent: boolean;
  isIncoming: boolean;
  isDateChanged: boolean;
  invoiceTag: InvoiceTag;
};

export type ChatMessageCollection = {
  data: ChatMessage[];
  loading: boolean;
  error?: any;
  onNextPage: () => void;
  hasNextPage: boolean;
};

export const createChatMessageCollection = (collection: Amity.LiveCollection<Amity.Message<any>>, userId: string) => {
  const toChatMessage = createChatMessageMapper(userId);
  const data = collection.data.map(toChatMessage);

  return {
    ...collection,
    data,
  };
};

const createChatMessageMapper =
  (userId: string) =>
  (message: Amity.Message<any>, index: number, collection: Amity.Message<any>[]): ChatMessage => {
    const user = UserRepository.getUserByIds.locally([message.creatorId]);
    const userDisplayName = user ? user.data[0].displayName : 'Użytkownik';

    const nextMessage = collection[index + 1];
    const isConsequent = nextMessage && nextMessage.creatorId === message.creatorId;
    const isIncoming = message.creatorId !== userId;
    const invoiceTag = message.metadata && {
      id: message.metadata?.id,
      number: message.metadata?.number,
    };

    const currentDate = dateFormatter.standardFormat(message.createdAt);
    const nextDate = nextMessage && dateFormatter.standardFormat(nextMessage.createdAt);
    const isDateChanged = currentDate !== nextDate;

    return {
      ...message,
      userDisplayName,
      isConsequent,
      isIncoming,
      isDateChanged,
      invoiceTag,
    };
  };
