import { useEffect, useState } from 'react';
import { CompanyData, getMyCompany } from '@services';
import { RequestErrorMessage } from '@interfaces';
import { handleApiError } from '@utils';
import { useContractorStore } from '@store';

interface GetMyCompanyDataInterface {
  isPendingOwnerData: boolean;
  fetchOwnerMyCompanyData: () => void;
}

export const useGetMyCompanyData = (): GetMyCompanyDataInterface => {
  const [isPendingOwnerData, setIsPendingOwnerData] = useState(false);
  const { setOwnerMyCompanyData, setHasAccountingClients } = useContractorStore();

  useEffect(() => {
    fetchOwnerMyCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOwnerMyCompanyData = () => {
    setIsPendingOwnerData(true);
    getMyCompany()
      .then((data: CompanyData) => {
        if (data) {
          setOwnerMyCompanyData(data);
          setHasAccountingClients(data.hasAccountingClients);
        }
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingOwnerData(false));
  };

  return {
    isPendingOwnerData,
    fetchOwnerMyCompanyData: () => fetchOwnerMyCompanyData(),
  };
};
