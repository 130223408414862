import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
  size?: number;
}

export const HistoryIcon = ({ colorType = '', size = 16 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        d="M12.9012 5.16236C11.554 2.78924 8.68099 1.63468 6.00576 2.5582C3.00416 3.59692 1.40736 6.87498 2.44608 9.87658C3.47904 12.8846 6.75651 14.482 9.76451 13.4427C11.625 12.8014 12.946 11.307 13.4336 9.54314"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.0559 3.05371V5.16507H10.9561" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.6964 9.6444L7.66504 8.43032V5.80762" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
