import { TableHeaderData, TableRowData } from '@components';
import { dateFormatter } from '@lib';
import { EmployeesListData } from '@services';
import { EmployeesStatusEnum, translateTypeOfContract } from '@utils';
import React from 'react';
import { InitialsAvatar } from 'src/components/miscellaneous/initialsAvatar/initialsAvatar';
import { EmployeesContractEnum } from 'src/services/employees';

export const tableHeader: TableHeaderData[] = [
  {
    label: 'Imię i nazwisko ',
    data: 'fullName',
    minWidth: 240,
    isSortable: true,
  },
  {
    label: 'PESEL',
    data: 'pesel',
    maxWidth: 160,
    minWidth: 160,
    isSortable: true,
    customCellClass: 'pesel-cell',
  },
  {
    label: 'Adres zamieszkania ',
    data: 'address',
    minWidth: 240,
    isSortable: true,
  },
  {
    label: 'Start umowy',
    data: 'contractStartDate',
    maxWidth: 160,
    minWidth: 160,
    isSortable: true,
  },
  {
    label: 'Koniec umowy',
    data: 'contractEndDate',
    maxWidth: 160,
    minWidth: 160,
    isSortable: true,
    nowrap: true,
  },
  {
    label: 'Umowa zatrudnienia',
    data: 'typeOfEmployment',
    maxWidth: 220,
    minWidth: 220,
    isSortable: true,
    customCellClass: 'type-of-employment-cell',
  },
  {
    label: '',
    data: 'action',
    maxWidth: 52,
    minWidth: 52,
    action: true,
    isSortable: false,
  },
];

export const employeeDataToTableRow = (data: EmployeesListData): TableRowData => {
  return {
    id: data?.id?.toString() || '',
    fullName: {
      content: (
        <div className="employee-avatar-wrapper">
          <InitialsAvatar testcy={`employee-initials-avatar-${data.id}`} names={[data.firstName, data.lastName]} personId={+data.id} />
          <div className="employee-name-wrapper">
            <div className="ellipsis-text-one-line-g employee-name">{data.fullName}</div>
            <div className="ellipsis-text-one-line-g employee-position">{data.position}</div>
          </div>
        </div>
      ),
    },
    pesel: {
      content: data.pesel || '',
    },
    address: {
      content: <div className="ellipsis-text-one-line-g">{data.address}</div>,
    },
    contractStartDate: {
      content: dateFormatter.standardFormat(data.contractStartDate) || '',
    },
    contractEndDate: {
      content: dateFormatter.standardFormat(data.contractEndDate) || '',
    },
    typeOfEmployment: {
      content: translateTypeOfContract(data.typeOfEmployment) || '',
    },
  };
};

export const EmployeeAction = {
  Preview: 'preview',
  Unregister: 'unregister',
} as const;
export type EmployeeAction = typeof EmployeeAction[keyof typeof EmployeeAction];

export const transformMenuItemsEmployeeList = (dataList: EmployeesListData[]) => {
  return dataList.map(data => {
    return [
      {
        label: 'Podgląd pracownika',
        value: EmployeeAction.Preview,
      },
      {
        label: 'Wyrejestruj pracownika',
        value: EmployeeAction.Unregister,
        disabled: data?.status === EmployeesStatusEnum.UNREGISTER || data?.typeOfEmployment === EmployeesContractEnum.CivilLawContract,
      },
    ];
  });
};
