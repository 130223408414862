import { Statuses, TableRowData, TheCheckbox, TheHeaderFilter, ThePrice } from '@components';
import { CustomObject, InvoiceStatus, Option } from '@interfaces';
import { dateFormatter } from '@lib';
import { Invoice, ReminderData } from '@services';
import {
  AccountantStatusType,
  BulkOperationsEnum,
  InvoiceStatusType,
  InvoicesSummaryTypeEnum,
  formatNumberToCurrency,
  invoiceStatusAccountantOptions,
  invoiceStatusOwnerOptions,
  isDefined,
  stringToNumberSecondOptions,
} from '@utils';
import React from 'react';
import { Control, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { EmailFormData } from 'src/features/shared/sharedEmailForm/sharedEmailForm.utils';
import { RecordAmountFormData } from 'src/features/shared/sharedInvoiceDetails/components/invoicePopups/components/invoiceRecordPaymentForm/invoiceRecordPaymentForm.utils';
import { ReminderFormData } from 'src/features/shared/sharedReminderForm/sharedReminderForm.utils';
import { DueDate } from '../dueDate/dueDate';
import { BulkOperationIconsWrapper } from './components/bulkOperationIconsWrapper';

export interface RefFunctions {
  saveParams: () => void;
  resetFilters(): () => void;
}

export const getTableHeader = (
  control: Control,
  getValues: UseFormGetValues<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  onChangeCheckbox: (value: boolean) => void,
  headerCheckboxIsPressed: boolean,
  onBulkOperationsInvoice: (action: BulkOperationsEnum) => void,
  companyId: string,
  onSetHeaderFilter: (filter: InvoiceStatus[]) => void
) => {
  return [
    {
      label: '',
      data: 'checkbox',
      keyPrefix: 'checkbox',
      width: 60,
      minWidth: 60,
      isSortable: false,
      customCellClass: 'checkbox-cell-costs',
      component: (
        <TheCheckbox
          testcy="incomes-table-header-checkbox"
          control={control}
          name={'check-all-on-page'}
          customColor="black-owner-view"
          defaultValue={headerCheckboxIsPressed}
          validation={{ onChange: e => onChangeCheckbox(e?.target?.value) }}
          size="small"
        />
      ),
    },
    {
      label: 'Nr dokumentu',
      data: 'number',
      minWidth: 200,
      isSortable: true,
      component: (
        <BulkOperationIconsWrapper
          headerCheckboxIsPressed={headerCheckboxIsPressed}
          onBulkOperationsInvoice={onBulkOperationsInvoice}
          sendInvoicesToInsertTooltip={sendInvoicesToInsertTooltip}
        />
      ),
    },
    {
      label: 'Podmiot',
      data: 'contractor',
      minWidth: 260,
      isSortable: true,
    },
    {
      label: `Status`,
      data: 'status',
      minWidth: 180,
      isSortable: false,
      component: (
        <TheHeaderFilter
          control={control}
          getValues={getValues}
          setValue={setValue}
          onSetHeaderFilter={onSetHeaderFilter}
          name={'status'}
          menuOptions={companyId ? invoiceStatusAccountantOptions : invoiceStatusOwnerOptions}
        />
      ),
    },
    {
      label: 'Płatność',
      data: 'dueDate',
      minWidth: 160,
      isSortable: true,
    },

    {
      label: 'Kwota',
      data: 'grossValue',
      minWidth: 160,
      isSortable: true,
    },
    {
      label: 'Wystawiono',
      data: 'issueDate',
      minWidth: 160,
      isSortable: true,
    },
    {
      label: '',
      data: 'action',
      width: 60,
      minWidth: 60,
      action: true,
      isSortable: false,
    },
  ];
};

export const invoiceDataToTableRow = (
  dataRow: Invoice,
  control: Control,
  onChangeCheckbox: (value: boolean, id: number, name: string, net: number, gross: number, data: Invoice[]) => void,
  isAccountant: boolean,
  companyId: string,
  data: Invoice[],
  index: number
): TableRowData => {
  const id = dataRow?.id?.toString();

  const status = isAccountant ? dataRow.accountingStatus : dataRow.status;
  const isConfirmed = status !== 'Draft';
  return {
    id: id,
    checkbox: {
      content: (
        <div key={`checkbox-${id}`}>
          <TheCheckbox
            testcy={`incomes-table-checkbox-row${index}`}
            control={control}
            name={`checkbox-${id}`}
            validation={{
              onChange: e => onChangeCheckbox(e?.target?.value, dataRow.id, `checkbox-${id}`, dataRow.netValue, dataRow.grossValue, data),
            }}
            customColor={'grey'}
            size="small"
          />
        </div>
      ),
    },
    status: {
      content: <Statuses status={status} />,
    },
    divider: { content: '' },
    deliveryDate: { content: dateFormatter.standardFormat(dataRow.deliveryDate) },
    issueDate: { content: dateFormatter.standardFormat(dataRow.issueDate) },
    number: { content: dataRow.number },
    contractor: {
      content: <div className="ellipsis-text-two-lines-g">{dataRow.contractor}</div>,
    },
    nip: { content: dataRow.nip },
    netValue: { content: formatNumberToCurrency(dataRow.netValue) },
    vatValue: { content: formatNumberToCurrency(dataRow.vatValue) },
    grossValue: {
      content: <ThePrice netValue={dataRow.netValue} grossValue={dataRow.grossValue} />,
    },
    dueDate: {
      content: <DueDate invoice={dataRow} />,
    },
    stashedData: {
      isConfirmed,
      status,
      data: dataRow,
      isCorrection: !!dataRow.correctedInvoiceId,
    },
  };
};
export const transformFormDataToEmailData = (data: EmailFormData) => {
  const transformedData = {
    recipients: [...data.emailTo],
    subject: data.subject,
    body: data.emailMessage,
    sendCopyToSender: data.sendCopy,
  };
  return transformedData;
};
export const transformFormDataToReminderData = (data: ReminderFormData): ReminderData => {
  const transformedData = {
    recipients: [...data.emailTo],
    subject: data.subject,
    body: data.emailMessage,
    sendCopyToSender: data.sendCopy,
    invoiceAttachment: data.invoiceAttachment,
  };
  return transformedData;
};

export type RecordAmountData = {
  amountPaid: number;
  date: string;
  notes: string;
};

export const transformFormDataToRecordAmountData = (data: RecordAmountFormData): RecordAmountData => {
  const transformedData = {
    amountPaid: stringToNumberSecondOptions(data.paymentAmount),
    date: dateFormatter.objectToString(data.paymentDate, 'yyyy-MM-dd'),
    notes: data.notes,
  };
  return transformedData;
};

export const getSelectedInvoices = (data: CustomObject): number[] => {
  const keys = Object.keys(data);
  return keys
    .filter(key => data[key] === true)
    .filter(key => typeof key === 'string')
    .map(key => +(key as string).split('-')[1])
    .filter(id => !isNaN(id));
};
export const concatWithoutDuplicates = (arr1: string[], arr2: string[]) => {
  const arr = [...arr1, ...arr2];
  return arr.filter((item, index) => arr.indexOf(item) === index);
};
export const openInNewTab = (url?: string): void => {
  const newWindow = window.open(url, '_blank');
  if (newWindow) newWindow.opener = null;
};

export const unregisterCheckboxes = (data: CustomObject, setValue: UseFormSetValue<FieldValues>): void => {
  const keys = Object.keys(data);
  keys.forEach(key => {
    if (typeof key === 'string' && key.includes('checkbox-') && !key.includes('checkbox-filter-')) {
      setValue(key, false);
    }
  });
};

export type DateRangeReturn = {
  startDate: Date | null;
  endDate: Date | null;
} | void;

export type SummaryTabsType = {
  id: InvoicesSummaryTypeEnum;
  name: string;
  isActive: boolean;
  amount: number;
};
export type InvoiceConfirmDialogDataType = {
  numberOfInvoicesChecked: number;
  invoicesNotConfirmed: number;
  dialogTitle: string;
  action: BulkOperationsEnum;
};

export type InvoicePendingStates = {
  data: boolean;
  chart: boolean;
  image: boolean;
  confirm: boolean;
  delete: boolean;
  email: boolean;
  reminder: boolean;
  recordPayment: boolean;
};

export const IncomesListAction = {
  Preview: 'preview',
  EditOrCorrect: 'editOrCorrect',
  Confirm: 'confirm',
  Delete: 'delete',
  Download: 'download',
  CreateSimilar: 'createSimilar',
  GenerateDuplicate: 'generateDuplicate',
  Send: 'send',
  MarkAsSent: 'markAsSent',
  SendReminder: 'sendReminder',
  RecordPayment: 'recordPayment',
  MarkAsPaid: 'markAsPaid',

  SendToInsert: 'sendToInsert',
} as const;
export type IncomesListAction = typeof IncomesListAction[keyof typeof IncomesListAction];

export const translateAction = (action: BulkOperationsEnum): string => {
  switch (action) {
    case BulkOperationsEnum.CONFIRM:
      return `Zatwierdzono:`;
    case BulkOperationsEnum.MARK_AS_PAID:
      return `Oznaczono jako opłacone:`;
    case BulkOperationsEnum.SEND_TO_INSERT:
      return `Wysłano:`;
  }
};

export type CheckboxStateDataType = {
  checkedNetValue?: number;
  checkedGrossValue?: number;
  totalInvoicesCount?: number;
  checkboxTimeout?: boolean;
  time?: any;
  totalNetValue?: number;
  totalGrossValue?: number;
  numberOfInvoicesChecked?: number;
  checkboxesSelected?: { id: number; name: string; net: number; gross: number }[];
  headerCheckboxIsPressed?: boolean;
  selectedAll?: boolean;
};

export const getActionMenuItems = (
  dataList: Invoice[],
  companyId: string,
  insertCredential: boolean,
  accountingCredential: boolean,
  pendingStates: InvoicePendingStates
): Option[][] => {
  return dataList.map(data => {
    if (companyId) {
      const isDisabledSendToInsert = !insertCredential || !accountingCredential || data.accountingStatus !== AccountantStatusType.New;

      return [
        {
          label: 'Podgląd faktury',
          value: IncomesListAction.Preview,
        },
        {
          label: 'Pobierz fakturę',
          value: IncomesListAction.Download,
        },
        {
          label: 'Wyślij do systemu księgowego',
          value: IncomesListAction.SendToInsert,
          disabled: isDisabledSendToInsert,
          additionalMessage: sendInvoicesToInsertTooltip(insertCredential, accountingCredential),
        },
      ];
    }
    const { status, isSent, originalInvoiceId, correctionId, accountingStatus } = data;

    const isDraft = status === InvoiceStatusType.Draft;
    const isPaid = status === InvoiceStatusType.Paid;
    const isCorrection = isDefined(originalInvoiceId);
    const wasCorrected = isDefined(correctionId);
    const isEditAvailable = isDraft;
    const isEditOrCorrectionDisabled =
      wasCorrected || (isEditAvailable && accountingStatus === (AccountantStatusType.Sending || AccountantStatusType.SentToAccountingService));

    const isConfirmDisabled = !isDraft || pendingStates.confirm;
    const confirmAdditionalMessage = isDraft ? 'Można zatwierdzić jedynie szkic faktury' : pendingStates.confirm ? 'Zatwierdzanie faktury...' : null;

    const isDeleteDisabled = !isDraft || pendingStates.delete;
    const deleteAdditionalMessage = isDeleteDisabled ? 'Nie można usunąć zatwierdzonej faktury' : pendingStates.delete ? 'Usuwanie faktury...' : null;

    const isCreateSimilarDisabled = isCorrection;
    const createSimilarAdditionalMessage = isCreateSimilarDisabled ? 'Nie można wystawić podobnej faktury do korekty' : null;

    const isGenerateDuplicateDisabled = isDraft;
    const generateDuplicateAdditionalMessage = isGenerateDuplicateDisabled ? 'Faktura musi być zatwierdzona' : null;

    const isSendDisabled = isDraft || pendingStates.email;
    const sendAdditionalMessage = isSendDisabled ? 'Faktura musi być zatwierdzona' : pendingStates.email ? 'Wysyłanie emaila...' : null;

    const isMarkAsSentDisabled = isSent || isDraft;
    const markAsSentAdditionalMessage = isSent ? 'Faktura jest już wysłana' : isDraft ? 'Faktura musi być zatwierdzona' : null;

    const isSendReminderDisabled = !isSent || isPaid || pendingStates.reminder;
    const sendReminderAdditionalMessage = isPaid
      ? 'Faktura jest już opłacona'
      : !isSent
      ? 'Faktura musi być wysłana'
      : pendingStates.reminder
      ? 'Wysyłanie przypomnienia...'
      : null;

    const arePaymentActionsDisabled = isDraft || isPaid || pendingStates.recordPayment;
    const paymentActionsAdditionalMessage = isPaid
      ? 'Faktura jest już opłacona'
      : isDraft
      ? 'Faktura musi być zatwierdzona'
      : pendingStates.recordPayment
      ? 'Rejestrowanie wpłaty...'
      : null;

    const menuItems: Option[] = [
      {
        label: 'Podgląd faktury',
        value: IncomesListAction.Preview,
      },
      {
        label: isDraft ? 'Edytuj' : 'Skoryguj fakturę',
        value: IncomesListAction.EditOrCorrect,
        disabled: isEditOrCorrectionDisabled,
      },
      {
        label: 'Zatwierdź fakturę',
        value: IncomesListAction.Confirm,
        disabled: isConfirmDisabled,
        additionalMessage: confirmAdditionalMessage,
      },
      {
        label: 'Usuń',
        value: IncomesListAction.Delete,
        disabled: isDeleteDisabled,
        additionalMessage: deleteAdditionalMessage,
      },
      {
        label: 'Pobierz fakturę',
        value: IncomesListAction.Download,
      },
      {
        label: 'Wystaw podobną',
        value: IncomesListAction.CreateSimilar,
        disabled: isCreateSimilarDisabled,
        additionalMessage: createSimilarAdditionalMessage,
      },
      {
        label: 'Generuj duplikat',
        value: IncomesListAction.GenerateDuplicate,
        disabled: isGenerateDuplicateDisabled,
        additionalMessage: generateDuplicateAdditionalMessage,
      },
      {
        label: 'Wyślij',
        value: IncomesListAction.Send,
        disabled: isSendDisabled,
        additionalMessage: sendAdditionalMessage,
      },
      {
        label: 'Oznacz jako wysłane',
        value: IncomesListAction.MarkAsSent,
        disabled: isMarkAsSentDisabled,
        additionalMessage: markAsSentAdditionalMessage,
      },
      {
        label: 'Wyślij upomnienie',
        value: IncomesListAction.SendReminder,
        disabled: isSendReminderDisabled,
        additionalMessage: sendReminderAdditionalMessage,
      },
      {
        label: 'Zarejestruj wpłatę',
        value: IncomesListAction.RecordPayment,
        disabled: arePaymentActionsDisabled,
        additionalMessage: paymentActionsAdditionalMessage,
      },
      {
        label: 'Oznacz jako opłacona',
        value: IncomesListAction.MarkAsPaid,
        disabled: arePaymentActionsDisabled,
        additionalMessage: paymentActionsAdditionalMessage,
      },
    ];
    return menuItems;
  });
};

export const sendInvoicesToInsertTooltip = (insertCredential: boolean, accountingCredential: boolean) => {
  if (!insertCredential && !accountingCredential) {
    return 'Przed wysłaniem faktury należy uzupełnić dane dostępowe do bazy danych InsERT po stronie firmy księgowej oraz przedsiębiorcy.';
  }
  if (!insertCredential) {
    return 'Przed wysłaniem faktury należy uzupełnić dane dostępowe do bazy danych InsERT po stronie firmy księgowej.';
  }
  if (!accountingCredential) {
    return 'Przed wysłaniem faktury należy uzupełnić dane dostępowe do bazy danych InsERT po stronie przedsiębiorcy.';
  }
  return '';
};
