import { useEffect, useState } from 'react';
import { getInsertIntegrationData, setIntegrationToInsert } from '@services';
import { IntegrationStatus, handleApiError } from '@utils';
import { RequestErrorMessage } from '@interfaces';
import { addHotjarEvent } from '@lib';

interface UseHoldInsertIntegration {
  isPendingGetHoldInsertIntegrationData: boolean;
  isPendingSetHoldInsertIntegrationData: boolean;
  integrationStatus: IntegrationStatus;
  setHoldIntegrationToInsert: (isStopped: boolean) => void;
}

export const useHoldInsertIntegration = (): UseHoldInsertIntegration => {
  const [isPendingGetHoldInsertIntegrationData, setIsPendingGetHoldInsertIntegrationData] = useState(false);
  const [isPendingSetHoldInsertIntegrationData, setIsPendingSetHoldInsertIntegrationData] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState<IntegrationStatus>(null);

  useEffect(() => {
    fetchHoldInsertIntegrationData();
  }, []);

  const fetchHoldInsertIntegrationData = () => {
    addHotjarEvent('Fetch hold InsERT integration');
    setIsPendingGetHoldInsertIntegrationData(true);
    getInsertIntegrationData()
      .then((data: IntegrationStatus) => {
        setIntegrationStatus(data);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingGetHoldInsertIntegrationData(false));
  };
  const setHoldInsertIntegrationData = (isStopped: boolean) => {
    addHotjarEvent('Set hold InsERT integration');
    setIsPendingSetHoldInsertIntegrationData(true);
    setIntegrationToInsert(isStopped)
      .then((data: IntegrationStatus) => {
        setIntegrationStatus(data);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingSetHoldInsertIntegrationData(false));
  };

  return {
    isPendingGetHoldInsertIntegrationData,
    isPendingSetHoldInsertIntegrationData,
    integrationStatus,
    setHoldIntegrationToInsert: isStopped => setHoldInsertIntegrationData(isStopped),
  };
};
