import React, { PropsWithChildren, useEffect } from 'react';
import { AuthProvider as OidcAuthProvider, useAuth } from 'oidc-react';
import { useUserStore } from '@store';
import { IsLoadingPage } from '@layout';
import { initializeGoogleAnalitics, initializeHotjar } from '@lib';
import { userManager } from './userManager';

const AuthProviderInternal = ({ children }: PropsWithChildren) => {
  const { isLoading } = useAuth();

  const { userData } = useUserStore();

  const userId = userData?.profile?.sub;

  useEffect(() => {
    if (userId) {
      initializeGoogleAnalitics(userId);
      initializeHotjar(userId);
    }
  }, [userId]);

  if (isLoading) return <IsLoadingPage />;

  return <>{children}</>;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  return (
    <OidcAuthProvider userManager={userManager}>
      <AuthProviderInternal>{children}</AuthProviderInternal>
    </OidcAuthProvider>
  );
};
