import React from 'react';

type Props = {
  colorType?: 'white' | 'primary' | 'disabled' | 'black' | 'grey600' | 'grey500' | '';
  size?: string | number;
};

export const CloseIcon = ({ colorType = '', size = 20 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 5L15 15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 5L5 15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
