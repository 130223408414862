import ReactGA from 'react-ga4';
import { appConfig } from 'src/appConfig';

export const initializeGoogleAnalitics = (userId: string) => {
  const key = appConfig.googleAnalytics.key;
  if (!key) return;
  ReactGA.initialize(key, {
    testMode: false,
    gaOptions: {
      userId,
    },
  });
  ReactGA.isInitialized = true;
};
