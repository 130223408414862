import { useState } from 'react';
import { InvoiceTag } from '../model/chatMessage';

export type MessageComposer = {
  currentMessage: string;
  updateMessage: (message: string) => void;
  addTag: (invoiceId: number) => void;
  reset: () => void;
  currentTag?: InvoiceTag;
};

type UseMessageComposerProps = {
  tagFactory: (invoiceId: number) => InvoiceTag;
};

export const useMessageComposer = ({ tagFactory }: UseMessageComposerProps): MessageComposer => {
  const [currentMessage, setCurrentMessage] = useState('');
  const [tags, setTags] = useState<InvoiceTag[]>([]);

  const addTag = (invoiceId: number) => {
    const index = tags.findIndex(t => t.id === invoiceId.toString());
    if (index === -1) {
      const tag = tagFactory(invoiceId);
      setTags([...tags, tag]);
    }
  };

  const reset = () => {
    setCurrentMessage('');
    setTags([]);
  };

  const updateMessage = (message: string) => {
    let tagStackCopy = [...tags];

    const result = tags.map((tag, index) => ({
      index,
      display: `@[${tag.number}]`,
    }));

    result.forEach(({ index, display }) => {
      if (!message.includes(display)) {
        tagStackCopy.splice(index, 1);
      }
    });

    setTags(tagStackCopy);
    setCurrentMessage(message);
  };

  return {
    currentMessage,
    updateMessage,
    addTag,
    reset,
    currentTag: tags[tags.length - 1],
  };
};
