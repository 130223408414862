import { countries } from '@utils';
import { Option } from '@interfaces';

export const CountryPrefix = {
  Poland: 'PL',
  Other: 'Other',
} as const;
export type CountryPrefix = typeof CountryPrefix[keyof typeof CountryPrefix];

const ascendingByLabel = (a: Option, b: Option) => (a.label > b.label ? 1 : -1);

export const getCountries = () => {
  const countryName = new Intl.DisplayNames(['pl'], { type: 'region' });
  const selectOptions = countries.map(country => {
    let value = Object.keys(country)[0] === 'GR' ? 'EL' : Object.keys(country)[0];
    let name = countryName.of(Object.keys(country)[0]);
    return { value: value, label: name };
  });

  const sortedArray = selectOptions.sort(ascendingByLabel);
  const indexOfLuxembourg = sortedArray.findIndex(item => item.label === 'Luksemburg');
  const indexOfIreland = sortedArray.findIndex(item => item.label === 'Irlandia');
  sortedArray.splice(indexOfIreland + 1, 0, { value: 'XI', label: 'Irlandia Północna' });
  const indexOfPoland = sortedArray.findIndex(item => item.label === 'Polska');

  sortedArray.splice(indexOfLuxembourg + 1, 0, { value: 'LV', label: 'Łotwa' });
  sortedArray.pop();
  sortedArray.splice(indexOfPoland + 1, 1);
  sortedArray.unshift({ value: 'PL', label: 'Polska' });

  return selectOptions;
};
