import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { AutocompleteInput, LoaderIcon, NumberInput, TextInputNew, TheCheckbox, TheSelect } from '@components';
import {
  DeductVATEnum,
  DeductionInCostsEnum,
  DeductionLimitEnum,
  VehicleOperationEnum,
  ZwOptions,
  allowVehicleOptions,
  deductVATOptions,
  focusOnNextFormInput,
  formErrorMsg,
  formatNumberToCurrency,
  inCostsOptions,
  standardValidators,
  stringToNumberSecondOptions,
  tooltipMessages,
  focusOnNextFormInput_FORCE,
} from '@utils';
import { useFilter } from '@hooks';
import { useInvoiceStore } from '@store';
import { InvoiceCalculatedData } from '@services';
import { useAccountingTemplate, useVatTemplate } from 'src/features/shared/sharedCostList/components/inDevelopmentTab/hooks';

import './costSummary.scoped.scss';
import './costSummary.scss';

interface Props {
  grossSummary: string;
  vatSummary: string;
  netSummary: string;
  isPendingRecalculate?: boolean;
  useFormFeatures: Partial<UseFormReturn<FieldValues>>;
  hasZW?: boolean;
  calculateOriginalInvoiceValuesId?: InvoiceCalculatedData;
}

export const CostSummary = ({
  hasZW = false,
  useFormFeatures,
  grossSummary,
  vatSummary,
  netSummary,
  isPendingRecalculate,
  calculateOriginalInvoiceValuesId,
}: Props) => {
  const [hasAnotherZW, setHasAnotherZW] = useState(false);
  const { control, watch, resetField, setValue, trigger, clearErrors } = useFormFeatures;
  const { isConfiguredAccountingTemplate, accountingTemplateDataOptions } = useAccountingTemplate();
  const { vatTemplateDataOptions } = useVatTemplate();
  const watchZwOptions = watch('zwOptions');
  const watchDeductionType = watch('deductionType');
  const watchDeductionPart = watch('deductionPart');
  const watchDeductionPercent = watch('deductionPercent');
  const watchDeductibleLimit = watch('deductibleLimit');
  const watchVehicleAcquisitionValue = watch('vehicleAcquisitionValue');
  const { isCorrected } = useInvoiceStore();
  const { pathname } = useLocation();

  const { filteredOptions: vatSchemeOptions, setFilterValue } = useFilter(accountingTemplateDataOptions);

  useEffect(() => {
    if (!hasZW) {
      setHasAnotherZW(false);
    } else if (hasZW && watchZwOptions === 'Other') {
      setHasAnotherZW(true);
    }
  }, [hasZW, watchZwOptions]);

  const isCostForm = (): boolean => {
    return pathname.includes('edit-cost');
  };

  const calculateProportion = (value?: number, limit?: number) => {
    const vehicleValueLimit = limit || stringToNumberSecondOptions(watchDeductibleLimit);
    const vehicleAcquisitionValue = value || stringToNumberSecondOptions(watchVehicleAcquisitionValue);
    if (vehicleAcquisitionValue === 0) {
      setValue('proportionLimit', '0%');
      trigger('proportionLimit');
      return;
    }
    if (vehicleValueLimit >= vehicleAcquisitionValue) {
      setValue('proportionLimit', '100%');
      trigger('proportionLimit');
    } else {
      const proportion = (Math.round((vehicleValueLimit / vehicleAcquisitionValue) * 100 * 100) / 100).toFixed(2);
      setValue('proportionLimit', `${proportion}%`);
      trigger('proportionLimit');
    }
  };

  const resetFormField = () => {
    setValue('deductionPart', '');
    setValue('deductionPercent', '');
    clearErrors('deductionPart');
    clearErrors('deductionPercent');
  };

  return (
    <div className="invoice-summary-data">
      <div className="data-area">
        {isCostForm() && (
          <div className="template-wrapper">
            <TheSelect
              testcy="vat-template"
              control={control}
              watch={watch}
              name="vatTemplate"
              label="Proszę określić wzorzec VAT"
              width="100%"
              options={vatTemplateDataOptions}
              hideEmptyOption={true}
              validation={{
                required: formErrorMsg.isRequired,
              }}
              onBlur={value => {
                setTimeout(() => {
                  focusOnNextFormInput_FORCE(`${isConfiguredAccountingTemplate ? 'vatAccountingScheme' : 'deductionType'}`);
                }, 0);
              }}
            />
            <AutocompleteInput
              testcy="vat-accounting-scheme"
              control={control}
              trigger={trigger}
              name="vatAccountingScheme"
              label="Proszę określić schemat księgowy"
              width="100%"
              options={vatSchemeOptions}
              onFocus={() => setFilterValue('')}
              disabled={!isConfiguredAccountingTemplate}
              tooltipMessage={`${isConfiguredAccountingTemplate ? '' : tooltipMessages.accountingTemplateError}`}
              validation={{ required: isConfiguredAccountingTemplate ? formErrorMsg.isRequired : false }}
              onFilterChanged={setFilterValue}
              onChange={value => {
                const v = value?.value;
                focusOnNextFormInput_FORCE('deductionType');

                if (v.includes('401-06') || v.includes('401-07') || v.includes('402-16')) {
                  setValue('deductionType', VehicleOperationEnum.EXPLOITATION);
                  resetFormField();
                } else if (v.includes('402-05')) {
                  setValue('deductionType', VehicleOperationEnum.LEASING);
                  resetFormField();
                } else if (!v || !v.includes('401-06') || !v.includes('401-07') || !v.includes('402-16')) {
                  setValue('deductionType', VehicleOperationEnum.NONE);
                  resetFormField();
                }
              }}
            />
            <TheSelect
              testcy="deduction-type"
              control={control}
              watch={watch}
              name="deductionType"
              label="Dotyczy pojazdu"
              width="100%"
              options={allowVehicleOptions}
              onChange={() => {
                setValue('deductibleLimit', DeductionLimitEnum._150);
                setValue('electricVehicle', false);
                resetField('vehicleAcquisitionValue');
                resetField('proportionLimit');
                resetFormField();
              }}
              defaultValue={VehicleOperationEnum.NONE}
              hideEmptyOption={true}
              validation={{ required: formErrorMsg.isRequired }}
              onBlur={value => {
                setTimeout(() => {
                  focusOnNextFormInput_FORCE(`deductionPart`);
                }, 0);
              }}
            />
            {watchDeductionType !== VehicleOperationEnum.NONE && (
              <div className="deduct-vat-wrapper">
                <TheSelect
                  testcy="deduction-part"
                  control={control}
                  watch={watch}
                  name="deductionPart"
                  label="Odliczenie VAT"
                  onChange={value => {
                    if (value === DeductVATEnum.FULLY_DEDUCTIBLE && watchDeductionType === VehicleOperationEnum.EXPLOITATION) {
                      setValue('deductionPercent', DeductionInCostsEnum.FULLY);
                      trigger('deductionPercent');
                    } else {
                      setValue('deductionPercent', '');
                    }
                  }}
                  options={deductVATOptions}
                  hideEmptyOption={true}
                  validation={{
                    required: formErrorMsg.isRequired,
                  }}
                  onBlur={value => {
                    setTimeout(() => {
                      focusOnNextFormInput_FORCE(`deductionPercent`);
                    }, 0);
                  }}
                />
                <TheSelect
                  testcy="deduction-percent"
                  control={control}
                  watch={watch}
                  name="deductionPercent"
                  label="W koszty"
                  options={inCostsOptions.filter(o => {
                    if (watchDeductionType === VehicleOperationEnum.LEASING) {
                      return o.value === DeductionInCostsEnum.FULLY || o.value === DeductionInCostsEnum.PARTIALLY_LEASING;
                    }
                    if (watchDeductionType === VehicleOperationEnum.EXPLOITATION && watchDeductionPart === DeductVATEnum.PARTIALLY_DEDUCTIBLE) {
                      return o.value !== DeductionInCostsEnum.PARTIALLY_LEASING;
                    }
                    if (watchDeductionType === VehicleOperationEnum.EXPLOITATION && watchDeductionPart === DeductVATEnum.FULLY_DEDUCTIBLE) {
                      return o.value === DeductionInCostsEnum.FULLY;
                    }
                  })}
                  hideEmptyOption={true}
                  onChange={() => {
                    setValue('deductibleLimit', DeductionLimitEnum._150);
                    setValue('electricVehicle', false);
                    resetField('vehicleAcquisitionValue');
                    resetField('proportionLimit');
                  }}
                  validation={{ required: formErrorMsg.isRequired }}
                  disabled={!watchDeductionPart ? true : false}
                  onBlur={value => {
                    setTimeout(() => {
                      focusOnNextFormInput_FORCE(`electricVehicle`);
                    }, 0);
                  }}
                />
              </div>
            )}
            {watchDeductionPercent === DeductionInCostsEnum.PARTIALLY_LEASING && (
              <>
                <TheCheckbox
                  testcy="electric-vehicle"
                  control={control}
                  name="electricVehicle"
                  label="Pojazd elektryczny"
                  validation={{
                    onChange: e => {
                      const value = e?.target?.value ? DeductionLimitEnum._225 : DeductionLimitEnum._150;
                      setValue('deductibleLimit', value);
                      if (!watchVehicleAcquisitionValue) return;
                      calculateProportion(null, stringToNumberSecondOptions(value));
                    },
                  }}
                  onKeyUpHandle={event => {
                    setTimeout(() => {
                      focusOnNextFormInput_FORCE(`vehicleAcquisitionValue`);
                    }, 0);
                  }}
                  defaultValue={false}
                />
                <TextInputNew
                  testcy="deductible-limit"
                  control={control}
                  name="deductibleLimit"
                  label="Limit odliczenia"
                  readOnly
                  validation={{ required: formErrorMsg.isRequired }}
                  defaultValue={DeductionLimitEnum._150}
                />
                <NumberInput
                  testcy="vehicle-acquisition-value"
                  control={control}
                  name="vehicleAcquisitionValue"
                  label="Wartość nabycia pojazdu"
                  maxDecimals={2}
                  float
                  placeholder="0,00"
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      focusOnNextFormInput(event, 4);
                      setValue('vehicleAcquisitionValue', formatNumberToCurrency(stringToNumberSecondOptions(watchVehicleAcquisitionValue)));
                      if (!watchVehicleAcquisitionValue) {
                        setValue('proportionLimit', '0%');
                        trigger('proportionLimit');
                        return;
                      }
                      calculateProportion();
                    }
                  }}
                  onBlur={value => {
                    setValue('vehicleAcquisitionValue', formatNumberToCurrency(stringToNumberSecondOptions(value)));
                    if (!value) {
                      setValue('proportionLimit', '0%');
                      trigger('proportionLimit');
                      return;
                    }
                    calculateProportion(stringToNumberSecondOptions(value));
                  }}
                  onFocus={event => {
                    setValue('vehicleAcquisitionValue', event.target.value.replace(/\s/g, ''));
                  }}
                  validation={{
                    required: formErrorMsg.isRequired,
                  }}
                  watch={watch}
                />
                <TextInputNew
                  testcy="limit-proportion"
                  control={control}
                  name="proportionLimit"
                  label="Proporcja limitu"
                  defaultValue=""
                  readOnly
                  validation={{ required: formErrorMsg.isRequired }}
                />
              </>
            )}
          </div>
        )}
        {hasZW && (
          <div className="zw-wrapper">
            <TheSelect
              testcy="zw-options"
              control={control}
              watch={watch}
              name="zwOptions"
              label="Proszę określić podstawę zwolnienia z podatku VAT"
              width="100%"
              options={ZwOptions}
              hideEmptyOption={true}
              onChange={value => {
                resetField('zwVatOther');
                return value === 'Other' ? setHasAnotherZW(true) : setHasAnotherZW(false);
              }}
              validation={{
                required: formErrorMsg.isRequired,
              }}
            />
            {hasZW && hasAnotherZW && (
              <TextInputNew
                testcy="zw-vat-other"
                control={control}
                name="zwVatOther"
                width="100%"
                label="Przepis, na podstawie którego stosowane jest zwolnienie z podatku VAT"
                validation={standardValidators.requiredMaxNumber(500)}
                defaultValue=""
              />
            )}
          </div>
        )}
        {isCorrected ? (
          <div className="invoice-details">
            <div className="invoice-details-column">
              <p className="sum-text">Razem przed korektą</p>
              <p className="">Razem korekta</p>
              <p className="">Razem po korekcie</p>
            </div>
            <div className="invoice-details-column">
              <p>Wartość Netto (PLN):</p>
              {isPendingRecalculate ? (
                <div className="value-loader">
                  <LoaderIcon size={'tiny'} />
                </div>
              ) : (
                <>
                  <p className="value" data-testcy="invoice-summary-net">
                    {formatNumberToCurrency(calculateOriginalInvoiceValuesId?.netValue)}
                  </p>
                  <p className="value" data-testcy="invoice-summary-net-left-to-pay">
                    {formatNumberToCurrency(Math.max(stringToNumberSecondOptions(netSummary) - calculateOriginalInvoiceValuesId?.netValue))}
                  </p>
                  <p className="value" data-testcy="invoice-summary-net-summary">
                    {netSummary}
                  </p>
                </>
              )}
            </div>
            <div className="invoice-details-column">
              <p>Kwota VAT (PLN):</p>
              {isPendingRecalculate ? (
                <div className="value-loader">
                  <LoaderIcon size={'tiny'} />
                </div>
              ) : (
                <>
                  <p className="value" data-testcy="invoice-summary-vat">
                    {formatNumberToCurrency(calculateOriginalInvoiceValuesId?.vatValue)}
                  </p>
                  <p className="value" data-testcy="invoice-summary-vat-left-to-pay">
                    {formatNumberToCurrency(Math.max(stringToNumberSecondOptions(vatSummary) - calculateOriginalInvoiceValuesId?.vatValue))}
                  </p>
                  <p className="value" data-testcy="invoice-summary-vat-summary">
                    {vatSummary}
                  </p>
                </>
              )}
            </div>
            <div className="invoice-details-column">
              <p>Wartość Brutto (PLN):</p>
              {isPendingRecalculate ? (
                <div className="value-loader">
                  <LoaderIcon size={'tiny'} />
                </div>
              ) : (
                <>
                  <p className="value value-bold" data-testcy="invoice-summary-gross">
                    {formatNumberToCurrency(calculateOriginalInvoiceValuesId?.grossValue)}
                  </p>
                  <p className="value value-bold" data-testcy="invoice-summary-gross-left-to-pay">
                    {formatNumberToCurrency(Math.max(stringToNumberSecondOptions(grossSummary) - calculateOriginalInvoiceValuesId?.grossValue))}
                  </p>
                  <p className="value value-bold" data-testcy="invoice-summary-gross-summary">
                    {grossSummary}
                  </p>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="invoice-details">
            <div className="invoice-details-column">
              <p className="sum-text">Suma:</p>
            </div>
            <div className="invoice-details-column">
              <p>Wartość Netto (PLN):</p>
              {isPendingRecalculate ? (
                <div className="value-loader">
                  <LoaderIcon size={'tiny'} />
                </div>
              ) : (
                <p className="value" data-testcy="invoice-summary-net">
                  {netSummary}
                </p>
              )}
            </div>
            <div className="invoice-details-column">
              <p>Kwota VAT (PLN):</p>
              {isPendingRecalculate ? (
                <div className="value-loader">
                  <LoaderIcon size={'tiny'} />
                </div>
              ) : (
                <p className="value" data-testcy="invoice-summary-vat">
                  {vatSummary}
                </p>
              )}
            </div>
            <div className="invoice-details-column">
              <p>Wartość Brutto (PLN):</p>
              {isPendingRecalculate ? (
                <div className="value-loader">
                  <LoaderIcon size={'tiny'} />
                </div>
              ) : (
                <p className="value value-bold" data-testcy="invoice-summary-gross">
                  {grossSummary}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
