import React from 'react';
import { CircularProgress } from '@mui/material';
import './loaderIcon.scss';

interface Props {
  size?: 'tiny' | 'small' | 'small2' | 'medium' | 'medium2' | 'big'| number;
  color?: 'primary' | 'secondary' | 'inherit';
}

export const LoaderIcon = ({ size = 'medium', color }: Props) => {
  const getWidth = () => {
    if (size === 'tiny') return '16px';
    if (size === 'small') return '20px';
    if (size === 'small2') return '24px';
    if (size === 'medium') return '35px';
    if (size === 'medium2') return '40px';
    if (size === 'big') return '55px';
    return `${size}px`;
  };

  return (
    <div className="loader-icon-component-wrapper">
      <CircularProgress size={size} className={`color-${color}`} style={{ width: getWidth() }} />
    </div>
  );
};
