import { getCompanyDataGus } from '@services';
import { getInvoiceMentionHints, getRelatedChatUsers } from './chat';
import { deleteMyAccount, getCompanies, getMyCompany, inviteCompany } from './companies';
import { createNewContractors, getContractors } from './contractors';
import { costMarkAsPaid, getCostDataById, getDigitalizedCosts } from './costs';
import { markTaxAsPaid } from './taxes';
import { getCashflow, updateBankAccountBalance, getProfitsAndLosses, getReceivablesAndPayables } from './dashboard';
import {
  createInvoicePdfById,
  invoiceRecordPayment,
  invoicesConfirm,
  invoicesSendEmail,
  invoicesSendReminder,
  invoiceMarkAsPaid,
  deleteInvoiceById,
  getChartData,
  invoiceMarkAsSent,
  getInvoices,
  getInvoiceHistory,
} from './invoices';
import { createNewEmployee, getEmployeeDataById, getEmployees, updateEmployee } from './employees';

export {
  addBankAccount,
  createNewCompany,
  deleteMyAccount,
  getAccountingCredentials,
  getCompanies,
  getCompaniesSuffixes,
  getCompanyById,
  getMyCompany,
  setAccountAsMain,
  setAccountingPermission,
  updateAccountingCredentials,
  updateCompany,
  updateMyCompany,
} from './companies';
export type { CompaniesOrderOptions, CompanyData, CompanyList, CompanyListData, GetCompaniesParams, InsertData, InsertGetData } from './companies';
export { createNewContractors, deleteContractorById, getContractorById, getContractors, updateContractor } from './contractors';
export type {
  ContractorAccountingSettings,
  ContractorData,
  ContractorsList,
  ContractorsListData,
  ContractorsOrderOptions,
  GetContractorsParams,
} from './contractors';
export * from './costs';
export { getCheckCredentials, getCredentials, getInsertIntegrationData, hasCredentials, setCredentials, setIntegrationToInsert } from './credential';
export type { CredentialsData, HasCredentials, HoldIntegrationToInsertData, IntegrationData } from './credential';
export {} from './dashboard';
export type { GetDashboardParams } from './dashboard';
export {
  createEmployeeNewDocuments,
  createNewEmployee,
  deleteEmployeeDocument,
  getEmployeeDataById,
  getEmployeeDownloadImgById,
  getEmployees,
  unregisterEmployee,
  updateEmployee,
} from './employees';
export type { EmployeeData, EmployeeDetails, EmployeesList, EmployeesListData, FilesDetails, GetEmployeesParams } from './employees';
export * from './files';
export { getCompanyDataGus } from './gusData';
export type { GusCompanyData } from './gusData';
export {
  createInvoice,
  createInvoiceCorrection,
  createInvoicePdfById,
  deleteInvoiceById,
  editInvoice,
  editInvoiceCorrection,
  getChartData,
  getCostSumValues,
  getInvoiceById,
  getInvoiceHistory,
  getInvoiceRelatedFiles,
  getInvoiceSumValues,
  getInvoiceTotal,
  getInvoices,
  getInvoicesNextNumber,
  invoiceMarkAsPaid,
  invoiceMarkAsSent,
  invoiceRecordPayment,
  invoicesConfirm,
  invoicesSendEmail,
  invoicesSendReminder,
  invoicesSendToInsert,
} from './invoices';
export type {
  CostCalculatedData,
  CostItemValuesBase,
  EmailFormData,
  GetCostSumValueParams,
  GetInvoiceListParams,
  GetInvoiceNumberParams,
  GetInvoiceSumValueParams,
  Invoice,
  InvoiceBulkOperationsParams,
  InvoiceCalculatedData,
  InvoiceCalculatedDataItem,
  InvoiceDetails,
  InvoiceHistory,
  InvoiceItemDetails,
  InvoiceList,
  InvoiceOrderOptions,
  InvoicePreviewDuplicateStateInterface,
  InvoiceRecordedPayment,
  InvoiceRelatedFiles,
  InvoiceSaveItem,
  InvoiceUnitCalculation,
  PayloadProperty,
  ReminderData,
  SaveInvoiceRequest,
  InvoiceEventType as invoiceEventType,
} from './invoices';
export { getNotifications } from './notifications';
export type { NotificationsList, NotificationsListData } from './notifications';
export { getTaxValues, getTaxValuesAccounting, updateTaxValues } from './taxes';
export type { GetTaxValuesTableRequest, TaxValueChange, TaxValueTable, TaxValues } from './taxes';

export const mutations = {
  createNewContractors,
  costMarkAsPaid,
  markTaxAsPaid,
  inviteCompany,
  getDigitalizedCosts,
  getInvoiceMentionHints,
  createInvoicePdfById: ({ id, duplicateDate }: { id: string; duplicateDate?: string }) => createInvoicePdfById(id, duplicateDate),
  invoicesSendEmail: ({ id, data }: { id: string; data: any }) => invoicesSendEmail(data, +id),
  invoicesSendReminder: ({ id, data }: { id: string; data: any }) => invoicesSendReminder(data, +id),
  invoiceRecordPayment: ({ id, data }: { id: string; data: any }) => invoiceRecordPayment(data, +id),
  updateBankAccountBalance,
  getCostDataById,
  deleteMyAccount,
  getCompanyDataGus,
  invoicesConfirm,
  invoiceMarkAsPaid,
  invoiceMarkAsSent,
  deleteInvoiceById,
  createNewEmployee,
  updateEmployee,
};

export const queries = {
  getContractors,
  getMyCompany,
  getCompanies,
  getCashflow,
  getReceivablesAndPayables,
  getProfitsAndLosses,
  getRelatedChatUsers,
  getCostDataById,
  getEmployees,
  getEmployeeDataById,
  getIncomesChartData: getChartData,
  getInvoices,
  getInvoiceHistory,
};

export type QueriesType = typeof queries;

export type MutationsType = typeof mutations;

export type MutationsTypeKey = keyof MutationsType;

export type QueriesTypeKey = keyof QueriesType;

export type QueryResult<TQueryKey extends QueriesTypeKey> = Awaited<ReturnType<QueriesType[TQueryKey]>>;

export type QueryParams<TQueryKey extends QueriesTypeKey> = Parameters<QueriesType[TQueryKey]>[0];

export type MutationResult<TMutationKey extends MutationsTypeKey> = Awaited<ReturnType<MutationsType[TMutationKey]>>;

export type MutationParams<TMutationKey extends MutationsTypeKey> = Parameters<MutationsType[TMutationKey]>[0];
