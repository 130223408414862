import { PaymentStatusType, PaymentType } from '@utils';
import { dateFormatter, invalidateApiQuery } from '@lib';
import { useApiQuery } from '../useApiQuery';

type UseCostDetailsResult = {
  displayName: string;
  number: string;
  createdDate: string;
  digitalizedDate: string;
  originalFileName: string;
  isDigitalized: boolean;
  totalAmountPaid: number;
  grossValue: number;
  leftToPay: number;
  isPaid: boolean;
  supportsPayments: boolean;
  paymentStatus: string;
  accountingStatus: string;
  refresh: () => void;
};

export const useCostDetails = (invoiceId: string, type?: string): UseCostDetailsResult => {
  const { data: costDetails } = useApiQuery('getCostDataById', Number(invoiceId), {
    enabled: !!invoiceId && type !== 'invoice',
    keepPreviousData: true,
  });

  const isDigitalized = !!costDetails?.invoicingData;
  const number = costDetails?.invoicingData?.number;
  const originalFileName = costDetails?.originalFilename;
  const displayName = isDigitalized && number ? `${number}` : `${originalFileName}`;

  const createdDate = dateFormatter.changeFormat(costDetails?.createdDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS", 'dd.MM.yyyy HH:mm');
  const digitalizedDate = dateFormatter.changeFormat(costDetails?.invoicingData?.digitalizedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS", 'dd.MM.yyyy HH:mm');

  const totalAmountPaid = costDetails?.invoicingData?.amountPaid + costDetails?.invoicingData?.totalInstalments;
  const grossValue = costDetails?.invoicingData?.grossValue;
  const leftToPay = costDetails?.invoicingData?.grossValue - totalAmountPaid;

  const isPaid = costDetails?.invoicingData?.paymentStatus === PaymentStatusType.Paid;
  const paymentMethodSupportsPayments = !!costDetails?.invoicingData?.paymentMethod && costDetails?.invoicingData?.paymentMethod !== PaymentType.Paid;
  const supportsPayments = !isPaid && isDigitalized && paymentMethodSupportsPayments;
  const paymentStatus = costDetails?.invoicingData?.paymentStatus;
  const accountingStatus = costDetails?.accountingStatus;

  const refresh = () => invalidateApiQuery('getCostDataById', Number(invoiceId));

  return {
    displayName,
    number,
    createdDate,
    digitalizedDate,
    originalFileName,
    isDigitalized,
    totalAmountPaid,
    grossValue,
    leftToPay,
    isPaid,
    supportsPayments,
    paymentStatus,
    accountingStatus,
    refresh,
  };
};
