import React from 'react';
import { Tooltip } from '@mui/material';
import { ConfirmIcon, DeleteIcon, PrintIcon } from '@components';
import { BulkOperationsEnum } from '@utils';
import { useCompanyStore, useContractorStore } from '@store';
import { useParams } from 'react-router';
import '../incomesTab.scoped.scss';
import { useCredentialToInsertData } from 'src/features/shared/sharedCompanyForm/sections/insertSection/hooks';

interface Props {
  headerCheckboxIsPressed: boolean;
  onBulkOperationsInvoice: (actionType?: string) => void;
  sendInvoicesToInsertTooltip: (hasInsertCredentials: boolean, hasEntityCredentials: boolean) => string;
}

export const BulkOperationIconsWrapper = ({ headerCheckboxIsPressed, onBulkOperationsInvoice, sendInvoicesToInsertTooltip }: Props) => {
  const { companyId } = useParams();
  const { ownerMyCompanyData } = useContractorStore();
  const { hasInsertCredentials } = useCredentialToInsertData(ownerMyCompanyData?.hasAccountingClients);
  const { selectedCompany } = useCompanyStore();

  return headerCheckboxIsPressed ? (
    <div className="header-icon-wrapper">
      {!companyId ? (
        <>
          <Tooltip title={'Zatwierdź fakturę'}>
            <button
              data-testcy="incomes-table-bulk-confirm"
              className="confirm-icon-wrapper"
              onClick={() => onBulkOperationsInvoice(BulkOperationsEnum.CONFIRM)}
            >
              <ConfirmIcon />
            </button>
          </Tooltip>
          <Tooltip title={'Oznacz jako opłacona'}>
            <button
              data-testcy="incomes-table-bulk-mark-as-paid"
              className="mark-as-paid-icon-wrapper"
              onClick={() => onBulkOperationsInvoice(BulkOperationsEnum.MARK_AS_PAID)}
            >
              <ConfirmIcon />
            </button>
          </Tooltip>
          <button data-testcy="incomes-table-bulk-print" className="print-icon-wrapper">
            <PrintIcon colorType="disabled" />
          </button>
          <button data-testcy="incomes-table-bulk-delete" className="delete-icon-wrapper">
            <DeleteIcon colorType="disabled" />
          </button>
        </>
      ) : (
        <Tooltip
          title={`${
            sendInvoicesToInsertTooltip(hasInsertCredentials, selectedCompany?.hasEntityCredentials)
              ? sendInvoicesToInsertTooltip(hasInsertCredentials, selectedCompany?.hasEntityCredentials)
              : 'Wyślij do systemu księgowego'
          }`}
        >
          <div className={`${!!sendInvoicesToInsertTooltip(hasInsertCredentials, selectedCompany?.hasEntityCredentials) && 'confirm-icon-wrapper-disabled'}`}>
            <button
              data-testcy="incomes-table-bulk-send-invoice"
              className="send-to-insert-icon-wrapper"
              onClick={() => onBulkOperationsInvoice(BulkOperationsEnum.SEND_TO_INSERT)}
              disabled={!!sendInvoicesToInsertTooltip(hasInsertCredentials, selectedCompany?.hasEntityCredentials)}
            >
              <ConfirmIcon />
            </button>
          </div>
        </Tooltip>
      )}
    </div>
  ) : (
    <>Nr dokumentu</>
  );
};
