/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonIconNew, EyeIcon, MoneyChart, PageLoaderWrapper, SearchBarValues, TableRowData, TheDialog, TheTableNew } from '@components';
import { SharedInvoiceDetails } from '@features';
import {
  useConfirmInvoice,
  useDebounce,
  useDeleteInvoice,
  useIncomesChart,
  useInvoiceDrawerController,
  useInvoiceImage,
  useMarkInvoiceAsPaid,
  useMarkInvoiceAsSent,
  usePagination,
  useSendInvoiceEmail,
  useSubmitInvoicePayment,
  useSubmitReminder,
} from '@hooks';
import { CustomObject, InvoiceStatus, RequestErrorMessage } from '@interfaces';
import { addHotjarEvent, dateFormatter } from '@lib';
import {
  ContractorsList,
  GetInvoiceListParams,
  Invoice,
  InvoiceBulkOperationsParams,
  InvoiceList,
  InvoiceOrderOptions,
  getContractors,
  getInvoices,
  invoiceMarkAsPaid,
  invoicesConfirm,
  invoicesSendToInsert,
} from '@services';
import { useCompanyStore, useContractorStore, useInternalAppStore, useInvoiceStore, useListParamsStore, useUserStore } from '@store';
import {
  AccountantStatusType,
  BulkOperationsEnum,
  InvoiceActionEnum,
  InvoiceStatusType,
  SortDirection,
  UserRolesEnum,
  formatCurrency,
  formatFetchedDataToPdf,
  handleApiError,
  invoiceStatusAccountantOptions,
  invoiceStatusOwnerOptions,
  isDefined,
  snackbarMessagesHandler,
} from '@utils';
import { uniqBy } from 'lodash';
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHref, useNavigate, useParams } from 'react-router-dom';
import { ListSearchBar, SelectedInvoiceCustomRow } from 'src/components/combined';
import { EmailFormData } from 'src/features/shared/sharedFilePreview/sharedFilePreview.utils';
import { InvoicePopups } from 'src/features/shared/sharedInvoiceDetails/components';
import { CreateDuplicateFormData } from 'src/features/shared/sharedInvoiceDetails/components/invoicePopups/components/invoiceCreateDuplicateForm/invoiceCreateDuplicateForm.utils';
import { RecordAmountFormData } from 'src/features/shared/sharedInvoiceDetails/components/invoicePopups/components/invoiceRecordPaymentForm/invoiceRecordPaymentForm.utils';
import { useGetInvoiceData } from 'src/features/shared/sharedInvoiceDetails/hooks';
import { ReminderFormData } from 'src/features/shared/sharedReminderForm/sharedReminderForm.utils';
import {
  CheckboxStateDataType,
  IncomesListAction,
  InvoiceConfirmDialogDataType,
  InvoicePendingStates,
  RefFunctions,
  concatWithoutDuplicates,
  getSelectedInvoices,
  getTableHeader,
  invoiceDataToTableRow,
  openInNewTab,
  transformFormDataToEmailData,
  transformFormDataToRecordAmountData,
  transformFormDataToReminderData,
  getActionMenuItems,
  translateAction,
  unregisterCheckboxes,
} from './incomesTab.utils';

import './incomesTab.scoped.scss';
import './incomesTab.scss';
import { useCredentialToInsertData } from 'src/features/shared/sharedCompanyForm/sections/insertSection/hooks';

export const IncomesTab = forwardRef((_: unknown, ref) => {
  const refTotalAmountClick = createRef<RefFunctions>();
  const testcy = 'incomes-table';

  const { page, setPage, totalRows, setTotalRows, rowsPerPage, setRowsPerPage } = usePagination();

  const [orderBy, setOrderBy] = useState<InvoiceOrderOptions>('issueDate');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESCENDING);

  const [rows, setRows] = useState<TableRowData[]>([]);

  const [enableChartTooltip, setEnableChartTooltip] = useState(false);
  const [isChartCardOpen, setIsChartCardOpen] = useState(false);
  const [isChartLoaded, setIsChartLoaded] = useState(false);
  const [isCheckedStore, setIsCheckedStore] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isGtuSelected, setIsGtuSelected] = useState(false);
  const [statusSelected, setStatusSelected] = useState<InvoiceStatus[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [listParams, setListParams] = useState<GetInvoiceListParams>();
  const [searchBarDefaultValues, setSearchBarDefaultValues] = useState<SearchBarValues>(null);
  const [invoiceList, setInvoiceList] = useState([]);
  const [openConfirmBulkOperationDialog, setOpenConfirmBulkOperationDialog] = useState(false);
  const [dialogConfirmBulkOperationsData, setDialogConfirmBulkOperationsData] = useState<InvoiceConfirmDialogDataType>();
  const [updateData, setUpdateData] = useState(false);
  const [menuItemsList, setMenuItemsList] = useState([]);
  const [checkboxState, setCheckboxesState] = useState<CheckboxStateDataType>({});
  const [totalValue, setTotalValue] = useState<number>();

  const [isDataPending, setIsDataPending] = useState(true);

  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openReminderModal, setOpenReminderModal] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openRecordPaymentModal, setOpenRecordPaymentModal] = useState(false);
  const [openCreateDuplicateModal, setOpenCreateDuplicateModal] = useState(false);

  const listSearchParams = useDebounce(listParams, 500);

  const navigate = useNavigate();

  const routeParams = useParams();
  const { lastParamsInvoiceList, setLastParamsInvoiceList } = useListParamsStore();
  const { ownerMyCompanyData, setContractorsList, selectedContractorId, selectedContractor, setSelectedContractor } = useContractorStore();
  const { selectedCompany } = useCompanyStore();
  const { control, handleSubmit, getValues, setValue, register, resetField } = useForm();
  const { role } = useUserStore();

  const { setIsFromDashboard, setIsCorrected, setIsClone, invoiceId } = useInvoiceStore();
  const { hasInsertCredentials } = useCredentialToInsertData(ownerMyCompanyData?.hasAccountingClients);
  const { lastFetchedInvoiceFile } = useInternalAppStore();

  const { openInvoiceDrawer, closeInvoiceDrawer } = useInvoiceDrawerController('invoice');

  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>(null);

  const { invoiceData: selectedInvoice, amountToPay, amountPaid, invoiceName, refreshInvoiceData, isPendingInvoiceData } = useGetInvoiceData(selectedInvoiceId);

  const { incomesChartData: chartData, isIncomesChartLoading } = useIncomesChart(listSearchParams, setEnableChartTooltip);

  //! action success handlers
  const onConfirmInvoiceSuccess = () => {
    addHotjarEvent('Confirm invoice from meatballs menu');
    refreshData(InvoiceActionEnum.CONFIRM);
  };
  const onSendInvoiceEmailSuccess = () => {
    addHotjarEvent('Send email to contractor from meatballs menu', 'action');
    refreshData();
    setOpenEmailModal(false);
  };
  const onSubmitReminderSuccess = () => {
    addHotjarEvent('Send reminder to contractor from meatballs menu', 'action');
    refreshData();
    setOpenReminderModal(false);
  };
  const onSubmitInvoicePaymentSuccess = () => {
    addHotjarEvent('Record invoice payment from meatballs menu', 'action');
    setOpenRecordPaymentModal(false);
    refreshData();
  };
  const onMarkInvoiceAsPaidSuccess = () => {
    addHotjarEvent('Mark invoice as paid from meatballs menu', 'action');
    setOpenRecordPaymentModal(false);
    refreshData();
  };
  const onMarkInvoiceAsSentSuccess = () => {
    addHotjarEvent('Mark invoice as sent from meatballs menu', 'action');
    refreshData();
  };
  const onDeleteInvoiceSuccess = () => {
    addHotjarEvent('Delete invoice from meatballs menu', 'action');
    refreshData(InvoiceActionEnum.DELETE, selectedInvoiceId);
  };

  //! action hooks
  const { downloadInvoiceAsPdf, isDownloadImageLoading } = useInvoiceImage();
  const { confirmInvoice, isConfirmInvoiceLoading } = useConfirmInvoice(onConfirmInvoiceSuccess);
  const { sendInvoiceEmail, isSendInvoiceEmailLoading } = useSendInvoiceEmail(onSendInvoiceEmailSuccess);
  const { submitReminder, isSubmitReminderLoading } = useSubmitReminder(onSubmitReminderSuccess);
  const { submitInvoicePayment, isSubmitInvoicePaymentLoading } = useSubmitInvoicePayment(onSubmitInvoicePaymentSuccess);
  const { markInvoiceAsPaid, isMarkInvoiceAsPaidLoading } = useMarkInvoiceAsPaid(onMarkInvoiceAsPaidSuccess);
  const { markInvoiceAsSent, isMarkInvoiceAsSentLoading } = useMarkInvoiceAsSent(onMarkInvoiceAsSentSuccess);
  const { deleteInvoice, isDeleteInvoiceLoading } = useDeleteInvoice(onDeleteInvoiceSuccess);

  const pendingStates: InvoicePendingStates = {
    data: isDataPending,
    chart: isIncomesChartLoading,
    image: isDownloadImageLoading,
    confirm: isConfirmInvoiceLoading,
    delete: isDeleteInvoiceLoading,
    email: isSendInvoiceEmailLoading || isMarkInvoiceAsSentLoading,
    reminder: isSubmitReminderLoading,
    recordPayment: isSubmitInvoicePaymentLoading || isMarkInvoiceAsPaidLoading,
  };

  useEffect(() => {
    closeInvoiceDrawer();
  }, []);

  useEffect(() => {
    fetchContractors();
  }, []);

  useEffect(() => {
    const uniqueCheckedValue = uniqBy(checkboxState.checkboxesSelected, 'id'); //removed if had duplicate id
    const net = uniqueCheckedValue.map(item => item.net);
    const gross = uniqueCheckedValue.map(item => item.gross);

    const netSum = net.reduce((partialSum, a) => partialSum + a, 0);
    const grossSum = gross.reduce((partialSum, a) => partialSum + a, 0);

    if (
      (checkboxState.selectedAll && checkboxState.totalInvoicesCount - uniqueCheckedValue.length) ||
      (!checkboxState.selectedAll && uniqueCheckedValue.length)
    ) {
      const timeout = setTimeout(() => {
        setCheckboxesState(p => ({ ...p, checkboxTimeout: true }));
      }, 600);
      setCheckboxesState(p => ({ ...p, time: timeout }));
    } else {
      setCheckboxesState(p => ({ ...p, checkboxTimeout: false }));
      clearTimeout(checkboxState.time);
    }
    if (checkboxState.selectedAll) {
      setCheckboxesState(p => ({
        ...p,
        checkedNetValue: checkboxState?.totalNetValue - netSum,
        checkedGrossValue: checkboxState?.totalGrossValue - grossSum,
        numberOfInvoicesChecked: checkboxState?.totalInvoicesCount - uniqueCheckedValue.length,
        headerCheckboxIsPressed: !!(checkboxState?.totalInvoicesCount - uniqueCheckedValue.length),
      }));
      return;
    }
    setCheckboxesState(p => ({ ...p, numberOfInvoicesChecked: uniqueCheckedValue.length, headerCheckboxIsPressed: uniqueCheckedValue.length > 0 }));
    if (uniqueCheckedValue.length) {
      setCheckboxesState(p => ({ ...p, checkedNetValue: netSum, checkedGrossValue: grossSum }));
    }
  }, [checkboxState?.checkboxesSelected, checkboxState?.totalNetValue, checkboxState?.totalGrossValue]);

  useEffect(() => {
    if (lastParamsInvoiceList && !!lastParamsInvoiceList?.companyId === !!routeParams.companyId) {
      setPage(lastParamsInvoiceList.page);
      setSortDirection(lastParamsInvoiceList.sortDirection);
      setOrderBy(lastParamsInvoiceList.orderBy);
      if (lastParamsInvoiceList.searchBarValues) {
        setStatusSelected(lastParamsInvoiceList.searchBarValues.status);
        setSearchBarDefaultValues(lastParamsInvoiceList.searchBarValues);
      }
      setLastParamsInvoiceList(null);
    } else {
      const { fromDate, toDate } = dateFormatter.getMonthRange();
      setSearchBarDefaultValues({ fromDate: fromDate, toDate: toDate });
    }
    setIsCheckedStore(true);
  }, []);

  useEffect(() => {
    if (isCheckedStore) {
      modifyParams();
    }
  }, [isCheckedStore, routeParams.companyId, searchValue, page, orderBy, sortDirection, isGtuSelected, statusSelected, startDate, endDate, rowsPerPage]);

  useEffect(() => {
    fetchTableData();
    unloadChartIfClosed();
  }, [listSearchParams]);

  useImperativeHandle(
    ref,
    () => {
      return {
        saveParams() {
          saveToStateParams();
        },
      };
    },
    [searchValue, page, orderBy, sortDirection, isGtuSelected, statusSelected, startDate, endDate]
  );

  const permissionAsAccountant = (): boolean => {
    return role === UserRolesEnum.BUSINESS && !!routeParams.companyId;
  };

  const onChangeCheckbox = (value: boolean, id: number, name: string, net: number, gross: number, data: Invoice[]) => {
    const ids = getSelectedInvoices(getValues());

    const arrayOfCheckboxSelectedOnPage = [];
    data.forEach(row => {
      const checkboxIsInCheckboxSelected = ids.filter(item => {
        return Number(item) === row.id;
      });
      arrayOfCheckboxSelectedOnPage.push(...checkboxIsInCheckboxSelected);
    });

    arrayOfCheckboxSelectedOnPage.length === data.length && setValue(`check-all-on-page`, true);
    arrayOfCheckboxSelectedOnPage.length < data.length && setValue(`check-all-on-page`, false);
    !arrayOfCheckboxSelectedOnPage.length && setValue(`check-all-on-page`, false);
    setCheckboxesState(p => {
      if (p.selectedAll) {
        if (!value) {
          return { ...p, checkboxesSelected: [...p.checkboxesSelected, { id, name, net, gross }] };
        } else {
          return {
            ...p,
            headerCheckboxIsPressed: p.headerCheckboxIsPressed,
            checkboxesSelected: p.checkboxesSelected.filter(item => item.name !== name),
          };
        }
      } else {
        if (value) {
          return { ...p, checkboxesSelected: [...p.checkboxesSelected, { id, name, net, gross }] };
        } else {
          return { ...p, checkboxesSelected: p.checkboxesSelected.filter(item => item.name !== name) };
        }
      }
    });
  };

  const onChangeCheckboxHeader = (isChecked: boolean) => {
    invoiceList.forEach(row => {
      const nameOfCheckbox = `checkbox-${row.id}`;
      if (checkboxState?.selectedAll) {
        if (!isChecked) {
          setValue(`checkbox-${row.id}`, false);
          setCheckboxesState(p => ({
            ...p,
            checkboxesSelected: [...p.checkboxesSelected, { id: row.id, name: nameOfCheckbox, net: row.netValue, gross: row.grossValue }],
          }));
        } else {
          setValue(`checkbox-${row.id}`, true);
          setCheckboxesState(p => ({ ...p, checkboxesSelected: p.checkboxesSelected.filter(item => item.name !== nameOfCheckbox) }));
        }
      } else {
        if (isChecked) {
          setValue(`checkbox-${row.id}`, true);
          setCheckboxesState(p => ({
            ...p,
            checkboxesSelected: [...p.checkboxesSelected, { id: row.id, name: nameOfCheckbox, net: row.netValue, gross: row.grossValue }],
          }));
        } else {
          setValue(`checkbox-${row.id}`, false);
          setCheckboxesState(p => ({ ...p, checkboxesSelected: p.checkboxesSelected.filter(item => item.name !== nameOfCheckbox) }));
        }
      }
    });
  };

  const unselectAllCheckboxes = () => {
    setCheckboxesState(p => ({ ...p, checkboxesSelected: [], headerCheckboxIsPressed: false, selectedAll: false }));
    setValue('check-all-on-page', false);
    unregisterCheckboxes(getValues(), setValue);
  };

  const onSelectAll = () => {
    setCheckboxesState(p => ({
      ...p,
      checkedNetValue: checkboxState?.totalNetValue,
      checkedGrossValue: checkboxState?.totalGrossValue,
      selectedAll: true,
      numberOfInvoicesChecked: checkboxState?.totalInvoicesCount,
      checkboxesSelected: [],
    }));

    invoiceList.forEach(row => {
      setValue(`checkbox-${row.id}`, true);
      setValue(`check-all-on-page`, true);
    });
  };

  const onUnselectAll = () => {
    unselectAllCheckboxes();
  };

  const onFilterChange = (data: SearchBarValues) => {
    unselectAllCheckboxes();
    setIsGtuSelected(data.noGtu);
    setSearchValue(data.textFilter);
    setStartDate(data.fromDate);
    setEndDate(data.toDate);
    setPage(1);
  };

  const onSelectRow = (id: string) => {
    setSelectedInvoiceId(id);
    openInvoiceDrawer('onlyInvoiceDrawer', id);
  };

  const onBulkOperationsInvoice = (action: BulkOperationsEnum) => {
    unselectAfterBulkOperation(action);
    if (action === BulkOperationsEnum.CONFIRM) {
      handleSubmit(f => submitIssuedInvoice(f as CustomObject))();
      return;
    }
    if (action === BulkOperationsEnum.MARK_AS_PAID) {
      handleSubmit(f => submitMarkAsPaidInvoice(f as CustomObject))();
      return;
    }
    if (action === BulkOperationsEnum.SEND_TO_INSERT) {
      handleSubmit(f => sendInvoiceToInsert(f as CustomObject))();
      return;
    }
  };

  const fetchContractors = () => {
    getContractors({ pageSize: 100, textFilter: '', companyId: Number(routeParams.companyId) || null })
      .then((contractors: ContractorsList) => {
        setContractorsList(contractors.data);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally();
  };

  const unloadChartIfClosed = async () => {
    if (!isChartCardOpen) setIsChartLoaded(false);
  };

  const toggleChart = () => {
    setIsChartCardOpen(!isChartCardOpen);
    if (!isChartLoaded) setIsChartLoaded(true);
  };

  const fetchTableData = async (resetCheckboxes = false) => {
    if (!listSearchParams) return;
    setIsDataPending(true);

    try {
      const invoicesList = await getInvoices(listSearchParams);
      if (invoicesList) {
        setTableParam(invoicesList, resetCheckboxes);
        generateRows(invoicesList.data);
        setMenuItemsList(
          getActionMenuItems(invoicesList.data, routeParams.companyId, hasInsertCredentials, selectedCompany?.hasEntityCredentials, pendingStates)
        );
        setTotalValue(invoicesList.grossValue);
      }
    } catch (err) {
      console.error(err);
      handleApiError(err);
    }
    setIsDataPending(false);
  };

  const onChangeSort = (sortType: SortDirection, propertyName: string): void => {
    setSortDirection(sortType);
    setOrderBy(propertyName as InvoiceOrderOptions);
  };

  const onChangePagination = (data: { page: number; rowsPerPage: number }) => {
    setPage(data.page);
    setRowsPerPage(data.rowsPerPage);
  };

  const sendInvoiceToInsert = (dataId: CustomObject) => {
    const params: InvoiceBulkOperationsParams = {
      filter: listParams.filter,
      ids: checkboxState.checkboxesSelected.map(item => item.id),
      selectAll: checkboxState.selectedAll,
    };
    if (dataId?.id) {
      params.ids = [Number(dataId.id)];
      params.selectAll = false;
    } else {
      params.ids = checkboxState?.checkboxesSelected.map(item => item.id);
    }
    invoicesSendToInsert(params)
      .then(data => {
        if (dataId?.id) {
          snackbarMessagesHandler.invoicesSendToInsert(false);
        } else {
          dialogConfirmBulkOperations(checkboxState.numberOfInvoicesChecked, data, 'Wysłane faktury', BulkOperationsEnum.SEND_TO_INSERT);
          setOpenConfirmBulkOperationDialog(true);
        }
        fetchTableData(true);
        unloadChartIfClosed();
        setUpdateData(true);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err));
  };

  const submitIssuedInvoice = async (data: CustomObject) => {
    const params: InvoiceBulkOperationsParams = {
      filter: listParams.filter,
      ids: checkboxState.checkboxesSelected.map(item => item.id),
      selectAll: checkboxState.selectedAll,
    };

    try {
      const receivedData = await invoicesConfirm(params);
      dialogConfirmBulkOperations(checkboxState.numberOfInvoicesChecked, receivedData, 'Zatwierdzone faktury', BulkOperationsEnum.CONFIRM);
      setOpenConfirmBulkOperationDialog(true);
      fetchTableData(true);
      // fetchInvoicesTotal();
      unloadChartIfClosed();
      setUpdateData(true);
    } catch (err) {
      console.error(err);
      handleApiError(err);
    }
  };

  const unselectAfterBulkOperation = (bulk: BulkOperationsEnum) => {
    if (
      checkboxState.selectedAll ||
      statusSelected.includes(InvoiceStatusType.Paid) ||
      statusSelected.includes(AccountantStatusType.Sending) ||
      statusSelected?.length === 0
    )
      return;
    if (
      (statusSelected.includes(InvoiceStatusType.Draft) && bulk === BulkOperationsEnum.CONFIRM) ||
      (!statusSelected.includes(InvoiceStatusType.Draft) && bulk === BulkOperationsEnum.MARK_AS_PAID) ||
      (statusSelected.includes(AccountantStatusType.New) && bulk === BulkOperationsEnum.SEND_TO_INSERT)
    ) {
      setCheckboxesState(p => ({ ...p, checkboxesSelected: [], headerCheckboxIsPressed: false, selectedAll: false }));
    }
  };

  const submitMarkAsPaidInvoice = async (data: CustomObject) => {
    const params: InvoiceBulkOperationsParams = {
      filter: listParams.filter,
      ids: checkboxState.checkboxesSelected.map(item => item.id),
      selectAll: checkboxState.selectedAll,
    };

    try {
      const receivedData = await invoiceMarkAsPaid(params);
      dialogConfirmBulkOperations(checkboxState.numberOfInvoicesChecked, receivedData, 'Oznaczono jako opłacone', BulkOperationsEnum.MARK_AS_PAID);
      setOpenConfirmBulkOperationDialog(true);
      fetchTableData(true);
      // fetchInvoicesTotal();
      unloadChartIfClosed();
      setUpdateData(true);
    } catch (err) {
      console.error(err);
      handleApiError(err);
    }
  };

  const dialogConfirmBulkOperations = (numberOfInvoicesChecked: number, invoicesNotConfirmed, dialogTitle: string, action: BulkOperationsEnum) => {
    const params = { numberOfInvoicesChecked, invoicesNotConfirmed, dialogTitle, action };
    setDialogConfirmBulkOperationsData(params);
  };

  const generateRows = (data: Invoice[]): void => {
    const isAccountant = permissionAsAccountant();
    const rows = data.map((dataRow: Invoice, index) => {
      const transformedDataToRow = invoiceDataToTableRow(dataRow, control, onChangeCheckbox, isAccountant, routeParams?.companyId, data, index);
      return transformedDataToRow;
    });
    setRows([...rows]);
  };

  const setTableParam = (invoicesList: InvoiceList, resetCheckboxes: boolean): void => {
    setTotalRows(invoicesList.totalCount);
    setInvoiceList(invoicesList.data);
    setCheckboxesState(p => ({
      ...p,
      totalInvoicesCount: invoicesList.totalCount,
      totalNetValue: invoicesList.netValue,
      totalGrossValue: invoicesList.grossValue,
    }));
    const arrayOfNonCheckboxSelectedOnPage = [];
    const arrayOfCheckboxSelectedOnPage = [];
    if (checkboxState.selectedAll && !resetCheckboxes) {
      invoicesList.data.forEach(row => {
        if (checkboxState.checkboxesSelected.filter(item => item.id === row.id).length > 0) {
          setValue(`checkbox-${row.id}`, false);
          arrayOfNonCheckboxSelectedOnPage.push(...checkboxState.checkboxesSelected);
        } else {
          setValue(`checkbox-${row.id}`, true);
        }
      });
      arrayOfNonCheckboxSelectedOnPage.length ? setValue(`check-all-on-page`, false) : setValue(`check-all-on-page`, true);
    } else {
      invoicesList.data.forEach(row => {
        const uniqueCheckedValue = uniqBy(checkboxState.checkboxesSelected, 'id');
        const checkboxIsInCheckboxSelected = uniqueCheckedValue.filter(item => {
          return item.id === row.id;
        });
        arrayOfCheckboxSelectedOnPage.push(...checkboxIsInCheckboxSelected);
      });
      if (arrayOfCheckboxSelectedOnPage.length && arrayOfCheckboxSelectedOnPage.length === invoicesList.data.length) {
        setValue(`check-all-on-page`, true);
      } else {
        setValue(`check-all-on-page`, false);
      }
    }
  };

  const saveToStateParams = () => {
    setLastParamsInvoiceList({
      page,
      sortDirection,
      orderBy,
      searchBarValues: {
        textFilter: searchValue,
        noGtu: isGtuSelected,
        status: statusSelected,
        fromDate: startDate,
        toDate: endDate,
      },
      companyId: Number(routeParams.companyId) || null,
      itemsPerPage: rowsPerPage,
    });
  };

  const modifyParams = () => {
    const params: GetInvoiceListParams = {
      filter: { companyId: +routeParams.companyId || null },
      paging: { pageNumber: page, pageSize: rowsPerPage },
      sorting: { direction: sortDirection },
    };

    if (orderBy) params.sorting.orderBy = orderBy;
    if (searchValue) params.filter.textFilter = searchValue;
    if (isGtuSelected) params.filter.noGtuOnly = isGtuSelected;
    if (statusSelected) {
      statusSelected.forEach(item => {
        setValue(`checkbox-filter-status-${item}`, true);
      });
      if (!permissionAsAccountant()) {
        params.filter.status = statusSelected;
      } else {
        params.filter.accountingStatus = statusSelected;
      }
    }
    if (startDate) params.filter.dateFrom = dateFormatter.objectToString(startDate, "yyyy-MM-dd'T'HH:mm:ss");
    if (endDate) params.filter.dateTo = dateFormatter.objectToString(endDate, "yyyy-MM-dd'T'HH:mm:ss");
    setListParams(params);
    setTimeout(() => {
      saveToStateParams();
    });
  };

  const refreshData = (action?: InvoiceActionEnum, invoiceId?: string, keepInvoiceIdDrawer = false) => {
    // es5 because hoisting
    fetchTableData();
    unloadChartIfClosed();
    setUpdateData(false);
    changeSelectedArray(action, invoiceId);
    if (action !== InvoiceActionEnum.DELETE) refreshInvoiceData();
    if (!keepInvoiceIdDrawer) setSelectedInvoiceId(null);
  };

  //! actions
  const openInvoicePreview = (data: TableRowData): void => {
    openInvoiceDrawer('invoiceDrawerAndInvoicePreview', data.id);
  };
  const openEditInvoiceForm = (id: string, isCorrected: boolean) => {
    setIsFromDashboard(false);
    setIsCorrected(isCorrected);

    if (isCorrected) {
      navigate(`/invoices-list/edit-invoice-correction/correction-edit/${id}`);
      return;
    }
    navigate(`/invoices-list/edit-invoice/edit/${id}`);
  };
  const openCloneInvoiceForm = () => {
    navigate(`/invoices-list/add-invoice?clone=${selectedInvoice?.id}`);
    setIsClone(true);
    register('paidAmount');
    resetField('paidAmount');
    setIsCorrected(false);
    setIsFromDashboard(false);
  };
  const openCorrectionForm = (id: string) => {
    navigate(`/invoices-list/add-invoice/correction/${id}`);
    setIsCorrected(true);
    setIsFromDashboard(false);
    register('paidAmount');
    resetField('paidAmount');
  };
  const onSubmitEmailForm = (data: EmailFormData) => {
    const requestData = transformFormDataToEmailData(data);

    sendInvoiceEmail(String(selectedInvoice?.id), requestData);

    if (selectedContractorId === selectedInvoice?.contractorId) {
      const emails = concatWithoutDuplicates(selectedContractor.emails, data.emailTo);
      setSelectedContractor({ ...selectedContractor, emails: emails }, selectedContractorId);
    }
  };
  const onSubmitReminderForm = (data: ReminderFormData) => {
    const requestData = transformFormDataToReminderData(data);

    submitReminder(String(selectedInvoice?.id), requestData);

    if (selectedContractorId === selectedInvoice?.contractorId) {
      const emails = concatWithoutDuplicates(selectedContractor.emails, data.emailTo);
      setSelectedContractor({ ...selectedContractor, emails: emails }, selectedContractorId);
    }
  };
  const onSubmitRecordPayment = (data: RecordAmountFormData) => {
    const requestData = transformFormDataToRecordAmountData(data);
    submitInvoicePayment(String(selectedInvoice?.id), requestData);
  };
  const onSubmitDeleteForm = (agree: boolean) => {
    if (agree) deleteInvoice(String(selectedInvoice?.id));
    setOpenDialogDelete(false);
  };
  const downloadInvoice = (id: string) => {
    if (lastFetchedInvoiceFile.id === id) {
      formatFetchedDataToPdf(lastFetchedInvoiceFile.file, `faktura-${selectedInvoice?.number}.pdf`);
      return;
    }
    downloadInvoiceAsPdf(id, selectedInvoice?.number);
  };
  const href = useHref('file-preview', { relative: 'route' });
  const onSubmitCreateDuplicate = (data: CreateDuplicateFormData) => {
    const duplicateDateFormatted = dateFormatter.objectToString(data.createDuplicateDate, 'yyyy-MM-dd');
    openInNewTab(href + '/' + selectedInvoice?.id + '?duplicateDate=' + duplicateDateFormatted);
    setOpenCreateDuplicateModal(false);
  };

  const onClickInvoiceMenu = (row: TableRowData) => {
    setSelectedInvoiceId(row.stashedData.data.id?.toString());
  };

  const onClickMenuItem = (action: string, row: TableRowData) => {
    if (action === IncomesListAction.Preview) return openInvoicePreview(row);
    if (action === IncomesListAction.EditOrCorrect) {
      if (row.stashedData.status === InvoiceStatusType.Draft) {
        return openEditInvoiceForm(row.id, row.stashedData.isCorrection);
      }
      return openCorrectionForm(row.id);
    }
    if (action === IncomesListAction.Confirm) return confirmInvoice(row.id);
    if (action === IncomesListAction.Delete) return setOpenDialogDelete(true);
    if (action === IncomesListAction.Download) return downloadInvoice(row.id);
    if (action === IncomesListAction.CreateSimilar) return openCloneInvoiceForm();
    if (action === IncomesListAction.GenerateDuplicate) return setOpenCreateDuplicateModal(true);
    if (action === IncomesListAction.Send) return setOpenEmailModal(true);
    if (action === IncomesListAction.MarkAsSent) return markInvoiceAsSent(row.id);
    if (action === IncomesListAction.SendReminder) return setOpenReminderModal(true);
    if (action === IncomesListAction.RecordPayment) return setOpenRecordPaymentModal(true);
    if (action === IncomesListAction.MarkAsPaid) return markInvoiceAsPaid(row.id);

    if (action === IncomesListAction.SendToInsert) return sendInvoiceToInsert(row);
  };

  const onSetHeaderFilter = (filter: InvoiceStatus[]) => {
    setStatusSelected(filter);
  };
  const resetAllFilters = () => {
    const filterStatus = routeParams.companyId ? invoiceStatusAccountantOptions : invoiceStatusOwnerOptions;
    filterStatus.forEach(item => {
      setValue(`checkbox-filter-status-${item.value}`, false);
      setStatusSelected([]);
    });
  };

  const changeSelectedArray = (action: InvoiceActionEnum, invoiceId: string) => {
    if (action === InvoiceActionEnum.DELETE) {
      setCheckboxesState(p => ({ ...p, checkboxesSelected: p.checkboxesSelected.filter(item => item.id !== +invoiceId) }));
    } else if (action === InvoiceActionEnum.CONFIRM) {
      unselectAllCheckboxes();
    }
  };

  return (
    <>
      <SharedInvoiceDetails
        invoiceId={selectedInvoiceId}
        isAccountant={permissionAsAccountant()}
        refreshParentData={refreshData}
        updateData={updateData}
        type="invoice"
      />
      <TheDialog
        testcy="confirm-bulk-operation"
        open={openConfirmBulkOperationDialog}
        onClose={() => setOpenConfirmBulkOperationDialog(false)}
        title={dialogConfirmBulkOperationsData?.dialogTitle}
        rejectButtonText={'Zamknij'}
      >
        <div className="dialog-content" style={{ paddingTop: '20px' }}>
          <div className="confirmed">
            {translateAction(dialogConfirmBulkOperationsData?.action)}
            <span className="bold"> {dialogConfirmBulkOperationsData?.numberOfInvoicesChecked - dialogConfirmBulkOperationsData?.invoicesNotConfirmed}</span>
          </div>
          <div className="checked">
            Zaznaczonych:<span className="bold"> {dialogConfirmBulkOperationsData?.numberOfInvoicesChecked}</span>
          </div>
        </div>
      </TheDialog>
      <InvoicePopups
        onSubmitEmailForm={onSubmitEmailForm}
        onSubmitReminderForm={onSubmitReminderForm}
        onSubmitRecordPayment={onSubmitRecordPayment}
        onSubmitCreateDuplicate={onSubmitCreateDuplicate}
        setOpenEmailModal={setOpenEmailModal}
        setOpenReminderModal={setOpenReminderModal}
        onDeleteInvoice={onSubmitDeleteForm}
        setOpenRecordPaymentModal={setOpenRecordPaymentModal}
        setOpenCreateDuplicateModal={setOpenCreateDuplicateModal}
        openCreateDuplicateModal={openCreateDuplicateModal}
        openDialogDelete={openDialogDelete}
        openEmailModal={openEmailModal}
        openReminderModal={openReminderModal}
        openRecordPaymentModal={openRecordPaymentModal}
        invoiceName={invoiceName}
        amountToPay={isPendingInvoiceData ? null : amountToPay}
        amountPaid={isPendingInvoiceData ? null : amountPaid}
        dueDate={selectedInvoice?.dueDate}
        invoiceData={selectedInvoice}
        isEmailPending={pendingStates.email}
        isReminderPending={pendingStates.reminder}
        isRecordPaymentPending={pendingStates.recordPayment}
      />
      <div className="owner-incomes-tab">
        {!routeParams.companyId && (
          <div className={`money-chart-card ${isChartCardOpen ? 'money-chart-card-open' : ''}`}>
            {isChartLoaded && (
              <div className="money-chart-container">
                <PageLoaderWrapper isLoading={pendingStates.chart}>
                  <MoneyChart testcy="invoices-chart" series={chartData} isPending={pendingStates.chart} enableChartTooltip={enableChartTooltip} />
                </PageLoaderWrapper>
              </div>
            )}
            <div className="invoices-total">
              <p className="invoices-total-title">Łączna kwota faktur</p>
              <div className="invoices-total-amount">{formatCurrency(totalValue || 0.0, 'zł')}</div>
            </div>
            <ButtonIconNew testcy="money-chart-toggle" onClick={toggleChart} size="big" className="money-chart-toggle">
              <EyeIcon isClosed={isChartCardOpen} />
            </ButtonIconNew>
          </div>
        )}
        <div className="filter-area">
          <ListSearchBar
            onChange={onFilterChange}
            resetAllFilters={resetAllFilters}
            isPending={pendingStates.data}
            setPage={setPage}
            defaultValues={searchBarDefaultValues}
            showSearchBar
            showStatusPicker
            showGtuCheckbox={isDefined(routeParams.companyId)}
            showDatePicker
            ref={refTotalAmountClick}
            selectedStatus={statusSelected}
          />
        </div>
        <div className="table-wrapper-g">
          <TheTableNew
            testcy="incomes-table"
            rows={rows}
            clickableRows
            CustomRowComponents={
              <SelectedInvoiceCustomRow
                onUnselectAll={onUnselectAll}
                onSelectAll={onSelectAll}
                checkboxState={checkboxState}
                optionsColumnSpan={3}
                testcy={testcy}
              />
            }
            header={getTableHeader(
              control,
              getValues,
              setValue,
              onChangeCheckboxHeader,
              checkboxState.headerCheckboxIsPressed,
              onBulkOperationsInvoice,
              routeParams.companyId,
              onSetHeaderFilter
            )}
            defaultSortProp={orderBy}
            defaultSortType={sortDirection}
            onChangeSort={onChangeSort}
            onSelectRow={onSelectRow}
            selectedRowId={invoiceId}
            numberOfRowsChecked={checkboxState.numberOfInvoicesChecked}
            onClickOpenMenu={onClickInvoiceMenu}
            actionMenuItems={menuItemsList}
            onClickMenuItem={onClickMenuItem}
            totalRows={totalRows}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePagination={onChangePagination}
          />
        </div>
      </div>
    </>
  );
});
