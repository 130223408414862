import { useState } from 'react';

export type ContractorModalOpenMode = 'add' | 'edit' | 'addWithName' | 'addWithNip';
export type ContractorModalMode = ContractorModalOpenMode | 'closed';

export const useContractorModalController = () => {
  const [modalMode, setModalMode] = useState<ContractorModalMode>('closed');

  const openModal = (mode: ContractorModalOpenMode) => setModalMode(mode);
  const closeModal = () => setModalMode('closed');

  return {
    isOpen: modalMode !== 'closed',
    isAdd: modalMode === 'add' || modalMode === 'addWithNip' || modalMode === 'addWithName',
    modalMode,
    openModal,
    closeModal,
  };
};
