import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
  size?: number;
}

export const CircleIcon = ({ colorType = '', size = 18}: Props) => {
  return (
    <svg className={`icon-color-${colorType}-g`} width={size} height={size} viewBox="0 0 15 15" fill="none">
      <circle cx="7.39233" cy="7.5" r="7" />
    </svg>
  );
};
