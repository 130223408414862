import {
  format,
  isValid,
  parse,
  addDays,
  differenceInCalendarDays,
  startOfMonth,
  endOfMonth,
  endOfYear,
  startOfYear,
  lastDayOfMonth,
  isLeapYear,
  addYears,
  parseISO,
} from 'date-fns';
import { pl } from 'date-fns/locale';
import { DateFormat, DateRange } from '@interfaces';

export const dateFormatter = {
  objectToString: (date: Date, expectedFormat: DateFormat = 'dd.MM.yyyy'): string => {
    if (!isValid(date)) return '';
    return format(date, expectedFormat, { locale: pl });
  },
  changeFormat: (date: string, dateFormat: DateFormat, expectedFromat: DateFormat): string => {
    const parsed = parse(date, dateFormat, new Date());
    return isValid(parsed) ? format(parsed, expectedFromat) : '';
  },
  standardFormat: (date: string): string => {
    const parsed = parseISO(date);
    return isValid(parsed) ? format(parsed, 'dd.MM.yyyy') : '';
  },
  getDateDay: (date: Date): string => {
    return format(date, 'dd');
  },
  getDateMonth: (date: Date): string => {
    return format(date, 'MM');
  },
  getDateObjectFromString: (date: string, format: DateFormat = 'dd.MM.yyyy'): Date => {
    const parsed = parse(date, format, new Date(), { locale: pl });
    return parsed;
  },
  diffFromCurrentDay(date: Date): number {
    return differenceInCalendarDays(date, new Date());
  },
  diffBetweenDates(toDate: Date, fromData: Date): number {
    return differenceInCalendarDays(toDate, fromData);
  },
  addDaysToDate: (date: Date, dayToAdd: number): Date => {
    return addDays(date, dayToAdd);
  },
  isValidStringDate: (date: string, format: DateFormat = 'dd.MM.yyyy'): boolean => {
    const parsed = parse(date, format, new Date());
    return isValid(parsed);
  },
  isValidDateObject: (date: Date): boolean => {
    return isValid(date);
  },
  getMonthRange: (monthDiff: number = 0): DateRange => {
    const date = new Date();
    return {
      fromDate: startOfMonth(new Date(date.getFullYear(), date.getMonth() + monthDiff)),
      toDate: endOfMonth(new Date(date.getFullYear(), date.getMonth() + monthDiff)),
    };
  },
  dateToMonthRange: (date: Date): DateRange => {
    return {
      fromDate: startOfMonth(date),
      toDate: endOfMonth(date),
    };
  },
  getYearRange: (yearDiff: number = 0): DateRange => {
    const date = new Date();
    return {
      fromDate: startOfYear(new Date(date.getFullYear() + yearDiff, date.getMonth())),
      toDate: endOfYear(new Date(date.getFullYear() + yearDiff, date.getMonth())),
    };
  },
  nudgeDate: (range: DateRange, direction: 1 | -1): DateRange => {
    if (isWholeMonthInRange(range)) {
      const fromDate = startOfMonth(new Date(range.fromDate.getFullYear(), range.fromDate.getMonth() + direction));
      const toDate = endOfMonth(new Date(fromDate.getFullYear(), fromDate.getMonth()));
      return {
        fromDate,
        toDate,
      };
    }
    const { isWholeYear, yearDiff } = isWholeYearInRange(range);
    if (isWholeYear) {
      const fromDate = addYears(range.fromDate, direction * yearDiff);
      let toDate = addYears(range.toDate, direction * yearDiff);

      if (fromDate.toDateString().substring(4, 10) === 'Mar 01' && isLeapYear(toDate)) {
        toDate = addDays(toDate, 1);
      }

      return {
        fromDate,
        toDate,
      };
    }
    const difference = 1 + differenceInCalendarDays(range.toDate, range.fromDate);

    const fromDate = addDays(range.fromDate, difference * direction);
    const toDate = addDays(range.toDate, difference * direction);

    return { fromDate, toDate };
  },
};

function isWholeMonthInRange(range: DateRange): boolean {
  if (range.fromDate.getDate() !== 1) return false;
  if (range.fromDate.getFullYear() !== range.toDate.getFullYear()) return false;
  if (range.fromDate.getMonth() !== range.toDate.getMonth()) return false;

  const lastDay = lastDayOfMonth(range.fromDate);

  return range.toDate.getDate() === lastDay.getDate();
}

function isWholeYearInRange(range: DateRange): { isWholeYear: false; yearDiff: null } | { isWholeYear: true; yearDiff: number } {
  //if the difference between the years is leass than 364 days, it is definitely not a whole year
  if (range.toDate.valueOf() - range.fromDate.valueOf() < 31_449_600_000) return { isWholeYear: false, yearDiff: null };

  const newFromDate = addDays(range.fromDate, -1);

  // get date strings to compare later
  // substring() converts the date to usable format: "Thu Oct 05 2023" -> "Oct 05"
  const fromDateStr = range.fromDate.toDateString().substring(4, 10);
  const fromDateStrNew = newFromDate.toDateString().substring(4, 10);
  const toDateStr = range.toDate.toDateString().substring(4, 10);

  // should return true if either the modified dates match exactly
  // or in two cases when there might be a leap year issue:
  //     if startDate is Mar 01, and it ISN'T a leap year, and the endDate is Feb 29, and it IS a leap year
  //     if startDate is Mar 01, and it IS a leap year, and the endDate is Feb 28, and it ISN'T a leap year
  // this is more complicated than I would want, but it is what it is ¯\_(ツ)_/¯
  if (
    fromDateStrNew === toDateStr ||
    (fromDateStr === 'Mar 01' &&
      ((!isLeapYear(range.fromDate) && toDateStr === 'Feb 29') || (isLeapYear(range.fromDate) && toDateStr === 'Feb 28' && !isLeapYear(range.toDate))))
  ) {
    return {
      isWholeYear: true,
      yearDiff: range.toDate.getFullYear() - newFromDate.getFullYear(),
    };
  }

  return { isWholeYear: false, yearDiff: null };
}
