import { useMemo } from 'react';
import { CashflowDataPoint } from 'src/services/dashboard';

type UseCashflowDataProps = {
  data: CashflowDataPoint[];
  xThreshold: Date;
};

export const useCashflowData = ({ data, xThreshold }: UseCashflowDataProps) => {
  const sanitizedData = useMemo(() => {
    const chartdata: CashflowDataPoint[] = [];

    for (let i = 0; i < data.length - 1; i++) {
      const currentValue = data[i].value;
      const nextValue = data[i + 1].value;
      const firstTime = new Date(data[i].date.toDateString());
      const secondTime = new Date(data[i + 1].date.toDateString());

      const isDateThreshold = firstTime < xThreshold && secondTime > xThreshold;

      chartdata.push({
        date: firstTime,
        value: currentValue,
      });

      if (isDateThreshold) {
        chartdata.push({
          date: xThreshold,
          value: (currentValue + nextValue) / 2,
        });
      }
    }

    if (chartdata.length !== 0) {
      chartdata.push(data[data.length - 1]);
    }

    return chartdata;
  }, [data, xThreshold]);

  return sanitizedData;
};
