import React from 'react';
import { FormProvider } from 'react-hook-form';
import { NumberInput, DatePicker, ButtonMain } from '@components';
import { formErrorMsg, formatNumberToCurrency, standardValidators, stringToNumberSecondOptions } from '@utils';
import { useUpdateBankAccountBalanceForm } from '@hooks';

import './updateBankAccountBalanceForm.scoped.scss';

type Props = {
  onCancel: () => void;
  onSubmitted: () => void;
};

export const UpdateBankAccountBalanceForm = ({ onCancel, onSubmitted }: Props) => {
  const { form, isSubmitting, onSubmit } = useUpdateBankAccountBalanceForm({ onSubmitted });

  const onBalanceInputBlur = () => {
    const balanceString = form.getValues('balance');
    const formattedBalance = formatNumberToCurrency(stringToNumberSecondOptions(balanceString));
    form.setValue('balance', formattedBalance);
  };

  return (
    <FormProvider {...form}>
      <form className="cashflow-bank-account-balance-form" onSubmit={onSubmit}>
        <DatePicker
          name="date"
          testcy="cashflow-bank-account-balance-date-picker"
          label="Data aktualizacji"
          appearance="light"
          width="100%"
          disableFuture
          disabled={isSubmitting}
          validation={{
            required: formErrorMsg.isRequired,
            validate: {
              validDate: standardValidators.validDate,
              noFutureDate: standardValidators.noFutureDate,
            },
          }}
        />
        <NumberInput
          name="balance"
          testcy="cashflow-bank-account-balance-value"
          label="Aktualny stan konta"
          appearance="light"
          width="100%"
          placeholder="0,00"
          float
          negative
          onBlur={onBalanceInputBlur}
          disabled={isSubmitting}
          validation={{
            required: formErrorMsg.isRequired,
          }}
        />
        <div className="cashflow-bank-account-balance-buttons">
          <ButtonMain
            testcy="cashflow-bank-account-balance-cancel"
            isDisabled={isSubmitting}
            content="Anuluj"
            width="160px"
            colorType="secondary"
            onClick={onCancel}
          />
          <ButtonMain testcy="cashflow-bank-account-balance-submit" type="submit" content="Aktualizuj saldo" width="160px" isDisabled={isSubmitting} />
        </div>
      </form>
    </FormProvider>
  );
};
