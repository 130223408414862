import { CustomObject, OptionBase } from '@interfaces';
import { GusCompanyData } from '@services';
import { AccountantStatusType, DocumentFileCategoryEnum, InvoiceStatusType, PaymentStatusType } from '@utils';
import { isEqual } from 'lodash';
import { DocumentIcon } from '@components';
import React from 'react';
import { EmployeesContractEnum } from 'src/services/employees';

export const translateStatus = (value: InvoiceStatusType | AccountantStatusType | PaymentStatusType | DocumentFileCategoryEnum): string => {
  if (value === 'Draft') return 'Szkic';
  if (value === 'Issued') return 'Niewysłana';
  if (value === 'Overdue') return 'Przeterminowana';
  if (value === 'Sent') return 'Wysłana';
  if (value === 'PartiallyPaid') return 'Cz. opłacona';
  if (value === 'Paid') return 'Opłacona';
  if (value === 'Unpaid') return 'Nieopłacona';
  if (value === 'New') return 'Nowa';
  if (value === 'Sending') return 'W trakcie wysyłania do systemu księgowego';
  if (value === 'SentToAccountingService') return 'Wysłana do systemu księgowego';
  if (value === 'Confirmed') return 'Zatwierdzona';
  if (value === 'Empty') return 'Brak';
  if (value === 'RevenuesExpenditures') return 'Książki Przychodów i Rozchodów';
  if (value === 'ProfitsLosses') return 'Rachunek Zysków i Strat';
  if (value === 'BalanceSheet') return 'Bilans';
  if (value === 'Personnel') return 'Kadry';
  if (value === 'OfficialLetters') return 'Pisma urzędowe';
  return '';
};

export const translateAccountantStatus = (value: AccountantStatusType): string => {
  switch (value) {
    case 'New':
      return 'Niewysłana';
    case 'SentToAccountingService':
      return 'Wysłana';
    case 'Sending':
      return 'Wysyłanie...';
    case 'Confirmed':
      return 'Zadekretowana';
    default:
      return '';
  }
};

export const translateTypeOfContract = (value: EmployeesContractEnum): string => {
  if (value === EmployeesContractEnum.EmploymentContract) return 'Umowa o pracę';
  if (value === EmployeesContractEnum.CivilLawContract) return 'Umowa cywilnoprawna';

  return '';
};

export const formatCurrency = (value: number, currency?: string): string => {
  const numberString = value.toLocaleString('pl-PL', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    useGrouping: true,
  });
  if (!currency) return numberString;
  return `${numberString} ${currency}`;
};

export const formatNumberToCurrency = (value: number): string => {
  const formatter = new Intl.NumberFormat('pl-PL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const daysOverdueToText = (value: number): string => {
  return value === 1 ? '1 dzień' : `${value} dni`;
};

export const isArraysEqual = (arrayOne: CustomObject[], arrayTwo: CustomObject[]): boolean => {
  if (arrayOne.length !== arrayTwo.length) return false;
  let isEqualValues = true;

  arrayOne.forEach((obj, index) => {
    if (!isEqual(obj, arrayTwo[index])) isEqualValues = false;
  });
  return isEqualValues;
};

export const stringToNumber = (value: string): number => {
  if (!value) return 0;
  const removeSpaceString = value.replace(/ /g, '');
  const stringFormated = removeSpaceString.replace(',', '.');
  const num = +stringFormated;
  if (isNaN(num)) return 0;
  return num;
};

export const stringToNumberSecondOptions = (value: string): number => {
  if (!value) return 0;
  const removeSpaceString = value.split(/\s/).join('');
  const stringFormated = removeSpaceString.replace(',', '.');
  const num = +stringFormated;
  if (isNaN(num)) return 0;
  return num;
};

export const numberToString = (value: number) => {
  if (!value) return '0';
  return value.toString().replace('.', ',');
};

export const toTwoDecimalPlaces = v => (Math.round(v * 100) / 100).toFixed(2);

export const formatFetchedDataToPdf = (data: Blob, name: string): void => {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${name}`;
  a.click();
  a.remove();
  setTimeout(() => window.URL.revokeObjectURL(url), 100);
};

export const checkIsIncorrectDataFromGus = (data: GusCompanyData): boolean => {
  const { address = {}, companyName, regon } = data;
  const gusData = {
    companyName,
    ...address,
    regon,
  };
  return Object.values(gusData).every(x => x === '');
};

export const findOptionLabel = (options: OptionBase[], optionValue: string) => {
  return options.find(o => o.value === optionValue)?.label;
};

export const setIconColor = (color: string) => {
  switch (color) {
    case 'primary':
      return '#0aa473';
    case 'black':
      return '#212529';
    case 'disabled':
      return '#828282';
  }
};

const ONE_KILOBYTE = 1024;
const FILESIZE_DECIMALS = 2;
const FILESIZE_UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] as const;

export const formatFileSize = (filesize: number) => {
  const unitIndex = Math.floor(Math.log(filesize) / Math.log(ONE_KILOBYTE));

  if (unitIndex >= FILESIZE_UNITS.length) return 'Całkiem spory plik';

  return `${(filesize / Math.pow(ONE_KILOBYTE, unitIndex)).toFixed(FILESIZE_DECIMALS)} ${FILESIZE_UNITS[unitIndex]}`;
};

export const transformFaultyFilesToJSX = (
  files: File[],
  case1: string = 'inny plik',
  case234: string = 'inne pliki',
  case567890: string = 'innych plików'
): JSX.Element[] => {
  //map first 3 files
  const JSX = files.slice(0, 3).map(file => (
    <li key={file.name}>
      <div className="name-icon-wrapper">
        <div className="icon">
          <DocumentIcon colorType="grey200" fill="#939AA4" />
        </div>
        <div className="name-wrapper">
          <span className="name-ellipsis">{file.name.slice(0, -4)}</span>
          <span style={{ color: '#5F6770' }}>{file.name.slice(-4)}</span>
        </div>
      </div>
    </li>
  ));
  //add an indicator for how many total files are there
  if (files.length > 3) {
    const fileCount = files.length - 3;
    JSX.push(
      <li className="custom-item" key="$$CUSTOM$$">
        ...i {fileCount} {pluralize(fileCount, case1, case234, case567890)}
      </li>
    );
  }

  return JSX;
};

export const transformBiggerFilesToJSX = (
  files: File[],
  case1: string = 'inny plik',
  case234: string = 'inne pliki',
  case567890: string = 'innych plików'
): JSX.Element[] => {
  //map first 3 files
  const JSX = files.slice(0, 3).map(file => (
    <li key={file.name}>
      <div className="name-icon-wrapper-bigger-size-file">
        <div className="icon">
          <DocumentIcon colorType="grey200" fill="#939AA4" />
        </div>
        <div>
          <div className="name-wrapper">
            <span className="name-ellipsis">{file.name.slice(0, -4)}</span>
            <span style={{ color: '#5F6770' }}>{file.name.slice(-4)}</span>
          </div>
          <p className="size">Ten plik ma rozmiar {formatFileSize(file.size)}.</p>{' '}
        </div>
      </div>{' '}
    </li>
  ));
  //add an indicator for how many total files are there
  if (files.length > 3) {
    const fileCount = files.length - 3;
    JSX.push(
      <li className="custom-item" key="$$CUSTOM$$">
        ...i {fileCount} {pluralize(fileCount, case1, case234, case567890)}
      </li>
    );
  }

  return JSX;
};
export function pluralize(amount: number, case1: string, case234: string, case567890: string) {
  if (amount === 1) return case1;
  const amountStr = String(amount);
  if (amountStr.match(/2$|3$|4$/) && amountStr !== '12' && amountStr !== '13' && amountStr !== '14') return case234;
  return case567890;
}
