import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { DragoScrollWrapper } from '@components';
import { CustomObject } from '@interfaces';
import './invoicePdf.scss';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

interface Props {
  pdfFileUrl: string;
  zoom: number;
  zoomTick: number;
  pdfCurrentPage: number;
  onDocumentLoadSuccess: (e: CustomObject) => void;
  handleZoom: (v: number) => void;
  setIsLoadedImage: (v: boolean) => void;
  allowCopyText?: boolean;
}

export const InvoicePdf = ({
  pdfFileUrl,
  zoom,
  zoomTick,
  pdfCurrentPage,
  onDocumentLoadSuccess,
  handleZoom,
  setIsLoadedImage,
  allowCopyText,
}: Props) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight - 10);
    }
  }, []);

  return (
    <div className="invoice-wrapper" ref={ref}>
      <DragoScrollWrapper
        id="invoice-drag-scroll"
        onZoom={v => handleZoom(v)}
        zoom={zoom}
        zoomTick={zoomTick}
        allowCopyText={allowCopyText}
      >
        <Document
          className="invoice-document"
          file={pdfFileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={() => setIsLoadedImage(true)}
          error={<div>Wystąpił nieoczekiwany błąd</div>}
          noData={<div>Brak załadowane zdjęcia</div>}
        >
          <Page
            height={height}
            pageNumber={pdfCurrentPage}
            scale={zoom}
            renderTextLayer={allowCopyText}
            renderAnnotationLayer={false}
            className="annotation-layer"
          />
        </Document>
      </DragoScrollWrapper>
    </div>
  );
};
