import { Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';
import { formatNumberToCurrency } from '@utils';
import React from 'react';

import './invoiceSummaryTable.scoped.scss';
import { LoaderIcon } from '@components';

type Props = {
  isRecalculating: boolean;
  net: number;
  vat: number;
  gross: number;
};

export const InvoiceSummaryTable = ({ isRecalculating, vat, net, gross }: Props) => {
  const loaderOrValue = (value: number) => {
    if (isRecalculating) {
      return <LoaderIcon size="tiny" />;
    } else {
      return formatNumberToCurrency(value);
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell className="bold">Kwota VAT</TableCell>
          <TableCell className="bold">Wartość netto</TableCell>
          <TableCell className="bold">Wartość brutto</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className="bold">Suma</TableCell>
          <TableCell>{loaderOrValue(vat)}</TableCell>
          <TableCell>{loaderOrValue(net)}</TableCell>
          <TableCell className="bold">{loaderOrValue(gross)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
