import { useApiMutation } from '@hooks';
import { InvoiceTag } from '../model/chatMessage';

type Hint = {
  id: string | number;
  display: string;
};
type ResolveHintsCallback = (hints: Hint[]) => void;

export type ResolveHintsFunction = (filter: string, callback: ResolveHintsCallback) => void;

type UseHintResolverProps = {
  companyId: number;
};

export const useHintResolver = ({ companyId }: UseHintResolverProps) => {
  const { data: hintList, mutate } = useApiMutation('getInvoiceMentionHints');

  const getById = (id: number): InvoiceTag => {
    const invoice = hintList.invoices.find(hint => hint.invoiceId === id);

    if (invoice == null) {
      return null;
    } else {
      return { id: invoice.invoiceId.toString(), number: invoice.number };
    }
  };

  const resolveHints: ResolveHintsFunction = (filter: string, callback: ResolveHintsCallback) => {
    mutate(
      { companyId, filter },
      {
        onSuccess: result => {
          const hints = result.invoices.map(invoice => ({ id: invoice.invoiceId, display: invoice.number }));
          callback(hints);
        },
      }
    );
  };

  return {
    getById,
    resolveHints,
  };
};
