import { addDays } from 'date-fns';
import { useApiQuery } from '../useApiQuery';

export const useCashflow = (date: Date) => {
  const { data: dataPoints, isLoading } = useApiQuery(
    'getCashflow',
    {
      from: addDays(date, -30),
      to: addDays(date, 30),
    },
    {
      select: response => response.dataPoints ?? [],
      keepPreviousData: true,
      placeholderData: {
        dataPoints: [],
      },
    }
  );

  return {
    cashFlow: dataPoints,
    isLoading,
  };
};
