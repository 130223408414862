import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { InvoiceDetails } from '@services';

interface InvoiceStore {
  selectedInvoice: InvoiceDetails | null;
  setSelectedInvoice: (data: InvoiceDetails | null) => void;
  invoiceId: string;
  isOpenInvoiceDrawer: boolean;
  isOpenInvoicePreview: boolean;
  setOpenInvoiceDrawer: (isOpen: boolean, invoiceId: string) => void;
  setOpenInvoicePreview: (isOpen: boolean, invoiceId: string) => void;
  isClone: null | boolean;
  setIsClone: (isClone: boolean) => void;
  isCorrected: null | boolean;
  setIsCorrected: (isCorrected: boolean) => void;
  isFromDashboard: null | boolean;
  setIsFromDashboard: (isFromDashboard: boolean) => void;
  isFavoriteClicked: null | boolean;
  setIsFavoriteClicked: (isClone: boolean) => void;
}

export const useInvoiceStore = create<InvoiceStore>(set => ({
  selectedInvoice: null,
  setSelectedInvoice: data => set(() => ({ selectedInvoice: data })),
  isOpenInvoiceDrawer: false,
  isOpenInvoicePreview: false,
  invoiceId: null,
  setOpenInvoiceDrawer: (isOpen: boolean, invoiceId: string) => set(() => ({ isOpenInvoiceDrawer: isOpen, invoiceId: invoiceId })),
  setOpenInvoicePreview: (isOpen: boolean, invoiceId: string) => set(() => ({ isOpenInvoicePreview: isOpen, invoiceId: invoiceId })),
  isClone: false,
  setIsClone: (isClone: boolean) => set(() => ({ isClone: isClone })),
  isCorrected: false,
  setIsCorrected: (isCorrected: boolean) => set(() => ({ isCorrected: isCorrected })),
  isFromDashboard: false,
  setIsFromDashboard: (isFromDashboard: boolean) => set(() => ({ isFromDashboard: isFromDashboard })),
  isFavoriteClicked: false,
  setIsFavoriteClicked: (isClicked: boolean) => set(() => ({ isFavoriteClicked: isClicked })),
}));

if (process.env.NODE_ENV === 'development') mountStoreDevtool('InvoiceStore', useInvoiceStore);

// for future use: remember to set setSelectedInvoice as null!!
