import { SpecialInput, TextInputNew, TheDialog } from '@components';
import { BankAccountData } from '@interfaces';
import { bankNameNumbers, focusOnNextFormInput, formErrorMsg } from '@utils';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { cleanupBank } from 'src/components/form';
import './bankDialog.scoped.scss';

type OmittedBankAccountData = Omit<BankAccountData, 'mainAccount'>;

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (data: OmittedBankAccountData) => void;
  defaultFormValues?: OmittedBankAccountData;
}

export const BankDialog = ({ setIsOpen, onSubmit, defaultFormValues }: Props) => {
  const useFormProps = useForm<OmittedBankAccountData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: defaultFormValues,
  });
  const { reset, handleSubmit, setValue, watch } = useFormProps;

  const onSubmitBankAccountDialog = (agree: boolean) => {
    if (agree) {
      handleSubmit(f => {
        onSubmit(f);
        reset();
        setIsOpen(false);
      })();
      return;
    }
    setIsOpen(false);
    reset();
  };

  const getDefaultBankName = (number: string): string | null => {
    if (!number) return null;
    number = cleanupBank(number);
    if (number.length !== 26) return null;
    number = number.slice(2, 6);
    const name = bankNameNumbers[number];
    return name ?? null;
  };

  const setDefaultBankName = (number: string) => {
    const name = getDefaultBankName(number);
    if (!name) return;
    setValue('bankName', name);
  };

  const isGeneratedBankName = getDefaultBankName(watch('number')) !== null;

  return (
    <TheDialog
      testcy="bank-account"
      title="Nowy rachunek bankowy"
      open
      onClose={onSubmitBankAccountDialog}
      approveButtonText="Zatwierdź"
      rejectButtonText="Anuluj"
    >
      <form>
        <div className="bank-account-dialog">
          <FormProvider {...useFormProps}>
            <SpecialInput
              testcy="new-bank-account-number"
              type="bank"
              name="number"
              label="Numer rachunku bankowego"
              width="100%"
              appearance="light"
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
              validation={{
                required: formErrorMsg.isRequired,
              }}
              onChange={setDefaultBankName}
            />
            <TextInputNew
              testcy="new-bank-account-name"
              name="bankName"
              label="Nazwa banku"
              width="100%"
              appearance="light"
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
              validation={{
                required: formErrorMsg.isRequired,
              }}
              disabled={isGeneratedBankName}
            />
          </FormProvider>
        </div>
      </form>
    </TheDialog>
  );
};
