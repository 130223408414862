import React, { MouseEvent } from 'react';
import Button from '@mui/material/Button';
import './buttonMain.scoped.scss';
import { Tooltip } from '@mui/material';

interface Props {
  content: string;
  testcy: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  colorType?: 'primary' | 'secondary' | 'blackOutlined' | 'third' | 'four' | 'business' | 'business400' | 'red' | 'text';
  width?: string;
  sizeType?: 'extra-small' | 'small' | 'medium' | 'big';
  type?: 'button' | 'submit' | 'reset';
  endIcon?: React.ReactNode;
  tooltip?: string;
  newLayout?: boolean;
}

export const ButtonMain = ({
  content,
  testcy,
  onClick = () => {},
  isDisabled,
  colorType = 'primary',
  width = 'unset',
  sizeType = 'medium',
  type = 'button',
  endIcon,
  tooltip,
  newLayout = false,
}: Props) => {
  const button = (
    <Button
      className={`button ${colorType} ${sizeType} ${isDisabled ? 'disabled-btn' : ''}`}
      style={{ width: width }}
      onClick={onClick}
      disabled={isDisabled}
      data-testcy={testcy}
      type={type}
      endIcon={endIcon}
      classes={{
        root: newLayout ? 'button-new-layout' : '',
      }}
    >
      {content}
    </Button>
  );

  return tooltip ? (
    <Tooltip sx={{ m: 1 }} arrow={true} title={tooltip}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    <>{button}</>
  );
};
