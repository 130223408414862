import { OwnerContractorForm, SharedCompanyForm, SharedContractorsList } from '@features';
import { ContractorData, getContractorById } from '@services';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { TheRouteObject } from '../types';

export const contractor: TheRouteObject[] = [
  {
    index: true,
    element: <SharedContractorsList />,
  },
  {
    path: 'add',
    element: <OwnerContractorForm />,
    handle: {
      crumb: 'Dodaj kontrahenta',
    },
  },
  {
    path: 'edit/:contractorId',
    element: <OwnerContractorForm />,
    loader: async ({ params }) => {
      return await getContractorById(params.contractorId);
    },
    handle: {
      crumb: (data: ContractorData) => `Edytuj ${data.friendlyName ?? data.name}`,
    },
  },
  {
    path: 'owner/edit/:role/:place',
    element: <SharedCompanyForm />,
    handle: {
      crumb: `Edycja danych firmy`,
    },
  },
  {
    path: 'owner/edit/:role',
    element: <SharedCompanyForm />,
    handle: {
      crumb: `Edycja danych firmy`,
    },
  },
  { path: '*', element: <Navigate to="/contractors-list" /> },
];
