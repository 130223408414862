import { FileDropZone, TheDialog, ListSearchBar, TableRowInterface } from '@components';
import { FormProvider, useForm } from 'react-hook-form';
import { RequestErrorMessage } from '@interfaces';
import { addCategoryToFile, compareInboxHashes, deleteInboxFile, downloadInboxFile, getInboxFiles, getMyCompany, uploadInboxFiles } from '@services';
import {
  DocumentFileCategoryEnum,
  FileObject,
  apiFileToFileObject,
  documentFileCategoryOptions,
  formatFetchedDataToPdf,
  handleApiError,
  snackbarMessagesHandler,
} from '@utils';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DocumentsFormData, actionMenuItems, updateFilesAfterCategoryChanged } from './sharedInbox.utils';
import { SharedTableList } from '@features';
import './sharedInbox.scss';
import { useCurrentCompanyId } from '@hooks';
import cleaningPic from '@assets/images/cleaning_angle.png';

export const SharedInbox = () => {
  const [files, setFiles] = useState<FileObject[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const { companyId } = useParams();
  const [fileToDelete, setFileToDelete] = useState<FileObject>();
  const [statusSelected, setStatusSelected] = useState<DocumentFileCategoryEnum[]>([]);
  const currentCompanyId = useCurrentCompanyId();
  const [isFetchPending, setIsFetchPending] = useState(true);
  const [isUploadPending, setIsUploadPending] = useState(false);

  const useFormProps = useForm<DocumentsFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      'checkbox-filter-tag-RevenuesExpenditures': false,
      'checkbox-filter-tag-ProfitsLosses': false,
      'checkbox-filter-tag-BalanceSheet': false,
      'checkbox-filter-tag-Personnel': false,
      'checkbox-filter-tag-OfficialLetters': false,
    },
  });
  const { setValue } = useFormProps;

  useEffect(() => {
    fetchFiles(statusSelected);
  }, [statusSelected]);

  const fetchFiles = async (statusSelected: DocumentFileCategoryEnum[]) => {
    // next task - add categories to params dynamically #2683
    const params = { categories: statusSelected };
    setIsFetchPending(true);
    try {
      const myCompany = await getMyCompany();
      const response = await getInboxFiles(String(companyId ?? myCompany.id), params);
      const mappedFiles = response.map(file => {
        return apiFileToFileObject(file);
      });
      setFiles(mappedFiles);
    } catch (err) {
      handleApiError(err);
    }
    setIsFetchPending(false);
  };

  const addFiles = (filesToAdd: FileObject[]) => {
    setFiles([...filesToAdd, ...files]);
  };
  const removeFile = (id: string) => {
    const newFiles = files.filter(file => file.id != id);
    setFiles(newFiles);
  };

  const onFileUpload = async (data: FormData) => {
    const myCompany = await getMyCompany();
    const thisCompanyId = companyId ?? myCompany.id;

    data.set('companyId', String(thisCompanyId));

    setIsUploadPending(true);
    try {
      const response = await uploadInboxFiles(String(thisCompanyId), data);
      const mappedFiles = response.map(file => apiFileToFileObject(file));
      addFiles(mappedFiles);
      snackbarMessagesHandler.inboxFileUpload(mappedFiles.length > 1);
    } catch (err) {
      handleApiError(err);
    }
    setIsUploadPending(false);
  };

  const onChangeCategoryFile = (value: DocumentFileCategoryEnum, id: number) => {
    addCategoryToFile(id, value)
      .then(() => {
        snackbarMessagesHandler.documentChangedCategory();
        const updateFiles = updateFilesAfterCategoryChanged(files, value, id);
        setFiles(updateFiles);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        fetchFiles(statusSelected);
      });
  };

  const onClickRemove = (data: TableRowInterface) => {
    setOpenDeleteDialog(true);
    const foundFile = files.find(file => file.id == data.id);
    setFileToDelete(foundFile);
  };

  const onClickDownload = (fileData: TableRowInterface) => {
    downloadInboxFile(fileData.id)
      .then((data: Blob) => {
        if (!data) return;
        const blob = new Blob([data], { type: data.type });
        const foundFile = files.find(file => file.id == fileData.id);
        formatFetchedDataToPdf(blob, foundFile.originalFilename);
        snackbarMessagesHandler.fileDownloaded();
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err));
  };

  const onCloseConfirmDelete = async (isConfirmed: boolean) => {
    setOpenDeleteDialog(false);
    if (!isConfirmed) return;

    try {
      await deleteInboxFile(fileToDelete.id);
      snackbarMessagesHandler.fileDeleted();
      removeFile(fileToDelete.id);
    } catch (err) {
      handleApiError(err);
    }
    setFileToDelete(undefined);
  };

  const onSetHeaderFilter = (filter: DocumentFileCategoryEnum[]) => {
    setStatusSelected(filter);
  };

  const resetAllFilters = () => {
    documentFileCategoryOptions.forEach(item => {
      setValue(`checkbox-filter-tag-${item.value}`, false);
      setStatusSelected([]);
    });
  };

  return (
    <div className="standard-layout-spacing-g">
      <div className="header">
        <h1 className="title-g" data-testcy="inbox-page-title">
          Dokumenty
        </h1>
      </div>
      <div className="shared-inbox-wrapper">
        <TheDialog
          testcy="delete-confirmation"
          open={openDeleteDialog}
          onClose={onCloseConfirmDelete}
          approveButtonText="Tak"
          rejectButtonText="Nie"
        >
          <div className="inbox-dialog-container">
            <img src={cleaningPic} alt="cleaning-set" width={111} height={128} />

            <div>Czy na pewno chcesz usunąć dokument</div>
            <div className="remove-dialog-content-document">
              <div>{fileToDelete?.originalFilename}</div>
              <p>?</p>
            </div>
          </div>
        </TheDialog>
        <div className="file-drop-zone-container">
          <FileDropZone
            testcy="inbox-upload"
            accept="pdf,png,jpg,jpeg"
            showMaxTotalSize
            isPending={isUploadPending}
            multiple
            compareHashesFetchFn={(hashes: string[]) =>
              compareInboxHashes({
                values: hashes,
                companyId: currentCompanyId,
              })
            }
            onChange={onFileUpload}
          />
        </div>
        <div className="list-search-bar-wrapper">
          <ListSearchBar resetAllFilters={resetAllFilters} isPending={isFetchPending} selectedStatus={statusSelected} />
        </div>
        <FormProvider {...useFormProps}>
          <SharedTableList
            testcy="documents-files-list"
            header={{ name: 'Nazwa', tag: 'Tag', date: 'Data dodania' }}
            files={files}
            actionMenuItems={actionMenuItems}
            tagOptions={documentFileCategoryOptions}
            statusSelected={statusSelected}
            onSetHeaderFilter={onSetHeaderFilter}
            onChangeCategoryFile={onChangeCategoryFile}
            onDownloadClick={item => onClickDownload(item as FileObject)}
            onDeleteClick={item => onClickRemove(item as FileObject)}
            rowsWithoutScroll={8}
            isPending={isFetchPending}
          ></SharedTableList>
        </FormProvider>
      </div>
    </div>
  );
};
