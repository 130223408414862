export enum SortDirection {
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending',
}

export enum BusinessEntity {
  PERSON = 'person',
  COMPANY = 'company',
}

export enum UserRolesEnum {
  BUSINESS = 'business role',
  ACCOUNTANT = 'accountant',
}

export const InvoiceNumberFormat = {
  Yearly: 'Yearly',
  Monthly: 'Monthly',
} as const;
export type InvoiceNumberFormat = typeof InvoiceNumberFormat[keyof typeof InvoiceNumberFormat];

export enum InvoicesSummaryTypeEnum {
  OVERDUE = 'Overdue',
  OUTSTANDING = 'Outstanding',
  DRAFT = 'Draft',
}
export enum CostDocumentsStatusTypeEnum {
  ADDED = 'added',
}
export enum CostTypeDocumentsTypeEnum {
  EXPENSE = 'expense',
  STORAGE = 'storage',
  INVESTMENT = 'investment',
}

export enum IntegrationStatus {
  IDLE = 'Idle',
  RUNNING = 'Running',
  PAUSE_REQUESTED = 'PauseRequested',
  PAUSED = 'Paused',
}

export enum VatTemplateTypeEnum {
  KPN = '1',
  KPN_PZ = '2',
  NK = '3',
  NK_K = '4',
  NKST = '5',
  NKST_K = '6',
  OO_DT = '7',
  WNT = '8',
  WNT_ST = '9',
  WTT_N = '10',
}

export enum InvoiceActionEnum {
  SEND = 'send',
  OPEN_SIDE_PANEL = 'openSidePanel',
  MARK_AS_PAID = 'markAsPaid',
  DELETE = 'delete',
  CONFIRM = 'confirm',
  SEND_REMINDER = 'sendReminder',
  RECORD_PAYMENT = 'recordPayment',
}
export enum CostsUnitEnum {
  VAT_RATE = 'VatRate',
  GROSS_VALUE = 'GrossValue',
  NET_VALUE = 'NetValue',
}

export const ButtonType = {
  Button: 'button',
  Submit: 'submit',
  Reset: 'reset',
} as const;
export type ButtonType = typeof ButtonType[keyof typeof ButtonType];

export enum EmployeesOrderOptionsEnum {
  ADDRESS = 'address',
  TYPE_OF_EMPLOYMENT = 'typeOfEmployment',
  CONTRACT_START_DATE = 'contractStartDate',
  PESEL = 'pesel',
  FULL_NAME = 'fullName',
}

export enum EmployeesStatusEnum {
  REGISTER = 'Registered',
  UNREGISTER = 'Deregistered',
}

export enum PaymentTypeEnum {
  TRANSFER = 'Transfer',
  CASH = 'Cash',
  TRANSFER_SPLIT_PAYMENT = 'TransferSplitPayment',
  CARD = 'Card',
  PREPAID = 'Prepaid',
}

export enum BulkOperationsEnum {
  CONFIRM = 'confirm',
  MARK_AS_PAID = 'markAsPaid',
  EXPORT_CSV = 'exportCSV',
  SEND_TO_INSERT = 'sendToInsert',
}

export enum VehicleOperationEnum {
  NONE = 'None',
  EXPLOITATION = 'Exploitation',
  LEASING = 'Leasing',
}

export enum DeductVATEnum {
  PARTIALLY_DEDUCTIBLE = 'PartiallyDeductible',
  FULLY_DEDUCTIBLE = 'FullyDeductible',
}

export enum DeductionInCostsEnum {
  FULLY = 'Fully',
  PARTIALLY_75 = 'Partial75',
  PARTIALLY_20 = 'Partial20',
  PARTIALLY_LEASING = 'PartialLeasing',
}

export enum DeductionLimitEnum {
  _225 = '225 000,00',
  _150 = '150 000,00',
}

export enum DocumentFileCategoryEnum {
  REVENUES_EXPENDITURES = 'RevenuesExpenditures',
  PROFITS_LOSSES = 'ProfitsLosses',
  BALANCE_SHEET = 'BalanceSheet',
  PERSONNEL = 'Personnel',
  OFFICIAL_LETTERS = 'OfficialLetters',
}
