import React from 'react';
import { EmployeeForm } from '@features';
import { TheDialog } from '@components';

interface Props {
  openEmployeeModal: boolean;
  setOpenEmployeeModal: (v: boolean) => void;
}

export const ModalAndDialogs = ({ openEmployeeModal, setOpenEmployeeModal }: Props) => {
  const closeModal = () => setOpenEmployeeModal(false);

  return (
    <>
      <TheDialog formDialog  title="Dodanie nowego pracownika" testcy="owner-employee" open={openEmployeeModal} onClose={closeModal}>
        <EmployeeForm onSubmitted={closeModal} />
      </TheDialog>
    </>
  );
};
