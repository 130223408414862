import { PageLoaderWrapper, TheDialog } from '@components';
import { useInvoiceHistory, useModal } from '@hooks';
import { InvoiceHistory } from '@services';
import { default as React, useEffect, useState } from 'react';
import { InvoiceHistoryItemDetails } from '../../components';
import { TheAccordion } from './../../../../../components/miscellaneous/theAccordion/theAccordion';
import { HistoryItem } from './historyItem/historyItem';

import './historyTab.scoped.scss';
import { dateFormatter } from '@lib';

interface Props {
  invoiceId: string;
  type: 'cost' | 'invoice';
}

export const InvoiceDetailsHistoryTab = ({ invoiceId, type }: Props) => {
  const { history, isLoading } = useInvoiceHistory(type === 'invoice' && invoiceId);
  const [currentHistoryDetails, setCurrentHistoryDetails] = useState<InvoiceHistory>(null);
  const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(true);
  const [date, setDate] = useState('');

  const { isOpen: isDetailsModalOpen, closeModal, openModal } = useModal();

  useEffect(() => {
    if (currentHistoryDetails) {
      const dateFormated = dateFormatter.changeFormat(currentHistoryDetails?.time, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS", 'dd.MM.yyyy HH:mm');
      setDate(dateFormated);
    }
  }, [currentHistoryDetails]);

  const onHistoryItemClick = (details: InvoiceHistory) => {
    openModal();
    setCurrentHistoryDetails(details);
  };

  return (
    <div className="section no-background">
      <TheDialog
        title={`Zmiany użytkownika ${currentHistoryDetails?.user} - ${date}`}
        testcy="invoice-history"
        open={isDetailsModalOpen}
        onClose={closeModal}
        approveButtonText="Ok"
        approveButtonWidth="120px"
      >
        <InvoiceHistoryItemDetails historyDetails={currentHistoryDetails} />
      </TheDialog>
      <PageLoaderWrapper isLoading={isLoading}>
        <TheAccordion
          testcy="history-accordion"
          open={isHistoryOpen}
          setOpen={setIsHistoryOpen}
          title="Historia faktury"
          id="history-accordion"
          appearance="primary"
        >
          <div className="history">
            {history.length > 0
              ? history.map((item, index) => {
                  return <HistoryItem key={index} item={item} onClick={() => onHistoryItemClick(item)} />;
                })
              : 'Brak historii'}
          </div>
        </TheAccordion>
      </PageLoaderWrapper>
    </div>
  );
};
