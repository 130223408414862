import React, { useMemo } from 'react';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { ChartTooltipWithLine } from '../components/chartTooltipWithLine';
import { ChartGrid } from '../components/chartGrid';
import { useChartParameters } from '../hooks/useChartParameters';
import { useCashflowData } from '../hooks/useCashFlowData';
import { useTooltipYPosition } from '../hooks/useTooltipYPosition';
import { CashflowDataPoint } from 'src/services/dashboard';
import { LineWithAreaToZero } from '../components/lineWithAreaToZero';

export type ChartProps = {
  data: CashflowDataPoint[];
  parentWidth?: number;
  parentHeight?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  initialWidth?: number;
  initialHeight?: number;
};

const Chart = ({
  data,
  parentWidth,
  parentHeight,
  margin = {
    left: 10,
    bottom: 30,
    top: 10,
    right: 25,
  },
}: ChartProps) => {
  const xNumTicks = parentWidth > 520 ? 6 : 5;
  const yNumTicks = 5;

  const currentDate = useMemo(() => new Date(new Date().toDateString()), []);

  const chartData = useCashflowData({
    data,
    xThreshold: currentDate,
  });

  const chartParameters = useChartParameters({
    containerWidth: parentWidth,
    containerHeight: parentHeight,
    margin,
    data: chartData,
    xAccessor: d => d.date,
    yAccessor: d => d.value,
  });

  const tooltipX = chartParameters.xScale(currentDate) ?? 0;
  const tooltipY = useTooltipYPosition({
    data: chartData,
    getX: d => chartParameters.xScale(d.date),
    getY: d => chartParameters.yScale(d.value),
    chartHeigth: chartParameters.chartAreaHeight,
    tooltipXPosition: tooltipX,
  });

  return (
    <svg width={parentWidth} height={parentHeight} ref={chartParameters.containerRef}>
      <Group left={chartParameters.chartLeftMargin} top={margin.top}>
        <ChartGrid
          chartWidth={chartParameters.chartAreaWidth}
          chartHeight={chartParameters.chartAreaHeight}
          xNumTicks={xNumTicks}
          yNumTicks={yNumTicks}
          xScale={chartParameters.xScale}
          yScale={chartParameters.yScale}
          labelOffset={chartParameters.labelOffset}
        />
        <LineWithAreaToZero
          data={chartData}
          getX={d => chartParameters.xScale(d.date) ?? 0}
          getY={d => chartParameters.yScale(d.value) ?? 0}
          yScale={chartParameters.yScale}
          defined={d => d.date <= currentDate}
          chartHeight={chartParameters.chartAreaHeight}
          line={{
            stroke: '#939AA4',
            strokeWidth: 2,
          }}
          gradientBelowZero={{
            from: 'white',
            to: '#939AA4',
            fromOpacity: 0.1,
            toOpacity: 0.3,
          }}
          gradientAboveZero={{
            from: '#939AA4',
            to: 'white',
            fromOpacity: 0.3,
            toOpacity: 0.1,
          }}
        />
        <LineWithAreaToZero
          data={chartData}
          getX={d => chartParameters.xScale(d.date) ?? 0}
          getY={d => chartParameters.yScale(d.value) ?? 0}
          yScale={chartParameters.yScale}
          defined={d => d.date >= currentDate}
          chartHeight={chartParameters.chartAreaHeight}
          line={{
            stroke: '#2540D9',
            strokeWidth: 2,
          }}
          gradientBelowZero={{
            from: 'white',
            to: '#F04438',
            fromOpacity: 0.1,
            toOpacity: 0.3,
          }}
          gradientAboveZero={{
            from: '#2540D9',
            to: 'white',
            fromOpacity: 0.3,
            toOpacity: 0.1,
          }}
        />
        {chartData.length !== 0 && <ChartTooltipWithLine x={tooltipX} y={tooltipY} chartHeight={chartParameters.chartAreaHeight} label="Dzisiaj" />}
      </Group>
    </svg>
  );
};

export const CashflowChart = (props: ChartProps) => {
  return (
    <ParentSize
      parentSizeStyles={{
        height: props.initialHeight,
      }}
    >
      {({ width, height }) => <Chart {...props} parentWidth={width} parentHeight={height} />}
    </ParentSize>
  );
};
