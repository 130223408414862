import React, { useId } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { LinearGradient } from '@visx/gradient';
import { LinePath } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import * as c from '@visx/curve';
import { Threshold } from '@visx/threshold';
import { Group } from '@visx/group';
import { clamp } from 'lodash';

type GradientProps = {
  from: string;
  fromOffset?: string | number;
  fromOpacity: number;
  to: string;
  toOffset?: string | number;
  toOpacity: number;
};

type Props<TData> = {
  data: TData[];
  getX: (d: TData) => number;
  getY: (d: TData) => number;
  yScale: ReturnType<typeof scaleLinear<number>>;
  defined?: (d: TData, index: number, data: TData[]) => boolean;
  line: {
    stroke: string;
    strokeWidth: number;
  };
  gradientAboveZero: GradientProps;
  gradientBelowZero: GradientProps;
  chartHeight: number;
};

export function LineWithAreaToZero<TData>({ data, getX, getY, yScale, defined, line, gradientAboveZero, gradientBelowZero, chartHeight }: Props<TData>) {
  const gradientBelowId = useId();
  const gradientAboveId = useId();

  const thresholdId = useId();

  const [style] = useSpring(
    () => ({
      from: { opacity: 0, transform: 'translateY(400px) scaleY(0)' },
      to: { opacity: 1, transform: 'translateY(0) scaleY(1)' },
      config: {
        duration: 300,
      },
      reset: true,
    }),
    [data]
  );

  const AnimatedGroup = animated(Group);

  return (
    <AnimatedGroup style={style}>
      <LinearGradient id={gradientBelowId} x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse" {...gradientBelowZero} />
      <LinearGradient id={gradientAboveId} x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse" {...gradientAboveZero} />
      <Threshold<TData>
        clipAboveTo={0}
        clipBelowTo={chartHeight}
        id={thresholdId}
        data={data}
        curve={c.curveMonotoneX}
        defined={defined}
        x={getX}
        y0={getY}
        y1={clamp(yScale(0), 1, chartHeight)}
        belowAreaProps={{
          fill: `url(#${gradientBelowId})`,
        }}
        aboveAreaProps={{
          fill: `url(#${gradientAboveId})`,
        }}
      />
      <LinePath<TData> curve={c.curveMonotoneX} data={data} x={getX} y={getY} defined={defined} {...line} />
    </AnimatedGroup>
  );
}
