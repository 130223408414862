import React from 'react';
import { SharedCompanyForm, AccountantCompanyList, AccountantCompanyDetails, OwnerContractorForm, SharedEmployeeProfile, FilePreview } from '@features';
import { Navigate } from 'react-router';
import { InDevelopmentTab } from 'src/features/shared/sharedCostList/components';
import { CompanyData, ContractorData, CostDetails, EmployeeDetails, getCompanyById, getContractorById, getCostDataById, getEmployeeDataById } from '@services';
import { TheRouteObject } from '../types';

export const ownerCompanyList: TheRouteObject[] = [
  {
    index: true,
    element: <AccountantCompanyList />,
  },
  {
    path: 'add-company',
    element: <SharedCompanyForm />,
    handle: {
      crumb: 'Dodaj firmę',
    },
  },
  {
    path: 'edit/:companyId',
    element: <SharedCompanyForm />,
    loader: async ({ params }) => {
      return await getCompanyById(params.companyId);
    },
    handle: {
      crumb: (data: CompanyData) => `Edytuj firmę "${data.name}"`,
    },
  },
  {
    path: 'details/:companyId',
    loader: async ({ params }) => {
      return await getCompanyById(params.companyId);
    },
    handle: {
      crumb: (data: CompanyData) => data.name,
    },
    children: [
      {
        index: true,
        element: <AccountantCompanyDetails />,
      },
      {
        path: 'edit-cost/:id',
        element: <InDevelopmentTab />,
        loader: async ({ params }) => {
          return await getCostDataById(+params.id);
        },
        handle: {
          crumb: (data: CostDetails) => {
            if (data.invoicingData?.number) return `Edytuj koszt ${data.invoicingData.number}`;
            return `Digitalizuj plik "${data.originalFilename}"`;
          },
        },
      },
      {
        path: 'add',
        element: <OwnerContractorForm />,
        handle: {
          crumb: 'Dodaj kontrahenta',
        },
      },
      {
        path: 'edit/:contractorId',
        element: <OwnerContractorForm />,
        loader: async ({ params }) => {
          return await getContractorById(params.contractorId);
        },
        handle: {
          crumb: (data: ContractorData) => `Edytuj ${data.friendlyName ?? data.name}`,
        },
      },
      {
        path: 'employee-preview/:employeeId',
        element: <SharedEmployeeProfile />,
        loader: async ({ params }) => {
          return await getEmployeeDataById(+params.employeeId);
        },
        handle: {
          crumb: (data: EmployeeDetails) => `${data.firstName} ${data.lastName}`,
        },
      },
      {
        path: 'file-preview/:duplicateId',
        element: <FilePreview />,
        handle: {
          crumb: `Podgląd pliku`,
        },
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/owner-company-list" />,
  },
];
