import { ButtonIconNew, ChevronIcon } from '@components';
import React from 'react';

import './loginActionCard.scoped.scss';

interface Props {
  testcy: string;
  title: string;
  subtitle: string;
  onClick: () => void;
  isRed?: boolean;
}

export const LoginActionCard = ({ title, subtitle, onClick, isRed, testcy }: Props) => {
  return (
    <div className="login-action-card" onClick={onClick}>
      <div className="title-container">
        <div className={`title ${isRed ? 'red' : ''}`}>{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
      <ButtonIconNew testcy={`${testcy}-button`} size="big" color='white'>
        <ChevronIcon direction='right' />
      </ButtonIconNew>
    </div>
  );
};
