import { useForm } from 'react-hook-form';
import { snackbarMessagesHandler, stringToNumberSecondOptions } from '@utils';
import { useApiMutation } from '@hooks';
import { invalidateApiQuery } from '@lib';

type UpdateBankAccountBalanceFormData = {
  date: Date;
  balance: string;
};

type UseUpdateBankAccountBalanceFormProps = {
  onSubmitted: () => void;
};

export const useUpdateBankAccountBalanceForm = ({ onSubmitted }: UseUpdateBankAccountBalanceFormProps) => {
  const form = useForm<UpdateBankAccountBalanceFormData>({
    defaultValues: {
      date: new Date(new Date().toDateString()),
      balance: null,
    },
  });

  const { mutate, isLoading } = useApiMutation('updateBankAccountBalance', {
    onSuccess: () => {
      invalidateApiQuery('getCashflow');
      snackbarMessagesHandler.bankAccountBalanceUpdated();
      onSubmitted();
    },
  });

  const onSubmit = form.handleSubmit(data =>
    mutate({
      date: data.date,
      balance: stringToNumberSecondOptions(data.balance),
    })
  );

  return {
    form,
    isSubmitting: isLoading,
    onSubmit,
  };
};
