import { useApiQuery } from '@hooks';

export const useInvoiceHistory = (invoiceId: string) => {
  const { data, isLoadingAndEnabled } = useApiQuery('getInvoiceHistory', invoiceId, {
    enabled: !!invoiceId,
  });

  return {
    history: data ?? [],
    isLoading: isLoadingAndEnabled,
  };
};
