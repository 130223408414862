import React, { PropsWithChildren } from 'react';
import { LoaderIcon } from '@components';

type Props = PropsWithChildren & {
  isLoading: boolean;
  maxHeight?: boolean;
  loaderIconSize?: 'tiny' | 'small' | 'medium' | 'medium2' | 'big';
};

export const PageLoaderWrapper = ({ isLoading, children, maxHeight, loaderIconSize = 'big' }: Props) => {
  return (
    <>
      {isLoading ? (
        <div className="loader-full-page-g center-g" style={{ height: maxHeight ? '100%' : null }}>
          <LoaderIcon size={loaderIconSize} />
        </div>
      ) : (
        children
      )}
    </>
  );
};
