import React from 'react';
import { OwnerDashboard } from '@features';
import { Navigate } from 'react-router';
import { TheRouteObject } from '../types';

export const dashboard: TheRouteObject[] = [
  {
    index: true,
    element: <OwnerDashboard />,
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
];
