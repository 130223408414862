import { dateFormatter } from '@lib';
import { VatExemptionInfo } from '@services';
import { gtuOptions, paymentTypeOptions, vatOptions, formatNumberToCurrency, ZwOptions } from '@utils';
import { findOptionLabel } from 'src/utils/helpers';

export const propertyTranslator = {
  Number: 'Numer dokumentu',
  IssueDate: 'Data wystawienia faktury',
  DeliveryDate: 'Data dostawy',
  PaymentMethod: 'Forma płatności',
  DueDate: 'Termin zapłaty',
  Contractor: 'Kontrahent',
  BankAccountNumber: 'Numer konta bankowego',
  AmountPaidOnIssue: 'Zapłacona kwota',
  VatExemptionInfo: 'Podstawa zwolnienia z podatku VAT',
  CorrectionReason: 'Przyczyna korekty',
};

export const dateProperties = ['IssueDate', 'DeliveryDate', 'DueDate'];

export const translateInvoiceItem = {
  description: 'nazwa',
  grossValue: 'wartość brutto',
  gtu: 'GTU',
  netValue: 'wartość netto',
  quantity: 'ilość',
  unit: 'jednostka',
  netUnitPrice: 'cena jednostkowa',
  vatValue: 'wartość vat',
  vatRate: 'stawka vat',
};

export const getItemChangesTitle = (isOldValues: boolean, isDeleted: boolean) => {
  if (isDeleted) {
    return 'Usunięto pozycję:';
  }

  if (isOldValues) {
    return 'Edytowano pozycję:';
  }

  return 'Dodano nową pozycję:';
};

export const resolvePropertyValue = (propertyName: string, rawValue: any) => {
  if (dateProperties.includes(propertyName)) {
    return dateFormatter.changeFormat(rawValue, "yyyy-MM-dd'T'HH:mm:ss", 'dd.MM.yyyy');
  }

  switch (propertyName) {
    case 'PaymentMethod':
      return findOptionLabel(paymentTypeOptions, rawValue) || rawValue;
    case 'AmountPaidOnIssue':
      return `${formatNumberToCurrency(rawValue)} zł`;
    case 'VatExemptionInfo':
      const deserialized = JSON.parse(rawValue) as VatExemptionInfo;
      if (!deserialized) {
        return 'brak';
      }
      if (deserialized.exemptionType === 'Other') {
        return deserialized.description || '';
      }
      return findOptionLabel(ZwOptions, deserialized.exemptionType) || 'brak';
    default:
      return rawValue;
  }
};

export const resolveItemPropertyValue = (propertyName: string, rawValue: any) => {
  switch (propertyName) {
    case 'vatRate':
      return `${findOptionLabel(vatOptions, rawValue) || rawValue}%`;
    case 'gtu':
      return findOptionLabel(gtuOptions, rawValue) || 'brak';
    case 'grossValue':
    case 'netValue':
    case 'vatValue':
    case 'netUnitPrice':
      return `${formatNumberToCurrency(rawValue)} zł`;
    default:
      return rawValue;
  }
};
