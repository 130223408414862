import { snackbarMessagesHandler } from '@utils';
import { ReminderFormData } from 'src/services/invoices';
import { useApiMutation } from '../useApiMutation';

interface IUseSubmitReminder {
  submitReminder: (id: string, data: ReminderFormData) => void;
  isSubmitReminderLoading: boolean;
}

export const useSubmitReminder = (onSuccess: () => void): IUseSubmitReminder => {
  const { mutate, isLoading } = useApiMutation('invoicesSendReminder');

  const submitReminder = (id: string, data: ReminderFormData) => {
    mutate({ id, data }, {
      onSuccess: () => {
        snackbarMessagesHandler.invoiceSendReminder();
        onSuccess();
      }
    });
  }

  return { submitReminder, isSubmitReminderLoading: isLoading };
};
