import { ButtonIconNew, ButtonMainNew, Copy, SpecialInput, TextInputNew, TheSelect } from '@components';
import { useContractorStore } from '@store';
import { UserRolesEnum, focusOnNextFormInput, formErrorMsg, legalPersonalityOptions, standardValidators, yesNoOptions } from '@utils';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SettingsSection, SettingsSubSection, SettingsTab } from '../../components/settingsTab';

import './companyDataSection.scoped.scss';

interface Props {
  isNipCorrect: boolean;
  isNipEnabled: boolean;
  fetchGusData: () => void;
}

export const CompanyDataSection = ({ isNipCorrect, isNipEnabled, fetchGusData }: Props) => {
  const routeParams = useParams();
  const { watch } = useFormContext();
  const { setAccountingIdCopyToClipboard } = useContractorStore();

  const onCopy = () => {
    navigator.clipboard.writeText(watch('ownAccountingId'));
    setAccountingIdCopyToClipboard(watch('ownAccountingId'));
  };

  return (
    <SettingsTab title="Dane firmy">
      <SettingsSection>
        <SettingsSubSection title="Rodzaj podmiotu">
          <TheSelect
            testcy="legal-personality"
            options={legalPersonalityOptions}
            hideEmptyOption
            name="legalPersonality"
            width="100%"
            appearance="filled"
            validation={{
              required: formErrorMsg.isRequired,
            }}
          />
        </SettingsSubSection>
      </SettingsSection>
      <SettingsSection>
        <SettingsSubSection title="Dane firmy" subtitle="Nazwa firmy, adres oraz dane kontaktowe." noBorder>
          <div className="nip-container">
            {routeParams.role === UserRolesEnum.BUSINESS ? (
              <div className="text-input-with-btn-row">
                <div className="input">
                  <SpecialInput
                    testcy="company-nip"
                    name="nip"
                    label="Numer NIP"
                    width="100%"
                    type="nip"
                    disabled={!isNipEnabled}
                    appearance="filled"
                    validation={{
                      required: formErrorMsg.isRequired,
                    }}
                  />
                </div>
                <div className="fetch-gus-data-container">
                  <ButtonMainNew
                    testcy="fetch-gus-data"
                    content="Pobierz dane z GUS"
                    sizeType="extra-big"
                    colorType="transparent"
                    width="186px"
                    isDisabled={!isNipCorrect || !isNipEnabled}
                    onClick={fetchGusData}
                  />
                </div>
              </div>
            ) : (
              <SpecialInput
                testcy="company-nip"
                name="nip"
                label="Numer NIP"
                width="100%"
                type="nip"
                appearance="filled"
                validation={{
                  required: formErrorMsg.isRequired,
                }}
                onKeyDown={event => {
                  focusOnNextFormInput(event, 2);
                }}
              />
            )}
          </div>
          <TextInputNew
            testcy="company-name-full"
            name="name"
            label="Pełna nazwa firmy"
            width="100%"
            appearance="filled"
            onKeyUp={event => {
              focusOnNextFormInput(event, 2);
            }}
            validation={standardValidators.requiredMaxNumber(255)}
          />
          <SpecialInput
            testcy="company-regon"
            type="regon"
            name="regon"
            label="Regon"
            width="100%"
            appearance="filled"
            onKeyDown={event => {
              focusOnNextFormInput(event, 2);
            }}
            validation={{
              required: formErrorMsg.isRequired,
            }}
          />
        </SettingsSubSection>
        <SettingsSubSection>
          <TextInputNew
            testcy="street"
            name="street"
            label="Ulica i numer"
            width="100%"
            appearance="filled"
            onKeyUp={event => {
              focusOnNextFormInput(event, 2);
            }}
            validation={standardValidators.requiredMaxNumber(100)}
          />
          <TextInputNew
            testcy="city"
            name="city"
            label="Miejscowość"
            width="100%"
            appearance="filled"
            onKeyUp={event => {
              focusOnNextFormInput(event, 2);
            }}
            validation={standardValidators.requiredMaxNumber(100)}
          />
          <SpecialInput
            testcy="zip-code"
            type="postalcode"
            name="zipCode"
            label="Kod pocztowy"
            width="100%"
            appearance="filled"
            onKeyDown={event => {
              focusOnNextFormInput(event, 2);
            }}
            validation={{
              required: formErrorMsg.isRequired,
            }}
          />
        </SettingsSubSection>
      </SettingsSection>
      <SettingsSection>
        <SettingsSubSection
          title="KSeF"
          subtitle={
            <span>
              Czy wyrażasz zgodę na otrzymywanie
              <br />
              faktur ustrukturyzowanych w KSeF?
            </span>
          }
        >
          <TheSelect
            testcy="allowKsef"
            options={yesNoOptions}
            hideEmptyOption
            name="allowKsef"
            width="100%"
            appearance="filled"
            validation={{
              required: formErrorMsg.isRequired,
            }}
          />
        </SettingsSubSection>
      </SettingsSection>
      {watch('ownAccountingId') && !routeParams.companyId && (
        <SettingsSection>
          <SettingsSubSection title="Identyfikator firmy" subtitle={<span>Numer, który łączy Księgową firmę z Twoją firmą.</span>}>
            <TextInputNew
              testcy="own-accounting-id"
              name="ownAccountingId"
              label="Identyfikator firmy"
              width="100%"
              appearance="filled"
              readOnly
              onKeyUp={event => {
                focusOnNextFormInput(event, 2);
              }}
              endAdornment={
                <ButtonIconNew className="settings-adornment-button" testcy="copy-own-accounting-id" size="small" color="transparent" onClick={onCopy}>
                  <Copy />
                </ButtonIconNew>
              }
            />
          </SettingsSubSection>
        </SettingsSection>
      )}
    </SettingsTab>
  );
};
