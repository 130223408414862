import React, { PropsWithChildren } from 'react';

import './dashboardCard.scoped.scss';

type Props = PropsWithChildren & {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
};

export const DashboardCardHeader = ({ icon, title, subtitle, children }: Props) => {
  return (
    <div className="card-header">
      <div className="card-header-layout">
        <div className="card-header-title">
          {icon}
          {!!title && <h2>{title}</h2>}
        </div>
        {!!subtitle && <h3 className="card-header-subtitle">{subtitle}</h3>}
      </div>
      {children}
    </div>
  );
};
