import { ButtonIconNew, DeleteIconNew, EditIcon, HeartIcon, SpecialInput } from '@components';
import { focusOnNextFormInput, formErrorMsg } from '@utils';
import React from 'react';

import './bankAccountField.scss';

interface _GeneralProps {
  index: number;
  isMain?: boolean;
  bankName?: string;
  onFavoriteClick: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}
type Props = ({ empty?: false } & _GeneralProps) | ({ empty: true } & Partial<_GeneralProps>);

export const BankAccountField = ({ index, isMain, bankName, onFavoriteClick, onEditClick, onDeleteClick, empty }: Props) => {
  if (index === -1) {
    return <div className="bank-account-field align-center">Nie oznaczono jeszcze konta głównego.</div>;
  }
  if (empty) {
    return <div className="bank-account-field align-center">Nie dodano jeszcze konta bankowego.</div>;
  }
  return (
    <div className="bank-account-field">
      <div className="favorite-button">
        <ButtonIconNew testcy={`bank-list-favorite-${index}`} size="extra-small" color="transparent" shouldResizeChildSvg={false} onClick={onFavoriteClick}>
          <HeartIcon isFilled={isMain} size="24" />
        </ButtonIconNew>
      </div>
      <SpecialInput
        testcy={`bank-list-input-${index}`}
        type="bank"
        name={`bank-number-${index}`}
        label={isMain ? 'Numer konta (konto główne)' : 'Numer konta'}
        width="100%"
        appearance="filled"
        onKeyDown={event => {
          focusOnNextFormInput(event, 4);
        }}
        validation={{
          required: formErrorMsg.isRequired,
        }}
        helperText={bankName}
        adornmentAlignment="center"
        endAdornment={
          <div className="end-adornment">
            <ButtonIconNew testcy={`bank-list-edit-${index}`} size="extra-small" color="transparent" shouldResizeChildSvg={false} onClick={onEditClick}>
              <EditIcon size={16} />
            </ButtonIconNew>
            <ButtonIconNew testcy={`bank-list-delete-${index}`} size="extra-small" color="transparent" shouldResizeChildSvg={false} onClick={onDeleteClick}>
              <DeleteIconNew size={16} />
            </ButtonIconNew>
          </div>
        }
      />
    </div>
  );
};
