import React from 'react';
import logo from '@assets/logo.svg';

const NotFound = () => {
  return (
    <div className="standard-layout-spacing-g info-page-wrapper-g">
      <div className="header">
        <img src={logo} alt="logo" className="logo" />
        <p>WebCount</p>
      </div>

      <div className="content">Błędny adres URL</div>
    </div>
  );
};

export default NotFound;
