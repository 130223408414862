import { useApiMutation } from '@hooks';
import { CustomObject } from '@interfaces';
import { CostsBulkOperationsParams, GetCostDocumentsListParams } from '@services';
import { AccountantStatusType, BulkOperationsEnum, snackbarMessagesHandler } from '@utils';

interface useMarkAsPaidCostInterface {
  markCostAsPaid: (
    dataId: CustomObject,
    isBulkOperation: boolean,
    filter?: GetCostDocumentsListParams['filter'],
    isSelectedAll?: boolean,
    checkboxSelected?: { id: number; name: string; net: number; gross: number }[],
    numberOfInvoicesChecked?: number
  ) => void;
}

export const useMarkAsPaidCost = (costId: string, refreshParent?, dialogConfirmBulkOperations?): useMarkAsPaidCostInterface => {
  const markCostAsPaid = (
    dataId: CustomObject,
    isBulkOperation: boolean,
    filter = {},
    isSelectedAll: boolean,
    checkboxesSelected: { id: number; name: string; net: number; gross: number }[],
    numberOfInvoicesChecked: number
  ) => {
    const params: CostsBulkOperationsParams = {
      filter: filter,
      ids: [],
      selectAll: isSelectedAll,
      isBulkOperation,
      numberOfInvoicesChecked,
    };
    if (dataId?.id) {
      params.ids = [Number(dataId.id)];
      params.selectAll = false;
    } else {
      params.ids = checkboxesSelected.map(item => item.id);
    }
    mutate(params);
  };

  const { mutate } = useApiMutation('costMarkAsPaid', {
    onSuccess: (data, variables) => {
      if (!variables?.isBulkOperation && !data.length) {
        snackbarMessagesHandler.costsMarkAsPaid();
      } else {
        dialogConfirmBulkOperations?.(variables?.numberOfInvoicesChecked, data, 'Oznaczono jako opłacone', BulkOperationsEnum.MARK_AS_PAID);
      }
      refreshParent?.();
    },
  });

  return {
    markCostAsPaid: (dataId, isBulkOperation, filter, isSelectedAll, checkboxesSelected, numberOfInvoicesChecked) =>
      markCostAsPaid(dataId, isBulkOperation, filter, isSelectedAll, checkboxesSelected, numberOfInvoicesChecked),
  };
};
