import React from 'react';
import { Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ButtonMain } from '../buttonMain/buttonMain';
import './theDialog.scoped.scss';
import { CloseIcon, PageLoaderWrapper } from '@components';

interface Props {
  open: boolean;
  testcy: string;
  onClose: (agree: boolean) => void;
  onClickAdditionalButton?: () => void;
  children: JSX.Element;
  title?: string;
  rejectButtonText?: string;
  approveButtonText?: string;
  additionalButtonText?: string;
  canApprove?: boolean;
  maxWidth?: Breakpoint;
  className?: string;
  isActionsPending?: boolean;
  removeCloseButton?: boolean;
  tryAgainButtonText?: string;
  rejectButtonWidth?: string;
  rejectButtonColorType?: 'secondary' | 'primary' | 'text' | 'blackOutlined' | 'third' | 'four' | 'business' | 'business400';
  tryAgainButtonWidth?: string;
  approveButtonWidth?: string;
  additionalButtonWidth?: string;
  additionalButtonColorType?: 'secondary' | 'primary' | 'blackOutlined' | 'third' | 'four' | 'business' | 'business400';
  approveButtonColorType?: 'primary' | 'red';
  formDialog?: boolean;
  fullWidth?: boolean;
  fullScreen?: boolean;
}

export const TheDialog = ({
  open,
  testcy,
  onClose,
  onClickAdditionalButton,
  children,
  title,
  rejectButtonText,
  additionalButtonText = '',
  approveButtonText,
  canApprove = true,
  maxWidth = 'md',
  className,
  isActionsPending,
  removeCloseButton,
  tryAgainButtonText,
  rejectButtonColorType = 'secondary',
  rejectButtonWidth = '160px',
  tryAgainButtonWidth= '160px',
  approveButtonWidth = '160px',
  additionalButtonWidth= '160px',
  additionalButtonColorType = 'secondary',
  approveButtonColorType = 'primary',
  formDialog,
  fullWidth,
  fullScreen,
}: Props) => {
  return (
    <div className="dialog-container">
      <Dialog
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        open={open}
        className={'dialog-wrapper-new'}
        maxWidth={maxWidth}
        PaperProps={{
          sx: {
            borderRadius: '24px',
            overflow: 'hidden',
            '& .MuiList-root': {
              pt: '0',
            },
            '& .MuiMenu-root': {},
            '& .MuiDialogTitle-root': {
              padding: '0px',
            },
          },
        }}
      >
        {!removeCloseButton && (
          <button type="button" className="close-icon" onClick={() => onClose(false)} data-testcy={`dialog-${testcy}-close`}>
            <CloseIcon colorType="grey500" size={24} />
          </button>
        )}
        {title && <DialogTitle className="title">{title}</DialogTitle>}

        <DialogContent className={`content ${formDialog && 'form-dialog-content'}`}>{children}</DialogContent>
        {(additionalButtonText || rejectButtonText || approveButtonText) && (
          <DialogActions className={`${!additionalButtonText ? 'footer-1' : 'footer'}`}>
            {additionalButtonText && (
              <ButtonMain
                sizeType="small"
                onClick={onClickAdditionalButton}
                content={additionalButtonText}
                width={additionalButtonWidth}
                colorType={additionalButtonColorType}
                testcy={`dialog-${testcy}-additional`}
              />
            )}
            <div className={'buttons-gap'}>
              <PageLoaderWrapper isLoading={isActionsPending} loaderIconSize="medium2">
                <>
                  {rejectButtonText && (
                    <ButtonMain
                      width={rejectButtonWidth}
                      sizeType="small"
                      onClick={() => onClose(false)}
                      content={rejectButtonText}
                      colorType={rejectButtonColorType}
                      testcy={`dialog-${testcy}-reject`}
                    />
                  )}
                  {approveButtonText && (
                    <ButtonMain
                      width={approveButtonWidth}
                      sizeType="small"
                      onClick={() => canApprove && onClose(true)}
                      content={approveButtonText}
                      isDisabled={!canApprove}
                      colorType={approveButtonColorType}
                      testcy={`dialog-${testcy}-approve`}
                    />
                  )}
                </>
              </PageLoaderWrapper>
            </div>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
