import React from 'react';

type Props = {
  color?: 'white' | 'primary' | 'disabled' | '';
  size?: number;
};

export const CloseIconNew = ({ color = '', size = 24}: Props) => {
  return (
    <svg className={`icon-color-${color}-stroke`} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path d="M6 6L18 18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18 6L6 18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
