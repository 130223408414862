import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import './radioButton.scss';

interface Props {
  value: string;
  testcy: string;
  label?: string;
  disabled?: boolean;
}

export const RadioButton = ({ value, testcy, label = '', disabled }: Props) => {
  return (
    <FormControlLabel
      className="radio-button-component"
      data-testcy={testcy}
      disabled={disabled}
      value={value}
      control={<Radio size="small" />}
      label={label}
    />
  );
};
