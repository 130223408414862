import { useEffect, useState } from 'react';
import { UserRepository } from '@amityco/ts-sdk';
import { RelatedChatUserDetails } from 'src/services/chat';
import { createPrivateChannel } from '../services/channels';
import { useApiQuery } from 'src/hooks/useApiQuery';
import { useAmitySessionState } from './useAmitySessionState';

export type Channel = Amity.Channel & {
  discussedCompanyId?: number;
};

export const useChannelList = () => {
  const { isConnected } = useAmitySessionState();
  const [channels, setChannels] = useState<Channel[]>([]);

  const { data: relatedUsers } = useApiQuery('getRelatedChatUsers', undefined, {
    placeholderData: [],
    enabled: isConnected,
  });

  useEffect(() => {
    if (!relatedUsers || relatedUsers.length === 0) {
      return;
    }

    const fetchChannels = async () => {
      const ids = relatedUsers.map((relatedUser: RelatedChatUserDetails) => relatedUser.chatUserId);

      const users = await UserRepository.getUserByIds(ids);
      const newChannels = await Promise.all(
        users.data.map(async user => {
          const channel = await createPrivateChannel(user.userId);

          const companyId = relatedUsers.find(relatedUser => relatedUser.chatUserId === user.userId)?.discussedCompanyId;
          return {
            ...channel.data,
            displayName: user.displayName,
            discussedCompanyId: companyId,
          };
        })
      );

      setChannels(newChannels);
    };

    fetchChannels();
  }, [relatedUsers]);

  return channels;
};
