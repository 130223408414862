import React from 'react';
import { Control } from 'react-hook-form';
import { TextInputNew } from '@components';
import { MenuItem } from '@mui/material';

interface Props {
  testcy: string;
  control: Control;
  onFilterChanged: (value: string) => void;
  parentName: string;
}

const FilterOptions = ({ testcy, control, onFilterChanged, parentName }: Props) => {
  return (
    <MenuItem onKeyDown={e => e.stopPropagation()}>
      <TextInputNew
        testcy={testcy}
        control={control}
        name={`filter-select-${parentName}`}
        onChange={onFilterChanged}
        placeholder="Wyszukaj"
        width="100%"
      />
    </MenuItem>
  );
};

export const MemoizedFilterOptions = React.memo(FilterOptions);
