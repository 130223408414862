import { useEffect, useState } from 'react';
import { VatTemplate, getVatTemplates } from '@services';
import { handleApiError } from '@utils';
import { RequestErrorMessage, Option } from '@interfaces';
import { useAccountantStore } from '@store';

interface UseVatTemplateInterface {
  isPendingVatTemplateData: boolean;
  vatTemplateDataOptions: Option[];
}

export const useVatTemplate = (): UseVatTemplateInterface => {
  const [isPendingVatTemplateData, setIsPendingVatTemplateData] = useState(false);
  const [vatTemplateDataOptions, setVatTemplateDataOptions] = useState([]);
  const { setVatTemplateStoreOptions } = useAccountantStore();

  useEffect(() => {
    fetchVatTemplates();
  }, []);

  const transformDataToSelectOptions = (templates: VatTemplate[]) => {
    const selectOptions = templates.map(template => {
      const label = `${template.code} ${template.description}`;
      const value = `${template.id}`;
      const options = { value, label, key: value };
      return options;
    });
    setVatTemplateDataOptions(selectOptions);
  };

  const fetchVatTemplates = () => {
    setIsPendingVatTemplateData(true);
    getVatTemplates()
      .then(data => {
        setVatTemplateStoreOptions(data);
        if (data.length) {
          transformDataToSelectOptions(data);
        } else {
          transformDataToSelectOptions([]);
        }
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        setIsPendingVatTemplateData(false);
      });
  };

  return {
    isPendingVatTemplateData,
    vatTemplateDataOptions,
  };
};
