import { httpClient } from '@lib';
import { AxiosResponse } from 'axios';
import {
  InvoiceStatusType,
  AccountantStatusType,
  DeductVATEnum,
  DeductionInCostsEnum,
  DeductionLimitEnum,
  GtuOptionsType,
  PaymentType,
  SortDirection,
  VatOptionsType,
  VatTemplateTypeEnum,
  VehicleOperationEnum,
  ZwOptionsType,
  PaymentStatusType,
} from '@utils';
import {
  ContractorInvoicingData,
  ContractorInvoicingDataBase,
  FileHashesRequest,
  InvoiceStatus,
  InvoicingItemValues,
  InvoicingItemValuesBase,
  PagedList,
} from '@interfaces';
import { DuplicatedFile } from '@components';

//! overview
export interface CostOverviewNew {
  id: number;
  issueDate: string;
  status: InvoiceStatusType;
  documentNumber: string;
  dueDate: string;
  daysOverdue: number;
  contractor: string;
  netValue: number;
  grossValue: number;
  vatValue: number;
  description: string;
  amountPaid: number;
  paymentMethod: string;
  accountingStatus: AccountantStatusType;
  paymentStatus: PaymentStatusType;
  createdDate: string;
  originalFilename: string;
}

export interface CostFile {
  id: number;
  createdDate: string;
  originalFilename: string;
  stashedData: string;
}

export interface CostDocumentsList extends PagedList<CostOverviewNew> {}
export interface CostFilesList extends PagedList<CostFile> {}

export type CostDocumentsOrderOptions = 'issueDate' | 'originalFilename';

export interface GetCostDocumentsListParams {
  filter?: {
    companyId?: number;
    textFilter?: string;
    paymentStatusFilter?: InvoiceStatus[];
    accountingStatusFilter?: InvoiceStatus[];
    dateFrom?: string;
    dateTo?: string;
  };
  paging?: { pageNumber?: number; pageSize?: number };
  sorting?: {
    direction?: SortDirection;
    orderBy?: CostDocumentsOrderOptions;
  };
}

export interface GetCostFilesParams {
  companyId?: number;
  pageNumber?: number;
}

//! details
export interface CostDetails {
  id: number;
  createdDate: string;
  originalFilename: string;
  description?: string | null;
  companyId: number;
  invoicingData?: CostInvoicingDetails | null;
  accountingStatus: AccountantStatusType;
}

interface CostInvoicingDetails {
  number: string;
  digitalizedDate: string;
  dueDate?: string | null;
  daysOverdue?: number | null;
  contractor: string;
  netValue: number;
  vatValue: number;
  grossValue: number;
  vatExemptionInfo?: VatExemptionInfo | null;
  amountPaid: number;
  totalInstalments: number;
  paymentMethod: PaymentType | null;
  paymentStatus?: PaymentStatusType | null;
  deductionMonth: string;
  bankAccountNumber?: string | null;
  vatRateRecords: VatSaveItem[];
  contractorId: string;
  issueDate: string;
  deliveryDate: string;
  accountingTemplate: AccountingTemplate | null;
  vatTemplate: VatTemplate;
  vehicleDeductionInfo: VehicleDeductionInfo;
  contractorInvoicingData: ContractorInvoicingData;

}

interface CostDataBase {
  number: string;
  bankAccountNumber?: string;
  issueDate: string;
  deliveryDate: string;
  deductionMonth: string;
  dueDate?: string;
  contractorId: string;
  paymentMethod: PaymentType;
  amountPaid: number;
  totalInstalments?: number;
  companyId?: number;
  netValue?: number;
  grossValue?: number;
  vatValue?: number;
  digitalizedDate?: string;
  paymentStatus?: string;
}

export interface CostData extends CostDataBase {
  contractorInvoicingData: ContractorInvoicingData;
  vatExemptionInfo?: VatExemptionInfo;
  vatRateRecords: VatSaveItem[];
  vatTemplate: VatTemplate;
  accountingTemplate: VatTemplate;
  vehicleDeductionInfo: VehicleDeductionInfo;
}

export interface VehicleDeductionInfo {
  type: VehicleOperationEnum;
  part: DeductVATEnum;
  percent: DeductionInCostsEnum;
  leasingDeductionInfo?: LeasingDeductionInfo;
}

export interface LeasingDeductionInfo {
  isElectricVehicle: boolean;
  deductibleLimit: DeductionLimitEnum | number;
  vehicleAcquisitionValue: number;
  proportionLimit: number;
}

export interface VatExemptionInfo {
  exemptionType?: ZwOptionsType;
  description?: string;
}

export interface UpdateCostDataRequest extends CostDataBase {
  contractorInvoicingData?: ContractorInvoicingDataBase;
  vatRateRecords: VatSaveItem[];
  vatExemptionInfo?: VatExemptionInfo;
  vatTemplateId: number;
  accountingTemplateId?: number;
  vehicleDeductionInfo: VehicleDeductionInfo;
}

export interface VatSaveItem {
  vatRate: VatOptionsType;
  netValue: number;
  vatValue: number;
  grossValue: number;
  hasCustomAmounts: boolean;
}

export interface VatTemplate {
  id: number;
  description: string;
  code: string;
}

export interface CostSaveItem extends InvoicingItemValuesBase {
  gtu?: GtuOptionsType;
  description: string;
  unit: string;
}

export interface CostItem extends InvoicingItemValues {
  gtu?: GtuOptionsType;
  description: string;
  unit: string;
}

export interface CostCreationResultItem {
  id?: number;
  originalFilename: string;
  message?: string;
  isSuccessful: boolean;
}

export interface AccountingTemplateScheme {
  templates: AccountingTemplate[];
  isConfigured: boolean;
}

export interface AccountingTemplate {
  id: number;
  code: string;
  description: string;
}
export interface VatTemplateOptions {
  label: string;
  value: VatTemplateTypeEnum;
}

export interface CostsBulkOperationsParams {
  filter?: {
    companyId?: number;
    textFilter?: string;
    dateFrom?: string;
    dateTo?: string;
  };
  ids: number[];
  selectAll: boolean;
  isBulkOperation?: boolean;
  numberOfInvoicesChecked?: number;
}

export type CostAccountingSettings = {
  accountingTemplate?: VatTemplate;
  vatTemplate?: VatTemplate;
};

export type RecordOutgoingPaymentRequest = {
  amountPaid: number;
  date: string;
  notes?: string;
};

export type RecordOutgoingPaymentParams = {
  id: number;
  request: RecordOutgoingPaymentRequest;
};

//! NON-DIGITALIZED COSTS
export const getCostFiles = async (params: GetCostFilesParams = { pageNumber: 0 }) => {
  try {
    const response: AxiosResponse<CostFilesList> = await httpClient.get('/Costs/files', { params });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const createNewCostDocuments = async (files: FormData) => {
  try {
    const response = await httpClient.post<CostCreationResultItem[]>('/Costs/files', files);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getCostDownloadImgById = async (id: string) => {
  try {
    const response: AxiosResponse<Blob> = await httpClient.get(`/Costs/${id}/download`, { responseType: 'blob' });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

//! DIGITALIZED COSTS
export const getDigitalizedCosts = async (params: GetCostDocumentsListParams) => {
  try {
    const response: AxiosResponse<CostDocumentsList> = await httpClient.post('/Costs/search', params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const editCostData = async (costData: UpdateCostDataRequest, id: string) => {
  try {
    const response: AxiosResponse = await httpClient.put(`/Costs/${id}`, costData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getCostDataById = async (id: number) => {
  try {
    const response = await httpClient.get<CostDetails>(`/Costs/${id}`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const sendCostToInsert = async (id: number) => {
  try {
    const response = await httpClient.post(`/Costs/SendToAccounting`, [id]);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

//! shared
export const deleteCostDocument = async (id: number) => {
  try {
    const response = await httpClient.delete(`/Costs/${id}`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getAccountingTemplates = async (companyId: number | null) => {
  try {
    const response = await httpClient.get<AccountingTemplateScheme>(`/Costs/AccountingTemplates`, { params: { companyId } });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getVatTemplates = async () => {
  try {
    const response = await httpClient.get<VatTemplate[]>(`/Costs/VatTemplates`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const costTransferCSV = async (params: CostsBulkOperationsParams) => {
  try {
    const response = await httpClient.post(`/Costs/transfers`, params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const confirmCostDocument = async (id: number) => {
  try {
    const response = await httpClient.put(`/Costs/${id}/confirm`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const compareCostHashes = async (body: FileHashesRequest) => {
  try {
    const response: AxiosResponse<DuplicatedFile[]> = await httpClient.post(`/Costs/files/hashes`, body);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const costMarkAsPaid = async (params: CostsBulkOperationsParams) => {
  try {
    const response = await httpClient.post(`/Costs/MarkAsPaid`, params);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const costRecordOutgoingPayment = async (params: RecordOutgoingPaymentParams) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Costs/${params.id}/RecordPayment`, params.request);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
