import { ButtonMainNew, PlusIcon, SearchBarValues, TableRowData, TheTableNew } from '@components';
import { useApiQuery, useDebounce, usePagination } from '@hooks';
import { GetEmployeesParams } from '@services';
import { useListParamsStore } from '@store';
import { EmployeesOrderOptionsEnum, SortDirection } from '@utils';
import { addHotjarEvent } from '@lib';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ListSearchBar } from 'src/components/combined';
import { ModalAndDialogs } from './components';
import { useUnregisterEmployee } from './hooks';
import { EmployeeAction, employeeDataToTableRow, tableHeader, transformMenuItemsEmployeeList } from './ownerEmployeesList.utils';

import './ownerEmployeesList.scoped.scss';
import './ownerEmployeesList.scss';

export const EmployeesViewList = () => {
  const { page, setPage, rowsPerPage, setRowsPerPage } = usePagination();

  const [searchValue, setSearchValue] = useState('');
  const [orderBy, setOrderBy] = useState<EmployeesOrderOptionsEnum>(EmployeesOrderOptionsEnum.FULL_NAME);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASCENDING);
  const [companyId, setCompanyId] = useState('');
  const [isCheckedStore, setIsCheckedStore] = useState(false);
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false);
  const [listParams, setListParams] = useState<GetEmployeesParams>();
  const [searchBarDefaultValues, setSearchBarDefaultValues] = useState<SearchBarValues>(null);
  const listSearchParams = useDebounce(listParams, 200);
  const { lastParamsEmployeesList, setLastParamsEmployeesList } = useListParamsStore();

  const { unregisterEmployee } = useUnregisterEmployee();

  const navigate = useNavigate();
  const routeParams = useParams();

  useEffect(() => {
    if (lastParamsEmployeesList) {
      setPage(lastParamsEmployeesList.page);
      setSortDirection(lastParamsEmployeesList.sortDirection);
      setOrderBy(lastParamsEmployeesList.orderBy);
      if (lastParamsEmployeesList.searchBarValues) setSearchBarDefaultValues(lastParamsEmployeesList.searchBarValues);
      setLastParamsEmployeesList(null);
    }
    setIsCheckedStore(true);
  }, []);

  useEffect(() => {
    if (!isCheckedStore) return;
    setCompanyId(routeParams.companyId);
    if (companyId || !routeParams.companyId) modifyParams();
  }, [isCheckedStore, companyId, searchValue, page, orderBy, sortDirection, rowsPerPage]);

  const modifyParams = () => {
    const params: GetEmployeesParams = {
      companyId: +routeParams.companyId || null,
      direction: sortDirection,
      pageNumber: page,
      pageSize: rowsPerPage,
    };

    if (orderBy) params.orderBy = orderBy;
    if (searchValue) params.textFilter = searchValue;

    setListParams(params);
  };

  const onClickAddEmployee = () => {
    saveToStateParams();
    addHotjarEvent('add employee');
    setOpenEmployeeModal(true);
  };

  const onChangePagination = (data: { page: number; rowsPerPage: number }) => {
    setPage(data.page);
    setRowsPerPage(data.rowsPerPage);
  };

  const onFilterChange = (data: SearchBarValues) => {
    setSearchValue(data.textFilter);
    setPage(1);
  };

  const { data, isFetching } = useApiQuery('getEmployees', listSearchParams, {
    enabled: !!listSearchParams,
    keepPreviousData: true,
    placeholderData: {
      data: [],
      totalCount: 0,
      pageNumber: 0,
      pageSize: 0,
      totalPageCount: 0,
    },
  });

  const totalCount = data.totalCount;
  const rows = data.data.map(employee => employeeDataToTableRow(employee));
  const actionMenuItems = transformMenuItemsEmployeeList(data.data);

  const saveToStateParams = () => {
    setLastParamsEmployeesList({
      sortDirection,
      page,
      orderBy,
      searchBarValues: {
        textFilter: searchValue,
      },
      itemsPerPage: rowsPerPage,
    });
  };

  const onChangeSort = (sortType: SortDirection, propertyName: string) => {
    setSortDirection(sortType);
    setOrderBy(propertyName as EmployeesOrderOptionsEnum);
  };

  const onClickEmployeePreview = (row: TableRowData) => {
    companyId ? navigate(`/owner-company-list/details/${companyId}/employee-preview/${row.id}`) : navigate(`/employees-list/employee-preview/${row.id}`);
  };

  const onClickEmployeeUnregister = (row: TableRowData) => {
    unregisterEmployee(Number(row.id));
  };

  const onClickMenuItem = (action: string | null, row: TableRowData) => {
    if (!action) return;

    switch (action) {
      case EmployeeAction.Preview:
        onClickEmployeePreview(row);
        break;
      case EmployeeAction.Unregister:
        onClickEmployeeUnregister(row);
        break;

      default:
        throw new Error(`Cannot find employee action: ${action}`);
    }
  };

  return (
    <div className={`employees-list-wrapper ${companyId ? '' : 'standard-layout-spacing-g'} `}>
      <div className="title-container">
        {!routeParams.companyId && (
          <>
            <h1 className="title-g">Pracownicy</h1>
            <ButtonMainNew
              testcy="add-employee"
              content={'Dodaj pracownika'}
              colorType="blue"
              sizeType="extra-big"
              startIcon={<PlusIcon size={24} />}
              onClick={onClickAddEmployee}
            />
          </>
        )}
      </div>
      <ModalAndDialogs openEmployeeModal={openEmployeeModal} setOpenEmployeeModal={setOpenEmployeeModal} />
      <div className="employees-list-header">
        <ListSearchBar onChange={onFilterChange} isPending={isFetching} setPage={setPage} showSearchBar defaultValues={searchBarDefaultValues} />
      </div>
      <div className="table-wrapper-g employees-table">
        <TheTableNew
          testcy="employee-table"
          rows={rows}
          header={tableHeader}
          lastColumnWithoutBorder={companyId ? true : false}
          defaultSortProp={orderBy}
          defaultSortType={sortDirection}
          onChangeSort={onChangeSort}
          actionMenuItems={actionMenuItems}
          onClickMenuItem={onClickMenuItem}
          //pagination
          page={page}
          totalRows={totalCount}
          rowsPerPage={rowsPerPage}
          onChangePagination={onChangePagination}
        />
      </div>
    </div>
  );
};
