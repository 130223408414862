import { formatNumberToCurrency } from '@utils';

export interface ReminderFormData {
  from: string;
  emailTo: string[];
  subject: string;
  emailMessage: string;
  sendCopy: boolean;
  invoiceAttachment: boolean;
}

export const EmailMessage = (invoiceNumber: string, amountToPay: number) => {
  const massageFields = {
    subject: `Prosimy o płatność za: Faktura numer ${invoiceNumber}`,
    body: `Dzień dobry,\nprosimy o opłacenie Faktura ${invoiceNumber} na kwotę ${formatNumberToCurrency(amountToPay)} PLN brutto.\nTermin płatności już minął.`,
  };
  return massageFields;
};
