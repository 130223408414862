import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { standardValidators } from '@utils';
import { ButtonMain, LoaderIcon, TagInput, TextInputNew, TheCheckbox, Toggle } from '@components';
import { useContractorStore } from '@store';
import { EmailFormData, EmailMessage } from './sharedEmailForm.utils';
import './sharedEmailForm.scoped.scss';

interface Props {
  invoiceNumber: string;
  dueDate?: string;
  amountToPay?: number;
  isEmailPending: boolean;
  onSubmit: (data: EmailFormData) => void;
}

export const SharedEmailForm = ({ invoiceNumber, dueDate, amountToPay, onSubmit, isEmailPending }: Props) => {
  const [tags, setTags] = useState([]);
  const { handleSubmit, control, setValue, clearErrors, trigger } = useForm<FieldValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const { ownerMyCompanyData, selectedContractor } = useContractorStore();

  return (
    <form
      onSubmit={handleSubmit((data: FieldValues) => {
        data['emailTo'] = tags;
        onSubmit(data as any);
      })}
    >
      <div className="form-wrapper">
        <div className="form-column">
          <TextInputNew
            testcy="email-form-from"
            control={control}
            name="emailFrom"
            label={'Nadawca'}
            width="100%"
            appearance="light"
            validation={standardValidators.requiredMaxNumber(255)}
            readOnly
            defaultValue={ownerMyCompanyData.name}
          />
          <TagInput
            testcy="email-form-to"
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            trigger={trigger}
            setTagsData={setTags}
            name="emailTo"
            label={'Odbiorca'}
            width="100%"
            type="email"
            placeholder="Adres mailowy odbiorcy"
            appearance="light"
            defaultValue={selectedContractor?.emails}
            validation={standardValidators.requiredEmailList(tags)}
          />
          <TextInputNew
            testcy="email-form-subject"
            control={control}
            name="subject"
            label={'Temat'}
            width="100%"
            appearance="light"
            validation={standardValidators.requiredMaxNumber(254)}
            defaultValue={EmailMessage(ownerMyCompanyData.name, invoiceNumber, amountToPay, dueDate).subject}
          />
          <TextInputNew
            testcy="email-form-message"
            control={control}
            name="emailMessage"
            label={'Treść wiadomości'}
            width="100%"
            multiline
            rows={'5'}
            appearance="light-rows"
            defaultValue={EmailMessage(ownerMyCompanyData.name, invoiceNumber, amountToPay, dueDate).body}
            validation={standardValidators.requiredMaxNumber(1000)}
          />
          <div className="toggle-btn-wrapper">
            <div>
              <Toggle control={control} name="sendCopy" testcy="email-form-send-copy" />
            </div>
            <div>{`Wyślij kopię do mnie na ${ownerMyCompanyData?.email}`}</div>
          </div>
        </div>
      </div>
      <div className="form-footer">
        {isEmailPending && (
          <div className="loader-wrapper">
            <LoaderIcon size="small" />
          </div>
        )}
        <div className={`${isEmailPending && 'button-wrapper'}`}>
          <ButtonMain testcy="email-form-send" type="submit" content={' Wyślij'} width="220px" isDisabled={isEmailPending} />
        </div>
      </div>
    </form>
  );
};
