import { RecordAmountData } from 'src/features/shared/sharedInvoicesList/components/incomesTab/incomesTab.utils';
import { useApiMutation } from '../useApiMutation';
import { snackbarMessagesHandler } from '@utils';

interface IUseSubmitInvoicePayment {
  submitInvoicePayment: (id: string, data: RecordAmountData) => void;
  isSubmitInvoicePaymentLoading: boolean;
}

export const useSubmitInvoicePayment = (onSuccess: () => void): IUseSubmitInvoicePayment => {
  const { mutate, isLoading } = useApiMutation('invoiceRecordPayment');

  const submitInvoicePayment = (id: string, data: RecordAmountData) => {
    mutate({ id, data }, {
      onSuccess: () => {
        snackbarMessagesHandler.invoiceRecordAmountPaid();
        onSuccess();
      }
    });
  }

  return { submitInvoicePayment, isSubmitInvoicePaymentLoading: isLoading };
};
