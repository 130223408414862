import React from 'react';

import './settings.scss';

interface Props {
  title?: string;
  titleIcon?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
}

export const SettingsSection = ({ title, titleIcon, children }: Props) => {
  return (
    <div className="settings-section">
      {title && (
        <div className="section-title-container">
          <h3>
            {titleIcon && <div className="title-icon">{titleIcon}</div>}
            <div>{title}</div>
          </h3>
        </div>
      )}
      {children && <div className="section-content">{children}</div>}
    </div>
  );
};
