import React, { MouseEvent } from 'react';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import { ButtonType } from '@utils';

import './buttonMainNew.scoped.scss';
import './buttonMainNew.scss';

export const ButtonMainColorType = {
  Primary: 'primary',
  PrimaryLight: 'primaryLight',
  Secondary: 'secondary',
  BlackOutlined: 'blackOutlined',
  Third: 'third',
  ThirdLight: 'thirdLight',
  WhiteRound: 'white',
  Red: 'red',
  Transparent: 'transparent',
  Blue: 'blue',
  Business: 'business',
  BusinessDarker: 'businessDarker',
} as const;
export type ButtonMainColorType = typeof ButtonMainColorType[keyof typeof ButtonMainColorType];

export const ButtonMainSizeType = {
  ExtraSmall: 'extra-small',
  Small: 'small',
  Medium: 'medium',
  Big: 'big',
  Bigger: 'bigger',
  ExtraBig: 'extra-big',
} as const;
export type ButtonMainSizeType = typeof ButtonMainSizeType[keyof typeof ButtonMainSizeType];

interface Props {
  content?: string | JSX.Element;
  children?: React.ReactNode | React.ReactNode[];
  testcy: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  colorType?: ButtonMainColorType;
  width?: string;
  sizeType?: ButtonMainSizeType;
  type?: ButtonType;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  tooltip?: string;
  margin?: string;
  padding?: string;
  aspectRatio?: string;
  noBorder?: boolean;
}

export const ButtonMainNew = ({
  content,
  children,
  testcy,
  onClick = () => {},
  isDisabled,
  colorType = 'primary',
  width = 'unset',
  sizeType = 'medium',
  type = 'button',
  startIcon,
  endIcon,
  tooltip,
  margin = '0',
  padding,
  aspectRatio,
  noBorder,
}: Props) => {
  const buttonClasses = ['button-main', `color-${colorType}`, `size-${sizeType}`, isDisabled ? 'disabled-btn' : '', noBorder ? 'no-border-btn' : ''].join(' ');
  const button = (
    <Button
      className={buttonClasses}
      style={{ width: width, margin: margin, padding: padding, aspectRatio: aspectRatio }}
      onClick={onClick}
      disabled={isDisabled}
      type={type}
      startIcon={startIcon}
      endIcon={endIcon}
      data-testcy={testcy}
    >
      {content}
      {children}
    </Button>
  );

  return tooltip ? (
    <Tooltip sx={{ m: 1 }} arrow title={tooltip}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    <>{button}</>
  );
};
