import { dateFormatter } from '@lib';

export const InvoiceStatusType = {
  Draft: 'Draft',
  Issued: 'Issued',
  Overdue: 'Overdue',
  Sent: 'Sent',
  PartiallyPaid: 'PartiallyPaid',
  Paid: 'Paid',
} as const;
export type InvoiceStatusType = typeof InvoiceStatusType[keyof typeof InvoiceStatusType];

export const AccountantStatusType = {
  New: 'New',
  Sending: 'Sending',
  SentToAccountingService: 'SentToAccountingService',
  Confirmed: 'Confirmed',
} as const;
export type AccountantStatusType = typeof AccountantStatusType[keyof typeof AccountantStatusType];

export const PaymentStatusType = {
  PartiallyPaid: 'PartiallyPaid',
  Paid: 'Paid',
  Unpaid: 'Unpaid',
  Empty: 'Empty',
} as const;
export type PaymentStatusType = typeof PaymentStatusType[keyof typeof PaymentStatusType];

const STATUSES = {
  //invoice
  [InvoiceStatusType.Draft]: 'Szkic',
  [InvoiceStatusType.Issued]: 'Niewysłana',
  [InvoiceStatusType.Overdue]: 'Przeterminowana',
  [InvoiceStatusType.Sent]: 'Wysłana',
  [InvoiceStatusType.PartiallyPaid]: 'Cz. opłacona',
  [InvoiceStatusType.Paid]: 'Opłacona',
  //accountant
  [AccountantStatusType.New]: 'Nowa',
  [AccountantStatusType.Sending]: 'W trakcie wysyłania do systemu księgowego',
  [AccountantStatusType.SentToAccountingService]: 'Wysłana do systemu księgowego',
  [AccountantStatusType.Confirmed]: 'Zatwierdzona',
} as const;

/**
 * Translates a status code into a human-readable equivalent.
 * @param value The status code to be translated.
 * @returns The human-readable equivalent of the provided status code.
 */
export const translateStatusNew = (value: AccountantStatusType | InvoiceStatusType): string => {
  return STATUSES[value] ?? '';
};

export const calculateOverdueAmount = (dueDateStr: string): string => {
  if (!dueDateStr) return '';
  const dueDate = dateFormatter.getDateObjectFromString(dueDateStr, "yyyy-MM-dd'T'HH:mm:ss");
  const amount = Math.floor((new Date().valueOf() - dueDate.valueOf()) / 86_400_000);
  if (amount <= 0) return '';
  if (amount == 1) return '1 dzień';
  return `${amount} dni`;
};
