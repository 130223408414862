import React from 'react';
import { TheDialog } from '@components';
import { InvitationSettings } from '@features';
import { useContractorStore } from '@store';

interface Props {
  openAddAccountantDialog?: boolean;
  setOpenAddAccountantDialog?: (v: boolean) => void;
  openAccountantInfoModal?: boolean;
  setOpenAccountantInfoModal?: (v: boolean) => void;
}

export const MenuAvatarsPopups = ({
  openAddAccountantDialog = false,
  setOpenAddAccountantDialog,
  openAccountantInfoModal = false,
  setOpenAccountantInfoModal,
}: Props) => {
  const { ownerMyCompanyData } = useContractorStore();

  const sentEmailToAccountant = () => {
    window.open(`mailto:${ownerMyCompanyData?.accountingCompany?.email || ''}`, '_blank');
    setOpenAccountantInfoModal(false);
  };
  return (
    <>
      <InvitationSettings 
      openAddAccountantDialog={openAddAccountantDialog} 
      setOpenAddAccountantDialog={setOpenAddAccountantDialog} />
      <TheDialog
        testcy="accounting-company"
        open={openAccountantInfoModal}
        onClose={() => setOpenAccountantInfoModal(false)}
        onClickAdditionalButton={sentEmailToAccountant}
        title={'Dane firmy księgowej'}
        additionalButtonText={'Wyślij e-mail'}
        rejectButtonText={'Zamknij'}
        rejectButtonColorType="primary"
      >
        <div>Firma księgowa: {ownerMyCompanyData?.accountingCompany?.name}</div>
      </TheDialog>
    </>
  );
};
