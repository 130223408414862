import React, { useEffect, useState } from 'react';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { useNotifications } from '@features';
import { PageLoaderWrapper } from '@components';
import { dateFormatter } from '@lib';
import './sharedNotifications.scoped.scss';
import { NotificationIcon } from 'src/components/icons/notificationIcon';

interface Props {}

export const SharedNotifications = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);

  const { getNotificationsData, notifications, isPendingGetNotifications } = useNotifications(1, 10);

  useEffect(() => {
    getNotificationsData();
    // signal r to get info about notification in future
  }, [getNotificationsData]);

  const handleClickAvatarNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenNotification(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenNotification(false);
  };
  return (
    <>
      <Avatar
        component={'button'}
        data-testcy="notifications-avatar"
        className="notification-avatar"
        onClick={handleClickAvatarNotification}
        src="/future-image.jpg"
        aria-controls={openNotification ? 'notification-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openNotification ? 'true' : undefined}
      >
        <NotificationIcon />
      </Avatar>
      <Menu
        className="menu-notification-wrapper"
        anchorEl={anchorEl}
        id="notification-menu"
        open={openNotification}
        onClose={handleClose}
        onClick={handleClose} //change in future task, POST, read notification
        PaperProps={{
          sx: {
            width: '388px',
            borderRadius: '25px',
            mt: '15px',
            maxHeight: '520px',
            minHeight: '80px',
            '& .MuiList-root': {
              padding: '10px',
            },
            '& .MuiMenuItem-root': {
              whiteSpace: 'break-spaces',
              padding: '5px',
              margin: '5px',
              marginRight: '10px',
            },
            '&::-webkit-scrollbar-track': {
              marginBlock: '20px',
            },
            '&::-webkit-scrollbar-thumb': {
              margin: '5px',
              borderRadius: '10px',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <PageLoaderWrapper isLoading={isPendingGetNotifications}>
          <>
            <div className="menu-notification-title">Powiadomienia</div>
            <div className="menu-list">
              {notifications?.length ? (
                notifications.map((notification, index) => (
                  <MenuItem
                    data-testcy={`notifications-item-${index}`}
                    key={Math.random()}
                    className="menu-item"
                    onClick={() => {
                      setOpenNotification(true);
                    }}
                  >
                    <div>{notification.message}</div>
                    <div> {dateFormatter.changeFormat(notification.time, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS", 'dd.MM.yyyy')}</div>
                  </MenuItem>
                ))
              ) : (
                <div className="no-notifications">Brak powiadomień</div>
              )}
            </div>
          </>
        </PageLoaderWrapper>
      </Menu>
    </>
  );
};
