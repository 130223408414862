import { FileHashInfo } from '@interfaces';
import { dateFormatter } from '@lib';
import { pluralize, snackbarMessagesHandler } from '@utils';
import { ChangeEvent, DragEvent } from 'react';

export interface UploadedFiles {
  data: FormData;
  okayFileCount: number;
  totalFileCount: number;
  invalidFiles: File[];
  wrongSizeFiles: File[];
  tooLongNameFiles: File[];
}

interface DuplicatedFileData {
  name: string;
  createdDate: string;
}
export interface DuplicatedFile<T = DuplicatedFileData> {
  hash: string;
  name?: string;
  duplicates: T[];
}

export type DuplicatedFileListItem = {
  matchedFiles: string[];
  duplicates: DuplicatedFileData[];
  remainingDuplicateCount: number;
};

export const eventToFiles = (
  event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLElement>,
  maxAmountOfFiles: number,
  maxTotalSize: number,
  maxSingleSize: number,
  maxNameLength: number,
  accept?: string
): UploadedFiles => {
  const allowedExtensions: string[] | undefined = acceptStringToArray(accept);
  let files: File[] = [];

  if (isDropEvent(event)) {
    files = [...event.dataTransfer.files];
  } else {
    files = [...event.target.files];
  }

  let totalValidFilesSize: number = 0;

  const uploadedFiles = {
    data: new FormData(),
    okayFileCount: 0,
    totalFileCount: files.length,
    invalidFiles: [],
    wrongSizeFiles: [] as File[],
    tooLongNameFiles: [] as File[],
  };

  //check each file
  let shouldReturn = false;
  files.forEach(file => {
    shouldReturn = false;
    //check if length is below max
    if (file.name.length > maxNameLength) {
      uploadedFiles.tooLongNameFiles.push(file);
      shouldReturn = true;
    }
    //check if file extension is allowed
    if (!isFileExtensionAllowed(file, allowedExtensions)) {
      uploadedFiles.invalidFiles.push(file);
      shouldReturn = true;
    }
    //check if size is below maximum
    if (file.size > maxSingleSize) {
      uploadedFiles.wrongSizeFiles.push(file);
      shouldReturn = true;
    }
    if (shouldReturn) return;

    //add the file to be uploaded
    totalValidFilesSize += file.size;
    uploadedFiles.data.append('file', file);
    uploadedFiles.okayFileCount++;
  });

  //if total files size is above maximum, display snackbar and abort any further actions
  if (totalValidFilesSize > maxTotalSize) {
    snackbarMessagesHandler.allFilesSize(maxTotalSize / 1024 / 1024, totalValidFilesSize);
    return;
  }
  //if total file count is above maximum, display snackbar and abort any further actions
  if (files.length > maxAmountOfFiles) {
    snackbarMessagesHandler.tooManyFiles(maxAmountOfFiles, files.length);
    return;
  }
  //if there are any files that did not match the requirements, display appropriate snackbars
  if (uploadedFiles.tooLongNameFiles.length || uploadedFiles.wrongSizeFiles.length || uploadedFiles.invalidFiles.length) {
    if (uploadedFiles.tooLongNameFiles.length) snackbarMessagesHandler.fileTooLongName();
    if (uploadedFiles.wrongSizeFiles.length) snackbarMessagesHandler.singleFileSize(maxSingleSize / 1024 / 1024, uploadedFiles.wrongSizeFiles[0].size);
    if (uploadedFiles.invalidFiles.length) snackbarMessagesHandler.invalidFileFormat();

    return uploadedFiles;
  }

  return uploadedFiles;
};

function isDropEvent<T>(event: ChangeEvent | DragEvent<T>): event is DragEvent<T> {
  return event.type === 'drop';
}
function isFileExtensionAllowed(file: File, extensions?: string[]): boolean {
  if (!extensions) return true;
  const extension = file.name.toLowerCase().split('.').pop();
  return extensions.includes(extension);
}
function acceptStringToArray(accept: string | undefined): string[] | undefined {
  if (!accept) return undefined;
  return accept.replace(/\./g, '').replace(/\s/g, '').split(',');
}

export function mapDuplicatedFiles(files: DuplicatedFile[], hashes: FileHashInfo[]) {
  const duplicatedFileList: DuplicatedFileListItem[] = [];
  for (let i = 0; i < files.length; i++) {
    const duplicatedFile = files[i];
    duplicatedFile.hash = duplicatedFile.hash.toLowerCase();

    const matchedFilenames = hashes.filter(h => h.hash === duplicatedFile.hash).map(h => h.name);

    if (matchedFilenames.length === 0) {
      throw new Error(`Cannot find a file with hash ${duplicatedFile.hash} in the list of files.`);
    }

    const notDisplayedDuplicateCount = duplicatedFile.duplicates.length - 3;

    duplicatedFileList.push({
      matchedFiles: matchedFilenames,
      duplicates: duplicatedFile.duplicates.slice(0, 3),
      remainingDuplicateCount: notDisplayedDuplicateCount > 0 ? notDisplayedDuplicateCount : 0,
    });
  }
  return duplicatedFileList;
}

export const formatFile = (filenames: string[], duplicateCount: number) => {
  const str = filenames.reduce((acc, curr) => (acc += `"${curr}", `), '').slice(0, -2);

  const duplicateString = filenames.length === 1 ? 'jest duplikatem' : 'są duplikatami';
  const ending = duplicateCount === 1 ? 'u' : 'ów';

  return `Wygląda na to, że ${str} ${duplicateString} plik${ending}:`;
};

export const formatDuplicateItem = (data: DuplicatedFileData) => {
  const dateFormatted = dateFormatter.changeFormat(data.createdDate.slice(0, 19), "yyyy-MM-dd'T'HH:mm:ss", "dd-MM-yyyy 'o' HH:mm");

  return `"${data.name}" (przesłanego w dniu ${dateFormatted})`;
};

export const formatRemainingDuplicates = (count: number) => {
  return pluralize(count, `...oraz 1 inny plik`, `...oraz ${count} inne pliki`, `...oraz ${count} innych plików`);
};
