import { CircleIcon, SingleBarChart } from '@components';
import { ReceivablesPayablesObject, SingleBarChartData, formatCurrency } from '@utils';
import React from 'react';

import './cardContent.scoped.scss';

interface Props {
  data: ReceivablesPayablesObject;
  chartData: SingleBarChartData;
  isLoading: boolean;
  type: 'receivables' | 'payables';
}

export const CardContent = ({ data, chartData, isLoading, type }: Props) => {
  return (
    <div className="receivables-payables-content">
      <div className="receivables-payables-upper-container">
        <div className="receivables-payables-text">
          <div className="text">Całkowita kwota faktur do zapłaty:</div>
          <div className="amount">{formatCurrency(data?.total || 0, 'zł')}</div>
        </div>
        <div className="receivables-payables-chart">
          <SingleBarChart testcy="receivables-chart" isPending={isLoading} data={chartData} className={type} />
        </div>
      </div>
      <div className="receivables-payables-lower-container">
        <div className="amount-wrapper open">
          <div className="amount-wrapper-inner">
            <div className="amount-icon open">
              <CircleIcon />
            </div>
            <div className="amount-title">Oczekiwane:</div>
          </div>
          <div className="amount">{formatCurrency(data?.open || 0, 'PLN')}</div>
        </div>
        <div className="amount-wrapper">
          <div className="amount-wrapper-inner">
            <div className="amount-icon overdue">
              <CircleIcon />
            </div>
            <div className="amount-title">Przeterminowane:</div>
          </div>
          <div className="amount">{formatCurrency(data?.overdue || 0, 'PLN')}</div>
        </div>
      </div>
    </div>
  );
};
