import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { CompanyData } from '@services';

interface CompanyStore {
  selectedCompany: CompanyData | null;
  selectedCompanyId: string;
  setSelectedCompany: (data: CompanyData, companyId: string) => void;
}

export const useCompanyStore = create<CompanyStore>(set => ({
  selectedCompany: null,
  selectedCompanyId: '',
  setSelectedCompany: (data: CompanyData, id: string) => set(() => ({ selectedCompany: data, selectedCompanyId: id })),
}));

if (process.env.NODE_ENV === 'development') mountStoreDevtool('CompanyStore', useCompanyStore);
