import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';

export const useGoogleAnalyticsPageUpdate = () => {
  const location = useLocation();
  useEffect(() => {
    if (ReactGA.isInitialized) ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
};
