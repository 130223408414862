import React from 'react';
import { TableHeaderInterface, TableRowInterface } from '@components';
import { CompanyListData } from '@services';

export const tableHeader: TableHeaderInterface[] = [
  {
    label: 'Nazwa firmy',
    data: 'name',
    minWidth: 200,
    width: 200,
    isSortable: true,
  },
  {
    label: 'Właściciel',
    data: 'owner',
    minWidth: 200,
    width: 200,
    isSortable: true,
  },
  {
    label: 'NIP',
    data: 'nip',
    minWidth: 150,
    width: 150,
    isSortable: true,
  },
  {
    label: 'Adres',
    data: 'address',
    minWidth: 180,
    width: 180,
    isSortable: true,
  },
  {
    label: 'Telefon',
    data: 'phoneNumber',
    minWidth: 130,
    width: 130,
    isSortable: false,
  },
  {
    label: 'E-mail',
    data: 'email',
    minWidth: 230,
    width: 230,
    isSortable: true,
  },
  {
    label: 'KSEF',
    data: 'allowKsef',
    minWidth: 90,
    width: 90,
  },
  {
    label: '',
    data: 'action',
    minWidth: 60,
    width: 60,
    action: true,
    isSortable: false,
  },
];

export const companyDataToTableRow = (data: CompanyListData): TableRowInterface => {
  const formatAddress = data.address.split(',');
  return {
    id: data?.id?.toString() || '',
    name: {
      content: <div className="ellipsis-text-two-lines-g">{data.name}</div>,
    },
    owner: {
      content: <div className="ellipsis-text-two-lines-g">{data.owner}</div>,
    },
    nip: {
      content: data.nip,
    },
    address: {
      content: (
        <>
          <div>{formatAddress[0]},</div>
          <div>{formatAddress[1]}</div>
        </>
      ),
    },
    phoneNumber: {
      content: data.phoneNumber,
    },
    email: {
      content: <div className="ellipsis-text-long-word-g">{data.email}</div>,
    },
    allowKsef: {
      content: data.allowKsef ? 'Tak' : 'Nie',
    },
    action: {},
  };
};

export const transformMenuItemsCompaniesList = dataList => {
  const menuItems = [];
  dataList.forEach(data => {
    menuItems.push([
      {
        label: 'Otwórz',
        value: 'open',
      },
      {
        label: 'Edytuj',
        value: 'edit',
      },
      {
        label: 'Zaproś',
        value: 'invite',
      },
    ]);
  });

  return menuItems;
};
