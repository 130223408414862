import { useState } from 'react';

import { handleApiError } from '@utils';
import { RequestErrorMessage } from '@interfaces';
import { getCheckCredentials } from '@services';
import { addHotjarEvent } from '@lib';

interface UseInsertStatusInterface {
  isPendingCredentialsToInsertData: boolean;
  status: string;
  checkInsertStatus: (companyId: string) => void;
  resetStatus: () => void;
}

export const useCheckInsertStatusEachCompany = (): UseInsertStatusInterface => {
  const [isPendingCredentialsToInsertData, setIsPendingCredentialsToInsertData] = useState(false);
  const [status, setStatus] = useState('');

  const fetchInsertStatus = (companyId: string) => {
    addHotjarEvent('Get InsERT data status');
    setIsPendingCredentialsToInsertData(true);
    const params = +companyId;
    getCheckCredentials(params)
      .then(data => {
        setStatus(data ? 'success' : 'failure');
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingCredentialsToInsertData(false));
  };

  return {
    isPendingCredentialsToInsertData,
    status,
    checkInsertStatus: companyId => fetchInsertStatus(companyId),
    resetStatus: () => setStatus(null),
  };
};
