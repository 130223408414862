import React, { useState, useEffect, useMemo } from 'react';
import { ButtonText, TextInputNew, NumberInput, SpecialInput } from '@components';
import { Control, FieldValues, UseFormUnregister, RegisterOptions, useFormContext } from 'react-hook-form';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { focusOnNextFormInput, focusOnNextFormInput_FORCE } from '@utils';
import './listBox.scoped.scss';
import { SpecialInputType } from './../specialInput/specialInput.utils';

export const ListBoxInputType = {
  Text: 'text',
  Number: 'number',
  NumberFloat: 'number-float',
  ...SpecialInputType,
} as const;
export type ListBoxInputType = typeof ListBoxInputType[keyof typeof ListBoxInputType];

export interface Props {
  items: string[];
  testcy: string;
  control?: Control;
  unregister?: UseFormUnregister<FieldValues>;
  itemLabel: string;
  addButtonText?: string;
  propertyNamePrefix: string;
  inputType: ListBoxInputType;
  inputValidation?: RegisterOptions;
  numberInputMaxDecimals?: number;
  addButtonFactory?: (onClick: () => void) => JSX.Element;
  inputWidth?: string;
  fieldNameFactory?: (index: number) => string;
  dialogView?: boolean;
}

export const ListBox = ({
  items,
  testcy,
  control,
  unregister,
  itemLabel,
  addButtonText,
  inputType = ListBoxInputType.Text,
  propertyNamePrefix,
  inputValidation,
  addButtonFactory,
  numberInputMaxDecimals,
  inputWidth,
  fieldNameFactory = (index: number) => `${propertyNamePrefix}-${index}`,
}: Props) => {
  const [formItems, setFormItems] = useState([]);

  const context = useFormContext();

  useEffect(() => {
    const newItems = items?.map((item, index) => ({ value: item, id: index }));
    setFormItems(newItems);
  }, [items]);

  const addItem = () => {
    const newItems = [...formItems, { value: '', id: formItems.length ? formItems.at(-1).id + 1 : 0 }];
    setFormItems(newItems);
    setTimeout(() => {
      const fieldName = fieldNameFactory(Number(`${formItems.length ? formItems.at(-1).id + 1 : 0}`));
      focusOnNextFormInput_FORCE(fieldName);
    }, 0);
  };

  const removeItem = (id: string, index: number) => {
    const newItems = [...formItems];
    newItems.splice(index, 1);
    const fieldName = fieldNameFactory(index);
    unregister?.(fieldName);
    context?.unregister?.(fieldName);
    setFormItems(newItems);
  };

  const addButton = useMemo(
    () =>
      addButtonFactory ? (
        addButtonFactory(addItem)
      ) : (
        <ButtonText fontFamily='Poppins, sans-serif' testcy={`${testcy}-add-button`} content={addButtonText ? addButtonText : 'DODAJ'} endIcons="add" onClick={addItem} name="add-button" />
      ),
    [addButtonFactory, formItems]
  );

  const isNumberInput = () => inputType == ListBoxInputType.Number || inputType == ListBoxInputType.NumberFloat;
  const isSpecialInput = (type: ListBoxInputType): type is SpecialInputType => Object.values<string>(SpecialInputType).includes(type);

  return (
    <div className="listbox-wrapper">
      {formItems.map((item, index) => {
        const fieldName = fieldNameFactory(index);
        return (
          <div key={item.id} className="listbox-text-input-wrapper">
            <div className="input-list-wrapper">
              {inputType == ListBoxInputType.Text && (
                <TextInputNew
                  onKeyUp={event => {
                    focusOnNextFormInput(event, 2);
                  }}
                  defaultValue={item.value}
                  name={fieldName}
                  control={control}
                  label={itemLabel}
                  width="100%"
                  appearance={'light'}
                  validation={inputValidation}
                  testcy={`${testcy}-input-${index}`}
                />
              )}
              {isNumberInput() && (
                <NumberInput
                  onKeyDown={event => {
                    focusOnNextFormInput(event, 2);
                  }}
                  float={inputType == ListBoxInputType.NumberFloat}
                  defaultValue={item.value}
                  name={fieldName}
                  control={control}
                  maxDecimals={numberInputMaxDecimals}
                  label={itemLabel}
                  width="100%"
                  appearance={'light'}
                  validation={inputValidation}
                  testcy={`${testcy}-input-${index}`}
                />
              )}
              {isSpecialInput(inputType) && (
                <SpecialInput
                  type={inputType}
                  onKeyDown={event => {
                    if (event.key === 'Enter' && Number(index) + 1 === formItems.length) return focusOnNextFormInput_FORCE('add-button');
                    if (event.key === 'Enter') {
                      return focusOnNextFormInput_FORCE(fieldNameFactory(index + 1));
                    }
                  }}
                  defaultValue={item.value}
                  name={fieldName}
                  control={control}
                  label={itemLabel}
                  width="100%"
                  appearance={'light'}
                  validation={inputValidation}
                  testcy={`${testcy}-input-${index}`}
                />
              )}
            </div>
            <button type="button" data-testcy={`${testcy}-remove-${index}`} onClick={() => removeItem(item.id, index)}>
              <RemoveCircleOutlineIcon className="remove-icon" />
            </button>
          </div>
        );
      })}
      {addButton}
    </div>
  );
};
