import React from 'react';
import { ArrowIcon } from '../arrow/arrow';

import './receivablesPayables.scoped.scss';

export const ReceivablesOrPayables = {
  Receivables: 'receivables',
  Payables: 'payables',
} as const;
export type ReceivablesOrPayables = typeof ReceivablesOrPayables[keyof typeof ReceivablesOrPayables];

interface Props {
  type: ReceivablesOrPayables;
}

export const ReceivablesPayablesIcon = ({ type }: Props) => {
  return (
    <div className={`receivables-icon-wrapper icon-${type}`}>
      <svg xmlns="http://www.w3.org/2000/svg" className="hollow-circle icon-color--stroke" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <circle cx="10" cy="10.5" r="9.3" strokeWidth="1.4" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" className="filled-circle icon-color--g icon-color--stroke" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <circle cx="8" cy="8.5" r="7.3" strokeWidth="1.4" />
      </svg>
      <div className="arrow">
        <svg className={`icon-color--stroke`} width={12} height={12} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.71582 6.96872L7.99994 2.66666L12.284 6.96872" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8 2.66666V13.3333" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </div>
  );
};
