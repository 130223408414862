import React, { useEffect, useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useWindowSize } from '@hooks';

import './theAccordion.scoped.scss';
import { ChevronIcon } from 'src/components/icons';

export const AccordionAppearance = {
  Old: 'old',
  Primary: 'primary',
} as const;
export type AccordionAppearance = typeof AccordionAppearance[keyof typeof AccordionAppearance];

interface Props {
  open: boolean;
  testcy: string;
  setOpen: (v: boolean) => void;
  title: string;
  children: JSX.Element;
  id: string;
  appearance?: AccordionAppearance;
}

export const TheAccordion = ({ open, setOpen, title, children, id, testcy, appearance = AccordionAppearance.Old }: Props) => {
  const refAccordionChild = useRef<HTMLDivElement>();
  const [width] = useWindowSize();

  useEffect(() => {
    resize();
  });

  useEffect(() => {
    resize();
  }, [width]);

  const resize = () => {
    const height = refAccordionChild?.current?.clientHeight;
    const el = document.getElementById(id);
    if (!el || height === undefined) return;
    el.style.height = `${open ? height : 0}px`;
  };

  return (
    <div className={`the-accordion-wrapper ${open ? 'accordion-open' : 'accordion-closed'} appearance-${appearance}`}>
      <button type="button" data-testcy={testcy} className="accordion-header" onClick={() => setOpen(!open)}>
        <p className="title-g accordion-title">{title}</p>
        {appearance === AccordionAppearance.Old ? <ExpandMoreIcon className="accordion-arrow-icon" /> : <ChevronIcon direction='down' size={16} />}
      </button>

      <div className="accordion-children" id={id}>
        <div ref={refAccordionChild}>{children}</div>
      </div>
    </div>
  );
};
