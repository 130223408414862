import React, { PropsWithChildren } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider as Provider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { handleApiError } from '@utils';
import { RequestErrorMessage } from '@interfaces';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      handleApiError(error as RequestErrorMessage[]);
    },
  }),
  mutationCache: new MutationCache({
    onError: error => {
      handleApiError(error as RequestErrorMessage[]);
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const QueryClientProvider = ({ children }: PropsWithChildren) => {
  return (
    <Provider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </Provider>
  );
};
