import { useCostStore, useInvoiceStore } from '@store';

export type InvoiceDrawerOpenMode = 'onlyInvoiceDrawer' | 'onlyInvoicePreview' | 'invoiceDrawerAndInvoicePreview';

export const useInvoiceDrawerController = (type: 'cost' | 'invoice') => {
  const { setOpenInvoiceDrawer, setOpenInvoicePreview } = useInvoiceStore();
  const { setOpenCostDrawer, setOpenCostPreview } = useCostStore();

  const openInvoiceDrawer = (mode: InvoiceDrawerOpenMode, id: string) => {
    if (type === 'invoice') {
      switch (mode) {
        case 'invoiceDrawerAndInvoicePreview':
          setOpenInvoiceDrawer(true, id);
          setOpenInvoicePreview(true, id);
          return;
        case 'onlyInvoiceDrawer':
          setOpenInvoiceDrawer(true, id);
          setOpenInvoicePreview(false, id);

          return;
        case 'onlyInvoicePreview':
          setOpenInvoiceDrawer(false, id);
          setOpenInvoicePreview(true, id);
          return;
      }
    } else if (type === 'cost') {
      switch (mode) {
        case 'invoiceDrawerAndInvoicePreview':
          setOpenCostDrawer(true, id);
          setOpenCostPreview(true, id);
          return;
        case 'onlyInvoiceDrawer':
          setOpenCostDrawer(true, id);
          setOpenCostPreview(false, id);

          return;
        case 'onlyInvoicePreview':
          setOpenCostDrawer(false, id);
          setOpenCostPreview(true, id);

          return;
      }
    }
  };
  const closeInvoiceDrawer = () => {
    setOpenInvoiceDrawer(false, '');
    setOpenInvoicePreview(false, '');
    setOpenCostDrawer(false, '');
    setOpenCostPreview(false, '');
  };

  return {
    openInvoiceDrawer,
    closeInvoiceDrawer,
  };
};
