import { addHotjarEvent } from '@lib';
import { formatFetchedDataToPdf } from '@utils';
import { useApiMutation } from '../useApiMutation';

interface IUseInvoiceImage {
  downloadInvoiceAsPdf: (id: string, invoiceNumber: string, duplicateDate?: string) => void;
  isDownloadImageLoading: boolean;
}

export const useInvoiceImage = (): IUseInvoiceImage => {
  const { mutate, isLoading } = useApiMutation('createInvoicePdfById');

  const downloadInvoiceAsPdf = (id: string, invoiceNumber: string, duplicateDate?: string) => {
    mutate({ id, duplicateDate }, {
      onSuccess: (data) => {
        const blob = new Blob([data], { type: 'image/pdf' });
        addHotjarEvent('Download invoice PDF');
        if (blob) formatFetchedDataToPdf(blob, `faktura-${invoiceNumber}.pdf`);
      }
    });
  }

  return { downloadInvoiceAsPdf, isDownloadImageLoading: isLoading };
};
