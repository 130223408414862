import { useNavigate, useParams } from 'react-router';
import { useInvoiceStore } from '@store';
import { useInvoiceDrawerController } from '@hooks';

interface UseEditCostDataInterface {
  editDigitalizedCost: (invoiceId: string) => void;
}

export const useEditDigitalizedCost = (): UseEditCostDataInterface => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { setIsCorrected } = useInvoiceStore();
  const { closeInvoiceDrawer } = useInvoiceDrawerController('cost');

  const editDigitalizedCost = (invoiceId: string) => {
    closeInvoiceDrawer();
    setIsCorrected(false); // future investigation why this is needed here
    if (companyId) {
      navigate(`/owner-company-list/details/${companyId}/edit-cost/${invoiceId}`);
      return;
    }
    navigate(`/costs-list/edit-cost/${invoiceId}`);
  };

  return {
    editDigitalizedCost: invoiceId => editDigitalizedCost(invoiceId),
  };
};
