import React, { MouseEvent, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckIcon from '@mui/icons-material/Check';
import './buttonTextNew.scoped.scss';
import { ButtonType } from '@utils';

export const IconType = {
  Far: 'far',
  Add: 'add',
  Info: 'info',
} as const;
export type IconType = typeof IconType[keyof typeof IconType];

export const ButtonTextColor = {
  Primary: 'primary',
  Blue: 'blue',
  Grey: 'grey',
} as const;
export type ButtonTextColor = typeof ButtonTextColor[keyof typeof ButtonTextColor];

export interface Props {
  content?: string;
  children?: string | JSX.Element | JSX.Element[];
  testcy: string;
  className?: string;
  isDisabled?: boolean;
  fontColor?: string;
  startIcons?: 'back' | 'tick' | JSX.Element;
  endIcons?: IconType;
  color?: ButtonTextColor;
  type?: ButtonType;
  iconSize?: 'small' | 'medium' | 'big';
  fontSize?: string;
  fontWeight?: string | number;
  noDecoration?: boolean;
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none';
  margin?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  width?: string;
  highPadding?: boolean;
}

export const ButtonTextNew = ({
  content,
  children,
  testcy,
  className,
  isDisabled,
  startIcons,
  fontColor = '',
  endIcons,
  width,
  color = ButtonTextColor.Primary,
  type = 'button',
  iconSize = 'small',
  fontSize = '14px',
  fontWeight = 400,
  noDecoration,
  textTransform = 'uppercase',
  margin = '0',
  onClick = () => {},
  highPadding,
}: Props) => {
  const [startIcon, setStartIcon] = useState(null);
  const [endIcon, setEndIcon] = useState(null);

  useEffect(() => {
    if (startIcons === 'back') {
      setStartIcon(<ArrowBackIosNewIcon style={{ fontSize: `${iconSize}`,marginRight:'8px' }} />);
    } else if (startIcons === 'tick') {
      setStartIcon(<CheckIcon style={{ fontSize: `${iconSize}` ,marginRight:'8px'}} />);
    } else {
      setStartIcon(startIcons);
    }

    switch (endIcons) {
      case IconType.Far:
        setEndIcon(<ArrowForwardIosIcon style={{ fontSize: `${iconSize}` }} />);
        break;
      case IconType.Add:
        setEndIcon(<ControlPointIcon style={{ fontSize: `${iconSize}` }} />);
        break;
      case IconType.Info:
        setEndIcon(<InfoOutlinedIcon style={{ fontSize: `${iconSize}` }} />);
        break;
    }
  }, [endIcons, iconSize, startIcons]);

  return (
    <Button
      sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
      className={`button-text button-color-${color} ${noDecoration ? 'no-decoration' : ''} ${highPadding ? 'high-padding' : ''} ${className}`}
      style={{
        color: fontColor,
        fontWeight: fontWeight,
        textDecorationColor: fontColor,
        fontSize: fontSize,
        textTransform: textTransform,
        margin: margin,
        width: width,
      }}
      onClick={onClick}
      disabled={isDisabled}
      variant="text"
      startIcon={startIcon}
      type={type}
      endIcon={endIcon}
      disableRipple
      data-testcy={testcy}
    >
      <div className="focus-indicator"></div>
      {content}
      {children}
    </Button>
  );
};
