import React from 'react';
import { TheDialog } from '@components';
import { SharedEmailForm, SharedReminderForm } from '@features';
import { InvoiceDetails } from '@services';
import { EmailFormData } from 'src/features/shared/sharedEmailForm/sharedEmailForm.utils';
import { ReminderFormData } from 'src/features/shared/sharedReminderForm/sharedReminderForm.utils';
import { InvoiceCreateDuplicateForm } from './components';
import { CreateDuplicateFormData } from './components/invoiceCreateDuplicateForm/invoiceCreateDuplicateForm.utils';
import { InvoiceRecordPaymentForm } from './components/invoiceRecordPaymentForm/invoiceRecordPaymentForm';
import { RecordAmountFormData } from './components/invoiceRecordPaymentForm/invoiceRecordPaymentForm.utils';
import cleaningPic from '@assets/images/cleaning_angle.png';

interface Props {
  openCreateDuplicateModal: boolean;
  setOpenCreateDuplicateModal: (v: boolean) => void;
  setOpenEmailModal: (v: boolean) => void;
  setOpenReminderModal: (v: boolean) => void;
  setOpenRecordPaymentModal: (v: boolean) => void;
  openEmailModal: boolean;
  openReminderModal: boolean;
  onSubmitEmailForm: (data: EmailFormData) => void;
  onSubmitReminderForm: (data: ReminderFormData) => void;
  onSubmitRecordPayment: (data: RecordAmountFormData) => void;
  onSubmitCreateDuplicate: (data: CreateDuplicateFormData) => void;
  openDialogDelete: boolean;
  openRecordPaymentModal: boolean;
  onDeleteInvoice: (v: boolean) => void;
  invoiceName: string;
  amountToPay: number | null;
  amountPaid: string | null;
  dueDate: string;
  invoiceData: InvoiceDetails;
  isEmailPending: boolean;
  isReminderPending: boolean;
  isRecordPaymentPending: boolean;
}

export const InvoicePopups = ({
  openCreateDuplicateModal,
  setOpenCreateDuplicateModal,
  setOpenEmailModal,
  setOpenReminderModal,
  openReminderModal,
  setOpenRecordPaymentModal,
  openEmailModal,
  openRecordPaymentModal,
  onSubmitEmailForm,
  onSubmitReminderForm,
  openDialogDelete,
  onDeleteInvoice,
  invoiceName,
  amountToPay,
  amountPaid,
  dueDate,
  invoiceData,
  isEmailPending,
  isReminderPending,
  isRecordPaymentPending,
  onSubmitRecordPayment,
  onSubmitCreateDuplicate,
}: Props) => {
  return (
    <>
      <TheDialog
        title="Udostępnij fakturę mailem"
        testcy="invoice-email"
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        className="modal-small-size-g"
        formDialog
      >
        <SharedEmailForm invoiceNumber={invoiceName} dueDate={dueDate} amountToPay={amountToPay} isEmailPending={isEmailPending} onSubmit={onSubmitEmailForm} />
      </TheDialog>
      <TheDialog
        title="Wyślij przypomnienie o płatności"
        testcy="invoice-reminder"
        open={openReminderModal}
        onClose={() => setOpenReminderModal(false)}
        formDialog
      >
        <SharedReminderForm invoiceNumber={invoiceName} amountToPay={amountToPay} isReminderPending={isReminderPending} onSubmit={onSubmitReminderForm} />
      </TheDialog>
      <TheDialog testcy="invoice-payment" open={openRecordPaymentModal} onClose={() => setOpenRecordPaymentModal(false)} title={'Zarejestruj wpłatę'}>
        <InvoiceRecordPaymentForm
          onSubmit={onSubmitRecordPayment}
          isRecordPaymentPending={isRecordPaymentPending}
          amountToPay={amountToPay}
          amountPaid={amountPaid}
        ></InvoiceRecordPaymentForm>
      </TheDialog>

      <TheDialog testcy="invoice-delete-confirmation" open={openDialogDelete} onClose={onDeleteInvoice} approveButtonText="Tak" rejectButtonText="Nie">
        <div className="inbox-dialog-container">
          <img src={cleaningPic} alt="cleaning-set" width={111} height={128} />
          <div>Czy na pewno chcesz usunąć fakturę</div>
          <div className="remove-dialog-content-document">
            <div>
              {invoiceName} <span className="question-mark">?</span>
            </div>
          </div>
        </div>
      </TheDialog>
      <TheDialog title="Wystaw duplikat" testcy="invoice-payment" open={openCreateDuplicateModal} onClose={() => setOpenCreateDuplicateModal(false)}>
        <InvoiceCreateDuplicateForm invoiceData={invoiceData} onSubmit={onSubmitCreateDuplicate} />
      </TheDialog>
    </>
  );
};
