import { SearchIcon, TextInputNew } from '@components';
import React from 'react';
import { Control } from 'react-hook-form';
import { FormElementSize } from '../types';

import './searchInput.scss';

type Props = {
  testcy: string;
  control: Control;
  name: string;
  onChange: (value: string) => void;
  placeholder: string;
  width: string;
  size: FormElementSize;
};

export const SearchInput = ({ testcy, control, name, onChange, placeholder, width, size }: Props) => {
  return (
    <div className="search-input-container">
      <TextInputNew
        testcy={testcy}
        control={control}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        width={width}
        size={size}
        startAdornment={<SearchIcon />}
        appearance="primary"
      />
    </div>
  );
};
