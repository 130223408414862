import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { ButtonTabs } from '@interfaces';
import './tabsButton.scoped.scss';
import { useTabsButtonStore } from './tabsButtonStore';

interface Props {
  contents: ButtonTabs[];
  testcy: string;
  onClickTab: (id: string) => void;
  fontSize?: number;
  beforeTabChange?: (id: string) => Promise<boolean> | boolean;
}

export const TabsButton = ({ contents, onClickTab, fontSize = 14, beforeTabChange, testcy }: Props) => {
  const { tabs, setTabs, updateActiveTab } = useTabsButtonStore();

  useEffect(() => {
    setTabs(contents);
  }, [contents, setTabs]);

  const onClickButton = async (id: string) => {
    if (!(await beforeTabChange(id))) {
      return;
    }

    updateActiveTab(id);
    onClickTab(id);
  };

  useEffect(() => {
    // updateActiveTab(contents[0].id); !important trigger allways first tab
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tab-button-wrapper">
      {tabs.map((data, tabIndex) => {
        return (
          <Button
            key={data.name}
            className={`button ${!!data.isActive ? 'active' : ''} ${!!data.disable ? 'disabled' : ''}`}
            style={{
              fontSize: `${fontSize}px`,
              width: `${data.width || 200}px`,
              visibility: `${data.disable ? 'hidden' : 'visible'}`,
            }}
            variant="text"
            disableRipple
            onClick={async () => await onClickButton(data.id)}
            value={data.name}
            data-testcy={`${testcy}-${tabIndex}`}
          >
            {data.name}
          </Button>
        );
      })}
    </div>
  );
};
