import { Contractors, CostsIcon, HomeIcon, Inbox, Invoices, RevenuesIcon, StoreIcon, Taxes, UsersIcon } from '@components';
import { useUserStore, useContractorStore } from '@store';
import { NavIconType, navData } from '@utils';
import React from 'react';
import { NavLink } from 'react-router-dom';

import './navbar.scoped.scss';

const getIcon = (icon: NavIconType) => {
  if (icon === NavIconType.Contractors) return <Contractors colorType="" />;
  if (icon === NavIconType.Invoices) return <Invoices colorType="" />;
  if (icon === NavIconType.Taxes) return <Taxes colorType="" />;
  if (icon === NavIconType.Inbox) return <Inbox colorType="" />;
  if (icon === NavIconType.Home) return <HomeIcon colorType="" />;
  if (icon === NavIconType.Costs) return <CostsIcon colorType="" />;
  if (icon === NavIconType.Revenues) return <RevenuesIcon colorType="" />;
  if (icon === NavIconType.Store) return <StoreIcon colorType="" />;
  if (icon === NavIconType.Users) return <UsersIcon colorType="" />;
};

export const Navbar = () => {
  const { role } = useUserStore();
  const { hasAccountingClients } = useContractorStore();

  return (
    <div className='nav-wrapper'> 
      <nav className="navigation">
        {navData
          .filter(data => data.permissions.includes(role))
          .filter(data => data.route !== '/owner-company-list' || (data.route === '/owner-company-list' && hasAccountingClients))
          .map(data => {
            return (
              <NavLink
                data-testcy={`header-navlink-${data.testcy}`}
                key={data.route}
                to={data.route}
                className={({ isActive }) => (isActive ? 'navlink-active navlink' : 'navlink')}
              >
                <div className="icon-wrapper">{getIcon(data.icon)}</div>
                {data.name}
              </NavLink>
            );
          })}
      </nav>
    </div>
  );
};
