import React, { ReactNode } from 'react';
import { Drawer } from '@mui/material';
import './theDrawer.scss';

interface Props {
  children: ReactNode;
  showCloseIcon?: boolean;
  anchor?: 'left' | 'top' | 'right' | 'bottom';
  variant?: 'permanent' | 'persistent' | 'temporary';
  width?: string;
  dimensionVariant?: 'full-screen' | 'body-content';
  open: boolean;
}

export const TheDrawer = ({ children, anchor = 'right', variant = 'temporary', width = 'unset', dimensionVariant = 'full-screen', open }: Props) => {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      variant={variant}
      className={`${dimensionVariant === 'body-content' ? 'body-content-drawer' : ''}`}
      sx={{
        width: width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: width,
          boxSizing: 'border-box',
        },
      }}
      ModalProps={{
        disableEnforceFocus: true,
        hideBackdrop: true,
      }}
      transitionDuration={{
        exit: 800,
        enter: 800,
      }}
    >
      <div className="drawer-body-wrapper">
        <div className="drawer-children-wrapper">{children}</div>
      </div>
    </Drawer>
  );
};
