import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const Taxes = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17 2.75H7C4.65279 2.75 2.75 4.65279 2.75 7V17C2.75 19.3472 4.65279 21.25 7 21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V7C21.25 4.65279 19.3472 2.75 17 2.75Z"
        stroke="#101013"
        strokeWidth="1.5"
      />
      <path d="M8.25 11.75V16.25" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M12.25 7.75V16.25" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M16.25 10.75V16.25" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
