import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const ConfirmIcon = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-g`} width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.44998 15.4326L11.7538 10.1288L10.6692 9.04423L6.4346 13.2788L4.31535 11.1596L3.24615 12.2288L6.44998 15.4326ZM1.8077 19.5C1.30257 19.5 0.875 19.325 0.525 18.975C0.175 18.625 0 18.1974 0 17.6923V2.3077C0 1.80257 0.175 1.375 0.525 1.025C0.875 0.675 1.30257 0.5 1.8077 0.5H9.75L15 5.74995V17.6923C15 18.1974 14.825 18.625 14.475 18.975C14.125 19.325 13.6974 19.5 13.1922 19.5H1.8077ZM9 6.49995V1.99998H1.8077C1.73077 1.99998 1.66024 2.03203 1.59612 2.09613C1.53202 2.16024 1.49997 2.23077 1.49997 2.3077V17.6923C1.49997 17.7692 1.53202 17.8397 1.59612 17.9038C1.66024 17.9679 1.73077 18 1.8077 18H13.1922C13.2692 18 13.3397 17.9679 13.4038 17.9038C13.4679 17.8397 13.5 17.7692 13.5 17.6923V6.49995H9Z"
        fill="#000000"
      />
    </svg>
  );
};
