import insertImg from '@assets/images/insert.png';
import { ButtonMainNew, PageLoaderWrapper, PasswordInput, TextInputNew, TheDialog, TheSelect } from '@components';
import { useIsAllFalse } from '@hooks';
import { useContractorStore } from '@store';
import { IntegrationStatus, focusOnNextFormInput, formErrorMsg, scrollToFirstError, setFormValues, snackbarMessagesHandler, yesNoOptions } from '@utils';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SettingsSection, SettingsSubSection, SettingsTab } from '../../components/settingsTab';
import { useCredentialToInsertData, useHoldInsertIntegration } from './hooks';
import { CredentialsFormData, toggleMessagesTexts, transformCredentialsDataToFormData, transformFormDataToCredentialsData } from './insertSection.utils';

export const InsertSection = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const { ownerMyCompanyData } = useContractorStore();
  const { isPendingGetHoldInsertIntegrationData, isPendingSetHoldInsertIntegrationData, integrationStatus, setHoldIntegrationToInsert } =
    useHoldInsertIntegration();
  const { username, password, server, isPendingCredentialsToInsertData, setCredentialsToInsert } = useCredentialToInsertData(
    ownerMyCompanyData.hasAccountingClients
  );
  const isLoadingPage = useIsAllFalse([isPendingCredentialsToInsertData, isPendingGetHoldInsertIntegrationData], 500);

  const onChangeHoldIntegration = (value: string) => {
    value === 'tak' ? setHoldIntegrationToInsert(true) : setHoldIntegrationToInsert(false);
  };

  useEffect(() => {
    const formData = transformCredentialsDataToFormData(username, password, server);
    setFormValues(setValue, formData);
  }, [username, password, server]);

  useEffect(() => {
    if (integrationStatus === IntegrationStatus.PAUSED || integrationStatus === IntegrationStatus.PAUSE_REQUESTED) setValue('holdInsertIntegration', 'tak');
    else setValue('holdInsertIntegration', 'nie');
  }, [integrationStatus]);

  const getHoldIntegrationMessage = (): string | null => {
    const isHold = watch('holdInsertIntegration');
    if (isHold == 'tak' && integrationStatus === IntegrationStatus.PAUSE_REQUESTED) return toggleMessagesTexts.processing;
    if (isHold == 'tak' && integrationStatus === IntegrationStatus.PAUSED) return toggleMessagesTexts.processStopped;
    return null;
  };

  const onSubmit = (data: CredentialsFormData) => {
    const requestInsertData = transformFormDataToCredentialsData(data);
    setCredentialsToInsert(requestInsertData);
  };

  const onCloseDialog = (agree: boolean) => {
    setIsDialogOpen(false);
    if (!agree) return;

    handleSubmit(
      f => onSubmit(f as CredentialsFormData),
      () => {
        if (Object.keys(errors).length) scrollToFirstError(Object.keys(errors));
        snackbarMessagesHandler.contractorFormNeedCorrection();
      }
    )();
  };

  return (
    <>
      <TheDialog
        testcy="change-insert-data"
        open={isDialogOpen}
        onClose={onCloseDialog}
        approveButtonText="Potwierdź"
        rejectButtonText="Anuluj"
        title="Czy na pewno chcesz zmienić dane dostępowe do bazy danych InsERT?"
      >
        <div className="dialog-content">
          <p>Wprowadzenie niepoprawnych danych spowoduje utratę połączenia z bazą.</p>
        </div>
      </TheDialog>
      <SettingsTab title="Integracje">
        <PageLoaderWrapper isLoading={isLoadingPage}>
          <SettingsSection>
            <SettingsSubSection title="InsERT" subtitle="Dane dostępowe do bazy danych InsERT." image={<img src={insertImg} />}>
              <TextInputNew
                control={control}
                testcy="insert-database"
                name="serverInsertDataBase"
                label="Baza danych"
                width="100%"
                appearance="filled"
                onKeyUp={event => {
                  focusOnNextFormInput(event, 2);
                }}
                validation={{
                  maxLength: {
                    value: 1000,
                    message: formErrorMsg.maxLength(1000),
                  },
                }}
              />
              <TextInputNew
                control={control}
                testcy="insert-unsername"
                name="userNameInsertDataBase"
                label="Użytkownik"
                width="100%"
                appearance="filled"
                onKeyUp={event => {
                  focusOnNextFormInput(event, 2);
                }}
                validation={{
                  maxLength: {
                    value: 100,
                    message: formErrorMsg.maxLength(100),
                  },
                }}
              />
              <PasswordInput
                control={control}
                testcy="insert-password"
                name="passwordInsertDataBase"
                label="Hasło"
                width="100%"
                appearance="filled"
                onKeyUp={event => {
                  focusOnNextFormInput(event, 3);
                }}
                validation={{
                  maxLength: {
                    value: 100,
                    message: formErrorMsg.maxLength(100),
                  },
                }}
              />
            </SettingsSubSection>
            <SettingsSubSection subtitle="Chcesz wstrzymać integracje z InsERT?" saveImageSpace>
              <TheSelect
                control={control}
                testcy="allowKsef"
                options={yesNoOptions}
                hideEmptyOption
                name="holdInsertIntegration"
                width="100%"
                appearance="filled"
                disabled={isPendingSetHoldInsertIntegrationData}
                validation={{
                  required: formErrorMsg.isRequired,
                }}
                onChange={onChangeHoldIntegration}
                helperText={getHoldIntegrationMessage()}
              />
            </SettingsSubSection>
            <ButtonMainNew
              testcy="insert-save"
              sizeType="medium"
              colorType="primaryLight"
              width="200px"
              onClick={() => {
                setIsDialogOpen(true);
              }}
            >
              Zapisz dane InsERT
            </ButtonMainNew>
          </SettingsSection>
        </PageLoaderWrapper>
      </SettingsTab>
    </>
  );
};
