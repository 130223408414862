import axios, { AxiosRequestConfig } from 'axios';
import { appConfig } from 'src/appConfig';
import { userManager } from './oidc/userManager';

const httpClient = axios.create({
  baseURL: appConfig.url,
  withCredentials: true,
});

httpClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const user = await userManager.getUser();
  const token = user?.access_token;

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

httpClient.interceptors.response.use(
  res => {
    return res;
  },
  error => {
    if (error.response.status === 401) {
      userManager.signoutRedirect();
    }
    return Promise.reject(error);
  }
);

export { httpClient };
