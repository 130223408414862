interface useOpenInNewTabInterface {
  openInNewTab: (url: string) => void;
}

export const useOpenInNewTab = (): useOpenInNewTabInterface => {
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.opener = null;
  };

  return {
    openInNewTab,
  };
};
