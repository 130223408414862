import { useApiMutation } from '@hooks';
import { snackbarMessagesHandler } from '@utils';
import { userManager } from '@lib';

interface DeleteAccountInterface {
  deleteAccount: () => void;
}

export const useDeleteAccount = (): DeleteAccountInterface => {
  const { mutate } = useApiMutation('deleteMyAccount', {
    onSuccess: () => {
      snackbarMessagesHandler.accountDeleted();
      userManager.signoutRedirect();
    },
  });

  return {
    deleteAccount: () => mutate(null as any),
  };
};
