import React from 'react';
import { formatNumberToCurrency } from '@utils';
import './thePrice.scoped.scss';

export const ThePrice = ({ netValue, grossValue, bold = false, showCurrency = false, showLabels = false }) => {
  return (
    <>
      <div className={`price-gross-text ${bold && 'bold'}`}>
        {showLabels && 'Brutto: '}
        {formatNumberToCurrency(grossValue)}
        {showCurrency && ' PLN'}
      </div>
      <div className={`price-net-text ${bold && 'bold'}`}>
        {showLabels && 'Netto: '}
        {formatNumberToCurrency(netValue)}
        {showCurrency && ' PLN'}
      </div>
    </>
  );
};
