export type ChartDataPoint = { x: string; y: number | null };
export type ChartSeries = {
  name: string;
  data: ChartDataPoint[];
};

export type IncomesChartAPIData = {
  dates: string[];
  paid: number[];
  unpaid: number[];
};
export type ProfitLossChartAPIData = {
  dates: string[];
  profits: number[];
  losses: number[];
};

export type ReceivablesPayablesObject = {
  open: number;
  overdue: number;
  total: number;
};
export type ReceivablesPayablesChartAPIData = {
  receivables: ReceivablesPayablesObject;
  payables: ReceivablesPayablesObject;
};

export type SingleBarChartData = {
  name?: string;
  class?: string;
  value: number;
  order: number;
}[];

function mapSingleSeries(series: number[], dates: string[], dateOptions: Intl.DateTimeFormatOptions): ChartDataPoint[] {
  return series.map((v, i) => {
    return {
      x: new Date(dates[i]).toLocaleDateString('pl-PL', dateOptions),
      y: v,
    };
  });
}

export const mapIncomesChartData = (data: IncomesChartAPIData): ChartSeries[] => {
  return [
    {
      name: 'Zapłacone',
      data: mapSingleSeries(data.paid, data.dates, { day: '2-digit', month: '2-digit', year: 'numeric' }),
    },
    {
      name: 'Oczekujące',
      data: mapSingleSeries(data.unpaid, data.dates, { day: '2-digit', month: '2-digit', year: 'numeric' }),
    },
  ];
};
export const mapProfitLossChartData = (data: ProfitLossChartAPIData): ChartSeries[] => {
  return [
    {
      name: 'Przychody',
      data: mapSingleSeries(data.profits, data.dates, { month: 'short', year: 'numeric' }),
    },
    {
      name: 'Koszty',
      data: mapSingleSeries(data.losses, data.dates, { month: 'short', year: 'numeric' }),
    },
  ];
};
export const mapReceivablesPayablesData = (data: ReceivablesPayablesChartAPIData): { receivables: SingleBarChartData; payables: SingleBarChartData } => {
  return {
    receivables: [
      {
        name: 'Oczekiwane',
        value: data.receivables.open,
        order: 1,
      },
      {
        name: 'Przeterminowane',
        value: data.receivables.overdue,
        order: 2,
      },
    ],
    payables: [
      {
        name: 'Oczekiwane',
        value: data.payables.open,
        order: 1,
      },
      {
        name: 'Przeterminowane',
        value: data.payables.overdue,
        order: 2,
      },
    ],
  };
};
