import { useRef, useState, useMemo, useLayoutEffect } from 'react';
import { scaleTime, scaleLinear } from '@visx/scale';
import { max, min, extent } from '@visx/vendor/d3-array';

type UseChartParametersProps<TData> = {
  containerWidth: number;
  containerHeight: number;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  data: TData[];
  xAccessor: (d: TData) => Date;
  yAccessor: (d: TData) => number;
};

export const useChartParameters = <TData>({ containerWidth, containerHeight, margin, data, xAccessor, yAccessor }: UseChartParametersProps<TData>) => {
  const containerRef = useRef<SVGSVGElement>(null);
  const [maxLabelWidth, setMaxLabelWidth] = useState(0);

  const chartAreaHeight = containerHeight - margin.top - margin.bottom;
  const chartAreaWidth = containerWidth - margin.left - margin.right - maxLabelWidth;

  // bounds
  const xMax = Math.max(chartAreaWidth, 0);
  const yMax = Math.max(chartAreaHeight, 0);

  // scales
  const xScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xMax],
        domain: extent(data, xAccessor) as [Date, Date],
      }),
    [xMax, data]
  );
  const yScale = useMemo(() => {
    const maxValue = max(data, yAccessor);
    const minValue = min(data, yAccessor);

    // Należy rozszerzyć zakres osi Y tak, żeby mieć odpowiedni odstęp od górnej i dolnej krawędzi wykresu.
    let marginOfSafety = (maxValue - minValue) * 0.1;

    if (marginOfSafety === 0) {
      marginOfSafety = 50;
    }

    return scaleLinear<number>({
      range: [yMax, 0],
      domain: [minValue - marginOfSafety, maxValue + marginOfSafety || 0],
      nice: 4,
    });
  }, [yMax, data]);

  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const labels = containerRef.current.getElementsByClassName('axis-left-tick-label');
    let maxWidth = 0;

    Array.from(labels).forEach((label: SVGTextElement) => {
      const boundingRect = label.getBoundingClientRect();

      if (boundingRect.width > maxWidth) {
        maxWidth = boundingRect.width;
      }
    });

    const epsilon = 5;
    if (maxWidth < maxLabelWidth - epsilon || maxWidth > maxLabelWidth + epsilon) {
      setMaxLabelWidth(maxWidth);
    }
  }, [yScale, containerRef.current]);

  return {
    containerRef,
    chartAreaHeight: yMax,
    chartAreaWidth: xMax,
    chartLeftMargin: margin.left + maxLabelWidth,
    labelOffset: maxLabelWidth,
    xScale,
    yScale,
  };
};
