import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CloseIconNew } from 'src/components/icons';
import './tagsInput.scss';

interface Props {
  tag: string;
  handleDelete: (value: string) => void;
}

export const Tags = ({ tag, handleDelete }: Props) => {
  return (
    <Box
      sx={{
        background: '#FFFFFF',
        borderRadius: '48px',
        marginRight: '8px',
        padding: '8px',
        justifyContent: 'center',
        alignContent: 'center',
        color: '#101013',
      }}
    >
      <Stack direction="row" gap={1}>
        <Typography>{tag}</Typography>
        <div
          className="close-icon-wrapper"
          onClick={() => {
            handleDelete(tag);
          }}
        >
          <CloseIconNew size={16} />
        </div>
      </Stack>
    </Box>
  );
};
