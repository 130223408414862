import { initUserManager } from 'oidc-react';
import { useUserStore } from '@store';
import { appConfig } from 'src/appConfig';

export const userManager = initUserManager(appConfig.oidc);

userManager.events.addUserLoaded(user => {
  useUserStore.getState().setUserData(user);
});

userManager.events.addUserUnloaded(() => {
  useUserStore.getState().setUserData(null);
});

userManager.getUser().then(user => {
  useUserStore.getState().setUserData(user);
});
