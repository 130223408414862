import { httpClient } from '@lib';
import { AxiosResponse } from 'axios';

export interface GetNotificationsParams {
  pageNumber?: number;
  pageSize?: number;
}

export interface NotificationsList {
  data?: NotificationsListData[];
  pageNumber: number;
  totalPageCount: number;
  pageSize: number;
  totalCount: number;
}

export interface NotificationsListData {
  message: string;
  time: string;
}

export const getNotifications = async (params: GetNotificationsParams = {}) => {
  try {
    const response: AxiosResponse<NotificationsList> = await httpClient.get('/Notifications', { params });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
