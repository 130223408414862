import React from 'react';
import { CompanyIcon } from '@components';
import { useContractorStore } from '@store';
import { TheBreadcrumbs } from './components/theBreadcrumbs/theBreadcrumbs';
import { MenuAvatars } from './components/menuAvatars/menuAvatars';

import './header.scoped.scss';

export const Header = () => {
  const { ownerMyCompanyData } = useContractorStore();

  return (
    <div className="header">
      <div className="header-group">
        <span className="owner-company-name">Cashcloud app.</span>
        <div className="company-title">
          <CompanyIcon />
          <p className="company-name">{ownerMyCompanyData?.name}</p>
        </div>
        <TheBreadcrumbs />
      </div>
      <MenuAvatars />
    </div>
  );
};
