import React from 'react';
import { TheDialog } from '@components';
import { OwnerContractorForm, SharedInvoiceDetails } from '@features';
import { BankAccountData } from '@interfaces';
import { BankAccountForm } from '../bankAccountForm/bankAccountForm';
import './modalAndDialogs.scoped.scss';

interface Props {
  id: string;
  openContractorModal: boolean;
  openBankAccountModal: boolean;
  closeContractorModal: () => void;
  contractorToEditId: string;
  cancelConfirmationDialogOpen: boolean;
  onCancelConfirmationDialogClose: (agree: boolean) => void;
  onCreatedContractor: (closeModal: boolean, id: string, name: string) => void;
  setOpenBankAccountModal: (v: boolean) => void;
  onAddNewBankAccount: (v: BankAccountData) => void;
  contractorSearchNip: string;
  contractorSearchName: string;
  isContractorSavedInDataBase: boolean;
  addNewContractor: boolean;
  onContractorModified: () => void;
  setUpdateData: (v: boolean) => void;
  updateData: boolean;
}

export const ModalAndDialogs = ({
  id,
  openContractorModal,
  openBankAccountModal,
  closeContractorModal,
  contractorToEditId,
  cancelConfirmationDialogOpen,
  onCancelConfirmationDialogClose,
  onCreatedContractor,
  setOpenBankAccountModal,
  onAddNewBankAccount,
  contractorSearchNip,
  contractorSearchName,
  isContractorSavedInDataBase,
  addNewContractor,
  onContractorModified,
  setUpdateData,
  updateData,
}: Props) => {
  return (
    <>
      <SharedInvoiceDetails
        invoiceId={id}
        refreshParentData={() => {
          setUpdateData(false);
        }}
        updateData={updateData}
        type="invoice"
      />
      <TheDialog
        testcy="owner-invoice-leave-confirmation"
        open={cancelConfirmationDialogOpen}
        onClose={onCancelConfirmationDialogClose}
        approveButtonText="Zapisz zmiany"
        rejectButtonText="Wyjdź"
        title="Czy chcesz opuścić formularz bez zapisania zmian?"
      >
        <></>
      </TheDialog>

      <TheDialog
        testcy="owner-invoice-contractor"
        open={openContractorModal}
        onClose={closeContractorModal}
        className="modal-medium-size-g"
        fullWidth
        formDialog
        title={`${addNewContractor ? 'Dodaj kontrahenta' : 'Edycja danych kontrahenta'}`}
      >
        <OwnerContractorForm
          onCreatedContractor={onCreatedContractor}
          isNested={true}
          defaultNip={contractorSearchNip}
          contractorDefaultId={contractorToEditId}
          defaultName={contractorSearchName}
          isContractorSavedInDataBase={isContractorSavedInDataBase}
          addNewContractor={addNewContractor}
          showToggleSaveToDataBase={true}
          onContractorModified={onContractorModified}
          dialogView={true}
        />
      </TheDialog>
      <TheDialog
        testcy="owner-invoice-bank-account"
        open={openBankAccountModal}
        onClose={() => {
          setOpenBankAccountModal(false);
        }}
        title="Dodaj konto bankowe"
      >
        <BankAccountForm setCloseDialog={setOpenBankAccountModal} onAddNewBankAccount={onAddNewBankAccount} />
      </TheDialog>
    </>
  );
};
