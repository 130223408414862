import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import './paymentSummaryTable.scoped.scss';

type Props = {
  leftToPay: string;
  amountPaid: string;
};

export const PaymentSummaryTable = ({ leftToPay, amountPaid }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className="bold">Zapłacono</TableCell>
          <TableCell className="bold">Do zapłaty</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <p>{amountPaid}</p>
          </TableCell>
          <TableCell className="bold">
            <p>{leftToPay}</p>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
