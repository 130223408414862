import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
  size?: string | number;
}

export const ErrorCircleIcon = ({ colorType = '', size = 24 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 10C17.5 5.85757 14.1424 2.5 10 2.5C5.85757 2.5 2.5 5.85757 2.5 10C2.5 14.1416 5.85757 17.5 10 17.5C14.1424 17.5 17.5 14.1416 17.5 10Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.99677 6.92236V10.5053M9.99993 13.1183V13.0786" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
