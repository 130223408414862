import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormReturn, useFormState } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';
import { DatePicker, NumberInput, SelectDayButtonsToolbar, SpecialInput, Statuses, TheAccordion, TheSelect } from '@components';
import { dateFormatter } from '@lib';
import {
  focusOnNextFormInput,
  focusOnNextFormInput_FORCE,
  formatNumberToCurrency,
  formErrorMsg,
  InvoiceStatusType,
  PaymentType,
  standardValidators,
  stringToNumberSecondOptions,
} from '@utils';
import { Option } from '@interfaces';
import './paymentSection.scoped.scss';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  useFormFeatures: Partial<UseFormReturn<FieldValues>>;
  paymentTypeOptions: Option[];
  amountPaid: string;
  leftToPaid: string;
  grossSummary: string;
  isPaid: boolean;
  setPaymentTypeCurrent: (v: PaymentType) => void;
  setIsBlurred: (v: boolean) => void;
}

export const PaymentSection = ({
  open,
  setOpen,
  useFormFeatures,
  paymentTypeOptions,
  amountPaid,
  leftToPaid,
  grossSummary,
  isPaid,
  setPaymentTypeCurrent,
  setIsBlurred,
}: Props) => {
  const [openPicker, setOpenPicker] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date>(null);
  const { resetField, clearErrors, control, trigger, watch, setValue } = useFormFeatures;
  const {
    errors: { dueDate: dueDateErr, paymentType: paymentTypeErr, bankNumber: bankNumberErr, paidAmount: paidAmountErr },
  } = useFormState({ control });

  const invoiceDate = watch('invoiceDate');
  const paymentType = watch('paymentType');
  const paidAmount = watch('paidAmount');

  useEffect(() => {
    const errors = [dueDateErr, paymentTypeErr, bankNumberErr, paidAmountErr];
    const isInvalid = errors.some(e => !!e);
    if (isInvalid && !open) setOpen(true);
  }, [dueDateErr, paymentTypeErr, bankNumberErr, paidAmountErr, open, setOpen]);

  const onCalendarToolbarOptionClick = (day: number) => {
    const selectedDate = dateFormatter.addDaysToDate(invoiceDate || new Date(), day);
    setValue('dueDate', selectedDate);
    trigger('dueDate');
    setOpenPicker(false);
    setSelectedDay(selectedDate);
  };

  const onChangeHandle = (value: string) => {
    const issueDateParsed = dateFormatter.getDateObjectFromString(value);
    setSelectedDay(issueDateParsed);
  };

  return (
    <TheAccordion testcy="costs-form-payment-accordion" open={open} setOpen={setOpen} id="payment-data-invoice-form" title="Płatność">
      <div className="payment-section-wrapper-main">
        <div className="payment-section-wrapper">
          <div className="data-picker-and-payment-type-wrapper">
            <TheSelect
              testcy="costs-form-payment-type"
              control={control}
              name="paymentType"
              label="Forma płatności"
              width="300px"
              options={paymentTypeOptions}
              hideEmptyOption
              renderLabel={option => {
                if (option.value === PaymentType.Paid) return <Statuses status={InvoiceStatusType.Paid} />;
                return option.label;
              }}
              validation={{
                onChange: (e: SelectChangeEvent<PaymentType>) => {
                  const paymentType = e.target.value as PaymentType;
                  if (!paymentType) {
                    resetField('bankAccount');
                    resetField('dueDate');
                  }
                  setPaymentTypeCurrent(paymentType);
                  const element = document.getElementById('dueDate') as HTMLInputElement;
                  element?.select();
                  clearErrors('dueDate');
                },
                required: formErrorMsg.isRequired,
              }}
              onBlur={e => {
                setTimeout(() => {
                  focusOnNextFormInput_FORCE(`dueDate`);
                }, 0);
              }}
            />

            {paymentType && !isPaid && (
              <>
                <DatePicker
                  testcy="costs-form-due-date"
                  control={control}
                  name="dueDate"
                  onChange={onChangeHandle}
                  openPicker={openPicker}
                  setOpenPicker={setOpenPicker}
                  trigger={trigger}
                  clearErrors={clearErrors}
                  label="Termin zapłaty"
                  width="300px"
                  allowSuggestions
                  validation={standardValidators.requiredDate}
                  toolbarComponent={
                    <SelectDayButtonsToolbar
                      testcy="costs-form-due-date"
                      daysList={[1, 3, 7, 14, 30]}
                      onSelectDay={onCalendarToolbarOptionClick}
                      dayToDiff={new Date()}
                      selectedValue={selectedDay}
                    />
                  }
                  onKeyDown={event => {
                    focusOnNextFormInput(event, 3);
                  }}
                />
              </>
            )}
            {!isPaid && (
              <NumberInput
                testcy="costs-form-paid-amount"
                control={control}
                name="paidAmount"
                width="100%"
                maxDecimals={2}
                float
                negative={stringToNumberSecondOptions(grossSummary) < 0}
                label="Zapłacono"
                onKeyDown={event => {
                  focusOnNextFormInput(event, `bankAccount`);
                }}
                onFocus={() => {
                  setIsBlurred(false);
                }}
                onBlur={() => {
                  setIsBlurred(true);
                  setValue('paidAmount', formatNumberToCurrency(stringToNumberSecondOptions(paidAmount)));
                }}
                validation={{
                  required: formErrorMsg.isRequired,
                  validate: {
                    validAmount: standardValidators.validPaymentAmount(grossSummary, true),
                  },
                }}
                placeholder="0,00"
                defaultValue="0,00"
              />
            )}
          </div>
          {(paymentType === 'Transfer' || paymentType === 'TransferSplitPayment') && !isPaid && (
            <div className="form-bank-account-row">
              <div className="input-wrapper">
                <SpecialInput
                  testcy="costs-form-bank-account"
                  name="bankAccount"
                  control={control}
                  label={`Numer konta`}
                  type="bank"
                  width="100%"
                  validation={{
                    required: formErrorMsg.isRequired,
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="invoice-payment-summary">
          <div className="data-area">
            <div></div>
            <div className="invoice-payment-details">
              <div className="invoice-details-column">
                <p>Zapłacono (PLN):</p>
                <p className="value value-bold" data-testcy="paid-amount">
                  {amountPaid || '0,00'}
                </p>
              </div>
              <div className="invoice-details-column">
                <p>Kwota do zapłaty (PLN):</p>
                <p className="value value-bold" data-testcy="left-to-pay">
                  {leftToPaid}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TheAccordion>
  );
};
