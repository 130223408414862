import { ButtonMain, ListBox, LoaderIcon, RadioButtonGroup, SpecialInput, TextInputNew, TheSelect, TheSwitch, Toggle } from '@components';
import {
  BusinessEntity,
  businessEntityOptions,
  focusOnNextFormInput,
  focusOnNextFormInput_FORCE,
  formErrorMsg,
  regCountry,
  scrollToFirstError,
  standardValidators,
} from '@utils';
import React, { useEffect, useState } from 'react';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { ContractorFormData } from '../../ownerContractorForm.utils';
import { CountryPrefix, getCountries } from './contractorForm.utils';
import './contractorForm.scoped.scss';
import './contractorForm.scss';
import { countryValidatorMessage, isNipRequired } from 'src/utils/formHelpers';

interface Props {
  errors: FieldErrors<ContractorFormData>;
  isCompanyFormType: boolean;
  isSavePending: boolean;
  isNipEnabled: boolean;
  isEdit: boolean;
  possibilityToOnlyGetData: boolean;
  showToggleSaveToDataBase?: boolean;
  emails: string[];
  fetchGusData: () => void;
  handleRadioChange: (value: string) => void;
  useFormProps: UseFormReturn<ContractorFormData, any>;
  onSubmit: (data: ContractorFormData) => void;
  dialogView?: boolean;
}

const importAll = r => {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
};
const images = importAll(require.context('../../../../../assets/countryIcons', false, /\.(svg)$/));

export const ContractorForm = ({
  errors,
  isCompanyFormType,
  isSavePending,
  isNipEnabled,
  isEdit,
  possibilityToOnlyGetData,
  showToggleSaveToDataBase,
  emails,
  fetchGusData,
  handleRadioChange,
  useFormProps,
  onSubmit,
  dialogView,
}: Props) => {
  const { handleSubmit, getFieldState, getValues, setValue, trigger, watch, resetField } = useFormProps;
  const [isCountryOtherBeforeIsCompanyChange, setIsCountryOtherBeforeIsCompanyChange] = useState(false);
  const [lastUpdatedZipCodeType, setLastUpdatedZipCodeType] = useState<CountryPrefix>(null);
  const [isNipInvalid, setIsNipInvalid] = useState(false);

  const countryWatch = watch('country');
  const nipWatch = watch('nip');
  const companyOrPerson = watch('companyOrPerson');

  const zipCode = watch('zipCode');
  useEffect(() => {
    if (!zipCode || getFieldState('zipCode').isTouched) return;
    setValue('foreignZipCode', zipCode, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
    trigger('zipCode');
  }, [zipCode]);

  //detect foreign
  const foreignZipCode = watch('foreignZipCode');
  useEffect(() => {
    if (!foreignZipCode || getFieldState('foreignZipCode').isTouched) return;
    setValue('zipCode', foreignZipCode, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
    trigger('foreignZipCode');
  }, [foreignZipCode]);

  const nipState = countryWatch !== CountryPrefix.Poland ? getFieldState('foreignTaxNumber') : getFieldState('nip');
  useEffect(() => {
    setIsNipInvalid(nipState.error == undefined);
  }, [nipState]);

  const onCompanyOrPersonChange = (value: string): void => {
    setIsCountryOtherBeforeIsCompanyChange(countryWatch !== CountryPrefix.Poland);

    if (value === 'person' && countryWatch !== CountryPrefix.Poland) {
      setValue('country', CountryPrefix.Poland);
    }

    handleRadioChange(value);
  };

  const onSubmitButtonClick = () => {
    if (countryWatch !== CountryPrefix.Poland) {
      if (lastUpdatedZipCodeType === CountryPrefix.Poland) {
        setValue('foreignZipCode', getValues('zipCode'), { shouldTouch: true, shouldDirty: true });
      }
    } else {
      if (lastUpdatedZipCodeType !== CountryPrefix.Poland) {
        setValue('zipCode', getValues('foreignZipCode'), { shouldTouch: true, shouldDirty: true });
      }
    }

    setTimeout(async () => {
      const isValidPL = await trigger('zipCode');
      const isValidOther = await trigger('foreignZipCode');

      if ((countryWatch !== CountryPrefix.Poland ? !isValidOther : !isValidPL) || Object.keys(errors).length > 0) {
        scrollToFirstError(Object.keys(errors));
      }
      handleSubmit(onSubmit)();
    }, 0);
  };

  return (
    <form className={`w-100-g contractor-form ${dialogView ? 'dialog-view' : 'form-page'}`}>
      <div className="form-wrapper">
        <div className="form-column">
          <div className="sub-title-g">Dane kontrahenta</div>
          <TextInputNew
            testcy="company-name-full"
            name="name"
            label={isCompanyFormType ? 'Pełna nazwa firmy' : 'Imię i nazwisko'}
            width="100%"
            appearance={'light'}
            validation={standardValidators.requiredMaxNumber(255)}
            onKeyUp={event => {
              focusOnNextFormInput(event, 2);
            }}
          />
          <TextInputNew
            testcy="company-name-short"
            validation={{
              required: false,
              maxLength: {
                value: 100,
                message: formErrorMsg.maxLength(100),
              },
            }}
            name="shortName"
            label="Nazwa skrócona"
            width="100%"
            appearance={'light'}
            onKeyUp={event => {
              if (companyOrPerson === BusinessEntity.COMPANY) {
                isEdit ? focusOnNextFormInput(event, 7) : focusOnNextFormInput(event, 'country');
              } else {
                focusOnNextFormInput(event, 4);
              }
            }}
          />
          {isCompanyFormType && (
            <>
              <div className="text-input-with-btn-row">
                <div className="inputs">
                  <TheSelect
                    testcy="company-country"
                    name="country"
                    label="Prefiks kraju"
                    width="125px"
                    options={getCountries()}
                    defaultValue={CountryPrefix.Poland}
                    disabled={!isNipEnabled || isEdit}
                    onChange={() => {
                      resetField('nip');
                      resetField('foreignTaxNumber');
                    }}
                    appearance={'light'}
                    validation={{
                      required: formErrorMsg.isRequired,
                    }}
                    hideEmptyOption
                    onBlur={value => {
                      setTimeout(() => {
                        focusOnNextFormInput_FORCE(`${value.nativeEvent.target.outerText.includes('Polska') ? 'nip' : 'foreignTaxNumber'}`);
                      }, 0);
                    }}
                    flags={images}
                  />
                  <div className="input">
                    {countryWatch === CountryPrefix.Poland ? (
                      <SpecialInput
                        testcy="company-nip"
                        name="nip"
                        label="Numer NIP"
                        width="100%"
                        type="nip"
                        disabled={!isNipEnabled || isEdit}
                        appearance={'light'}
                        validation={{
                          required: formErrorMsg.isRequired,
                        }}
                        onKeyDown={event => {
                          focusOnNextFormInput(event, 3);
                        }}
                      />
                    ) : (
                      <SpecialInput
                        testcy="company-nip"
                        name="foreignTaxNumber"
                        label="Numer NIP"
                        width="100%"
                        type="foreign-tax-number"
                        formatFn={value => value.toUpperCase()}
                        disabled={!isNipEnabled || isEdit}
                        onKeyDown={event => {
                          focusOnNextFormInput(event, 2);
                        }}
                        appearance={'light'}
                        validation={{
                          required: isNipRequired(watch('country')) ? formErrorMsg.isRequired : false,
                          pattern: {
                            value: regCountry(watch('country')),
                            message: countryValidatorMessage(countryWatch),
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
                {countryWatch === CountryPrefix.Poland && (
                  <ButtonMain
                    testcy="fetch-gus-data"
                    content="Dodaj dane z GUS"
                    sizeType="extra-small"
                    isDisabled={nipWatch.length !== 10 || !isNipInvalid || !isNipEnabled}
                    onClick={fetchGusData}
                  />
                )}
              </div>
              <SpecialInput
                testcy="company-regon"
                name="regon"
                label="Regon"
                width="100%"
                type="regon"
                appearance={'light'}
                onKeyDown={event => {
                  focusOnNextFormInput(event, 4);
                }}
              />
            </>
          )}
          <RadioButtonGroup
            testcy="company-type"
            name="companyOrPerson"
            radioBtnList={businessEntityOptions.map(x => ({
              ...x,
              disabled: isEdit || !isNipEnabled,
            }))}
            onChangeHandle={onCompanyOrPersonChange}
            isHorizontal={true}
            defaultValue={BusinessEntity.COMPANY}
            margin="0 0 0 5px"
          />
        </div>
        <div className="form-column">
          <div className="sub-title-g">Adres</div>
          <TextInputNew
            testcy="street"
            name="street"
            label="Ulica i numer"
            width="100%"
            appearance={'light'}
            validation={standardValidators.requiredMaxNumber(100)}
            onKeyDown={event => {
              focusOnNextFormInput(event, 2);
            }}
          />
          <TextInputNew
            testcy="city"
            name="city"
            label="Miejscowość"
            width="100%"
            appearance={'light'}
            validation={standardValidators.requiredMaxNumber(100)}
            onKeyDown={event => {
              focusOnNextFormInput(event, 2);
            }}
          />
          {countryWatch === CountryPrefix.Poland ? (
            <SpecialInput
              testcy="zip-code"
              name="zipCode"
              label="Kod pocztowy"
              width="100%"
              type="postalcode"
              appearance={'light'}
              validation={{
                required: formErrorMsg.isRequired,
              }}
              onChange={() => setLastUpdatedZipCodeType(CountryPrefix.Poland)}
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
          ) : (
            <SpecialInput
              testcy="zip-code"
              name="foreignZipCode"
              label="Kod pocztowy"
              width="100%"
              type="foreign-postalcode"
              appearance={'light'}
              validation={{
                required: formErrorMsg.isRequired,
              }}
              onChange={() => setLastUpdatedZipCodeType(CountryPrefix.Other)}
              onKeyDown={event => {
                focusOnNextFormInput(event, 2);
              }}
            />
          )}
          <div className="sub-title-g sub-title-last">Adresy e-Mail</div>
          <ListBox
            testcy="email"
            items={emails}
            itemLabel="E-Mail"
            addButtonText="DODAJ NOWY E-MAIL"
            inputType="email"
            propertyNamePrefix="emails"
            fieldNameFactory={index => `emails.${index}`}
            inputValidation={{
              required: false,
              maxLength: {
                value: 254,
                message: formErrorMsg.maxLength(254),
              },
            }}
            dialogView={dialogView}
          />
        </div>

        {possibilityToOnlyGetData && (
          <div className="toggle-btn-wrapper">
            <div>{'Wykorzystaj jednorazowo'}</div>
            <div>
              <Toggle testcy="use-once" name="saveContractor" />
            </div>
            <div>{`Zapisz na liście kontrahentów`}</div>
          </div>
        )}
        {!possibilityToOnlyGetData && showToggleSaveToDataBase && (
          <div className="toggle-btn-wrapper">
            <div>{'Wykorzystaj jednorazowo'}</div>
            <div>
              <Toggle testcy="use-once-alt" name="saveContractor" />
            </div>
            <div>{`Zapisz na liście kontrahentów`}</div>
          </div>
        )}
      </div>

      <div className="form-footer">
        {isSavePending && (
          <div className="loader-wrapper">
            <LoaderIcon size="small" />
          </div>
        )}
        <div className={`${isSavePending && 'button-wrapper'}`}>
          <ButtonMain
            testcy="save-changes"
            onClick={onSubmitButtonClick}
            type="button"
            content={`${isEdit ? 'Zapisz zmiany' : 'Dodaj kontrahenta'}`}
            width="260px"
            isDisabled={isSavePending}
          />
        </div>
      </div>
    </form>
  );
};
// dialogView
