import { getMyCompany } from '@services';
import { useContractorStore, useUserStore } from '@store';
import { handleApiError } from '@utils';
import { useEffect } from 'react';

export const useMyCompanyInitializer = () => {
  const { setOwnerMyCompanyData } = useContractorStore();
  const { userData } = useUserStore();

  useEffect(() => {
    const fetchOwnerMyCompanyData = async () => {
      try {
        const companyData = await getMyCompany();
        setOwnerMyCompanyData(companyData);
      } catch (err) {
        handleApiError(err);
      }
    };

    if (userData) fetchOwnerMyCompanyData();
  }, [setOwnerMyCompanyData, userData]);
};
