import { httpClient } from '@lib';
import { AxiosResponse } from 'axios';
import { InvoiceNumberFormat, LegalPersonalityType, SortDirection, YesOrNo } from '@utils';
import { Address, BankAccountData, UserBasicData, SuffixInvoiceNumberData } from '@interfaces';

export interface CompanyData {
  id?: number;
  nip: string;
  regon: string;
  name: string;
  owner: UserBasicData;
  address: Address;
  phoneNumber: string;
  email: string;
  fax: string;
  allowKsef: boolean;
  legalPersonality: LegalPersonalityType | null;
  bankAccounts: BankAccountData[];
  invoiceNumberSuffixes: SuffixInvoiceNumberData[];
  invoiceNumberFormat: InvoiceNumberFormat;
  isDummy: boolean;
  accountingCredentials?: { database: string; username: string; password: string };
  hasEntityCredentials?: boolean;
  hasAccountingClients?: boolean;
  accountingId?: string;
  accountingCompany?: { email: string; name: string };
  hasSuffixes: boolean;
}

export type CompaniesOrderOptions = 'nip' | 'name' | 'owner' | 'address' | 'email';

export type InviteCompanyResult = 'Success' | 'Failure' | 'AlreadyExists' | 'AlreadyAccepted';

export interface GetCompaniesParams {
  textFilter?: string;
  direction?: SortDirection;
  orderBy?: CompaniesOrderOptions;
  pageNumber?: number;
  pageSize?: number;
}

export interface CompanyListData {
  id: number;
  nip: string;
  name: string;
  owner: string;
  address: string;
  phoneNumber: string;
  email: string;
  allowKsef: boolean;
}

export interface CompanyList {
  data: CompanyListData[];
  pageNumber: number;
  totalPageCount: number;
  pageSize: number;
  totalCount: number;
}

export interface MainAccount {
  accountNumber: string;
}

export interface InsertData {
  accountingCredentials: {
    dataBase?: string;
    userName?: string;
    password?: string;
  };
}

export interface InsertGetData {
  database: string;
  username: string;
  password: string;
}

export const createNewCompany = async (companyData: CompanyData) => {
  try {
    const response: AxiosResponse = await httpClient.post('/Companies', companyData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getCompanies = async (params: GetCompaniesParams = {}) => {
  try {
    const response: AxiosResponse<CompanyList> = await httpClient.get('/Companies', { params });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getCompanyById = async (id: string) => {
  try {
    const response: AxiosResponse<CompanyData> = await httpClient.get(`/Companies/${id}`);
    return response.data;
  } catch (e) {
    throw e?.response;
  }
};

export const updateCompany = async (companyData: CompanyData, id: string) => {
  try {
    const response: AxiosResponse<CompanyData> = await httpClient.put(`/Companies/${id}`, companyData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getCompaniesSuffixes = async (id: string) => {
  try {
    const response: AxiosResponse<string[]> = await httpClient.get(`/Companies/${id}/InvoiceNumberSuffixes`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getMyCompany = async () => {
  try {
    const response: AxiosResponse<CompanyData> = await httpClient.get(`/Companies/MyCompany`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const updateMyCompany = async (companyData: CompanyData) => {
  try {
    const response: AxiosResponse<CompanyData> = await httpClient.put(`/Companies/MyCompany`, companyData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const addBankAccount = async (bankAccountData: BankAccountData) => {
  try {
    const response: AxiosResponse<CompanyData> = await httpClient.post(`/Companies/MyCompany/Accounts`, bankAccountData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const setAccountAsMain = async (bankAccountData: MainAccount) => {
  try {
    const response = await httpClient.post(`/Companies/MyCompany/SetAccountAsMain`, bankAccountData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const updateAccountingCredentials = async (id: string, insertData: InsertData) => {
  try {
    const response = await httpClient.put(`/Companies/${id}/accountingCredentials`, insertData);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getAccountingCredentials = async (id: string) => {
  try {
    const response: AxiosResponse<InsertGetData> = await httpClient.get(`/Companies/${id}/accountingCredentials`);
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const setAccountingPermission = async (id: string) => {
  try {
    const response: AxiosResponse = await httpClient.post(`/Companies/accountingPermission`, id, { headers: { 'Content-Type': 'application/json' } });
    return response.data;
  } catch (e) {
    throw e?.response;
  }
};

export const inviteCompany = async (id: number) => {
  try {
    const response: AxiosResponse<InviteCompanyResult> = await httpClient.post(`/Companies/${id}/invite`);
    return response.data;
  } catch (e) {
    throw e?.response;
  }
};

export const deleteMyAccount = async () => {
  try {
    await httpClient.delete(`/Companies/MyCompany`);
  } catch (e) {
    throw e?.response?.data;
  }
};
