import { ButtonMain, ButtonTextNew, DatePicker, LoaderIcon, NumberInput, PageLoaderWrapper, SyncIcon, TheTableNew } from '@components';
import { IBaseForm } from '@interfaces';
import { Tooltip } from '@mui/material';
import { useTaxStore } from '@store';
import { formatNumberToCurrency, scrollToFirstError, standardValidators, taxName } from '@utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TheChip } from 'src/components/miscellaneous/theChip/theChip';
import { useApiMutation } from 'src/hooks/useApiMutation';
import { TaxValue, TaxValueTable } from 'src/services/taxes';
import { useDataSource } from './dataSource';
import './sharedTaxes.scoped.scss';
import { tableHeader, taxDataToTableRow, useSharedTaxesForm } from './sharedTaxesForm';
import { dateFormatter } from '@lib';

interface Props extends IBaseForm {}

export const SharedTaxes = (props: Props) => {
  const testcy = 'tax-table';
  const [checked, setChecked] = useState([]);
  const [rows, setRows] = useState([]);
  const { control, setValue, handleSubmit, trigger, clearErrors, watch } = props.formFeatures;
  const form = useSharedTaxesForm(props);
  const { setTaxValueSetManually } = useTaxStore();

  useEffect(() => {
    setTaxValueSetManually([]);
    setTimeout(() => {
      setValue('month', new Date());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routeParams = useParams();
  const month = watch('month');
  const { isFetching, isSaving, getTaxValues, taxValues, tableTaxValues } = useDataSource(routeParams.companyId && Number(routeParams.companyId), month);
  const isAccountant = !!routeParams.companyId;
  useEffect(() => {
    if (!month) return;

    getTaxValues();
  }, [getTaxValues, month]);

  useEffect(() => {
    let updatedList = [];
    if (taxValues?.length && tableTaxValues?.length) {
      createTableRow(tableTaxValues);
      taxValues[0].values.forEach(x => {
        if (x.value) {
          updatedList.push(x.taxDiscriminator);
        }
        setValue(`${x.taxDiscriminator}-value`, x.value !== null ? formatNumberToCurrency(x.value) : formatNumberToCurrency(x.accountingValue));
        setValue(`${x.taxDiscriminator}-date`, x.dueDate ? new Date(x.dueDate) : '');
      });
      setTaxValueSetManually(updatedList);
      setChecked(updatedList);
    }
  }, [taxValues, tableTaxValues]);

  const applyClasses = useCallback(
    (taxValues: TaxValue[]) => {
      const hasSyncIcon = taxValues?.some(tax => !tax.isPaid && isAccountant);
      return hasSyncIcon ? 'tax-value-input-paid' : '';
    },
    [taxValues, isAccountant]
  );

  const handleCheck = event => {
    let updatedList = [...checked];
    if (checked.includes(event.taxDiscriminator)) {
      setValue(`${event.taxDiscriminator}-value`, formatNumberToCurrency(event.accountingValue), { shouldTouch: true });
      const arr = updatedList.filter(x => x !== event.taxDiscriminator);
      updatedList = [...arr];
    } else {
      setValue(`${event.taxDiscriminator}-value`, formatNumberToCurrency(event.value), { shouldTouch: true });
      updatedList = [...checked, event.taxDiscriminator];
    }
    setTaxValueSetManually(updatedList);
    setChecked(updatedList);
  };

  const isChecked = useCallback(
    (event: TaxValue) => {
      return checked.includes(event.taxDiscriminator);
    },
    [checked]
  );

  const createTableRow = (data: TaxValueTable[]) => {
    const rows = data.map((dataRow: TaxValueTable, index: number) => {
      const transformedDataToRow = taxDataToTableRow(dataRow, index, month);
      return transformedDataToRow;
    });
    setRows([...rows]);
  };

  const helperText = (x: TaxValue) => {
    if (isChecked(x) && x.accountingValue !== null) {
      return routeParams.companyId && `Podatek zsynchronizowany z systemem księgowym ${formatNumberToCurrency(x.accountingValue)}`;
    } else if (x.accountingValue === null) {
      return routeParams.companyId ? 'Brak danych z systemu księgowego' : x.value === null && 'Kwota podatku nie jest uzupełniona';
    }
  };

  const { mutate, isLoading: isMarkingAsPaid } = useApiMutation('markTaxAsPaid', {
    onSuccess: () => {
      getTaxValues();
    },
  });

  const markAsPaid = (month: Date, taxDiscriminator: string) => {
    mutate({
      month,
      taxDiscriminator,
    });
  };

  return (
    <>
      <form
        onSubmit={
          !isAccountant
            ? () => {}
            : handleSubmit(
                f => form.onSubmit(f),
                e => {
                  if (Object.keys(e)) scrollToFirstError(Object.keys(e));
                }
              )
        }
      >
        <div className={`form-column ${isAccountant ? 'form-column-width-accountant standard-layout-spacing-g' : 'form-column-width'} `}>
          <PageLoaderWrapper isLoading={!taxValues?.length}>
            {isAccountant && (
              <>
                <div className={`month-picker ${isAccountant && 'month-picker-accountant'}`}>
                  <DatePicker
                    testcy="taxes-month"
                    control={control}
                    name="month"
                    trigger={trigger}
                    clearErrors={clearErrors}
                    label="Miesiąc"
                    views={['year', 'month']}
                    openTo={'month'}
                    inputFormat={'MM.yyyy'}
                    placeholder="mm.rrrr"
                    appearance="light"
                    validation={standardValidators.requiredDate}
                    width="220px"
                  />
                  {(isFetching || isMarkingAsPaid) && <LoaderIcon size="small" />}
                </div>
                {taxValues?.length
                  ? taxValues[0].values.map((x, i) => (
                      <div
                        key={x.taxDiscriminator}
                        className={`tax-value-input ${isAccountant && x.isPaid && 'month-picker-accountant'} ${applyClasses(taxValues[0].values)}`}
                      >
                        <div className={`synchronize-input-wrapper ${isAccountant && x.isPaid && 'synchronize-input-wrapper-paid'}`}>
                          {!x.isPaid && (
                            <Tooltip arrow title={!isChecked(x) ? 'Ustaw podatek końcowy' : 'Ustaw podatek zsynchronizowany z systemem księgowym'}>
                              <button
                                key={i}
                                type="button"
                                value={x.taxDiscriminator}
                                data-testcy={`action-toggle-sync`}
                                className={`tax-icon-button`}
                                onClick={e => {
                                  handleCheck(x);
                                }}
                              >
                                <SyncIcon colorType={'primary'} crossed={!isChecked(x)} />
                              </button>
                            </Tooltip>
                          )}
                          <NumberInput
                            testcy={`taxes-value-${taxName(x.taxDiscriminator)}`}
                            control={control}
                            name={`${x.taxDiscriminator}-value`}
                            label={taxName(x.taxDiscriminator)}
                            float
                            negative
                            readOnly={!isAccountant || x.isPaid}
                            maxDecimals={2}
                            placeholder={isAccountant ? '0,00' : null}
                            disabled={!isChecked(x) && isAccountant}
                            helperText={helperText(x)}
                            appearance="light"
                            width="220px"
                          />
                        </div>
                        <div>
                          <DatePicker
                            testcy={`taxes-date-${taxName(x.taxDiscriminator)}`}
                            control={control}
                            name={`${x.taxDiscriminator}-date`}
                            label={'Termin płatności ' + taxName(x.taxDiscriminator)}
                            readOnly={!isAccountant || x.isPaid}
                            clearErrors={clearErrors}
                            appearance="light"
                            validation={standardValidators.requiredDate}
                            placeholder={isAccountant ? 'Termin płatności' : null}
                            width="220px"
                          />
                        </div>

                        {((!!x.value && x.value >= 0) || (!!x.accountingValue && x.accountingValue >= 0)) && (
                          <div className="chip-wrapper">
                            {x.isPaid ? (
                              <TheChip label="Opłacony" variant="filled" color="success" />
                            ) : (
                              <TheChip label="Nieopłacony" variant="outlined" color="error" />
                            )}
                          </div>
                        )}
                      </div>
                    ))
                  : null}

                <div className="button-submit-wrapper">
                  <ButtonMain width="140px" testcy="taxes-save" type="submit" content={'Zapisz'} isDisabled={isSaving} />
                </div>
              </>
            )}
            {!isAccountant && taxValues?.length && (
              <div className="tax-wrapper">
                <DatePicker
                  testcy="taxes-month"
                  control={control}
                  name="month"
                  trigger={trigger}
                  clearErrors={clearErrors}
                  views={['year', 'month']}
                  openTo={'month'}
                  inputFormat={'MM.yyyy'}
                  placeholder="mm.rrrr"
                  appearance="light"
                  validation={standardValidators.requiredDate}
                  width="220px"
                />
                <div className="tax-lines-wrapper">
                  {taxValues[0].values.map((x, i) => (
                    <div className="lines">
                      <div className="tax-line">
                        <div className="tax-name">{taxName(x.taxDiscriminator)}</div>

                        <div className="tax-date">{dateFormatter.objectToString(new Date(x.dueDate), 'dd.MM.yyyy')}</div>
                        <div className="tax-value">{x.value !== null ? formatNumberToCurrency(x.value) : formatNumberToCurrency(x.accountingValue)}</div>
                      </div>
                      <div className="chip-and-button-wrapper">
                        {((!!x.value && x.value >= 0) || (!!x.accountingValue && x.accountingValue >= 0)) && (
                          <>
                            {x.isPaid ? (
                              <TheChip label="Opłacony" variant="filled" color="success" />
                            ) : (
                              <TheChip label="Nieopłacony" variant="outlined" color="error" />
                            )}
                            {!x.isPaid && (
                              <ButtonTextNew
                                fontSize="10px"
                                startIcons="tick"
                                iconSize="medium"
                                fontWeight={500}
                                testcy={`taxes-mark-as-paid-${x.taxDiscriminator.toLowerCase()}`}
                                content="Oznacz jako opłacony"
                                noDecoration
                                textTransform="none"
                                width="max-content"
                                color="blue"
                                onClick={() => markAsPaid(taxValues[0].month, x.taxDiscriminator)}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PageLoaderWrapper>
        </div>
      </form>
      <div className="tax-table-wrapper table-wrapper-g">
        <TheTableNew testcy={testcy} header={tableHeader} rows={rows} totalRows={12} showPagination={false} />
      </div>
    </>
  );
};
