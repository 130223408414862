import { LabeledAction, isNumberRegexp } from '@utils';
import { useContractorModalController } from './useContractorModalController';
import { useContractorSearch } from './useContractorSearch';
import { Option } from '@interfaces';

type UseContractorSectionProps = {
  defaultOptions: Option[];
  clientId: string;
  clientName: string;
};

export const useContractorSection = ({ defaultOptions, clientId, clientName }: UseContractorSectionProps) => {
  const search = useContractorSearch({
    defaultOptions,
    currentClientName: clientName,
  });
  const modalController = useContractorModalController();

  const isValidNip = (value: string) => isNumberRegexp.test(value) && value.length === 10;

  const calculateDefaultNip = () => {
    if (modalController.modalMode === 'add' && isValidNip(search.debouncedValue) && search.options && !search.options.length) return search.debouncedValue;
    if (modalController.modalMode === 'addWithNip' && isValidNip(search.value)) return search.value;
    return '';
  };

  const calculateDefaultName = () => {
    if (modalController.modalMode === 'add' && !isValidNip(search.debouncedValue) && search.options && !search.options.length) return search.debouncedValue;
    if (modalController.modalMode === 'addWithName') return search.value;
    return '';
  };

  const extendedActions: LabeledAction[] = [
    {
      label: search.value ? `Dodaj "${search.value}"` : 'Dodaj nowego kontrahenta',
      onClick: () => modalController.openModal('addWithName'),
      enabled: true,
      testcyTag: 'contractor-add-with-name',
    },
    {
      label: `Pobierz dane z GUS o NIPie "${search.value}"`,
      onClick: () => modalController.openModal('addWithNip'),
      enabled: isValidNip(search.value),
      testcyTag: 'contractor-add-with-nip',
    },
  ];

  const closeModal = () => {
    if (!clientId) search.setValue('');
    modalController.closeModal();
  };

  return {
    search,
    modalController: {
      ...modalController,
      closeModal,
    },
    formDefaults: {
      Id: modalController.modalMode === 'edit' ? clientId : '',
      Nip: calculateDefaultNip(),
      Name: calculateDefaultName(),
    },
    allowedActions: extendedActions,
  };
};
