import { useApiQuery } from '../useApiQuery';
import { mapReceivablesPayablesData } from '@utils';

const getBlankObject = () => ({ receivables: { open: 0, overdue: 0, total: 0 }, payables: { open: 0, overdue: 0, total: 0 } });

export const useReceivablesPayables = () => {
  const { data, isLoading } = useApiQuery('getReceivablesAndPayables', undefined, {
    select: response => response ?? getBlankObject(),
    keepPreviousData: true,
    placeholderData: getBlankObject(),
  });

  const chartData = mapReceivablesPayablesData(data);

  return {
    data,
    chartData,
    isLoading,
  };
};
