import { ChatComposer } from '@features';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { TheRouteObject } from '../types';

export const chat: TheRouteObject[] = [
  {
    index: true,
    element: <ChatComposer />,
  },
  {
    path: '*',
    element: <Navigate to="/chat" />,
  },
];
