export type TabId = 'income' | 'costs' | 'clients' | 'data' | 'taxes' | 'newcosts' | 'employees' | 'inbox';

export const tabsData = [
  {
    name: 'Przychody',
    id: 'income',
    isActive: true,
    width: 150,
  },
  {
    name: 'Kontrahenci',
    id: 'clients',
    width: 150,
  },
  {
    name: 'Podatki',
    id: 'taxes',
    width: 150,
  },
  {
    name: 'Koszty',
    id: 'costs',
    width: 150,
  },
  {
    name: 'Pracownicy',
    id: 'employees',
    width: 150,
  },
  {
    name: 'Dokumenty',
    id: 'inbox',
    width: 150,
  },
];
