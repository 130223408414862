export const FormElementAppearance = {
  Old: 'old',
  Primary: 'primary',
  Filled: 'filled',
  Light: 'light',
  LightRows: 'light-rows',
  Tag: 'tag',
} as const;
export type FormElementAppearance = typeof FormElementAppearance[keyof typeof FormElementAppearance];

export const FormElementSize = {
  Big: 'big',
  Medium: 'medium',
  Small: 'small',
} as const;
export type FormElementSize = typeof FormElementSize[keyof typeof FormElementSize];
