import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { CompanyData, ContractorData, ContractorsListData } from '@services';

interface ContractorStore {
  selectedContractor: ContractorData | null;
  selectedContractorId: string;
  setSelectedContractor: (data: ContractorData, contractorId: string) => void;
  ownerMyCompanyData: CompanyData;
  setOwnerMyCompanyData: (data: CompanyData) => void;
  setContractorsList: (data: ContractorsListData[]) => void;
  contractorsList: ContractorsListData[];
  hasAccountingClients: boolean | null;
  setHasAccountingClients: (data: boolean) => void;
  accountingIdCopyToClipboard: string;
  setAccountingIdCopyToClipboard: (data: string) => void;
}

export const useContractorStore = create<ContractorStore>(set => ({
  selectedContractor: null,
  selectedContractorId: '',
  setSelectedContractor: (data: ContractorData, id: string) => set(() => ({ selectedContractor: data, selectedContractorId: id })),
  ownerMyCompanyData: null,
  setOwnerMyCompanyData: (data: CompanyData) => set(() => ({ ownerMyCompanyData: data })),
  contractorsList: [],
  setContractorsList: (data: ContractorsListData[]) => set(() => ({ contractorsList: data })),
  hasAccountingClients: null,
  setHasAccountingClients: (data: boolean) => set(() => ({ hasAccountingClients: data })),
  accountingIdCopyToClipboard: '',
  setAccountingIdCopyToClipboard: (data: string) => set(() => ({ accountingIdCopyToClipboard: data })),
}));

if (process.env.NODE_ENV === 'development') mountStoreDevtool('ContractorStore', useContractorStore);
