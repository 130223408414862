import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

interface InternalAppStore {
  blurOut: boolean;
  setBlurOut: (v: boolean) => void;
  openModalList: string[];
  setOpenModalList: (add: boolean, id: string) => void;
  lastFetchedInvoiceFile: { file: Blob; id: string };
  setLastFetchedInvoiceFile: (file: Blob, id: string) => void;
}

export const useInternalAppStore = create<InternalAppStore>(set => ({
  blurOut: false,
  setBlurOut: (value: boolean) => set(() => ({ blurOut: value })),
  openModalList: [],
  setOpenModalList: (add: boolean, id: string) => {
    const currentList = useInternalAppStore.getState().openModalList;
    const updatedList = add && !currentList.includes(id) ? [...currentList, id] : currentList.filter(i => i !== id);
    set(() => ({ openModalList: updatedList }));
    useInternalAppStore.getState().setBlurOut(!!updatedList.length);
  },
  lastFetchedInvoiceFile: { file: null, id: null },
  setLastFetchedInvoiceFile: (file: Blob, id: string) => set(() => ({ lastFetchedInvoiceFile: { file, id } })),
}));

if (process.env.NODE_ENV === 'development') mountStoreDevtool('InternalAppStore', useInternalAppStore);
