import React, { useEffect, useState } from 'react';
import { DragoScrollWrapper, LoaderIcon, TabsButtonModal, useTabsButtonModalStore, useTabsButtonStore } from '@components';
import { formatFetchedDataToPdf, handleApiError } from '@utils';
import { useIsAllFalse } from '@hooks';
import { createInvoicePdfById, getCostDownloadImgById } from '@services';
import { CustomObject, RequestErrorMessage } from '@interfaces';
import { InvoiceTools, InvoicePdf, LinkedFiles } from './components';
import './sharedFilePreview.scoped.scss';
import { useCostStore, useInternalAppStore, useInvoiceStore } from '@store';
import { useGetInvoiceData } from '../sharedInvoiceDetails/hooks';
import { TabId, tabsData } from './sharedFilePreview.utils';
import { addHotjarEvent, dateFormatter } from '@lib';

interface Props {
  testcy: string;
  fileId: string;
  fileName: string;
  modalParentId?: string;
  arrangement?: 'center' | 'left';
  type: 'invoice' | 'cost';
  startZoom?: number;
  allowCopyText?: boolean;
  showTabs?: boolean;
  dateDuplicate?: Date;
}

const minZoom = 0.8;
const maxZoom = 2.5;
const zoomTick = 0.1;

export const SharedFilePreview = ({
  testcy,
  fileId,
  fileName,
  modalParentId = '',
  arrangement,
  type,
  startZoom = 2,
  allowCopyText = false,
  showTabs = false,
  dateDuplicate,
}: Props) => {
  const [isPendingInvoice, setIsPendingInvoice] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState('');
  const [imgFileUrl, setImgFileUrl] = useState('');
  const [blobFile, setBlobFile] = useState<Blob>(null);
  const [isLoadedImage, setIsLoadedImage] = useState(false);
  const [zoom, setZoom] = useState(startZoom);
  const [pdfPagesMax, setPdfPagesMax] = useState(1);
  const [pdfCurrentPage, setPdfCurrentPage] = useState(1);
  const [tabId, setTabId] = useState<TabId>('details');

  const imageWidth = window.innerWidth > 789 ? window.innerWidth / 3 : window.innerWidth;

  const { invoiceName } = useGetInvoiceData(fileId, type);

  const isPendingAction = useIsAllFalse([isPendingInvoice], 0);
  const { setLastFetchedInvoiceFile, lastFetchedInvoiceFile } = useInternalAppStore();
  const { updateActiveTab } = useTabsButtonModalStore();
  const { invoiceId } = useInvoiceStore();
  const { costId } = useCostStore();

  useEffect(() => {
    fetchImage();
    addHotjarEvent('Open invoice preview');
    setupResizePosition();
    showTabs && updateActiveTab('details');
  }, []);

  useEffect(() => {
    if (isLoadedImage) setupAfterLoadPosition();
  }, [arrangement, isLoadedImage]);

  const fetchImage = () => {
    if (type === 'invoice') fetchInvoiceImage();
    if (type === 'cost') fetchCostImage();
  };
  const setupResizePosition = () => {
    const wrapperEl = document.querySelector(`#${modalParentId} .content-wrapper`) as HTMLElement;
    if (!wrapperEl) return;
    wrapperEl.style.width = 'fit-content';
    wrapperEl.style.margin = arrangement === 'center' ? '0 auto' : '0 0 0 10px';
  };

  const setupAfterLoadPosition = () => {
    const wrapperEl = document.querySelector(`#${modalParentId} .content-wrapper`) as HTMLElement;
    const pdfEl = document.querySelector(`#${modalParentId} .content-wrapper .invoice-preview-section`) as HTMLElement;
    if (!wrapperEl || !pdfEl) return;
    const pdfDimensions = pdfEl.getBoundingClientRect();
    const width = pdfDimensions.width;
    const windowWidth = window.innerWidth;
    const wrapperWidth = width + 30;
    const marginValue = (windowWidth - wrapperWidth) / 2;

    wrapperEl.style.width = `${wrapperWidth}px`;
    wrapperEl.style.margin = arrangement === 'center' ? `0 ${marginValue}px` : '0 0 0 10px';
  };

  const fetchInvoiceImage = () => {
    const transformedRequestDate = dateDuplicate ? dateFormatter.objectToString(dateDuplicate, 'yyyy-MM-dd') : null;
    setIsPendingInvoice(true);
    createInvoicePdfById(invoiceId || costId || fileId || lastFetchedInvoiceFile?.id, transformedRequestDate)
      .then((data: Blob) => {
        if (!data) return;
        const blob = new Blob([data], { type: data.type });
        setLastFetchedInvoiceFile(blob, fileId || lastFetchedInvoiceFile?.id);
        processTheFile(blob, true);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingInvoice(false));
  };

  const fetchCostImage = () => {
    setIsPendingInvoice(true);
    getCostDownloadImgById(fileId)
      .then((data: Blob) => {
        if (!data) return;
        const blob = new Blob([data], { type: data.type });
        processTheFile(blob, data.type.includes('pdf'));
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingInvoice(false));
  };

  const processTheFile = (file: Blob, isPdf: boolean) => {
    setBlobFile(file);
    if (isPdf) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        setPdfFileUrl(base64data as string);
        setImgFileUrl('');
      };
    } else {
      const url = URL.createObjectURL(file);
      setImgFileUrl(url);
      setPdfFileUrl('');
      setIsLoadedImage(true);
    }
  };

  const onDownloadInvoice = () => {
    if (isPendingAction) return;

    let downloadFileName = fileName;
    if (pdfFileUrl) {
      downloadFileName = dateDuplicate ? `duplikat-faktura-${invoiceName}` : `faktura-${invoiceName}`;
      addHotjarEvent('Download invoice PDF');
    } else {
      addHotjarEvent('Download file');
    }

    if (blobFile) formatFetchedDataToPdf(blobFile, downloadFileName);
  };

  const onDocumentLoadSuccess = (e: CustomObject) => {
    setPdfPagesMax(e?.numPages || 1);
    setTimeout(() => {
      setIsLoadedImage(true);
    }, 250);
  };

  const handleZoom = (value: number) => {
    if (value < minZoom || value > maxZoom || value === zoom) return;
    setupResizePosition();
    const newZoom = Number(value.toFixed(1));
    setZoom(newZoom);
    setTimeout(() => {
      setupAfterLoadPosition();
    });
  };

  const onTabClick = (id: string) => {
    setTabId(id as TabId);
  };

  const onBeforeTabChange = async (id: string): Promise<boolean> => {
    return true;
  };

  return (
    <div className="invoice-preview-component-wrapper">
      <div className="invoice-preview-content">
        <div className={`invoice-preview-section ${isLoadedImage ? 'invoice-visible' : 'invoice-not-visible'}`}>
          {pdfFileUrl ? (
            showTabs ? (
              <>
                <div className="header">
                  <div className="navigation">
                    <TabsButtonModal testcy="tabs" contents={tabsData} onClickTab={onTabClick} beforeTabChange={onBeforeTabChange} />
                  </div>
                </div>
                <div className="tabs-wrapper">
                  {tabId === 'details' && (
                    <InvoicePdf
                      pdfFileUrl={pdfFileUrl}
                      zoom={zoom}
                      zoomTick={zoomTick}
                      pdfCurrentPage={pdfCurrentPage}
                      onDocumentLoadSuccess={onDocumentLoadSuccess}
                      handleZoom={handleZoom}
                      setIsLoadedImage={() => {}}
                      allowCopyText={allowCopyText}
                    />
                  )}
                  {tabId === 'linked' && <LinkedFiles testcy="linked-files" fileId={fileId} />}
                </div>
              </>
            ) : (
              <InvoicePdf
                pdfFileUrl={pdfFileUrl}
                zoom={zoom}
                zoomTick={zoomTick}
                pdfCurrentPage={pdfCurrentPage}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                handleZoom={handleZoom}
                setIsLoadedImage={() => {}}
                allowCopyText={allowCopyText}
              />
            )
          ) : (
            <DragoScrollWrapper id="invoice-drag-scroll-img-file" onZoom={v => handleZoom(v)} zoom={zoom} zoomTick={zoomTick}>
              <div style={{ width: `${zoom * imageWidth}px` }}>
                <img src={imgFileUrl} width="100%" alt="file-img" draggable={false} className="image-document" />
              </div>
            </DragoScrollWrapper>
          )}
          {tabId !== 'linked' && (
            <div className="setting-toolbar">
              <InvoiceTools
                testcy={`${testcy}-tools`}
                pdfPagesMax={pdfPagesMax}
                zoom={zoom}
                minZoom={minZoom}
                zoomTick={zoomTick}
                maxZoom={maxZoom}
                pdfCurrentPage={pdfCurrentPage}
                handleZoom={handleZoom}
                onDownloadInvoice={onDownloadInvoice}
                setPdfCurrentPage={setPdfCurrentPage}
              />
            </div>
          )}
        </div>
        {(!isLoadedImage || isPendingAction) && (
          <div className="invoice-loader-wrapper">
            <LoaderIcon size="big" />
          </div>
        )}
      </div>
    </div>
  );
};
