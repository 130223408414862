import { isDefined } from "@utils";

//! onChange formatting rules
const integerWhitelist = /[^-\d\*]/g;
const floatWhitelist = /[^-\d,.\*]/g;
const multipleCommaRegex = /(,[^\*]*?),/g;
const minusMisplacedRegex = /^(.)-/g;
const repeatedAsteriskRegex = /\*{2,}/g;
const createMaxDecimalsRegex = (maxDecimals: number) => new RegExp(`(,[0-9]{${maxDecimals}})[0-9]+`, 'g');

export function formatNumber(v: string, float?: boolean, maxDecimals?: number, negative?: boolean, allowMultiplication?: boolean, fixedDecimals?: boolean): string {
  if (float) {
    return formatFloat(v, negative, maxDecimals, allowMultiplication, fixedDecimals);
  }
  return formatInteger(v, negative, allowMultiplication);
}

function formatFloat(v: string, negative?: boolean, maxDecimals?: number, allowMultiplication?: boolean, fixedDecimals?: boolean): string {
  v = v.replace(floatWhitelist, '').replace(/\./g, ',').replace(multipleCommaRegex, '$1');
  v = handleNegativeAndAsterisks(v, negative, allowMultiplication);
  return handleMaxDecimals(v, maxDecimals, fixedDecimals);
}
function formatInteger(v: string, negative?: boolean, allowMultiplication?: boolean): string {
  v = v.replace(integerWhitelist, '');
  return handleNegativeAndAsterisks(v, negative, allowMultiplication);
}

function handleMaxDecimals(v: string, maxDecimals?: number, fixedDecimals?: boolean) {
  if (maxDecimals == undefined) return v.split(',')[0];
  const regexp = createMaxDecimalsRegex(maxDecimals);
  v = v.replace(regexp, '$1');

  if (!fixedDecimals) return v;

  const split = v.split(',');
  return split[0] + ',' + (split[1] ?? '').padEnd(maxDecimals, '0');
}

function handleAsterisks(v: string, allowMultiplication?: boolean): string {
  if (allowMultiplication) {
    return v.replace(repeatedAsteriskRegex, '*');
  }
  return v.replace(/\*/g, '');
}
function handleNegativeAndAsterisks(v: string, negative?: boolean, allowMultiplication?: boolean): string {
  v = handleAsterisks(v, allowMultiplication);
  if (negative) {
    return v.replace(minusMisplacedRegex, '$1');
  }
  return v.replace(/-/g, '');
}

//! onBlur formatting rules
const leadingZeroRegexp = /^(-?)0+([1-9])/;

export function formatNumberOnBlur(v: string, float?: boolean, maxDecimals?: number, negative?: boolean, allowMultiplication?: boolean, fixedDecimals?: boolean): string {
  v = formatNumber(v, float, maxDecimals, negative, allowMultiplication, fixedDecimals);
  v = v.replace(leadingZeroRegexp, '$1$2');
  if (allowMultiplication) v = handleMultiplication(v, maxDecimals);
  return v;
}

function handleMultiplication(v: string, maxDecimals?: number): string {
  if (!v) return v;
  v = performMultiplication(v);
  return handleMaxDecimals(v, maxDecimals);
}
function performMultiplication(v: string, maxDecimals?: number): string {
  const calculation = v
    .split('*') //split into individual numbers
    .map(str => str.replace(/,/g, '.')) //convert from human-readable to machine-readable
    .filter(str => str !== '') //remove empty strings
    .map(str => Number(str)) //convert from string to number
    .reduce((acc, num) => acc * num, 1); //multiply every value
  const roundedValue = Math.round(calculation * 100) / 100;
  let stringValue = isDefined(maxDecimals) ? roundedValue.toFixed(maxDecimals) : roundedValue.toString();
  return stringValue.replace(/\./g, ',');
}
