import { ButtonIconNew, ButtonTextNew, TheDialog, UploadIcon } from '@components';
import React, { ChangeEvent, DragEvent, useEffect, useRef, useState, useMemo } from 'react';
import { FILE_SIZE_25MB, hashFileContent, snackbarMessagesHandler, transformBiggerFilesToJSX, transformFaultyFilesToJSX } from '@utils';
import { isNull } from 'lodash';
import { DuplicatedFile, eventToFiles, DuplicatedFileListItem, mapDuplicatedFiles } from './fileDropZone.utils';

import { FileHashInfo } from '@interfaces';
import { DuplicatedFileList } from './components/duplicatedFileList';
import { CloudPending } from './components/cloudPending';
import './fileDropZone.scoped.scss';
import './fileDropZone.scss';
import { InformationIcon } from 'src/components/icons/informationIcon';

interface Props {
  testcy: string;
  maxTotalSize?: number;
  maxSingleSize?: number;
  maxAmountOfFiles?: number;
  maxNameLength?: number;
  accept?: string;
  multiple?: boolean;
  compareHashesFetchFn?: (hashes: string[]) => Promise<DuplicatedFile[]>;
  onChange?: (files: FormData) => void;
  isPending?: boolean;
  showDropzone?: boolean;
  openBrowserDialog?: boolean;
  setOpenBrowserDialog?: (isTrue: boolean) => void;
  showMaxTotalSize?: boolean;
}

const DEFAULT_OPTIONS = {
  maxTotalSize: FILE_SIZE_25MB,
  maxSingleSize: FILE_SIZE_25MB,
  maxAmountOfFiles: 20,
  maxNameLength: 100,
};

export const FileDropZone = ({
  testcy,
  maxTotalSize = DEFAULT_OPTIONS.maxTotalSize,
  maxSingleSize = DEFAULT_OPTIONS.maxSingleSize,
  maxAmountOfFiles = DEFAULT_OPTIONS.maxAmountOfFiles,
  maxNameLength = DEFAULT_OPTIONS.maxNameLength,
  accept,
  multiple,
  compareHashesFetchFn,
  onChange,
  showMaxTotalSize,
  isPending = false,
  showDropzone = true,
  openBrowserDialog = false,
  setOpenBrowserDialog,
}: Props) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [dragActive, setDragActive] = useState(false);

  const [isComboModalOpen, setIsComboModalOpen] = useState(false);

  const [listOfInvalidFormatFiles, setListOfInvalidFormatFiles] = useState<JSX.Element[]>();
  const [listOfWrongSizeFiles, setListOfWrongSizeFiles] = useState<JSX.Element[]>();
  const [listOfTooLongNameFiles, setListOfTooLongNameFiles] = useState<JSX.Element[]>();

  const maxTotalSizeStr = useMemo(() => (showMaxTotalSize ? `${maxTotalSize / 1024 / 1024}MB` : ''), [showMaxTotalSize, maxTotalSize]);

  const [pendingUploadedFiles, setPendingUploadedFiles] = useState<FormData>(null);
  const [isOpenDuplicateFilesModal, setIsOpenDuplicateFilesModal] = useState<boolean>(false);
  const [listOfDuplicateFiles, setListOfDuplicateFiles] = useState<DuplicatedFileListItem[]>([]);

  useEffect(() => {
    if (!inputFileRef.current || !openBrowserDialog) return;
    setOpenBrowserDialog(false);
    inputFileRef.current.click();
  }, [openBrowserDialog]);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement> | DragEvent<HTMLElement>): Promise<FormData | null> => {
    const files = eventToFiles(e, maxAmountOfFiles, maxTotalSize, maxSingleSize, maxNameLength, accept);

    if (!files) return null;

    if (maxAmountOfFiles && files.okayFileCount > maxAmountOfFiles) {
      snackbarMessagesHandler.tooManyFiles(maxAmountOfFiles, files.okayFileCount);
      return null;
    }
    //show modal for wrong size files
    if (files.wrongSizeFiles.length > 0) {
      setIsComboModalOpen(true);
      const wrongSizeFilesList = transformBiggerFilesToJSX(files.wrongSizeFiles);
      setListOfWrongSizeFiles(wrongSizeFilesList);
    } else {
      setListOfWrongSizeFiles(null);
    }
    //show modal for files with too long names
    if (files.tooLongNameFiles.length > 0) {
      setIsComboModalOpen(true);
      const tooLongNameFilesList = transformFaultyFilesToJSX(files.tooLongNameFiles);
      setListOfTooLongNameFiles(tooLongNameFilesList);
    } else {
      setListOfTooLongNameFiles(null);
    }
    //show modal for files with wrong format
    if (files.invalidFiles.length > 0) {
      setIsComboModalOpen(true);
      const invalidFilesList = transformFaultyFilesToJSX(files.invalidFiles);
      setListOfInvalidFormatFiles(invalidFilesList);
    } else {
      setListOfInvalidFormatFiles(null);
    }
    //stop if no files are matching the criteria
    if (files.okayFileCount === 0) return null;

    if (compareHashesFetchFn) {
      const areCollisionsFound = await hashAllFiles(files.data);
      if (areCollisionsFound) {
        setPendingUploadedFiles(files.data);
        return null;
      }
    }

    return files.data;
  };

  const hashAllFiles = async (files: FormData): Promise<boolean> => {
    // hash the files
    const fileHashInfo: FileHashInfo[] = [];
    for (const file of [...files.values()] as File[]) {
      fileHashInfo.push({
        name: file.name,
        hash: await hashFileContent(file),
      });
    }
    // compare hashes against the backend
    const duplicatedFiles = await compareHashesFetchFn(fileHashInfo.map(v => v.hash));

    //display the duplicate files, if they exist
    if (duplicatedFiles.length > 0) {
      setIsOpenDuplicateFilesModal(true);
      const duplicates = mapDuplicatedFiles(duplicatedFiles, fileHashInfo);
      setListOfDuplicateFiles(duplicates);
      return true;
    }
    return false;
  };

  const onChangeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const dataOrNull = await handleFileUpload(e);
    inputFileRef.current.value = null;
    if (isNull(dataOrNull)) return;

    onChange(dataOrNull);
  };

  const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    if (!dragActive) setDragActive(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);

    const dataOrNull = await handleFileUpload(e);
    if (isNull(dataOrNull)) return;

    onChange(dataOrNull);
  };

  const onDragEnterHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(true);
  };

  const onDragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
  };

  const openBrowseDialog = () => {
    if (!inputFileRef.current) return;

    inputFileRef.current.click();
  };

  const onCloseComboModal = () => {
    setIsComboModalOpen(false);
  };

  const onCloseDuplicateFilesModal = (rejected: boolean) => {
    setIsOpenDuplicateFilesModal(false);
    if (rejected) {
      setPendingUploadedFiles(null);
      return;
    }
    onChange(pendingUploadedFiles);
  };
  return (
    <>
      <TheDialog
        testcy={testcy}
        open={isComboModalOpen}
        onClose={onCloseComboModal}
        rejectButtonText="Zamknij"
        tryAgainButtonText="Spróbuj ponownie"
        rejectButtonColorType="primary"
        title="Niektóre z przesłanych plików nie mogą zostać dodane ze względu na:"
      >
        <div className="inbox-dialog-container">
          {listOfInvalidFormatFiles && (
            <>
              <div className="inbox-dialog-reason">
                <p>Niedozwolony format </p>{' '}
                <ButtonIconNew testcy="" size="small" tooltip="Dozwolone formaty: PDF, PNG, JPG, JPEG">
                  <InformationIcon colorType="warning" />
                </ButtonIconNew>
              </div>
              <ul className="inbox-list-of-faulty-files">{listOfInvalidFormatFiles}</ul>
            </>
          )}
          {listOfWrongSizeFiles && (
            <>
              <div className="inbox-dialog-reason">
                <p>Za duży rozmiar</p>{' '}
                <ButtonIconNew testcy="" size="small" tooltip="Maks. rozmiar: 25MB">
                  <InformationIcon colorType="warning" />
                </ButtonIconNew>
              </div>
              <ul className="inbox-list-of-faulty-files">{listOfWrongSizeFiles}</ul>
            </>
          )}
          {listOfTooLongNameFiles && (
            <>
              <div className="inbox-dialog-reason">
                <p>Za długą nazwę</p>
              </div>
              <ul className="inbox-list-of-faulty-files">{listOfTooLongNameFiles}</ul>
            </>
          )}
        </div>
      </TheDialog>
      <TheDialog
        testcy={testcy}
        open={isOpenDuplicateFilesModal && !isComboModalOpen}
        onClose={onCloseDuplicateFilesModal}
        approveButtonText="Nie dodawaj" //approve and reject buttons are swapped for color reasons
        rejectButtonText="Dodaj mimo to"
        title="Wykryliśmy duplikat!"
        removeCloseButton
      >
        <DuplicatedFileList duplicates={listOfDuplicateFiles} />
      </TheDialog>
      <input
        type="file"
        id="upload-file-input"
        style={{ display: 'none' }}
        multiple={multiple}
        accept={accept}
        ref={inputFileRef}
        onChange={e => onChangeHandler(e)}
      />

      {showDropzone && (
        <div
          className={`file-drop-zone drag-drop-wrapper ${dragActive || isPending ? 'drag-active' : ''}`}
          onDragOver={e => onDragOverHandler(e)}
          onDragEnter={e => onDragEnterHandler(e)}
          onDragLeave={e => onDragLeaveHandler(e)}
          onDrop={e => onDropHandler(e)}
        >
          {isPending ? (
            <CloudPending innerIcon="pending" />
          ) : !dragActive ? (
            <>
              <div className="upload-icon">
                <UploadIcon colorType="black" />
              </div>
              <div className="button-wrapper">
                <ButtonTextNew
                  testcy={`${testcy}-browse`}
                  content={'Kliknij,'}
                  onClick={() => openBrowseDialog()}
                  textTransform="none"
                  fontSize="14px"
                  fontColor="#2540d9"
                  fontWeight={500}
                />
                <p>aby przesłać lub przeciągnij i upuść</p>
              </div>
              {maxTotalSizeStr && (
                <p data-testcy={`${testcy}-max-size`} className="file-size-text">
                  Maksymalny rozmiar pliku {maxTotalSizeStr}
                </p>
              )}
            </>
          ) : (
            <CloudPending innerIcon="uploading" />
          )}
        </div>
      )}
    </>
  );
};
