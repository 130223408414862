import React from 'react';
import { LoaderIcon } from '@components';
import './isLoadingPage.scss';

export const IsLoadingPage = () => {
  return (
    <div className="is-loading-page-wrapper">
      <LoaderIcon size={150} />
    </div>
  );
};
