import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const CostsIcon = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7364 2.76175H8.0844C6.0044 2.75378 4.3004 4.41078 4.2504 6.49078V17.2278C4.2054 19.3298 5.8734 21.0698 7.9744 21.1148C8.0114 21.1148 8.0484 21.1158 8.0844 21.1148H16.0724C18.1624 21.0408 19.8144 19.3188 19.8025 17.2278V8.03778L14.7364 2.76175Z"
        stroke="#101013"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4746 2.75V5.659C14.4746 7.079 15.6236 8.23 17.0436 8.234H19.7976"
        stroke="#101013"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.6406 9.90869V15.9497" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.29633 13.9096L11.6413 16.2646L13.9863 13.9096" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
