import { SharedNotifications, useInvitationSettings } from '@features';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Avatar, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { useContractorStore, useUserStore } from '@store';
import { UserRolesEnum, tooltipMessages } from '@utils';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { SendEmailPopup } from '../sendEmailPopup/sendEmailPopup';
import { MenuAvatarsPopups } from './menuAvatarPopups';
import './menuAvatars.scoped.scss';
import { useChatContext } from 'src/features/chat/providers/ChatContextProvider';
import { MessageCommentIcon } from 'src/components/icons/messageCommentIcon';
import { PersonIcon } from 'src/components/icons/personIcon';
import { userManager } from '@lib';

export const MenuAvatars = () => {
  const [openAccount, setOpenAccount] = useState(false);
  const [openAccountantModal, setOpenAccountantModal] = useState(false);
  const [openAccountantInfoModal, setOpenAccountantInfoModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { ownerMyCompanyData } = useContractorStore();
  const { role } = useUserStore();
  const navigate = useNavigate();
  const { accountingCompanyName } = useInvitationSettings();
  const { isConnected, chatState, channels, changeState } = useChatContext();

  const onClickEdit = () => {
    if (role === UserRolesEnum.BUSINESS) navigate(`/contractors-list/owner/edit/${role}`);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenAccount(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenAccount(false);
  };
  const addAccountant = () => {
    if (ownerMyCompanyData?.isDummy) {
      return;
    }
    setOpenAccountantModal(true);
  };

  const showYoursAccountant = () => {
    setOpenAccountantInfoModal(true);
  };

  return (
    <>
      <MenuAvatarsPopups
        openAddAccountantDialog={openAccountantModal}
        setOpenAddAccountantDialog={setOpenAccountantModal}
        openAccountantInfoModal={openAccountantInfoModal}
        setOpenAccountantInfoModal={setOpenAccountantInfoModal}
      />
      <SendEmailPopup isOpen={openSendEmailModal} setIsOpen={setOpenSendEmailModal} />

      <div className="avatars-wrapper">
        {chatState === 'closed' && isConnected && !!channels && channels.length !== 0 && (
          <Avatar data-testcy="open-chat-button" component={'button'} onClick={() => changeState('open')}>
            <MessageCommentIcon />
          </Avatar>
        )}
        <SharedNotifications />
        <Avatar
          component={'button'}
          onClick={handleClick}
          aria-controls={openAccount ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openAccount ? 'true' : undefined}
          data-testcy="header-user-image"
        >
          <PersonIcon />
        </Avatar>
        <Menu
          className="menu-account-wrapper"
          anchorEl={anchorEl}
          id="account-menu"
          open={openAccount}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            sx: {
              borderRadius: '24px',
              mt: '15px',
              minWidth: '322px',
              minHeight: '180px',
              overflow: 'hidden',
              '& .MuiList-root': {
                pt: '0',
                pb: '12px',
              },
              '& .MuiMenu-root': {
                m: '15px',
              },
              '& .MuiTypography-root': {
                fontSize: '20px',
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <div className="name-wrapper">
            <span>{ownerMyCompanyData?.owner?.firstName} </span>
            <span>{ownerMyCompanyData?.owner?.lastName}</span>
          </div>
          <MenuItem
            data-testcy="header-user-settings"
            classes={{
              root: 'settings-item',
            }}
            onClick={() => {
              onClickEdit();
              setOpenAccount(false);
            }}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon
                fontSize="small"
                classes={{
                  root: 'settings-icon',
                }}
              />
            </ListItemIcon>
            <ListItemText>Ustawienia</ListItemText>
          </MenuItem>
          <MenuItem
            data-testcy="header-user-accountant"
            classes={{
              root: `${
                ownerMyCompanyData?.accountingCompany?.name || accountingCompanyName
                  ? 'settings-item'
                  : `${ownerMyCompanyData?.isDummy && 'add-accountant-disabled'} add-accountant-item`
              }`,
            }}
            onClick={() => {
              ownerMyCompanyData?.accountingCompany?.name || accountingCompanyName ? showYoursAccountant() : addAccountant();
            }}
          >
            {ownerMyCompanyData?.accountingCompany?.name || accountingCompanyName ? (
              <>
                <ListItemIcon>
                  <AccountCircleOutlinedIcon
                    fontSize="small"
                    classes={{
                      root: 'settings-icon',
                    }}
                  />
                </ListItemIcon>

                <ListItemText>Twoja firma księgowa</ListItemText>
              </>
            ) : (
              <>
                <ListItemIcon>
                  <PersonAddAltIcon
                    fontSize="small"
                    classes={{
                      root: 'add-accountant-icon',
                    }}
                    sx={{ width: 20, height: 20 }}
                  />
                </ListItemIcon>
                <Tooltip title={ownerMyCompanyData?.isDummy && tooltipMessages.addAccountantMenuItem} arrow>
                  <ListItemText>Dodaj firmę księgową</ListItemText>
                </Tooltip>
              </>
            )}
          </MenuItem>
          <MenuItem
            data-testcy="header-user-logout"
            classes={{
              root: 'logout-item',
            }}
            onClick={() => {
              setOpenAccount(false);
              userManager.signoutRedirect();
            }}
          >
            <ListItemIcon>
              <LogoutIcon
                fontSize="small"
                classes={{
                  root: 'logout-icon',
                }}
              />
            </ListItemIcon>
            <ListItemText>Wyloguj się</ListItemText>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};
