import { useState } from 'react';

export const useModal = (defaultIsOpen: boolean = false) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return {
    isOpen,
    openModal: () => setIsOpen(true),
    closeModal: () => setIsOpen(false),
  };
};
