import React from 'react';
import Chart from 'react-apexcharts';

import './moneyChart.scoped.scss';
import { ApexOptions } from 'apexcharts';
import { ChartSeries, toTwoDecimalPlaces } from '@utils';
import { dateFormatter } from '@lib';

interface Props {
  testcy: string;
  series: ChartSeries[];
  isPending: boolean;
  enableChartTooltip: boolean;
}

export const MoneyChart = ({ testcy, series, isPending, enableChartTooltip }: Props) => {
  const options: ApexOptions = {
    xaxis: {
      labels: {
        formatter: v => {
          const dateParts = String(v).split('.');
          const dateObject = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
          return dateFormatter.objectToString(dateObject, 'dd.MM');
        },
        rotate: 0,
        offsetY: 2,
        maxHeight: 24,
        style: {
          fontSize: '12px',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 400,
        },
        hideOverlappingLabels: true,
      },
    },
    yaxis: {
      labels: {
        formatter: v => `${v.toLocaleString('pl-PL', { useGrouping: true })} PLN`,
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 400,
        },
        align: 'left',
      },
    },
    chart: {
      stacked: true,
      selection: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    fill: {
      colors: ['#30BA93', '#AEEED6'],
      type: 'solid',
      opacity: 1,
    },
    colors: ['#30BA93', '#AEEED6'],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: !enableChartTooltip,
      followCursor: true,
      inverseOrder: true,
      shared: true,
      intersect: false,
      style: {
        fontSize: '14px',
        fontFamily: 'Poppins, sans-serif',
      },
      x: {
        show: true,
        formatter: v => {
          const dateParts = String(v).split('.');
          const dateObject = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
          return dateFormatter.objectToString(dateObject, 'dd MMMM');
        },
      },
      y: {
        formatter: v => `${toTwoDecimalPlaces(v)} PLN`,
      },
      fixed: {
        enabled: false,
      },
    },
  };

  return (
    <div className="chart-container">
      <div className="chart-wrapper">{isPending ? <div></div> : <Chart data-testcy={testcy} type="bar" height="172" options={options} series={series} />}</div>
    </div>
  );
};
