import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useLocation } from 'react-router';

export const useHotjarStateUpdate = () => {
  const location = useLocation();
  useEffect(() => {
    if (hotjar.initialized()) hotjar.stateChange(location.pathname);
  }, [location]);
};
