import React from 'react';

interface Props {
  colorType?: 'black' | 'white' | 'primary' | 'disabled' | '';
  size?: string | number;
}

export const CompanyIcon = ({ colorType = '', size = 16 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        d="M12.5851 13.7587V3.62758C12.5851 2.86105 11.9636 2.24023 11.1977 2.24023H4.766C4.0001 2.24023 3.37866 2.86105 3.37866 3.62758V13.7587"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0821 5.35742H9.74526M6.2168 5.35742H6.87998M9.0821 7.64504H9.74526M6.2168 7.64504H6.87998"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.48633 13.7586V11.1222C6.48633 10.738 6.79327 10.4248 7.17746 10.4248H8.79026C9.16761 10.4248 9.4814 10.738 9.4814 11.1222V13.7586"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.4812 13.7578H13.4816" stroke="#101013" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
