import React from 'react';
import { CloudIcon, UploadIcon } from 'src/components/icons';
import { LoaderIcon } from 'src/components/miscellaneous';
import '../fileDropZone.scoped.scss';

type Props = {
  innerIcon: 'pending' | 'uploading';
};

export const CloudPending = ({ innerIcon }: Props) => {
  return (
    <div className="uploading-files">
      <div className="icons-wrapper">{innerIcon === 'uploading' ? <UploadIcon colorType="white" /> : <LoaderIcon size="tiny" color="primary" />}</div>
      <CloudIcon colorType="blue" />
    </div>
  );
};
