import { QueriesTypeKey, QueryParams, QueryResult, queries } from '@services';
import { QueryKey, UseQueryOptions, useQuery as useRQQuery } from '@tanstack/react-query';

export const useApiQuery = <Key extends QueriesTypeKey, TError = unknown, TData = QueryResult<Key>>(
  query: Key,
  args: QueryParams<Key>,
  options?: UseQueryOptions<QueryResult<Key>, TError, TData>
) => {
  const queryFn = queries[query];
  const queryKey: QueryKey = [query, args];

  const result = useRQQuery(queryKey, async () => await queryFn(args as never), options);

  return { ...result, isLoadingAndEnabled: result.isLoading && options?.enabled };
};
