import React, { useEffect } from 'react';
import { animated, config, useSpringRef, useTransition } from '@react-spring/web';
import { useChatContext } from '../../providers/ChatContextProvider';
import { ChatList } from '../ChatList/chatList';
import { Chat } from '../Chat/chat';
import { MinimizedChat } from '../MinimizedChat/minimizedChat';

import './floatingChat.scoped.scss';

export const FloatingChat = () => {
  const { chatState, channels, currentChannelId, changeChannel, changeState } = useChatContext();

  const isMinimized = chatState === 'minimized';

  const AnimatedDiv = animated('div');

  const springRef = useSpringRef();
  const transitions = useTransition(chatState, {
    ref: springRef,
    from: { transform: 'scale(0%)' },
    enter: { transform: 'scale(100%)' },
    leave: { transform: 'scale(0%)' },
    exitBeforeEnter: true,
    config: {
      ...config.stiff,
      duration: 200,
    },
  });

  useEffect(() => {
    springRef.start();
  }, [springRef, chatState]);

  const channelId = channels.length === 1 ? channels[0].channelId : currentChannelId;
  const channelName = channels.find(ch => ch.channelId === channelId)?.displayName;

  const AnimatedMinimizedChat = animated(MinimizedChat);

  return (
    <div className="floating-chat-anchor">
      {transitions((spring, state) => {
        if (state === 'closed' || state === 'maximized') {
          return null;
        }

        if (state === 'minimized') {
          return <AnimatedMinimizedChat channelName={channelName} changeState={changeState} style={spring} />;
        } else {
          return (
            <AnimatedDiv className="anchored-container" style={spring}>
              <div className="anchored-chat-list-container">
                {channels.length > 1 && <ChatList channels={channels} selectedChannelId={channelId} onChannelSelect={changeChannel} />}
              </div>
              {channelId && (
                <div className="chat-container">
                  <Chat subChannelId={channelId} chatName={channelName} isFloating isMinimized={isMinimized} />
                </div>
              )}
            </AnimatedDiv>
          );
        }
      })}
    </div>
  );
};
