import React, { useMemo } from 'react';
import { ButtonSizeType } from '@interfaces';
import { Tooltip } from '@mui/material';

import './buttonIconNew.scoped.scss';
import './buttonIconNew.scss';

const IconButtonColor = {
  Primary: 'primary',
  PrimaryLight: 'primary-light',
  PrimaryLightColored: 'primary-light-colored',
  Transparent: 'transparent',
  White: 'white',
  Red: 'red',
} as const;
type IconButtonColor = typeof IconButtonColor[keyof typeof IconButtonColor];

interface Props {
  children?: JSX.Element;
  testcy: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  color?: IconButtonColor;
  size?: ButtonSizeType;
  tooltip?: string;
  tooltipPlacement?: string;
  className?: string;
  shouldResizeChildSvg?: boolean;
  border?: boolean;
}

export const ButtonIconNew = ({
  children,
  testcy,
  size = 'medium',
  color = 'white',
  onClick = e => {},
  disabled = false,
  tooltip,
  tooltipPlacement = 'top',
  className,
  shouldResizeChildSvg = true,
  border = false,
}: Props) => {
  const buttonClasses = useMemo(() => {
    return [
      'button-icon-wrapper',
      `button-icon-${size}`,
      `button-icon-color-${color}`,
      disabled ? 'disabled-icon-button' : '',
      className ?? '',
      shouldResizeChildSvg ? 'button-resize-child-svg' : '',
      border ? 'border' : '',
    ].join(' ');
  }, [size, color, disabled, className, shouldResizeChildSvg, border]);

  const button = (
    <button data-testcy={testcy} type="button" className={buttonClasses} onClick={e => (disabled ? null : onClick(e))}>
      {children}
    </button>
  );

  if (!tooltip) return button;

  return (
    <Tooltip sx={{ m: 1 }} placement={tooltipPlacement as any} classes={{ tooltip: 'new-layout-tooltip' }} arrow title={tooltip}>
      <span>{button}</span>
    </Tooltip>
  );
};
