import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ContractorData, InvoiceDetails, getContractorById, getInvoiceById } from '@services';
import { handleApiError, AccountantStatusType } from '@utils';
import { InvoiceStatus, Option } from '@interfaces';
import { useContractorStore, useInvoiceStore } from '@store';
import { addHotjarEvent } from '@lib';

interface UseGetInvoiceData {
  isPendingInvoiceData: boolean;
  isPendingContractorData: boolean;
  invoiceData: InvoiceDetails;
  invoiceName: string;
  invoiceStatus: InvoiceStatus;
  accountingInvoiceStatus: AccountantStatusType;
  isInvoiceSent: boolean;
  amountToPay: number;
  amountPaid: string;
  dueDate: string;
  popupMenuItems: Option[];
  correctionId: number;
  refreshInvoiceData: () => void;
}

export const useGetInvoiceData = (invoiceId: string, type?: string): UseGetInvoiceData => {
  const [isPendingInvoiceData, setIsPendingInvoiceData] = useState(false);
  const [isPendingContractorData, setIsPendingContractorData] = useState(false);
  const [invoiceData, setInvoiceData] = useState<InvoiceDetails>(null);
  const [invoiceName, setInvoiceName] = useState('');
  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceStatus>(null);
  const [accountingInvoiceStatus, setAccountingInvoiceStatus] = useState<AccountantStatusType>(null);
  const [amountToPay, setAmountToPay] = useState(null);
  const [amountPaid, setAmountPaid] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [isInvoiceSent, setIsInvoiceSent] = useState(false);
  const [popupMenuItems, setPopupMenuItems] = useState([]);
  const [contractorId, setContractorId] = useState(null);
  const [correctionId, setCorrectionId] = useState(null);

  const { setSelectedContractor } = useContractorStore();
  const { setSelectedInvoice } = useInvoiceStore();
  const navigate = useNavigate();
  const { companyId } = useParams();

  useEffect(() => {
    if (invoiceId && type !== 'cost') fetchInvoiceData();
  }, [invoiceId]);

  const fetchInvoiceData = (isRefresh = false) => {
    const idNum = +invoiceId;
    if (isNaN(idNum) || !invoiceId) return;
    if (!isRefresh) addHotjarEvent('Open invoice details drawer');
    setIsPendingInvoiceData(true);

    getInvoiceById(idNum)
      .then(data => {
        setSelectedInvoice(data);
        setInvoiceData(data);
        setDueDate(data.dueDate);
        setInvoiceName(data.number);
        setAmountToPay(data.grossValue);
        setInvoiceStatus(data.status);
        setAccountingInvoiceStatus(data.accountingStatus);
        setIsInvoiceSent(data.isSent);
        setAmountPaid(data.amountPaid + data.totalInstalments);
        setCorrectionId(data.correctionId);
        if (!!data.contractorId && contractorId !== data.contractorId) {
          fetchContractorData(data.contractorId);
          setContractorId(data.contractorId);
        }
        setMenuItems(data.status, data.originalInvoiceId);
      })
      .catch(err => {
        if (err.status === 404 || err.status === 403) {
          navigate('/page-not-found');
          return;
        }
        handleApiError(err.data);
      })
      .finally(() => {
        setIsPendingInvoiceData(false);
      });
  };

  const setMenuItems = (invoiceStatus: InvoiceStatus, originalInvoiceId: number) => {
    const menuItems = [
      {
        label: 'Pobierz fakturę',
        value: 'download',
      },
    ];
    if (!companyId && !originalInvoiceId) {
      menuItems.push({
        label: 'Wystaw podobną',
        value: 'clone',
      });
    }

    if (invoiceStatus === 'Draft') {
      menuItems.push({
        label: 'Usuń fakturę',
        value: 'delete',
      });
    }
    if (!companyId && invoiceStatus !== 'Draft') {
      menuItems.push({
        label: 'Generuj duplikat',
        value: 'duplicate',
      });
    }
    setPopupMenuItems(menuItems);
  };

  const fetchContractorData = (id: string) => {
    setIsPendingContractorData(true);
    getContractorById(id)
      .then((data: ContractorData) => {
        setSelectedContractor(data, id);
      })
      .catch(err => {
        if (err.status === 404 || err.status === 403) {
          navigate('/page-not-found');
          return;
        }
        handleApiError(err.data);
      })
      .finally(() => setIsPendingContractorData(false));
  };

  return {
    isPendingInvoiceData,
    isPendingContractorData,
    invoiceData,
    invoiceName,
    invoiceStatus,
    accountingInvoiceStatus,
    isInvoiceSent,
    amountToPay,
    amountPaid,
    dueDate,
    popupMenuItems,
    correctionId,
    refreshInvoiceData: () => {
      type !== 'cost' && fetchInvoiceData(true);
    },
  };
};
