import React from 'react';
import { DuplicatedFileListItem, formatDuplicateItem, formatFile, formatRemainingDuplicates } from '../fileDropZone.utils';
import '../fileDropZone.scoped.scss';

type Props = {
  duplicates: DuplicatedFileListItem[];
};

export const DuplicatedFileList = ({ duplicates }: Props) => {
  return (
    <div className="drop-zone-dialog-wrapper">
      {duplicates.map(file => (
        <div key={file.matchedFiles.join('-').substring(0, 100)} className="drop-zone-dialog-container">
          <p>{formatFile(file.matchedFiles, file.duplicates.length)}</p>
          <ul className="list-of-wrong-format-files">
            {file.duplicates.map(duplicate => (
              <li key={`${duplicate.name}-${duplicate.createdDate}`}>{formatDuplicateItem(duplicate)}</li>
            ))}
            {file.remainingDuplicateCount > 0 && <li>{formatRemainingDuplicates(file.remainingDuplicateCount)}</li>}
          </ul>
        </div>
      ))}
    </div>
  );
};
