import { Address } from '@interfaces';
import { httpClient } from '@lib';
import { AxiosResponse } from 'axios';

export interface GusCompanyData {
  address: Address;
  companyName: string;
  regon: string;
}

export const getCompanyDataGus = async (nip: string) => {
  try {
    const response: AxiosResponse<GusCompanyData> = await httpClient.get('/GusData', { params: { nip } });
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};
