import React from 'react';

interface Props {
  colorType?: 'black' | 'white' | 'primary' | 'disabled' | '';
  size?: string | number;
}

export const DeleteIconNew = ({ colorType = '', size = 24 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.1297 4.41797H2.55078" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.2669 6.42969L11.8753 11.7907C11.7944 12.8997 10.871 13.7589 9.75827 13.7589H5.92273C4.81068 13.7589 3.88666 12.8997 3.80572 11.7901L3.41406 6.42969"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2546 4.41508L9.93274 2.83043C9.8393 2.48112 9.52237 2.23829 9.16122 2.23829H6.52372C6.16068 2.23704 5.8425 2.47987 5.74848 2.83043L5.42969 4.41508"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
