import React, { useState, MouseEvent } from 'react';
import { InvoiceStatus, Option } from '@interfaces';
import { IconButton, Menu } from '@mui/material';
import { ChevronIcon, TheCheckbox } from '@components';

import './theHeaderFilter.scoped.scss';
import { Control, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { DocumentFileCategoryEnum } from '@utils';

interface Props {
  control: Control;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  menuOptions: Option[];
  onSetHeaderFilter: (filter: InvoiceStatus[] | DocumentFileCategoryEnum[]) => void;
}

export const TheHeaderFilter = ({ control, getValues, setValue, name, menuOptions, onSetHeaderFilter }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    let arr = [];
    for (const [key, value] of Object.entries(getValues())) {
      if (value && key.includes(`checkbox-filter-${name}`)) {
        const index = key.lastIndexOf('-');
        arr.push(key.slice(index + 1));
      }
    }
    onSetHeaderFilter(arr);
  };

  const clearSelection = () => {
    menuOptions.forEach(item => {
      setValue(`checkbox-filter-${name}-${item.value}`, false);
    });
  };
  return (
    <>
      {name !== 'tag' && name.charAt(0).toUpperCase() + name.slice(1)}
      <IconButton
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleClick}
        data-testcy={`header-${name}-filter-button-open-menu`}
        className="icon-button"
      >
        <ChevronIcon colorType="black" direction="down" size={16} />
      </IconButton>
      <Menu
        className="status-menu-wrapper"
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '24px',
            backgroundColor: ' #FFF',
            boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.05),0px 10px 23px 0px rgba(0, 0, 0, 0.05)',
            padding: '8px',
          },
        }}
      >
        {menuOptions?.map((opt, i) => {
          return (
            <div className="filter-menu-wrapper" key={i}>
              <TheCheckbox
                testcy={`checkbox-filter-${name}-${opt.value}`}
                control={control}
                name={`checkbox-filter-${name}-${opt.value}`}
                key={`checkbox-filter-${name}-${opt.value}`}
                customColor="grey"
                size="small"
              />
              <div
                data-testcy={`checkbox-filter-${name}-${opt.value}`}
                className="filter-menu-label"
                onClick={() => {
                  setValue(`checkbox-filter-${name}-${opt.value}`, !getValues(`checkbox-filter-${name}-${opt.value}`));
                }}
              >
                {opt.label}
              </div>
            </div>
          );
        })}
        <button className="clear-button" onClick={clearSelection} data-testcy={`header-${name}-filter-button-clear-selected`}>
          Wyczyść
        </button>
      </Menu>
    </>
  );
};
