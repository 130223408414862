export const emailValidateRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isNumberRegexp = /^\d+$/;
export const isNumberFractionRegexp = /^([0-9,\s*]+)$/;
export const isNumberFractionNegativeRegexp = /^-$|^-([0-9,\s*]+)$|^[0]$|^[0]([0,\s*]+)$/;
export const calculateComasRegexp = /,/g;
export const isPhoneNumber = /^([0-9 +-]+)$/;
export const isZipCode = /^[\d-]*$/; //GET ONLY NUMBERS AND '-'
export const zipCodeMatch = /^\d{5}$/; // GET ONLY 5 NUMBERS
export const zipCodeRegexp = /^\d{2}-\d{3}$|^\d{5}$/; //GET ONLY NUMBERS IN FORMAT XX-XXX OR XXXXX
export const regonRegexp = /^\d{9}$|^\d{14}$/; // GET ONLY 9 OR 14 NUMBERS
export const suffixRegexp = /^[a-zA-Z0-9]+$/;
export const numberAndTextRegexp = /^[a-zA-Z0-9]+$/;
export const letterLowerCaseRegexp = /^[a-z]+$/;
export const nipRegexp = /^\s*\d{3}-\d{3}-\d{2}-\d{2}\s*$|^\s*\d{3}-\d{2}-\d{2}-\d{3}\s*$/; //GET ONLY NUMBERS IN FORMAT XXX-XXX-XX-XX OR XXX-XX-XX-XXX
export const digitsAndSpacesRegex = /^(\s*\d\s*)+$/;

export const regCountry = (countryCode: string) => {
  let reg: RegExp;
  switch (countryCode) {
    case 'AT':
      reg = /^U\d{8}$/;
      break;
    case 'BE':
      reg = /^\d{10}$/;
      break;
    case 'BG':
      reg = /^\d{9,10}$/;
      break;
    case 'CN':
      reg = /^[0-9A-Z]{1,18}$/;
      break;
    case 'CY':
      reg = /^\d{8}[A-Z]$/;
      break;
    case 'CZ':
      reg = /^\d{8,10}$/;
      break;
    case 'DK':
      reg = /^\d{8}$/;
      break;
    case 'EE':
      reg = /^\d{9}$/;
      break;
    case 'FI':
      reg = /^\d{8}$/;
      break;
    case 'FR':
      reg = /^[0-9A-Z]{2}\d{9}$/;
      break;
    case 'EL': //Grecja
      reg = /^\d{9}$/;
      break;
    case 'ES':
      reg = /^([A-Z]\d{7}[0-9A-Z]|[0-9A-Z]\d{7}[A-Z]|[A-Z]\d{7}[A-Z])$/;
      break;
    case 'NL':
      reg = /^\d{9}B\d{2}$/;
      break;
    case 'IE': //Irlandia
      reg = /^(\d[0-9A-Z]\d{5}[0-9A-Z]{1,2})$/;
      break;
    case 'LT':
      reg = /^(\d{9}|\d{12})$/;
      break;
    case 'LU':
      reg = /^\d{8}$/;
      break;
    case 'LV':
      reg = /^\d{11}$/;
      break;
    case 'MT':
      reg = /^\d{8}$/;
      break;
    case 'DE':
      reg = /^\d{9}$/;
      break;
    case 'PT':
      reg = /^\d{9}$/;
      break;
    case 'RU':
      reg = /^(\d{10}|\d{12})$/;
      break;
    case 'RO':
      reg = /^\d{2,10}$/;
      break;
    case 'SK':
      reg = /^\d{10}$/;
      break;
    case 'SI':
      reg = /^\d{8}$/;
      break;
    // case 'US':
    //   reg = /^\d{9}$/;
    //   break;
    case 'SE':
      reg = /^\d{12}$/;
      break;
    case 'TR':
      reg = /^\d{10}$/;
      break;
    case 'HU':
      reg = /^\d{8}$/;
      break;
    case 'GB':
      reg = /^\d{9}(\d{3})?$/;
      break;
    case 'IT':
      reg = /^\d{11}$/;
      break;
    case 'HR':
      reg = /^\d{11}$/;
      break;
    case 'UA':
      reg = /^\d{12}$/;
      break;
    case 'XI': //Irlandia Północna
      reg = /^[0-9A-Z]{2,13}$/;
      break;
  }
  return reg;
};
