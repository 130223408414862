import { InvoiceBulkOperationsParams } from '@services';
import { useApiMutation } from '../useApiMutation';
import { snackbarMessagesHandler } from '@utils';

interface IUseConfirmInvoice {
  confirmInvoice: (id: string, selectAll?: boolean) => void;
  isConfirmInvoiceLoading: boolean;
}

export const useConfirmInvoice = (onSuccess: () => void): IUseConfirmInvoice => {
  const { mutate, isLoading } = useApiMutation('invoicesConfirm');

  const confirmInvoice = (id: string, selectAll: boolean = false) => {
    const params: InvoiceBulkOperationsParams = {
      ids: [+id],
      selectAll,
    };
    mutate(params, {
      onSuccess: () => {
        snackbarMessagesHandler.invoicesConfirmed(false);
        onSuccess();
      }
    });
  }

  return { confirmInvoice, isConfirmInvoiceLoading: isLoading };
};
