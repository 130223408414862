import { BankAccountData } from '@interfaces';
import { isDefined } from '@utils';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SettingsSection, SettingsSubSection, SettingsTab } from '../../components/settingsTab';
import { BankAccountField } from './bankAccountField';
import { BankDialog } from './bankDialog';
import { useBankAccounts } from './useBankAccounts';

interface Props {
  accounts: BankAccountData[];
}

export const BankDataSection = ({ accounts }: Props) => {
  const [isBankAccountDialogOpen, setIsBankAccountDialogOpen] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState<BankAccountData>(null);
  const [bankDialogEditIndex, setBankDialogEditIndex] = useState<number>(null);

  const formContext = useFormContext();
  const { mainAccount, mainAccountIndex, otherAccounts, setMainAccount, addAccount, updateAccount, removeAccount } = useBankAccounts(
    accounts,
    formContext.setValue
  );

  const onFavoriteClick = (index: number) => {
    setMainAccount(index);
  };

  const openBankAccountDialog = (data?: BankAccountData, index?: number) => {
    const number = formContext.getValues(`bank-number-${index}`);
    setDefaultFormValues({ ...data, number });
    setBankDialogEditIndex(index);
    setIsBankAccountDialogOpen(true);
  };

  const onBankDialogSubmit = (data: BankAccountData) => {
    if (isDefined(bankDialogEditIndex)) {
      updateAccount(bankDialogEditIndex, data);
      formContext.trigger(`bank-number-${bankDialogEditIndex}`);
      return;
    }
    addAccount(data);
  };

  return (
    <>
      {isBankAccountDialogOpen && (
        <BankDialog
          setIsOpen={setIsBankAccountDialogOpen}
          onSubmit={onBankDialogSubmit}
          defaultFormValues={defaultFormValues}
        />
      )}
      <SettingsTab title="Dane bankowe">
        <SettingsSection>
          <SettingsSubSection title="Numery kont bankowych" subtitle="Główny numer konta." noBorder>
            <BankAccountField
              index={mainAccountIndex}
              isMain
              bankName={mainAccount?.bankName}
              onFavoriteClick={() => onFavoriteClick(mainAccountIndex)}
              onEditClick={() => openBankAccountDialog(mainAccount, mainAccountIndex)}
              onDeleteClick={() => removeAccount(mainAccountIndex)}
            />
          </SettingsSubSection>
          <SettingsSubSection
            subtitle={
              <div className="lines">
                <div className="line">Opcjonalne numery kont.</div>
                <div className="line">
                  Chciałbyś{' '}
                  <a className="link-g" onClick={() => openBankAccountDialog()}>
                    dodać kolejne konto?
                  </a>
                </div>
              </div>
            }
          >
            {otherAccounts.map(account => {
              if (!account) return null;
              const { index } = account;
              return (
                <BankAccountField
                  key={index}
                  index={index}
                  isMain={false}
                  bankName={account?.bankName}
                  onFavoriteClick={() => onFavoriteClick(index)}
                  onEditClick={() => openBankAccountDialog(account, index)}
                  onDeleteClick={() => removeAccount(index)}
                />
              );
            })}
            {otherAccounts.length === 0 && <BankAccountField empty />}
          </SettingsSubSection>
        </SettingsSection>
      </SettingsTab>
    </>
  );
};
