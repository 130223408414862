import { useState } from 'react';
import { useLiveMessageCollection } from './useLiveMessageCollection';
import { useHintResolver } from './useHintResolver';
import { useMessageSender } from './useMessageSender';
import { useMessageComposer } from './useMessageComposer';

type UseMessagingChannelProps = {
  subChannelId: string;
  userId?: string;
  companyId: number;
};

export const useMessagingChannel = ({ subChannelId, userId, companyId }: UseMessagingChannelProps) => {
  const [contextEnabled, setContextEnabled] = useState(true);

  const messageCollection = useLiveMessageCollection({ subChannelId, userId });
  const hintResolver = useHintResolver({ companyId });
  const messageComposer = useMessageComposer({ tagFactory: hintResolver.getById });
  const { currentMessage: textMessage, currentTag: tag } = messageComposer;

  const { sendTextMessage, sendMessageWithHeader, isSending } = useMessageSender({
    onSuccess: () => {
      messageComposer.reset();
      setContextEnabled(true);
    },
  });

  const lastMessageTag = contextEnabled ? messageCollection?.data[0]?.invoiceTag : null;
  const invoiceTag = tag ?? lastMessageTag;

  const sendCurrentMessage = () => {
    if (!textMessage) {
      return;
    }

    const isInitializingInvoiceContext = !!tag && (!lastMessageTag || lastMessageTag.id !== tag.id);
    if (isInitializingInvoiceContext) {
      sendMessageWithHeader({ subChannelId, textMessage, tag });
    } else {
      sendTextMessage({ subChannelId, textMessage, tag: invoiceTag });
    }
  };

  return {
    messageCollection,
    messageComposer,
    isSending,
    sendCurrentMessage,
    resolveHints: hintResolver.resolveHints,
    invoiceTag: lastMessageTag,
    disableContext: () => setContextEnabled(false),
  };
};
