import React from 'react';
import { dateFormatter } from '@lib';
import { daysOverdueToText } from '@utils';
import { Invoice } from '@services';
import './dueDate.scoped.scss';

export const DueDate = ({ invoice }: { invoice: Invoice }) => {
  return (
    <>
      <div className="bold-text">{dateFormatter.standardFormat(invoice.dueDate)}</div>
      <div className="warning">{!!invoice.daysOverdue && daysOverdueToText(invoice.daysOverdue)}</div>
    </>
  );
};
