import { ButtonText, FileDropZone, PageLoaderWrapper, TableRowInterface, TheDialog, TheTable } from '@components';
import { useApiQuery } from '@hooks';
import { RequestErrorMessage } from '@interfaces';
import { createEmployeeNewDocuments, deleteEmployeeDocument, getEmployeeDownloadImgById } from '@services';
import { formatFetchedDataToPdf, handleApiError, snackbarMessagesHandler } from '@utils';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { EmployeeProfile } from './components';
import './sharedEmployeeProfile.scoped.scss';
import { actionMenuItems, employeeDocumentDataToTableRow, tableHeader, transformMenuItemsEmployeeProfile } from './sharedEmployeeProfile.utils';
import { compareEmployeeFileHashes } from 'src/services/employees';
import cleaningPic from '@assets/images/cleaning_angle.png';
import { invalidateApiQuery } from '@lib';

export const SharedEmployeeProfile = () => {
  const [isPendingAddNewDocuments, setIsPendingAddNewDocuments] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [employeeDocumentId, setEmployeeDocumentId] = useState(null);
  const [employeeDocumentName, setEmployeeDocumentName] = useState('');

  const { companyId, employeeId } = useParams();
  const navigate = useNavigate();

  const { data: employeeDetails, isLoading } = useApiQuery('getEmployeeDataById', Number(employeeId), {
    enabled: !!employeeId,
  });

  const rows = employeeDetails?.files.map(file => employeeDocumentDataToTableRow(file)) ?? [];
  const employeeProfileMenuItems = transformMenuItemsEmployeeProfile(employeeDetails);

  const addNewEmployeeDocuments = async (data: FormData) => {
    setIsPendingAddNewDocuments(true);
    try {
      const employeeDocuments = await createEmployeeNewDocuments(data, Number(employeeId));

      const successfulFilesCount = employeeDocuments?.filter(doc => doc.isSuccessful).length;
      if (successfulFilesCount) {
        snackbarMessagesHandler.employeeUploadDoc(successfulFilesCount > 1 ? true : false);
      }
    } catch (err) {
      console.error(err);
      handleApiError(err);
    }
    setIsPendingAddNewDocuments(false);
    invalidateApiQuery('getEmployeeDataById', Number(employeeId));
  };

  const onClickRemove = (data: TableRowInterface) => {
    setEmployeeDocumentId(data.id);
    setEmployeeDocumentName(data.originalFilename.content);
    setOpenDeleteDialog(true);
  };

  const onClickDownload = (fileData: TableRowInterface) => {
    getEmployeeDownloadImgById(fileData.id)
      .then((data: Blob) => {
        if (!data) return;
        const blob = new Blob([data], { type: data.type });
        formatFetchedDataToPdf(blob, `${fileData.originalFilename.content}`);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {});
  };

  const onCloseConfirmDelete = (isConfirmed: boolean) => {
    setOpenDeleteDialog(false);
    if (!isConfirmed) return;
    deleteEmployeeDocument(employeeDocumentId)
      .then(() => {
        snackbarMessagesHandler.employeeDocumentDeleted();
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        invalidateApiQuery('getEmployeeDataById', Number(employeeId));
      });
  };

  const clickBack = () => {
    companyId ? navigate(`/owner-company-list/details/${companyId}`) : navigate(`/employees-list`);
  };

  return (
    <div className={`owner-employee-documents-tab standard-layout-spacing-g`}>
      <TheDialog
        testcy="delete-confirmation"
        open={openDeleteDialog}
        onClose={onCloseConfirmDelete}
        approveButtonText="Tak"
        rejectButtonText="Nie"
      >
        <div className="remove-dialog-content">
          <img src={cleaningPic} alt="cleaning-set" width={111} height={128} />
          <div>Czy na pewno chcesz usunąć dokument</div>
          <div className="remove-dialog-content-document">
            <div>{employeeDocumentName}</div>
            <p>?</p>
          </div>
        </div>
      </TheDialog>

      <ButtonText testcy="go-back" onClick={clickBack} content="powrót" startIcons="back" />
      <div className="profile-and-files-wrapper">
        <div className="profile-wrapper">
          <EmployeeProfile employeeDetails={employeeDetails} actionMenuItems={employeeProfileMenuItems} />
        </div>
        <div className="upload-and-table-wrapper">
          <FileDropZone
            testcy="employee-file-drop-zone"
            accept="pdf,png,jpg,jpeg"
            isPending={isPendingAddNewDocuments}
            compareHashesFetchFn={(hashes: string[]) =>
              compareEmployeeFileHashes({
                values: hashes,
                employeeId: employeeId,
              })
            }
            showMaxTotalSize
            multiple
            onChange={addNewEmployeeDocuments}
          />
          <PageLoaderWrapper isLoading={isLoading}>
            <TheTable
              testcy="employee-files-table"
              rows={rows}
              header={tableHeader}
              onClickRemove={onClickRemove}
              onClickDownload={onClickDownload}
              actionMenuItems={actionMenuItems}
              showHeaderAndFooter={false}
              centerCells
            />
          </PageLoaderWrapper>
        </div>
      </div>
    </div>
  );
};
