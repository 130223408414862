import React from 'react';
import { AccountantStatusType, DocumentFileCategoryEnum, InvoiceStatusType, PaymentStatusType, translateAccountantStatus, translateStatus } from '@utils';

import './statuses.scoped.scss';

export const Statuses = ({ status, costAccountingStatuses = false }) => {
  const getStatusStyle = (status: InvoiceStatusType | PaymentStatusType | AccountantStatusType | DocumentFileCategoryEnum) => {
    switch (status) {
      case 'Draft':
        return 'draft';
      case 'Issued':
        return 'issued';
      case 'Overdue':
        return 'overdue';
      case 'Sent':
        return 'sent';
      case 'PartiallyPaid':
        return 'partially-paid';
      case 'Paid':
        return 'paid';
      case 'Unpaid':
        return 'unpaid';
      case 'Empty':
        return 'empty';
      case 'New':
        return 'new';
      case 'Sending':
        return 'sending';
      case 'SentToAccountingService':
        return 'sentToAccountingService';
      case 'Confirmed':
        return 'confirmed';
      case 'RevenuesExpenditures':
        return 'revenuesExpenditures';
      case 'ProfitsLosses':
        return 'profitsLosses';
      case 'BalanceSheet':
        return 'balanceSheet';
      case 'Personnel':
        return 'personnel';
      case 'OfficialLetters':
        return 'officialLetters';
    }
  };
  return (
    <div className="status-wrapper">
      <div className={`status-span ellipsis-text-two-lines-g ${getStatusStyle(status)} `}>{costAccountingStatuses ? translateAccountantStatus(status) : translateStatus(status)}</div>
    </div>
  );
};
