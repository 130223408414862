import React, { useMemo } from 'react';

import { Tooltip } from '@mui/material';
import { SingleBarChartData, toTwoDecimalPlaces } from '@utils';
import { isArray, sum } from 'lodash';

import './singleBarChart.scoped.scss';
import './singleBarChart.scss';
import { SingleBarChartTooltip } from './tooltip';

interface Props {
  testcy: string;
  data: SingleBarChartData;
  isPending?: boolean;
  className?: string;
}

export const SingleBarChart = ({ testcy, data, isPending, className }: Props) => {
  const mappedData = useMemo(() => {
    if (!data || (isArray(data) && data.length == 0)) return [];

    const totalValues = sum(data?.map(item => item.value));
    const newData = data
      .filter(v => v.value > 0)
      .map((item, index) => {
        const percentage = toTwoDecimalPlaces((item.value / totalValues) * 100);
        return {
          value: item.value,
          order: item.order,
          percentage: percentage,
          class: ['chart-item', item.class ?? '', 'chart-item-' + item.order, item.name ? 'chart-item-' + item.name : ''].join(' '),
          name: item.name,
        };
      })
      .sort((a, b) => b.order - a.order);
    return newData;
  }, [data]);

  return (
    <div className="single-bar-chart chart-container">
      {isPending && <div className="single-bar-chart-placeholder">Ładowanie...</div>}
      {!isPending && mappedData?.length == 0 ? <div className="single-bar-chart-placeholder">Brak danych</div> : <div></div>}
      {mappedData?.length > 0 &&
        mappedData.map((item, index) => {
          const itemJSX = (
            <div className={item.class} data-testcy={`${testcy}-${index}`} key={index} style={{ '--_chart-bar-width': `${item.percentage}%` } as any} />
          );
          if (item.name) {
            return (
              <Tooltip
                title={SingleBarChartTooltip({ name: item.name, amount: item.value, className: `chart-type-circle-${item.order} chart-type-circle-${className}` })}
                arrow
                describeChild
                classes={{ popper: 'single-bar-chart-tooltip' }}
                key={index}
              >
                {itemJSX}
              </Tooltip>
            );
          }
          return itemJSX;
        })}
    </div>
  );
};
