import React from 'react';

interface Props {
  colorType?: 'black' | 'white' | 'primary' | 'disabled' | '';
  size?: number;
}

export const NotificationIcon = ({ colorType = 'black', size = 24 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.43311 9.56643C5.43311 5.93928 8.37337 3 11.9995 3C15.6257 3 18.566 5.93928 18.566 9.56643L18.567 10.8848C18.567 11.6651 18.7382 12.4347 19.0709 13.1401L19.537 14.1305C20.2492 15.6444 19.1449 17.3851 17.4714 17.3851H6.52768C4.85421 17.3851 3.74991 15.6444 4.46211 14.1305L4.92815 13.1401C5.25993 12.4347 5.43214 11.6651 5.43214 10.8848L5.43311 9.56643Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.90039 17.9004C8.90039 19.6118 10.2878 21.0002 11.9992 21.0002C13.7116 21.0002 15.099 19.6118 15.099 17.9004"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
