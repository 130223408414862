import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { ChartSeries, toTwoDecimalPlaces } from '@utils';

import './profitAndLossChart.scoped.scss';
import { PageLoaderWrapper } from '@components';
import { dateFormatter } from '@lib';

interface Props {
  series: ChartSeries[];
  isPending: boolean;
  onClick?: (date: Date, seriesIndex: number) => void;
}

export const ProfitAndLossChart = ({ series, isPending, onClick }: Props) => {
  const options: ApexOptions = {
    xaxis: {
      labels: {
        rotate: 0,
        maxHeight: 22,
      },
      categories: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],

    },
    legend: {
      show: false,
    },
    yaxis: {
      labels: {
        formatter: v => `${v.toLocaleString('pl-PL', { useGrouping: true })} PLN`,
      },
    },
    chart: {
      selection: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection(e: Event, chart, point) {
          e.preventDefault();
          const pointIndex = point.dataPointIndex;
          const seriesIndex = point.seriesIndex;
          const dateStr = chart.data.twoDSeriesX[pointIndex];
          const date = dateFormatter.getDateObjectFromString(dateStr, 'MMM yyyy');
          ;
          onClick(date, seriesIndex);
        },
      },
    },

    colors: ['#F2A022', '#BAB5FC'],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      followCursor: true,
      shared: true,
      intersect: false,
      x: {
        show: true,
      },
      y: {
        formatter: v => `${toTwoDecimalPlaces(v)} PLN`,
      },
      fixed: {
        enabled: false,
      },
    },
  };

  return (
    <div className="chart-container">
      <div className="chart-wrapper">
        <PageLoaderWrapper isLoading={isPending} maxHeight>
          <Chart type="bar" height="250px" options={options} series={series} />
        </PageLoaderWrapper>
      </div>
    </div>
  );
};
