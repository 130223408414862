import { useState } from 'react';
import { setAccountingPermission } from '@services';
import { apiErrorMessages, handleApiError, snackbarMessagesHandler } from '@utils';
import { addHotjarEvent, displaySnackBarMessage } from '@lib';
import { useGetMyCompanyData } from '@features';

interface AccountingIdInterface {
  isPendingAccountingPermission: boolean;
  accountingCompanyName: string;
  setAccountingId: (id: string) => void;
}

export const useInvitationSettings = (): AccountingIdInterface => {
  const [isPendingAccountingPermission, setIsPendingAccountingPermission] = useState(false);
  const [accountingCompanyName, setAccountingCompanyName] = useState('');

  const { fetchOwnerMyCompanyData } = useGetMyCompanyData();

  const setAccountingId = (id: string) => {
    addHotjarEvent('Set accounting id');
    setIsPendingAccountingPermission(true);
    setAccountingPermission(id)
      .then(data => {
        setAccountingCompanyName(data);
        if (data) {
          fetchOwnerMyCompanyData();
          snackbarMessagesHandler.settingAccountingPermission();
        }
      })
      .catch(err => {
        if (err.status === 404) {
          displaySnackBarMessage('error', apiErrorMessages.accountingPermissionWrongId);
          return;
        }
        handleApiError(err.data);
      })
      .finally(() => setIsPendingAccountingPermission(false));
  };

  return {
    isPendingAccountingPermission,
    accountingCompanyName,
    setAccountingId: id => setAccountingId(id),
  };
};
