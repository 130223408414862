import React from 'react';

import './theChip.scoped.scss';

export type Props = {
  variant: 'outlined' | 'filled';
  color: 'disabled' | 'success' | 'error' | 'warning';
  label: string;
};

export const TheChip = ({ variant, color, label }: Props) => {
  return <span className={`chip ${variant} ${color}`}>{label}</span>;
};
