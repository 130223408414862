import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const Contractors = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17 2.75H7C4.65279 2.75 2.75 4.65279 2.75 7V17C2.75 19.3472 4.65279 21.25 7 21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V7C21.25 4.65279 19.3472 2.75 17 2.75Z"
        stroke="#101013"
        strokeWidth="1.5"
      />
      <path
        d="M9.29423 16.6559C8.50845 16.6559 8.03361 16.0912 8 15.3045C8 13.7033 9.56456 13.1509 11.9219 13.1323C14.2834 13.1572 15.8522 13.7095 15.8438 15.3045C15.806 16.0912 15.3339 16.6559 14.5496 16.6559H9.29423Z"
        fill="white"
        stroke="#101013"
        strokeWidth="1.44"
        strokeMiterlimit="10"
      />
      <path
        d="M11.9098 11.1242C13.0487 11.1242 13.9719 10.201 13.9719 9.06211C13.9719 7.92324 13.0487 7 11.9098 7C10.7709 7 9.84766 7.92324 9.84766 9.06211C9.84766 10.201 10.7709 11.1242 11.9098 11.1242Z"
        stroke="#101013"
        strokeWidth="1.44"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
