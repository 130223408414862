import { ButtonMainNew, PageLoaderWrapper, TheDialog } from '@components';
import { useFetchGusData, useIsAllFalse } from '@hooks';
import { BankAccountData, RequestErrorMessage } from '@interfaces';
import { addHotjarEvent } from '@lib';
import {
  CompanyData,
  GusCompanyData,
  InsertGetData,
  createNewCompany,
  getAccountingCredentials,
  getCompanyById,
  getMyCompany,
  updateAccountingCredentials,
  updateCompany,
  updateMyCompany,
} from '@services';
import { useCompanyStore, useContractorStore, useListParamsStore } from '@store';
import {
  InvoiceNumberFormat,
  UserRolesEnum,
  checkIsIncorrectDataFromGus,
  errorCode,
  handleApiError,
  isDefined,
  scrollToFirstError,
  setFormValues,
  snackbarMessagesHandler,
} from '@utils';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BankDataSection, CompanyDataSection, InsertSection, LoginSection, MySettingsSection, OwnerProfileSection } from './sections';
import {
  CompanyFormData,
  transformCompanyDataToFormData,
  transformFormDataToCompanyData,
  transformFormDataToInsertData,
  transformInsertDataToFormData,
} from './sharedCompanyForm.utils';
import { AccountantInsertSection } from './sections/insertSection/accountantInsertSection';
import { transformInsertErrorForm } from './sections/insertSection/accountantInsertSection.utils';
import { GusFetcher } from './components/gusFetcher/gusFetcher';

import './sharedCompanyForm.scoped.scss';

export const SharedCompanyForm = () => {
  const useFormProps = useForm<CompanyFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });
  const {
    handleSubmit,
    setValue,
    unregister,
    reset,
    watch,
    register,
    clearErrors,
    getFieldState,
    formState: { errors },
  } = useFormProps;

  const [isSavePending, setIsSavePending] = useState(false);
  const [isCompanyDataPending, setIsCompanyDataPending] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isFetchedGusData, setIsFetchedGusData] = useState(false);
  const [nip, setNip] = useState('');
  const [openDialogGusFetcher, setOpenDialogGusFetcher] = useState(false);
  const [openDialogFormEdited, setOpenDialogFormEdited] = useState(false);
  const [isPendingOwnerData, setIsPendingOwnerData] = useState(false);
  const [isNipCorrect, setIsNipCorrect] = useState(false);
  const [isNipEnabled, setIsNipEnabled] = useState(true);
  const [isAccountingCredentialsPending, setIsAccountingCredentialsPending] = useState(false);
  const [hasEntityCredentials, setHasEntityCredentials] = useState(false);

  const [bankAccounts, setBankAccounts] = useState<BankAccountData[]>([]);

  const { fetchGusData, isGusDataLoading } = useFetchGusData(data => onGusDataFetchedByOwner(data, watchedForm.nip));

  const routeParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCompanyId, selectedCompany, setSelectedCompany } = useCompanyStore();
  const { ownerMyCompanyData, setOwnerMyCompanyData } = useContractorStore();
  const isLoadingPage = useIsAllFalse([isAccountingCredentialsPending, isPendingOwnerData, isCompanyDataPending, isGusDataLoading], 500);
  const { setLastParamsCompaniesList } = useListParamsStore();
  const watchedForm = watch();

  useEffect(() => {
    if (routeParams.companyId) {
      setCompanyId(routeParams.companyId);
      fetchCompanyData(routeParams.companyId);
      return;
    }
    if (routeParams.role) {
      fetchOwnerMyCompanyData();
    }
  }, []); //eslint-disable-line

  const nipError = getFieldState('nip').error;
  const nipWatch = watch('nip');
  useEffect(() => {
    setIsNipCorrect(!nipWatch || nipWatch.length !== 10 || nipError ? false : true);
  }, [nipError, nipWatch]);

  useEffect(() => {
    setIsEdit(Boolean(companyId) || routeParams.role === UserRolesEnum.BUSINESS);
  }, [routeParams, companyId]);

  useEffect(() => {
    if (hasEntityCredentials && routeParams.companyId) fetchAccountingCredentials(routeParams.companyId);
  }, [hasEntityCredentials, routeParams.companyId]); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchCompanyData = (id: string) => {
    if (selectedCompanyId === id && selectedCompany) {
      setToFormData(selectedCompany);
      setHasEntityCredentials(selectedCompany.hasEntityCredentials);
    } else {
      setIsCompanyDataPending(true);
      getCompanyById(id)
        .then(data => {
          setHasEntityCredentials(data.hasEntityCredentials);
          setToFormData(data);
          setSelectedCompany(data, id);
        })
        .catch(err => {
          if (err.status === 404 || err.status === 403) {
            navigate('/page-not-found');
            return;
          }
          handleApiError(err.data);
        })
        .finally(() => setIsCompanyDataPending(false));
    }
  };

  const fetchAccountingCredentials = async (id: string) => {
    setIsAccountingCredentialsPending(true);

    try {
      const data = await getAccountingCredentials(id);
      setToFormCredentialsData(data);
    } catch (err) {
      handleApiError(err);
      console.error(err);
    }
    setIsAccountingCredentialsPending(false);
  };

  const onSubmit = (data: CompanyFormData): void => {
    const requestCompanyData = transformFormDataToCompanyData(data, companyId);
    const requestInsertData = transformFormDataToInsertData(data);

    setIsSavePending(true);
    if (routeParams.role) {
      editMyCompany(requestCompanyData);
      return;
    }
    if (companyId) {
      editCompany(requestCompanyData);
      editAccountingCredentials(companyId, requestInsertData);
      return;
    }
    createCompany(requestCompanyData);
  };

  const setToFormData = (data: CompanyData) => {
    const formDataValues = transformCompanyDataToFormData(data);
    setFormValues(setValue, formDataValues);
    setBankAccounts(data.bankAccounts);
    // bank account form data is set in useBankAccounts.ts hook
  };

  const setToFormCredentialsData = (data: InsertGetData) => {
    const { formDataValues } = transformInsertDataToFormData(data);

    setFormValues(setValue, formDataValues);
  };

  const createCompany = async (requestData: CompanyData) => {
    setIsSavePending(true);

    try {
      await createNewCompany(requestData);
      addHotjarEvent('Add company data', 'action');
      setLastParamsCompaniesList(null);
      navigate('/owner-company-list');
      snackbarMessagesHandler.companyCreated();
    } catch (err) {
      handleApiError(err);
      console.error(err);
    }
    setIsSavePending(false);
  };

  const editCompany = async (requestData: CompanyData) => {
    setIsSavePending(true);

    try {
      await updateCompany(requestData, companyId);
      addHotjarEvent('Edit company data', 'action');
      setLastParamsCompaniesList(null);
      setSelectedCompany(null, '');
      snackbarMessagesHandler.companyEdited();
      refreshStoredCompany(companyId);
      navigate(`/owner-company-list`);
    } catch (err) {
      handleApiError(err);
      console.error(err);
      err.forEach(er => {
        if (er.propertyName === errorCode.invoiceNumberFormatError) {
          reset({ keepDirtyValues: false }, { keepValues: true });
          watchedForm.invoiceNumberFormat === InvoiceNumberFormat.Monthly
            ? setValue('invoiceNumberFormat', InvoiceNumberFormat.Yearly)
            : setValue('invoiceNumberFormat', InvoiceNumberFormat.Monthly);
        }
      });
    }
    setIsSavePending(false);
  };

  const editAccountingCredentials = async (companyId: string, requestInsertData) => {
    setIsSavePending(true);

    try {
      await updateAccountingCredentials(companyId, requestInsertData);
      addHotjarEvent('Edit insERT credentials', 'action');
    } catch (err) {
      handleApiError(err);
      console.error(err);
    }
    setIsSavePending(false);
  };

  const fetchOwnerMyCompanyData = async () => {
    setIsPendingOwnerData(true);

    try {
      const data = await getMyCompany();
      setOwnerMyCompanyData(data);
      setToFormData(data);
    } catch (err) {
      handleApiError(err);
      console.error(err);
    }
    setIsPendingOwnerData(false);
  };

  const editMyCompany = (requestData: CompanyData) => {
    addHotjarEvent('User(owner) edit own data', 'action');
    updateMyCompany(requestData)
      .then(() => {
        snackbarMessagesHandler.companyEdited();
        addHotjarEvent('Edit company data by owner', 'action');
        refreshStoredCompany();
        navigate(`/contractors-list`);
      })
      .catch((err: RequestErrorMessage[]) => {
        console.error(err);
        handleApiError(err);
        err.forEach?.(er => {
          if (er.propertyName === errorCode.invoiceNumberFormatError) {
            reset({ keepDirtyValues: false }, { keepValues: true });
            watchedForm.invoiceNumberFormat === InvoiceNumberFormat.Monthly
              ? setValue('invoiceNumberFormat', InvoiceNumberFormat.Yearly)
              : setValue('invoiceNumberFormat', InvoiceNumberFormat.Monthly);
          }
        });
      })
      .finally(() => {
        setIsSavePending(false);
      });
  };

  const onFormCompletedManually = () => {
    setIsFetchedGusData(true);
    reset();
  };

  const onGusDataFetched = (data: GusCompanyData, nip: string) => {
    const { companyName, address = {} } = data;
    const formDataValues = {
      ...data,
      ...address,
      nip,
    };
    if (checkIsIncorrectDataFromGus(data)) {
      snackbarMessagesHandler.gusNoData();
      openDialogPopup(nip);
      return;
    }
    snackbarMessagesHandler.gusDataWasFetched();
    setIsFetchedGusData(true);
    setValue('name', companyName);
    setFormValues(setValue, formDataValues);
  };

  const openDialogPopup = (nip: string) => {
    setOpenDialogGusFetcher(true);
    setNip(nip);
  };

  const onCloseDialogGusFetcher = (agree: boolean) => {
    setOpenDialogGusFetcher(false);
    if (agree) {
      reset();
      setFormValues(setValue, { nip });
      setIsFetchedGusData(true);
    }
  };

  const onCloseDialogFormEdited = (agree: boolean) => {
    setOpenDialogFormEdited(false);
    if (!agree) {
      routeParams.companyId ? navigate(`/owner-company-list`) : navigate(`/contractors-list`);
      return;
    }
    handleSubmit(
      f => onSubmit(f as CompanyFormData),
      () => {
        if (Object.keys(errors).length) scrollToFirstError(Object.keys(errors) as string[]);
        snackbarMessagesHandler.contractorFormNeedCorrection();
      }
    )();
  };

  const onSubmitClick = () => {
    if (Object.keys(errors).length) scrollToFirstError(Object.keys(errors) as string[]);
    if (routeParams.role === UserRolesEnum.BUSINESS) {
      handleSubmit(f => onSubmit(f as CompanyFormData))();
      return;
    }
    const watchInsertInputs = watch(['dataBaseInsert', 'userNameInsert', 'passwordInsert']);

    transformInsertErrorForm(watchInsertInputs, register, unregister);
    handleSubmit(f => onSubmit(f as CompanyFormData))();
  };

  const onFetchGusDataClick = () => {
    if (!nipWatch || nipWatch.length !== 10 || nipError) {
      snackbarMessagesHandler.nipIsRequired();
      setIsNipCorrect(false);
      setIsNipEnabled(true);
      return;
    }
    setIsNipEnabled(false);
    fetchGusData(watchedForm.nip, isNipCorrect);
  };

  const onGusDataFetchedByOwner = (data: GusCompanyData, nip: string) => {
    const { companyName, address = {} } = data;
    const formDataValues = {
      ...data,
      ...address,
      nip,
    };
    if (checkIsIncorrectDataFromGus(data)) {
      snackbarMessagesHandler.gusNoData();
      setIsNipEnabled(true);
      return;
    }
    snackbarMessagesHandler.gusDataWasFetched();

    setIsNipEnabled(false);
    setIsFetchedGusData(true);
    setValue('name', companyName);
    setFormValues(setValue, formDataValues);

    clearErrors('name');
    if (formDataValues.address?.street) clearErrors('street');
    if (formDataValues.address?.city) clearErrors('city');
    if (formDataValues.address?.zipCode) clearErrors('zipCode');
    if (formDataValues.address?.zipCode) clearErrors('zipCode');
    if (formDataValues?.regon) clearErrors('regon');
  };

  const refreshStoredCompany = (id?: string) => {
    id
      ? getCompanyById(id)
      : getMyCompany()
          .then(data => setOwnerMyCompanyData(data))
          .catch((err: RequestErrorMessage[]) => handleApiError(err));
  };

  const getSuffixes = () => {
    if (routeParams?.companyId) return selectedCompany?.invoiceNumberSuffixes ?? [];
    if (location.pathname.includes('add-company')) return [];
    return ownerMyCompanyData?.invoiceNumberSuffixes ?? [];
  };

  const showUserForms = !routeParams.companyId && !location.pathname.includes('add-company');
  const showUserInsertForm = showUserForms && ownerMyCompanyData?.hasAccountingClients;

  const showAccountantForms = isDefined(routeParams.companyId) || location.pathname.includes('add-company');

  return (
    <div className="shared-company-form-wrapper standard-layout-spacing-g">
      <h1 className="title-g">Ustawienia</h1>
      <PageLoaderWrapper isLoading={isLoadingPage}>
        {isFetchedGusData || isEdit ? (
          <form className="shared-company-form" onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...useFormProps}>
              <CompanyDataSection isNipCorrect={isNipCorrect} isNipEnabled={isNipEnabled} fetchGusData={onFetchGusDataClick} />
              <BankDataSection accounts={bankAccounts} />
              <OwnerProfileSection />
              {showUserForms && <LoginSection />}
              <MySettingsSection suffixes={getSuffixes()} />
              {showUserInsertForm && <InsertSection />}
              {showAccountantForms && <AccountantInsertSection />}
              <ButtonMainNew
                testcy="save-changes"
                colorType="primary"
                sizeType="extra-big"
                isDisabled={isSavePending}
                onClick={onSubmitClick}
                margin="32px 0 0 auto"
              >
                Zapisz zmiany
              </ButtonMainNew>
            </FormProvider>
          </form>
        ) : (
          <GusFetcher onFormCompletedManually={onFormCompletedManually} onGusDataFetched={onGusDataFetched} />
        )}
      </PageLoaderWrapper>
      <TheDialog
        testcy="company-form-nip-not-found"
        open={openDialogGusFetcher}
        onClose={onCloseDialogGusFetcher}
        approveButtonText="Tak"
        rejectButtonText="Nie"
        approveButtonWidth="120px"
        rejectButtonWidth="120px"
        title={`Nie znaleziono podmiotu o numerze NIP ${nip}.`}
      >
        <div className="dialog-content">Czy na pewno chcesz dodać firmę o tym numerze?</div>
      </TheDialog>
      <TheDialog
        testcy="company-form-leave-without-saving"
        open={openDialogFormEdited}
        onClose={onCloseDialogFormEdited}
        approveButtonText="Zapisz i wyjdź"
        rejectButtonText="Tak"
        title="Czy chcesz opuścić formularz bez zapisania zmian?"
      >
        <></>
      </TheDialog>
    </div>
  );
};
