import { DateRange } from '@interfaces';
import { dateFormatter } from '@lib';
import { ChartSeries, Range, mapProfitLossChartData } from '@utils';
import { useMemo } from 'react';
import { useApiQuery } from '../useApiQuery';

interface IUseProfitsLosses {
  profitsLossesData: ChartSeries[];
  isLoading: boolean;
  costsSum: number;
  profitsSum: number;
}

export const useProfitsLosses = (dateRange: DateRange): IUseProfitsLosses => {
  const dateRangeString: Range<string> = useMemo(() => {
    return {
      from: dateFormatter.objectToString(dateRange.fromDate, "yyyy-MM-dd'T'HH:mm:ss"),
      to: dateFormatter.objectToString(dateRange.toDate, "yyyy-MM-dd'T'HH:mm:ss"),
    };
  }, [dateRange]);

  const { data: seriesData, isLoading } = useApiQuery(
    'getProfitsAndLosses',
    {
      dateFrom: dateRangeString.from,
      dateTo: dateRangeString.to,
    },
    {
      select: response => response ?? { dates: [], profits: [], losses: [] },
      keepPreviousData: true,
      placeholderData: { dates: [], profits: [], losses: [] },
    }
  );

  const profitsLossesData: ChartSeries[] = useMemo(() => mapProfitLossChartData(seriesData), [seriesData]);

  const profits = profitsLossesData.find((entry) => entry.name === "Przychody");
  const costs = profitsLossesData.find((entry) => entry.name === "Koszty");

  const profitsSum = profits.data.reduce((acc, entry) => acc + entry.y, 0);
  const costsSum = costs.data.reduce((acc, entry) => acc + entry.y, 0);

  return {
    profitsLossesData,
    isLoading,
    costsSum,
    profitsSum
  };
};
