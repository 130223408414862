import {
  AccountantStatusType,
  DeductVATEnum,
  DeductionInCostsEnum,
  DocumentFileCategoryEnum,
  InvoiceNumberFormat,
  InvoiceStatusType,
  PaymentStatusType,
  VatTemplateTypeEnum,
  VehicleOperationEnum,
  translateAccountantStatus,
  translateStatus
} from '@utils';
import { EmployeesContractEnum } from 'src/services/employees';

export const PaymentType = {
  None: 'None',
  Transfer: 'Transfer',
  Cash: 'Cash',
  TransferSplitPayment: 'TransferSplitPayment',
  Card: 'Card',
  Paid: 'Paid',
} as const;
export type PaymentType = typeof PaymentType[keyof typeof PaymentType];

export const paymentTypeOptions = [
  {
    label: 'Gotówka',
    value: PaymentType.Cash,
  },
  {
    label: 'Karta',
    value: PaymentType.Card,
  },
  {
    label: 'Przelew',
    value: PaymentType.Transfer,
  },
  {
    label: 'Przelew z podzieloną płatnością',
    value: PaymentType.TransferSplitPayment,
  },
  {
    label: 'Opłacona',
    value: PaymentType.Paid,
  },
];

export type PriceValueType = 'net' | 'gross';
export const priceValueType = [
  {
    label: 'Netto',
    value: 'net',
  },
  {
    label: 'Brutto',
    value: 'gross',
  },
];

export type VatOptionsType = '_23' | '_8' | '_5' | '_0' | 'ZW' | 'NP';
export const vatOptions = [
  {
    label: '23',
    value: '_23',
  },
  {
    label: '8',
    value: '_8',
  },
  {
    label: '5',
    value: '_5',
  },
  {
    label: '0',
    value: '_0',
  },
  {
    label: 'ZW',
    value: 'ZW',
  },
  {
    label: 'NP',
    value: 'NP',
  },
];
export type ZwOptionsType = 'Art113' | 'Art43' | 'Other';
export const ZwOptions = [
  {
    label: 'Zwolnienie ze względu na rodzaj prowadzonej działalności (art. 43 ust. 1 ustawy o VAT)',
    value: 'Art43',
  },
  {
    label: 'Zwolnienie ze względu na nieprzekroczenie 200 000 PLN obrotu (art. 113 ust. 1 i 9 ustawy o VAT)',
    value: 'Art113',
  },
  {
    label: 'Inne...',
    value: 'Other',
  },
];

export type GtuOptionsType =
  | 'GTU_01'
  | 'GTU_02'
  | 'GTU_03'
  | 'GTU_04'
  | 'GTU_05'
  | 'GTU_06'
  | 'GTU_07'
  | 'GTU_08'
  | 'GTU_09'
  | 'GTU_10'
  | 'GTU_11'
  | 'GTU_12'
  | 'GTU_13';

export const gtuOptions = [
  {
    label: '01',
    value: 'GTU_01',
  },
  {
    label: '02',
    value: 'GTU_02',
  },
  {
    label: '03',
    value: 'GTU_03',
  },
  {
    label: '04',
    value: 'GTU_04',
  },
  {
    label: '05',
    value: 'GTU_05',
  },
  {
    label: '06',
    value: 'GTU_06',
  },
  {
    label: '07',
    value: 'GTU_07',
  },
  {
    label: '08',
    value: 'GTU_08',
  },
  {
    label: '09',
    value: 'GTU_09',
  },
  {
    label: '10',
    value: 'GTU_10',
  },
  {
    label: '11',
    value: 'GTU_11',
  },
  {
    label: '12',
    value: 'GTU_12',
  },
  {
    label: '13',
    value: 'GTU_13',
  },
];

export const invoiceNumberFormatTypeOptions = [
  { label: 'Roczny', value: InvoiceNumberFormat.Yearly },
  { label: 'Miesięczny', value: InvoiceNumberFormat.Monthly },
];

export const businessEntityOptions = [
  { label: 'Osoba fizyczna', value: 'person' },
  { label: 'Firma', value: 'company' },
];

export const LegalPersonalityType = {
  CorporatePerson: 'CorporatePerson',
  NaturalPerson: 'NaturalPerson',
} as const;
export type LegalPersonalityType = typeof LegalPersonalityType[keyof typeof LegalPersonalityType];

export const legalPersonalityOptions = [
  { label: 'Osoba prawna', value: 'CorporatePerson' },
  { label: 'Osoba fizyczna', value: 'NaturalPerson' },
];

export const YesOrNo = {
  Yes: "tak",
  No: "nie",
} as const;
export type YesOrNo = typeof YesOrNo[keyof typeof YesOrNo];

export const yesNoOptions = [
  { label: 'Tak', value: YesOrNo.Yes },
  { label: 'Nie', value: YesOrNo.No },
];

export const invoiceStatusOwnerOptions = [
  {
    label: translateStatus(InvoiceStatusType.Draft),
    value: InvoiceStatusType.Draft,
  },
  {
    label: translateStatus(InvoiceStatusType.Issued),
    value: InvoiceStatusType.Issued,
  },
  {
    label: translateStatus(InvoiceStatusType.Overdue),
    value: InvoiceStatusType.Overdue,
  },
  {
    label: translateStatus(InvoiceStatusType.Sent),
    value: InvoiceStatusType.Sent,
  },
  {
    label: translateStatus(InvoiceStatusType.PartiallyPaid),
    value: InvoiceStatusType.PartiallyPaid,
  },
  {
    label: translateStatus(InvoiceStatusType.Paid),
    value: InvoiceStatusType.Paid,
  },
];

export const invoiceStatusAccountantOptions = [
  {
    label: translateStatus(AccountantStatusType.New),
    value: AccountantStatusType.New,
  },
  {
    label: translateStatus(AccountantStatusType.Sending),
    value: AccountantStatusType.Sending,
  },
  {
    label: translateStatus(AccountantStatusType.SentToAccountingService),
    value: AccountantStatusType.SentToAccountingService,
  },
];

export const invoiceAccountantStatusShortOptions = [
  {
    label: translateAccountantStatus(AccountantStatusType.New),
    value: AccountantStatusType.New,
  },
  {
    label: translateAccountantStatus(AccountantStatusType.Sending),
    value: AccountantStatusType.Sending,
  },
  {
    label: translateAccountantStatus(AccountantStatusType.SentToAccountingService),
    value: AccountantStatusType.SentToAccountingService,
  },
  {
    label: translateAccountantStatus(AccountantStatusType.Confirmed),
    value: AccountantStatusType.Confirmed,
  },
];
export const costOwnerStatusOptions = [
  {
    label: 'Brak',
    value: PaymentStatusType.Empty,
  },
  {
    label: translateStatus(PaymentStatusType.Paid),
    value: PaymentStatusType.Paid,
  },
  {
    label: translateStatus(PaymentStatusType.PartiallyPaid),
    value: PaymentStatusType.PartiallyPaid,
  },
  {
    label: translateStatus(PaymentStatusType.Unpaid),
    value: PaymentStatusType.Unpaid,
  },
];

export const vatTemplateOptions = [
  {
    label: 'KPN Korekta podatku naliczonego (nieterminowe płatności, art.89b ust.1)',
    value: VatTemplateTypeEnum.KPN,
  },
  {
    label: 'KPN-PZ Korekta podatku naliczonego (ponowne zaliczenie do VAT, art. 89b ust.4)',
    value: VatTemplateTypeEnum.KPN_PZ,
  },
  {
    label: 'NK Nabycie krajowe',
    value: VatTemplateTypeEnum.NK,
  },
  {
    label: 'NK-K Korekta podatku naliczonego od pozostałych nabyć',
    value: VatTemplateTypeEnum.NK_K,
  },
  {
    label: 'NKST Nabycie krajowe środka trwałego',
    value: VatTemplateTypeEnum.NKST,
  },
  {
    label: 'NKST-K Korekta podatku naliczonego od nabyć środków trwałych',
    value: VatTemplateTypeEnum.NKST_K,
  },
  {
    label: 'OO-DT-ptk Odwrotne obciążenie - dostawa towarów (art. 17 ust. 1 ptk 5 dostawca nie posiada siedziby na terytorium kraju)',
    value: VatTemplateTypeEnum.OO_DT,
  },
  {
    label: 'WNT Wewnątrzwspólnotowe nabycie towarów',
    value: VatTemplateTypeEnum.WNT,
  },
  {
    label: 'WNT-ST Wewnątrzwspólnotowe nabycie towarów - środek transportu',
    value: VatTemplateTypeEnum.WNT_ST,
  },
  {
    label: 'WTT-N Transakcja trójstronna - nabycie',
    value: VatTemplateTypeEnum.WTT_N,
  },
];

export const typeOfEmploymentOptions = [
  { label: 'Umowa o pracę', value: EmployeesContractEnum.EmploymentContract },
  { label: 'Umowa cywilnoprawna', value: EmployeesContractEnum.CivilLawContract },
];

export const allowVehicleOptions = [
  { label: 'Nie', value: VehicleOperationEnum.NONE },
  { label: 'Eksploatacja pojazdu', value: VehicleOperationEnum.EXPLOITATION },
  { label: 'Leasing', value: VehicleOperationEnum.LEASING },
];
export const deductVATOptions = [
  { label: 'Częściowe odliczenie (50%)', value: DeductVATEnum.PARTIALLY_DEDUCTIBLE },
  { label: 'Pełne odliczenie (100%)', value: DeductVATEnum.FULLY_DEDUCTIBLE },
];
export const inCostsOptions = [
  { label: '100%', value: DeductionInCostsEnum.FULLY },
  { label: '75% (pojazd firmowy)', value: DeductionInCostsEnum.PARTIALLY_75 },
  { label: '20% (pojazd wspólnika)', value: DeductionInCostsEnum.PARTIALLY_20 },
  { label: 'Leasing proporcjonalnie', value: DeductionInCostsEnum.PARTIALLY_LEASING },
];

export const documentFileCategoryOptions = [
  {
    label: 'Książki Przychodów i Rozchodów',
    value: DocumentFileCategoryEnum.REVENUES_EXPENDITURES,
  },
  {
    label: 'Rachunek Zysków i Strat',
    value: DocumentFileCategoryEnum.PROFITS_LOSSES,
  },
  {
    label: 'Bilans',
    value: DocumentFileCategoryEnum.BALANCE_SHEET,
  },
  {
    label: 'Kadry',
    value: DocumentFileCategoryEnum.PERSONNEL,
  },
  {
    label: 'Pisma urzędowe',
    value: DocumentFileCategoryEnum.OFFICIAL_LETTERS,
  },
];

export let countries = [
  { PL: 'Poland' },
  { AF: 'Afghanistan' },
  { AL: 'Albania' },
  { DZ: 'Algeria' },
  { AS: 'American Samoa' },
  { AD: 'Andorra' },
  { AO: 'Angola' },
  { AI: 'Anguilla' },
  { AG: 'Antigua and Barbuda' },
  { AR: 'Argentina' },
  { AM: 'Armenia' },
  { AW: 'Aruba' },
  { AU: 'Australia' },
  { AT: 'Austria' },
  { AZ: 'Azerbaijan' },
  { BS: 'Bahamas' },
  { BH: 'Bahrain' },
  { BD: 'Bangladesh' },
  { BB: 'Barbados' },
  { BY: 'Belarus' },
  { BE: 'Belgium' },
  { BZ: 'Belize' },
  { BJ: 'Benin' },
  { BM: 'Bermuda' },
  { BT: 'Bhutan' },
  { BO: 'Bolivia, Plurinational State of' },
  { BA: 'Bosnia and Herzegovina' },
  { BW: 'Botswana' },
  { BR: 'Brazil' },
  { IO: 'British Indian Ocean Territory' },
  { BG: 'Bulgaria' },
  { BF: 'Burkina Faso' },
  { BI: 'Burundi' },
  { KH: 'Cambodia' },
  { CM: 'Cameroon' },
  { CA: 'Canada' },
  { CV: 'Cape Verde' },
  { KY: 'Cayman Islands' },
  { CF: 'Central African Republic' },
  { TD: 'Chad' },
  { CL: 'Chile' },
  { CN: 'China' },
  { CO: 'Colombia' },
  { KM: 'Comoros' },
  { CG: 'Congo' },
  { CD: 'Democratic Republic of the Congo' },
  { CK: 'Cook Islands' },
  { CR: 'Costa Rica' },
  { CI: "Côte d'Ivoire" },
  { HR: 'Croatia' },
  { CU: 'Cuba' },
  { CW: 'Curaçao' },
  { CY: 'Cyprus' },
  { CZ: 'Czech Republic' },
  { DK: 'Denmark' },
  { DJ: 'Djibouti' },
  { DM: 'Dominica' },
  { DO: 'Dominican Republic' },
  { EC: 'Ecuador' },
  { EG: 'Egypt' },
  { SV: 'El Salvador' },
  { GQ: 'Equatorial Guinea' },
  { ER: 'Eritrea' },
  { EE: 'Estonia' },
  { ET: 'Ethiopia' },
  { FK: 'Falkland Islands (Malvinas)' },
  { FO: 'Faroe Islands' },
  { FJ: 'Fiji' },
  { FI: 'Finland' },
  { FR: 'France' },
  { PF: 'French Polynesia' },
  { GA: 'Gabon' },
  { GM: 'Gambia' },
  { GE: 'Georgia' },
  { DE: 'Germany' },
  { GH: 'Ghana' },
  { GI: 'Gibraltar' },
  { GR: 'Greece' },
  { GL: 'Greenland' },
  { GD: 'Grenada' },
  { GU: 'Guam' },
  { GT: 'Guatemala' },
  { GG: 'Guernsey' },
  { GN: 'Guinea' },
  { GW: 'Guinea-Bissau' },
  { HT: 'Haiti' },
  { HN: 'Honduras' },
  { HK: 'Hong Kong' },
  { HU: 'Hungary' },
  { IS: 'Iceland' },
  { IN: 'India' },
  { ID: 'Indonesia' },
  { IR: 'Iran, Islamic Republic of' },
  { IQ: 'Iraq' },
  { IE: 'Ireland' },
  { IM: 'Isle of Man' },
  { IL: 'Israel' },
  { IT: 'Italy' },
  { JM: 'Jamaica' },
  { JP: 'Japan' },
  { JE: 'Jersey' },
  { JO: 'Jordan' },
  { KZ: 'Kazakhstan' },
  { KE: 'Kenya' },
  { KI: 'Kiribati' },
  { KP: 'North Korea' },
  { KR: 'South Korea' },
  { KW: 'Kuwait' },
  { KG: 'Kyrgyzstan' },
  { LA: "Lao People's Democratic Republic" },
  { LV: 'Latvia' },
  { LB: 'Lebanon' },
  { LS: 'Lesotho' },
  { LR: 'Liberia' },
  { LY: 'Libya' },
  { LI: 'Liechtenstein' },
  { LT: 'Lithuania' },
  { LU: 'Luxembourg' },
  { MO: 'Macao' },
  { MK: 'Republic of Macedonia' },
  { MG: 'Madagascar' },
  { MW: 'Malawi' },
  { MY: 'Malaysia' },
  { MV: 'Maldives' },
  { ML: 'Mali' },
  { MT: 'Malta' },
  { MH: 'Marshall Islands' },
  { MQ: 'Martinique' },
  { MR: 'Mauritania' },
  { MU: 'Mauritius' },
  { MX: 'Mexico' },
  { FM: 'Micronesia, Federated States of' },
  { MD: 'Republic of Moldova' },
  { MC: 'Monaco' },
  { MN: 'Mongolia' },
  { ME: 'Montenegro' },
  { MS: 'Montserrat' },
  { MA: 'Morocco' },
  { MZ: 'Mozambique' },
  { MM: 'Myanmar' },
  { NA: 'Namibia' },
  { NR: 'Nauru' },
  { NP: 'Nepal' },
  { NL: 'Netherlands' },
  { NZ: 'New Zealand' },
  { NI: 'Nicaragua' },
  { NE: 'Niger' },
  { NG: 'Nigeria' },
  { NU: 'Niue' },
  { NF: 'Norfolk Island' },
  { MP: 'Northern Mariana Islands' },
  { NO: 'Norway' },
  { OM: 'Oman' },
  { PK: 'Pakistan' },
  { PW: 'Palau' },
  { PS: 'Palestinian Territory' },
  { PA: 'Panama' },
  { PG: 'Papua New Guinea' },
  { PY: 'Paraguay' },
  { PE: 'Peru' },
  { PH: 'Philippines' },
  { PN: 'Pitcairn' },
  { PT: 'Portugal' },
  { PR: 'Puerto Rico' },
  { QA: 'Qatar' },
  { RO: 'Romania' },
  { RU: 'Russia' },
  { RW: 'Rwanda' },
  { KN: 'Saint Kitts and Nevis' },
  { LC: 'Saint Lucia' },
  { WS: 'Samoa' },
  { SM: 'San Marino' },
  { ST: 'Sao Tome and Principe' },
  { SA: 'Saudi Arabia' },
  { SN: 'Senegal' },
  { RS: 'Serbia' },
  { SC: 'Seychelles' },
  { SL: 'Sierra Leone' },
  { SG: 'Singapore' },
  { SX: 'Sint Maarten' },
  { SK: 'Slovakia' },
  { SI: 'Slovenia' },
  { SB: 'Solomon Islands' },
  { SO: 'Somalia' },
  { ZA: 'South Africa' },
  { SS: 'South Sudan' },
  { ES: 'Spain' },
  { LK: 'Sri Lanka' },
  { SD: 'Sudan' },
  { SR: 'Suriname' },
  { SZ: 'Swaziland' },
  { SE: 'Sweden' },
  { CH: 'Switzerland' },
  { SY: 'Syria' },
  { TW: 'Taiwan' },
  { TJ: 'Tajikistan' },
  { TZ: 'Tanzania' },
  { TH: 'Thailand' },
  { TG: 'Togo' },
  { TK: 'Tokelau' },
  { TO: 'Tonga' },
  { TT: 'Trinidad and Tobago' },
  { TN: 'Tunisia' },
  { TR: 'Turkey' },
  { TM: 'Turkmenistan' },
  { TC: 'Turks and Caicos Islands' },
  { TV: 'Tuvalu' },
  { UG: 'Uganda' },
  { UA: 'Ukraine' },
  { AE: 'United Arab Emirates' },
  { GB: 'United Kingdom' },
  { US: 'United States' },
  { UY: 'Uruguay' },
  { UZ: 'Uzbekistan' },
  { VU: 'Vanuatu' },
  { VE: 'Venezuela, Bolivarian Republic of' },
  { VN: 'Viet Nam' },
  { VI: 'Virgin Islands' },
  { YE: 'Yemen' },
  { ZM: 'Zambia' },
  { ZW: 'Zimbabwe' },
];
