import React from 'react';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import { FormControlLabel, Checkbox } from '@mui/material';
import './theCheckbox.scss';

export const CheckboxAppearance = {
  Old: 'old',
  Primary: 'primary',
} as const;
export type CheckboxAppearance = typeof CheckboxAppearance[keyof typeof CheckboxAppearance];

interface Props {
  name: string;
  testcy: string;
  control: Control;
  label?: string;
  disabled?: boolean;
  defaultValue?: boolean;
  margin?: string;
  validation?: RegisterOptions;
  customColor?: string;
  size?: 'small' | 'medium';
  appearance?: CheckboxAppearance;
  onKeyUpHandle?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
}

export const TheCheckbox = ({
  name,
  testcy,
  control,
  label = '',
  disabled = false,
  defaultValue = false,
  margin = '0',
  validation = {},
  customColor = '',
  size = 'medium',
  appearance = CheckboxAppearance.Old,
  onKeyUpHandle,
}: Props) => {
  const onKeyUpHandler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (onKeyUpHandle) {
      onKeyUpHandle(e);
    }
  };

  const customColorClass = `custom-${customColor}`;

  return (
    <div className={`checkbox-wrapper-component ${label ? '' : 'non-label-checkbox'} appearance-${appearance}`} style={{ margin: margin }}>
      <div className="hidden-g"></div>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={validation}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={
              <Checkbox
                id={name}
                inputProps={
                  {
                    'data-testcy': testcy,
                  } as any
                }
                classes={{ root: ` ${customColor ? customColorClass : 'root-color'}` }}
                value={value}
                checked={value}
                onChange={onChange}
                disabled={disabled}
                onKeyUp={e => onKeyUpHandler(e)}
                onClick={e => e.stopPropagation()}
                size={size}
              />
            }
            label={label}
          />
        )}
      />
    </div>
  );
};
