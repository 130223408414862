import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { CostAccountingSettings } from '@services';

interface CostStore {
  costAccountingSettings: CostAccountingSettings | null;
  setCostAccountingSettings: (data: CostAccountingSettings | null) => void;
  costId: string;
  isOpenCostDrawer: boolean;
  isOpenCostPreview: boolean;
  setOpenCostDrawer: (isOpen: boolean, costId: string) => void;
  setOpenCostPreview: (isOpen: boolean, costId: string) => void;
}

export const useCostStore = create<CostStore>(set => ({
  costAccountingSettings: null,
  setCostAccountingSettings: data => set(() => ({ costAccountingSettings: data })),
  isOpenCostDrawer: false,
  isOpenCostPreview: false,
  costId: null,
  setOpenCostDrawer: (isOpen: boolean, costId: string) => set(() => ({ isOpenCostDrawer: isOpen, costId: costId })),
  setOpenCostPreview: (isOpen: boolean, costId: string) => set(() => ({ isOpenCostPreview: isOpen, costId: costId })),
}));

if (process.env.NODE_ENV === 'development') mountStoreDevtool('CostStore', useCostStore);

// for future use: remember to set setSelectedInvoice as null!!
