import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonMain, SearchBarValues, TheDialog, TheTableNew, TableRowData } from '@components';
import { ContractorsOrderOptions, ContractorsListData, GetContractorsParams, deleteContractorById } from '@services';
import { SortDirection, handleApiError, snackbarMessagesHandler } from '@utils';
import { useApiQuery, useDebounce, useIsAllFalse, usePagination } from '@hooks';
import { useListParamsStore } from '@store';
import { RequestErrorMessage } from '@interfaces';
import { contractorDataToTableRow, tableHeader, transformMenuItemsCostsList } from './sharedContractorsList.utils';
import './sharedContractorsList.scoped.scss';
import { ListSearchBar } from 'src/components/combined';
import { addHotjarEvent } from '@lib';
import cleaningPic from '@assets/images/cleaning_angle.png';

export const SharedContractorsList = () => {
  const testcy = 'contractors-table';
  const { page, setPage, totalRows, setTotalRows, rowsPerPage, setRowsPerPage } = usePagination();

  const [searchValue, setSearchValue] = useState('');
  const [orderBy, setOrderBy] = useState<ContractorsOrderOptions>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASCENDING);
  const [rows, setRows] = useState([]);
  const [isPendingData, setIsPendingData] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [isCheckedStore, setIsCheckedStore] = useState(false);
  const [listParams, setListParams] = useState<GetContractorsParams>();
  const [searchBarDefaultValues, setSearchBarDefaultValues] = useState<SearchBarValues>(null);
  const [selectedContractor, setSelectedContractor] = useState<TableRowData>(null);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);
  const [menuItemsList, setMenuItemsList] = useState([]);

  const listSearchParams = useDebounce(listParams, 200);

  const navigate = useNavigate();

  const { lastParamsContractorList, setLastParamsContractorList } = useListParamsStore();

  const routeParams = useParams();

  const isPending = useIsAllFalse([isPendingData, isPendingDelete]);

  useEffect(() => {
    if (lastParamsContractorList) {
      setPage(lastParamsContractorList.page);
      setSortDirection(lastParamsContractorList.sortDirection);
      setOrderBy(lastParamsContractorList.orderBy);
      if (lastParamsContractorList.searchBarValues) setSearchBarDefaultValues(lastParamsContractorList.searchBarValues);
      setLastParamsContractorList(null);
    }
    setIsCheckedStore(true);
  }, []);

  useEffect(() => {
    if (!isCheckedStore) return;
    setCompanyId(routeParams.companyId);
    if (companyId || !routeParams.companyId) modifyParams();
  }, [isCheckedStore, companyId, searchValue, page, orderBy, sortDirection, rowsPerPage]);

  const modifyParams = () => {
    const params: GetContractorsParams = {
      companyId: +routeParams.companyId || null,
      direction: sortDirection,
      pageNumber: page,
      pageSize: rowsPerPage,
    };

    if (orderBy) params.orderBy = orderBy;
    if (searchValue) params.textFilter = searchValue;

    setListParams(params);
  };

  const onClickRemove = (data: TableRowData) => {
    setSelectedContractor(data);
    setOpenRemoveDialog(true);
  };

  const onCloseRemoveDialog = (agree: boolean) => {
    setOpenRemoveDialog(false);

    if (agree) {
      deleteContractor(selectedContractor.id);
      addHotjarEvent('remove contractor');
    }
  };

  const deleteContractor = (id: string) => {
    setIsPendingDelete(true);
    deleteContractorById(id)
      .then(() => {
        snackbarMessagesHandler.contractorWasDeleted();
        refetch();
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingDelete(false));
  };
  const navigateToCompanyListOrContractorList = (data?: TableRowData) => {
    if (data) {
      if (companyId) {
        navigate(`/owner-company-list/details/${companyId}/edit/${data.id}`);
      } else {
        navigate(`/contractors-list/edit/${data.id}`);
      }
    } else {
      if (companyId) {
        navigate(`/owner-company-list/details/${companyId}/add`);
      } else {
        navigate('/contractors-list/add');
      }
    }
  };
  const onClickEdit = (data: TableRowData) => {
    addHotjarEvent('edit contractor');
    saveToStateParams();
    navigateToCompanyListOrContractorList(data);
  };

  const onClickAddContractor = () => {
    saveToStateParams();
    addHotjarEvent('add contractor');
    navigateToCompanyListOrContractorList();
  };

  const onChangePagination = (data: { page: number; rowsPerPage: number }) => {
    setPage(data.page);
    setRowsPerPage(data.rowsPerPage);
  };

  const onFilterChange = (data: SearchBarValues) => {
    setSearchValue(data.textFilter);
    setPage(1);
  };

  const { refetch } = useApiQuery(
    'getContractors',
    { ...listSearchParams, companyId: +routeParams.companyId || null },
    {
      onSuccess: result => {
        generateRows(result.data);
        setMenuItemsList(transformMenuItemsCostsList(result.data));
        setTotalRows(result.totalCount);
      },
      keepPreviousData: true,
    }
  );

  const onClickMenuItem = (action: string, row: TableRowData) => {
    if (action === 'edit') return onClickEdit(row);
    if (action === 'delete') return onClickRemove(row);
  };

  function generateRows(list: ContractorsListData[]) {
    const rows = list.map((data: ContractorsListData) => {
      const tranformedDataToRow = contractorDataToTableRow(data);
      return tranformedDataToRow;
    });
    setRows(rows);
  }

  const saveToStateParams = () => {
    setLastParamsContractorList({
      sortDirection,
      page,
      orderBy,
      searchBarValues: {
        textFilter: searchValue,
      },
      itemsPerPage: rowsPerPage,
    });
  };

  const onChangeSort = (sortType: SortDirection, propertyName: string) => {
    setSortDirection(sortType);
    setOrderBy(propertyName.toLowerCase() as ContractorsOrderOptions);
  };

  return (
    <div className={`contractors-list-wrapper ${companyId ? '' : 'standard-layout-spacing-g'} `}>
      {!companyId && <h1 className="title-g">Lista kontrahentów</h1>}
      <TheDialog testcy="invoice-delete-confirmation" open={openRemoveDialog} onClose={onCloseRemoveDialog} approveButtonText="Tak" rejectButtonText="Nie">
        <div className="inbox-dialog-container">
          <img src={cleaningPic} alt="cleaning-set" width={111} height={128} />
          <div>Czy na pewno chcesz usunąć kontrahenta</div>
          <div className="remove-dialog-content-document">
            <div>
              {selectedContractor?.stashedData?.name} <span className="question-mark">?</span>
            </div>
          </div>
        </div>
      </TheDialog>

      <div className="contractors-list-header">
        <ListSearchBar onChange={onFilterChange} isPending={isPending} setPage={setPage} showSearchBar defaultValues={searchBarDefaultValues} />
        <div className="button-loader-wrapper">
          <ButtonMain testcy="add-contractor-button" content={'Dodaj kontrahenta'} width="250px" onClick={onClickAddContractor} />
        </div>
      </div>
      <div className="table-wrapper-g contractors-table">
        <TheTableNew
          testcy={testcy}
          header={tableHeader}
          rows={rows}
          actionMenuItems={menuItemsList}
          defaultSortProp={orderBy}
          defaultSortType={sortDirection}
          onClickMenuItem={onClickMenuItem}
          onChangeSort={onChangeSort}
          totalRows={totalRows}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePagination={onChangePagination}
        />
      </div>
    </div>
  );
};
