import React from 'react';

interface Props {
  name: string;
  amount: number;
  className: string;
}

export const SingleBarChartTooltip = ({ name, amount, className }: Props) => {
  const formattedAmount = amount.toLocaleString('pl-PL', {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <div className="single-bar-chart-tooltip-container">
      <div className={`single-bar-chart-tooltip-type ${className}`}></div>
      <div className="single-bar-chart-tooltip-name">{name}:</div>
      <div className="single-bar-chart-tooltip-amount">{formattedAmount} PLN</div>
    </div>
  );
};;