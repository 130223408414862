import { API_REGIONS, Client } from '@amityco/ts-sdk';
import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useAmitySessionState } from '../hooks/useAmitySessionState';
import { ChatState, useChatState } from '../hooks/useChatState';
import { Channel, useChannelList } from '../hooks/useChannelList';
import { login } from '../services/authentication';
import { useUserStore } from '@store';
import { appConfig } from 'src/appConfig';

const client = Client.createClient(appConfig.amity.apiKey, API_REGIONS.EU);

type ChatContextValue = {
  isConnected: boolean;
  currentUserId: string | undefined;
  chatState: ChatState;
  changeState: (newState: ChatState) => void;
  channels: Channel[];
  currentChannelId: string;
  changeChannel: (newChannelId: string) => void;
};

const ChatContext = React.createContext<ChatContextValue>({
  isConnected: false,
  currentUserId: undefined,
  chatState: 'closed',
  changeState: () => {},
  channels: [],
  currentChannelId: '',
  changeChannel: () => {},
});
export const useChatContext = () => useContext(ChatContext);

export const ChatContextProvider = ({ children }: PropsWithChildren) => {
  const { isConnected } = useAmitySessionState();
  const { chatState, changeState } = useChatState();
  const channels = useChannelList();
  const [currentChannelId, changeChannel] = useState('');
  const { userData } = useUserStore();

  useEffect(() => {
    if (!userData) return;

    login();
  }, [userData]);

  const value = useMemo(
    () => ({
      isConnected,
      currentUserId: client.userId,
      chatState,
      changeState,
      channels,
      currentChannelId,
      changeChannel,
    }),
    [isConnected, chatState, changeState, channels, currentChannelId, changeChannel]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
