import { ChevronRightThick } from '@components';
import { Breadcrumbs } from '@mui/material';
import { isDefined } from '@utils';
import React, { useMemo } from 'react';
import { NavLink, useMatches } from 'react-router-dom';
import { RouteMatch } from '@routes';
import { isFunction, isString } from 'lodash';

import './theBreadcrumbs.scss';

export const TheBreadcrumbs = () => {
  const matches = useMatches();
  const crumbs = useMemo(() => {
    return matches
      .map(match => {
        return {
          handle: match.handle,
          pathname: match.pathname,
          data: match.data,
        } as RouteMatch;
      })
      .filter(match => isDefined(match.handle?.crumb))
      .map(match => {
        const crumb = match.handle.crumb;
        const crumbDataOrString = isFunction(crumb) ? crumb(match.data) : crumb;
        const crumbData = isString(crumbDataOrString) ? { label: crumbDataOrString } : crumbDataOrString;
        return {
          ...crumbData,
          pathname: match.pathname,
        };
      });
  }, [matches]);

  return (
    <Breadcrumbs className="breadcrumbs" separator={<ChevronRightThick />}>
      <NavLink key="home" to="/dashboard" tabIndex={crumbs.length > 0 ? 0 : -1} data-testcy="breadcrumb-home" className="breadcrumb-link">
        Panel
      </NavLink>
      {crumbs.map(({ label, pathname, noEllipsis }, index) => {
        return (
          <NavLink
            key={pathname}
            to={pathname}
            tabIndex={index + 1 < crumbs.length ? 0 : -1}
            data-testcy={`breadcrumb-${index}`}
            className={`breadcrumb-link ${noEllipsis ? '' : 'use-ellipsis'}`}
          >
            <>{label}</>
          </NavLink>
        );
      })}
    </Breadcrumbs>
  );
};
