import { ButtonMain, DatePicker } from '@components';
import { formErrorMsg } from '@utils';
import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import './invoiceCreateDuplicateForm.scoped.scss';
import { CreateDuplicateFormData } from './invoiceCreateDuplicateForm.utils';
import { dateFormatter } from '@lib';
import { InvoiceDetails } from '@services';

interface Props {
  onSubmit: (data: CreateDuplicateFormData) => void;
  invoiceData: InvoiceDetails;
}

export const InvoiceCreateDuplicateForm = ({ invoiceData, onSubmit }: Props) => {
  const [openPicker, setOpenPicker] = useState(false);

  const { handleSubmit, control, clearErrors, trigger } = useForm<FieldValues | CreateDuplicateFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: { createDuplicateDate: new Date() },
  });

  return (
    <form className="w-100-g" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-wrapper">
        <DatePicker
          testcy="invoice-duplicate-form-date"
          control={control}
          name="createDuplicateDate"
          openPicker={openPicker}
          setOpenPicker={setOpenPicker}
          trigger={trigger}
          clearErrors={clearErrors}
          label="Data stworzenia duplikatu"
          width="100%"
          minDate={new Date(invoiceData.issueDate)}
          appearance='light'
          validation={{
            required: formErrorMsg.isRequired,
            validate: value => {
              const formattedDate1 = dateFormatter.getDateObjectFromString(dateFormatter.standardFormat(invoiceData.issueDate));
              const getDiff = dateFormatter.diffBetweenDates(formattedDate1, value);
              if (getDiff > 0) {
                return formErrorMsg.duplicateInvoiceDate;
              }
              return dateFormatter.isValidDateObject(value) || formErrorMsg.incorrectDate;
            },
          }}
        />
        <div className="form-footer">
          <ButtonMain testcy="duplicate-invoice-form-" type="submit" content={'Wystaw duplikat'} width="220px" isDisabled={false} />
        </div>
      </div>
    </form>
  );
};
