import { formErrorMsg } from "@utils";
import { UseFormRegister, UseFormUnregister } from "react-hook-form";
import { CompanyFormData } from "../../sharedCompanyForm.utils";


export const transformInsertErrorForm = (watchForm: string[], register: UseFormRegister<CompanyFormData>, unregister: UseFormUnregister<CompanyFormData>) => {
  const isAllEmpty = watchForm.every((x: string) => x === '');
  const isAllHaveValue = watchForm.every((x: string) => x.length > 0);
  if (isAllEmpty || isAllHaveValue) {
    unregister('userNameInsert', { keepValue: true });
    unregister('dataBaseInsert', { keepValue: true });
    unregister('passwordInsert', { keepValue: true });
    register('userNameInsert', { required: false });
    register('dataBaseInsert', { required: false });
    register('passwordInsert', { required: false });
    return true;
  }

  const arr = ['dataBaseInsert', 'userNameInsert', 'passwordInsert'];

  watchForm.forEach((x: string, i: number) => {
    if (!x) {
      register(arr[i], {
        required: formErrorMsg.isRequired,
      });
    }
  });
};