import React from 'react';

interface Props {
  colorType?: 'black' | 'white' | 'primary' | 'disabled' | '';
  size?: string | number;
}

export const MessageCommentIcon = ({ colorType = 'black', size = 24 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6208 17.8119C16.875 19.8832 14.4251 20.9113 11.9769 20.9028C11.1195 20.8991 7.30018 20.902 5.04633 20.9028C4.37568 20.9028 3.97965 20.154 4.35234 19.5955C4.56063 19.2815 4.82777 18.9001 5.03886 18.603C5.24902 18.305 5.2677 17.9142 5.08556 17.5974C4.76518 17.0407 4.28882 16.1935 4.16459 15.8851C2.68507 12.6761 3.25577 8.75106 5.89631 6.10159C9.5092 2.47649 15.5412 2.73609 18.8067 6.88041C21.3155 10.0632 21.2324 14.7135 18.6208 17.8119Z"
        stroke="#101013"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
