export enum EnvironmentTypeEnum {
  LOCAL = 'local',
  QA = 'qa',
  DEV = 'dev',
  PROD = 'prod',
}

export const appConfig = {
  url: process.env.REACT_APP_URL,
  env: process.env.REACT_APP_ENVIRONMENT as EnvironmentTypeEnum,
  oidc: {
    clientId: process.env.REACT_APP_OIDC_CLIENT_ID,
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    redirectUri: process.env.REACT_APP_OIDC_REDIRECT_URL,
    scope: 'webcount-api roles offline_access',
  },
  googleAnalytics: {
    key: process.env.REACT_APP_GOOGLE_ANALITICS_KEY,
  },
  hotjar: {
    id: process.env.REACT_APP_HOTJAR_HJID,
    sv: process.env.REACT_APP_HOTJAR_HJSV,
  },
  owner: {
    domain: process.env.REACT_APP_OWNER_DOMAIN,
    name: process.env.REACT_APP_OWNER_COMPANY_NAME,
    address: process.env.REACT_APP_OWNER_ADDRESS,
    nip: process.env.REACT_APP_OWNER_NIP,
    regon: process.env.REACT_APP_OWNER_REGON,
    registrationCity: process.env.REACT_APP_OWNER_REGISTRATION_CITY,
    krs: process.env.REACT_APP_OWNER_KRS,
  },
  amity: {
    apiKey: process.env.REACT_APP_AMITY_API_KEY,
  },
} as const;
