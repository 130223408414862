import React from 'react';

import './settings.scss';

interface Props {
  title: string;
  children: React.ReactNode | React.ReactNode[];
}

export const SettingsTab = ({ title, children }: Props) => {
  return (
    <section className="settings-tab">
      <h2>{title}</h2>
      <div className="tab-content">
        {children}
      </div>
    </section>
  );
};
