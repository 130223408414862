import React, { useEffect } from 'react';
import { animated, config, useSpringRef, useTransition } from '@react-spring/web';
import { CloseIcon } from '@components';

import './invoiceBar.scoped.scss';

type Props = {
  invoiceNumber: string;
  onClose: () => void;
};

export const InvoiceBar = ({ invoiceNumber, onClose }: Props) => {
  const springRef = useSpringRef();
  const transitions = useTransition(invoiceNumber, {
    ref: springRef,
    from: { maxHeight: 0 },
    enter: { maxHeight: 100 },
    leave: { maxHeight: 0 },
    exitBeforeEnter: true,
    config: {
      ...config.stiff,
      duration: 200,
    },
  });

  useEffect(() => {
    springRef.start();
  }, [springRef, invoiceNumber]);

  return transitions(
    (style, item) =>
      !!item && (
        <animated.div style={style}>
          <div className="invoice-bar">
            <p>{item}</p>
            <button className="invoice-bar-close-button" data-testcy="chat-action-close" onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
        </animated.div>
      )
  );
};
