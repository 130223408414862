import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | 'black' | '';
  size?: number | string;
  isFilled?: boolean;
}

export const HeartIcon = ({ colorType = '', size = 24, isFilled = false }: Props) => {
  if (isFilled)
    return (
      <svg className={`icon-color-${colorType}-g`} width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0733 4.30973C15.4903 3.81245 13.3361 4.15901 12.0007 5.96093C10.7037 4.22141 8.54953 3.79037 6.91465 4.31069C3.34633 5.45597 2.24425 9.36317 3.24073 12.4793C4.85449 17.4963 10.4916 20.8956 12.0007 20.8956C13.5242 20.8956 19.1805 17.4963 20.7607 12.4793C21.7553 9.36413 20.6493 5.45693 17.0733 4.30973Z"
        />
      </svg>
    );
  return (
    <svg className={`icon-color-${colorType}-stroke`} width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.35196 12.3276C2.33622 9.15149 3.52346 5.52346 6.85586 4.44993C8.60971 3.88995 10.8875 4.50299 12.0009 6.63489C13.1417 4.47362 15.3844 3.89942 17.1364 4.44993C20.4688 5.52346 21.6655 9.15149 20.6488 12.3276C19.0665 17.3569 13.2971 20.7453 12.0009 20.7453C10.7047 20.7453 4.98926 17.4157 3.35196 12.3276Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
