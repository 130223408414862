import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const RevenuesIcon = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7366 0.761808H5.08465C3.00465 0.753837 1.30065 2.41084 1.25065 4.49084V15.2278C1.20565 17.3298 2.87365 19.0698 4.97465 19.1148C5.01165 19.1148 5.04865 19.1158 5.08465 19.1148H13.0726C15.1626 19.0408 16.8146 17.3188 16.8027 15.2278V6.03784L11.7366 0.761808Z"
        stroke="#101013"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.475 0.750122V3.65912C11.475 5.07912 12.624 6.23012 14.044 6.23412H16.798"
        stroke="#101013"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.64099 7.90881V13.9498" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.9866 10.2643L8.64163 7.9093L6.29663 10.2643" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
