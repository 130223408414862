import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const UsersIcon = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5.63714 19.2429C4.45847 19.2429 3.74622 18.3958 3.6958 17.2158C3.6958 14.8139 6.04264 13.9855 9.57866 13.9575C13.121 13.9948 15.4741 14.8232 15.4616 17.2158C15.4048 18.3958 14.6967 19.2429 13.5201 19.2429H5.63714Z"
        strokeWidth="1.44"
        strokeMiterlimit="10"
      />
      <path
        d="M17.897 18.5427H19.4002C20.3545 18.5427 20.9287 17.8556 20.9747 16.8986C20.9837 15.2121 19.5432 14.4839 17.2798 14.3022"
        strokeWidth="1.44"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.55996 10.9451C11.2683 10.9451 12.6531 9.56027 12.6531 7.85196C12.6531 6.14365 11.2683 4.75879 9.55996 4.75879C7.85165 4.75879 6.4668 6.14365 6.4668 7.85196C6.4668 9.56027 7.85165 10.9451 9.55996 10.9451Z"
        strokeWidth="1.44"
        strokeMiterlimit="10"
      />
      <path
        d="M17.2662 10.8092C18.3789 10.8092 19.2809 9.90716 19.2809 8.79447C19.2809 7.68179 18.3789 6.77979 17.2662 6.77979C16.1535 6.77979 15.2515 7.68179 15.2515 8.79447C15.2515 9.90716 16.1535 10.8092 17.2662 10.8092Z"
        strokeWidth="1.44"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
