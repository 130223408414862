import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | 'black' | '';
}

export const UploadIcon = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7366 2.76178H8.08465C6.00465 2.75381 4.30065 4.41081 4.25065 6.49081V17.2278C4.20565 19.3298 5.87365 21.0698 7.97465 21.1148C8.01165 21.1148 8.04865 21.1158 8.08465 21.1148H16.0726C18.1626 21.0408 19.8146 19.3188 19.8027 17.2278V8.03781L14.7366 2.76178Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.475 2.75011V5.65911C14.475 7.07911 15.624 8.23011 17.044 8.23411H19.798"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.641 9.90881V15.9498" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.9866 12.2643L11.6416 9.9093L9.29663 12.2643" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
