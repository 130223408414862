import { formatNumberToCurrency } from '@utils';

export interface EmailFormData {
  from: string;
  emailTo: string[];
  subject: string;
  emailMessage: string;
  sendCopy: boolean;
}

export const EmailMessage = (owner: string, invoiceNumber: string, amountToPay: number, dueDate: string) => {
  const massageFields = {
    subject: `${owner} wysłał fakturę (${invoiceNumber}) `,
    body: `${owner} wysłał fakturę ${invoiceNumber} na kwotę ${formatNumberToCurrency(amountToPay)}zł z terminem płatności do ${new Date(
      dueDate
    ).toLocaleDateString('pl-PL')}.`,
  };
  return massageFields;
};
