import { SharedInbox } from '@features';
import React from 'react';

export const InboxTab = () => {
  return (
    <div className="inbox-tab" style={{ marginTop: 30 }}>
      <SharedInbox />
    </div>
  );
};
