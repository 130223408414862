import { useOpenInNewTab } from '@hooks';
import { RequestErrorMessage } from '@interfaces';
import { dateFormatter } from '@lib';
import { InvoiceRelatedFiles, createInvoicePdfById, getInvoiceRelatedFiles } from '@services';
import { handleApiError } from '@utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useHref } from 'react-router-dom';
import { LinkedFileItem } from './components';

import './linkedFiles.scoped.scss';

interface Props {
  testcy: string;
  fileId: any;
}

export const LinkedFiles = ({ testcy, fileId }: Props) => {
  const [relatedFiles, setRelatedFiles] = useState<InvoiceRelatedFiles[]>([]);
  const [isPendingId, setIsPendingId] = useState(null);
  const { openInNewTab } = useOpenInNewTab();

  const href = useHref('file-preview', { relative: 'route' });

  useEffect(() => {
    fetchRelatedFiles();
  }, []);

  const fetchRelatedFiles = async () => {
    try {
      const files = await getInvoiceRelatedFiles(Number(fileId));
      setRelatedFiles(files);
    } catch (err) {
      handleApiError(err);
    }
  };
  const openWindow = useCallback((id: string, date: string | null) => {
    if (date) {
      setIsPendingId(false);
      openInNewTab(href + '/' + id + '?duplicateDate=' + date);
      return;
    }

    createInvoicePdfById(id, null)
      .then((data: Blob) => {
        if (!data) return;
        const blob = new Blob([data], { type: data.type });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          let pdfWindow = window.open('');
          pdfWindow.document.write("<iframe width='100%' height='100%' src=' " + encodeURI(base64data as string) + "'></iframe>");
        };
        setIsPendingId(null);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {});
  }, []);

  return (
    <div className="cost-files-container">
      <div className="cost-files-wrapper">
        <div className="cost-files-list">
          {relatedFiles.map(item => {
            const duplicateDate = dateFormatter.changeFormat(item?.duplicateDate as string, "yyyy-MM-dd'T'HH:mm:ss", 'dd.MM.yyyy');
            return (
              <LinkedFileItem
                testcy={testcy}
                key={item.id + Math.random()}
                name={`${item.number} ${item.duplicateDate ? '(duplikat z dnia ' + duplicateDate + ')' : ''}`}
                onClickRow={() => {
                  setIsPendingId(item.id);
                  openWindow(item.id.toString(), item?.duplicateDate);
                }}
                isPendingData={isPendingId === item.id}
              ></LinkedFileItem>
            );
          })}
        </div>
      </div>
    </div>
  );
};
