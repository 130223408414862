import { UserRolesEnum } from './enums';

export const NavIconType = {
  Contractors: 'contractors',
  Invoices: 'invoices',
  Taxes: 'taxes',
  Inbox: 'files',
  Home: 'home',
  Costs: 'costs',
  Revenues: 'revenues',
  Store: 'store',
  Users: 'users',
} as const;
export type NavIconType = typeof NavIconType[keyof typeof NavIconType];

type NavData = {
  route: string;
  name: string;
  permissions: UserRolesEnum[];
  icon: NavIconType;
  testcy: string;
};

export const navData: NavData[] = [
  {
    route: '/dashboard',
    name: 'Panel',
    permissions: [UserRolesEnum.BUSINESS],
    icon: NavIconType.Home,
    testcy: 'dashboard',
  },
  {
    route: '/costs-list',
    name: 'Koszty',
    permissions: [UserRolesEnum.BUSINESS],
    icon: NavIconType.Costs,
    testcy: 'costs',
  },
  {
    route: '/invoices-list',
    name: 'Przychody',
    permissions: [UserRolesEnum.BUSINESS],
    icon: NavIconType.Revenues,
    testcy: 'invoices',
  },
  {
    route: '/taxes',
    name: 'Podatki',
    permissions: [UserRolesEnum.BUSINESS],
    icon: NavIconType.Taxes,
    testcy: 'taxes',
  },
  {
    route: '/contractors-list',
    name: 'Kontrahenci',
    permissions: [UserRolesEnum.BUSINESS],
    icon: NavIconType.Contractors,
    testcy: 'contractors',
  },
  {
    route: '/owner-company-list',
    name: 'Lista firm',
    permissions: [UserRolesEnum.BUSINESS],
    icon: NavIconType.Store,
    testcy: 'company-list',
  },
  {
    route: '/employees-list',
    name: 'Pracownicy',
    permissions: [UserRolesEnum.BUSINESS],
    icon: NavIconType.Users,
    testcy: 'employees',
  },
  {
    route: '/documents',
    name: 'Dokumenty',
    permissions: [UserRolesEnum.BUSINESS],
    icon: NavIconType.Inbox,
    testcy: 'inbox',
  },
];
