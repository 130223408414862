import { TaxValueChange, TaxValueTable } from '@services';
import { stringToNumberSecondOptions, snackbarMessagesHandler, formatNumberToCurrency } from '@utils';
import { useDataSource } from './dataSource';
import { useParams } from 'react-router';
import { IBaseForm } from '@interfaces';
import { dateFormatter } from '@lib';
import { useTaxStore } from '@store';
import { TableHeaderInterface, TableRowInterface } from '@components';

export interface TaxValueFormData {}

export const useSharedTaxesForm = (props: IBaseForm) => {
  const { watch, reset } = props.formFeatures;
  const { taxValueSetManually } = useTaxStore();

  const routeParams = useParams();
  const month = watch('month');
  const { updateTaxValues } = useDataSource(+routeParams.companyId, month);

  const mapToRequestValues = (data: TaxValueFormData): TaxValueChange[] => {
    return Object.keys(data)
      .filter(x => isTaxValueProperty(x))
      .map(x => {
        const discr = getTaxDiscriminator(x);
        return {
          taxDiscriminator: discr,
          value: getTaxValue(data, x),
          dueDate: getTaxDueDate(data, discr + '-date'),
        };
      });

    function isTaxValueProperty(key: string): boolean {
      return key.match(/[a-zA-Z]-value/g) !== null;
    }

    function getTaxDiscriminator(key: string): string {
      return key.split('-')[0];
    }

    function getTaxValue(data: TaxValueFormData, key: string): number {
      return data[key] && taxValueSetManually.includes(key.slice(0, -6)) ? stringToNumberSecondOptions(data[key]) : null;
    }
    function getTaxDueDate(data: TaxValueFormData, key: string): string | null {
      return data[key] ? dateFormatter.objectToString(data[key] as Date, 'yyyy-MM-dd') : null;
    }
  };

  const onSubmit = async (data: TaxValueFormData) => {
    const values = mapToRequestValues(data);
    await updateTaxValues(values);
    snackbarMessagesHandler.taxValuesUpdated();
    reset(data);
  };

  return {
    onSubmit,
  };
};

export const tableHeader: TableHeaderInterface[] = [
  {
    label: '',
    data: 'month',
    width: 200,
    minWidth: 165,
    isSortable: false,
    customCellClass: 'taxes-table__month',
  },
  {
    label: 'Przychód podatkowy miesiąca',
    data: 'monthlyRevenue',
    width: 200,
    minWidth: 165,
    isSortable: false,
  },
  {
    label: 'Koszt podatkowy miesiąca',
    data: 'monthlyCost',
    width: 200,
    minWidth: 165,
    isSortable: false,
  },
  {
    label: 'Zysk/Strata miesiąca',
    data: 'monthlyProfit',
    width: 200,
    minWidth: 165,
    isSortable: false,
  },
  {
    label: 'Przychód podatkowy od początku roku',
    data: 'yearlyRevenue',
    width: 200,
    minWidth: 165,
    isSortable: false,
  },
  {
    label: 'Koszt podatkowy od początku roku',
    data: 'yearlyCost',
    width: 200,
    minWidth: 165,
    isSortable: false,
  },
  {
    label: 'Zysk/Strata od początku roku',
    data: 'yearlyProfit',
    width: 200,
    minWidth: 165,
    isSortable: false,
  },
  {
    label: 'Zaliczka CIT',
    data: 'cit',
    width: 200,
    minWidth: 165,
    isSortable: false,
  },
];

export const taxDataToTableRow = (dataRow: TaxValueTable, index: number, watchMonth: Date): TableRowInterface => {
  const fullMonth = new Date(2009, index, 10).toLocaleString('pl-PL', { month: 'long' });
  const fullYear = watchMonth.getFullYear();

  return {
    id: index.toString(),
    month: { content: `${fullMonth} ${fullYear}` },
    monthlyRevenue: { content: `${dataRow.monthly.revenue !== null ? formatNumberToCurrency(dataRow.monthly.revenue) : 'brak danych'}` },
    monthlyCost: { content: `${dataRow.monthly.cost !== null ? formatNumberToCurrency(dataRow.monthly.cost) : 'brak danych'}` },
    monthlyProfit: { content: `${dataRow.monthly.profit !== null ? formatNumberToCurrency(dataRow.monthly.profit) : 'brak danych'}` },
    yearlyRevenue: { content: `${dataRow.yearly.revenue !== null ? formatNumberToCurrency(dataRow.yearly.revenue) : 'brak danych'}` },
    yearlyCost: { content: `${dataRow.yearly.cost !== null ? formatNumberToCurrency(dataRow.yearly.cost) : 'brak danych'}` },
    yearlyProfit: { content: `${dataRow.yearly.profit !== null ? formatNumberToCurrency(dataRow.yearly.profit) : 'brak danych'}` },
    cit: { content: `${dataRow.cit !== null ? formatNumberToCurrency(dataRow.cit) : 'brak danych'}` },
  };
};
