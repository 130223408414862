import { LoaderIcon } from '@components';
import { FormControl, InputAdornment, InputLabel, TextField } from '@mui/material';
import { HelperTextAlignment } from '@utils';
import { isArray } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Control, Controller, FieldValues, RegisterOptions, UseFormWatch } from 'react-hook-form';
import './numberInput.scss';
import { formatNumber, formatNumberOnBlur } from './numberInput.utils';
import { FormElementAppearance, FormElementSize } from '../types';

interface Props {
  name: string;
  testcy: string;
  appearance?: FormElementAppearance;
  size?: FormElementSize;
  control?: Control;
  width?: string;
  margin?: string;
  disabled?: boolean;
  readOnly?: boolean;
  validation?: RegisterOptions;
  float?: boolean;
  negative?: boolean;
  allowMultiplication?: boolean;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onFocus?: (e) => void;
  maxDecimals?: number;
  fixedDecimals?: boolean;
  isPendingValue?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  helperTextAlign?: HelperTextAlignment;
  watch?: UseFormWatch<FieldValues>;
}

export const NumberInput = ({
  name,
  testcy,
  appearance = FormElementAppearance.Old,
  size = FormElementSize.Medium,
  control,
  width = '200px',
  disabled = false,
  readOnly = false,
  validation = {},
  float = false,
  negative = false,
  allowMultiplication = false,
  defaultValue = '',
  label,
  placeholder = '',
  endAdornment,
  startAdornment,
  onChange,
  onBlur,
  onKeyDown,
  onKeyUp,
  onFocus,
  maxDecimals,
  fixedDecimals,
  isPendingValue = false,
  autoFocus = false,
  helperText,
  helperTextAlign = HelperTextAlignment.Left,
  watch,
}: Props) => {
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    if (!watch) return;

    const subscription = watch(value => {
      if (!isArray(value.disabledValues)) return;

      setIsDisabled(value.disabledValues.includes(name));
    });

    return () => subscription && subscription.unsubscribe();
  }, [watch, name]);

  const onChangeHandler = (onChangeProvided: (...event: any[]) => void, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const value = formatNumber(e.target.value, float, maxDecimals, negative, allowMultiplication);
    onChange?.(value);
    onChangeProvided(value);
  };

  const onBlurHandler = (
    onBlurProvided: (...event: any[]) => void,
    onChangeProvided: (...event: any[]) => void,
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const rawValue = e.target.value;
    const value = formatNumberOnBlur(rawValue, float, maxDecimals, negative, allowMultiplication, fixedDecimals);

    if (rawValue !== value) {
      onChange?.(value);
      onChangeProvided(value);
    }
    onBlur?.(value);
    onBlurProvided(value);
  };

  const onPasteHandler = (onChangeProvided: (...event: any[]) => void, e: React.ClipboardEvent): void => {
    e.preventDefault();
    const pasteContent = e.clipboardData.getData('Text');
    const result = formatNumber(pasteContent, float, maxDecimals, negative, allowMultiplication);

    onChange?.(result);
    onChangeProvided(result);
  };

  const onKeyDownHandler = (onChangeProvided: (...event: any[]) => void, e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      const rawValue = (e.target as HTMLInputElement).value;
      const value = formatNumberOnBlur(rawValue, float, maxDecimals, negative, allowMultiplication);

      if (rawValue !== value) {
        onChange?.(value);
        onChangeProvided(value);
      }
    }
    onKeyDown?.(e);
  };

  const getRootInputClasses = (error: any): string => {
    //prettier-ignore
    return [
      'number-input styled-input',
      `helper-text-${error?.message ? 'left' : helperTextAlign}`,
      error ? 'has-error' : '',
      isDisabled ? 'input-disabled' : '',
      readOnly ? 'input-readonly' : '',
    ].join(' ');
  };

  return (
    <div className={`number-input-container appearance-${appearance} size-${size}`} style={{ width, minWidth: width }}>
      <FormControl variant="outlined">
        {label && (
          <InputLabel shrink className="input-label" data-testcy={`${testcy}-label`} disabled={disabled}>
            {label}
          </InputLabel>
        )}
        <Controller
          control={control}
          name={name}
          rules={validation}
          defaultValue={defaultValue}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <div className={`number-input-wrapper`}>
              <TextField
                autoComplete="off"
                id={name}
                inputProps={{
                  'data-testcy': testcy,
                }}
                type="text"
                value={value ?? ''}
                onChange={e => onChangeHandler(onChange, e)}
                onPaste={e => onPasteHandler(onChange, e)}
                onBlur={e => onBlurHandler(onBlur, onChange, e)}
                onKeyDown={e => onKeyDownHandler(onChange, e)}
                onKeyUp={e => onKeyUp?.(e)}
                onFocus={e => onFocus?.(e)}
                placeholder={placeholder}
                disabled={isDisabled}
                autoFocus={autoFocus}
                helperText={error?.message ?? helperText}
                classes={{ root: getRootInputClasses(error) }}
                InputProps={{
                  readOnly: readOnly,
                  startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null,
                  endAdornment:
                    endAdornment || isPendingValue ? (
                      <InputAdornment position="end">
                        {endAdornment ? endAdornment : ''}
                        {isPendingValue ? <LoaderIcon size="tiny" /> : ''}
                      </InputAdornment>
                    ) : null,
                }}
              />
            </div>
          )}
        />
      </FormControl>
    </div>
  );
};
