import React from 'react';
import { SharedTaxes } from '@features';
import { useForm } from 'react-hook-form';
import './ownerTaxesView.scoped.scss';

export const OwnerTaxesView = () => {
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  return (
    <div className="standard-layout-spacing-g taxes-wrapper">
      <h1 className="title-g">Podatki</h1>
      <div className="content">
        <SharedTaxes formFeatures={form} />
      </div>
    </div>
  );
};
