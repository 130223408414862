import React from 'react';
import { useInternalAppStore } from '@store';
import { Outlet } from 'react-router-dom';
import { Footer, Header, IsLoadingPage, FloatingFooter } from './components';
import './layout.scoped.scss';
import { ChatContextProvider, FloatingChat } from 'src/features/chat';
import { SharedCookieInfo } from '@features';
import { useMyCompanyInitializer } from '@hooks';
import { useHotjarStateUpdate, useGoogleAnalyticsPageUpdate } from '@lib';
import { Navbar } from './components/navbar/navbar';
import { FloatingEnv } from './components/floatingEnv/floatingEnv';

const Layout = () => {
  const { blurOut } = useInternalAppStore();

  useHotjarStateUpdate();
  useGoogleAnalyticsPageUpdate();
  useMyCompanyInitializer();

  return (
    <ChatContextProvider>
      <SharedCookieInfo />
      <div className={`layout ${blurOut ? 'layout-blurred' : ''}`}>
        <div></div>
        <Header />
        <div className='nav-wrapper'>
          <Navbar />
        </div>
        <div className="layout-content">
          <Outlet />
          <Footer />
        </div>
      </div>
      <FloatingEnv />
      <FloatingChat />
      <FloatingFooter />
    </ChatContextProvider>
  );
};

export { IsLoadingPage, FloatingFooter };
export default Layout;