import React from 'react';
import { UseFormReturn, FieldValues } from 'react-hook-form';
import { SharedTaxes } from '@features';

interface ITaxTabProps {
  formFeatures: Partial<UseFormReturn<FieldValues>>;
}

export const TaxTab = (props: ITaxTabProps) => {
  return <SharedTaxes {...props} />;
};
