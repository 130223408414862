import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
  size?: number;
}

export const SearchIcon = ({ colorType = '', size = 24 }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6324 3C16.2814 3 20.0499 6.76847 20.0499 11.4175C20.0499 16.0666 16.2814 19.836 11.6324 19.836C6.98331 19.836 3.21484 16.0666 3.21484 11.4175C3.21484 6.76847 6.98331 3 11.6324 3Z"
        stroke="#101013"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.3613 17.584L20.7863 21.0002" stroke="#101013" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

