import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { CompaniesOrderOptions, ContractorsOrderOptions, CostDocumentsOrderOptions, InvoiceOrderOptions } from '@services';
import { EmployeesOrderOptionsEnum, SortDirection } from '@utils';
import { SearchBarValues } from '@components';

interface ListParams<T> {
  sortDirection?: SortDirection;
  page?: number;
  orderBy?: T;
  searchBarValues?: SearchBarValues;
  companyId?: number;
  itemsPerPage?: number;
}

interface ContractorStore {
  lastParamsInvoiceList: ListParams<InvoiceOrderOptions> | null;
  setLastParamsInvoiceList: (val: ListParams<InvoiceOrderOptions> | null) => void;
  lastParamsContractorList: ListParams<ContractorsOrderOptions> | null;
  setLastParamsContractorList: (val: ListParams<ContractorsOrderOptions> | null) => void;
  lastParamsCompaniesList: ListParams<CompaniesOrderOptions> | null;
  setLastParamsCompaniesList: (val: ListParams<CompaniesOrderOptions> | null) => void;
  lastParamsCostDocumentsList: ListParams<CostDocumentsOrderOptions> | null;
  setLastParamsCostDocumentsList: (val: ListParams<CostDocumentsOrderOptions> | null) => void;
  lastParamsEmployeesList: ListParams<EmployeesOrderOptionsEnum> | null;
  setLastParamsEmployeesList: (val: ListParams<EmployeesOrderOptionsEnum> | null) => void;
  lastParamsDashboard: ListParams<''> | null;
  setLastParamsDashboard: (val: ListParams<''> | null) => void;
}

export const useListParamsStore = create<ContractorStore>(set => ({
  lastParamsInvoiceList: null,
  setLastParamsInvoiceList: (data: ListParams<InvoiceOrderOptions> | null) => set(() => ({ lastParamsInvoiceList: data })),
  lastParamsContractorList: null,
  setLastParamsContractorList: (data: ListParams<ContractorsOrderOptions> | null) => set(() => ({ lastParamsContractorList: data })),
  lastParamsEmployeesList: null,
  setLastParamsEmployeesList: (data: ListParams<EmployeesOrderOptionsEnum> | null) => set(() => ({ lastParamsEmployeesList: data })),
  lastParamsCompaniesList: null,
  setLastParamsCompaniesList: (data: ListParams<CompaniesOrderOptions> | null) => set(() => ({ lastParamsCompaniesList: data })),
  lastParamsCostDocumentsList: null,
  setLastParamsCostDocumentsList: (data: ListParams<CostDocumentsOrderOptions> | null) => set(() => ({ lastParamsCostDocumentsList: data })),
  lastParamsDashboard: null,
  setLastParamsDashboard: (data: ListParams<''> | null) => set(() => ({ lastParamsDashboard: data })),
}));

if (process.env.NODE_ENV === 'development') mountStoreDevtool('ListParamsStore', useListParamsStore);
