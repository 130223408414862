import React from 'react';
import { InvoiceHistory as InvoiceHistoryInterface, PayloadProperty } from '@services';
import {
  propertyTranslator,
  translateInvoiceItem,
  getItemChangesTitle,
  resolvePropertyValue,
  resolveItemPropertyValue,
} from './invoiceHistoryItemDetails.utils';
import './invoiceHistoryItemDetails.scoped.scss';

interface Props {
  historyDetails: InvoiceHistoryInterface;
}

export const InvoiceHistoryItemDetails = ({ historyDetails }: Props) => {
  return (
    <div className="history-details-wrapper">
      {(historyDetails.payload as PayloadProperty)?.propertyChanges?.map(property => {
        const newVal = resolvePropertyValue(property.property, property.newValue);
        const oldVal = resolvePropertyValue(property.property, property.oldValue);

        return (
          <div className="property-change-item-wrapper" key={property.newValue + property.oldValue}>
            <p className="property-name">{propertyTranslator[property.property] || ''}:</p>
            <p className="property-new">{newVal} </p>
            <p className="property-old">{oldVal}</p>
          </div>
        );
      })}

      {(historyDetails.payload as PayloadProperty)?.itemChanges?.map((property, i) => {
        const isDeleted = !property.newValue;
        const newValuesKeys = !isDeleted ? Object.keys(property.newValue).filter(v => !!translateInvoiceItem[v]) : [];

        const isOldValues = !!property.oldValue;
        const oldValuesKeys = isOldValues ? Object.keys(property.oldValue).filter(v => !!translateInvoiceItem[v]) : [];

        const invoiceId = property.newValue?.invoiceId ?? property.oldValue?.invoiceId;

        return (
          <div className="item-change-item-wrapper" key={invoiceId + i}>
            <p className="item-new-title">{getItemChangesTitle(isOldValues, isDeleted)}</p>
            {!isDeleted && (
              <div className="item-new">
                {newValuesKeys.map((v, i) => {
                  const value = resolveItemPropertyValue(v, property.newValue[v]);
                  return (
                    <div className="item-row-wrapper" key={v}>
                      <p className="item-value-name">{translateInvoiceItem[v]}: </p> {value}
                      {i === newValuesKeys.length - 1 ? '' : ','}
                    </div>
                  );
                })}
              </div>
            )}
            {isOldValues && (
              <div className="item-old">
                {oldValuesKeys.map((v, i) => {
                  const value = resolveItemPropertyValue(v, property.oldValue[v]);
                  return (
                    <div className="item-row-wrapper" key={v}>
                      <p className="item-value-name">{translateInvoiceItem[v]}: </p> {value}
                      {i === oldValuesKeys.length - 1 ? '' : ','}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
