import { Client } from '@amityco/ts-sdk';
import { getAmityAuthToken } from 'src/services/chat';

const sessionHandler: Amity.SessionHandler = {
  sessionWillRenewAccessToken: async (renewal: Amity.AccessTokenRenewal) => {
    try {
      const { authToken } = await getAmityAuthToken();

      renewal.renewWithAuthToken(authToken);
    } catch (error) {
      renewal.unableToRetrieveAuthToken();
    }
  },
};

export const login = async () => {
  const { userId, authToken } = await getAmityAuthToken();

  if (!Client.getActiveClient()?.apiKey) {
    return;
  }

  await Client.login(
    {
      userId: userId,
      authToken: authToken, // required if using secure mode
    },
    sessionHandler
  );
};

export const logout = async () => {
  await Client.logout();
};
