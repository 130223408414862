export const dialogMessagesText = Object.freeze({
  constFileDelete: 'Czy na pewno chcesz usunąć dokument?',
  constDelete: 'Czy na pewno chcesz usunąć fakturę',
  constDeleteAsAccountant: 'Czy na pewno chcesz usunąć fakturę',
});

export const dialogMessagesHandler = Object.freeze({
  costDelete: (costNumber: string, isAccountant: boolean) =>
    isAccountant ? `${dialogMessagesText.constDeleteAsAccountant} "${costNumber}" ?` : `${dialogMessagesText.constDelete} "${costNumber}" ?`,
});
