import { useState } from 'react';
import { unregisterEmployee } from '@services';
import { RequestErrorMessage } from '@interfaces';
import { handleApiError, snackbarMessagesHandler } from '@utils';
import { addHotjarEvent, invalidateApiQuery } from '@lib';

interface UseMarkInvoiceAsSent {
  isPendingUnregisterEmployee: boolean;
  unregisterEmployee: (employeeId: number) => void;
}

export const useUnregisterEmployee = (): UseMarkInvoiceAsSent => {
  const [isPendingUnregisterEmployee, setIsPendingUnregisterEmployee] = useState(false);

  const unregister = (employeeId: number) => {
    addHotjarEvent('unregister employee');
    setIsPendingUnregisterEmployee(true);
    unregisterEmployee(employeeId)
      .then(() => {
        snackbarMessagesHandler.employeeUnregister();
        invalidateApiQuery('getEmployeeDataById', employeeId);
        invalidateApiQuery('getEmployees');
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingUnregisterEmployee(false));
  };

  return {
    isPendingUnregisterEmployee,
    unregisterEmployee: employeeId => unregister(employeeId),
  };
};
