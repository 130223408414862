import React from 'react';

type Props = {
  colorType?: 'black' | 'white' | 'primary' | 'disabled' | 'blue' | '';
};

export const CloudIcon = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-g`} width="64" height="42" viewBox="0 0 64 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0196 8.21282C20.7545 3.31329 25.9903 0 32 0C38.0097 0 43.2455 3.3133 45.9804 8.21284C46.6316 8.07342 47.3072 8.00003 48 8.00003C53.302 8.00003 57.6 12.2981 57.6 17.6C57.6 17.6374 57.5998 17.6747 57.5994 17.712C61.4257 19.9251 64 24.0619 64 28.8C64 35.8692 58.2692 41.6 51.2 41.6H12.8C5.73075 41.6 0 35.8692 0 28.8C0 24.0619 2.57437 19.9251 6.40066 17.712C6.40024 17.6747 6.40002 17.6374 6.40002 17.6C6.40002 12.2981 10.6981 8.00003 16 8.00003C16.6928 8.00003 17.3684 8.07341 18.0196 8.21282Z"
      />
    </svg>
  );
};
