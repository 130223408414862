import { FilePreview, OwnerInvoice, SharedInvoicesList } from '@features';
import { Invoice, getInvoiceById } from '@services';
import React from 'react';
import { Navigate } from 'react-router';
import { TheRouteObject } from '../types';

export const invoicesList: TheRouteObject[] = [
  {
    index: true,
    element: <SharedInvoicesList />,
  },
  {
    path: 'add-invoice',
    element: <OwnerInvoice />,
    loader: async () => {
      const clone = window.location.href.match(/\?clone=(\d+)/)?.[1];
      if (!clone) return null;
      return getInvoiceById(+clone);
    },
    handle: {
      crumb: (data: Invoice | null) => {
        if (!data) return 'Utwórz fakturę';
        return {
          label: `Wystaw podobną do ${data.number}`,
          noEllipsis: true,
        };
      },
    },
  },
  {
    path: 'add-invoice/:action/:correctionId',
    element: <OwnerInvoice />,
    loader: async ({ params }) => {
      return getInvoiceById(+params.correctionId);
    },
    handle: {
      crumb: (data: Invoice | null) => {
        if (!data) return null;
        return {
          label: `Skoryguj ${data.number}`,
          noEllipsis: true,
        };
      },
    },
  },
  {
    path: 'edit-invoice-correction/:action/:correctionId',
    element: <OwnerInvoice />,
    loader: async ({ params }) => {
      return getInvoiceById(+params.correctionId);
    },
    handle: {
      crumb: (data: Invoice | null) => {
        if (!data) return null;
        return {
          label: `Edytuj fakturę ${data.number}`,
          noEllipsis: true,
        };
      },
    },
  },
  {
    path: 'edit-invoice/:action/:id',
    element: <OwnerInvoice />,
    loader: async ({ params }) => {
      return await getInvoiceById(+params.id);
    },
    handle: {
      crumb: (data: Invoice) => ({
        label: `Edytuj fakturę ${data.number}`,
        noEllipsis: true,
      }),
    },
  },
  {
    path: 'file-preview/:duplicateId',
    element: <FilePreview />,
    handle: {
      crumb: `Podgląd pliku`,
    },
  },
  {
    path: '*',
    element: <Navigate to="/invoices-list" />,
  },
];
