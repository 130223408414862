import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const Copy = ({ colorType = '' }: Props) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={`icon-color-${colorType}-stroke`}>
      <path
        d="M10.1905 4.63279C10.0209 3.53915 9.23333 2.80078 8.04653 2.80078H4.19676C2.85656 2.80078 2.01465 3.7527 2.01465 5.09743V8.72325C2.01465 9.94832 2.71484 10.851 3.86284 10.9934"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.804 6.5874H7.95553C6.6147 6.5874 5.77344 7.53674 5.77344 8.88081V12.5067C5.77344 13.8507 6.61017 14.8001 7.95553 14.8001H11.8033C13.1494 14.8001 13.9861 13.8507 13.9861 12.5067V8.88081C13.9861 7.53674 13.1494 6.5874 11.804 6.5874Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
