import React from 'react';
import { Avatar } from '@mui/material';
import { getAvatarColor } from './initialsAvatar.utils';

import './initialsAvatar.scss';

interface Props {
  testcy: string;
  names: string[];
  personId: number;
}

export const InitialsAvatar = ({ testcy, names, personId }: Props) => {
  const color = getAvatarColor(personId);

  const viableNames = names.length > 1 ? names : names[0].split(' ');

  const initials = viableNames
    .filter(n => n?.trim().length > 0)
    .slice(0, 2)
    .map(n => n?.charAt(0))
    .join('');

  return (
    <Avatar className={`initials-avatar color-${color}`} data-testcy={testcy}>
      {initials}
    </Avatar>
  );
};
