import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
  crossed?: boolean;
}

export const SyncIcon = ({ colorType = '', crossed = false }: Props) => {
  if (crossed) {
    return (
      <svg
        className={`icon-color-${colorType}-g`}
        style={{ height: 22, width: 22 }}
        xmlns="http://www.w3.org/2000/svg"
        height="48"
        viewBox="0 -960 960 960"
        width="48"
      >
        <path d="M809-56 653-212q-20 13-39 21.5T574-175v-63q9-3 18-7.5t18-9.5L256-609q-19 31-27.5 65.5T220-477q0 58 21.5 105.5T307-287l30 21v-124h60v230H167v-60h130l-15-12q-68-54-95.5-115T160-477q1-54 15-97t38-78L57-808l41-41L850-97l-41 41Zm-72-236-42-42q21-33 33-70t12-79q0-46-22.5-95.5T655-668l-29-26v124h-60v-230h230v60H665l15 14q64 60 92 124t28 119q0 54-15.5 101T737-292ZM334-695l-41-41q22-17 45.5-28.5T387-784v62q-13 5-26.5 12T334-695Z" />
      </svg>
    );
  }
  return (
    <svg
      className={`icon-color-${colorType}-g`}
      style={{ height: 22, width: 22 }}
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M167-160v-60h130l-15-12q-64-51-93-111t-29-134q0-106 62.5-190.5T387-784v62q-75 29-121 96.5T220-477q0 63 23.5 109.5T307-287l30 21v-124h60v230H167Zm407-15v-63q76-29 121-96.5T740-483q0-48-23.5-97.5T655-668l-29-26v124h-60v-230h230v60H665l15 14q60 56 90 120t30 123q0 106-62 191T574-175Z" />
    </svg>
  );
};
