import React from 'react';
import { ChatItem } from '../ChatItem/chatItem';
import './chatList.scoped.scss';

type Props = {
  channels: Amity.Channel[];
  selectedChannelId: string | undefined;
  onChannelSelect: (channelId: string) => void;
};

export const ChatList = ({ channels, onChannelSelect, selectedChannelId }: Props) => {
  return (
    <div className="chat-list-container">
      <div className="chat-list-header">
        <p className="chat-list-header-label">Czat</p>
      </div>
      <div className="chat-items-container">
        {Array.isArray(channels) &&
          channels.map(channel => (
            <ChatItem key={channel.channelId} channel={channel} isSelected={selectedChannelId === channel.channelId} onSelect={onChannelSelect} />
          ))}
      </div>
    </div>
  );
};
