import { DocumentIcon, TheSelect, Toggle } from '@components';
import { dateFormatter } from '@lib';
import { InvoiceNumberFormat, formErrorMsg, invoiceNumberFormatTypeOptions } from '@utils';
import { isNull } from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SettingsSection, SettingsSubSection, SettingsTab } from '../../components/settingsTab';
import { SuffixField } from './suffixField';
import { useSuffixList } from './useSuffixList';
import { EmptySuffixField } from './emptySuffixField';
import { SuffixInvoiceNumberData } from '@interfaces';

import './mySettingsSection.scoped.scss';

interface Props {
  suffixes: SuffixInvoiceNumberData[];
}

export const MySettingsSection = ({ suffixes: rawSuffixes }: Props) => {
  const { watch, setValue, unregister } = useFormContext();
  const { suffixes, addSuffix, removeSuffix, updateSuffix } = useSuffixList(rawSuffixes, setValue, unregister);

  const getFormatHelperText = () => {
    const format = watch('invoiceNumberFormat');
    //get the first non-empty suffix, or null if doesn't exist
    let suffix: string | null = null;
    let suffixIndex = 0;
    while (true) {
      const watchedSuffix = watch(`suffix-${suffixIndex}`);
      if (watchedSuffix === undefined) break;
      if (watchedSuffix.length > 0) {
        suffix = watchedSuffix;
        break;
      }
      suffixIndex++;
    }

    const monthOrYear =
      format === InvoiceNumberFormat.Yearly ? new Date().getFullYear() : `${dateFormatter.getDateMonth(new Date())}/${new Date().getFullYear()}`;

    if (isNull(suffix)) return `Przykładowy numer faktury: 1/${monthOrYear}`;
    return `Przykładowy numer faktury: 1/${monthOrYear}/${suffix.toUpperCase()}`;
  };

  const allowSuffixes = watch('allowSuffixes');

  return (
    <SettingsTab title="Moje ustawienia">
      <SettingsSection title="Ustawienia faktury" titleIcon={<DocumentIcon size={20} />}>
        <SettingsSubSection title="Format numeru faktury" subtitle=" ">
          <TheSelect
            testcy="format"
            name="invoiceNumberFormat"
            width="100%"
            hideEmptyOption
            appearance="filled"
            options={invoiceNumberFormatTypeOptions}
            defaultValue={InvoiceNumberFormat.Yearly}
            validation={{
              required: formErrorMsg.isRequired,
            }}
            helperText={getFormatHelperText()}
          />
        </SettingsSubSection>
      </SettingsSection>
      <SettingsSection>
        <SettingsSubSection
          title="Przyrostki numeru faktury"
          subtitle={
            allowSuffixes ? (
              <a className="link-g" onClick={addSuffix}>
                Dodaj kolejny przyrostek.
              </a>
            ) : null
          }
        >
          <Toggle name="allowSuffixes" testcy="allow-suffixes" defaultValue={allowSuffixes} />
          {allowSuffixes && (
            <div className="suffixes-container">
              {suffixes.map(suffixObj => {
                if (isNull(suffixObj.value)) return null;
                const { index } = suffixObj;
                return <SuffixField key={index} index={index} onDeleteClick={() => removeSuffix(index)} onBlur={v => updateSuffix(index, v)} />;
              })}
              {suffixes.length === 0 && <EmptySuffixField />}
            </div>
          )}
        </SettingsSubSection>
      </SettingsSection>
    </SettingsTab>
  );
};
