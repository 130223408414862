export type InputFormatFn = (value: string) => string;

export const HelperTextAlignment = {
  Left: 'left',
  Right: 'right',
} as const;
export type HelperTextAlignment = typeof HelperTextAlignment[keyof typeof HelperTextAlignment];

export type Range<T> = { from: T; to: T };

export type LabeledAction = {
  enabled: boolean;
  testcyTag?: string;
  label: string;
  onClick: () => void;
};
