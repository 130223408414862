import React, { useEffect, ChangeEvent, useRef, useState } from 'react';
import { Control, Controller, FieldValues, RegisterOptions, UseFormClearErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { Box, FormControl, InputLabel, TextField } from '@mui/material';
import { HelperTextAlignment, emailValidateRegexp } from '@utils';
import { Tags } from './tagsInput.utils';
import './tagsInput.scss';
import { FormElementAppearance, FormElementSize } from '../types';

interface Props {
  name: string;
  testcy: string;
  control: Control;
  clearErrors: UseFormClearErrors<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setTagsData?: (value: string[]) => void;
  type?: 'email';
  width?: string;
  label?: string;
  validation?: RegisterOptions;
  defaultValue?: string[];
  onChange?: (value: string) => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  helperText?: string;
  helperTextAlign?: HelperTextAlignment;
  appearance?: FormElementAppearance;
  size?: FormElementSize;
}

export const TagInput = ({
  name,
  testcy,
  control,
  clearErrors,
  trigger,
  type,
  width = '200px',
  label,
  validation,
  defaultValue,
  autoFocus,
  placeholder,
  readOnly = false,
  onChange,
  onBlur,
  setValue,
  setTagsData,
  helperText,
  helperTextAlign = HelperTextAlignment.Left,
  appearance = FormElementAppearance.Old,
  size = FormElementSize.Medium,
}: Props) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [getWidth, setGetWidth] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    setGetWidth(ref.current.offsetWidth);
    if (defaultValue) setTagsInner(defaultValue);
  }, []);

  useEffect(() => {
    setValue(name, tags);
    if (tags?.length) clearErrors(name);
  }, [tags]);

  const setTagsInner = (tagsData: string[]) => {
    setTags(tagsData);
    setTagsData && setTagsData(tagsData);
  };

  const onChangeHandler = (onChangeProvided: (...event: any[]) => void, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    e.stopPropagation();
    const v = e.target.value;
    onChange?.(v);
    onChangeProvided(e);
    setInputValue(v);
  };

  const onBlurHandler = (onBlurProvided: (...event: any[]) => void, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    onBlur?.();
    if (!e.target.value.match(emailValidateRegexp) && type === 'email') {
      return onBlurProvided(e);
    }

    setTagsInner([...tags, e.target.value]);
    setInputValue('');
  };

  const deleteHandler = (value: string, index: number) => {
    const newTags = tags.filter((val, i) => val !== value || i !== index);
    setTagsInner(newTags);

    if (newTags.length === 0) {
      setValue(name, newTags);
      trigger(name);
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (!inputValue.match(emailValidateRegexp) && type === 'email') {
        return;
      }
      e.preventDefault();
      setTagsInner([...tags, inputValue]);
      setInputValue('');
    }
  };

  const getRootInputClasses = (error: any): string => {
    //prettier-ignore
    return [
      'tag-input styled-input',
      `helper-text-${error?.message ? 'left' : helperTextAlign}`,
      error?.message ? 'has-error' : '',
      readOnly ? 'input-readonly' : ''
    ].join(' ');
  };

  return (
    <div ref={ref} className={`tag-input-wrapper-component appearance-${appearance} size-${size}`} style={{ width: width, minWidth: width }}>
      <FormControl variant="outlined">
        {label && (
          <InputLabel shrink className="input-label" data-testcy={`${testcy}-label`}>
            {label}
          </InputLabel>
        )}
        <Controller
          control={control}
          name={name}
          rules={validation}
          render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
            <TextField
              value={inputValue}
              inputProps={{
                'data-testcy': testcy,
              }}
              onKeyDown={onKeyDownHandler}
              onChange={e => onChangeHandler(onChange, e)}
              onBlur={e => onBlurHandler(onBlur, e)}
              variant="outlined"
              placeholder={placeholder}
              autoFocus={autoFocus}
              helperText={error?.message ?? helperText}
              classes={{ root: getRootInputClasses(error) }}
              InputProps={{
                startAdornment: (
                  <Box
                    sx={{
                      margin: `${tags.length ? '9px' : '0px'}`,
                      width: `${tags.length ? getWidth : '0px'}`,
                      maxWidth: getWidth,
                      display: `${tags.length ? 'flex' : 'none'}`,
                      gap: '3px',
                      flexWrap: `${tags.length ? 'wrap' : 'nowrap'}`,
                    }}
                  >
                    {tags.map((tag, index) => {
                      return (
                        <Tags
                          data-testcy={`${testcy}-tag-${index}`}
                          tag={tag}
                          handleDelete={(value: string) => {
                            deleteHandler(value, index);
                          }}
                          key={index}
                        />
                      );
                    })}
                  </Box>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};
