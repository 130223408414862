

/**
 * Splits a string into equal-size chunks.
 * @param str The string to be split.
 * @param size The maximum size of each chunk.
 * @returns An array of strings of specified length. The last string may be shorter.
 * 
 * @copyright https://stackoverflow.com/questions/7033639/split-large-string-in-n-size-chunks-in-javascript
 */
export function splitIntoChunks(str: string, size: number): string[] {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, pos = 0; i < numChunks; i++, pos += size) {
    chunks[i] = str.substring(pos, pos + size);
  }

  return chunks;
}