import React, { useState } from 'react';
import { ButtonMain, ConfirmIcon, DownloadIcon, ListIcon, PencilIcon, TableRowInterface, WasteBasketIcon, DocumentIcon } from '@components';
import { CustomObject } from '@interfaces';
import PreviewIcon from '@mui/icons-material/Preview';
import { Tooltip } from '@mui/material';
import './action.scss';
import { SyncIcon } from 'src/components/icons';
import { useContractorStore } from '@store';

interface Params {
  row: TableRowInterface;
  index: number;
  rowActions: CustomObject;
  cannotDelete: boolean;
  onClickEdit?: (data: TableRowInterface) => void;
  onClickRemove?: (data: TableRowInterface) => void;
  onClickDownload?: (data: TableRowInterface) => void;
  onClickList?: (data: TableRowInterface) => void;
  onClickOpenBtn?: (data: TableRowInterface) => void;
  onClickPreview?: (data: TableRowInterface) => void;
  onClickEmployeePreview?: (data: TableRowInterface) => void;
  onClickEmployeeUnregister?: (data: TableRowInterface) => void;
  onClickSendToInsert?: (data: TableRowInterface) => void;
  onClickCreateCSVFile?: (data: TableRowInterface) => void;
  onClickSyncToggle?: (id: string, newState: boolean) => void;
  onClickInviteCompany?: (data: TableRowInterface) => void;
}

export const Action = ({
  index,
  row,
  rowActions,
  cannotDelete,
  onClickEdit,
  onClickRemove,
  onClickDownload,
  onClickList,
  onClickOpenBtn,
  onClickPreview,
  onClickEmployeePreview,
  onClickEmployeeUnregister,
  onClickSendToInsert,
  onClickCreateCSVFile,
  onClickSyncToggle,
  onClickInviteCompany,
}: Params) => {
  const [isSyncToggleDisabled, setIsSyncToggleDisabled] = useState(!row.action.syncToggleState);

  const { ownerMyCompanyData } = useContractorStore();

  const generateSendCostToBankTooltip = () => {
    if (!ownerMyCompanyData.bankAccounts.length) return 'Przed wygenerowaniem pliku .CSV należy uzupełnić konto bankowe w danych firmy';
    if (rowActions.costIsPaid) return 'Faktura kosztowa została już opłacona';
    if (rowActions.disabledSentCostToBank) return 'Do wygenerowania pliku .CSV konieczne jest uzupełnienie danych w formularzu';
    if (rowActions.costPaymentMethodIsCorrect)
      return 'Do wygenerowania pliku .CSV konieczne jest uzupełnienie typu płatności jako przelew lub płatność podzielona';
    return 'Wygeneruj do pliku CSV';
  };
  return (
    <div className={`action-cell`}>
      {(rowActions.edit || rowActions.editDisabled) && (
        <button
          type="button"
          data-testcy={`action-edit-${index}`}
          className={`icon-wrapper ${rowActions.editDisabled ? 'disabled-action-cell' : ''}`}
          onClick={() => !rowActions.editDisabled && onClickEdit(row)}
          disabled={rowActions.editDisabled}
        >
          <PencilIcon colorType={rowActions.editDisabled ? 'disabled' : 'primary'} />
        </button>
      )}
      {(rowActions.sentToInsert || rowActions.sentToInsertDisabled) && (
        <Tooltip
          title={`${
            rowActions.insertCredential ? 'Wyślij fakturę kosztową' : 'Przed wysłaniem faktury należy uzupełnić dane dostępowe do bazy danych InsERT'
          } `}
        >
          <button
            type="button"
            data-testcy={`action-send-to-insert-${index}`}
            className={`icon-wrapper ${rowActions.sentToInsertDisabled ? 'disabled-action-cell' : ''}`}
            onClick={() => !rowActions.sentToInsertDisabled && onClickSendToInsert(row)}
            disabled={rowActions.sentToInsertDisabled}
          >
            <ConfirmIcon colorType={rowActions.sentToInsertDisabled ? 'disabled' : 'primary'} />
          </button>
        </Tooltip>
      )}
      {rowActions.download && (
        <button type="button" data-testcy={`action-download-${index}`} className={`icon-wrapper`} onClick={() => onClickDownload(row)}>
          <DownloadIcon colorType={'primary'} />
        </button>
      )}
      {rowActions.employeePreview && (
        <Tooltip title={'Podgląd pracownika'}>
          <button type="button" data-testcy={`action-employee-preview-${index}`} className={`icon-wrapper`} onClick={() => onClickEmployeePreview(row)}>
            <PencilIcon colorType={'primary'} />
          </button>
        </Tooltip>
      )}
      {rowActions.syncToggle && (
        <button
          type="button"
          data-testcy={`action-toggle-sync-${index}`}
          className={`icon-wrapper`}
          title={row.action.syncToggleState ? 'Wyłącz przeliczanie' : null}
          onClick={() => {
            onClickSyncToggle(row.id, !row.action.syncToggleState);
            setIsSyncToggleDisabled(true);
          }}
          disabled={isSyncToggleDisabled}
        >
          <SyncIcon colorType={isSyncToggleDisabled ? 'disabled' : 'primary'} crossed={!row.action.syncToggleState} />
        </button>
      )}
      {(rowActions.remove || rowActions.removeDisabled) && (
        <button
          type="button"
          data-testcy={`action-remove-${index}`}
          className={`icon-wrapper ${cannotDelete ? 'disabled-action-cell' : ''}`}
          onClick={() => !cannotDelete && onClickRemove(row)}
          disabled={rowActions.removeDisabled}
        >
          <WasteBasketIcon colorType="grey500" />
        </button>
      )}
      {rowActions.preview && (
        <button type="button" data-testcy={`action-preview-${index}`} onClick={() => onClickPreview(row)}>
          <PreviewIcon className="mui-icon" />
        </button>
      )}
      {rowActions.list && (
        <button type="button" data-testcy={`action-list-${index}`} className={`icon-wrapper`} onClick={() => onClickList(row)}>
          <ListIcon colorType={'primary'} />
        </button>
      )}
      {rowActions.inviteCompany && (
        <Tooltip title="Zaproś firmę">
          <button type="button" data-testcy={`action-list-${index}`} className={`icon-wrapper`} onClick={() => onClickInviteCompany(row)}>
            <ListIcon colorType={'primary'} />
          </button>
        </Tooltip>
      )}
      {rowActions.sentCostToBank && (
        <Tooltip title={generateSendCostToBankTooltip()}>
          <button
            type="button"
            data-testcy={`action-send-cost-to-bank-${index}`}
            className={`icon-wrapper ${rowActions.disabledSentCostToBank || rowActions.costPaymentMethodIsCorrect ? 'disabled-action-cell' : ''}`}
            onClick={() => {
              !(rowActions.disabledSentCostToBank || rowActions.costPaymentMethodIsCorrect) && onClickCreateCSVFile(row);
            }}
          >
            <DocumentIcon colorType={rowActions.disabledSentCostToBank || rowActions.costPaymentMethodIsCorrect ? 'disabled' : 'primary'} />
          </button>
        </Tooltip>
      )}
      {(rowActions.employeeUnregister || rowActions.employeeUnregisterDisabled) && (
        <Tooltip title={'Wyrejestruj pracownika'}>
          <button
            type="button"
            data-testcy={`action-delete-employee-${index}`}
            className={`icon-wrapper ${rowActions.employeeUnregisterDisabled ? 'disabled-action-cell' : ''}`}
            onClick={() => !rowActions.employeeUnregisterDisabled && onClickEmployeeUnregister(row)}
          >
            <WasteBasketIcon colorType={rowActions.employeeUnregisterDisabled ? 'disabled' : 'primary'} />
          </button>
        </Tooltip>
      )}
      {rowActions.openButton && (
        <ButtonMain
          testcy={`action-open-${index}`}
          width="100px"
          sizeType="extra-small"
          colorType="secondary"
          content="Otwórz"
          onClick={() => onClickOpenBtn(row)}
        />
      )}
    </div>
  );
};
