import { Button, Paper, Stack } from '@mui/material';
import React from 'react';
import './paperWithActions.scoped.scss';
import { LabeledAction } from '@utils';

type Props = React.HTMLAttributes<HTMLElement> & {
  actions: LabeledAction[];
  testcy: string;
};

export const PaperWithActions = ({ testcy, actions, ...htmlElementProps }: Props) => {
  const enabledActions = actions?.filter(action => action.enabled) ?? [];

  return (
    <Stack className="menu-g" component={Paper} spacing={1}>
      <div {...htmlElementProps}>{htmlElementProps.children}</div>
      {enabledActions.map(({ testcyTag, label, onClick }) => (
        // TODO: Temporary implementation. Replace with transparent button when #3022 is merged
        <Button key={label} data-testcy={`${testcy}-${testcyTag}`} onMouseDown={onClick} className="drop-down-button" disableRipple>
          {label}
        </Button>
      ))}
    </Stack>
  );
};
