import { useState } from 'react';

interface UsePaginationProps {
  page?: number;
  rowsPerPage?: number;
}

export const usePagination = (defaults: UsePaginationProps = {}) => {
  const [page, setPage] = useState(defaults.page ?? 1);
  const [rowsPerPage, setRowsPerPage] = useState(defaults.rowsPerPage ?? 50);
  const [totalRows, setTotalRows] = useState(0);

  return {
    page,
    setPage: setPage,
    rowsPerPage,
    setRowsPerPage: setRowsPerPage,
    totalRows,
    setTotalRows: setTotalRows,
  };
};
