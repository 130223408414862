import React from 'react';
import { reactStringReplace } from 'src/utils/reactStringReplace';

import './messageContent.scoped.scss';

type Props = {
  type: Amity.MessageContentType;
  isDeleted: boolean;
  data: Amity.ContentData<string>;
  isIncoming: boolean;
  mentionedInvoiceNumber?: string;
};

export const MessageContent = ({ type, isDeleted, data, isIncoming, mentionedInvoiceNumber }: Props) => {
  if (isDeleted) {
    return <p>Wiadomość usunięta.</p>;
  }

  if (type === 'text') {
    const lines = (data as Amity.ContentDataText).text.split('\n');
    const match = `@[${mentionedInvoiceNumber}]`;
    const invoiceMentionClassName = isIncoming ? 'incoming-invoice-mention' : 'invoice-mention';

    return (
      <>
        {lines.map((line, index) => {
          return (
            <p key={index}>
              {reactStringReplace(line, match, (match, index) => (
                <span key={`${match}-${index}`} className={invoiceMentionClassName}>
                  {mentionedInvoiceNumber}
                </span>
              ))}
            </p>
          );
        })}
      </>
    );
  } else {
    return <p>Nieznany typ wiadomości.</p>;
  }
};
