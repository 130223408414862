import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CredentialsData, getCredentials, hasCredentials, setCredentials } from '@services';
import { handleApiError, snackbarMessagesHandler } from '@utils';
import { RequestErrorMessage } from '@interfaces';
import { addHotjarEvent } from '@lib';

interface UseCredentialToInsertData {
  isPendingCredentialsToInsertData: boolean;
  isPendingCredentialsToInsertDataSet: boolean;
  isPendingHasCredentialsData: boolean;
  server: string;
  username: string;
  password: string;
  hasInsertCredentials: boolean;
  setCredentialsToInsert: (params: CredentialsData, redirectTo?: string) => void;
}

export const useCredentialToInsertData = (hasAccountingClients: boolean): UseCredentialToInsertData => {
  const [isPendingCredentialsToInsertData, setIsPendingCredentialsToInsertData] = useState(false);
  const [isPendingCredentialsToInsertDataSet, setIsPendingCredentialsToInsertDataSet] = useState(false);
  const [isPendingHasCredentialsData, setIsPendingHasCredentialsData] = useState(false);
  const [server, setServer] = useState('');
  const [username, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [hasInsertCredentials, setHasInsertCredentials] = useState(false);
  const routeParams = useParams();

  useEffect(() => {
    if (routeParams.companyId) {
      fetchHasCredentials();
    }
    if (hasAccountingClients) {
      fetchCredentialsToInsertData();
    }
  }, []);

  const fetchCredentialsToInsertData = () => {
    addHotjarEvent('Get InsERT credentials data');
    setIsPendingCredentialsToInsertData(true);
    getCredentials()
      .then((data: CredentialsData) => {
        setServer(data.server);
        setUser(data.username);
        setPassword(data.password);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingCredentialsToInsertData(false));
  };

  const setCredentialsToInsert = (params: CredentialsData, redirectTo: string) => {
    addHotjarEvent('Set InsERT credentials');
    setIsPendingCredentialsToInsertDataSet(true);
    setCredentials(params)
      .then(() => {
        snackbarMessagesHandler.settingsCredentials();
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingCredentialsToInsertDataSet(false));
  };

  const fetchHasCredentials = () => {
    addHotjarEvent('Get InsERT credentials boolean');
    setIsPendingHasCredentialsData(true);
    hasCredentials()
      .then((data: boolean) => {
        setHasInsertCredentials(data);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingHasCredentialsData(false));
  };

  return {
    isPendingCredentialsToInsertData,
    isPendingCredentialsToInsertDataSet,
    isPendingHasCredentialsData,
    server,
    username,
    password,
    hasInsertCredentials,
    setCredentialsToInsert: (params, redirectTo) => setCredentialsToInsert(params, redirectTo),
  };
};
