import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const YearsDropdownCalendar = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} width="36" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 10L20 10" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 3V5" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 3V5" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.767 4H8.24073C5.62979 4 4 5.42074 4 8.03217V15.8937C4 18.5465 5.62979 20 8.24073 20H15.7593C18.3779 20 20 18.5717 20 15.9594V8.03217C20.0077 5.42074 18.3856 4 15.767 4Z"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 16H15.5" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
