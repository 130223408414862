import { httpClient } from '@lib';
import { AxiosResponse } from 'axios';

export type ChatAuthDetails = {
  userId: string;
  authToken: string;
};

export type RelatedChatUserDetails = {
  chatUserId: string;
  discussedCompanyId: number;
};

export type InvoiceMentionHintsParams = {
  companyId: number;
  count?: number;
  filter: string;
};

export type InvoiceMentionHint = {
  invoiceId: number;
  number: string;
  contractorFriendlyName: string;
  contractorFullName: string;
  deliveryDate: Date;
  issueDate: Date;
  dueDate: Date;
  netValue: number;
  vatValue: number;
  grossValue: number;
  status: string;
  accountingStatus: string;
};

export type InvoiceMentionHintList = {
  invoices: InvoiceMentionHint[];
};

export const getAmityAuthToken = async () => {
  try {
    const response: AxiosResponse<ChatAuthDetails> = await httpClient.get('/chat/authToken');
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getRelatedChatUsers = async () => {
  try {
    const response: AxiosResponse<RelatedChatUserDetails[]> = await httpClient.get('/chat/relatedUsers');
    return response.data;
  } catch (e) {
    throw e?.response?.data;
  }
};

export const getInvoiceMentionHints = async (params: InvoiceMentionHintsParams): Promise<InvoiceMentionHintList> => {
  try {
    const response: AxiosResponse<InvoiceMentionHintList> = await httpClient.post('/chat/invoiceMentionHints', params);

    const invoices = response.data.invoices.map((invoice: InvoiceMentionHint) => ({
      ...invoice,
      deliveryDate: new Date(invoice.deliveryDate),
      issueDate: new Date(invoice.issueDate),
      dueDate: new Date(invoice.dueDate),
    }));

    return {
      invoices,
    };
  } catch (e) {
    throw e?.response?.data;
  }
};
