import { ButtonTabs } from '@interfaces';
import create from 'zustand';

interface ITabsButtonsStore {
  tabs: ButtonTabs[];
  setTabs: (tabs: ButtonTabs[]) => void;
  updateActiveTab: (tabId: string) => void;
}

export const useTabsButtonModalStore = create<ITabsButtonsStore>(set => ({
  tabs: [],
  setTabs: (newTabs: ButtonTabs[]) => set(() => ({ tabs: newTabs })),
  updateActiveTab: (tabId: string) => {
    const newTabs = useTabsButtonModalStore.getState().tabs.filter(tab => {
      tab.isActive = tab.id === tabId;
      return tab;
    });
    set(() => ({ tabs: newTabs }));
  },
}));
