export interface EmailFormData {
  from: string;
  emailTo: string[];
  subject: string;
  emailMessage: string;
  sendCopy: boolean;
}

export const transformFormDataToEmailData = (data: EmailFormData) => {
  const transformedData = {
    recipients: [...data.emailTo],
    subject: data.subject,
    body: data.emailMessage,
    sendCopyToSender: data.sendCopy,
  };
  return transformedData;
};

export const tabsData = [
  {
    name: 'Detale',
    id: 'details',
    isActive: true,
    width: 150,
  },
  {
    name: 'Powiązane dokumenty',
    id: 'linked',
    width: 200,
  },
];

export type TabId = 'details' | 'linked';
