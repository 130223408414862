import React from 'react';
import { Tooltip } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { ButtonIcon, DownloadIcon } from '@components';
import './invoiceTools.scoped.scss';

interface Props {
  testcy: string;
  pdfPagesMax: number;
  zoom: number;
  minZoom: number;
  zoomTick: number;
  maxZoom: number;
  pdfCurrentPage: number;
  handleZoom: (value: number) => void;
  setPdfCurrentPage: (value: number) => void;
  onDownloadInvoice: () => void;
}

export const InvoiceTools = ({
  testcy,
  pdfPagesMax,
  zoom,
  minZoom,
  zoomTick,
  maxZoom,
  handleZoom,
  setPdfCurrentPage,
  pdfCurrentPage,
  onDownloadInvoice,
}: Props) => {
  return (
    <div className="invoice-tools">
      <div className="zoom-options">
        <ButtonIcon testcy={`${testcy}-zoomin`} onClick={() => handleZoom(zoom + zoomTick)} disabled={zoom >= maxZoom}>
          <ZoomInIcon />
        </ButtonIcon>
        <ButtonIcon testcy={`${testcy}-zoomout`} onClick={() => handleZoom(zoom - zoomTick)} disabled={zoom <= minZoom}>
          <ZoomOutIcon />
        </ButtonIcon>
        <Tooltip title={'Pobierz fakturę'} placement="top" arrow>
          <div>
            <ButtonIcon testcy={`${testcy}-download`} onClick={onDownloadInvoice}>
              <div className="icon-btn-wrapper">
                <DownloadIcon />
              </div>
            </ButtonIcon>
          </div>
        </Tooltip>
        {pdfPagesMax > 1 && (
          <>
            <Tooltip title="Poprzednia strona" placement="top" arrow>
              <div>
                <ButtonIcon testcy={`${testcy}-pageprev`} onClick={() => setPdfCurrentPage(pdfCurrentPage - 1)} disabled={pdfCurrentPage <= 1}>
                  <ArrowCircleLeftIcon />
                </ButtonIcon>
              </div>
            </Tooltip>
            <Tooltip title="Następna strona" placement="top" arrow>
              <div>
                <ButtonIcon
                  testcy={`${testcy}-pagenext`}
                  onClick={() => {
                    setPdfCurrentPage(pdfCurrentPage + 1);
                  }}
                  disabled={pdfCurrentPage >= pdfPagesMax}
                >
                  <ArrowCircleRightIcon />
                </ButtonIcon>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};
