import { snackbarMessagesHandler } from '@utils';
import { useApiMutation } from '../useApiMutation';

interface IUseDeleteInvoice {
  deleteInvoice: (id: string) => void;
  isDeleteInvoiceLoading: boolean;
}

export const useDeleteInvoice = (onSuccess: () => void): IUseDeleteInvoice => {
  const { mutate, isLoading } = useApiMutation('deleteInvoiceById');

  const deleteInvoice = (id: string) => {
    mutate(id, {
      onSuccess: () => {
        snackbarMessagesHandler.invoiceDeleted();
        onSuccess();
      }
    });
  }

  return { deleteInvoice, isDeleteInvoiceLoading: isLoading };
};
