import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const PrintIcon = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-g`} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5005 5.30466V2.30466H5.50054V5.30466H4.00056V0.804688H15.0005V5.30466H13.5005ZM15.3082 9.30461C15.5915 9.30461 15.829 9.20878 16.0207 9.01711C16.2124 8.82545 16.3082 8.58795 16.3082 8.30461C16.3082 8.02128 16.2124 7.78378 16.0207 7.59211C15.829 7.40045 15.5915 7.30461 15.3082 7.30461C15.0249 7.30461 14.7874 7.40045 14.5957 7.59211C14.404 7.78378 14.3082 8.02128 14.3082 8.30461C14.3082 8.58795 14.404 8.82545 14.5957 9.01711C14.7874 9.20878 15.0249 9.30461 15.3082 9.30461ZM13.5005 15.9969V11.7277H5.50054V15.9969H13.5005ZM15.0005 17.4969H4.00056V13.4969H0.289062V7.80461C0.289062 7.0963 0.531046 6.50255 1.01501 6.02339C1.49898 5.54424 2.09031 5.30466 2.78901 5.30466H16.2121C16.9204 5.30466 17.5141 5.54424 17.9933 6.02339C18.4724 6.50255 18.712 7.0963 18.712 7.80461V13.4969H15.0005V17.4969ZM17.2121 11.9969V7.80461C17.2121 7.52128 17.1162 7.28378 16.9246 7.09211C16.7329 6.90045 16.4954 6.80461 16.2121 6.80461H2.78901C2.50568 6.80461 2.26818 6.90045 2.07651 7.09211C1.88485 7.28378 1.78901 7.52128 1.78901 7.80461V11.9969H4.00056V10.2277H15.0005V11.9969H17.2121Z"
        fill="#000000"
      />
    </svg>
  );
};
