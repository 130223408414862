import { AttachmentIcon, ButtonText, ChevronIcon, CloseIcon, HistoryIcon, HomeIcon, PageLoaderWrapper, PopupMenu, Statuses, TheDrawer } from '@components';
import { useCostDetails, useInvoiceDrawerController, useIsAllFalse, useOpenInNewTab } from '@hooks';
import { RequestErrorMessage } from '@interfaces';
import { addHotjarEvent, invalidateApiQuery } from '@lib';
import {
  InvoiceBulkOperationsParams,
  costRecordOutgoingPayment,
  createInvoicePdfById,
  deleteInvoiceById,
  invoiceMarkAsPaid,
  invoiceRecordPayment,
  invoicesConfirm,
  invoicesSendEmail,
  invoicesSendReminder,
} from '@services';
import { useContractorStore, useCostStore, useInternalAppStore, useInvoiceStore } from '@store';
import { AccountantStatusType, InvoiceActionEnum, formatFetchedDataToPdf, handleApiError, snackbarMessagesHandler } from '@utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHref, useNavigate, useParams } from 'react-router-dom';
import { EmailFormData } from '../sharedEmailForm/sharedEmailForm.utils';
import { SharedFilePreview } from '../sharedFilePreview/sharedFilePreview';
import { ReminderFormData } from '../sharedReminderForm/sharedReminderForm.utils';
import { InvoicePopups } from './components';
import { CreateDuplicateFormData } from './components/invoicePopups/components/invoiceCreateDuplicateForm/invoiceCreateDuplicateForm.utils';
import { RecordAmountFormData } from './components/invoicePopups/components/invoiceRecordPaymentForm/invoiceRecordPaymentForm.utils';
import { useGetInvoiceData } from './hooks';
import {
  InvoiceDetailsTab,
  transformFormDataToEmailData,
  transformFormDataToRecordAmountData,
  transformFormDataToReminderData,
} from './sharedInvoiceDetails.utils';
import { InvoiceDetailsMainTab } from './tabs';
import { InvoiceDetailsHistoryTab } from './tabs/historyTab/historyTab';

import './sharedInvoiceDetails.scss';

interface Props {
  invoiceId: string;
  refreshParentData?: (action: InvoiceActionEnum, invoiceId: string, keepInvoiceId: boolean) => void;
  isAccountant?: boolean;
  updateData?: boolean;
  type?: 'invoice' | 'cost';
}

export const SharedInvoiceDetails = ({ invoiceId, refreshParentData, isAccountant = false, updateData, type }: Props) => {
  const [isPendingConfirm, setIsPendingConfirm] = useState(false);
  const [isPendingInvoiceFile, setIsPendingInvoiceFile] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openReminderModal, setOpenReminderModal] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openRecordPaymentModal, setOpenRecordPaymentModal] = useState(false);
  const [openCreateDuplicateModal, setOpenCreateDuplicateModal] = useState(false);
  const [isEmailPending, setIsEmailPending] = useState(false);
  const [isReminderPending, setIsReminderPending] = useState(false);
  const [isRecordPaymentPending, setIsRecordPaymentPending] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [currentTab, setCurrentTab] = useState<InvoiceDetailsTab>('main');

  const { selectedContractorId, selectedContractor, setSelectedContractor } = useContractorStore();
  const { setIsClone, setIsCorrected, setIsFromDashboard, isOpenInvoicePreview, isOpenInvoiceDrawer } = useInvoiceStore();
  const { isOpenCostPreview, isOpenCostDrawer } = useCostStore();
  const { openInNewTab } = useOpenInNewTab();

  const navigate = useNavigate();

  const {
    isPendingInvoiceData,
    isPendingContractorData,
    dueDate,
    amountToPay,
    amountPaid,
    invoiceData,
    invoiceName,
    invoiceStatus,
    accountingInvoiceStatus,
    popupMenuItems,
    refreshInvoiceData,
  } = useGetInvoiceData(invoiceId, type);
  const cost = useCostDetails(invoiceId, type);
  const isPendingPopupMenu = useIsAllFalse([isPendingDelete, isPendingInvoiceFile], 0);
  const { lastFetchedInvoiceFile } = useInternalAppStore();
  const isLoadingPage = isPendingInvoiceData || isPendingContractorData;
  const { openInvoiceDrawer, closeInvoiceDrawer } = useInvoiceDrawerController(type);

  const { register, resetField } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: { paidAmount: 0, prefix: 'FK' },
  });

  const href = useHref('file-preview', { relative: 'route' });
  const { companyId } = useParams();

  const fetchInvoiceImage = () => {
    setIsPendingInvoiceFile(true);
    createInvoicePdfById(invoiceId, null)
      .then((data: Blob) => {
        const blob = new Blob([data], { type: 'image/pdf' });
        addHotjarEvent('Download invoice PDF');
        if (blob) formatFetchedDataToPdf(blob, `faktura-${invoiceName}.pdf`);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingInvoiceFile(false));
  };

  const refreshData = useCallback(
    (action: InvoiceActionEnum) => {
      // es5 because hoisting
      refreshParentData?.(action, invoiceId, true);
      refreshInvoiceData();
      invalidateApiQuery('getInvoiceHistory', type === 'invoice' && invoiceId);
      invalidateApiQuery('getCostDataById', Number(invoiceId));
    },
    [invoiceId, refreshInvoiceData, refreshParentData, type]
  );

  const shouldResetCurrentTab = isOpenInvoiceDrawer || isOpenCostDrawer;
  useEffect(() => {
    setCurrentTab('main');
  }, [shouldResetCurrentTab, setCurrentTab]);
  useEffect(() => {
    if (!updateData) return;
    refreshData(InvoiceActionEnum.OPEN_SIDE_PANEL);
  }, [updateData]);

  useEffect(() => {
    setDisableEdit(accountingInvoiceStatus === (AccountantStatusType.Sending || AccountantStatusType.SentToAccountingService));
  }, [accountingInvoiceStatus]);

  useEffect(() => {
    if (!isNaN(+amountPaid) && !isNaN(+amountToPay)) {
      +amountToPay - +amountPaid === 0 ? setIsPaid(true) : setIsPaid(false);
    }
  }, [amountPaid, amountToPay]);

  const onConfirmInvoice = () => {
    const params: InvoiceBulkOperationsParams = {
      ids: [+invoiceId],
      selectAll: false,
    };
    setIsPendingConfirm(true);
    addHotjarEvent('Confirm invoice from details drawer');
    invoicesConfirm(params)
      .then(() => {
        snackbarMessagesHandler.invoicesConfirmed(false);
        refreshData(InvoiceActionEnum.CONFIRM);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingConfirm(false));
  };

  const onEditInvoice = () => {
    setIsFromDashboard(false);
    const isCorrected = invoiceName.slice(0, 2) === 'FK';
    if (isCorrected) {
      navigate(`/invoices-list/edit-invoice-correction/correction-edit/${invoiceId}`);
      setIsCorrected(true);
    } else {
      navigate(`/invoices-list/edit-invoice/edit/${invoiceId}`);
      setIsCorrected(false);
    }
    closeInvoiceDrawer();
  };

  const onCloneInvoice = () => {
    navigate(`/invoices-list/add-invoice?clone=${invoiceId}`);
    setIsClone(true);
    register('paidAmount');
    resetField('paidAmount');
    closeInvoiceDrawer();
    setIsCorrected(false);
    setIsFromDashboard(false);
  };

  const onCorrectionInvoice = () => {
    navigate(`/invoices-list/add-invoice/correction/${invoiceId}`);
    closeInvoiceDrawer();
    setIsCorrected(true);
    setIsFromDashboard(false);
    register('paidAmount');
    resetField('paidAmount');
  };

  const onSubmitEmailForm = (data: EmailFormData) => {
    setIsEmailPending(true);
    addHotjarEvent('Send email to contractor', 'action');
    const requestData = transformFormDataToEmailData(data);
    invoicesSendEmail(requestData, +invoiceId)
      .then(() => {
        snackbarMessagesHandler.invoiceSendEmail();
        refreshData(InvoiceActionEnum.SEND);
        setOpenEmailModal(false);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        setIsEmailPending(false);
        if (selectedContractorId === invoiceData.contractorId) {
          const emails = concatWithoutDuplicates(selectedContractor.emails, data.emailTo);
          setSelectedContractor({ ...selectedContractor, emails: emails }, selectedContractorId);
        }
      });
  };

  const concatWithoutDuplicates = (arr1: string[], arr2: string[]) => {
    const arr = [...arr1, ...arr2];
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  const onSubmitReminderForm = (data: ReminderFormData) => {
    setIsReminderPending(true);
    addHotjarEvent('Send reminder to contractor', 'action');
    const requestData = transformFormDataToReminderData(data);
    invoicesSendReminder(requestData, +invoiceId)
      .then(() => {
        snackbarMessagesHandler.invoiceSendReminder();
        refreshData(InvoiceActionEnum.SEND_REMINDER);
        setOpenReminderModal(false);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => {
        setIsReminderPending(false);
        if (selectedContractorId === invoiceData.contractorId) {
          const emails = concatWithoutDuplicates(selectedContractor.emails, data.emailTo);
          setSelectedContractor({ ...selectedContractor, emails: emails }, selectedContractorId);
        }
      });
  };

  const onSubmitRecordPayment = async (data: RecordAmountFormData) => {
    setIsRecordPaymentPending(true);
    addHotjarEvent('Record invoice payment', 'action');
    const requestData = transformFormDataToRecordAmountData(data);

    try {
      if (type === 'invoice') {
        await invoiceRecordPayment(requestData, +invoiceId);
      } else {
        await costRecordOutgoingPayment({
          id: +invoiceId,
          request: requestData,
        });
      }

      snackbarMessagesHandler.invoiceRecordAmountPaid();
      setOpenRecordPaymentModal(false);
      refreshData(InvoiceActionEnum.RECORD_PAYMENT);
    } catch (err) {
      handleApiError(err);
    } finally {
      setIsRecordPaymentPending(false);
    }
  };

  const markAsPaid = () => {
    const params: InvoiceBulkOperationsParams = {
      ids: [+invoiceId],
      selectAll: false,
    };
    setIsRecordPaymentPending(true);
    addHotjarEvent('Record invoice payment', 'action');

    invoiceMarkAsPaid(params)
      .then(() => {
        snackbarMessagesHandler.invoiceRecordAmountPaid();
        setOpenRecordPaymentModal(false);
        refreshData(InvoiceActionEnum.MARK_AS_PAID);
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsRecordPaymentPending(false));
  };

  const onDeleteInvoice = (agree: boolean) => {
    if (agree) deleteInvoice();
    setOpenDialogDelete(false);
  };

  const deleteInvoice = () => {
    addHotjarEvent('Delete invoice');
    setIsPendingDelete(true);
    deleteInvoiceById(invoiceId)
      .then(() => {
        snackbarMessagesHandler.invoiceDeleted();
        if (!isAccountant) navigate('/invoices-list');
        if (refreshParentData) refreshParentData(InvoiceActionEnum.DELETE, invoiceId, false);
        closeInvoiceDrawer();
      })
      .catch((err: RequestErrorMessage[]) => handleApiError(err))
      .finally(() => setIsPendingDelete(false));
  };

  const onSubmitCreateDuplicate = (data: CreateDuplicateFormData) => {
    setOpenCreateDuplicateModal(false);
    openInNewTab(href + '/' + invoiceId + '?duplicateDate=' + data.createDuplicateDate);
  };

  const downloadInvoice = () => {
    lastFetchedInvoiceFile.id === invoiceId ? formatFetchedDataToPdf(lastFetchedInvoiceFile.file, `faktura-${invoiceName}.pdf`) : fetchInvoiceImage();
  };

  const onClickMenuItem = (action: string) => {
    if (action === 'download') downloadInvoice();
    if (action === 'delete') setOpenDialogDelete(true);
    if (action === 'clone') onCloneInvoice();
    if (action === 'duplicate') setOpenCreateDuplicateModal(true);
  };

  const ICON_SIZE = 16;

  return (
    <TheDrawer variant={'temporary'} dimensionVariant={'body-content'} open={isOpenInvoiceDrawer || isOpenCostDrawer}>
      <InvoicePopups
        onSubmitEmailForm={onSubmitEmailForm}
        onSubmitReminderForm={onSubmitReminderForm}
        onSubmitRecordPayment={onSubmitRecordPayment}
        onSubmitCreateDuplicate={onSubmitCreateDuplicate}
        setOpenEmailModal={setOpenEmailModal}
        setOpenReminderModal={setOpenReminderModal}
        onDeleteInvoice={onDeleteInvoice}
        setOpenRecordPaymentModal={setOpenRecordPaymentModal}
        setOpenCreateDuplicateModal={setOpenCreateDuplicateModal}
        openCreateDuplicateModal={openCreateDuplicateModal}
        openDialogDelete={openDialogDelete}
        openEmailModal={openEmailModal}
        openReminderModal={openReminderModal}
        openRecordPaymentModal={openRecordPaymentModal}
        invoiceName={invoiceName}
        amountToPay={type === 'invoice' ? amountToPay : cost.grossValue}
        amountPaid={type === 'invoice' ? amountPaid : cost.totalAmountPaid?.toString()}
        dueDate={dueDate}
        invoiceData={invoiceData}
        isEmailPending={isEmailPending}
        isReminderPending={isReminderPending}
        isRecordPaymentPending={isRecordPaymentPending}
      />
      <div className="drawer-wrapper">
        <div className={`${isOpenInvoicePreview || isOpenCostPreview ? 'show-preview-image' : 'hide-preview-image'}`}>
          {(isOpenInvoicePreview || isOpenCostPreview) && (
            <>
              <div className="hide-invoice-preview-wrapper">
                <ButtonText
                  testcy="hide-invoice-preview"
                  onClick={() => {
                    openInvoiceDrawer('onlyInvoiceDrawer', invoiceId);
                  }}
                  fontSize="12px"
                  content="ukryj fakturę"
                  endIcons="far"
                  margin="0"
                />
              </div>
              <div className="drawer-preview-wrapper">
                <SharedFilePreview
                  testcy="modal-file-preview"
                  fileId={invoiceId}
                  fileName={invoiceName || cost.number}
                  modalParentId="invoice-invoices-list-modal"
                  arrangement={'center'}
                  type={type}
                  showTabs={type === 'invoice'}
                  startZoom={1.5}
                />
              </div>
            </>
          )}
        </div>
        <div className="invoice-details-wrapper">
          <div>
            <div className="drawer-action-icons-wrapper">
              <button
                className="close-button"
                type="button"
                data-testcy={`drawer-close-button`}
                onClick={() => {
                  closeInvoiceDrawer();
                  if (companyId) {
                    navigate(`/owner-company-list/details/${companyId}`);
                  } else {
                    type === 'invoice' ? navigate('/invoices-list') : navigate('/costs-list');
                  }
                }}
              >
                <CloseIcon colorType="black" size={24} />
              </button>
              <div className="icons-wrapper">
                <button
                  data-testcy="invoice-details-drawer-preview"
                  className="icon-action-wrapper"
                  onClick={() => {
                    openInvoiceDrawer('invoiceDrawerAndInvoicePreview', invoiceId);
                  }}
                >
                  <ChevronIcon direction="left" />
                </button>
                <button
                  data-testcy="invoice-details-drawer-main"
                  className={`icon-action-wrapper ${currentTab === 'main' ? 'active-tab' : ''}`}
                  onClick={() => setCurrentTab('main')}
                >
                  <HomeIcon size={ICON_SIZE} />
                </button>
                <button
                  data-testcy="invoice-details-drawer-history"
                  className={`icon-action-wrapper ${currentTab === 'history' ? 'active-tab' : ''} ${type === 'cost' ? 'disabled-icon' : ''}`}
                  onClick={() => type === 'invoice' && setCurrentTab('history')}
                >
                  <HistoryIcon size={ICON_SIZE} colorType={type === 'cost' ? 'disabled' : ''} />
                </button>
                <button data-testcy="invoice-details-drawer-related" className="icon-action-wrapper disabled-icon">
                  <AttachmentIcon size={ICON_SIZE} colorType="disabled" />
                </button>
                <div className={`icon-action-wrapper ${type === 'cost' && 'disabled-icon'} `}>
                  <PopupMenu isDisabled={type === 'cost'} menuItems={popupMenuItems} onClickMenuItem={onClickMenuItem} />
                </div>
              </div>
            </div>
            <div className="custom-line"></div>
            <PageLoaderWrapper isLoading={isLoadingPage}>
              <div className="invoice-details-header">
                <div className="header-title title-g">{type === 'invoice' ? invoiceName : cost.displayName}</div>
                <div className="status-wrapper">
                  <Statuses
                    status={isAccountant ? accountingInvoiceStatus || (cost.isDigitalized && cost.accountingStatus) : invoiceStatus || cost.paymentStatus}
                    costAccountingStatuses={isAccountant && !!cost.accountingStatus}
                  />
                </div>
              </div>
              {currentTab === 'main' && (
                <InvoiceDetailsMainTab
                  type={type}
                  invoiceId={invoiceId}
                  isAccountant={isAccountant}
                  setOpenRecordPaymentModal={setOpenRecordPaymentModal}
                  setOpenEmailModal={setOpenEmailModal}
                  setOpenReminderModal={setOpenReminderModal}
                  refreshData={refreshData}
                  markAsPaid={markAsPaid}
                  isPaid={isPaid}
                  isPendingConfirm={isPendingConfirm}
                  disableEdit={disableEdit}
                  onConfirmInvoice={onConfirmInvoice}
                  onEditInvoice={onEditInvoice}
                  onCorrectionInvoice={onCorrectionInvoice}
                  isLoadingPage={isLoadingPage}
                  invoiceData={invoiceData}
                />
              )}
              {currentTab === 'history' && <InvoiceDetailsHistoryTab invoiceId={invoiceId} type={type} />}
            </PageLoaderWrapper>
          </div>
        </div>
      </div>
    </TheDrawer>
  );
};
