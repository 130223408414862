import React from 'react';
import { Control, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Tooltip } from '@mui/material';
import { ConfirmIcon, DocumentIcon, Statuses, TableRowData, TheCheckbox, TheHeaderFilter, ThePrice } from '@components';
import { CustomObject } from '@interfaces';
import { dateFormatter } from '@lib';
import { CompanyData, CostOverviewNew } from '@services';
import {
  AccountantStatusType,
  BulkOperationsEnum,
  FILE_SIZE_10MB,
  FILE_SIZE_25MB,
  InvoiceStatusType,
  PaymentStatusType,
  PaymentTypeEnum,
  costOwnerStatusOptions,
  invoiceAccountantStatusShortOptions,
  snackbarMessagesHandler,
  translateAccountantStatus,
} from '@utils';
import { CostCreationResultItem, CostFile } from 'src/services/costs';

export const getTableHeader = (
  control: Control,
  getValues: UseFormGetValues<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  onChangeCheckbox: (value: boolean) => void,
  headerCheckboxIsPressed: boolean,
  onBulkOperationsInvoice: (action: BulkOperationsEnum) => void,
  selectedCompanyData: CompanyData,
  showAccountingStatus: boolean,
  companyId: string,
  onSetHeaderFilter: (filter) => void
) => {
  const hasBankAccount = selectedCompanyData?.bankAccounts?.length > 0;
  return [
    {
      label: '',
      data: 'checkbox',
      keyPrefix: 'checkbox',
      width: 60,
      minWidth: 60,
      isSortable: false,
      customCellClass: 'checkbox-cell-costs',
      component: (
        <TheCheckbox
          testcy="costs-table-checkbox-all"
          control={control}
          name={'check-all-on-page'}
          customColor="black-owner-view"
          defaultValue={headerCheckboxIsPressed}
          validation={{ onChange: e => onChangeCheckbox(e?.target?.value) }}
          size="small"
        />
      ),
    },
    {
      label: 'Nr dokumentu',
      data: 'name',
      minWidth: 230,
      width: 230,
      isSortable: true,
      component: headerCheckboxIsPressed ? (
        <div className={`header-icon-wrapper`}>
          <Tooltip title={`${!hasBankAccount ? 'Brak konta bankowego w ustawieniach firmy' : 'Eksportuj fakturę do CSV'}`}>
            <div className={`${!hasBankAccount && 'confirm-icon-wrapper-disabled'}`}>
              <button
                data-testcy="cost-table-bulk-export-csv"
                className={`confirm-icon-wrapper`}
                onClick={() => onBulkOperationsInvoice(BulkOperationsEnum.EXPORT_CSV)}
                disabled={!hasBankAccount}
              >
                <ConfirmIcon />
              </button>
            </div>
          </Tooltip>
          {!companyId && (
            <Tooltip title={`Oznacz jako opłacona`}>
              <div>
                <button
                  data-testcy="cost-table-bulk-mark-as-paid"
                  className={`mark-as-paid-icon-wrapper`}
                  onClick={() => onBulkOperationsInvoice(BulkOperationsEnum.MARK_AS_PAID)}
                >
                  <ConfirmIcon />
                </button>
              </div>
            </Tooltip>
          )}
        </div>
      ) : (
        ''
      ),
    },
    {
      label: 'Podmiot',
      data: 'contractor',
      minWidth: 200,
      isSortable: true,
    },
    {
      label: `Status`,
      data: 'status',
      minWidth: 180,
      isSortable: false,
      component: (
        <TheHeaderFilter
          control={control}
          getValues={getValues}
          setValue={setValue}
          onSetHeaderFilter={onSetHeaderFilter}
          name={'status'}
          menuOptions={companyId ? invoiceAccountantStatusShortOptions : costOwnerStatusOptions}
        />
      ),
    },
    {
      label: 'Płatność',
      data: 'dueDate',
      minWidth: 160,
      isSortable: true,
    },
    {
      label: 'Kwota',
      data: 'grossValue',
      minWidth: 160,
      isSortable: true,
    },
    {
      label: 'Data wystawienia',
      data: 'issueDate',
      minWidth: 200,
      isSortable: true,
    },
    {
      label: '',
      data: 'action',
      customCellClass: 'action-cell-costs',
      width: 60,
      minWidth: 60,
      action: true,
      isSortable: false,
    },
  ];
};

export const costDocumentDataToTableRow = (
  dataRow: CostOverviewNew,
  companyId: string,
  accountingCredential: boolean,
  insertCredential: boolean,
  ownerHasBankAccount: boolean,
  control: Control,
  onChangeCheckbox: (value: boolean, id: number, name: string, net: number, gross: number, data: CostOverviewNew[]) => void,
  uniqueId: string,
  index,
  data: CostOverviewNew[],
  showAccountingStatus: boolean
): TableRowData => {
  const id = dataRow?.id?.toString();

  // future task add tooltip use cases below
  // const getCSVTooltipAndDisabled = (): { CSVTooltip: string; CSVDisabled: boolean } => {
  //   if (!ownerHasBankAccount) return { CSVTooltip: 'Przed wygenerowaniem pliku .CSV należy uzupełnić konto bankowe w danych firmy', CSVDisabled: true };

  //   const isFullyPaid = data.grossValue === data.amountPaid;
  //   if (isFullyPaid) return { CSVTooltip: 'Faktura kosztowa została już opłacona', CSVDisabled: true };

  //   if (!data.grossValue) return { CSVTooltip: 'Do wygenerowania pliku .CSV konieczne jest uzupełnienie danych w formularzu', CSVDisabled: true };

  //   if (data.paymentMethod !== PaymentTypeEnum.TRANSFER && data.paymentMethod !== PaymentTypeEnum.TRANSFER_SPLIT_PAYMENT)
  //     return { CSVTooltip: 'Do wygenerowania pliku .CSV konieczne jest uzupełnienie typu płatności jako przelew lub płatność podzielona', CSVDisabled: true };

  //   return { CSVTooltip: 'Wygeneruj do pliku CSV', CSVDisabled: false };
  // };

  return {
    id: id,
    checkbox: {
      content: '',
      component: (
        <div key={`checbox-key-${id}-${dataRow.status}-${uniqueId}`}>
          <TheCheckbox
            testcy={`costs-table-checkbox-${index}`}
            control={control}
            name={`checkbox-${id}`}
            validation={{
              onChange: e => onChangeCheckbox(e?.target?.value, dataRow.id, `checkbox-${id}`, dataRow.netValue, dataRow.grossValue, data),
            }}
            customColor="grey"
            size="small"
          />
        </div>
      ),
    },
    name: {
      content: (
        <div className="costs-name-container">
          <DocumentIcon colorType="black" />
          <div className="costs-name">{dataRow.documentNumber || ''}</div>
        </div>
      ),
    },
    status: {
      content: <Statuses status={companyId ? dataRow.accountingStatus : dataRow.paymentStatus} costAccountingStatuses={companyId ? true : false} />,
    },
    dueDate: {
      content: (
        <div className="costs-date-container">
          <div className="costs-date">{dateFormatter.standardFormat(dataRow.dueDate)}</div>
          {dataRow.daysOverdue > 0 && !!dataRow.grossValue && dataRow.paymentStatus !== InvoiceStatusType.Paid && (
            <div className="costs-overdue">
              {dataRow.daysOverdue.toLocaleString('pl-PL')} {dataRow.daysOverdue == 1 ? 'dzień' : 'dni'}
            </div>
          )}
        </div>
      ),
    },
    contractor: {
      content: <div className="costs-contractor ellipsis-text-one-line-g">{dataRow.contractor}</div>,
    },
    grossValue: {
      content: <ThePrice netValue={dataRow.netValue} grossValue={dataRow.grossValue} />,
    },
    issueDate: { content: <div className="costs-date">{dateFormatter.standardFormat(dataRow.issueDate)}</div> },
    action: {
      // ...getCSVTooltipAndDisabled(),
    },
    stashedData: { documentNumber: dataRow.documentNumber, accountingStatus: dataRow.accountingStatus },
  };
};

export const transformData = (data: FormData, companyId?: any) => {
  let totalValidFilesSize = 0;
  const files = data.getAll('file') as File[];

  const allFiles = {
    formData: new FormData(),
    okayFileCount: 0,
    totalFileCount: files.length,
    wrongSizeFiles: [] as File[],
    tooLongNameFiles: [] as File[],
  };
  //add the company ID if it exists
  if (companyId) {
    allFiles.formData.append('companyId', companyId);
  }
  //check each file
  let shouldReturn = false;
  files.forEach(file => {
    shouldReturn = false;
    //check if length is below 100
    if (file.name.length > 100) {
      allFiles.tooLongNameFiles.push(file);
      shouldReturn = true;
    }
    //check if size is below maximum
    if (file.size > FILE_SIZE_10MB) {
      allFiles.wrongSizeFiles.push(file);
      shouldReturn = true;
    }
    if (shouldReturn) return;

    //add the file to be uploaded
    totalValidFilesSize += file.size;
    allFiles.okayFileCount++;
    allFiles.formData.append('file', file);
  });
  //if total files size is above maximum, display snackbar and abort any further actions
  if (totalValidFilesSize > FILE_SIZE_25MB) {
    snackbarMessagesHandler.allFilesSize(25, totalValidFilesSize);
    return;
  }
  //if total file count is above maximum, display snackbar and abort any further actions
  if (files.length > 20) {
    snackbarMessagesHandler.tooManyFiles(20, files.length);
    return;
  }
  //if there are any files that did not match the requirements, display appropriate snackbars
  if (allFiles.tooLongNameFiles.length || allFiles.wrongSizeFiles.length) {
    if (allFiles.tooLongNameFiles.length) snackbarMessagesHandler.fileTooLongName();
    if (allFiles.wrongSizeFiles.length) snackbarMessagesHandler.singleFileSize(10, allFiles.wrongSizeFiles[0].size);

    //if there is only one file uploaded, abort any further actions
    if (allFiles.totalFileCount <= 1) return;
    return allFiles;
  }
  return allFiles;
};

export const getSelectedCosts = (data: CustomObject): number[] => {
  return Object.keys(data)
    .filter(key => data[key] === true)
    .filter(key => typeof key === 'string')
    .map(key => +(key as string).split('-')[1])
    .filter(id => !isNaN(id));
};

export const transformDataToFileList = files => {
  const maxFileCount = 3;
  const template = files.slice(0, maxFileCount).map(d => <li key={d.name}>{d.name}</li>);
  return template;
};

export function isCostFile(data: TableRowData | CostFile): data is CostFile {
  return typeof data.id == 'number';
}

export const transformMenuItemsCostsList = (
  dataList: CostOverviewNew[],
  companyId: string,
  insertCredential: boolean,
  accountingCredential: boolean,
  hasBankAccount: boolean
) => {
  const menuItems = [];
  dataList.forEach(data => {
    const isSendToInsertDisabled = !insertCredential || !accountingCredential || data.accountingStatus !== AccountantStatusType.New;
    const isOwnerDeleteDisabled = data.accountingStatus !== AccountantStatusType.New;
    const isAccountantDeleteDisabled = data.accountingStatus === AccountantStatusType.Confirmed;
    const isConfirmDisabled = data.accountingStatus !== AccountantStatusType.SentToAccountingService;
    const disabledMarkAsPaid = data.paymentStatus === PaymentStatusType.Paid;
    //prettier-ignore
    const confirmDisabledMessage =
      data.accountingStatus === AccountantStatusType.Confirmed
        ? 'Dokument jest już zatwierdzony'
        : (
          data.accountingStatus !== AccountantStatusType.SentToAccountingService
            ? 'Można zatwierdzić jedynie dokument wysłany do InsERTa.'
            : undefined
        );
    const disabledCreateCVSFile =
      (data.paymentMethod !== PaymentTypeEnum.TRANSFER && data.paymentMethod !== PaymentTypeEnum.TRANSFER_SPLIT_PAYMENT) ||
      !data.grossValue ||
      (data.grossValue && data.grossValue === data.amountPaid) ||
      !hasBankAccount ||
      data.paymentStatus === PaymentStatusType.Paid;

    menuItems.push(
      companyId
        ? [
            {
              label: 'Podgląd faktury',
              value: 'filePreview',
            },
            {
              label: 'Edytuj',
              value: 'edit',
            },
            {
              label: 'Zatwierdź',
              value: 'confirm',
              disabled: isConfirmDisabled,
              additionalMessage: confirmDisabledMessage,
            },
            {
              label: 'Wyślij do InsERT',
              value: 'sendToInsert',
              disabled: isSendToInsertDisabled,
            },
            {
              label: 'Eksportuj do CSV',
              value: 'exportCSV',
              disabled: disabledCreateCVSFile,
            },
            {
              label: 'Usuń',
              value: 'delete',
              disabled: isAccountantDeleteDisabled,
              additionalMessage: isAccountantDeleteDisabled ? 'Nie można usunąć zatwierdzonego dokumentu.' : undefined,
            },
          ]
        : [
            {
              label: 'Podgląd faktury',
              value: 'filePreview',
            },
            {
              label: 'Edytuj',
              value: 'edit',
              additionalMessage: isAccountantDeleteDisabled ? 'Nie można edytować zatwierdzonego dokumentu.' : undefined,
            },
            {
              label: 'Eksportuj do CSV',
              value: 'exportCSV',
              disabled: disabledCreateCVSFile,
            },
            {
              label: 'Oznacz jako opłacona',
              value: 'markAsPaid',
              disabled: disabledMarkAsPaid,
            },
            {
              label: 'Usuń',
              value: 'delete',
              disabled: isOwnerDeleteDisabled,
            },
          ]
    );
  });

  return menuItems;
};

export const transformFileCreationResultToCostFile = (data: CostCreationResultItem[]): CostFile[] => {
  return data.map(f => {
    return {
      id: f.id,
      originalFilename: f.originalFilename,
      createdDate: new Date().toISOString(),
      stashedData: '',
    };
  });
};

export const unregisterCheckboxes = (data: CustomObject, setValue: UseFormSetValue<FieldValues>): void => {
  const keys = Object.keys(data);
  keys.forEach(key => {
    if (typeof key === 'string' && key.includes('checkbox-') && !key.includes('checkbox-filter-')) {
      setValue(key, false);
    }
  });
};

export type CostConfirmDialogDataType = {
  numberOfInvoicesChecked: number;
  invoicesNotConfirmed: number;
  dialogTitle: string;
  action: BulkOperationsEnum;
};
export type CheckboxStateDataType = {
  checkedNetValue?: number;
  checkedGrossValue?: number;
  totalNetValue?: number;
  totalGrossValue?: number;
  numberOfInvoicesChecked?: number;
  totalInvoicesCount?: number;
  checkboxTimeout?: boolean;
  time?: any;
  checkboxesSelected?: { id: number; name: string; net: number; gross: number }[];
  headerCheckboxIsPressed?: boolean;
  selectedAll?: boolean;
};

export const translateAction = (action: BulkOperationsEnum): string => {
  switch (action) {
    case BulkOperationsEnum.EXPORT_CSV:
      return `Eksportowano:`;
    case BulkOperationsEnum.MARK_AS_PAID:
      return `Opłacono:`;
  }
};

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const actionMenuItems = [
  {
    label: 'Usuń plik',
    value: 'digitalize',
  },
  {
    label: 'Usuń plik',
    value: 'delete',
  },
  {
    label: 'Podgląd pliku',
    value: 'preview',
  },
];