import { DatePicker, Divider, SelectDayButtonsToolbar, TheSelect } from '@components';
import { Option } from '@interfaces';
import { dateFormatter } from '@lib';
import { SelectChangeEvent } from '@mui/material';
import { useInvoiceStore } from '@store';
import {
  InvoiceStatusType,
  PaymentType,
  focusOnNextFormInput,
  formErrorMsg,
  formatNumberToCurrency,
  standardValidators,
  stringToNumberSecondOptions,
} from '@utils';
import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormReturn, useFormState } from 'react-hook-form';
import { useParams } from 'react-router';
import { NumberInput, Statuses } from 'src/components/form';
import './paymentSection.scoped.scss';
import { isUndefined } from 'lodash';
import { InvoiceSection } from 'src/components/miscellaneous/invoiceSection/invoiceSection';
import { PaymentSummaryTable } from './paymentSummaryTable';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  useFormFeatures: Partial<UseFormReturn<FieldValues>>;
  paymentTypeOptions: Option[];
  bankAccountsOptions: Option[];
  disableBankAccount: boolean;
  amountPaid: string;
  leftToPaid: string;
  grossSummary: string;
  isPaid: boolean;
  status: string;
  setPaymentTypeCurrent: (v: PaymentType) => void;
  setOpenBankAccountModal: (v: boolean) => void;
  setIsBlurred: (v: boolean) => void;
}

export const PaymentSection = ({
  open,
  setOpen,
  useFormFeatures,
  paymentTypeOptions,
  bankAccountsOptions,
  disableBankAccount,
  amountPaid,
  leftToPaid,
  grossSummary,
  isPaid,
  status,
  setPaymentTypeCurrent,
  setOpenBankAccountModal,
  setIsBlurred,
}: Props) => {
  const [openPicker, setOpenPicker] = useState(false);
  const [isDueDate, setIsDueDate] = useState(true);
  const [isDisableBankAccount, setIsDisableBankAccount] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date>(null);
  const { clearErrors, control, trigger, watch, setValue } = useFormFeatures;
  const {
    errors: { dueDate: dueDateErr, paymentType: paymentTypeErr, bankNumber: bankNumberErr },
  } = useFormState({ control });
  const { id, action } = useParams();

  const { isCorrected, setIsFavoriteClicked } = useInvoiceStore();

  const mainBankAccount = watch('main-bank-account');
  const invoiceDate = watch('invoiceDate');
  const paymentType = watch('paymentType');
  const paidAmount = watch('paidAmount');
  const dueDate = watch('dueDate');

  useEffect(() => {
    isPaid || disableBankAccount ? setIsDisableBankAccount(true) : setIsDisableBankAccount(false);
  }, [isPaid, disableBankAccount]);

  useEffect(() => {
    if (isDueDate) {
      setValue('dueDate', dueDate);
      setIsDueDate(false);
      return;
    }
    //dont change the date in any mode other than the initial creation
    if (!isUndefined(id) || action === 'edit' || action === 'correction-edit') return;

    if (paymentType === 'Cash' || paymentType === 'Prepaid' || paymentType === 'Card') {
      setValue('dueDate', invoiceDate);
    } else {
      const defaultDueDate = dateFormatter.addDaysToDate(invoiceDate, 14);
      setValue('dueDate', defaultDueDate);
    }
  }, [paymentType]);

  useEffect(() => {
    const errors = [dueDateErr, paymentTypeErr, bankNumberErr];
    const isInvalid = errors.some(e => !!e);
    if (isInvalid && !open) setOpen(true);
  }, [dueDateErr, paymentTypeErr, bankNumberErr]);

  const onCalendarToolbarOptionClick = (day: number) => {
    const selectedDate = dateFormatter.addDaysToDate(invoiceDate || new Date(), day);
    setValue('dueDate', selectedDate);
    setOpenPicker(false);
    setSelectedDay(selectedDate);
  };

  const onChangeHandle = (value: string) => {
    const issueDateParsed = dateFormatter.getDateObjectFromString(value);
    setSelectedDay(issueDateParsed);
  };

  return (
    <InvoiceSection title="Płatność">
      <div className="payment-details-ontainer">
        <div className="payment-details">
          <div className="input-container">
            <TheSelect
              testcy="invoice-form-payment-type"
              control={control}
              name="paymentType"
              label="Sposób zapłaty"
              appearance="primary"
              size="medium"
              width="384px"
              hideEmptyOption
              options={paymentTypeOptions}
              renderLabel={option => {
                if (option.value === PaymentType.Paid) return <Statuses status={InvoiceStatusType.Paid} />;
                return option.label;
              }}
              validation={{
                required: formErrorMsg.isRequired,
                onChange: (e: SelectChangeEvent<PaymentType>) => {
                  const paymentType = e.target.value as PaymentType;
                  setPaymentTypeCurrent(paymentType);
                },
              }}
              defaultValue="Transfer"
            />
          </div>
          {!isCorrected && !isPaid ? (
            <div className="input-container">
              <NumberInput
                testcy="invoice-form-paid-amount"
                control={control}
                name="paidAmount"
                width="384px"
                appearance="primary"
                size="medium"
                maxDecimals={2}
                float
                allowMultiplication
                label="Zapłacono"
                placeholder="0,00"
                defaultValue="0,00"
                disabled={status === InvoiceStatusType.Paid}
                onKeyDown={event => {
                  focusOnNextFormInput(event, `bankNumber`);
                }}
                onFocus={() => {
                  setIsBlurred(false);
                }}
                onBlur={() => {
                  setIsBlurred(true);
                  setValue('paidAmount', formatNumberToCurrency(stringToNumberSecondOptions(paidAmount)));
                }}
                validation={{
                  validate: value => {
                    return stringToNumberSecondOptions(grossSummary) >= stringToNumberSecondOptions(value) ? true : formErrorMsg.incorrectAmountToPay;
                  },
                  required: formErrorMsg.isRequired,
                }}
              />
            </div>
          ) : (
            <div />
          )}

          {!isDisableBankAccount && !isPaid ? (
            <div className="input-container">
              <TheSelect
                testcy="invoice-form-bank-number"
                control={control}
                watch={watch}
                name="bankNumber"
                appearance="primary"
                size="medium"
                label="Numer konta"
                width="384px"
                hideEmptyOption
                showAdditionalMessageInOption
                emptyListText="Brak wyników"
                options={bankAccountsOptions}
                validation={{ required: disableBankAccount ? false : formErrorMsg.isRequired }}
                disabled={disableBankAccount}
                isEnabledOnEmptyList
                onAddClick={() => setOpenBankAccountModal(true)}
                addBtnText="Dodaj konto bankowe"
                rerenderKey={mainBankAccount}
                onBlur={() => {
                  setIsFavoriteClicked(false);
                }}
              />
            </div>
          ) : (
            !isCorrected && <div />
          )}

          {!isPaid && (
            <div className="input-container">
              <DatePicker
                testcy="invoice-form-due-date"
                control={control}
                name="dueDate"
                appearance="primary"
                size="medium"
                onChange={onChangeHandle}
                openPicker={openPicker}
                setOpenPicker={setOpenPicker}
                trigger={trigger}
                clearErrors={clearErrors}
                label="Termin zapłaty"
                width="384px"
                validation={standardValidators.requiredDate}
                toolbarComponent={
                  <SelectDayButtonsToolbar
                    testcy="invoice-date-due-date"
                    daysList={[1, 3, 7, 14, 30]}
                    onSelectDay={onCalendarToolbarOptionClick}
                    dayToDiff={invoiceDate}
                    selectedValue={selectedDay}
                    defaultNumberDueDays={14}
                  />
                }
                onKeyDown={event => {
                  focusOnNextFormInput(event, 3);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Divider />
      <div className="summary-container">
        <div>
          <PaymentSummaryTable leftToPay={leftToPaid} amountPaid={amountPaid} />
        </div>
      </div>
    </InvoiceSection>
  );
};
