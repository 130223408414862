import React from 'react';

interface Props {
  colorType?: 'white' | 'primary' | 'disabled' | '';
}

export const Inbox = ({ colorType = '' }: Props) => {
  return (
    <svg className={`icon-color-${colorType}-stroke`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.84485 3.59424C5.08245 3.59424 3.35059 5.55675 3.35059 8.334V15.8281C3.35059 18.6053 5.07423 20.5679 7.84485 20.5679H15.7708C18.5424 20.5679 20.2669 18.6053 20.2669 15.8281V8.334C20.2669 5.55675 18.5424 3.59424 15.7717 3.59424"
        stroke="#101013"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2596 13.1606H16.2336C15.3996 13.1606 14.6388 13.634 14.2685 14.3837C13.8196 15.2911 12.886 15.9158 11.8069 15.9158C10.728 15.9158 9.79437 15.2911 9.34537 14.3837C8.97504 13.634 8.21427 13.1606 7.38034 13.1606H3.35791"
        stroke="#101013"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.6278 7.62744L11.9907 10.1799L9.35449 7.62744" stroke="#101013" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.9907 10.1766V3.22852" stroke="#101013" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
