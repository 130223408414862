import React from 'react';
import { TheDialog } from 'src/components/miscellaneous';

interface ReportAnIssueModalProps {
  isOpened: boolean;
  onCloseModal: () => void;
}

export const ReportAnIssueModal = ({ isOpened, onCloseModal }: ReportAnIssueModalProps) => {
  return (
    <TheDialog testcy="report-problem" open={isOpened} onClose={onCloseModal}>
      <p>
        Jeśli chcesz zgłosić sugestię lub problem z aplikacją, napisz na{' '}
        <a data-testcy="report-problem-mailto" href="mailto:info@webcount.eu">
          info@webcount.eu
        </a>
      </p>
    </TheDialog>
  );
};
