import React from 'react';
import { InvoiceHistory } from '@services';

import './historyItem.scoped.scss';
import { getIsEventClickable, translateInvoiceEvent } from './historyItem.utils';
import { dateFormatter } from '@lib';

interface Props {
  item: InvoiceHistory;
  onClick?: () => void;
}

export const HistoryItem = ({ item: { event, time, user }, onClick }: Props) => {
  const historyItemContent = (
    <>
      <div className="history-item-text strong">{user || 'UŻYTKOWNIK USUNIĘTY'}</div>
      <div className="history-item-text light">{translateInvoiceEvent(event)}</div>
      <div className="history-item-text strong">{dateFormatter.changeFormat(time, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS", 'dd.MM.yyyy, HH:mm')}</div>
    </>
  );

  if (getIsEventClickable(event))
    return (
      <button className="history-item" onClick={onClick}>
        {historyItemContent}
      </button>
    );

  return <div className="history-item">{historyItemContent}</div>;
};
