import { TableRow, TableRowTypeMap } from '@mui/material';
import React from 'react';

import '../theTableNew.scoped.scss';

type Props = TableRowTypeMap<{}, 'tr'>['props'] & {
  isActive: boolean;
};

export const TheTableRow = (props: Props) => {
  const { isActive, ...rowProps } = props;
  const className = `${isActive ? 'table-body-row-custom-active' : 'table-body-row-custom'}`;

  return <TableRow {...rowProps} className={className} />;
};
