import { EditIcon } from '@components';
import { ContractorData } from '@services';
import React from 'react';

import './contractorInfo.scoped.scss';

type Props = {
  data: ContractorData;
  onEdit: () => void;
};

export const ContractorInfo = ({ data, onEdit }: Props) => {
  const {
    name,
    nip,
    address: { street, zipCode, city },
  } = data;

  return (
    <div>
      <div className="header">
        <p className="name">{name}</p>
        <button type="button" data-testcy="invoice-form-contractor-edit" className="edit-contractor-icon" onClick={onEdit}>
          <EditIcon colorType="primary" />
        </button>
      </div>
      <p className="address">
        {street}
        <br />
        {zipCode} {city}
      </p>
      {!!nip && <p className="nip">NIP: {nip}</p>}
    </div>
  );
};
